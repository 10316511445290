/** @format */

import api from "../../../utils/api";
// import { isObject, removeDuplicate, uuidv4 } from "../../../utils/helper";
import { planningService } from "../constant";

const basePath = `${planningService}`;

export const getPlanningAll = async (ref: any, params: any): Promise<any> => {
  try {
    let response: any = await api.get<any>(`${basePath}/${ref}`, { params });

    return response?.data?.return;
  } catch (error) {
    console.log(error);
  }
};

/** Edit Alokasi Sumber Dana BOS   */
export const editAlokasiSumberDanaBos = async (params: any) => {
  const nsm = params.nsm;
  const response = await api.put<any>(
    `${basePath}/portal-bos/alokasi/edit/${nsm}`,
    params,
  );
  return response?.data?.return;
};

/** Delete Alokasi Sumber Dana BOS   */
export const deleteAlokasiSumberDanaBos = async (params: any) => {
  try {
    const response = await api.delete(
      `${basePath}/portal-bos/alokasi/delete/${params}`,
    );
    return response?.data?.return;
  } catch (error) {
    console.log(error);
  }
};

export const getRencanaIndikatifPendapatanLogs = async () => {
  try {
    const response = await api.get(
      `${basePath}/rencana-pendapatan-indikatif/logs`,
    );
    localStorage.setItem(
      "indikatif-pendapatan-logs",
      JSON.stringify(response.data.return),
    );
    return response?.data?.return;
  } catch (error) {
    console.log(error);
    // if (error.response) throw error.response;
    // else throw error;
  }
};

export const getRencanaKegiatanIndikatifBelanjaLogs = async (tahun?: any) => {
  try {
    const response = await api.get(
      `${basePath}/rencana/kegiatan-indikatif/logs`,
      {
        params: {
          tahun: tahun,
        },
      },
    );
    localStorage.setItem(
      "kegiatan-indikatif-belanja-logs",
      JSON.stringify(response.data.return),
    );
    return response?.data?.return;
  } catch (error) {
    console.log(error);
    // if (error.response) throw error.response;
    // else throw error;
  }
};

export const getAlokasi = async (id: any) => {
  try {
    const response = await api.get<any>(
      `${basePath}/rencana-pendapatan-indikatif/listalokasi/` + id,
    );
    return response?.data?.return;
  } catch (error) {
    console.log(error);
  }
};

export const deleteVerifikasiSumberDanaBos = async (id: any) => {
  try {
    const response = await api.delete(
      `${basePath}/portal-bos/verifikasi/delete/${id}`,
    );
    return response?.data?.return;
  } catch (err) {
    console.log(err);
  }
};

export const deletePencairanSumberDanaBos = async (params: any) => {
  try {
    const response = await api.put<any>(
      `${basePath}/portal-bos/pengajuan/cancel`,
      params,
    );
    return response?.data?.return;
  } catch (err) {
    console.log(err);
  }
};

/** Edit Verifikasi Sumber Dana BOS   */
export const editVerifikasiSumberDanaBos = async (params: any) => {
  const response = await api.put<any>(
    `${basePath}/portal-bos/verifikasi/pengajuan`,
    params,
  );
  return response?.data?.return;
};

/** Edit Pengajuan Sumber Dana BOS   */
export const editPengajuanSumberDanaBos = async (params: any) => {
  const response = await api.put<any>(
    `${basePath}/portal-bos/pengajuan/penyaluran`,
    params,
  );
  return response?.data?.return;
};

/** Edit Pengajuan Verifikasi Dokumen Sumber Dana BOS   */
export const editPengajuanVerifikasiDokumenBos = async (params: any) => {
  const response = await api.put<any>(
    `${basePath}/portal-bos/status-verifikasi-dokumen`,
    params,
  );
  return response?.data?.return;
};

/** Edit Tolak Pengajuan Verifikasi Dokumen Sumber Dana BOS   */
export const tolakPengajuanVerifikasiDokumenBos = async (params: any) => {
  const response = await api.put<any>(
    `${basePath}/portal-bos/tolak-status-verifikasi-dokumen`,
    params,
  );
  return response?.data?.return;
};

/** Post Berkas RAB  */
export const postBerkasRencana = async (params: any) => {
  const response = await api.post(`${basePath}/berkas-rencana/upload`, params);
  return response?.data?.return;
};

/** Get Berkas RAB  */
export const getBerkasRencana = async (id: any, nsm: any, tahun: any) => {
  const response = await api.get(
    `${basePath}/berkas-rencana?idRincianRencanaKegiatan=${id}&nsm=${nsm}&tahun=${tahun}`,
  );
  return response?.data?.return;
};
