/** @format */

import React, { useState, useEffect } from "react";
import { Modal, InputNumber, Input, Button, Select, Form } from "antd";
import { getPlanningAll } from "../../../../../services/v2/planningservice";
import { getAlokasiDanaBKBA } from "../../../../../services/v2/notificationservice";

type FormModalActionType = {
  title: any;
  editData: any;
  openModal: boolean;
  loading: boolean;
  tmpSumberDana: any;
  tmpFilterSumberDana: any;
  auth: any;
  hanldeClose: () => void;
  handleSave: (e: any) => void;
};

const ModalFormPendapatan = ({
  title,
  editData,
  openModal,
  loading,
  tmpSumberDana,
  tmpFilterSumberDana,
  auth,
  handleSave,
  hanldeClose,
}: FormModalActionType) => {
  let apbnBosSemester: any = ["apbn_bos_semester_1", "apbn_bos_semester_2"];
  let bkbaList: any = [
    "bkba_afirmasi",
    "bkba_kinerja",
    "ba_rehab_berat",
    "bk_mdl",
  ];
  const [form] = Form.useForm();
  const [enabledNilai, setEnabledNilai] = useState(false);

  useEffect(() => {
    if (editData) {
      form.setFieldsValue({
        nilaiPendapatan: editData?.jumlah,
        keterangan: editData?.keterangan,
        sumberDana: editData?.kode_sumber_dana,
      });
      setEnabledNilai(
        (apbnBosSemester.includes(editData?.kode_sumber_dana) && true) ||
          (bkbaList.includes(editData?.kode_sumber_dana) && true) ||
          false,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editData]);

  const handleChange = (values: any) => {
    for (let obj in values) {
      if (obj === "sumberDana") {
        if (
          values[obj] === "apbn_bos_semester_1" ||
          values[obj] === "apbn_bos_semester_2"
        ) {
          setEnabledNilai(true);
          getPortalBos(values);
        } else if (
          values[obj] === "bkba_kinerja" ||
          values[obj] === "bkba_afirmasi" ||
          values[obj] === "ba_rehab_berat"
        ) {
          setEnabledNilai(true);
          getPortalBos(values);
        } else if (values[obj] === "bk_mdl") {
          setEnabledNilai(true);
        } else {
          setEnabledNilai(false);
        }
      }
    }
  };

  const getPortalBos = async (values: any) => {
    if (apbnBosSemester.includes(values?.sumberDana)) {
      const dataPortalBos = await getPlanningAll("portal-bos", {
        nsm: auth?.madrasah?.nsm,
        tahun: auth?.isTahun,
      });

      const tmpBos =
        values?.sumberDana === "apbn_bos_semester_1"
          ? "apbn_bos_semester1"
          : "apbn_bos_semester2";
      const tmoNilai = (dataPortalBos && dataPortalBos[`${tmpBos}`]) || "0";

      form.setFieldsValue({ nilaiPendapatan: tmoNilai });
    } else if (bkbaList.includes(values?.sumberDana)) {
      const tmpBKBA =
        (values?.sumberDana === "bkba_kinerja" && "kinerja") ||
        (values?.sumberDana === "bkba_afirmasi" && "afirmasi") ||
        (values?.sumberDana === "ba_rehab_berat" && "Rehab Berat");
      const dataPortalBos = await getAlokasiDanaBKBA(
        auth?.madrasah?.nsm,
        auth?.isTahun,
      );

      if (dataPortalBos && dataPortalBos?.jenis_bantuan === tmpBKBA) {
        const tmoNilai = (dataPortalBos && dataPortalBos.nominal) || "0";
        form.setFieldsValue({ nilaiPendapatan: tmoNilai });
      } else {
        form.setFieldsValue({ nilaiPendapatan: 0 });
      }
    }
  };

  const handleBeforeCLose = () => {
    form.resetFields();
    hanldeClose();
  };

  return (
    <Modal
      open={openModal}
      title={`${title} Rencana Pendapatan`}
      onCancel={handleBeforeCLose}
      footer={[
        <Button key="back" onClick={handleBeforeCLose}>
          Batal
        </Button>,
        <Button
          key="submit"
          type="primary"
          loading={loading}
          onClick={() => form.submit()}>
          Simpan
        </Button>,
      ]}>
      <Form
        form={form}
        key="formPendapatanDefinitif"
        name="formPendapatanDefinitif"
        layout="vertical"
        onValuesChange={handleChange}
        onFinish={handleSave}>
        <Form.Item
          label="Sumber Dana"
          name="sumberDana"
          rules={[
            { required: true, message: "Sumber Dana tidak boleh kosong!" },
          ]}>
          <Select
            showSearch
            optionFilterProp="children"
            // onChange={handleChange}
            placeholder="Pilih Sumber Dana">
            {tmpSumberDana?.length &&
              tmpSumberDana
                .filter((item: any) => !tmpFilterSumberDana.includes(item.kode))
                .map((e: any, i: any) => {
                  return (
                    <Select.Option key={`role${i}`} value={e.kode}>
                      {e.nama}
                    </Select.Option>
                  );
                })}
          </Select>
        </Form.Item>
        <Form.Item
          label="Nilai Pendapatan"
          name="nilaiPendapatan"
          rules={[
            {
              required: true,
              message: "Nilai Pendapatan tidak boleh kosong!",
            },
          ]}>
          <InputNumber
            disabled={enabledNilai}
            style={{ width: "100%" }}
            formatter={(value) =>
              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
            prefix="Rp."
            placeholder="Silahkan isi Nilai Pendapatan"
          />
        </Form.Item>
        <Form.Item label="Keterangan" name="keterangan">
          <Input.TextArea placeholder="Keterangan" />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ModalFormPendapatan;
