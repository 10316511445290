/** @format */

import React, { useState, useEffect } from "react";
import { BreadCrumb } from "../../../components";
import moment from "moment";
import "moment/locale/id";
import type { RangePickerProps } from "antd/es/date-picker";
import { uuidv4 } from "../../../utils/helper";
import {
  Table,
  Select,
  Space,
  Input,
  Button,
  Modal,
  Form,
  Badge,
  Result,
  Steps,
  Upload,
  DatePicker,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import InputSearch from "../../../components/InputSearch";
import {
  ButtonTableApproval,
  ButtonTableDelete,
  ButtonTableDetail,
  ButtonTableEdit,
  ButtonTambah,
} from "../../../components/Button";
import { UploadOutlined, DownloadOutlined } from "@ant-design/icons";
import type { UploadProps } from "antd";
import XLSX from "xlsx";
import notifAlert from "../../../components/NotifAlert";
import { setStore } from "../../../redux/actions";
import { getMadrasah } from "../../../services/v2/usermanservice/madrasahservices";
import { ExportToExcel } from "../../../components/Export/ExportToExcel";
import {
  deleteRencanaTanggalBkba,
  editRencanaTanggalBkba,
  postRencanaTanggalBkba,
} from "../../../services/v2/planningservice/rencanatanggalbkba";
import { useHistory } from "react-router-dom";

const { RangePicker } = DatePicker;
const { Step } = Steps;
const { Dragger } = Upload;

const range = (start: number, end: number) => {
  const result: any = [];
  for (let i = start; i < end; i++) {
    result.push(i);
  }
  return result;
};

// eslint-disable-next-line arrow-body-style
const disabledDate: RangePickerProps["disabledDate"] = (current) => {
  // Can not select days before today and today
  return current && current < moment().startOf("day");
};

const disabledRangeTime: RangePickerProps["disabledTime"] = (_, type) => {
  if (type === "start") {
    return {
      // disabledHours: () => range(0, 60).splice(0, 20),
      disabledMinutes: () => range(30, 60),
      disabledSeconds: () => [55, 56],
    };
  }
  return {
    disabledHours: () => range(0, 60).splice(20, 0),
    disabledMinutes: () => range(0, 31),
    disabledSeconds: () => [55, 56],
  };
};

const TanggalRkamBKBA = () => {
  const route = useHistory();
  const itemBreadcrumb = [
    { path: "/", breadcrumbName: "Home" },
    { path: "/", breadcrumbName: "Pengaturan" },
    { path: "/", breadcrumbName: "Tanggal eRKAM BKBA" },
  ];
  const dispatch = useDispatch();
  const store = useSelector((state: any) => state.store);
  const refProvinsi = store.provinsi || [];
  const refKabkota = store.kabkota || [];

  const auths = useSelector((state: any) => state.auth);
  const auth = auths.data || [];
  const [search, setSearch] = useState<any>(null);
  const [form] = Form.useForm();
  const [id, setID] = useState("");
  const [title, setTitle] = useState<any>(null);
  const [dataForm, setDataForm] = useState<any>(null);
  const [loading, setLoading] = useState<any>(false);
  const tmpListMadrasah = store?.listMadrasah || [];
  const [fileImport, setFileImport] = useState<any>([]);
  // const [fileXLS, setFileXLS] = useState<any>(null);
  const tmpTanggalRkamBkba = store.rencanaTanggalBkba || [];
  const [selectedFileList, setSelectFileList] = useState<any>([]);
  const [dataListMadrasahBkba, setDataListMadrasahBkba] = useState<any>([]);
  const [current, setCurrent] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  let dataTable = search
    ? tmpTanggalRkamBkba.filter((item: any) => {
        const val = search.toLowerCase();
        return (
          (item.kode_jenis_tahapan !== null &&
            (item.kode_jenis_tahapan || "").toLowerCase().includes(val)) ||
          (item.keterangan !== null &&
            (item.keterangan || "").toLowerCase().includes(val))
        );
      })
    : tmpTanggalRkamBkba;

  let totalDataTable = dataTable.length;
  /**
   * Filter Pagination
   */
  dataTable = dataTable
    .sort((a, b) => (parseInt(a.activated) < parseInt(b.activated) ? 1 : -1))
    .filter((v, i) => {
      let start = pageSize * (page - 1);
      let end = start + pageSize;

      return i >= start && i < end;
    });

  const handleFileExcel = async (files: any) => {
    var reader = await new FileReader();
    let elements: any = [];
    setLoading(true);
    // setFileXLS(file.originFileObj);
    try {
      reader.onload = function (e: any) {
        var data = new Uint8Array(e.target.result);
        var workbook = XLSX.read(data, { type: "array" });
        var firstSheet = workbook.SheetNames[0];
        // eslint-disable-next-line array-callback-return
        XLSX.utils.sheet_to_json(workbook.Sheets[firstSheet]).map((el: any) => {
          elements.push(el.NSM.toString());
        });

        if (elements.length) {
          // eslint-disable-next-line array-callback-return
          const blob = new Blob([files.file], {
            type: files.file.type,
          });
          const link = window.URL.createObjectURL(blob);
          const typeTmp = ".xlsx";

          let fileTmp: any = [
            {
              status: "done",
              uid: files.file.uid,
              name: files.file.name,
              originFileObj: files.file,
              url: link,
              thumbUrl: `${link}${typeTmp}`,
            },
          ];

          setSelectFileList(fileTmp);
          setFileImport(elements);
        }
      };

      reader.readAsArrayBuffer(files.file);
    } catch (error) {
      notifAlert({ type: "error", description: "Upload file gagal!" });
    }
    setLoading(false);
  };

  useEffect(() => {
    setLoading(true);
    const tmpListMadrasahBkba =
      tmpListMadrasah.filter((item) => fileImport.includes(item.nsm)) || [];
    setDataListMadrasahBkba(tmpListMadrasahBkba);
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileImport]);

  const props: UploadProps = {
    name: "file",
    multiple: false,
    fileList: selectedFileList,
    accept: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    customRequest: handleFileExcel,
    listType: "picture",
    onChange(info: any) {
      const { status } = info.file;

      if (status !== "removed" && status !== "uploading") {
        setSelectFileList([info.file]);
      }
      if (status !== "removed") {
        setSelectFileList([info.file]);
        // handleFileUpload(info.fileList);
      }
      if (status === "done") {
        // message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === "error") {
        // message.error(`${info.file.name} file upload failed.`);
      }
    },
    beforeUpload(file: any) {
      const isExcel =
        file.type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";

      if (!isExcel) {
        notifAlert({
          type: "error",
          description: `${file.name} format yang di upload harus excel`,
        });
      }

      return isExcel || Upload.LIST_IGNORE;
    },
    onDrop(e: any): void {
      console.log("Dropped files", e.dataTransfer.files);
    },
    onRemove(file: any) {
      setSelectFileList([]);
      setFileImport([]);
    },
  };

  const steps = [
    {
      title: "Set Tanggal ERKAM",
      content: "setTanggalErkam",
    },
    {
      title: "Import Data",
      content: "import",
    },
    {
      title: "Madrasah Penerima BKBA",
      content: "proses",
    },
  ];

  const getData = async () => {
    setLoading(true);
    if (tmpListMadrasah.length === 0) {
      const madrasah = await getMadrasah("");
      const listMadrasah = madrasah || [];
      setTimeout(() => {
        dispatch(setStore({ listMadrasah }));
      }, 100);
    }
    setLoading(false);
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const tmpJenisTahapan = [
    {
      value: "penetapan indikatif",
      label: "Penetapan Indikatif",
      key: "INDIKATIF",
    },
    {
      value: "penetapan definitif",
      label: "Penetapan Definitif",
      key: "DEFINITIF",
    },
    { value: "perubahan", label: "Perubahan", key: "DEFINITIF" },
  ];
  const handleEdit = (record: any) => {
    setTitle("Edit");
    setOpenModal(true);
    setID(record.id);
    setFileImport(record?.nsm || []);
    form.setFieldsValue({
      kodeJenisTahapan: record.kode_jenis_tahapan,
      tanggal: [moment(record.start_date), moment(record.end_date)],
      keterangan: record.keterangan,
    });
  };

  const handleSelesai = async (record: any) => {
    try {
      await deleteRencanaTanggalBkba(record.id);
      let filterData = tmpTanggalRkamBkba.filter(
        (item: any) => !item.id.includes(record.id),
      );
      let payload = {
        ...record,
        activated: `0`,
      };

      const rencanaTanggalBkba = [...filterData, payload];
      notifAlert({
        type: "success",
        description: "Tanggal tahapan berhasil selesai",
      });
      setTimeout(() => {
        dispatch(setStore({ rencanaTanggalBkba }));
      }, 100);
    } catch (error) {
      console.log(error);
    }
  };

  const handleDelete = (record: any) => {
    setLoading(true);
    let listNsm = fileImport.filter((item) => !item.includes(record.nsm));
    setFileImport(listNsm);
    notifAlert({
      type: "success",
      description: "Data madrasah berhasil di hapus",
    });
    setLoading(false);
  };

  const handleTambah = () => {
    setTitle("Tambah");
    setOpenModal(true);
    setID(uuidv4());
    form.setFieldsValue({
      kodeJenisTahapan: null,
      tanggal: null,
      keterangan: null,
    });
  };

  const handleSave = async () => {
    setLoading(true);
    let statusPagu = tmpJenisTahapan.find((item: any) =>
      item.value.includes(dataForm.kodeJenisTahapan),
    );

    let payload: any = {
      id: id,
      kode_jenis_tahapan: dataForm.kodeJenisTahapan,
      keterangan: dataForm.keterangan,
      tahun: auth.isTahun,
      start_date: moment(dataForm.tanggal[0]).format("YYYY-MM-DDTHH:mm"),
      end_date: moment(dataForm.tanggal[1]).format("YYYY-MM-DDTHH:mm"),
      status_pagu: statusPagu?.key,
      expired: moment(dataForm.tanggal[1]).format("YYYY-MM-DDTHH:mm"),
      // kode_provinsi: auth.profile.kode_provinsi,
      // kode_kabkota: auth.profile.kode_kabkota,
      activated: "1",
      nsm: fileImport,
    };

    if (title === "Tambah") {
      let filterData = tmpTanggalRkamBkba.filter(
        (item: any) => item.activated === `0`,
      );
      const rencanaTanggalBkba = [...filterData, payload];
      try {
        const result = await postRencanaTanggalBkba(payload);
        if (result) {
          notifAlert({
            type: "success",
            description: "Tanggal tahapan berhasil di simpan",
          });
        }

        setTimeout(() => {
          dispatch(setStore({ rencanaTanggalBkba }));
        }, 100);
      } catch (error) {
        console.log(error);
      }
    } else {
      let filterData = tmpTanggalRkamBkba.filter(
        (item: any) => !item.id.includes(id),
      );
      const rencanaTanggalBkba = [...filterData, payload];

      try {
        const result = await editRencanaTanggalBkba(payload);
        if (result) {
          notifAlert({
            type: "success",
            description: "Tanggal tahapan berhasil di simpan",
          });
        }

        setTimeout(() => {
          dispatch(setStore({ rencanaTanggalBkba }));
        }, 100);
      } catch (error) {
        console.log(error);
      }
    }
    handleReset();
    setOpenModal(false);
    setLoading(false);
  };

  const columns: any = [
    {
      title: "Tahapan",
      dataIndex: "kode_jenis_tahapan",
      key: "kode_jenis_tahapan",
      className: "capitalize",
    },
    {
      title: "Tanggal Mulai",
      dataIndex: "start_date",
      key: "start_date",
      render: (start_date) =>
        moment(start_date).format("dddd,DD MMM YYYY HH:mm:ss"),
    },
    {
      title: "Tanggal Akhir",
      dataIndex: "end_date",
      key: "end_date",
      render: (end_date) =>
        moment(end_date).format("dddd,DD MMM YYYY HH:mm:ss"),
    },
    {
      title: "Keterangan",
      dataIndex: "keterangan",
      key: "keterangan",
    },
    {
      title: "Status",
      dataIndex: "activated",
      key: "activated",
      render: (activated) =>
        (activated === "1" && (
          <>
            <Badge status="processing" text="Aktif" />
          </>
        )) || (
          <>
            <Badge status="success" text="Selesai" />
          </>
        ),
    },
    {
      title: "Aksi",
      key: "aksi",
      render: (record: any) =>
        record.activated === "1" && (
          <Space size="small">
            <ButtonTableDetail
              tooltips="List Madrasah"
              onClick={() =>
                route.push({
                  pathname: "/pengaturan/tanggal-rkam-bkba/list",
                  state: {
                    ...record,
                  },
                })
              }
            />
            <ButtonTableEdit onClick={() => handleEdit(record)} />
            <ButtonTableApproval
              onClick={() => {
                Modal.confirm({
                  title: "Perhatian",
                  content: "Tetapkan Status Menjadi Selesai?",
                  onOk() {
                    return handleSelesai(record);
                  },
                  onCancel() {},
                  okText: "Selesai",
                  cancelText: "Batal",
                  okType: "danger",
                });
              }}
            />
          </Space>
        ),
    },
  ];

  const columnsList: any = [
    {
      title: "NSM",
      dataIndex: "nsm",
      key: "nsm",
    },
    {
      title: "Madrasah",
      dataIndex: "nama",
      key: "nama",
    },
    {
      title: "Jenjang",
      key: "jenjang",
      render: (record) => record.jenjang.nama,
    },
    {
      title: "Provinsi",
      dataIndex: "kode_provinsi",
      key: "kode_provinsi",
      render: (kode_provinsi) =>
        refProvinsi
          .filter((item) => item.kode === kode_provinsi)
          .map((item) => item.nama),
    },
    {
      title: "Kab / Kota",
      dataIndex: "kode_kabkota",
      key: "kode_kabkota",
      render: (kode_kabkota) =>
        refKabkota
          .filter((item) => item.kode === kode_kabkota)
          .map((item) => item.nama),
    },
    {
      title: "Aksi",
      key: "aksi",
      render: (record: any) => (
        <Space size="small">
          <ButtonTableDelete
            loading={loading}
            onClick={() => {
              Modal.confirm({
                title: "Perhatian",
                content: "Anda akan hapus data ini?",
                onOk() {
                  return handleDelete(record);
                },
                onCancel() {},
                okText: "Hapus",
                cancelText: "Batal",
                okType: "danger",
              });
            }}
          />
        </Space>
      ),
    },
  ];

  // const expandedRowDetail = (data: any) => {
  //   let dataListMadrasah: any = data.listMadrasah.length
  //     ? tmpListMadrasah.filter((item) => data?.listMadrasah.includes(item.nsm))
  //     : [];
  //   const columnsDetail: any = [
  //     {
  //       title: "No",
  //       key: "no",
  //       width: 50,
  //       render: (_, record, i: number) => i + 1,
  //     },
  //     {
  //       title: "NSM",
  //       key: "nsm",
  //       dataIndex: "nsm",
  //       width: 100,
  //     },
  //     {
  //       title: "Madrasah",
  //       key: "nama",
  //       dataIndex: "nama",
  //       width: 250,
  //     },
  //     {
  //       title: "Jenjang",
  //       key: "jenjang",
  //       width: 250,
  //       render: (record) => record.jenjang.nama,
  //     },
  //     {
  //       title: "Provinsi",
  //       dataIndex: "kode_provinsi",
  //       key: "kode_provinsi",
  //       width: 200,
  //       render: (kode_provinsi) =>
  //         refProvinsi
  //           .filter((item) => item.kode === kode_provinsi)
  //           .map((item) => item.nama),
  //     },
  //     {
  //       title: "Kab / Kota",
  //       dataIndex: "kode_kabkota",
  //       key: "kode_kabkota",
  //       width: 200,
  //       render: (kode_kabkota) =>
  //         refKabkota
  //           .filter((item) => item.kode === kode_kabkota)
  //           .map((item) => item.nama),
  //     },
  //     {
  //       title: "Aksi",
  //       key: "aksi",
  //       render: (record: any) =>
  //         record.activated === "1" && (
  //           <Space size="small">
  //             <ButtonTableDelete
  //               onClick={() => {
  //                 Modal.confirm({
  //                   title: "Perhatian",
  //                   content: "Anda yakin akan menghapus data madrasah ini?",
  //                   onOk() {
  //                     //   return handleSelesai(record);
  //                   },
  //                   onCancel() {},
  //                   okText: "Hapus",
  //                   cancelText: "Batal",
  //                   okType: "danger",
  //                 });
  //               }}
  //             />
  //           </Space>
  //         ),
  //     },
  //   ];

  //   return (
  //     <Table
  //       columns={columnsDetail}
  //       dataSource={dataListMadrasah}
  //       pagination={false}
  //       bordered
  //     />
  //   );
  // };

  const handleExport = () => {
    try {
      let xls = tmpListMadrasah.map((el: any) => {
        const prov =
          refProvinsi.find((item) => item.kode === el.kode_provinsi) || null;
        const kab = refKabkota.find((item) => item.kode === el.kode_kabkota);
        return {
          NSM: el.nsm,
          MADRASAH: el.nama,
          JENJANG: el?.jenjang?.nama,
          PROVINSI: prov?.nama || "",
          KABUPATEN: kab?.nama || "",
        };
      });
      ExportToExcel(xls, "referensi-madrasah");

      notifAlert({
        type: "success",
        description: "Data berhasil di export",
      });
    } catch (error) {
      notifAlert({
        type: "error",
        description: "Data Gagal di export",
      });
    }
  };
  const footerModal = (
    <div className="steps-action">
      {current > 0 && current < 3 && (
        <Button
          loading={loading}
          style={{ margin: "0 8px" }}
          onClick={() => prev()}>
          Kembali
        </Button>
      )}
      {current === 1 && (
        <Button
          type="primary"
          loading={loading}
          onClick={() => next()}
          disabled={
            current === 1
              ? dataListMadrasahBkba.length >= 1
                ? false
                : true
              : false
          }>
          Lanjut
        </Button>
      )}
      {current <= 0 && (
        <Button type="primary" loading={loading} onClick={() => form.submit()}>
          Lanjut
        </Button>
      )}
      {current === 2 && (
        <Button
          type="primary"
          loading={loading}
          onClick={() => handleSave()}
          disabled={dataListMadrasahBkba.length ? false : true}>
          Simpan
        </Button>
      )}
    </div>
  );

  const handleReset = () => {
    setCurrent(0);
    setSelectFileList([]);
    setFileImport([]);
    setDataForm(null);
  };

  const handleSubmit = (values) => {
    setDataForm(values);
    next();
  };

  const next = () => {
    setLoading(true);
    setCurrent(current + 1);
  };

  const prev = () => {
    setLoading(true);
    setCurrent(current - 1);
  };

  useEffect(() => {
    setLoading(false);
  }, [current]);

  return (
    <>
      <BreadCrumb routes={itemBreadcrumb} title="Tanggal Input eRKAM BKBA" />
      <div className="m-5 p-5 bg-white shadow-md rounded">
        <div className="mb-4 flex justify-end">
          <div className="mr-4">
            <ButtonTambah loading={loading} onClick={handleTambah} />
          </div>
          <InputSearch
            className="w-3/4"
            onChange={(e: any) => setSearch(e.currentTarget.value)}
          />
        </div>

        <div className="w-full">
          <Table
            loading={loading}
            rowKey={(record) => record.id}
            // expandable={{
            //   expandedRowRender: (record) => expandedRowDetail(record),
            // }}
            columns={columns}
            tableLayout="auto"
            dataSource={dataTable}
            bordered
            pagination={{
              total: totalDataTable,
              position: ["bottomRight"],
              defaultPageSize: pageSize,
              defaultCurrent: page,
              showTotal: (total) => `Total ${total} items`,
              onChange(page, pageSize) {
                setPage(page);
                setPageSize(pageSize);
              },
            }}
          />
        </div>
      </div>
      <Modal
        open={openModal}
        width={1200}
        title={`${title} Tanggal RKAM BKBA`}
        onCancel={() => setOpenModal(!openModal)}
        footer={footerModal}>
        <Steps current={current} size="small">
          {steps.map((item) => (
            <Step key={item.title} title={item.title} />
          ))}
        </Steps>
        <div className="my-4">
          {(steps[current].content === "setTanggalErkam" && (
            <Form
              form={form}
              key="formTanggalRkam"
              name="formTanggalRkam"
              layout="vertical"
              onFinish={handleSubmit}>
              <Form.Item
                label="Tahapan"
                name="kodeJenisTahapan"
                rules={[
                  {
                    required: true,
                    message: "Sumber Dana tidak boleh kosong!",
                  },
                ]}>
                <Select
                  showSearch
                  optionFilterProp="children"
                  placeholder="Pilih Tahapan">
                  {tmpJenisTahapan?.length &&
                    tmpJenisTahapan.map((e: any, i: any) => {
                      return (
                        <Select.Option key={`role${i}`} value={e.value}>
                          {e.label}
                        </Select.Option>
                      );
                    })}
                </Select>
              </Form.Item>
              <Form.Item
                label="Tanggal Mulai dan Akhir"
                name="tanggal"
                rules={[
                  {
                    required: true,
                    message:
                      "Tanggal Mulai dan Tanggal Akhir tidak boleh kosong!",
                  },
                ]}>
                <RangePicker
                  style={{ width: "100%" }}
                  disabledDate={disabledDate}
                  disabledTime={disabledRangeTime}
                  placeholder={["Pilih Tanggal Mulai", "Pilih Tanggal Selesai"]}
                  showTime={{
                    hideDisabledOptions: true,
                    defaultValue: [
                      moment("00:00:00", "HH:mm:ss"),
                      moment("11:59:59", "HH:mm:ss"),
                    ],
                  }}
                  format="YYYY-MM-DD HH:mm:ss"
                />
              </Form.Item>
              <Form.Item label="Keterangan" name="keterangan">
                <Input.TextArea placeholder="Keterangan" />
              </Form.Item>
            </Form>
          )) ||
            (steps[current].content === "import" && (
              <div className="flex flex-row mb-10">
                <div className="flex-1">
                  <Result
                    title="Informasi"
                    subTitle="Download terlebih dahulu file contoh template .excel"
                    extra={
                      <Button
                        key="console"
                        icon={<DownloadOutlined />}
                        onClick={handleExport}>
                        Download
                      </Button>
                    }
                  />
                </div>
                <div className="flex-1 p-4">
                  <Dragger {...props}>
                    <p className="ant-upload-drag-icon">
                      <UploadOutlined />
                    </p>
                    <p className="ant-upload-text">
                      Klik atau tarik File Excel
                    </p>
                    <p className="ant-upload-hint">
                      Format file .excel yang akan di upload harus sesuai dengan
                      file template hasil export.
                    </p>
                  </Dragger>
                </div>
              </div>
            )) ||
            (steps[current].content === "proses" && (
              <Table
                rowKey={(record) => record.nsm}
                dataSource={dataListMadrasahBkba}
                columns={columnsList}
                bordered
              />
            ))}
        </div>
      </Modal>
    </>
  );
};

export default TanggalRkamBKBA;
