/** @format */
import React, { useState, useEffect } from "react";
import moment from "moment";
import "moment/locale/id";
import { Alert } from "antd";
import { toTitleCase } from "../../utils/helper";

type notifMessageParams = {
  tanggalErkam: any;
  tanggalBkba: any;
  module: string;
  setAction: (action: any) => void;
};

export const NotifMessage = (params: notifMessageParams) => {
  const [title, setTitle] = useState<any>(null);
  const [description, setDescription] = useState<any>(null);
  const [status, setStatus] = useState<any>(false);
  const erkamDate: any = params?.tanggalErkam || [];
  const bkbaDate: any = params?.tanggalBkba || [];
  // let module =
  //   (params.module === "pendapatanIndikatif" && "Pendapatan") ||
  //   (params.module === "pendapatanDefinitif" && "Pendapatan") ||
  //   (params.module === "belanjaDefinitif" && "Belanja") ||
  //   (params.module === "belanjaIndikatif" && "Belanja");

  useEffect(() => {
    let listDataTanggal: any = {
      statusBos: false,
      tahapanBos: null,
      countdownBos: null,
      paguBos: null,
      statusBkba: false,
      tahapanBkba: null,
      countdownBkba: null,
      paguBkba: null,
    };
    if (erkamDate.length || bkbaDate.length) {
      if (
        moment().isBefore(erkamDate[0]?.end_date) &&
        moment().isBefore(bkbaDate[0]?.end_date)
      ) {
        listDataTanggal = {
          statusBos: true,
          tahapanBos: erkamDate[0]?.kode_jenis_tahapan,
          paguBos: erkamDate[0]?.status_pagu,
          countdownBos: countdownText(erkamDate[0]),
          statusBkba: true,
          tahapanBkba: bkbaDate[0]?.kode_jenis_tahapan,
          countdownBkba: countdownText(bkbaDate[0]),
          paguBkba: bkbaDate[0]?.status_pagu,
        };
      } else if (moment().isBefore(erkamDate[0]?.end_date)) {
        listDataTanggal = {
          statusBos: true,
          tahapanBos: erkamDate[0]?.kode_jenis_tahapan,
          countdownBos: countdownText(erkamDate[0]),
          paguBos: erkamDate[0]?.status_pagu,
          statusBkba: false,
          tahapanBkba: null,
          countdownBkba: null,
          paguBkba: null,
        };
      } else if (moment().isBefore(bkbaDate[0]?.end_date)) {
        listDataTanggal = {
          statusBos: false,
          tahapanBos: null,
          countdownBos: null,
          paguBos: null,
          statusBkba: true,
          tahapanBkba: bkbaDate[0]?.kode_jenis_tahapan,
          countdownBkba: countdownText(bkbaDate[0]),
          paguBkba: bkbaDate[0]?.status_pagu,
        };
      }
    }
    checkStatus(listDataTanggal, params.module);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  const countdownText = (dataTanggal: any) => {
    let timeStamp = (endDate: any) =>
      moment(endDate).toDate().getTime() - moment().toDate().getTime();
    let isTime = moment.duration(timeStamp(dataTanggal?.end_date));
    const timeUnits = ["years", "months", "days", "hours", "minutes"];
    const waktu = ["tahun", "bulan", "hari", "jam", "menit"];

    let textDown: any = timeUnits.reduce((text, unit, index) => {
      const unitValue = isTime[unit]();
      return unitValue > 0 ? text + unitValue + ` ${waktu[index]} ` : text;
    }, "");

    return textDown;
  };
  const checkStatus = (dataTanggal: any, module: any) => {
    let titleModule =
      (module === "pendapatanIndikatif" && "Pendapatan") ||
      (module === "pendapatanDefinitif" && "Pendapatan") ||
      (module === "belanjaDefinitif" && "Belanja") ||
      (module === "belanjaIndikatif" && "Belanja");

    if (module === "pendapatanIndikatif" || module === "belanjaIndikatif") {
      params.setAction(true);
      setTitle(`Penetapan ${titleModule} Indikatif`);
      if (dataTanggal.statusBos || dataTanggal.statusBkba) {
        if (
          dataTanggal.statusBos &&
          dataTanggal.statusBkba &&
          dataTanggal.tahapanBos === "penetapan indikatif" &&
          dataTanggal.tahapanBkba === "penetapan indikatif"
        ) {
          params.setAction(false);
          setStatus(true);
          setDescription(
            <div>
              <div>
                Penetapan Indikatif untuk <strong>BOS Reguler</strong> akan
                berakhir{` ${dataTanggal.countdownBos}  `}
              </div>
              <div>
                dan <strong>BKBA </strong>
                {` akan berakhir ${dataTanggal.countdownBkba}`}
              </div>
            </div>,
          );
        } else if (
          dataTanggal.statusBos &&
          dataTanggal.tahapanBos === "penetapan indikatif"
        ) {
          params.setAction(false);
          setStatus(true);
          setDescription(
            <div>
              <div>
                Penetapan Indikatif untuk <strong>BOS Reguler</strong> akan
                berakhir{` ${dataTanggal.countdownBos}  `} lagi
              </div>
            </div>,
          );
        } else if (
          dataTanggal.statusBkba &&
          dataTanggal?.tahapanBkba?.toString() === "penetapan indikatif"
        ) {
          params.setAction(false);
          setStatus(true);
          setDescription(
            <div>
              <div>
                Penetapan Indikatif untuk <strong>BKBA</strong> akan berakhir
                {` ${dataTanggal.countdownBkba}  `} lagi
              </div>
            </div>,
          );
        } else {
          params.setAction(true);
          setStatus(false);
          setDescription("Penetapan Pendapatan Indikatif telah berakhir");
        }
        // console.log(dataTanggal);
      } else {
        setTitle(`Penetapan ${titleModule} Indikatif`);
        setDescription("Penetapan Pendapatan Indikatif telah berakhir");
        setStatus(false);
        params.setAction(true);
      }

      // description = "Penetapan Pendapatan Indikatif telah berakhir";
    }

    if (module === "pendapatanDefinitif" || module === "belanjaDefinitif") {
      params.setAction(true);
      if (dataTanggal?.statusBos || dataTanggal?.statusBkba) {
        setTitle(`Penetapan ${titleModule} Definitif`);

        if (
          dataTanggal?.statusBos &&
          dataTanggal?.statusBkba &&
          dataTanggal?.tahapanBos?.toString() === "penetapan definitif" &&
          dataTanggal?.tahapanBkba?.toString() === "penetapan definitif"
        ) {
          params.setAction(false);
          setStatus(true);
          setDescription(
            <div>
              <div>
                Penetapan {` ${dataTanggal?.paguBos}  `} untuk{" "}
                <strong>BOS Reguler</strong> akan berakhir
                {` ${dataTanggal?.countdownBos}  `}
              </div>
              <div>
                dan <strong>BKBA </strong>
                {` akan berakhir ${dataTanggal?.countdownBkba}`}
              </div>
            </div>,
          );
        } else if (
          dataTanggal?.statusBos &&
          dataTanggal?.tahapanBos?.toString() === "penetapan definitif"
        ) {
          params.setAction(false);
          setStatus(true);
          setDescription(
            <div>
              <div>
                Penetapan {` ${dataTanggal?.paguBos}  `} untuk{" "}
                <strong>BOS Reguler</strong> akan berakhir
                {` ${dataTanggal?.countdownBos}  `} lagi
              </div>
            </div>,
          );
        } else if (
          dataTanggal?.statusBkba &&
          dataTanggal?.tahapanBkba?.toString() === "penetapan definitif"
        ) {
          params.setAction(false);
          setStatus(true);
          setDescription(
            <div>
              <div>
                Penetapan {` ${toTitleCase(dataTanggal?.paguBkba)}  `} untuk{" "}
                <strong>BKBA</strong> akan berakhir
                {` ${dataTanggal?.countdownBkba}  `} lagi
              </div>
            </div>,
          );
        } else {
          params.setAction(true);
          setStatus(false);
          setDescription("Penetapan Pendapatan Definitif telah berakhir");
        }
      } else {
        setTitle(`Penetapan ${titleModule} Definitif`);
        setDescription("Penetapan Pendapatan Definitif telah berakhir");
        setStatus(false);
      }
    }
  };

  return (
    <>
      <Alert
        message={title || "Loading"}
        description={description || "Please Wait.."}
        type={status ? "success" : "info" || "info"}
        showIcon
      />
    </>
  );
};
