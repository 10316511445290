/** @format */

import React, { useState, useEffect } from "react";
import {
  Select,
  Input,
  Form,
  DatePicker,
  InputNumber,
  Modal,
  Divider,
  Descriptions,
} from "antd";
import type { RangePickerProps } from "antd/es/date-picker";
import moment from "moment";
import "moment/locale/id";
import { formatRupiah } from "../../../../utils/helper";
import { ButtonUploads } from "../../../../components/Uploads";

type FormActionType = {
  openModal: boolean;
  title: string;
  form: any;
  store: any;
  dataEdit: any;
  files: any;
  confirmLoading: boolean;
  handleFileUpload: (e: any) => void;
  handleSave: (e: any) => void;
  handleClose: () => void;
};

const ModalPindahBuku = ({
  openModal,
  title,
  store,
  form,
  dataEdit,
  files,
  confirmLoading,
  handleFileUpload,
  handleSave,
  handleClose,
}: FormActionType) => {
  const tmpRekeningBelanja = store.rekeningBelanja || [];
  const tmpTipeKas = store.tipeKas || [];
  const tmpSumberDana = store.rencanaPendapatanDefinitif || [];
  const tmpPendapatan = store.realisasiPendapatan || [];
  const tmpPindahBuku = store.realisasiPindahBuku || [];
  const tmpPendapatanHeader = store.realisasiPendapatanHeader || null;
  // const [buttonEnabled, setButtonEnabled] = useState<any>(true);
  const [checkTipeKas, setCheckTipeKas] = useState<any>(false);
  const [checkNoRek, setCheckNoRek] = useState<any>(null);
  const [totalPendapatan, setTotalPendapatan] = useState<any>(0);
  const [totalPengeluaran, setTotalPengeluaran] = useState<any>(0);
  const [enabledRekening, setEnabledRekening] = useState<any>(false);
  const [validUpload, setValidUpload] = useState<any>([]);
  const [errorUpload, setErrorUpload] = useState<any>(null);
  const [errorSelect, setErrorSelect] = useState<any>("");
  const errorSumberDana = (
    <span style={{ color: "red" }}>
      {errorSelect === "0"
        ? ""
        : errorSelect > 0 || errorSelect === ""
        ? ""
        : "Sumber Dana Telah Mencapai Maksimum"}
    </span>
  );
  const [enabledRekeningTujuan, setEnabledRekeningTujuan] =
    useState<any>(false);
  let allFilter: any = { sumberDanaID: [], tipeKasID: [], rekeningID: [] };
  tmpPendapatan &&
    tmpPendapatan
      .filter((item) => item.kepalaMadrasahApproved === "True")
      // eslint-disable-next-line array-callback-return
      .map((item: any) => {
        item.kodeTipeKas && allFilter[`tipeKasID`].push(item.kodeTipeKas);
        item.rekeningBankId &&
          allFilter[`rekeningID`].push(item.rekeningBankId);
        item.rencanaPendapatanKode &&
          allFilter[`sumberDanaID`].push(item.rencanaPendapatanKode);
      });

  const getTotalData = (values: any) => {
    let tmpDataPendapatan: any = null;
    let totalSisa = 0;
    let totalBelumSelesai = 0;
    let statusTmp = ["Selesai", "Tidak Disetujui"];
    setCheckNoRek(values?.noRekening || null);
    setCheckTipeKas(values.tipeKas === "tunai" ? true : false);

    if (values.tipeKas === "rekening_bank") {
      tmpDataPendapatan =
        (values.tipeKas &&
          values.sumberDana &&
          values?.noRekening &&
          tmpPendapatanHeader &&
          tmpPendapatanHeader.header3.find(
            (item) =>
              item.kode_tipe_kas === values.tipeKas &&
              item.rekening_bank_id === values?.noRekening,
          )) ||
        null;
      tmpPindahBuku
        .filter(
          (items: any) =>
            items.kodeTipeKas === values.tipeKas &&
            items.rekeningBankId === values?.noRekening &&
            !statusTmp.includes(items.status) &&
            items.rencanaPendapatanKode === values.sumberDana,
        )
        // eslint-disable-next-line array-callback-return
        .map((items: any) => {
          totalBelumSelesai += items.grandTotal;
        });
    } else {
      tmpDataPendapatan =
        (values.tipeKas &&
          values.sumberDana &&
          tmpPendapatanHeader &&
          tmpPendapatanHeader.header3.find(
            (item) => item.kode_tipe_kas === values.tipeKas,
          )) ||
        null;
      tmpPindahBuku
        .filter(
          (items: any) =>
            items.kodeTipeKas === values.tipeKas &&
            !statusTmp.includes(items.status) &&
            items.rencanaPendapatanKode === values.sumberDana,
        )
        // eslint-disable-next-line array-callback-return
        .map((items: any) => {
          totalBelumSelesai += items.grandTotal;
        });
    }
    tmpDataPendapatan &&
      tmpDataPendapatan.details
        .filter((items) => items.sumber_dana.includes(values.sumberDana))
        .map((items) => (totalSisa = items.sisa));

    if (title === "Edit") {
      if (dataEdit?.status === "Selesai") {
        totalSisa = totalSisa + dataEdit?.grandTotal - totalBelumSelesai;
      } else {
        let tmpBelumSelesai = 0;

        tmpPindahBuku
          .filter(
            (items: any) =>
              items.kodeTipeKas === values.tipeKas &&
              items.rekeningBankId === values?.noRekening &&
              !statusTmp.includes(items.status) &&
              !items.id.includes(dataEdit?.id) &&
              items.rencanaPendapatanKode === values.sumberDana,
          )
          // eslint-disable-next-line array-callback-return
          .map((items: any) => {
            tmpBelumSelesai += items.grandTotal;
          });

        totalSisa = totalSisa - tmpBelumSelesai;
      }
    } else {
      totalSisa = totalSisa - totalBelumSelesai;
    }

    setTotalPendapatan(totalSisa);
  };

  const handleChangeField = (values: any) => {
    for (let obj in values) {
      switch (obj) {
        case "sumberDana":
          getTotalData(values);
          break;
        case "tipeKas":
          getTotalData(values);
          setEnabledRekening(values.tipeKas === "rekening_bank" ? true : false);
          break;
        case "tipeKasTujuan":
          setEnabledRekeningTujuan(
            values.tipeKasTujuan === "rekening_bank" ? true : false,
          );
          break;
        case "jumlah":
          setTotalPengeluaran((values.jumlah && values.jumlah) || 0);
          break;
        // case "files":
        //   setValidUpload(validUpload);
        //   break;
      }
    }
  };

  const handleChange = (_, values: any) => {
    // getDanaSumber(values);
    handleChangeField(values);
  };

  useEffect(() => {
    const values = form.getFieldsValue();

    getTotalData(values);
    handleChangeField(values);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.getFieldsValue(), dataEdit, title]);

  useEffect(() => {
    setValidUpload(files);
  }, [files]);

  const disabledDate: RangePickerProps["disabledDate"] = (current) => {
    const formValue = form.getFieldsValue();
    if (title === "Edit") {
      setErrorSelect("");
    }

    if (totalPendapatan >= 0) {
      const tanggalRealisasi = tmpPendapatan
        .sort(
          (a, b) =>
            moment(a.tanggalRealisasi).valueOf() -
            moment(b.tanggalRealisasi).valueOf(),
        )

        .filter(
          (item) =>
            item.rencanaPendapatanKode.includes(formValue.sumberDana) &&
            item.kodeTipeKas.includes(formValue.tipeKas),
        )
        .map((item) => item.tanggalRealisasi);
      return (
        tanggalRealisasi[0] &&
        moment(current).isBefore(tanggalRealisasi[0], "day")
      );
    }

    // Can not select days before today and today
    // if (title === "Edit") {
    // }
    return current && current < moment().startOf("day");
  };

  const handleSaveForm = (values: any) => {
    setErrorSelect("");
    let sumberData: any =
      tmpSumberDana.find(
        (item) => item.kode_sumber_dana === values.sumberDana,
      ) || null;

    let namaRekeningTujuan: any =
      values.tipeKasTujuan === "rekening_bank"
        ? tmpRekeningBelanja.find((item) =>
            item.id.includes(values?.noRekeningTujuan),
          )
        : null;
    let namaRekening: any =
      values.tipeKas === "rekening_bank"
        ? tmpRekeningBelanja.find((item) =>
            item.id.includes(values?.noRekening),
          )
        : null;

    let tipeKasNama: any =
      tmpTipeKas.find((item) => item.kode === values.tipeKas) || null;
    let tipeKasNamaTujuan: any =
      tmpTipeKas.find((item) => item.kode === values.tipeKasTujuan) || null;

    const payload = {
      rencanaPendapatanId: sumberData && sumberData.id,
      kodeTipeKas: values.tipeKas,
      namaTipeKas: tipeKasNama?.nama || null,
      rekeningBankId:
        values.tipeKas === "rekening_bank" ? values?.noRekening : null,
      namaRekeningBank: namaRekening?.no_rekening_nama || null,
      toKodeTipeKas: values.tipeKasTujuan,
      namaToTipeKas: tipeKasNamaTujuan?.nama || null,
      toRekeningBankId:
        values.tipeKasTujuan === "rekening_bank"
          ? values?.noRekeningTujuan
          : null,
      namaToRekeningBank: namaRekeningTujuan?.no_rekening_nama || null,
      keterangan: values.keterangan || null,
      tanggalNota: moment(values.tanggalNota).format("YYYY-MM-DD HH:mm:ss"),
      rencanaPendapatanKode:
        (sumberData && sumberData.kode_sumber_dana) || null,
      rencanaPendapatanName:
        (sumberData && sumberData.nama_sumber_dana) || null,
      grandTotal: values.jumlah,
    };
    setTotalPendapatan(0);
    setTotalPengeluaran(0);
    handleSave(payload);
  };

  const handleCloseForm = () => {
    setTotalPendapatan(0);
    setTotalPengeluaran(0);
    setErrorSelect("");
    handleClose();
  };

  const checkValidationSaldo = (saldoPendapatan, saldoPengeluaran) => {
    if (saldoPendapatan < saldoPengeluaran) {
      return Promise.reject("Saldo dari Sumber Dana tidak mencukupi!");
    } else if (saldoPengeluaran <= 0) {
      return Promise.reject("Jumlah Saldo tidak boleh Rp. 0");
    } else {
      return Promise.resolve();
    }
  };

  const checkFiles = (values: any) => {
    let messageTmp: any = { status: "success", messages: "" };
    if (!values.length) {
      setErrorUpload({
        status: "error",
        messages: "Maaf dokumen tidak boleh kosong!",
      });
      return Promise.reject("");
    } else {
      setErrorUpload(messageTmp);
      return Promise.resolve();
    }
  };

  const validationAfterUpload = (values: any) => {
    handleFileUpload(values);
    setValidUpload(values);
    checkFiles(values);
    form.setFieldsValue({ files: values });
  };

  return (
    <Modal
      key="modalPindahBuku"
      open={openModal}
      title={`${title} Nota Pindah Buku`}
      width={800}
      onCancel={handleCloseForm}
      //okButtonProps={{ disabled: buttonEnabled }}
      okText="Simpan"
      confirmLoading={confirmLoading}
      onOk={() => form.submit()}>
      <Form
        form={form}
        key="modalFormPindahBuku"
        layout="vertical"
        onValuesChange={handleChange}
        // onChange={() => getDanaSumber(form.getFieldsValue().sumberDana)}
        onFinish={handleSaveForm}>
        {title === "Edit" ? "" : errorSumberDana}
        <div className="grid grid-cols-1   md:grid-cols-2 lg:grid-cols-2 gap-2">
          <div>
            <Form.Item
              label={`Sumber Dana`}
              name="sumberDana"
              rules={[
                {
                  required: true,
                  message: "Sumber Dana tidak boleh kosong!",
                },
              ]}>
              <Select
                allowClear
                showSearch
                optionFilterProp="children"
                placeholder="Pilih Sumber Dana">
                {tmpSumberDana.length &&
                  tmpSumberDana
                    .filter((item) =>
                      allFilter.sumberDanaID.includes(item.kode_sumber_dana),
                    )
                    .map((item: any, i: any) => {
                      return (
                        <Select.Option
                          key={`sp${i}`}
                          value={item.kode_sumber_dana}>
                          {item.nama_sumber_dana}
                        </Select.Option>
                      );
                    })}
              </Select>
            </Form.Item>
          </div>
          <div>
            <Form.Item
              label="Tipe Kas"
              name="tipeKas"
              rules={[
                {
                  required: true,
                  message: "Tipe Kas tidak boleh kosong!",
                },
              ]}>
              <Select
                allowClear
                showSearch
                optionFilterProp="children"
                placeholder="Pilih Tipe Kas">
                {tmpTipeKas.length &&
                  tmpTipeKas
                    .filter((item) => allFilter.tipeKasID.includes(item.kode))
                    .map((item: any, i: any) => {
                      return (
                        <Select.Option key={`tipekas${i}`} value={item.kode}>
                          {item.nama}
                        </Select.Option>
                      );
                    })}
              </Select>
            </Form.Item>
          </div>
          {enabledRekening && (
            <div>
              <Form.Item
                label="No Rekening"
                name="noRekening"
                rules={[
                  {
                    required: true,
                    message: "No Rekening tidak boleh kosong!",
                  },
                ]}>
                <Select
                  allowClear
                  showSearch
                  optionFilterProp="children"
                  placeholder="Pilih No Rekening">
                  {tmpRekeningBelanja.length &&
                    tmpRekeningBelanja
                      .filter((item) => allFilter.rekeningID.includes(item.id))
                      .map((item: any, i: any) => {
                        return (
                          <Select.Option key={`noRek${i}`} value={item.id}>
                            {item.nama_bank} - {item.no_rekening}
                          </Select.Option>
                        );
                      })}
                </Select>
              </Form.Item>
            </div>
          )}
          <div>
            <Form.Item
              label="Tipe Kas Tujuan"
              name="tipeKasTujuan"
              rules={[
                {
                  required: true,
                  message: "Tipe Kas Tujuan tidak boleh kosong!",
                },
              ]}>
              <Select
                allowClear
                showSearch
                optionFilterProp="children"
                placeholder="Pilih Tipe Kas">
                {tmpTipeKas.length &&
                  tmpTipeKas
                    .filter((item: any) => {
                      // return true;
                      if (checkTipeKas) {
                        return item.kode !== "tunai";
                      }
                      return true;
                    })
                    .map((item: any, i: any) => {
                      return (
                        <Select.Option
                          key={`tipeKasTujuan${i}`}
                          value={item.kode}>
                          {item.nama}
                        </Select.Option>
                      );
                    })}
              </Select>
            </Form.Item>
          </div>
          {enabledRekeningTujuan && (
            <div>
              <Form.Item
                label="No Rekening Tujuan"
                name="noRekeningTujuan"
                rules={[
                  {
                    required: true,
                    message: "No Rekening Tujuan tidak boleh kosong!",
                  },
                ]}>
                <Select
                  allowClear
                  showSearch
                  optionFilterProp="children"
                  placeholder="Pilih No Rekening">
                  {tmpRekeningBelanja.length &&
                    tmpRekeningBelanja
                      .filter((item) => {
                        if (checkNoRek) {
                          return item.id !== checkNoRek;
                        }
                        return true;
                      })
                      .map((e: any, i: any) => {
                        return (
                          <Select.Option key={`noRekTu${i}`} value={e.id}>
                            {e.nama_bank} - {e.no_rekening}
                          </Select.Option>
                        );
                      })}
                </Select>
              </Form.Item>
            </div>
          )}
          <div>
            <Form.Item
              label="Tanggal Nota"
              name="tanggalNota"
              rules={[
                {
                  required: true,
                  message: "Tanggal Nota tidak boleh kosong!",
                },
              ]}>
              <DatePicker disabledDate={disabledDate} showTime />
            </Form.Item>
          </div>
          <div>
            <Form.Item
              label="Jumlah"
              name="jumlah"
              rules={[
                {
                  required: true,
                  message: "Jumlah tidak boleh kosong!",
                },
                {
                  validator: () =>
                    checkValidationSaldo(totalPendapatan, totalPengeluaran),
                },
              ]}>
              <InputNumber
                style={{ width: "100%" }}
                min={0}
                max={totalPendapatan}
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                prefix="Rp."
                placeholder="Jumlah"
              />
            </Form.Item>
          </div>
          <div>
            <Form.Item label="Keterangan" name="keterangan">
              <Input.TextArea placeholder="Keterangan" />
            </Form.Item>
          </div>
        </div>
        <Form.Item
          label="Upload Dokumen"
          required
          name="files"
          validateStatus={errorUpload?.status}
          hasFeedback
          help={errorUpload?.messages}
          rules={[
            {
              validator: () => checkFiles(validUpload),
            },
          ]}>
          <ButtonUploads
            files={files}
            handleFileUpload={validationAfterUpload}
          />
        </Form.Item>
      </Form>
      <Divider />
      <div className="flex justify-start md:justify-end gap-4">
        <div className="md:w-1/2">
          <Descriptions
            column={1}
            contentStyle={{
              fontWeight: 600,
              justifyContent: "end",
              fontSize: 18,
            }}>
            <Descriptions.Item
              label={`Total Penerimaan ${
                enabledRekening ? "Bank" : "Tunai"
              } di BKU`}>
              {formatRupiah(totalPendapatan)}
            </Descriptions.Item>
            <Descriptions.Item label="Total Pindah Buku">
              {formatRupiah(totalPengeluaran)}
            </Descriptions.Item>
            <Descriptions.Item
              label={`Saldo ${enabledRekening ? "Bank" : "Tunai"} di BKU`}>
              {formatRupiah(totalPendapatan - totalPengeluaran)}
            </Descriptions.Item>
          </Descriptions>
        </div>
      </div>
    </Modal>
  );
};
export default ModalPindahBuku;
