/** @format */

import { Modal, Button } from "antd";
type generateModal = {
  openModalPriview: boolean;
  pdfFile: any;
  onClose: () => void;
};
const ModalLihatDokumenBos = ({
  openModalPriview,
  pdfFile,
  onClose,
}: generateModal) => {
  return (
    <>
      <Modal
        open={openModalPriview}
        title={`Lihat Dokumen`}
        width={1200}
        onCancel={onClose}
        footer={[
          <Button key="back" onClick={onClose}>
            Kembali
          </Button>,
        ]}>
        <object
          aria-labelledby="rkam"
          data={pdfFile}
          // type="application/pdf"
          width="100%"
          height="400px"></object>
      </Modal>
    </>
  );
};
export default ModalLihatDokumenBos;
