/** @format */
import React, { useState, useEffect } from "react";
import { CloudUploadOutlined } from "@ant-design/icons";
import type { UploadProps } from "antd";
import { Upload, Modal } from "antd";
import notifAlert from "../NotifAlert";
import { deleteUploadNotaRealisasi } from "../../services/v2/realizationservice";

const { Dragger } = Upload;
type uploadsType = {
  files: any;
  handleFileUpload: (e: any) => void;
};
export const ButtonUploads = ({ files, handleFileUpload }: uploadsType) => {
  const [fileList, setFileList] = useState<any>([]);

  useEffect(() => {
    let fileTmp: any = [];
    if (files.length) {
      // eslint-disable-next-line array-callback-return
      files.map((item: any) => {
        fileTmp.push({
          status: "done",
          uid: item.id,
          name: item.fileName,
          url: item.fileUrl,
          thumbUrl: item.fileUrl,
        });
      });
    }
    setFileList(fileTmp);
  }, [files]);

  const uploadFile = (params) => {
    const blob = new Blob([params.file], {
      type: params.file.type,
    });
    const link = window.URL.createObjectURL(blob);
    const typeTmp = params.file.type === "application/pdf" ? ".pdf" : "";

    let fileTmp: any = [
      {
        uid: params.file.uid,
        status: "upload",
        name: params.file.name,
        originFileObj: params.file,
        url: link,
        thumbUrl: `${link}${typeTmp}`,
      },
      ...fileList,
    ];

    setFileList(fileTmp);
  };

  const handleDelete = async (record: any) => {
    let fileTmp: any =
      (fileList.length &&
        fileList.filter((item) => !item.uid.includes(record.uid))) ||
      fileList;
    let fileDelete = files.find((item) => item.id.includes(record.uid));
    if (fileDelete) {
      await deleteUploadNotaRealisasi(fileDelete.id);
    }
    handleFileUpload(fileTmp);
    setFileList(fileTmp);
  };

  const props: UploadProps = {
    name: "file",
    multiple: true,
    fileList: fileList,
    accept: "image/jpeg,application/pdf,image/jpg",
    customRequest: uploadFile,
    listType: "picture",
    beforeUpload: (file) => {
      let listFile: any = ["image/jpeg", "application/pdf", "image/jpg"];
      let isValid = listFile.includes(file.type);
      const maxFile = file.size >= 5000000 ? false : true;
      if (!isValid) {
        notifAlert({
          type: "error",
          description: "File yang di upload harus tipe:.jpg .jpeg dan .pdf",
        });
      }
      if (!maxFile) {
        notifAlert({
          type: "error",
          description: "Maksimal file 5M",
        });
        isValid = false;
      }
      return isValid || Upload.LIST_IGNORE;
    },
    onChange(info) {
      const { status } = info.file;

      if (status !== "removed" && status !== "uploading") {
        console.log(info.file, info.fileList);
        setFileList(info.fileList);
      }

      if (status !== "removed") {
        handleFileUpload(info.fileList);
      }
      if (status === "done") {
        // message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === "error") {
        // message.error(`${info.file.name} file upload failed.`);
      }
    },
    onRemove(file: any) {
      Modal.confirm({
        title: "Perhatian",
        content: "Yakin Hapus Data?",
        onOk() {
          return handleDelete(file);
        },
        onCancel() {},
        okText: "Hapus",
        cancelText: "Batal",
        okType: "danger",
      });
    },
  };
  return (
    <Dragger {...props}>
      <p className="ant-upload-drag-icon">
        <CloudUploadOutlined />
      </p>
      <p className="ant-upload-text">Upload File Disini</p>
      <p className="ant-upload-hint">Max 5 mb File tipe:.jpg .jpeg dan .pdf</p>
    </Dragger>
  );
};

export const ButtonUpload = ({ files, handleFileUpload }: uploadsType) => {
  const [fileList, setFileList] = useState<any>([]);

  useEffect(() => {
    let fileTmp: any = [];
    if (files.length) {
      // eslint-disable-next-line array-callback-return
      files.map((item: any) => {
        fileTmp.push({
          status: "done",
          uid: item.id,
          name: item.fileName,
          url: item.fileUrl,
          thumbUrl: item.fileUrl,
        });
      });
    }
    setFileList(fileTmp);
  }, [files]);

  const uploadFile = (params) => {
    const blob = new Blob([params.file], {
      type: params.file.type,
    });
    const link = window.URL.createObjectURL(blob);
    const typeTmp = params.file.type === "application/pdf" ? ".pdf" : "";

    let fileTmp: any = [
      {
        uid: params.file.uid,
        status: "upload",
        name: params.file.name,
        originFileObj: params.file,
        url: link,
        thumbUrl: `${link}${typeTmp}`,
      },
      // ...fileList,
    ];

    setFileList(fileTmp);
  };

  const handleDelete = async (record: any) => {
    let fileTmp: any =
      (fileList.length &&
        fileList.filter((item) => !item.uid.includes(record.uid))) ||
      fileList;

    handleFileUpload(fileTmp);
    setFileList(fileTmp);
  };

  const props: UploadProps = {
    name: "file",
    multiple: false,
    fileList: fileList,
    accept: "application/pdf",
    customRequest: uploadFile,
    listType: "picture",
    beforeUpload: (file) => {
      let listFile: any = ["application/pdf"];
      let isValid = listFile.includes(file.type);
      const maxFile = file.size >= 5000000 ? false : true;
      if (!isValid) {
        notifAlert({
          type: "error",
          description: "File yang di upload harus tipe: .pdf",
        });
      }
      if (!maxFile) {
        notifAlert({
          type: "error",
          description: "Maksimal file 5M",
        });
        isValid = false;
      }
      return isValid || Upload.LIST_IGNORE;
    },
    onChange(info) {
      const { status } = info.file;

      if (status !== "removed" && status !== "uploading") {
        console.log(info.file, info.fileList);
        setFileList(info.fileList);
      }

      if (status !== "removed") {
        handleFileUpload(info.fileList);
      }
      if (status === "done") {
        // message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === "error") {
        // message.error(`${info.file.name} file upload failed.`);
      }
    },
    onRemove(file: any) {
      Modal.confirm({
        title: "Perhatian",
        content: "Yakin Hapus Data?",
        onOk() {
          return handleDelete(file);
        },
        onCancel() {},
        okText: "Hapus",
        cancelText: "Batal",
        okType: "danger",
      });
    },
  };
  return (
    <Dragger {...props}>
      <p className="ant-upload-drag-icon">
        <CloudUploadOutlined />
      </p>
      <p className="ant-upload-text">Upload File Disini</p>
      <p className="ant-upload-hint">Max 5 mb File tipe: .pdf</p>
    </Dragger>
  );
};
