/** @format */

import React, { useEffect, useState } from "react";
import { BreadCrumb } from "../../../components";
import moment from "moment";
import "moment/locale/id";
import { Table, Space } from "antd";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import * as FileSaver from "file-saver";
// import * as XLSX from "xlsx";
import { formatCurr } from "../../../utils/helper";
import ExcelJS from "exceljs";
import { useDispatch, useSelector } from "react-redux";
import { getMadrasah } from "../../../services/v2/usermanservice/madrasahservices";
import { getLaporanRapbm } from "../../../services/v2/planningservice/laporanservices";
import FilterMadrasah from "../Component/FilterMadrasah";
import { ButtonDropdownExport } from "../../../components/Button";
import notifAlert from "../../../components/NotifAlert";
import { setStore } from "../../../redux/actions";

const LaporanRapbm: React.FC = () => {
  const itemBreadcrumb = [
    { path: "/", breadcrumbName: "Home" },
    { path: "/", breadcrumbName: "Laporan" },
    { path: "/", breadcrumbName: "Laporan Rencana" },
    { path: "/", breadcrumbName: "RAPBM" },
  ];

  const auths = useSelector((state: any) => state.auth);
  const store = useSelector((state: any) => state.store);
  const profile = store?.madrasahProfile || null;
  const auth = auths?.data || null;
  const groupRole = auth?.group_role || "";
  const refKabkota = store.kabkota || [];
  const refProvinsi = store.provinsi || [];
  const refKecamatan = store.kecamantan || [];
  const [tableData, setTableData] = useState<any>([]);
  const [tmpMadrasah, setTmpMadrasah] = useState<any>([]);
  const [dataMadrasah, setDataMadrasah] = useState<any>(null);
  const [tmpFilter, setTmpFilter] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const tmpListMadrasah = store?.listMadrasah || [];
  // let totalSisa = 0;

  let dataTable: any = [];

  tableData.length &&
    // eslint-disable-next-line array-callback-return
    tableData.map((item: any, i: number) => {
      let totalBelanja: any = 0;
      let totalPendapatan: any = 0;
      item?.snp?.length &&
        // eslint-disable-next-line array-callback-return
        item?.snp?.map((items: any, j: number) => {
          if (j === 0) {
            totalPendapatan = items?.rencanaPendapatan;
            dataTable.push({
              no: i + 1,
              namaSumberDana: item?.namaSumberDana,
              pendapatan: items?.rencanaPendapatan,
              namaBelanja: items?.namaSnp,
              belanja: items.rencanaBelanja,
            });
            totalBelanja += items.rencanaBelanja;
          } else {
            dataTable.push({
              no: "",
              namaSumberDana: null,
              pendapatan: null,
              namaBelanja: items?.namaSnp,
              belanja: items.rencanaBelanja,
            });
            totalBelanja += items.rencanaBelanja;
          }
        });
      dataTable.push({
        no: "",
        namaSumberDana: "TOTAL",
        pendapatan: totalPendapatan,
        namaBelanja: "",
        belanja: totalBelanja,
      });
    });

  const getData = async (tmpFilter: any) => {
    setLoading(true);
    if (groupRole !== "madrasah") {
      if (tmpListMadrasah.length === 0) {
        let filter: any = "";
        if (groupRole === "provinsi") {
          filter = `?kodeProvinsi=${auth?.kode_provinsi}`;
        }
        if (groupRole === "kabkota") {
          filter = `?kodeProvinsi=${auth?.kode_provinsi}&kode_kabkota=${auth?.kode_kabkota}`;
        }
        const madrasah = (await getMadrasah(filter)) || [];
        const listMadrasah = madrasah || [];
        setTimeout(() => {
          dispatch(setStore({ listMadrasah }));
        }, 100);
        setTmpMadrasah(madrasah);
      } else {
        setTmpMadrasah(tmpListMadrasah);
      }
      const payload = {
        tahun: auth?.isTahun,
        madrasahId: tmpFilter?.madrasahId,
      };
      if (tmpFilter?.madrasahId) {
        const resMadrasah = tmpListMadrasah.find((item) =>
          item.id.includes(tmpFilter?.madrasahId),
        );
        setDataMadrasah(resMadrasah);
        // const res = await getLaporanErkam(payload);
        const res = await getLaporanRapbm(payload);

        setTableData(res || []);
      }
    } else {
      setDataMadrasah(profile);
      const payload = {
        tahun: auth?.isTahun,
        madrasahId: auth?.madrasah?.id,
      };
      const res = await getLaporanRapbm(payload);
      setTableData(res || []);
      console.log(res);

      // setTmpData(res);
    }

    setLoading(false);
  };

  useEffect(() => {
    getData(tmpFilter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tmpFilter, auth?.isTahun]);

  /**
   * Data untuk tabel children pertama
   */
  let columns: any = [
    {
      title: "No",
      key: "no",
      // align: "center",
      width: 80,
      dataIndex: "no",
    },
    {
      title: "Keterangan",
      key: "namaSumberDana",
      dataIndex: "namaSumberDana",
      // align: "center",
      width: 300,
    },
    {
      title: "Pendapatan",
      key: "pendapatan",
      dataIndex: "pendapatan",
      render: (pendapatan) => (
        <div className="text-right">
          {(pendapatan && formatCurr(pendapatan)) || ""}
        </div>
      ),
    },
    {
      title: "Belanja",
      key: "namaBelanja",
      dataIndex: "namaBelanja",
    },
    {
      title: "Saldo",
      key: "belanja",
      dataIndex: "belanja",
      render: (belanja) => (
        <div className="text-right">{formatCurr(belanja)}</div>
      ),
    },
  ];

  const handleChangeValues = (values) => {
    setTmpFilter(values);
    // getData(values);
  };

  function handleExportPdf() {
    const namaKecamatan =
      refKecamatan.find((item) => item.kode === dataMadrasah?.kode_kecamatan) ||
      null;
    const namaKabkota =
      refKabkota.find((item) => item.kode === dataMadrasah?.kode_kabkota) ||
      null;
    const namaProvinsi =
      refProvinsi.find((item) => item.kode === dataMadrasah?.kode_provinsi) ||
      null;

    const doc: any = new jsPDF({
      putOnlyUsedFonts: true,
      orientation: "landscape",
      floatPrecision: 16,
    });

    var fline = 10;
    doc.setFont("times", "bold");
    doc.setFontSize(14);

    doc.text(`RENCANA ANGGARAN PENDAPATAN DAN BELANJA MADRASAH`, 150, 12, {
      align: "center",
    });
    doc.text(`TAHUN ANGGARAN ${auths?.isTahun}`, 150, 18, { align: "center" });
    doc.setFont("times", "normal");
    doc.setFontSize(10);
    doc.text("Madrasah/PPS", fline, 30);
    doc.text(`: ${dataMadrasah?.nama}`, fline + 60, 30);
    doc.text("NSM", fline, 35);
    doc.text(`: ${dataMadrasah?.nsm}`, fline + 60, 35);
    doc.text("Kecamatan", fline, 40);
    doc.text(`: ${namaKecamatan?.nama || "-"}`, fline + 60, 40);
    doc.text("Kabupaten / Kota", fline, 45);
    doc.text(`: ${namaKabkota?.nama || "-"}`, fline + 60, 45);
    doc.text("Provinsi", fline, 50);
    doc.text(`: ${namaProvinsi?.nama || "-"}`, fline + 60, 50);

    let las: any = [];
    // let sisaTotalPdf = 0;
    const head = [["No", "Keterangan", "Pendapatan", "Belanja", "Saldo"]];
    const foot = [];
    autoTable(doc, {
      styles: { lineColor: 244, lineWidth: 0.1 },
      margin: { left: 10, right: 10 },
      headStyles: {
        halign: "center",
        valign: "middle",
        fillColor: [0, 128, 0],
      }, // Cells in first column centered and green
      footStyles: {
        halign: "center",
        valign: "middle",
        fillColor: [0, 128, 0],
      }, // Cells in first column centered and green
      startY: 55,
      head: head,
      body:
        dataTable.length &&
        dataTable.map((item: any, i: number) => {
          return [
            item.no,
            item.namaSumberDana,
            (item?.pendapatan && formatCurr(item.pendapatan)) || "",
            item.namaBelanja,
            (item?.belanja && formatCurr(item.belanja)) || "",
          ];
        }),
      foot: foot,
      showHead: "firstPage",
      showFoot: "lastPage",
      columnStyles: {
        0: { halign: "center", cellWidth: 15 },
        1: { halign: "left", cellWidth: 70 },
        2: { halign: "right", cellWidth: 45 },
        3: { halign: "left", cellWidth: 102 },
        4: { halign: "right", cellWidth: 45 },
      },
      didDrawPage: (d) => las.push(d.cursor),
      // didDrawCell: (data) => {
      //   console.log(data.column.index);
      // },
    });

    let lastLine: any = doc.lastAutoTable.finalY + 5;
    const footerText = [
      {
        text: "Mengetahui",
        line: fline,
        space: 0,
        bottom: 10,
      },
      {
        text: moment().format("dddd, DD MMMM YYYY"),
        line: fline,
        space: 210,
        bottom: 0,
      },
      {
        text: "Kepala Madrasah",
        line: fline,
        space: 0,
        bottom: 5,
      },
      {
        text: "Bendahara Madrasah",
        line: fline,
        space: 210,
        bottom: 0,
      },
      {
        text:
          (dataMadrasah?.nama_kepala && `( ${dataMadrasah?.nama_kepala} )`) ||
          "(.................)",
        line: fline,
        space: 0,
        bottom: 30,
      },
      {
        text:
          (dataMadrasah?.nama_bendahara &&
            `( ${dataMadrasah?.nama_bendahara} )`) ||
          "(.................)",
        line: fline,
        space: 210,
        bottom: 0,
      },
    ];
    let tmpBottom = lastLine;
    // eslint-disable-next-line array-callback-return
    footerText.map((item) => {
      tmpBottom += item.bottom;
      if (tmpBottom > 195) {
        doc.addPage();
        tmpBottom = 15;
      }
      doc.text(item.text, fline + item.space, tmpBottom);
    });

    doc.output("dataurlnewwindow", { filename: "laporan-rapbm" });
    notifAlert({
      type: "success",
      description: "Data berhasil di export",
    });
  }

  const handleExportFile = (type) => {
    if (dataTable.length > 0) {
      if (type === "pdf") {
        handleExportPdf();
      } else if (type === "excel") {
        handleExportExcel();
      }
    } else {
      notifAlert({
        type: "warning",
        description:
          "Harap pilih data yang ingin di export melalui fungsi filter!",
      });
    }
  };

  const handleExportExcel = async () => {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const wb = new ExcelJS.Workbook();
    const ws = wb.addWorksheet("data RAPBM");
    ws.mergeCells("A2:G2");
    const namaKecamatan =
      refKecamatan.find((item) => item.kode === dataMadrasah?.kode_kecamatan) ||
      null;
    const namaKabkota =
      refKabkota.find((item) => item.kode === dataMadrasah?.kode_kabkota) ||
      null;
    const namaProvinsi =
      refProvinsi.find((item) => item.kode === dataMadrasah?.kode_provinsi) ||
      null;
    const headerTitleCell = ws.getCell("A2");
    headerTitleCell.font = {
      size: 14,
      underline: true,
      bold: true,
    };

    const headerColA = ws.getColumn("A");
    headerColA.width = 10;
    const headerColB = ws.getColumn("B");
    headerColB.width = 40;
    const headerColC = ws.getColumn("C");
    headerColC.width = 25;
    const headerColD = ws.getColumn("D");
    headerColD.width = 50;
    const headerColE = ws.getColumn("E");
    headerColE.width = 25;

    headerTitleCell.value = `RENCANA ANGGARAN PENDAPATAN DAN BELANJA MADRASAH T.A. ${auths?.isTahun}`;
    const listHeaderDetail = [
      {
        title: "Madrasah/PPS",
        value: `: ${dataMadrasah?.nama}`,
      },
      {
        title: "Kecamatan",
        value: `: ${namaKecamatan?.nama || "-"}`,
      },
      {
        title: "Kabupaten / Kota",
        value: `: ${namaKabkota?.nama || "-"}`,
      },
      {
        title: "Provinsi",
        value: `: ${namaProvinsi?.nama || "-"}`,
      },
    ];

    // eslint-disable-next-line array-callback-return
    listHeaderDetail.map((item, i) => {
      const row = i + 4;
      const headerTitleBody = ws.getCell(`A${row}`);
      const headerValueBody = ws.getCell(`B${row}`);
      headerTitleBody.font = {
        size: 12,
        underline: true,
        bold: true,
      };
      headerTitleBody.value = item.title;
      headerValueBody.font = {
        size: 12,
        underline: true,
      };
      headerValueBody.value = item.value;
    });

    const rowLast = ws.rowCount;
    let tableTmp: any = [];
    dataTable.length &&
      // eslint-disable-next-line array-callback-return
      dataTable.map((item) => {
        tableTmp.push([
          item.no,
          item.namaSumberDana,
          item?.pendapatan && formatCurr(item.pendapatan),
          item.namaBelanja,
          item?.belanja && formatCurr(item.belanja),
        ]);
      });

    ws.addTable({
      name: "MyTable",
      ref: `A${rowLast + 2}`,
      headerRow: true,
      // totalsRow: true,
      style: {
        showFirstColumn: true,
        showRowStripes: true,
      },
      columns: [
        { name: "No" },
        { name: "Keterangan" },
        { name: "Pendapatan" },
        { name: "Belanja" },
        { name: "Saldo" },
      ],
      rows: tableTmp,
    });
    let lastLine = 12 + tableTmp.length;
    ws.getCell(`A${lastLine}`).value = "Mengetahui";
    ws.getCell(`E${lastLine}`).value = moment().format("dddd, DD MMMM YYYY");
    ws.getCell(`A${lastLine + 2}`).value = "Kepala Madrasah";
    ws.getCell(`E${lastLine + 2}`).value = "Bendahara Madrasah";
    ws.getCell(`A${lastLine + 5}`).value =
      (dataMadrasah?.nama_kepala && `( ${dataMadrasah?.nama_kepala} )`) ||
      "(.................)";
    ws.getCell(`E${lastLine + 5}`).value =
      (dataMadrasah?.nama_bendahara && `( ${dataMadrasah?.nama_bendahara} )`) ||
      "(.................)";
    const excelBuffer = await wb.xlsx.writeBuffer();
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, "Laporan-RAPBM" + fileExtension);
  };

  return (
    <>
      <BreadCrumb routes={itemBreadcrumb} title="RAPBM" />
      {groupRole !== "madrasah" && (
        <FilterMadrasah
          handleChangeValues={handleChangeValues}
          madrasah={tmpMadrasah}
        />
      )}
      <div className="m-5 p-5 bg-white shadow-md rounded">
        <div className="mb-4 flex justify-end">
          <Space size={[8, 8]} wrap>
            <ButtonDropdownExport handleExportFile={handleExportFile} />
          </Space>
        </div>
        <div className="w-full">
          <Table
            // sticky
            // rowKey={(record) => record.id}
            columns={columns}
            dataSource={dataTable}
            // scroll={{ x: 1300 }}
            // showHeader={i > 0 ? false : true}
            bordered
            loading={loading}
            pagination={false}
          />
        </div>
      </div>
    </>
  );
};

export default LaporanRapbm;
