/** @format */

import React, { useState } from "react";

import { Form, Select } from "antd";
import { useSelector } from "react-redux";

type filterProps = {
  handleChangeValues: (values: any) => void;
  dataMadrasah: any;
};

const FilterPengajuan = ({ handleChangeValues, dataMadrasah }: filterProps) => {
  const [form] = Form.useForm();
  const store = useSelector((state: any) => state.store);
  const auth = store?.profile || null;
  const listProvinsi: any =
    (dataMadrasah?.length &&
      dataMadrasah?.map((item) => item?.kode_provinsi)) ||
    [];
  const listKabkota =
    (dataMadrasah?.length && dataMadrasah?.map((item) => item?.kode_kabkota)) ||
    [];
  const listBank: any = [];

  dataMadrasah?.length &&
    dataMadrasah?.map(
      (item) =>
        listBank.indexOf(item.nama_bank) === -1 &&
        listBank.push(item.nama_bank),
    );

  const initialValue = {
    provinsi: auth?.group_role === "pusat" ? null : auth?.kode_provinsi,
    kabkota:
      auth?.group_role === "pusat" || auth?.group_role === "provinsi"
        ? null
        : auth?.kode_kabkota,
  };

  const [provinsi, setProvinsi] = useState<any>(
    auth?.group_role === "pusat" ? null : auth?.kode_provinsi,
  );

  const refProvinsi =
    store.provinsi.filter((item: any) => listProvinsi.includes(item.kode)) ||
    [];

  const refKabkota =
    store.kabkota.filter((item: any) => listKabkota.includes(item.kode)) || [];

  const handleChange = (values) => {
    for (let obj in values) {
      if (obj === "provinsi") {
        setProvinsi(values.provinsi);
        form.setFieldsValue({ kabkota: null, madrasahId: null });
      }
    }

    changeField();
  };

  const changeField = () => {
    const val = form.getFieldsValue();
    let tmpFilter: any = {
      provinsi: null,
      kabkota: null,
      bank: null,
    };
    for (let obj in val) {
      switch (obj) {
        case "provinsi":
          let namaProv: any =
            refProvinsi.find((item) => item.kode === val.provinsi) || null;
          tmpFilter = {
            ...tmpFilter,
            provinsi: namaProv?.kode || null,
          };
          break;
        case "kabkota":
          let namaKabko: any =
            refKabkota.find((item) => item.kode === val.kabkota) || null;
          tmpFilter = {
            ...tmpFilter,
            kabkota: namaKabko?.kode || null,
          };
          break;
        case "bank":
          tmpFilter = { ...tmpFilter, bank: val.bank || null };
          break;
      }
    }
    handleChangeValues(tmpFilter);
  };
  return (
    <div className="m-5 p-5 bg-white shadow-md rounded">
      <Form
        form={form}
        key="formFilterPeriode"
        name="formFilterPeriode"
        initialValues={initialValue}
        labelAlign="left"
        labelCol={{ span: 6 }}
        onValuesChange={handleChange}>
        <div className="grid grid-cols-1  md:items-center  md:grid-cols-2 gap-4 ">
          <div>
            <Form.Item label="Provinsi" name="provinsi" required>
              <Select
                placeholder="Pilih Provinsi"
                showSearch
                allowClear
                optionFilterProp="children">
                {refProvinsi.length &&
                  refProvinsi.map((item) => (
                    <Select.Option
                      key={`filProv${item.kode}`}
                      value={item.kode}>
                      {item.nama}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
          </div>
          <div>
            <Form.Item label="Kab / Kota" name="kabkota">
              <Select
                placeholder="Pilih Kab / Kota"
                showSearch
                allowClear
                optionFilterProp="children">
                {refKabkota.length &&
                  provinsi &&
                  refKabkota
                    .filter((item) => item.kode_provinsi === provinsi)
                    .map((item) => (
                      <Select.Option
                        key={`filKab${item.kode}`}
                        value={item.kode}>
                        {item.nama}
                      </Select.Option>
                    ))}
              </Select>
            </Form.Item>
          </div>
          <div>
            <Form.Item label="Bank" name="bank">
              <Select
                placeholder="Pilih Bank"
                showSearch
                allowClear
                optionFilterProp="children">
                {listBank.length &&
                  listBank.map((item: any) => (
                    <Select.Option key={`filMad-${item}`} value={item}>
                      {item}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
          </div>
        </div>
      </Form>
    </div>
  );
};
export default FilterPengajuan;
