/** @format */

import React, { useState, useEffect } from "react";
import { Form, Modal, Descriptions, DatePicker, Input, Upload } from "antd";
import type { RangePickerProps } from "antd/es/date-picker";
import moment from "moment";
import "moment/locale/id";
import { formatRupiah } from "../../../../utils/helper";

type FormActionType = {
  openModal: boolean;
  form: any;
  title: string;
  store: any;
  data: any;
  files: any;
  handleSave: (e: any) => void;
  handleClose: () => void;
};

const ModalTanggalRealisasiPengembalianDana = ({
  openModal,
  title,
  data,
  store,
  form,
  files,
  handleSave,
  handleClose,
}: FormActionType) => {
  const tmpRekeningBelanja = store.rekeningBelanja || [];
  const tmpPenerima = store.penerimaRekening || [];
  const tmpTipeKas = store.tipeKas || [];
  const tmpBank = store.bank || [];
  const listSumberDana = store?.rencanaPendapatanDefinitif || [];
  let tmpDataPenerima: any =
    tmpPenerima.find((item: any) => item.id.includes(data?.penerimaId)) || null;
  const disabledDate: RangePickerProps["disabledDate"] = (current) => {
    return (
      data?.tanggalNota &&
      moment(current).utc().isBefore(data?.tanggalNota, "day")
    );
  };

  const [fileList, setFileList] = useState<any>([]);

  useEffect(() => {
    let fileTmp: any = [];
    if (files.length) {
      // eslint-disable-next-line array-callback-return
      files.map((item: any) => {
        fileTmp.push({
          status: "done",
          uid: item.id,
          name: item.fileName,
          url: item.fileUrl,
          thumbUrl: item.fileUrl,
        });
      });
    }
    setFileList(fileTmp);
  }, [files]);

  return (
    <Modal
      key="modalTanggalRealisasiPengembalianDana"
      open={openModal}
      title={`${title} Nota Pengembalian Dana`}
      width={800}
      onCancel={handleClose}
      cancelText="Batal"
      okText="Simpan"
      onOk={() => form.submit()}>
      <Form
        onFinish={handleSave}
        form={form}
        layout="vertical"
        key="formTanggalRealisasiPengembalianDana"
        name="formTanggalRealisasiPengembalianDana">
        <div className="grid grid-cols-1  md:items-center md:grid-cols-2 gap-2 ">
          <div>
            <Form.Item
              label="Tanggal Realisasi"
              name="tanggalRealisasi"
              rules={[
                {
                  required: true,
                  message: "Tanggal Realisasi tidak boleh kosong!",
                },
              ]}>
              <DatePicker
                disabledDate={disabledDate}
                placeholder="Tanggal Realisasi"
                showTime
              />
            </Form.Item>
          </div>
          <div>
            <Form.Item
              label="No Referensi"
              name="noReferensi"
              rules={[
                {
                  required: true,
                  message: "No Referensi tidak boleh kosong!",
                },
              ]}>
              <Input placeholder="No Referensi" />
            </Form.Item>
          </div>
        </div>
      </Form>
      <Descriptions
        labelStyle={{ fontWeight: 600 }}
        size="small"
        layout="vertical"
        column={{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 1, xs: 1 }}>
        <Descriptions.Item label="No Nota">
          {data?.noNotaFormat}
        </Descriptions.Item>
        <Descriptions.Item label="Tanggal Nota">
          {data?.tanggalNota
            ? moment(data?.tanggalNota).format("dddd,DD MMM YYYY HH:mm:ss")
            : "-"}
        </Descriptions.Item>
        <Descriptions.Item label="Sumber Dana">
          {listSumberDana.find(
            (item) =>
              item.kode_sumber_dana === data?.kodeSumberDana.toLowerCase(),
          )?.nama_sumber_dana || "-"}
        </Descriptions.Item>
        <Descriptions.Item label="Tipe Kas">
          {(data?.kodeTipeKas &&
            tmpTipeKas.find((item: any) => item.kode === data?.kodeTipeKas)
              ?.nama) ||
            "-"}
        </Descriptions.Item>
        <Descriptions.Item label="No Rekening">
          {(data?.rekeningBankId &&
            tmpRekeningBelanja.find((item: any) =>
              item.id.includes(data?.rekeningBankId),
            )?.no_rekening) ||
            "-"}
        </Descriptions.Item>
        <Descriptions.Item label="Rekening Atas Nama">
          {(data?.rekeningBankId &&
            tmpRekeningBelanja.find((item: any) =>
              item.id.includes(data?.rekeningBankId),
            )?.no_rekening_nama) ||
            "-"}
        </Descriptions.Item>
        <Descriptions.Item label="Nama Bank">
          {(data?.rekeningBankId &&
            tmpRekeningBelanja.find((item: any) =>
              item.id.includes(data?.rekeningBankId),
            )?.nama_bank) ||
            "-"}
        </Descriptions.Item>
        <Descriptions.Item label="Nama Penerima">
          {(tmpDataPenerima && tmpDataPenerima?.nama) || "-"}
        </Descriptions.Item>
        <Descriptions.Item label="No Rekening Penerima">
          {(tmpDataPenerima &&
            tmpDataPenerima?.penerimaRekenings?.find(
              (item) => item.id === data?.penerimaRekeningId,
            )?.no_rekening) ||
            "-"}
        </Descriptions.Item>
        <Descriptions.Item label="Nama Bank Penerima">
          {(tmpDataPenerima &&
            tmpBank.find((item) =>
              item.kode.includes(
                tmpDataPenerima?.penerimaRekenings?.find(
                  (item) => item.id === data?.penerimaRekeningId,
                )?.kode_bank,
              ),
            )?.nama) ||
            "-"}
        </Descriptions.Item>
        <Descriptions.Item label="Total">
          {(data?.grandTotal && formatRupiah(data?.grandTotal)) || "-"}
        </Descriptions.Item>
        <Descriptions.Item label="Keterangan">
          {data?.keterangan || "-"}
        </Descriptions.Item>
      </Descriptions>
      <div>
        <h3>Dokumen</h3>
        <Upload
          listType="picture-card"
          fileList={fileList}
          showUploadList={{ showRemoveIcon: false }}
          // onPreview={handlePreview}
        >
          {fileList.length >= 8 ? null : ""}
        </Upload>
      </div>
    </Modal>
  );
};
export default ModalTanggalRealisasiPengembalianDana;
