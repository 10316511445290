/** @format */

import api from "../../../utils/api";
import { referenceService } from "../constant";

const basePath = `${referenceService}/tipe-pencairan`;

/** Post TipePencairan   */

export const postTipePencairan = async (params: any) => {
  const response = await api.post<any>(`${basePath}`, params);
  return response?.data?.return;
};

/** Delete TipePencairan  */
export const deleteTipePencairan = async (id: string) => {
  const response = await api.delete(`${basePath}/${id}`);
  return response?.data?.return;
};
