/**
 * update fix
 *
 * @format
 */

import api from "../../../utils/api";
import { planningService } from "../constant";
const basePath = `${planningService}/dokumen-pencairan`;
const credentials = "und5sy2kn9HekqKBf0FpLieVb2Fv4kD2";

/** Get Dokumen Pencairan*/
export const getDokumenPencairan = async (params: any) => {
  const response = await api.get(`${basePath}${params}`);
  return response?.data?.return;
};

/** Get Dokumen Pencairan by Nsm */
export const getDokumenPencairanNsm = async (params: any) => {
  const response = await api.get(`${basePath}/nsm${params}`);
  return response?.data?.return;
};

/** Get Dokumen Pencairan by Nsm Manual*/
export const getDokumenPencairanNsmManual = async (params: any) => {
  const response = await api.get(
    `${basePath}/nsm/manual?xCredentialKey=${credentials}${params}`,
  );
  return response?.data?.return;
};

/** Post Dokumen Pencairan */
export const postDokumenPencairan = async (params: any) => {
  const response = await api.post(`${basePath}`, params);
  return response?.data?.return;
};

/** Edit Dokumen Pencairan  By ID*/
export const editDokumenPencairan = async (params: any, id: any) => {
  const response = await api.put(`${basePath}/${id}`, params);
  return response?.data?.return;
};

/** Edit Status Dokumen Pencairan  By ID*/
export const editStatusDokumenPencairan = async (params: any) => {
  const response = await api.put(`${basePath}/status-pencairan/${params}`);
  return response?.data?.return;
};

/** Delete Dokumen Pencairan  By ID*/
export const deleteDokumenPencairan = async (params: any) => {
  try {
    const response = await api.delete(`${basePath}/${params}`);
    return response?.data?.return;
  } catch (error) {
    console.log(error);
  }
};
