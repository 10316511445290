/** @format */

import React from "react";
import { BreadCrumb } from "../../../../components";

const SetPPK = () => {
  const itemBreadcrumb = [
    { path: "/", breadcrumbName: "Home" },
    { path: "/", breadcrumbName: "Pengaturan" },
    { path: "/", breadcrumbName: "PPK" },
    { path: "/", breadcrumbName: "Set PPK" },
  ];

  return (
    <>
      <BreadCrumb
        routes={itemBreadcrumb}
        title="Set Pejabat Pembuat Komitmen"
      />
    </>
  );
};

export default SetPPK;
