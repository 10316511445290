/** @format */

import React, { useState, useEffect } from "react";
import {
  Select,
  Input,
  Form,
  DatePicker,
  Modal,
  Table,
  Descriptions,
  Divider,
} from "antd";
import moment from "moment";
import "moment/locale/id";
import { ButtonTableEditBlue } from "../../../../components/Button";
import {
  formatRupiah,
  getJumlahSumberDana,
  uuidv4,
} from "../../../../utils/helper";
import ModalApprovalPengeluaranKegiatan from "../../PengeluaranKegiatan/Component/ModalApprovalPengeluaranKegiatan";
import { useSelector } from "react-redux";
import { ButtonUploads } from "../../../../components/Uploads";

type FormActionType = {
  dataEdit: any;
  openModal: boolean;
  title: string;
  store: any;
  files: any;
  handleFileUpload: (e: any) => void;
  handleSave: (e: any) => void;
  handleClose: () => void;
};

const ModalPengeluaranPajak = ({
  dataEdit,
  openModal,
  title,
  store,
  files,
  handleFileUpload,
  handleSave,
  handleClose,
}: FormActionType) => {
  const [form] = Form.useForm();
  const auths = useSelector((state: any) => state.auth);
  const tmpBiaya = store.realisasiBiaya || [];
  const tmpTipeKas = store.tipeKas || [];
  const tmpPajak = store.pajak || [];
  const tmpRekeningBelanja = store.rekeningBelanja || [];
  const tmpSumberDana = store.rencanaPendapatanDefinitif || [];
  const tmpPendapatanHeader = store.realisasiPendapatanHeader || null;
  // realisasiPendapatanHeader
  const tmpRealisasiPajak = store.realisasiPajak || [];
  // console.log(tmpBiaya);
  // console.log(tmpRealisasiPajak);
  // const [tipeKas, setTipeKas] = useState<any>(null);
  const [pajak, setPajak] = useState<any>(null);
  const [sumberDana, setSumberDana] = useState<any>(null);
  // const [noRekening, setNoRekening] = useState<any>(null);
  const [saldoKas, setSaldoKas] = useState<any>(0);
  const [saldoTotal, setSaldoTotal] = useState<any>(0);
  // const [enabledRekening, setEnabledRekening] = useState<any>(false);
  const [openModalApproval, setOpenModalApproval] = useState<any>(false);
  const [dataBiaya, setDataBiaya] = useState<any>(null);
  const [pajakList, setPajakList] = useState<any>([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<any>([]);
  const [validUpload, setValidUpload] = useState<any>([]);
  const [errorUpload, setErrorUpload] = useState<any>(null);
  const [confirmLoading, setConfirmLoading] = useState<any>(false);

  let allFilter: any = { sumberDanaID: [], tipeKasID: [], pajakID: [] };
  let biayaFilter: any = [];

  let tmpListSumberDana: any = [];
  tmpPendapatanHeader &&
    tmpPendapatanHeader?.header3?.length &&
    tmpPendapatanHeader?.header3.map((item) =>
      // eslint-disable-next-line array-callback-return
      item.details.map((items) => {
        let namaSumberDana = tmpSumberDana.find(
          (val) => val.kode_sumber_dana === items.sumber_dana,
        );
        let noRek: any =
          (items.rekening_bank_id &&
            tmpRekeningBelanja.find(
              (val) => val.id === items.rekening_bank_id,
            )) ||
          null;
        let namaTipeKas = tmpTipeKas.find(
          (val) => val.kode === items.kode_tipe_kas,
        );

        let tmpDataSumber: any = {
          id: `${items.sumber_dana}${items.kode_tipe_kas}${items.rekening_bank_id}`,
          idSumberDana: namaSumberDana.id,
          kodeSumberDana: items.sumber_dana,
          namaSumberDana: namaSumberDana.nama_sumber_dana,
          kodeTipeKas: items.kode_tipe_kas,
          namaTipeKas: namaTipeKas.nama,
          sisa: items.sisa,
          rekeningBankId: items.rekening_bank_id,
          noRekening: (noRek && noRek.no_rekening) || null,
          namaRekeningBank: (noRek && noRek.nama_bank) || null,
        };
        tmpListSumberDana.push(tmpDataSumber);
      }),
    );

  tmpRealisasiPajak.length &&
    // eslint-disable-next-line array-callback-return
    tmpRealisasiPajak.map((item: any) => {
      item.pajakDetails.length &&
        // eslint-disable-next-line array-callback-return
        item.pajakDetails.some((items: any) => {
          items?.biayaDetails?.length &&
            items?.biayaDetails?.map((itemss) => biayaFilter.push(itemss.id));
          // biayaFilter.push(items.biayaId)
        });
    });

  let tmpListBiaya: any =
    (tmpBiaya.length &&
      tmpBiaya
        .filter((item) => item.grandPajakTerutang > 0)
        .filter((item) => item.status === "SELESAI")) ||
    [];

  let tmpListSumberDanaRealisasi: any = [];
  tmpListBiaya.length &&
    tmpListBiaya.map((item) =>
      // eslint-disable-next-line array-callback-return
      item.biayaSumberDanas.map((items) => {
        tmpListSumberDanaRealisasi.push(
          `${items.kodeSumberDana}${items.kodeTipeKas}${items.rekeningBankId}`,
        );
        items.kodeSumberDana &&
          allFilter["sumberDanaID"].push(items.rencanaPendapatanId);
        items.kodeTipeKas && allFilter["tipeKasID"].push(items.kodeTipeKas);
        items.kodePajak && allFilter["pajakID"].push(items.kodePajak);
      }),
    );
  let tmpSumber: any =
    tmpListSumberDana.find((item) => item.id.includes(sumberDana)) || null;
  let dataTable: any =
    (pajak &&
      sumberDana &&
      tmpListBiaya.filter((item) => {
        return (
          item.biayaSumberDanas.length &&
          item.biayaSumberDanas.some(
            (items) =>
              items.kodePajak &&
              items.kodePajak === pajak &&
              items.kodeTipeKas &&
              items.kodeTipeKas.includes(tmpSumber?.kodeTipeKas) &&
              items.rencanaPendapatanId &&
              items.rencanaPendapatanId.includes(tmpSumber?.idSumberDana),
          )
        );
      })) ||
    [];

  dataTable =
    (tmpSumber?.kodeTipeKas === "rekening_bank" &&
      tmpSumber?.rekeningBankId &&
      dataTable.filter((item) => {
        return (
          item.biayaSumberDanas.length &&
          item.biayaSumberDanas.some(
            (items) =>
              items.rekeningBankId &&
              items.rekeningBankId.includes(tmpSumber?.rekeningBankId),
          )
        );
      })) ||
    dataTable;
  if (dataEdit) {
    let editBiayaFilter: any = [];
    dataEdit?.pajakDetails.map(
      (item: any) =>
        item?.biayaDetails.length &&
        // eslint-disable-next-line array-callback-return
        item?.biayaDetails.map((items: any) => {
          editBiayaFilter.push(items.id);
        }),
    );
    biayaFilter =
      editBiayaFilter.length &&
      biayaFilter.filter((item) => !editBiayaFilter.includes(item));
    dataTable =
      (biayaFilter.length &&
        dataTable.filter((items) =>
          items.biayaDetails.some((itemss) => !biayaFilter.includes(itemss.id)),
        )) ||
      dataTable;
  } else {
    dataTable =
      (biayaFilter.length &&
        dataTable.filter((items) =>
          items.biayaDetails.some((itemss) => !biayaFilter.includes(itemss.id)),
        )) ||
      dataTable;
  }

  const handleReset = () => {
    setSumberDana(null);
    setPajakList([]);
    setPajak(null);
    // setTipeKas(null);
    // setNoRekening(null);
    // setEnabledRekening(false);
    setSaldoKas(0);
    setSaldoTotal(0);
    setSelectedRowKeys([]);

    form.resetFields();
    handleClose();
  };

  useEffect(() => {
    setValidUpload(files);
    if (dataEdit && title === "Edit") {
      let editSumber =
        `${dataEdit.kodeSumberDana}${dataEdit.kodeTipeKas}${dataEdit.rekeningBankId}` ||
        null;
      let selectBiaya: any = [];
      dataEdit?.pajakDetails.length &&
        dataEdit?.pajakDetails.map((item) => selectBiaya.push(item.biayaId));
      setSelectedRowKeys(selectBiaya);
      setSaldoKas(getJumlahSumberDana(editSumber, tmpListSumberDana));
      setSaldoTotal(dataEdit?.grandTotal);
      setSumberDana(editSumber);
      setPajak(dataEdit?.kodePajak);
      form.setFieldsValue({
        sumberDana: editSumber,
        jenisPajak: dataEdit?.kodePajak,
        tanggalNota: moment(dataEdit?.tanggalNota),
        keterangan: dataEdit?.keterangan,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataEdit, files]);

  const onSelectChange = (selectedRowKeys, selectedRows) => {
    let pajakDetailsTmp: any = [];
    setSelectedRowKeys(selectedRowKeys);
    selectedRows.length &&
      // eslint-disable-next-line array-callback-return
      selectedRows.map((item) => {
        item.biayaSumberDanas
          .filter(
            (items) =>
              items.rencanaPendapatanId &&
              items.rencanaPendapatanId.includes(tmpSumber?.idSumberDana),
          )
          // eslint-disable-next-line array-callback-return
          .map((items) => {
            pajakDetailsTmp.push({
              biayaId: item.id,
              biayaDetails: item.biayaDetails,
              biayaSumberDanaId: items.id,
              pajakTotal: items.grandPajakTerutang,
              tanggalRealisasi: item.tanggalRealisasi,
            });
          });
      });
    setPajakList(pajakDetailsTmp);
    handleTotalKas(pajakDetailsTmp);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  function disabledDate(current) {
    // Can not select days before today and today
    if (pajakList.length) {
      let tanggalValid = pajakList.map((item) => item.tanggalRealisasi);
      tanggalValid.sort();
      return moment(current).isBefore(tanggalValid[0], "day");
    }
    return current && current < moment().startOf("day");
  }

  const columns: any = [
    {
      title: "Sumber Dana",
      key: "rencanaPendapatanId",
      render: (record) => {
        return tmpSumber?.namaSumberDana;
      },
    },
    {
      title: "Nota",
      dataIndex: "noNotaFormat",
      key: "noNotaFormat",
    },
    {
      title: `Jumlah Pajak`,
      key: "grandPajakTerutang",
      align: "right",
      render: (record) => {
        return record.biayaSumberDanas
          .filter((e) => e.rencanaPendapatanId === tmpSumber?.idSumberDana)
          .map((e) => formatRupiah(e.grandPajakTerutang));
      },
    },
    {
      title: "Aksi",
      width: "20%",
      key: "id",
      render: (record) => (
        <ButtonTableEditBlue
          tooltips="Rincian"
          onClick={() => handleDetail(record)}
        />
      ),
    },
  ];

  const handleChangeField = (values: any) => {
    for (let obj in values) {
      switch (obj) {
        case "sumberDana":
          setSumberDana(values.sumberDana);
          setSaldoKas(
            getJumlahSumberDana(values.sumberDana, tmpListSumberDana),
          );
          break;
        case "jenisPajak":
          setPajak(values.jenisPajak);
          break;
        // case "noRekening":
        //   setNoRekening(values.noRekening);
        //   break;
      }
      if (obj !== "tanggalNota") {
        setSelectedRowKeys([]);
        setPajakList([]);
        handleTotalKas([]);
      }
    }
  };

  const handleChange = (_, values) => {
    handleChangeField(values);
  };

  const handleBeforeSave = (values) => {
    var tanggalNota = moment(values.tanggalNota).format(
      "YYYY-MM-DDTHH:mm:ss.SSS[Z]",
    );

    let tmpPajakDetail: any = [];
    let dataSumber =
      tmpListSumberDana.find((item) => item.id.includes(values.sumberDana)) ||
      null;
    const id = title === "Tambah" ? uuidv4() : dataEdit?.id;

    pajakList.length &&
      pajakList.map((item) =>
        tmpPajakDetail.push({
          pajakId: id,
          biayaId: item.biayaId,
          sumberDanasId: item.biayaSumberDanaId,
          grandTotal: item.pajakTotal,
          biayaDetails: item.biayaDetails,
        }),
      );
    let payload: any = {
      id: id,
      rencanaPendapatanId: (dataSumber && dataSumber.idSumberDana) || null,
      kodeSumberDana: (dataSumber && dataSumber.kodeSumberDana) || null,
      namaSumberDana: (dataSumber && dataSumber.namaSumberDana) || null,
      kodeTipeKas: (dataSumber && dataSumber.kodeTipeKas) || null,
      rekeningBankId: (dataSumber && dataSumber.rekeningBankId) || null,
      kodePajak: values.jenisPajak,
      keterangan: values?.keterangan || null,
      tahun: auths.isTahun,
      tanggalNota: tanggalNota,
      grandTotal: saldoTotal,
      pajakDetails: tmpPajakDetail,
    };

    handleSave(payload);
    handleReset();
    setConfirmLoading(false);
  };

  const handleDetail = (record) => {
    setDataBiaya(record);
    setOpenModalApproval(true);
  };

  const handleTotalKas = (pajakList: any) => {
    let tmpTotalPajak = 0;
    pajakList.length &&
      // eslint-disable-next-line array-callback-return
      pajakList.map((item) => {
        tmpTotalPajak += item.pajakTotal;
      });

    setSaldoTotal(tmpTotalPajak);
  };

  const checkFiles = (values: any) => {
    let messageTmp: any = { status: "success", messages: "" };
    if (!values.length) {
      setErrorUpload({
        status: "error",
        messages: "Maaf dokumen tidak boleh kosong!",
      });
      return Promise.reject();
    } else {
      setErrorUpload(messageTmp);
      return Promise.resolve();
    }
  };

  const validationAfterUpload = (values: any) => {
    handleFileUpload(values);
    setValidUpload(values);
    checkFiles(values);
  };

  const handleOk = () => {
    setConfirmLoading(true);
    setTimeout(() => {
      form.submit();
    }, 2000);
  };
  return (
    <Modal
      open={openModal}
      title={`${title} Nota Pengeluaran Pajak`}
      onCancel={handleReset}
      okText="Simpan"
      width={1100}
      cancelText="Batal"
      confirmLoading={confirmLoading}
      okButtonProps={{ disabled: pajakList.length ? false : true }}
      onOk={handleOk}>
      <Form
        form={form}
        key="modalRealisasiPngeluaranPajak"
        name="modalRealisasiPengeluaranPajak"
        layout="vertical"
        onValuesChange={handleChange}
        onFinish={handleBeforeSave}>
        <div className="grid grid-cols-1   md:grid-cols-2 gap-2">
          <div>
            <Form.Item
              label="Sumber Dana"
              name="sumberDana"
              rules={[
                {
                  required: true,
                  message: "Sumber Dana tidak boleh kosong!",
                },
              ]}>
              <Select
                showSearch
                allowClear
                optionFilterProp="children"
                placeholder="Pilih Sumber Dana">
                {/* {tmpSumberDana.length &&
                  tmpSumberDana
                    .filter((item) => allFilter.sumberDanaID.includes(item.id))
                    .map((item: any, i: any) => {
                      return (
                        <Select.Option key={`sp${i}`} value={item.id}>
                          {item.nama_sumber_dana}
                        </Select.Option>
                      );
                    })} */}
                {tmpListSumberDana.length &&
                  tmpListSumberDana
                    .filter((item: any) =>
                      tmpListSumberDanaRealisasi.includes(item.id),
                    )
                    .map((item: any) => (
                      <Select.Option key={`sumber${item.id}`} value={item.id}>
                        {item.namaSumberDana} | {item.namaTipeKas}{" "}
                        {item.kodeTipeKas === "rekening_bank" &&
                          `| ${item.namaRekeningBank}-${item.noRekening}`}
                      </Select.Option>
                    ))}
              </Select>
            </Form.Item>
          </div>
          {/* <div>
            <Form.Item
              label="Tipe Kas"
              name="tipeKas"
              rules={[
                {
                  required: true,
                  message: "Tipe Kas tidak boleh kosong!",
                },
              ]}>
              <Select
                showSearch
                allowClear
                optionFilterProp="children"
                placeholder="Pilih Tipe Kas">
                {tmpTipeKas.length &&
                  tmpTipeKas
                    .filter((item) => allFilter.tipeKasID.includes(item.kode))
                    .map((item: any, i: any) => {
                      return (
                        <Select.Option key={`tipekas${i}`} value={item.kode}>
                          {item.nama}
                        </Select.Option>
                      );
                    })}
              </Select>
            </Form.Item>
          </div>
          {enabledRekening && (
            <div>
              <Form.Item
                label="No Rekening"
                name="noRekening"
                rules={[
                  {
                    required: true,
                    message: "No Rekening tidak boleh kosong!",
                  },
                ]}>
                <Select
                  showSearch
                  allowClear
                  optionFilterProp="children"
                  placeholder="Pilih No Rekening">
                  {tmpRekeningBelanja.length &&
                    tmpRekeningBelanja.map((e: any, i: any) => {
                      return (
                        <Select.Option key={`noRek${i}`} value={e.id}>
                          {e.nama_bank} - {e.no_rekening}
                        </Select.Option>
                      );
                    })}
                </Select>
              </Form.Item>
            </div>
          )} */}
          <div>
            <Form.Item
              label="Jenis Pajak"
              name="jenisPajak"
              rules={[
                {
                  required: true,
                  message: "Jenis Pajak tidak boleh kosong!",
                },
              ]}>
              <Select
                showSearch
                allowClear
                optionFilterProp="children"
                placeholder="Pilih Jenis Pajak">
                {tmpPajak.length &&
                  tmpPajak
                    .filter((item: any) =>
                      allFilter.pajakID.includes(item.kode),
                    )
                    .map((item: any, i: any) => {
                      return (
                        <Select.Option key={`snp${i}`} value={item.kode}>
                          {item.nama}
                        </Select.Option>
                      );
                    })}
              </Select>
            </Form.Item>
          </div>
          <div>
            <Form.Item
              label="Tanggal Nota"
              name="tanggalNota"
              rules={[
                {
                  required: true,
                  message: "Tanggal Nota tidak boleh kosong!",
                },
              ]}>
              <DatePicker disabledDate={disabledDate} showTime />
            </Form.Item>
          </div>

          <div>
            <Form.Item label="Keterangan" name="keterangan">
              <Input.TextArea placeholder="Keterangan" />
            </Form.Item>
          </div>
        </div>
        <Form.Item
          label="Upload Dokumen"
          required
          name="files"
          validateStatus={errorUpload?.status}
          hasFeedback
          help={errorUpload?.messages}
          rules={[
            {
              validator: () => checkFiles(validUpload),
            },
          ]}>
          <ButtonUploads
            files={files}
            handleFileUpload={validationAfterUpload}
          />
        </Form.Item>
      </Form>
      <Table
        columns={columns}
        rowKey={(record) => record.id}
        rowSelection={rowSelection}
        dataSource={dataTable}
        bordered
      />
      <Divider />
      <div className="flex justify-start md:justify-end gap-4">
        <div className="md:w-1/2">
          <Descriptions
            column={1}
            contentStyle={{
              fontWeight: 600,
              justifyContent: "end",
              fontSize: 18,
            }}>
            <Descriptions.Item label="Saldo Kas">
              {formatRupiah(saldoKas)}
            </Descriptions.Item>
            <Descriptions.Item label="Total Pajak">
              {formatRupiah(saldoTotal)}
            </Descriptions.Item>
            {/* <Descriptions.Item label="Sisa Saldo Kas">
              {formatRupiah(1000)}
            </Descriptions.Item> */}
          </Descriptions>
        </div>
      </div>
      <ModalApprovalPengeluaranKegiatan
        title={"Detail"}
        store={store}
        openModal={openModalApproval}
        files={files}
        // rincian={dataState}
        data={dataBiaya}
        handleClose={() => setOpenModalApproval(false)}
        handleSave={handleDetail}
      />
    </Modal>
  );
};
export default ModalPengeluaranPajak;
