/** @format */

import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  Tabs,
  Modal,
  Tag,
  Space,
  Table,
  Button,
  Descriptions,
  Alert,
  Form,
  Input,
} from "antd";
import { BreadCrumb } from "../../../components";
import { formatRupiah, uuidv4 } from "../../../utils/helper";
import {
  ButtonTableApproval,
  ButtonTableDelete,
  ButtonTableDetail,
} from "../../../components/Button";
import {
  deleteDokumenRencanaDefinitif,
  getDokumenRencanaDefinitif,
  statusDokumenRencanaDefinitif,
} from "../../../services/v2/planningservice/rencanapendapatandefinitif";
import notifAlert from "../../../components/NotifAlert";
import {
  getFileMadrasahManual,
  getMadrasahManual,
  statusUploadManual,
} from "../../../services/v2/planningservice/uploadmanualservices";
import {
  ExclamationCircleOutlined,
  ReloadOutlined,
  FileSearchOutlined,
} from "@ant-design/icons";
import { postDokumenPencairan } from "../../../services/v2/planningservice/pencairanservices";
import ModalDokumenAction from "../../../components/Modal/ModalActionDokumen";
import {
  getPlanningAll,
  tolakPengajuanVerifikasiDokumenBos,
} from "../../../services/v2/planningservice";
import InputSearch from "../../../components/InputSearch";
import { getLaporanErkam } from "../../../services/v2/planningservice/laporanservices";
import FilterMadrasahUpload from "../../Laporan/Component/FilterMadrasahUpload";
import moment from "moment";
import "moment/locale/id";
import {
  getDokumenRencanaDefinitifAxios,
  getLaporanPengembalianDanaAxios,
} from "../../../services/v2/reportservice";
import {
  checkDataErkam,
  getTanggalPengajuanBos,
  listDokumen,
  spesialBOSTahap2,
  validasiListDokumen,
} from "../../../utils/helper/helperBos";

const { TabPane } = Tabs;
const LaporanDokumen = () => {
  const itemBreadcrumb = [
    { path: "/", breadcrumbName: "Home" },
    { path: "/", breadcrumbName: "Pencairan Dokumen BOS" },
    { path: "/", breadcrumbName: "Kelengkapan Dokumen BOS" },
  ];
  const auths = useSelector((state: any) => state.auth);
  const store = useSelector((state: any) => state.store);
  const refProvinsi = store.provinsi || [];
  const refKabkota = store.kabkota || [];
  const [form] = Form.useForm();
  const [fileList, setFileList] = useState<any>([]);
  const [id, setID] = useState<any>(null);
  let dataTableDokumen: any = listDokumen();
  const auth = auths?.data || null;
  const [dataAction, setDataAction] = useState<any>(null);
  const [dataMadrasah, setDataMadrasah] = useState<any>(null);
  const [tmpMadrasah, setTmpMadrasah] = useState<any>([]);
  const [dataLaporan, setDataLaporan] = useState<any>([]);
  const [pdfFile, setPdfFile] = useState<any>(null);
  const [title, setTitle] = useState<any>(null);
  const [nilaiPengembalianDana, setNilaiPengembalianDana] = useState<any>(null);
  const [tmpFilter, setTmpFilter] = useState<any>(null);
  const [tipeFile, setTipeFile] = useState<any>(null);
  const [nilaiPortalBos, setNilaiPortalBos] = useState<any>(null);
  const [openModal, setOpenModal] = useState<any>(false);
  const [openModalData, setOpenModalData] = useState<any>(false);
  const [openModalDataDouble, setOpenModalDataDouble] = useState<any>(false);
  const [verificationButton, setVerificationButton] = useState<any>(false);
  const [fileDoubleDok, setFileDoubleDok] = useState<any>([]);

  const [tolakButton, setTolakButton] = useState<any>(true);
  const [search, setSearch] = useState<any>(null);
  const [openModalAction, setOpenModalAction] = useState<any>(false);
  const [loading, setLoading] = useState(false);
  const [activeTabKey, setActiveTabKey] = useState<string>("pm1");
  const listJadwalPengajuan = getTanggalPengajuanBos();
  const [messageInformation, setMessageInformation] = useState<any>(null);
  const [dataDouble, setDataDouble] = useState<any>(null);
  const tmpSpesialBOSTahap2 = spesialBOSTahap2();
  const checkDataMadrasah = tmpSpesialBOSTahap2.includes(
    dataMadrasah?.nsmMadrasah,
  );
  const showAlert =
    (activeTabKey !== "pm2" &&
      checkDataErkam(dataLaporan, checkDataMadrasah)) ||
    false;

  const getJadwal =
    listJadwalPengajuan.find(
      (item) =>
        moment().isAfter(item.startDate) &&
        moment().isBefore(moment(item.endDate).add("day", 2)),
    ) || null;
  let listLap = ["docRKAM6", "docLpj1"];

  useEffect(() => {
    if (auths?.isLogin) {
      getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tmpFilter, auth?.isTahun, activeTabKey]);

  useEffect(() => {
    handleVerifikasiTombol();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileList, nilaiPengembalianDana]);
  // const onTabClick = (key) => {
  //   setTabActive(key);
  //   handleDokumen(dataMadrasah, key);
  // };

  const getData = async () => {
    setLoading(true);

    // await deleteDokumenRencanaDefinitif("0h0s572qaxd3b");
    let filterParam: any = `?kodeProvinsi=${auth?.kode_provinsi}&tahun=${auth?.isTahun}`;
    if (auth?.group_role === "provinsi") {
      if (tmpFilter?.kabkota) {
        filterParam = `${filterParam}&kodeKabkota=${tmpFilter?.kabkota}`;
      }
    } else {
      filterParam = `${filterParam}&kodeKabkota=${auth?.kode_kabkota}`;
    }

    if (activeTabKey === "pm2") {
      filterParam = `${filterParam}&jenisMadrasah=M`;
    } else {
      filterParam = `${filterParam}&jenisMadrasah=R`;
    }

    const madrasahTmp = (await getMadrasahManual(filterParam)) || [];
    let madrasah =
      (tmpFilter?.penerimaBos !== undefined &&
        madrasahTmp.filter(
          (item) => item.penerimaBos === tmpFilter?.penerimaBos,
        )) ||
      madrasahTmp;

    madrasah =
      (tmpFilter?.status !== undefined &&
        madrasah.filter((item) => item.statusValidasi === tmpFilter.status)) ||
      madrasah;

    setTmpMadrasah(madrasah || []);
    setLoading(false);
  };

  const handleChangeValues = (values: any) => {
    setTmpFilter(values);
  };

  let swastaMadrasah: any =
    auth?.group_role === "provinsi"
      ? (tmpMadrasah.length &&
          tmpMadrasah.filter((item) => item.jenjangMadrasah === "ma")) ||
        []
      : (tmpMadrasah.length &&
          tmpMadrasah.filter((item) => item.jenjangMadrasah !== "ma")) ||
        [];

  // let swastaMadrasah: any = (tmpMadrasah.length && tmpMadrasah) || [];

  let dataTable: any = search
    ? swastaMadrasah.filter((item: any) => {
        const val = search?.toLowerCase();
        return (
          (item.namaMadrasah !== null &&
            (item.namaMadrasah || "").toString().toLowerCase().includes(val)) ||
          (item.nsmMadrasah !== null &&
            (item.nsmMadrasah || "").toString().toLowerCase().includes(val))
        );
      })
    : swastaMadrasah;

  dataTable =
    (tmpFilter?.tahap !== undefined &&
      dataTable.filter((item) => item.tahap === tmpFilter.tahap)) ||
    dataTable;

  let columnsDokumen: any = [
    {
      title: "Nama",
      dataIndex: "nama",
      key: "nama",
      render: (nama, record) => {
        if (record.key === "docLpj1" && checkDataMadrasah) {
          nama = "Laporan Pertanggung Jawaban (LPJ) Desember 2023";
        }
        return nama;
      },
    },
    {
      title: "Nama File",
      key: "namaFile",
      render: (record) => {
        let tahapTmp: any = dataMadrasah?.tahap.toString();
        let namaFile = fileList.find(
          (item) =>
            item.kodeDok === record.kode &&
            item.tahap.toString() === tahapTmp &&
            item.penerimaBos.toString() ===
              dataMadrasah?.penerimaBos.toString(),
        );

        if (record.key === "docBPD") {
          let infoPengembalian: any =
            (nilaiPengembalianDana?.sisa !== 0 && (
              <div className="text-red-500">{`Bukti pengembalian dana sebesar saldo  transaksi ${formatRupiah(
                nilaiPengembalianDana?.sisa,
              )}`}</div>
            )) ||
            "";
          return (
            (namaFile && (
              <>
                {namaFile?.namaDok} {infoPengembalian}
              </>
            )) ||
            infoPengembalian
          );
        } else {
          return namaFile?.namaDok || "";
        }
      },
    },
    {
      title: "Tanggal Upload",
      key: "tanggalUpload",
      render: (record) => {
        let namaFile = fileList.find(
          (item) =>
            item.kodeDok === record.kode &&
            item.tahap === record.tahap &&
            item.penerimaBos.toString() ===
              dataMadrasah?.penerimaBos.toString(),
        );
        return (
          (namaFile?.updated_at &&
            moment(namaFile?.created_at).format("DD MMM YYYY")) ||
          ""
        );
      },
    },
    {
      title: "Tanggal Verifikasi",
      key: "tanggalVerifikasi",
      render: (record) => {
        let namaFile = fileList.find(
          (item) =>
            item.kodeDok === record.kode &&
            item.tahap === record.tahap &&
            item.penerimaBos.toString() ===
              dataMadrasah?.penerimaBos.toString(),
        );
        return (
          (namaFile?.tanggal_verifikasi_dokumen &&
            moment(namaFile?.tanggal_verifikasi_dokumen).format(
              "DD MMM YYYY",
            )) ||
          ""
        );
      },
    },
    {
      title: "Keterangan",
      key: "keterangan",
      render: (record) => {
        let namaFile = fileList.find(
          (item) =>
            item.kodeDok === record.kode &&
            item.tahap === record.tahap &&
            item.penerimaBos.toString() ===
              dataMadrasah?.penerimaBos.toString(),
        );
        if (record.key === "docRKAM6" && showAlert) {
          return (
            <div className="mb-4">
              <Alert
                className="mb-4"
                message={
                  <>
                    Rencana belanja definitif belum sesuai dengan rencana
                    pendapatan.
                  </>
                }
                type="error"
                showIcon
              />
            </div>
          );
        } else {
          return (namaFile?.keterangan && namaFile?.keterangan) || "";
        }
        // return (namaFile?.keterangan && namaFile?.keterangan) || "";
      },
    },
    {
      title: "Status",
      key: "statusValidasi",
      render: (record) => {
        let tahapTmp: any = dataMadrasah?.tahap.toString();
        let namaFile = fileList.find(
          (item) =>
            item.kodeDok === record.kode &&
            item.tahap.toString() === tahapTmp &&
            item.penerimaBos.toString() ===
              dataMadrasah?.penerimaBos.toString(),
        );
        if (activeTabKey === "pm1") {
          if (!listLap.includes(record?.key)) {
            return namaFile ? (
              <Tag
                color={
                  (namaFile?.statusValidasi.toString() === "2" && "red") ||
                  (namaFile?.statusValidasi.toString() === "1" && "#008000") ||
                  "#ffca27"
                }>
                {(namaFile?.statusValidasi.toString() === "2" && "DiTolak") ||
                  (namaFile?.statusValidasi.toString() === "1" &&
                    "Disetujui") ||
                  "Belum Disetujui"}
              </Tag>
            ) : (
              <Tag>Dokumen Kosong</Tag>
            );
          }
        } else {
          return namaFile ? (
            <Tag
              color={
                (namaFile?.statusValidasi.toString() === "2" && "red") ||
                (namaFile?.statusValidasi.toString() === "1" && "#008000") ||
                "#ffca27"
              }>
              {(namaFile?.statusValidasi.toString() === "2" && "DiTolak") ||
                (namaFile?.statusValidasi.toString() === "1" && "Disetujui") ||
                "Belum Disetujui"}
            </Tag>
          ) : (
            <Tag>Dokumen Kosong</Tag>
          );
        }
      },
    },
    {
      title: "Aksi",
      key: "aksi",
      render: (record) => {
        let tahapTmp: any = dataMadrasah?.tahap.toString();
        let namaFile = fileList.find(
          (item) =>
            item.kodeDok === record.kode &&
            item.tahap.toString() === tahapTmp &&
            item.penerimaBos.toString() ===
              dataMadrasah?.penerimaBos.toString(),
        );

        let doubleDok: any =
          fileList.filter(
            (item) =>
              item.kodeDok === record.kode &&
              item.tahap.toString() === tahapTmp &&
              item.penerimaBos.toString() ===
                dataMadrasah?.penerimaBos.toString(),
          ) || [];

        if (activeTabKey === "pm1") {
          return (
            <Space>
              {namaFile && (
                <>
                  <ButtonTableApproval
                    tooltips="Konfirmasi Dokumen"
                    onClick={() => handleAfterApproval(record, namaFile)}
                  />
                </>
              )}
              {fileList.length !== 0 && listLap.includes(record?.key) && (
                <Button
                  icon={<ReloadOutlined />}
                  onClick={() => handleAction(record?.key)}>
                  Lihat Data
                </Button>
              )}
              {doubleDok.length >= 2 && (
                <ButtonTableDetail
                  onClick={() => handleDoubleDokumen(record, doubleDok)}
                  tooltips="Dokumen Double"
                />
              )}
            </Space>
          );
        } else {
          return (
            <Space>
              {namaFile && (
                <>
                  <ButtonTableApproval
                    tooltips="Konfirmasi Dokumen"
                    // disabled={(namaFile?.statusValidasi === 2 && true) || false}
                    onClick={() => handleAfterApproval(record, namaFile)}
                  />
                </>
              )}
              {doubleDok.length >= 2 && (
                <ButtonTableDetail
                  onClick={() => handleDoubleDokumen(record, doubleDok)}
                  tooltips="Dokumen Double"
                />
              )}
            </Space>
          );
        }
      },
    },
  ];

  let columns: any = [
    {
      title: "NSM",
      dataIndex: "nsmMadrasah",
      key: "nsmMadrasah",
    },
    {
      title: "Nama",
      dataIndex: "namaMadrasah",
      key: "namaMadrasah",
    },
    {
      title: "Jenjang",
      dataIndex: "jenjangMadrasah",
      key: "jenjangMadrasah",
      render: (jenjangMadrasah) => (
        <div className="uppercase">{jenjangMadrasah}</div>
      ),
    },
    {
      title: "Provinsi",
      dataIndex: "kodeProvinsi",
      key: "kodeProvinsi",
      render: (kodeProvinsi) => {
        let namaProvinsi: any =
          refProvinsi.find((item) => item.kode === kodeProvinsi) || null;
        return namaProvinsi?.nama;
      },
    },
    {
      title: "Kab / Kota",
      dataIndex: "kodeKabkota",
      key: "kodeKabkota",
      render: (kodeKabkota) => {
        let namaKabkota: any =
          refKabkota.find((item) => item.kode === kodeKabkota) || null;
        return namaKabkota?.nama;
      },
    },
    {
      title: `Penerima BOS ${auth?.isTahun - 1}`,
      key: "penerimaBos",
      dataIndex: "penerimaBos",
      render: (penerimaBos) => {
        return (
          <Tag color={([1, 2].includes(penerimaBos) && "#008000") || "#ffca27"}>
            {([1, 2].includes(penerimaBos) && "Penerima") || "Bukan Penerima"}
          </Tag>
        );
      },
    },
    {
      title: `Tahapan`,
      key: "tahap",
      dataIndex: "tahap",
      render: (tahap) => `Tahap ${tahap}`,
    },
    {
      title: "Status",
      dataIndex: "statusValidasi",
      key: "statusValidasi",
      render: (statusValidasi) => {
        return (
          <Tag color={(statusValidasi === 1 && "#008000") || "#ffca27"}>
            {(statusValidasi === 1 && "Disetujui") || "Belum Disetujui"}
          </Tag>
        );
      },
    },
    {
      title: "Aksi",
      key: "aksi",
      render: (record) => {
        return (
          <Space>
            <Button
              icon={<FileSearchOutlined />}
              onClick={() => handleAfterDokumen(record)}>
              Lihat Dokumen
            </Button>
          </Space>
        );
      },
    },
  ];

  const handleAction = async (action: any) => {
    if (action === "docRKAM6") {
      setOpenModalAction(true);
      setTitle(action);
    } else {
      setOpenModalAction(true);
      setTitle(action);
      setDataAction({
        tahap: dataMadrasah?.tahap,
        checkDataMadrasah: checkDataMadrasah,
        auth: auth,
        madrasahId: dataMadrasah?.madrasahId,
        status: "s",
      });
    }
  };

  const handleAfterDokumen = (record: any) => {
    setDataMadrasah(record);
    handleDokumen(record, record?.tahap);
    setOpenModalData(true);
    getPortalBos(record);
  };

  const getPortalBos = async (values: any) => {
    const dataPortalBos = await getPlanningAll("portal-bos", {
      nsm: values?.nsmMadrasah,
      tahun: auth?.isTahun,
    });

    setNilaiPortalBos(dataPortalBos || null);
    if (values?.jenisMadrasah === "R") {
      const lapErkam = await getLaporanErkam({
        tahun: values?.tahun,
        madrasahId: values?.madrasahId,
      });
      setDataLaporan(lapErkam || []);

      const tahunLpj: any = auth?.isTahun - 1;
      const tmpNilaiPengambalianDana: any =
        await getLaporanPengembalianDanaAxios(
          tahunLpj,
          auth,
          values?.madrasahId,
        );
      setNilaiPengembalianDana(tmpNilaiPengambalianDana || { sisa: 0 });
    } else {
      setNilaiPengembalianDana({ sisa: 0 });
    }
  };

  const handleDokumen = async (record: any, tahap: any) => {
    if (activeTabKey === "pm2") {
      try {
        let params: any = `&tahun=${record?.tahun}&nsm=${record?.nsmMadrasah}&tahap=${tahap}`;
        const res = await getFileMadrasahManual(params);
        if (res) {
          let result: any =
            (res?.length &&
              res.filter((item) => item?.jenisMadrasah === "M")) ||
            [];
          setFileList(result || []);
          // setFileList(res || []);
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        let params: any = `?tahun=${record?.tahun}&madrasahId=${record?.madrasahId}`;
        const res = await getDokumenRencanaDefinitif(params);
        if (res) {
          let result: any =
            (res?.length &&
              res.filter((item) => item?.jenisMadrasah === "R")) ||
            [];
          setFileList(result || []);
          // setFileList(res || []);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleSave = async (values: any) => {
    let keterangan = values?.keterangan || "";
    let statusValid = values?.keterangan ? 2 : 1;
    if (activeTabKey === "pm2") {
      try {
        await statusUploadManual(
          `${id?.id}?statusValidasi=${statusValid}&keterangan=${keterangan}`,
        );
        notifAlert({
          type: "success",
          description: `Dokumen Berhasil ${
            (statusValid === 1 && "Disetujui") || "Ditolak"
          }`,
        });
        handleDokumen(dataMadrasah, dataMadrasah?.tahap);
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        await statusDokumenRencanaDefinitif(
          `${id?.id}?statusValidasi=${statusValid}&keterangan=${keterangan}`,
        );
        notifAlert({
          type: "success",
          description: `Dokumen Berhasil ${
            (statusValid === 1 && "Disetujui") || "Ditolak"
          }`,
        });
        handleDokumen(dataMadrasah, dataMadrasah?.tahap);
      } catch (error) {
        console.log(error);
      }
    }
    form.resetFields();
    setOpenModal(false);
  };

  const getDownloadDokumen = async (params: any, typeDoc: any) => {
    setLoading(true);
    try {
      const res: any = await getDokumenRencanaDefinitifAxios(
        params,
        auth,
        typeDoc,
        dataMadrasah,
      );
      if (res) {
        setPdfFile(res);
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleAfterApproval = (record: any, fileData: any) => {
    let typeDoc: any = activeTabKey === "pm1" ? "application/pdf" : record.type;
    setLoading(true);
    setOpenModal(true);
    setTitle(record.nama);
    setID(fileData);
    setTipeFile(typeDoc);
    getDownloadDokumen(fileData, typeDoc);
  };

  const handlePencairan = async () => {
    const dataPortalBos = await getPlanningAll("portal-bos", {
      nsm: dataMadrasah?.nsmMadrasah,
      tahun: dataMadrasah?.tahun,
    });

    const tmpBos =
      dataMadrasah?.tahap === 1 ? "apbn_bos_semester1" : "apbn_bos_semester2";
    const tmoNilai = (dataPortalBos && dataPortalBos[`${tmpBos}`]) || "1";
    let payload: any = {
      id: uuidv4(),
      tahun: auth?.isTahun,
      statusPencairan: 1,
      tahap: dataMadrasah?.tahap,
      madrasahId: dataMadrasah?.madrasahId || null,
      nsmMadrasah: dataMadrasah?.nsmMadrasah || null,
      namaMadrasah: dataMadrasah?.namaMadrasah || null,
      nilaiPencairan: tmoNilai,
      noBuktiPencairan: "",
      penerimaBos: dataMadrasah?.penerimaBos,
      jenisMadrasah: dataMadrasah?.jenisMadrasah,
    };

    try {
      const res = await postDokumenPencairan(payload);
      if (res) {
        getData();
        setOpenModalData(false);
        notifAlert({
          type: "success",
          description: `Data Dokumen Madrasah ${res?.namaMadrasah} telah dikirim ke Pusat`,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const confirmPencairan = () => {
    Modal.confirm({
      title: "Konfirmasi",
      icon: <ExclamationCircleOutlined />,
      content: "Apakah dokumen Dana BOS akan dicairkan?",
      okText: "Ya",
      onOk() {
        return handlePencairan();
      },
      cancelText: "Batal",
    });
  };

  const handleVerifikasiTombol = () => {
    if (dataMadrasah?.jenisMadrasah === "R") {
      let listVerifikasiDoc: any =
        validasiListDokumen(
          dataMadrasah?.jenisMadrasah,
          dataMadrasah?.tahap,
          dataMadrasah?.penerimaBos,
        ) || [];

      if (nilaiPengembalianDana?.sisa === 0) {
        listVerifikasiDoc = listVerifikasiDoc.filter((item) => item !== 7);

        handleVerifikasiDokumen(
          listVerifikasiDoc,
          fileList.filter(
            (item) =>
              item.tahap === dataMadrasah?.tahap &&
              item.penerimaBos === dataMadrasah?.penerimaBos,
          ),
          // fileList,
          dataMadrasah?.jenisMadrasah,
        );
      } else {
        handleVerifikasiDokumen(
          listVerifikasiDoc,
          fileList.filter(
            (item) =>
              item.tahap === dataMadrasah?.tahap &&
              item.penerimaBos === dataMadrasah?.penerimaBos,
          ),
          // fileList,
          dataMadrasah?.jenisMadrasah,
        );
      }
    } else {
      let listVerifikasiDoc: any =
        validasiListDokumen(
          dataMadrasah?.jenisMadrasah,
          dataMadrasah?.tahap.toString(),
          dataMadrasah?.penerimaBos.toString(),
        ) || [];

      handleVerifikasiDokumen(
        listVerifikasiDoc,
        fileList.filter(
          (item) =>
            item.tahap === dataMadrasah?.tahap &&
            item.penerimaBos.toString() ===
              dataMadrasah?.penerimaBos.toString(),
        ),
        dataMadrasah?.jenisMadrasah,
      );
    }
  };

  const handleVerifikasiDokumen = (arrList: any, arrDoc: any, type: any) => {
    let validDoc: any =
      (type !== "R" && arrDoc.filter((item) => item.kodeDok !== 7)) || arrDoc;
    let checkFile: any = validDoc.filter(
      (item) => !arrList.includes(item.kodeDok),
    );

    let checkFileVerifikasi: any = arrDoc.filter(
      (item) => item.statusValidasi !== 1,
    );

    let checkTolakFileVerifikasi: any =
      arrDoc.find((item) => item.statusValidasi.toString() === "2") || null;
    setTolakButton(checkTolakFileVerifikasi ? false : true);

    let checkFileVerifikasiTrue: any =
      arrDoc
        .filter((item) => item.statusValidasi === 1)
        .filter((item) => arrList.includes(item.kodeDok)) || [];

    if (!getJadwal) {
      const lastJadwal: any =
        listJadwalPengajuan
          .reverse()
          .find((item) => moment().isAfter(item.endDate)) || null;
      let textInformation: any = (
        <div className="text-red-500 font-bold">
          Batas Verifikasi Pengajuan BOS TAHAP {lastJadwal?.tahap} BATCH{" "}
          {lastJadwal?.batch} berakhir pada{" "}
          {moment(lastJadwal?.endDate).add("day", 2).format("DD MMMM YYYY")}{" "}
          pukul 17.00 WIB
        </div>
      );
      setMessageInformation(textInformation);
      setVerificationButton(true);
    } else {
      let textInformation: any = (
        <div className="text-green-500 font-bold">
          Verifikasi Pengajuan BOS TAHAP {getJadwal?.tahap} BATCH{" "}
          {getJadwal?.batch} sedang berjalan di mulai dari{" "}
          {moment(getJadwal?.startDate).format("DD MMMM YYYY")} sampai dengan{" "}
          {moment(getJadwal?.endDate).add("day", 2).format("DD MMMM YYYY")}{" "}
          pukul 17.00 WIB
        </div>
      );
      setMessageInformation(textInformation);

      if (checkFile.length) {
        // console.log(checkFile);
        setVerificationButton(true);

        return;
      } else if (checkFileVerifikasi.length) {
        // console.log(checkFileVerifikasi);

        setVerificationButton(true);
        return;
      } else if (
        checkFileVerifikasiTrue.length.toString() !== arrList.length.toString()
      ) {
        setVerificationButton(true);
      } else if (
        type === "R" &&
        checkDataErkam(dataLaporan, checkDataMadrasah)
      ) {
        setVerificationButton(true);
      } else {
        setVerificationButton(false);
      }
    }
  };

  let columnsDokumenFile: any = [
    {
      title: "Nama File",
      dataIndex: "namaDok",
      key: "namaDok",
    },
    {
      title: "Status",
      dataIndex: "statusValidasi",
      key: "statusValidasi",
      render: (statusValidasi) => (
        <Tag
          color={
            (statusValidasi.toString() === "2" && "red") ||
            (statusValidasi.toString() === "1" && "#008000") ||
            "#ffca27"
          }>
          {(statusValidasi.toString() === "2" && "DiTolak") ||
            (statusValidasi.toString() === "1" && "Disetujui") ||
            "Belum Disetujui"}
        </Tag>
      ),
    },
    {
      title: "Tanggal Upload",
      dataIndex: "created_at",
      key: "created_at",
      render: (created_at) => moment(created_at).format("DD MMMM YYYY HH:mm"),
    },
    {
      title: "Aksi",
      key: "aksi",
      render: (record) => {
        return (
          <Space>
            <Button onClick={() => handleAfterApproval(dataDouble, record)}>
              Lihat Dokumen
            </Button>
            <ButtonTableDelete onClick={() => handleDeleteAction(record)} />
          </Space>
        );
      },
    },
  ];

  const handleTolakDokument = async () => {
    setLoading(true);
    let payload: any = {
      tahap: dataMadrasah?.tahap,
      tahun: dataMadrasah?.tahun,
      nsm: dataMadrasah?.nsmMadrasah,
      keterangan: null,
      statusPenerimaBos: dataMadrasah?.penerimaBos,
    };
    try {
      await tolakPengajuanVerifikasiDokumenBos(payload);
      Modal.success({
        title: "Sukses",
        content: "Dokumen berhasil ditolak",
        centered: true,
        wrapClassName: "erkam-custom-modal",
      });
      getData();
      setOpenModalData(false);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const handleDeleteAction = async (values) => {
    const res = await deleteDokumenRencanaDefinitif(values?.id);
    if (res) {
      getData();
      let fileDoubleTmp: any =
        fileDoubleDok.filter((item) => item.id !== values.id) || [];
      setFileDoubleDok(fileDoubleTmp);
      Modal.success({
        title: "Sukses",
        content: "Dokumen berhasil dihapus",
        centered: true,
        wrapClassName: "erkam-custom-modal",
      });
      handleDokumen(dataMadrasah, dataMadrasah?.tahap);
    }
  };

  const handleDoubleDokumen = (tmpDataDouble: any, fileDouble: any) => {
    setFileDoubleDok(fileDouble);
    setDataDouble(tmpDataDouble);
    setOpenModalDataDouble(true);
  };

  if (
    !tmpSpesialBOSTahap2.includes(dataMadrasah?.nsmMadrasah) &&
    dataMadrasah?.tahap === 2
  ) {
    dataTableDokumen = dataTableDokumen.filter(
      (item) => !["docPPK5", "docBPD", "docPTJM3"].includes(item.key),
    );
  } else {
    dataTableDokumen = dataTableDokumen.filter(
      (item) => !["docSPTJB"].includes(item.key),
    );
  }

  dataTableDokumen =
    ([1, 2].includes(dataMadrasah?.penerimaBos) &&
      dataTableDokumen.filter((item) => item.key !== "docSPPD")) ||
    dataTableDokumen.filter((item) => item.key !== "docLpj1");

  return (
    <>
      <BreadCrumb
        routes={itemBreadcrumb}
        title="Kelengkapan Dokumen BOS"
        footer={
          <Tabs
            defaultActiveKey="pm1"
            size="small"
            onChange={(key) => {
              setFileList([]);
              setTmpFilter(null);
              setActiveTabKey(key);
            }}>
            <TabPane tab="eRKAM" key="pm1" />
            <TabPane tab="Upload Manual" key="pm2" />
          </Tabs>
        }
      />
      <FilterMadrasahUpload
        type={activeTabKey}
        handleChangeValues={handleChangeValues}
      />
      <div className="m-5 p-5 bg-white shadow-md rounded">
        <div className="mb-4 flex justify-end">
          <InputSearch
            className="w-3/4"
            onChange={(e: any) => setSearch(e.currentTarget.value)}
          />
        </div>
        <Table
          loading={loading}
          columns={columns}
          dataSource={dataTable}
          bordered
        />
      </div>
      <Modal
        open={openModal}
        width={1200}
        title={`Approval Dokumen ${title}`}
        onCancel={() => setOpenModal(!openModal)}
        footer={[
          <Button onClick={() => setOpenModal(!openModal)}>Kembali</Button>,
          <Button
            disabled={(dataMadrasah?.statusValidasi === 1 && true) || false}
            onClick={() => form.submit()}
            danger>
            Tolak Dokumen
          </Button>,
          <Button
            // disabled={(dataMadrasah?.statusValidasi === 1 && true) || false}
            type="primary"
            onClick={() => handleSave(1)}>
            Disetujui
          </Button>,
        ]}>
        {tipeFile === "application/pdf" ? (
          <object
            aria-labelledby="rkam"
            data={pdfFile}
            type={tipeFile}
            width="100%"
            height="400px"></object>
        ) : (
          <>
            <Alert
              message="Informasi"
              description="File excel sudah berhasil di download, mohon diperiksa kembali!"
              type="info"
              showIcon
            />
          </>
        )}
        <div className="py-4">
          <Form
            form={form}
            key="formAprrovalDocument"
            name="formAprrovalDocument"
            labelAlign="left"
            onFinish={handleSave}>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: "Keterangan tidak boleh kosong!",
                },
              ]}
              label="Keterangan"
              name="keterangan">
              <Input.TextArea placeholder="Keterangan" />
            </Form.Item>
          </Form>
        </div>
      </Modal>
      <Modal
        open={openModalData}
        width={1200}
        title={`Lihat Dokumen`}
        onCancel={() => {
          setOpenModalData(!openModalData);
          setDataMadrasah(null);
          setNilaiPortalBos(null);
        }}
        footer={[
          <Button
            onClick={() => {
              setOpenModalData(!openModalData);
              setDataMadrasah(null);
              setNilaiPortalBos(null);
            }}>
            Kembali
          </Button>,
          <Button
            loading={loading}
            disabled={
              tolakButton ||
              (dataMadrasah?.statusValidasi === 1 && true) ||
              false
            }
            onClick={handleTolakDokument}
            danger>
            Batalkan Persetujuan
          </Button>,
          <Button
            type="primary"
            loading={loading}
            disabled={
              verificationButton ||
              (dataMadrasah?.statusValidasi === 1 && true) ||
              false
            }
            onClick={confirmPencairan}>
            Disetujui Dokumen Tahap {dataMadrasah?.tahap}
          </Button>,
        ]}>
        {(dataMadrasah?.tahap === 1 && (
          <>
            <Descriptions className="mb-2 px-6" layout="vertical" bordered>
              <Descriptions.Item
                label={`Alokasi Dana BOS TA. ${auth?.isTahun}`}>
                <div className="mb-2 text-xl font-bold">
                  {formatRupiah(
                    nilaiPortalBos?.apbn_bos_semester1 +
                      nilaiPortalBos?.apbn_bos_semester2,
                  )}
                </div>
              </Descriptions.Item>
              <Descriptions.Item
                label={`Nominal APBN-BOS Tahap ${dataMadrasah?.tahap}`}>
                <div className="mb-2 text-xl font-bold">
                  {formatRupiah(
                    (dataMadrasah?.tahap === 1 &&
                      nilaiPortalBos?.apbn_bos_semester1) ||
                      nilaiPortalBos?.apbn_bos_semester2,
                  )}
                </div>
              </Descriptions.Item>
              <Descriptions.Item label="Jumlah Siswa">
                <div className="mb-2 text-xl font-bold">
                  {nilaiPortalBos?.jumlahSiswa} Orang
                </div>
              </Descriptions.Item>
            </Descriptions>
            <Table
              loading={loading}
              columns={columnsDokumen}
              dataSource={
                dataTableDokumen.length
                  ? dataTableDokumen.filter(
                      (item) => item.tahap === 1 && item.key !== "docTBUP",
                    )
                  : []
              }
              bordered
              pagination={false}
            />
          </>
        )) || (
          <>
            <Descriptions
              className="mb-2 px-6"
              layout="vertical"
              column={3}
              bordered>
              <Descriptions.Item
                label={`Alokasi Dana BOS TA. ${auth?.isTahun}`}>
                <div className="mb-2 text-xl font-bold">
                  {formatRupiah(
                    nilaiPortalBos?.apbn_bos_semester1 +
                      nilaiPortalBos?.apbn_bos_semester2,
                  )}
                </div>
              </Descriptions.Item>
              <Descriptions.Item
                label={`Nominal APBN-BOS Tahap ${dataMadrasah?.tahap}`}>
                <div className="mb-2 text-xl font-bold">
                  {formatRupiah(
                    (dataMadrasah?.tahap === 1 &&
                      nilaiPortalBos?.apbn_bos_semester1) ||
                      nilaiPortalBos?.apbn_bos_semester2,
                  )}
                </div>
              </Descriptions.Item>
              {/* <Descriptions.Item label={`Nominal Non AA`}>
                <div className="mb-2 text-xl font-bold">
                  {formatRupiah(
                    (dataMadrasah?.tahap === 1 &&
                      nilaiPortalBos?.apbn_bos_semester1) ||
                      nilaiPortalBos?.apbn_bos_semester2 -
                        nilaiPortalBos?.nilai_aa,
                  )}
                </div>
              </Descriptions.Item>
              <Descriptions.Item label={`Nominal AA`}>
                <div className="mb-2 text-xl font-bold">
                  {formatRupiah(nilaiPortalBos?.nilai_aa)}
                </div>
              </Descriptions.Item> */}
              <Descriptions.Item label="Jumlah Siswa">
                <div className="mb-2 text-xl font-bold">
                  {nilaiPortalBos?.jumlahSiswa} Orang
                </div>
              </Descriptions.Item>
            </Descriptions>
            <Table
              loading={loading}
              columns={columnsDokumen}
              dataSource={
                dataTableDokumen.length
                  ? dataTableDokumen.filter((item) => item.tahap === 2)
                  : []
              }
              bordered
              pagination={false}
            />
          </>
        )}
        <div className="p-4">
          <Alert
            message={
              <div className="p-2">
                <div className="font-bold">{messageInformation}</div>
              </div>
            }
            type="info"
            showIcon
          />
        </div>
      </Modal>
      <Modal
        open={openModalDataDouble}
        width={1200}
        title={`Lihat Dokumen Double`}
        onCancel={() => {
          setOpenModalDataDouble(!openModalDataDouble);
          setFileDoubleDok([]);
          setDataDouble(null);
        }}
        footer={[
          <Button
            onClick={() => {
              setOpenModalDataDouble(!openModalDataDouble);
              setFileDoubleDok([]);
              setDataDouble(null);
            }}>
            Kembali
          </Button>,
        ]}>
        <Table
          loading={loading}
          columns={columnsDokumenFile}
          dataSource={fileDoubleDok}
          bordered
          pagination={false}
        />
      </Modal>
      <ModalDokumenAction
        openModal={openModalAction}
        dataAction={null}
        dataFile={[]}
        dataBOS={null}
        dataLaporan={dataLaporan}
        dataMadrasah={dataAction}
        onClose={() => setOpenModalAction(false)}
        action={title}
      />
    </>
  );
};

export default LaporanDokumen;
