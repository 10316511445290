/** @format */

import React, { useState, useEffect } from "react";
import { Button, Modal, Descriptions } from "antd";
import moment from "moment";
import "moment/locale/id";
import { formatRupiah } from "../../../../utils/helper";
import { UploadPriview } from "../../../../components/Uploads/UploadPriview";

type FormActionType = {
  openModal: boolean;
  title: string;
  store: any;
  data: any;
  files: any;
  handleSave: (e: any) => void;
  handleClose: () => void;
};

const ModalApprovalPindahBuku = ({
  openModal,
  title,
  data,
  store,
  files,
  handleSave,
  handleClose,
}: FormActionType) => {
  const tmpRekeningBelanja = store.rekeningBelanja || [];
  const dataRekening =
    tmpRekeningBelanja.find((item: any) =>
      item.id.includes(data?.rekeningBankId),
    ) || null;
  const dataRekeningTo =
    tmpRekeningBelanja.find((item: any) =>
      item.id.includes(data?.toRekeningBankId),
    ) || null;
  const [fileList, setFileList] = useState<any>([]);

  useEffect(() => {
    let fileTmp: any = [];
    if (files.length) {
      // eslint-disable-next-line array-callback-return
      files.map((item: any) => {
        fileTmp.push({
          status: "done",
          uid: item.id,
          name: item.fileName,
          url: item.fileUrl,
          thumbUrl: item.fileUrl,
        });
      });
    }
    setFileList(fileTmp);
  }, [files]);
  return (
    <Modal
      open={openModal}
      title={`${title} Nota Pindah Buku`}
      width={800}
      onCancel={handleClose}
      footer={
        title === "Approval" && [
          <Button onClick={handleClose}>Batal</Button>,
          <Button onClick={() => handleSave("/disapproval")} danger>
            Tolak
          </Button>,
          <Button
            disabled={(data?.status === "Selesai" && true) || false}
            type="primary"
            onClick={() => handleSave("/approval")}>
            Disetujui
          </Button>,
        ]
      }>
      <Descriptions
        labelStyle={{ fontWeight: 600 }}
        size="small"
        layout="vertical"
        column={{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 1, xs: 1 }}>
        <Descriptions.Item label="No Nota">
          {data?.noNotaFormat}
        </Descriptions.Item>
        <Descriptions.Item label="Tanggal Nota">
          {data?.tanggalNota
            ? moment(data?.tanggalNota).format("dddd,DD MMM YYYY HH:mm:ss")
            : "-"}
        </Descriptions.Item>
        <Descriptions.Item label="No Referensi">
          {data?.realisasiNoReferensi || "-"}
        </Descriptions.Item>
        <Descriptions.Item label="Tanggal Realisasi">
          {data?.tanggalRealisasi
            ? moment(data?.tanggalRealisasi).format("dddd,DD MMM YYYY HH:mm:ss")
            : "-"}
        </Descriptions.Item>
        <Descriptions.Item label="Sumber Dana">
          {data?.rencanaPendapatanName || "-"}
        </Descriptions.Item>
        <Descriptions.Item label="Tipe Kas">
          {data?.namaTipeKas || "-"}
        </Descriptions.Item>
        <Descriptions.Item label="No Rekening">
          {dataRekening ? dataRekening?.no_rekening : "-"}
        </Descriptions.Item>
        <Descriptions.Item label="Rekening Atas Nama">
          {dataRekening ? dataRekening?.no_rekening_nama : "-"}
        </Descriptions.Item>
        <Descriptions.Item label="Nama Bank">
          {dataRekening ? dataRekening?.nama_bank : "-"}
        </Descriptions.Item>
        <Descriptions.Item label="Ke Tipe Kas">
          {data?.namaToTipeKas || "-"}
        </Descriptions.Item>
        <Descriptions.Item label="Ke No Rekening">
          {dataRekeningTo ? dataRekeningTo?.no_rekening : "-"}
        </Descriptions.Item>
        <Descriptions.Item label="Ke Rekening Atas Nama">
          {dataRekeningTo ? dataRekeningTo?.no_rekening_nama : "-"}
        </Descriptions.Item>
        <Descriptions.Item label="Ke Nama Bank">
          {dataRekeningTo ? dataRekeningTo?.nama_bank : "-"}
        </Descriptions.Item>
        <Descriptions.Item label="Total">
          {(data?.grandTotal && formatRupiah(data?.grandTotal)) || "-"}
        </Descriptions.Item>
        <Descriptions.Item label="Keterangan">
          {data?.keterangan || "-"}
        </Descriptions.Item>
      </Descriptions>
      <UploadPriview fileList={fileList} tmpFile={files} />
    </Modal>
  );
};
export default ModalApprovalPindahBuku;
