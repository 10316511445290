/** @format */

import React from "react";
import Background from "../../assets/img/background-home.svg";
import MadrasahReform from "../../assets/img/madrasahReform.svg";
import {
  TwitterOutlined,
  YoutubeFilled,
  FacebookFilled,
  ArrowRightOutlined,
  InstagramFilled,
  DownloadOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { Space, Divider, Badge, Card, Button } from "antd";
import { Link, useHistory } from "react-router-dom";

const Home: React.FC = () => {
  const route = useHistory();
  const filePPT = `/PENJELASAN_SINGKAT_PENGISIAN_EDM_DAN_e-RKAM_v-2.pptx`;
  const openInNewTab = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };
  const baseURLEdm: string =
    window.REACT_APP_API_URL_EDM ||
    "https://latihan-edm-fe.erkam-v2.kemenag.go.id/";
  const baseURLErkamv1: any =
    (window.REACT_APP_API_URL_EDM !==
      "https://latihan-edm-fe.erkam-v2.kemenag.go.id/" &&
      "https://erkam-v1.kemenag.go.id/") ||
    null;
  return (
    <div
      // className="relative flex min-h-screen flex-col  py-6 sm:py-12"
      className="lg:container min-h-screen p-10"
      style={{
        background: `url(${Background})`,
        backgroundSize: "contain",
        backgroundColor: "#F5F5F7",
        backgroundPosition: "top right",
        backgroundRepeat: "no-repeat",
      }}>
      <div className="md:px-10 lg:px-12 flex flex-col">
        <div className="h-auto space-y-12 flex-shrink-0 mb-2 md:px-10">
          <div className="flex flex-col md:flex-row gap-4">
            <div className="flex-auto">
              <img
                aria-hidden="true"
                className="w-auto sm:h-12 mb-4"
                src={MadrasahReform}
                alt="Office"
              />
              <h1 className="home-heading-2 inter-font py-6">
                Realizing Education’s Promise <br />
                Madrasah Education Quality Reform
              </h1>
            </div>
            <div className="flex-auto">
              <div className="mb-2">
                <Badge.Ribbon text="Unduh Disini!!" color="orange">
                  <Card
                    title="Cara Penggunaan EDM dan eRKAM"
                    className="shadow-md"
                    size="small"
                    headStyle={{ fontWeight: 700, fontSize: 18 }}>
                    <div className="mb-2">
                      Panduan singkat pengisian aplikasi EDM V.2 dan eRKAM V.2
                    </div>
                    <div className="text-center">
                      <Link target="_blank" download to={filePPT}>
                        <Button
                          type="primary"
                          shape="round"
                          size="large"
                          icon={<DownloadOutlined />}
                          block>
                          Unduh File
                        </Button>
                      </Link>
                    </div>
                  </Card>
                </Badge.Ribbon>
              </div>
              <Card
                title="Upload Manual"
                className="shadow-md"
                size="small"
                headStyle={{ fontWeight: 700, fontSize: 18 }}>
                <div className="mb-2">Upload Dokumen madrasah non bimtek</div>
                <div className="text-center">
                  <Button
                    onClick={() => route.push("/upload-dokumen")}
                    type="default"
                    shape="round"
                    size="large"
                    style={{ background: "#fa8b15", color: "#FFF" }}
                    icon={<UploadOutlined />}
                    block>
                    Upload Dokumen
                  </Button>
                </div>
              </Card>
            </div>
          </div>

          <div className="inter-font  home-heading-3">
            Pilih untuk masuk ke Aplikasi
          </div>

          <div className="flex flex-wrap gap-4 mb-6">
            <div
              className="flex-1 shadow-md p-10 home-action"
              onClick={() => openInNewTab(baseURLEdm)}>
              <div className="inter-font home-heading-1">EDM V.2</div>
              <div className="inter-font mb-6">
                Evaluasi Diri Madrasah untuk Tahun Anggaran 2022
              </div>
              <div className="button-home ">
                <div className="button-text">
                  Masuk ke EDM v.2 {baseURLErkamv1 ? "" : "Latihan"}
                </div>
                <ArrowRightOutlined className="button-icon" />
              </div>
            </div>
            <div
              className="flex-1 shadow-md p-10 home-action"
              onClick={() => route.push("/login")}>
              <div className="inter-font home-heading-1">eRKAM V.2</div>
              <div className="inter-font  mb-6">
                Rencana Kerja dan Anggaran Madrasah Berbasis Elektronik untuk
                Tahun Anggaran 2023
              </div>
              <div className="button-home ">
                <div className="button-text">
                  Masuk ke eRKAM v.2 {baseURLErkamv1 ? "" : "Latihan"}
                </div>
                <ArrowRightOutlined className="button-icon" />
              </div>
            </div>
            {baseURLErkamv1 && (
              <div
                className="flex-1 shadow-md p-10 home-action"
                // onClick={() => openInNewTab("https://erkam.kemenag.go.id/")}
                onClick={() => openInNewTab(baseURLErkamv1)}>
                <div className="inter-font home-heading-1">eRKAM V.1</div>
                <div className="inter-font  mb-6">
                  Rencana Kerja dan Anggaran Madrasah Berbasis Elektronik untuk
                  Tahun Anggaran 2022, 2021
                </div>
                <div className="button-home ">
                  <div className="button-text">Masuk ke eRKAM v.1</div>
                  <ArrowRightOutlined className="button-icon" />
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="py-4 flex-shrink mt-4 md:px-8">
          <Divider className="mt-4" />
          <div className="flex md:flex-row gap-2">
            <div className="flex-1 ">
              © Copyright Kementerian Agama RI 2021 - 2022
            </div>
            <div className="flex-1 text-right">
              <Space size="middle">
                <a
                  href="https://www.youtube.com/channel/UCg8r6lAzXP9OCyR0G1uBewg"
                  // eslint-disable-next-line react/jsx-no-target-blank
                  target="_blank"
                  rel="noreferrer"
                  className=" font-light text-gray-700 dark:text-gray-700">
                  <YoutubeFilled style={{ fontSize: "18px" }} />
                </a>
                <a
                  href="https://www.instagram.com/kemenag_ri"
                  // eslint-disable-next-line react/jsx-no-target-blank
                  target="_blank"
                  rel="noreferrer"
                  className=" font-light text-gray-700 dark:text-gray-700">
                  <InstagramFilled style={{ fontSize: "18px" }} />
                </a>
                <a
                  href="https://facebook.com/KementerianAgamaRI"
                  // eslint-disable-next-line react/jsx-no-target-blank
                  target="_blank"
                  rel="noreferrer"
                  className=" font-light text-gray-700 dark:text-gray-700">
                  <FacebookFilled style={{ fontSize: "18px" }} />
                </a>
                <a
                  href="https://twitter.com/Kemenag_RI"
                  // eslint-disable-next-line react/jsx-no-target-blank
                  target="_blank"
                  rel="noreferrer"
                  className=" font-light text-gray-700 dark:text-gray-700">
                  <TwitterOutlined style={{ fontSize: "18px" }} />
                </a>
              </Space>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Home;
