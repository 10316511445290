/** @format */

import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Space, Table, Button, Tabs } from "antd";
import { BreadCrumb } from "../../components";
import { ButtonDropdownExport } from "../../components/Button";
// import moment from "moment";
// import "moment/locale/id";
import notifAlert from "../../components/NotifAlert";
import InputSearch from "../../components/InputSearch";
import jsPDF from "jspdf";
import * as FileSaver from "file-saver";
import ExcelJS from "exceljs";
import autoTable from "jspdf-autotable";
import { useHistory } from "react-router-dom";
import { formatCurr } from "../../utils/helper";
import {
  getBPKSumberDanaAanggaranRealisasi,
  getBPKSummaryAanggaranRealisasiProvinsi,
} from "../../services/v2/reportservice";
const { TabPane } = Tabs;

const TransaksiSPJ = () => {
  const itemBreadcrumb = [
    { path: "/", breadcrumbName: "Home" },
    { path: "/", breadcrumbName: "Laporan BKU dan SPJ" },
  ];

  const route = useHistory();
  const dataState: any = route ? route.location.state : null;
  const auths = useSelector((state: any) => state.auth);
  const auth = auths?.data || null;

  const [search, setSearch] = useState<any>(null);
  const [tmpMadrasah, setTmpMadrasah] = useState<any>([]);
  const [refSumberDana, setRefSumberDana] = useState<any>([]);

  const [activeTabKey, setActiveTabKey] = useState<string>("");
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(dataState?.page || 1);
  const [pageSize, setPageSize] = useState(dataState?.pageSize || 10);
  const tmpSumberDana: any =
    refSumberDana.find((item) => item.kodeSumberDana === activeTabKey) || null;

  useEffect(() => {
    getDataSumberDana();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth?.isTahun]);

  useEffect(() => {
    getData(activeTabKey);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth?.isTahun, activeTabKey]);

  /** Filter Search */
  let dataTable: any = search
    ? tmpMadrasah.filter((item: any) => {
        const val = search.toLowerCase();
        return (
          // (item.namaProvinsi !== null &&
          //   (item.namaProvinsi || "").toString().toLowerCase().includes(val)) ||
          item.namaKabkota !== null &&
          (item.namaKabkota || "").toString().toLowerCase().includes(val)
        );
      })
    : tmpMadrasah;
  let totalDataTable = dataTable.length;
  /**
   * Filter Pagination
   */
  dataTable = dataTable.filter((v, i) => {
    let start = pageSize * (page - 1);
    let end = start + pageSize;

    return i >= start && i < end;
  });

  const getData = async (kodeSumberDana: any) => {
    setLoading(true);
    if (kodeSumberDana) {
      try {
        //
        const tmpKonsiliasi =
          (await getBPKSummaryAanggaranRealisasiProvinsi(
            auth?.isTahun,
            auth,
            auth?.kode_provinsi,
            kodeSumberDana,
          )) || [];
        setTmpMadrasah(tmpKonsiliasi || []);
      } catch (error) {
        console.log(error);
      }
    }

    setLoading(false);
  };

  const getDataSumberDana = async () => {
    setLoading(true);
    try {
      //
      const tmplistSumberDana =
        (await getBPKSumberDanaAanggaranRealisasi(auth?.isTahun, auth)) || [];
      const listSumberDana = tmplistSumberDana?.sort((a, b) =>
        a.namaSumberDana.localeCompare(b.namaSumberDana),
      );
      setRefSumberDana(listSumberDana);
      if (listSumberDana.length) {
        setActiveTabKey(listSumberDana[0].kodeSumberDana);
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  // const handleChangeValues = (values: any) => {
  //   setTmpFilter(values);
  // };

  const handleExportFile = (type: any) => {
    if (dataTable.length > 0) {
      if (type === "pdf") {
        handleExportPdf();
      } else {
        handleExportExcel();
      }
    } else {
      notifAlert({
        type: "warning",
        description: "Maaf data tidak tersedia!",
      });
    }
  };

  const handleExportExcel = async () => {
    var ExcelJSWorkbook = new ExcelJS.Workbook();
    var worksheet = ExcelJSWorkbook.addWorksheet("Data");

    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";

    worksheet.mergeCells("A1:E1");
    worksheet.getColumn("A").width = 5;
    worksheet.getColumn("B").width = 14;
    worksheet.getColumn("C").width = 40;
    worksheet.getColumn("D").width = 40;

    const headerCell = worksheet.getCell("A1");
    headerCell.font = {
      size: 11,
      underline: true,
      bold: true,
    };
    headerCell.alignment = { vertical: "middle", horizontal: "center" };
    headerCell.value = `Laporan BKU dan SPJ  ${tmpSumberDana?.namaSumberDana} T.A ${auth.isTahun}`;

    let dataTmp: any = [];
    tmpMadrasah.map((e, i) => {
      return dataTmp.push([
        i + 1,
        e.namaKabkota,
        formatCurr(e.realisasiPendapatan),
        formatCurr(e.realisasiBelanja),
      ]);
    });

    worksheet.addTable({
      name: "Table",
      ref: "A3",
      headerRow: true,
      style: {
        showRowStripes: true,
      },
      columns: [
        { name: "No" },
        { name: "Kab / Kota" },
        { name: "Pendapatan" },
        { name: "Belanja" },
      ],
      rows: dataTmp,
    });

    const excelBuffer = await ExcelJSWorkbook.xlsx.writeBuffer();
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, `transaksi-spj${fileExtension}`);

    notifAlert({
      type: "success",
      description: "Data berhasil di export",
    });
  };

  function handleExportPdf() {
    const doc: any = new jsPDF({
      putOnlyUsedFonts: true,
      orientation: "landscape",
      floatPrecision: 16,
    });

    doc.setFont("times", "bold");
    doc.text(
      `Laporan BKU dan SPJ ${tmpSumberDana?.namaSumberDana} T.A ${auth.isTahun}`,
      150,
      10,
      {
        align: "center",
      },
    );

    var headers: any = [
      { id: "id", dataKey: "id", padding: 0, header: "No", width: 22 },
      {
        id: "namaKabKota",
        dataKey: "namaKabKota",
        padding: 0,
        header: "Kab / Kota",
        width: 50,
      },
      {
        id: "pendapatan",
        dataKey: "realisasiPendapatan",
        padding: 0,
        header: "Pendapatan",
        width: 80,
      },
      {
        id: "belanja",
        dataKey: "realisasiBelanja",
        padding: 0,
        header: "Belanja",
        width: 80,
      },
    ];
    let las: any = [];
    // const count = type === "pdf_all" ? tableData.length : 10;
    autoTable(doc, {
      styles: { lineColor: 244, lineWidth: 0.1, fontSize: 9 },
      headStyles: {
        halign: "center",
        valign: "middle",
        fillColor: [0, 128, 0],
      }, // Cells in first column centered and green
      columnStyles: {
        0: { halign: "center", cellWidth: 20 },
        1: { halign: "center", cellWidth: 120 },
        2: { halign: "right", cellWidth: 55 },
        3: { halign: "right", cellWidth: 55 },
      },
      startY: 15,
      margin: { top: 5, left: 25, right: 25 },
      columns: headers,
      body: tmpMadrasah.map((e, i) => {
        return {
          id: i + 1,
          namaKabKota: e?.namaKabKota || "",
          realisasiPendapatan: formatCurr(e.realisasiPendapatan),
          realisasiBelanja: formatCurr(e.realisasiBelanja),
        };
      }),
      didDrawPage: (d) => las.push(d.cursor),
    });
    var blob = doc.output("blob", { filename: "transaksi-spj-sumber-dana" });
    window.open(URL.createObjectURL(blob));
    // doc.output("dataurlnewwindow", { filename: "alokasi-sumber-dana-bos" });
    notifAlert({
      type: "success",
      description: "Data berhasil di export",
    });
  }

  let columnsDokumen: any = [
    {
      title: "No",
      key: "no",
      width: 70,
      render: (_, record, i: number) => i + 1 + (page - 1) * pageSize,
    },
    {
      title: "Kab / Kota",
      dataIndex: "namaKabKota",
      key: "namaKabKota",
      width: 230,
    },
    {
      title: "Pendapatan",
      dataIndex: "realisasiPendapatan",
      width: 100,
      key: "realisasiPendapatan",
      render: (realisasiPendapatan) => (
        <div className="text-right">{formatCurr(realisasiPendapatan)}</div>
      ),
    },
    {
      title: "Belanja",
      dataIndex: "realisasiBelanja",
      width: 100,
      key: "realisasiBelanja",
      render: (realisasiBelanja) => (
        <div className="text-right">{formatCurr(realisasiBelanja)}</div>
      ),
    },
    {
      title: "Aksi",
      key: "aksi",
      width: 140,
      fixed: "right",
      render: (record) => {
        return (
          <Space>
            {/* <ButtonTableApproval onClick={() => handleAfterApproval(record)} /> */}
            <Button
              type="primary"
              onClick={() =>
                route.push({
                  pathname: `/transaksi-spj/listkabkota/${record.namaKabKota}`,
                  state: {
                    page: page,
                    pageSize: pageSize,
                    dataProvinsi: dataState,
                    backUrl: "prov",
                    tahap: activeTabKey,
                    sumberDana: tmpSumberDana,
                    namaKabKota: record?.namaKabKota,
                    listMadrasah: record?.listMadrasah || [],
                    ...record,
                  },
                })
              }>
              Lihat Detail
            </Button>
          </Space>
        );
      },
    },
  ];

  return (
    <>
      <BreadCrumb
        routes={itemBreadcrumb}
        title={`Sumber Dana ${tmpSumberDana?.namaSumberDana}`}
        footer={
          <Tabs
            activeKey={activeTabKey}
            size="small"
            onChange={(key) => {
              // setFileList([]);
              // setTmpFilter(null);
              setActiveTabKey(key);
            }}>
            {refSumberDana.length &&
              refSumberDana.map((item) => (
                <TabPane
                  tab={`${item.namaSumberDana}`}
                  key={`${item.kodeSumberDana}`}
                />
              ))}
          </Tabs>
        }
      />
      <div className="m-5 p-5 bg-white shadow-md rounded">
        <div className="mb-4 flex justify-end">
          <div className="mr-4">
            <Space wrap>
              <ButtonDropdownExport handleExportFile={handleExportFile} />
            </Space>
          </div>
          <InputSearch
            className="w-3/4"
            onChange={(e: any) => setSearch(e.currentTarget.value)}
          />
        </div>
        <Table
          loading={loading}
          columns={columnsDokumen}
          scroll={{ x: "1300" }}
          dataSource={dataTable}
          bordered
          pagination={{
            total: totalDataTable,
            position: ["bottomRight"],
            defaultPageSize: pageSize,
            defaultCurrent: page,
            showTotal: (total) => `Total ${total} items`,
            onChange(page, pageSize) {
              setPage(page);
              setPageSize(pageSize);
            },
          }}
        />
      </div>
    </>
  );
};

export default TransaksiSPJ;
