/** @format */

import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Modal, Space, Table, Button, Form, Input } from "antd";
import { BreadCrumb } from "../../../../components";
import { ButtonDropdownExport } from "../../../../components/Button";
// import moment from "moment";
// import "moment/locale/id";
import notifAlert from "../../../../components/NotifAlert";
import { editDokumenPencairan } from "../../../../services/v2/planningservice/pencairanservices";
import InputSearch from "../../../../components/InputSearch";
import { formatRupiah } from "../../../../utils/helper";
// import jsPDF from "jspdf";
// import * as FileSaver from "file-saver";
// import ExcelJS from "exceljs";
// import autoTable from "jspdf-autotable";
import dataDokumen from "../dummy";
import { useHistory } from "react-router-dom";
import { DokumenStatistic } from "../../../../components/Statistic";

const DokumenPencairan = () => {
  const itemBreadcrumb = [
    { path: "/", breadcrumbName: "Home" },
    { path: "/", breadcrumbName: "Laporan" },
    { path: "/", breadcrumbName: "Laporan Rencana" },
    { path: "/", breadcrumbName: "Pencairan Dokumen BOS" },
  ];
  const route = useHistory();
  const dataState: any = route ? route.location.state : null;
  const auths = useSelector((state: any) => state.auth);
  const store = useSelector((state: any) => state.store);
  const refProvinsi = store.provinsi || [];
  const [formData] = Form.useForm();
  const [id, setID] = useState<any>(null);
  const auth = auths?.data || null;
  const [search, setSearch] = useState<any>(null);
  const [tmpMadrasah, setTmpMadrasah] = useState<any>([]);
  const [dataMadrasah, setDataMadrasah] = useState<any>(null);
  // const [tmpFilter, setTmpFilter] = useState<any>(null);
  const [openModal, setOpenModal] = useState<any>(false);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(dataState?.page || 1);
  const [pageSize, setPageSize] = useState(dataState?.pageSize || 10);

  useEffect(() => {
    if (auths?.isLogin) {
      getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth?.isTahun]);

  let dataTable: any = tmpMadrasah.map((item, i) => {
    let namaProvinsi: any =
      refProvinsi.find(
        (items) => items?.kode.toString() === item.kodeProvinsi.toString(),
      ) || null;

    return { no: i + 1, namaProvinsi: namaProvinsi?.nama, ...item };
  });

  /** Filter Search */
  dataTable = search
    ? dataTable.filter((item: any) => {
        const val = search.toLowerCase();
        return (
          (item.namaProvinsi !== null &&
            (item.namaProvinsi || "").toString().toLowerCase().includes(val)) ||
          (item.totalMadrasah !== null &&
            (item.totalMadrasah || "").toString().toLowerCase().includes(val))
        );
      })
    : dataTable;
  let totalDataTable = dataTable.length;
  /**
   * Filter Pagination
   */
  dataTable = dataTable.filter((v, i) => {
    let start = pageSize * (page - 1);
    let end = start + pageSize;

    return i >= start && i < end;
  });

  const getData = async () => {
    setLoading(true);
    try {
      setTmpMadrasah(dataDokumen());
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  // const handleChangeValues = (values: any) => {
  //   setTmpFilter(values);
  // };

  const handleExportFile = (type: any) => {
    if (dataTable.length > 0) {
      if (type === "pdf") {
        handleExportPdf();
      } else if (type === "excel") {
        handleExportExcel();
      }
    } else {
      notifAlert({
        type: "warning",
        description: "Maaf data tidak tersedia!",
      });
    }
  };

  const handleExportExcel = async () => {};

  function handleExportPdf() {}

  // const handleDeleteUpload = async (idFile: any) => {
  //   try {
  //     await deleteDokumenPencairan(idFile);
  //     notifAlert({
  //       type: "success",
  //       description: "Penghapusan Dokumen Berhasil",
  //     });
  //     getData();
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  let columnsDokumen: any = [
    {
      title: "No",
      key: "no",
      dataIndex: "no",
    },
    {
      title: "Kode",
      dataIndex: "kodeProvinsi",
      key: "kodeProvinsi",
    },
    {
      title: "Provinsi",
      dataIndex: "namaProvinsi",
      key: "namaProvinsi",
    },
    {
      title: "Total Madrasah",
      dataIndex: "totalMadrasah",
      key: "totalMadrasah",
      render: (totalMadrasah) => `${totalMadrasah} Madrasah`,
    },
    {
      title: "Total Madrasah Selesai",
      dataIndex: "totalSelesai",
      key: "totalSelesai",
      render: (totalSelesai) => `${totalSelesai} Madrasah`,
    },
    {
      title: "Total Madrasah Belum Selesai",
      key: "totalSelesai",
      render: (record) =>
        `${record?.totalMadrasah - record?.totalSelesai} Madrasah`,
    },
    {
      title: "Aksi",
      key: "aksi",
      render: (record) => {
        return (
          <Space>
            <Button
              onClick={() =>
                route.push({
                  pathname: `/laporan/pencairan-dokumen-bos/listprov/${record.kodeProvinsi}`,
                  state: {
                    page: page,
                    pageSize: pageSize,
                    ...record,
                  },
                })
              }
              type="primary">
              Lihat Detail
            </Button>
          </Space>
        );
      },
    },
  ];

  const handleSave = async (values: any) => {
    let payload: any = {
      statusPencairan: 1,
      id: id,
      tahun: dataMadrasah?.tahun,
      tahap: dataMadrasah?.tahap,
      nsmMadrasah: dataMadrasah?.nsmMadrasah,
      madrasahId: dataMadrasah?.madrasahId,
      nilaiPencairan: dataMadrasah?.nilaiPencairan,
      noBuktiPencairan: values.noBuktiPencairan,
    };
    try {
      await editDokumenPencairan(payload, id);
      notifAlert({
        type: "success",
        description: "Dokumen Berhasil disetujui",
      });
      getData();
    } catch (error) {
      console.log(error);
    }
    setOpenModal(false);
  };

  const handleReset = () => {
    setOpenModal(false);
    setDataMadrasah(null);
    setID(null);
    formData.resetFields();
  };

  return (
    <>
      <BreadCrumb routes={itemBreadcrumb} title="Pencairan Dokumen BOS" />
      <DokumenStatistic />
      {/* <FilterPencairanBos handleChangeValues={handleChangeValues} /> */}
      <div className="m-5 p-5 bg-white shadow-md rounded">
        <div className="mb-4 flex justify-end">
          <div className="mr-4">
            <Space wrap>
              <Button
                type="primary"
                onClick={() =>
                  route.push({
                    pathname: `/laporan/pencairan-dokumen-bos/action`,
                    state: {
                      page: page,
                      pageSize: pageSize,
                    },
                  })
                }>
                Pencairan
              </Button>
              <ButtonDropdownExport handleExportFile={handleExportFile} />
            </Space>
          </div>
          <InputSearch
            className="w-3/4"
            onChange={(e: any) => setSearch(e.currentTarget.value)}
          />
        </div>
        <Table
          loading={loading}
          columns={columnsDokumen}
          dataSource={dataTable}
          bordered
          pagination={{
            total: totalDataTable,
            position: ["bottomRight"],
            defaultPageSize: pageSize,
            defaultCurrent: page,
            showTotal: (total) => `Total ${total} items`,
            onChange(page, pageSize) {
              setPage(page);
              setPageSize(pageSize);
            },
          }}
        />
      </div>
      <Modal
        open={openModal}
        width={1000}
        title={`Approval Pencairan Dokumen Dana BOS`}
        onCancel={handleReset}
        footer={[
          <Button onClick={handleReset}>Batal</Button>,
          <Button type="primary" onClick={() => formData.submit()}>
            Disetujui
          </Button>,
        ]}>
        <Form
          form={formData}
          name="formPencairan"
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 8 }}
          labelAlign="left"
          labelWrap
          layout="horizontal"
          onFinish={handleSave}>
          <Form.Item
            label="No Bukti Pencairan"
            name="noBuktiPencairan"
            rules={[
              {
                required: true,
                message: "Nomor Bukti Pencairan tidak boleh kosong!",
              },
            ]}>
            <Input placeholder="Silahkan isi No Bukti Pencairan" />
          </Form.Item>
          {/* <Form.Item
            label="Nilai Pencairan"
            name="nilaiPencairan"
            rules={[
              {
                required: true,
                message: "Nilai Pencairan tidak boleh kosong!",
              },
            ]}>
            <InputNumber
              style={{ width: "100%" }}
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
              prefix="Rp."
              placeholder="Nilai Pencairan"
            />
          </Form.Item> */}
          <Form.Item label="Nilai Pencairan">
            {formatRupiah(dataMadrasah?.nilaiPencairan) || 0}
          </Form.Item>
          <Form.Item label="Madrasah">
            {dataMadrasah?.namaMadrasah || ""}
          </Form.Item>
          <Form.Item label="NSM">{dataMadrasah?.namaMadrasah || ""}</Form.Item>
          <Form.Item label="Madrasah">
            {dataMadrasah?.nsmMadrasah || ""}
          </Form.Item>
          <Form.Item label="Tahap">{dataMadrasah?.tahap || ""}</Form.Item>
          <Form.Item label="Jenis Upload">
            {dataMadrasah?.jenisMadrasah === "R"
              ? "eRkam"
              : "Upload Manual" || ""}
          </Form.Item>
        </Form>
        {/* <Descriptions
          labelStyle={{ fontWeight: 600 }}
          column={2}
          layout="vertical">
          <Descriptions.Item label="Nama">
            {dataMadrasah?.namaMadrasah || ""}
          </Descriptions.Item>
          <Descriptions.Item label="NSM">
            {dataMadrasah?.nsmMadrasah || ""}
          </Descriptions.Item>
          <Descriptions.Item label="Status Penerima BOS 2022">
            {dataMadrasah?.penerimaBos.toString() === "1"
              ? "Penerima BOS 2022"
              : "Bukan Penerima BOS 2022" || ""}
          </Descriptions.Item>
          <Descriptions.Item label="Tahap">
            {dataMadrasah?.tahap || ""}
          </Descriptions.Item>
          <Descriptions.Item label="Jenis Upload">
            {dataMadrasah?.jenisMadrasah === "R"
              ? "eRkam"
              : "Upload Manual" || ""}
          </Descriptions.Item>
        </Descriptions> */}
      </Modal>
    </>
  );
};

export default DokumenPencairan;
