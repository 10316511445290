/** @format */

import React, { useState, useEffect } from "react";
import { BreadCrumb } from "../../../../components";
import {
  Table,
  Select,
  Modal,
  Space,
  Button,
  Form,
  Input,
  Switch,
  Tag,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { uuidv4 } from "../../../../utils/helper";
import {
  ButtonTableDelete,
  ButtonTableEdit,
  ButtonTambah,
} from "../../../../components/Button";
import notifAlert from "../../../../components/NotifAlert";
import { setStore } from "../../../../redux/actions";
import InputSearch from "../../../../components/InputSearch";
import { getUsermanAll } from "../../../../services/v2/usermanservice";
import {
  deletePengawas,
  editPengawas,
  // editUsers,
  postPengawas,
} from "../../../../services/v2/usermanservice/managementservices";

import { getMadrasah } from "../../../../services/v2/usermanservice/madrasahservices";
import { getUsermanAllPage } from "../../../../services/v2/usermanservice/indexpage";

const Pengawas = () => {
  const itemBreadcrumb = [
    { path: "/", breadcrumbName: "Home" },
    { path: "/", breadcrumbName: "Pengaturan" },
    { path: "/", breadcrumbName: "Managemen User" },
    { path: "/", breadcrumbName: "Pengawas BKBA" },
  ];
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [search, setSearch] = useState<any>("");
  const store = useSelector((state: any) => state.store);
  const authTmp = useSelector((state: any) => state.auth);
  const auth = authTmp?.data || null;
  const profile = store?.profile || null;
  // const groupRole = auth?.group_role || null;
  const kodeRole = auth?.kode_role || null;
  const [tmpRole, setTmpRole] = useState<any>([]);
  const [openModalAction, setOpenModalAction] = useState(false);
  const [tableData, setTableData] = useState<any>([]);
  const refProvinsi = store?.provinsi || [];
  const refKabkota = store?.kabkota || [];
  const [provinsi, setProvinsi] = useState<any>(null);
  const [madrasah, setMadrasah] = useState<any>([]);
  const [title, setTitle] = useState("");
  const [id, setID] = useState<any>(null);
  const [dataUser, setDataUser] = useState<any>(null);
  const [isLoading, setLoading] = useState(false);

  const tmpListMadrasah = store?.listMadrasah || [];
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalDataTable, setTotalDataTable] = useState(0);

  const columns: any = [
    {
      title: "Nama",
      key: "nama",
      width: 200,
      onFilter: (value, record) => record?.nama.indexOf(value) === 0,
      sorter: (a, b) => a?.profile?.nama - b?.profile?.nama,
      render: (record) => record?.profile?.nama,
    },
    {
      title: "Username",
      key: "nik",
      width: 200,
      onFilter: (value, record) => record.nik.indexOf(value) === 0,
      sorter: (a, b) => a?.profile?.nik - b?.profile?.nik,
      render: (record) => record?.profile?.nik,
    },
    {
      title: "Email",
      key: "email",
      width: 300,
      onFilter: (value, record) => record?.email.indexOf(value) === 0,
      sorter: (a, b) => a?.profile?.user.email - b?.profile?.user.email,
      render: (record) => record?.profile?.user.email,
    },
    {
      title: "Madrasah",
      key: "madrasah",
      dataIndex: "listMadrasahIdPengawas",
      width: 230,
      render: (listMadrasahIdPengawas) => {
        return (
          listMadrasahIdPengawas?.length &&
          listMadrasahIdPengawas?.map((item) => {
            let namaMadrasah =
              tmpListMadrasah.find((items) => items.id.includes(item?.id)) ||
              null;
            return (
              namaMadrasah?.nama && (
                <Tag
                  color={
                    (namaMadrasah?.jenjang?.kode === "ra" && "purple") ||
                    (namaMadrasah?.jenjang?.kode === "ma" && "geekblue") ||
                    (namaMadrasah?.jenjang?.kode === "mts" && "blue") ||
                    (namaMadrasah?.jenjang?.kode === "mi" && "cyan") ||
                    "blue"
                  }>
                  {namaMadrasah.nama}
                </Tag>
              )
            );
          })
        );
      },
    },
    {
      title: "Role",
      key: "kodeRole",
      dataIndex: "kodeRole",
      width: 230,
      render: (kodeRole) =>
        tmpRole.find((items) => items.kode === kodeRole)?.nama || "-",
    },
    {
      title: "Valid Email",
      key: "validEmail",
      width: 80,
      render: (record) =>
        record?.profile?.requireEmail === 1 ? (
          <Tag color="#87d068">Aktif</Tag>
        ) : (
          <Tag>Tidak Aktif</Tag>
        ),
    },
    {
      title: "Validasi Email",
      key: "validEmail",
      width: 100,
      render: (record) =>
        record?.profile?.user?.valid_email ? (
          <Tag color="#87d068">Aktif</Tag>
        ) : (
          <Tag>Tidak Aktif</Tag>
        ),
    },
    {
      title: "Status",
      key: "status",
      fixed: "right",
      width: 100,
      filters: [
        {
          text: "Aktif",
          value: 1,
        },
        {
          text: "Tidak Aktif",
          value: 0,
        },
      ],
      onFilter: (value: string, record) =>
        record?.profile?.user?.activated === value,
      render: (record) =>
        record?.profile?.user?.activated ? (
          <Tag color="#87d068">Aktif</Tag>
        ) : (
          <Tag>Tidak Aktif</Tag>
        ),
    },
    {
      title: "Aksi",
      fixed: "right",
      width: 140,
      render: (record) => (
        <Space size="small">
          <ButtonTableEdit onClick={() => handleEdit(record)} />

          <ButtonTableDelete
            onClick={() => {
              Modal.confirm({
                title: "Perhatian",
                content: "Yakin Hapus Data?",
                onOk() {
                  return handleDelete(record);
                },
                onCancel() {},
                okText: "Hapus",
                cancelText: "Batal",
                okType: "danger",
              });
            }}
          />
        </Space>
      ),
    },
  ];

  const getData = async (
    tmpPage?: any,
    size: number | null = null,
    _search?: string,
  ) => {
    setLoading(true);

    const res = await getUsermanAllPage("management-user/get-user", {
      activated: 1,
      group: "pusat",
      page: tmpPage,
      size: size,
      kodeRole: "bkba_pengawas",
      search: _search,
    });

    const refRole = await getUsermanAll("role", {
      activated: 1,
      group: "pusat",
    });

    if (tmpListMadrasah.length === 0) {
      let filter: any = "";
      const madrasah = await getMadrasah(filter);
      const listMadrasah = madrasah || [];
      setTimeout(() => {
        dispatch(setStore({ listMadrasah }));
      }, 100);
      setMadrasah(madrasah);
    } else {
      setMadrasah(tmpListMadrasah);
    }

    setTmpRole(refRole || []);
    setTableData(res?.return || []);
    setTotalDataTable(res?.totalElements || 0);
    setLoading(false);
  };

  //   const getDataReset = async (page?: number, size: number | null = null) => {
  //     setLoading(true);

  //     const res = await getUsermanAllPage("management-user/get-user-page", {
  //       activated: 1,
  //       group: "pusat",
  //       page: currentPage - 1,
  //       size: size !== null ? size : undefined,
  //     });
  //     const refRole = await getUsermanAll("role", {
  //       activated: 1,
  //       group: "pusat",
  //     });

  //     if (tmpListMadrasah.length === 0) {
  //       let filter: any = "";

  //       const madrasah = await getMadrasah(filter);
  //       const listMadrasah = madrasah || [];
  //       setTimeout(() => {
  //         dispatch(setStore({ listMadrasah }));
  //       }, 100);
  //       setMadrasah(madrasah);
  //     } else {
  //       setMadrasah(tmpListMadrasah);
  //     }

  //     const response =
  //       groupRole === "pusat" &&
  //       res.return.filter((item) => item.kodeRole === "bkba_pengawas");

  //     setTmpRole(refRole || []);
  //     setTableData(response || []);
  //     setTotalElements(res.totalElements);
  //     setLoading(false);
  //   };

  useEffect(() => {
    getData(page, pageSize, search);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, pageSize, search]);

  const handleChange = (values: any) => {
    for (let obj in values) {
      switch (obj) {
        case "provinsi":
          setProvinsi(values.provinsi);
          // setKabkota(null);
          form.setFieldsValue({ kabkota: null });
          break;
        //   case "kabkota":
        //     setKabkota(values.kabkota);
        //     form.setFieldsValue({ madrasah: null });
        //     break;
      }
    }
  };

  const handlePageChange = async (pagex: any, pageSize: any) => {
    setPage(pagex);
    setPageSize(pageSize);
  };

  const dataTable = search
    ? tableData.filter((item: any) => {
        const val = search.toLowerCase();
        return (
          (item?.kode_role !== null &&
            (item?.kode_role || "").toLowerCase().includes(val)) ||
          (item?.profile?.nik !== null &&
            (item?.profile?.nik || "").toLowerCase().includes(val)) ||
          (item?.profile?.nama !== null &&
            (item?.profile?.nama || "").toLowerCase().includes(val)) ||
          (item?.profile?.user?.email !== null &&
            (item?.profile?.user?.email || "").toLowerCase().includes(val))
        );
      })
    : tableData;

  function handleEdit(record: any) {
    let editListMadrasah: any =
      (record?.listMadrasahIdPengawas?.length &&
        record?.listMadrasahIdPengawas.map((item) => item.id)) ||
      [];
    setOpenModalAction(true);
    setTitle("Edit");
    setDataUser(record);
    setID(record?.userId);
    setProvinsi(record.kodeProvinsi);

    form.setFieldsValue({
      kodeRole: record.kodeRole,
      nama: record.profile.nama,
      nik: record.profile.nik,
      status: record.profile.user.valid_email,
      email: record.profile.user.email,
      madrasah: editListMadrasah,
      provinsi: record.kodeProvinsi,
      kabkota: record.kodeKabkota,
    });
  }

  const handleTambah = () => {
    setOpenModalAction(true);
    setTitle("Tambah");
    form.resetFields();
  };

  const handleDelete = async (record: any) => {
    setLoading(true);
    try {
      await deletePengawas(record.userId);
      notifAlert({ type: "success", description: "Data berhasil dihapus" });
      getData(page, pageSize, search);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const handleSave = async (values: any) => {
    setLoading(true);
    let listMadrasah: any = [];
    values?.madrasah.length &&
      // eslint-disable-next-line array-callback-return
      values.madrasah.map((item) => {
        let tmMadrasah: any =
          tmpListMadrasah.find((items) => items.id.includes(item)) || null;
        tmMadrasah &&
          listMadrasah.push({
            email: tmMadrasah.email,
            alamat: tmMadrasah.alamat_jalan,
            nsm: tmMadrasah.nsm,
            kode_kabkota: tmMadrasah.kode_kabkota,
            kode_provinsi: tmMadrasah.kode_provinsi,
            id: tmMadrasah.id,
          });
      });
    let payload: any = {
      nik: values.nik,
      password: values.password,
      email: values.email,
      nama: values.nama,
      activated: 1,
      kode_role: "bkba_pengawas",
      kode_kabkota: values?.kabkota,
      kode_provinsi: values?.provinsi,
      require_email: values.status ? 1 : 0,
      list_madrasah_id_pengawas: listMadrasah,
      kantor_kabkota_id: profile?.kantor_kabkota_id,
      kantor_provinsi_id: profile?.kantor_provinsi_id,
      kantor_pusat_id: profile?.kantor_pusat_id,
      role_user_id: title === "Tambah" ? id : dataUser?.id,
    };

    if (title === "Tambah") {
      try {
        await postPengawas(payload);
        notifAlert({ type: "success", description: "Data berhasil disimpan" });
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        await editPengawas(id, payload);
        notifAlert({ type: "success", description: "Data berhasil disimpan" });
      } catch (error) {
        console.log(error);
      }
    }
    getData(page, pageSize, search);

    handleReset();
    setLoading(false);
  };

  const handleReset = () => {
    form.resetFields();
    setOpenModalAction(false);
    setDataUser(null);
    setID(uuidv4());
  };

  return (
    <>
      <BreadCrumb routes={itemBreadcrumb} title="Pengawas BKBA" />
      <div className="m-5 p-5 bg-white shadow-md rounded">
        <div className="mb-4 flex justify-end">
          {kodeRole === "super_admin_pusat" && (
            <div className="mr-4">
              <ButtonTambah onClick={handleTambah} loading={isLoading} />
            </div>
          )}

          <InputSearch
            className="w-3/4"
            onChange={(e: any) => setSearch(e.currentTarget.value)}
          />
        </div>

        <div className="w-full">
          <Table
            rowKey={(record) => record.id}
            columns={columns}
            dataSource={dataTable}
            bordered
            loading={isLoading}
            tableLayout="auto"
            scroll={{ x: "1300" }}
            pagination={{
              total: totalDataTable,
              position: ["bottomRight"],
              defaultPageSize: pageSize,
              defaultCurrent: page,
              showTotal: (total) => `Total ${total} items`,
              onChange(page, pageSize) {
                handlePageChange(page, pageSize);
              },
            }}
          />
        </div>
      </div>
      <Modal
        open={openModalAction}
        title={`${title} Pengawas`}
        width={850}
        onCancel={handleReset}
        footer={[
          <Button key="back" onClick={handleReset}>
            Batal
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={isLoading}
            onClick={() => form.submit()}>
            Simpan
          </Button>,
        ]}>
        <Form
          form={form}
          key="formPengawas"
          name="formPengawas"
          layout="vertical"
          onValuesChange={handleChange}
          onFinish={handleSave}>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div>
              <Form.Item
                label="Nama"
                name="nama"
                rules={[
                  {
                    required: true,
                    message: "Nama tidak boleh kosong!",
                  },
                ]}>
                <Input placeholder="Nama" />
              </Form.Item>
            </div>
            <div>
              <Form.Item
                label="NIK"
                name="nik"
                rules={[
                  {
                    required: true,
                    message: "NIK tidak boleh kosong!",
                  },
                ]}>
                <Input type="number" placeholder="NIK" />
              </Form.Item>
            </div>
            <div>
              <Form.Item
                label="Email"
                name="email"
                rules={[
                  {
                    type: "email",
                    message: "Email tidak valid!",
                  },
                  {
                    required: true,
                    message: "Email tidak boleh kosong!",
                  },
                ]}>
                <Input type="email" placeholder="Email" />
              </Form.Item>
            </div>

            <div>
              <Form.Item
                label="Provinsi"
                name="provinsi"
                rules={[
                  { required: true, message: "Provinsi tidak boleh kosong!" },
                ]}>
                <Select
                  showSearch
                  optionFilterProp="children"
                  placeholder="Pilih Provinsi">
                  {refProvinsi?.length &&
                    refProvinsi.map((e: any, i: any) => {
                      return (
                        <Select.Option key={`prov${i}`} value={e.kode}>
                          {e.nama}
                        </Select.Option>
                      );
                    })}
                </Select>
              </Form.Item>
            </div>
            <div>
              <Form.Item
                label="Kab / Kota"
                name="kabkota"
                rules={[
                  { required: true, message: "Kab / Kota tidak boleh kosong!" },
                ]}>
                <Select
                  showSearch
                  optionFilterProp="children"
                  placeholder="Pilih Kab / Kota">
                  {provinsi &&
                    refKabkota?.length &&
                    refKabkota
                      .filter((item) => item.kode_provinsi === provinsi)
                      .map((e: any, i: any) => {
                        return (
                          <Select.Option key={`role${i}`} value={e.kode}>
                            {e.nama}
                          </Select.Option>
                        );
                      })}
                </Select>
              </Form.Item>
            </div>
            <div>
              <Form.Item
                label="Madrasah"
                name="madrasah"
                rules={[
                  { required: true, message: "Madrasah tidak boleh kosong!" },
                ]}>
                <Select
                  showSearch
                  mode="multiple"
                  optionFilterProp="children"
                  placeholder="Pilih Madrasah">
                  {madrasah?.length &&
                    madrasah.map((e: any, i: any) => {
                      return (
                        <Select.Option key={`role${i}`} value={e.id}>
                          {e.nama} | {e.nsm}
                        </Select.Option>
                      );
                    })}
                </Select>
              </Form.Item>
            </div>
            <div>
              <Form.Item
                label="Password"
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Password tidak boleh kosong!",
                  },
                  {
                    validator: (_, value) =>
                      value && value.length >= 6
                        ? Promise.resolve()
                        : Promise.reject("Password minimal 6 karakter"),
                  },
                ]}>
                <Input.Password placeholder="Password" />
              </Form.Item>
            </div>
          </div>
          <Form.Item
            label="Memerlukan email valid?"
            name="status"
            valuePropName="checked">
            <Switch />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default Pengawas;
