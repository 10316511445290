/** @format */

import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Space, Table, Button } from "antd";
import { BreadCrumb } from "../../../../../components";
import { ButtonDropdownExport } from "../../../../../components/Button";
// import moment from "moment";
// import "moment/locale/id";
import notifAlert from "../../../../../components/NotifAlert";
import InputSearch from "../../../../../components/InputSearch";

import { useHistory } from "react-router-dom";
import { DokumenStatistic } from "../../../../../components/Statistic";

const ListPencairanProvinsi = () => {
  const route = useHistory();
  const dataState: any = route ? route.location.state : null;
  const itemBreadcrumb = [
    { path: "/", breadcrumbName: "Home" },
    { path: "/", breadcrumbName: "Laporan" },
    { path: "/", breadcrumbName: "Laporan Rencana" },
    { path: "/", breadcrumbName: "Pencairan Dokumen BOS" },
    { path: "/", breadcrumbName: `Provinsi ${dataState?.namaProvinsi}` },
  ];
  const [page, setPage] = useState(dataState?.page || 1);
  const [pageSize, setPageSize] = useState(dataState?.pageSize || 10);
  const auths = useSelector((state: any) => state.auth);
  const store = useSelector((state: any) => state.store);
  const refKabkota = store.kabkota || [];
  const auth = auths?.data || null;
  const [search, setSearch] = useState<any>(null);
  const [tmpMadrasah, setTmpMadrasah] = useState<any>([]);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (auths?.isLogin) {
      getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth?.isTahun]);

  /** Filter Search */

  let dataTable: any = search
    ? tmpMadrasah.filter((item: any) => {
        const val = search.toLowerCase();
        return (
          (item.namaKabkota !== null &&
            (item.namaKabkota || "").toString().toLowerCase().includes(val)) ||
          (item.kodeKabkota !== null &&
            (item.kodeKabkota || "").toString().toLowerCase().includes(val))
        );
      })
    : tmpMadrasah;
  let totalDataTable = dataTable.length;
  const getData = async () => {
    setLoading(true);
    try {
      let kabkotaTmp: any =
        refKabkota
          .filter(
            (item) =>
              item.kode_provinsi === dataState?.kodeProvinsi?.toString(),
          )
          .map((item, i) => {
            return {
              no: i + 1,
              kodeKabkota: item?.kode,
              namaKabkota: item?.nama,
              totalMadrasah: 1359,
              totalSelesai: 0,
              ...item,
            };
          }) || [];
      //    let dataTmp:any =  refKabkota.filter(item)
      setTmpMadrasah(kabkotaTmp);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  // const handleChangeValues = (values: any) => {
  //   setTmpFilter(values);
  // };

  const handleExportFile = (type: any) => {
    if (dataTable.length > 0) {
      if (type === "pdf") {
        handleExportPdf();
      } else if (type === "excel") {
        handleExportExcel();
      }
    } else {
      notifAlert({
        type: "warning",
        description: "Maaf data tidak tersedia!",
      });
    }
  };

  const handleExportExcel = async () => {};

  function handleExportPdf() {}

  // const handleDeleteUpload = async (idFile: any) => {
  //   try {
  //     await deleteDokumenPencairan(idFile);
  //     notifAlert({
  //       type: "success",
  //       description: "Penghapusan Dokumen Berhasil",
  //     });
  //     getData();
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  let columnsDokumen: any = [
    {
      title: "No",
      key: "no",
      dataIndex: "no",
    },
    {
      title: "Kode",
      dataIndex: "kodeKabkota",
      key: "kodeKabkota",
    },
    {
      title: "Kab / Kota",
      dataIndex: "namaKabkota",
      key: "namaKabkota",
    },
    {
      title: "Total Madrasah",
      dataIndex: "totalMadrasah",
      key: "totalMadrasah",
      render: (totalMadrasah) => `${totalMadrasah} Madrasah`,
    },
    {
      title: "Total Madrasah Selesai",
      dataIndex: "totalSelesai",
      key: "totalSelesai",
      render: (totalSelesai) => `${totalSelesai} Madrasah`,
    },
    {
      title: "Total Madrasah Belum Selesai",
      key: "totalSelesai",
      render: (record) =>
        `${record?.totalMadrasah - record?.totalSelesai} Madrasah`,
    },
    {
      title: "Aksi",
      key: "aksi",
      render: (record) => {
        return (
          <Space>
            {/* <ButtonTableApproval onClick={() => handleAfterApproval(record)} /> */}
            <Button
              type="primary"
              onClick={() =>
                route.push({
                  pathname: `/laporan/pencairan-dokumen-bos/list/${record.kodeKabkota}`,
                  state: {
                    page: page,
                    pageSize: pageSize,
                    dataProvinsi: dataState,
                    ...record,
                  },
                })
              }>
              Lihat Detail
            </Button>
          </Space>
        );
      },
    },
  ];

  return (
    <>
      <BreadCrumb
        routes={itemBreadcrumb}
        title={`Provinsi ${dataState?.namaProvinsi}`}
        back={true}
        toBack={() =>
          route.push({
            pathname: "../",
            state: {
              page: dataState?.page || 1,
              pageSize: dataState?.pageSize || 10,
            },
          })
        }
      />
      <DokumenStatistic />
      <div className="m-5 p-5 bg-white shadow-md rounded">
        <div className="mb-4 flex justify-end">
          <div className="mr-4">
            <Space wrap>
              <ButtonDropdownExport handleExportFile={handleExportFile} />
            </Space>
          </div>
          <InputSearch
            className="w-3/4"
            onChange={(e: any) => setSearch(e.currentTarget.value)}
          />
        </div>
        <Table
          loading={loading}
          columns={columnsDokumen}
          dataSource={dataTable}
          bordered
          pagination={{
            total: totalDataTable,
            position: ["bottomRight"],
            defaultPageSize: pageSize,
            defaultCurrent: page,
            showTotal: (total) => `Total ${total} items`,
            onChange(page, pageSize) {
              setPage(page);
              setPageSize(pageSize);
            },
          }}
        />
      </div>
    </>
  );
};

export default ListPencairanProvinsi;
