/** @format */

import api from "../../../utils/api";
import { referenceService } from "../constant";

const basePath = `${referenceService}`;

export const postKomponenBiaya = async (params: any) => {
  const response = await api.post(`${basePath}/komponen-biaya`, params, {
    headers: { "Content-Type": "application/json" },
  });
  return response?.data?.return;
};

export const editKomponenBiaya = async (params: any) => {
  const response = await api.put(
    `${basePath}/komponen-biaya/${params.kode}`,
    params,
    {
      headers: { "Content-Type": "application/json" },
    },
  );
  return response?.data?.return;
};

export const postKomponenBiayaMaster = async (params: any) => {
  const response = await api.post(
    `${basePath}/komponen-biaya-tanpa-harga`,
    params,
    {
      headers: { "Content-Type": "application/json" },
    },
  );
  return response?.data?.return;
};

export const editKomponenBiayaMaster = async (params: any) => {
  const response = await api.put(
    `${basePath}/komponen-biaya-tanpa-harga/${params.kode}`,
    params,
    {
      headers: { "Content-Type": "application/json" },
    },
  );
  return response?.data?.return;
};

export const setHargaKomponenBiaya = async (data: any): Promise<any> => {
  const response = await api.post(
    `${basePath}/upload-template-komponen-biaya`,
    data,
  );

  return response?.data?.return;
};

export const deleteKomponenBiayaMaster = async (kode: any) => {
  const response = await api.delete(`${basePath}/komponen-biaya/${kode}`);
  return response?.data?.return;
};
