/**
 * update fix
 *
 * @format
 */

import api from "../../../utils/api";
import { planningService } from "../constant";

const basePath = `${planningService}/madrasah-manual-upload`;
const credentials = "und5sy2kn9HekqKBf0FpLieVb2Fv4kD2";

/** Get Madrasah Manual by Nsm   */
export const getMadrasahManualByNsm = async (params: any) => {
  const response = await api.get(
    `${basePath}/madrasah/nsm/${params}?xCredentialKey=${credentials}`,
  );
  return response?.data?.return;
};

/** Get Madrasah Manual    */
export const getMadrasahManual = async (params: any) => {
  const response = await api.get(`${basePath}/madrasah${params}`);
  return response?.data?.return;
};

/** Get File by Nsm   */
export const getFileMadrasahManual = async (params: any) => {
  const response = await api.get(
    `${basePath}?xCredentialKey=${credentials}${params}`,
  );
  return response?.data?.return;
};

/** Delete File ID   */
export const deleteFileMadrasahManual = async (params: any) => {
  const response = await api.delete(
    `${basePath}/${params}?xCredentialKey=${credentials}`,
  );
  return response?.data?.return;
};

/** Post Madrasah Manual */
export const postMadrasahManual = async (params: any) => {
  const response = await api.post(
    `${basePath}/create?xCredentialKey=${credentials}`,
    params,
    {
      headers: { "Content-Type": "multipart/form-data" },
    },
  );
  return response?.data?.return;
};

/** Post Upload Manual */
export const postUploadManual = async (params: any) => {
  const response = await api.post(
    `${basePath}/upload?xCredentialKey=${credentials}`,
    params,
    {
      headers: { "Content-Type": "multipart/form-data" },
    },
  );
  return response?.data?.return;
};

/** Post Upload Manual */
export const editUploadManual = async (params: any, id: any) => {
  const response = await api.put(
    `${basePath}/upload/${id}?xCredentialKey=${credentials}`,
    params,
    {
      headers: { "Content-Type": "multipart/form-data" },
    },
  );
  return response?.data?.return;
};

/** Status Upload Manual */
export const statusUploadManual = async (params: any) => {
  const response = await api.put(
    `${basePath}/status-validasi/${params}&xCredentialKey=${credentials}`,
  );
  return response?.data?.return;
};

/** Get portal Bos   */
export const getPortalBosManual = async (params: any) => {
  const response = await api.get(
    `${planningService}/portal-bos/manual?xCredentialKey=${credentials}${params}`,
  );
  return response?.data?.return;
};

/** Status Upload Manual */
export const statusVerifikasiUploadManual = async (params: any) => {
  const response = await api.put(
    `${basePath}/status-varifikasi-dokumen?xCredentialKey=${credentials}`,
    params,
  );
  return response?.data?.return;
};
