/** @format */

import React, { useEffect, useState } from "react";
import ExcelJS from "exceljs";
import moment from "moment";
import "moment/locale/id";
import {
  // Menu,
  // Dropdown,
  // Button,
  // DatePicker,
  Table,
  Space,
} from "antd";
import * as FileSaver from "file-saver";
import { BreadCrumb } from "../../../components";
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import { formatRupiah } from "../../../utils/helper";
import { useDispatch, useSelector } from "react-redux";
import { ButtonDropdownExport } from "../../../components/Button";

import { getLaporanRkakl } from "../../../services/v2/planningservice/laporanservices";
import { getMadrasah } from "../../../services/v2/usermanservice/madrasahservices";
import notifAlert from "../../../components/NotifAlert";
import FilterMadrasah from "../Component/FilterMadrasah";
import { setStore } from "../../../redux/actions";
/**
 * Tampilan awal laporan RKAKL
 */
const LaporanRKAKL = () => {
  const itemBreadcrumb = [
    { path: "/", breadcrumbName: "Home" },
    { path: "/", breadcrumbName: "Laporan" },
    { path: "/", breadcrumbName: "Laporan Rencana" },
    { path: "/", breadcrumbName: "RKAKL" },
  ];
  const auths = useSelector((state: any) => state.auth);
  const store = useSelector((state: any) => state.store);
  const profile = store?.madrasahProfile || null;
  const auth = auths?.data || null;
  const groupRole = auth?.group_role || "";
  const [tmpMadrasah, setTmpMadrasah] = useState<any>([]);
  const [tmpFilter, setTmpFilter] = useState<any>(null);
  const [dataMadrasah, setDataMadrasah] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const tmpListMadrasah = store?.listMadrasah || [];
  const [tableData, setTableData] = useState<any>([]);
  let dataTable: any = [];

  let dataHeaders: any =
    (tableData.length && tableData[0]?.rencanaHeaders) || [];
  let dataTitles: any = (tableData.length && tableData[0]?.rencanaTitles) || [];
  // let dataRole: any = (tableData.length && tableData[0]?.rencanaRole) || null;

  let dataRincian: any =
    (tableData.length && tableData[0]?.rencanaKegiatans) || [];
  dataHeaders.length &&
    // eslint-disable-next-line array-callback-return
    dataHeaders.map((item) => {
      let tmpDataHeader: any = {
        kode: item.kode || null,
        uraian: item.uraian || null,
        volumeOutput: item.volumeOutput || null,
        rincianPerhitungan: `${item.totalKuantitas || ""} ${
          item.unitSatuan || ""
        }`,
        hargaSatuan:
          (item.hargaSatuan && formatRupiah(item.hargaSatuan)) || null,
        jumlahTotal:
          (item.jumlahTotal && formatRupiah(item.jumlahTotal)) || null,
      };
      dataTable.push(tmpDataHeader);
    });
  dataRincian.length &&
    // eslint-disable-next-line array-callback-return
    dataRincian.map((item) => {
      let tmpDataRincian: any = {
        kode: item.kode || null,
        uraian: item.deskripsi || null,
        volumeOutput: null,
        rincianPerhitungan: null,
        hargaSatuan: null,
        jumlahTotal:
          (item.deskripsiMoney && formatRupiah(item.deskripsiMoney)) || null,
      };
      dataTable.push(tmpDataRincian);
      item.rencanaKegiatans.length &&
        // eslint-disable-next-line array-callback-return
        item.rencanaKegiatans.map((items) => {
          let tmpDataRencana: any = {
            kode: items.kodeJenisBelanja || null,
            uraian: items.namaJenisBelanja || null,
            volumeOutput: null,
            rincianPerhitungan: null,
            hargaSatuan: null,
            jumlahTotal:
              (items.jumlahTotal && formatRupiah(items.jumlahTotal)) || null,
          };
          dataTable.push(tmpDataRencana);
          items.rincianKegiatans.length &&
            // eslint-disable-next-line array-callback-return
            items.rincianKegiatans.map((itemss) => {
              let tmpDataRencanaRincian: any = {
                kode: null,
                uraian: itemss.komponenBiayaNama || null,
                volumeOutput: itemss.volumeOutput || null,
                rincianPerhitungan: `${itemss.totalKuantitas || ""} ${
                  itemss.unitSatuan || ""
                }`,
                hargaSatuan:
                  (itemss.hargaSatuan && formatRupiah(itemss.hargaSatuan)) ||
                  null,
                jumlahTotal:
                  (itemss.jumlahTotal && formatRupiah(itemss.jumlahTotal)) ||
                  null,
              };
              dataTable.push(tmpDataRencanaRincian);
            });
        });
    });

  let columns: any = [
    {
      title: "Kode",
      key: "kode1",
      align: "center",
      children: [
        {
          title: "1",
          dataIndex: "kode",
          key: "kode",
          width: 140,
          align: "center",
        },
      ],
    },
    {
      title: "Uraian",
      key: "uraian2",
      align: "center",
      children: [
        {
          title: "2",
          dataIndex: "uraian",
          key: "uraian",
          width: 550,
          align: "center",
          render: (uraian) => <div className="text-left">{uraian}</div>,
        },
      ],
    },
    {
      title: "Volume Output",
      key: "volumeOutput3",
      align: "center",
      children: [
        {
          title: "3",
          dataIndex: "volumeOutput",
          key: "volumeOutput",
          width: 180,
          align: "center",
        },
      ],
    },
    {
      title: "Rincian Perhitungan (Jumlah)",
      key: "rincianPerhitungan4",
      align: "center",
      children: [
        {
          title: "4",
          dataIndex: "rincianPerhitungan",
          key: "rincianPerhitungan",
          width: 180,
          align: "center",
        },
      ],
    },
    {
      title: "Harga Satuan",
      key: "hargaSatuan5",
      align: "center",
      children: [
        {
          title: "5",
          dataIndex: "hargaSatuan",
          key: "hargaSatuan",
          width: 180,
          align: "center",
          render: (hargaSatuan) => (
            <div className="text-right">{hargaSatuan}</div>
          ),
        },
      ],
      // render: (jumlahTotal) => formatRupiah(jumlahTotal),
    },
    {
      title: "Jumlah Total",
      key: "jumlahTotal6",
      align: "center",
      children: [
        {
          title: "6",
          dataIndex: "jumlahTotal",
          key: "jumlahTotal",
          width: 180,
          align: "center",
          render: (jumlahTotal) => (
            <div className="text-right">{jumlahTotal}</div>
          ),
        },
      ],
      // render: (jumlahTotal) => formatRupiah(jumlahTotal),
    },
  ];

  useEffect(() => {
    getData(tmpFilter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tmpFilter, auth?.isTahun]);

  const getData = async (tmpFilter) => {
    setLoading(true);
    if (groupRole !== "madrasah") {
      if (tmpListMadrasah.length === 0) {
        let filter: any = "";
        if (groupRole === "provinsi") {
          filter = `?kodeProvinsi=${auth?.kode_provinsi}`;
        }
        if (groupRole === "kabkota") {
          filter = `?kodeProvinsi=${auth?.kode_provinsi}&kode_kabkota=${auth?.kode_kabkota}`;
        }
        const madrasah = await getMadrasah(filter);
        const listMadrasah = madrasah || [];
        setTimeout(() => {
          dispatch(setStore({ listMadrasah }));
        }, 100);
        setTmpMadrasah(madrasah);
      } else {
        setTmpMadrasah(tmpListMadrasah);
      }
      const payload = {
        tahun: auth?.isTahun,
        madrasahId: tmpFilter?.madrasahId,
      };
      if (tmpFilter?.madrasahId) {
        const resMadrasah = tmpListMadrasah.find((item) =>
          item.id.includes(tmpFilter?.madrasahId),
        );
        setDataMadrasah(resMadrasah);
        const res = await getLaporanRkakl(payload);
        let response = res || [];
        setTableData((response.length && response) || []);
      }
    } else {
      setDataMadrasah(profile);
      const payload = {
        tahun: auth?.isTahun,
        madrasahId: auth?.madrasah?.id,
      };
      try {
        const res = await getLaporanRkakl(payload);
        let response = res || [];
        setTableData((response.length && response) || []);
      } catch (error) {
        console.log(error);
      }
    }

    setLoading(false);
  };

  const handleChangeValues = (values: any) => {
    setTmpFilter(values);
    // getData(values);
  };

  const handleExportExcel = async () => {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const wb = new ExcelJS.Workbook();
    const ws = wb.addWorksheet("data RKAKL");
    ws.mergeCells("A2:G2");

    const headerTitleCell = ws.getCell("A2");
    headerTitleCell.font = {
      size: 14,
      underline: true,
      bold: true,
    };
    headerTitleCell.alignment = {
      vertical: "middle",
      horizontal: "center",
      wrapText: true,
    };
    headerTitleCell.value = `RENCANA KINERJA ANGGARAN KEMENTERIAN / LEMBAGA \n KEGIATAN T.A. ${auths?.isTahun}`;

    // eslint-disable-next-line array-callback-return
    dataTitles.length &&
      // eslint-disable-next-line array-callback-return
      dataTitles.map((item, i) => {
        if (i > 1) {
          const row = i + 3;
          const headerTitleBody = ws.getCell(`A${row}`);
          const headerValueBody = ws.getCell(`B${row}`);
          headerTitleBody.font = {
            size: 12,
            underline: true,
            bold: true,
          };
          headerTitleBody.value = item.kode;
          headerValueBody.font = {
            size: 12,
            underline: true,
          };
          headerValueBody.value = item.uraian;
        }
      });
    ws.getColumn("A").width = 20;
    ws.getColumn("B").width = 60;
    ws.getColumn("C").width = 30;
    ws.getColumn("D").width = 30;
    ws.getColumn("E").width = 30;
    ws.getColumn("F").width = 30;
    // ws.columns = [
    //   { header: "Kode", key: "kode" },
    //   { header: "Uraian", key: "uraian" },
    //   { header: "Volume Output", key: "volumeOutput" },
    //   { header: "Rincian Perhitungan (Jumlah)", key: "rincianPerhitungan" },
    //   { header: "Harga Satuan", key: "hargaSatuan" },
    //   { header: "Jumlah Total", key: "jumlahTotal" },
    // ];
    const rowLast = ws.rowCount;
    let dataList: any = [];

    dataTable.length &&
      // eslint-disable-next-line array-callback-return
      dataTable.map((item) => {
        dataList.push([
          item.kode,
          item.uraian,
          item.volumeOutput,
          item.rincianPerhitungan,
          item.hargaSatuan,
          item.jumlahTotal,
        ]);
      });

    ws.addTable({
      name: "MyTable",
      ref: `A${rowLast + 2}`,
      headerRow: true,
      // totalsRow: true,
      style: {
        showFirstColumn: true,
        // theme: "TableStyleDark3",
        showRowStripes: true,
      },
      columns: [
        { name: "Kode" },
        { name: "Uraian" },
        { name: "Volume Output" },
        { name: "Rincian Perhitungan (Jumlah)" },
        { name: "Harga Satuan" },
        { name: "Jumlah Total" },
      ],
      rows: dataList,
    });
    let lastLine = 18 + dataList.length;
    ws.getCell(`A${lastLine}`).value = "Mengetahui";
    ws.getCell(`F${lastLine}`).value = moment().format("dddd, DD MMMM YYYY");
    ws.getCell(`A${lastLine + 2}`).value = "Kepala Madrasah";
    ws.getCell(`F${lastLine + 2}`).value = "Bendahara Madrasah";
    ws.getCell(`A${lastLine + 5}`).value =
      (dataMadrasah?.nama_kepala && `( ${dataMadrasah?.nama_kepala} )`) ||
      "(.................)";
    ws.getCell(`F${lastLine + 5}`).value =
      (dataMadrasah?.nama_bendahara && `( ${dataMadrasah?.nama_bendahara} )`) ||
      "(.................)";
    const excelBuffer = await wb.xlsx.writeBuffer();
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, "Laporan-RKAKL" + fileExtension);
  };

  function handleExportPdf() {
    const doc: any = new jsPDF({
      putOnlyUsedFonts: true,
      orientation: "landscape",
      floatPrecision: 16,
    });
    let tableTmp: any = [];
    dataTable.length &&
      // eslint-disable-next-line array-callback-return
      dataTable.map((item) => {
        tableTmp.push([
          item.kode,
          item.uraian,
          item.volumeOutput,
          item.rincianPerhitungan,
          item.hargaSatuan,
          item.jumlahTotal,
        ]);
      });

    var fline = 10;
    doc.setFont("times", "bold");
    doc.setFontSize(14);

    doc.text(`RENCANA KINERJA ANGGARAN KEMENTERIAN / LEMBAGA`, 150, 12, {
      align: "center",
    });
    doc.text(`KEGIATAN T.A. ${auths?.isTahun}`, 150, 18, { align: "center" });
    doc.setFont("times", "normal");
    doc.setFontSize(10);
    let noLine = 30;
    dataTitles.length &&
      // eslint-disable-next-line array-callback-return
      dataTitles.map((item: any, i: any) => {
        if (i > 1) {
          doc.text(item.kode, fline, noLine);
          doc.text(`: ${item.uraian}`, fline + 60, noLine);
          noLine += 5;
        }
      });

    var headers: any = [
      { id: "kode", dataKey: "kode", padding: 0, header: "Kode", width: 42 },
      {
        id: "uraian",
        dataKey: "uraian",
        padding: 0,
        header: "Uraian",
        width: 80,
      },
      {
        id: "volumeOutput",
        dataKey: "volumeOutput",
        padding: 0,
        header: "Volume Output",
        width: 40,
      },
      {
        id: "rincianPerhitungan",
        dataKey: "rincianPerhitungan",
        padding: 0,
        header: "Rincian Perhitungan (Jumlah)",
        width: 40,
      },
      {
        id: "hargaSatuan",
        dataKey: "hargaSatuan",
        padding: 0,
        header: "Harga Satuan",
        width: 40,
      },
      {
        id: "jumlahTotal",
        dataKey: "jumlahTotal",
        padding: 0,
        header: "Jumlah Total",
        width: 39,
      },
    ];

    let las: any = [];
    autoTable(doc, {
      styles: { lineColor: 244, lineWidth: 0.1 },
      headStyles: {
        halign: "center",
        valign: "middle",
        fillColor: [0, 128, 0],
      }, // Cells in first column centered and green
      columnStyles: {
        0: { halign: "center", cellWidth: 23 },
        1: { halign: "left", cellWidth: 95 },
        2: { halign: "right", cellWidth: 38 },
        3: { halign: "right", cellWidth: 40 },
        4: { halign: "right", cellWidth: 40 },
        5: { halign: "right", cellWidth: 40 },
      },
      startY: 90,
      margin: { top: 10, left: 10, right: 10 },
      columns: headers,
      body: tableTmp,
      didDrawPage: (d) => las.push(d.cursor),
    });
    let lastLine: any = 7 + (las.length !== 0 ? las[0].y : 0);
    const footerText = [
      {
        text: "Mengetahui",
        line: fline,
        space: 0,
        bottom: 10,
      },
      {
        text: moment().format("dddd, DD MMMM YYYY"),
        line: fline,
        space: 210,
        bottom: 0,
      },
      {
        text: "Kepala Madrasah",
        line: fline,
        space: 0,
        bottom: 5,
      },
      {
        text: "Bendahara Madrasah",
        line: fline,
        space: 210,
        bottom: 0,
      },
      {
        text:
          (dataMadrasah?.nama_kepala && `( ${dataMadrasah?.nama_kepala} )`) ||
          "(.................)",
        line: fline,
        space: 0,
        bottom: 30,
      },
      {
        text:
          (dataMadrasah?.nama_bendahara &&
            `( ${dataMadrasah?.nama_bendahara} )`) ||
          "(.................)",
        line: fline,
        space: 210,
        bottom: 0,
      },
    ];
    let tmpBottom = lastLine;
    // eslint-disable-next-line array-callback-return
    footerText.map((item) => {
      tmpBottom += item.bottom;
      if (tmpBottom > 195) {
        doc.addPage();
        tmpBottom = 15;
      }
      doc.text(item.text, fline + item.space, tmpBottom);
    });

    doc.output("dataurlnewwindow", { filename: "laporan-rkakl" });
    notifAlert({
      type: "success",
      description: "Data berhasil di export",
    });
  }

  const handleExportFile = (type) => {
    if (dataTable.length > 0) {
      if (type === "pdf") {
        handleExportPdf();
      } else if (type === "excel") {
        handleExportExcel();
      }
    } else {
      notifAlert({
        type: "warning",
        description:
          "Harap pilih data yang ingin di export melalui fungsi filter!",
      });
    }
  };

  return (
    <>
      <BreadCrumb routes={itemBreadcrumb} title="RKAKL" />
      {groupRole !== "madrasah" && (
        <FilterMadrasah
          handleChangeValues={handleChangeValues}
          madrasah={tmpMadrasah}
        />
      )}
      <div className="m-5 p-5 bg-white shadow-md rounded">
        <div className="mb-4 flex justify-end">
          <Space size={[8, 8]} wrap>
            <ButtonDropdownExport handleExportFile={handleExportFile} />
          </Space>
        </div>
        <div className="w-full">
          <Table
            sticky
            rowKey={(record) => record.id}
            columns={columns}
            loading={loading}
            dataSource={dataTable}
            scroll={{ x: "1300" }}
            bordered
            pagination={false}
            // pagination={{
            //   total: totalDataTable,
            //   position: ["bottomRight"],
            //   defaultPageSize: pageSize,
            //   defaultCurrent: page,
            //   showTotal: (total) => `Total ${total} items`,
            //   onChange(page, pageSize) {
            //     setPage(page);
            //     setPageSize(pageSize);
            //   },
            // }}
          />
        </div>
      </div>
    </>
  );
};

export default LaporanRKAKL;
