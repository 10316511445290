/** @format */

import React, { useEffect } from "react";

import { Form, Select, Radio } from "antd";
import { useSelector } from "react-redux";

type filterProps = {
  type: any;
  handleChangeValues: (values: any) => void;
};

const FilterMadrasahUpload = ({ type, handleChangeValues }: filterProps) => {
  const [form] = Form.useForm();
  const store = useSelector((state: any) => state.store);
  const auths = useSelector((state: any) => state.auth);
  const auth = store?.profile || null;

  const groupRole = auth?.group_role;
  const initialValue = {
    provinsi: auth?.group_role === "pusat" ? null : auth?.kode_provinsi,
    kabkota:
      auth?.group_role === "pusat" || auth?.group_role === "provinsi"
        ? null
        : auth?.kode_kabkota,
  };
  const provinsi = auth?.kode_provinsi;
  const refKabkota = store.kabkota || [];
  useEffect(() => {
    form.resetFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type]);
  const handleChange = (values) => {
    const val = form.getFieldsValue();

    // console.log(values);
    // let tmpFilter: any = {
    //   provinsi: null,
    //   kabkota: null,
    //   penerimaBos: null,
    //   status: null,
    // };
    // for (let obj in values) {
    //   switch (obj) {
    //     case "provinsi":
    //       tmpFilter = { ...tmpFilter, provinsi: values.provinsi || null };
    //       break;
    //     case "kabkota":
    //       tmpFilter = { ...tmpFilter, kabkota: values.kabkota || null };
    //       break;
    //     case "penerimaBos":
    //       tmpFilter = { ...tmpFilter, penerimaBos: values.penerimaBos };
    //       break;
    //     case "status":
    //       tmpFilter = { ...tmpFilter, status: values.status };
    //       break;
    //   }
    // }
    handleChangeValues(val);
  };

  // const changeField = () => {
  //   const val = form.getFieldsValue();

  // };

  let statusList: any = [
    {
      key: "status0",
      id: 0,
      nama: "Belum Disetujui",
    },
    {
      key: "status1",
      id: 1,
      nama: "Disetujui",
    },
  ];
  let statusPenerimaBos: any = [
    { id: 1, nama: "Penerima" },
    { id: 0, nama: "Bukan Penerima" },
  ];
  return (
    <div className="m-5 p-5 bg-white shadow-md rounded">
      <Form
        form={form}
        key="formFilterMadrasahUpload"
        name="formFilterMadrasahUpload"
        labelAlign="left"
        initialValues={initialValue}
        labelCol={{ span: 8 }}
        onValuesChange={handleChange}>
        <div className="grid grid-cols-1  md:items-center  md:grid-cols-2 gap-4 ">
          {groupRole === "provinsi" && (
            <div>
              <Form.Item label="Kab / Kota" name="kabkota" required>
                <Select
                  placeholder="Pilih Kab / Kota"
                  showSearch
                  allowClear
                  disabled={
                    auth.group_role === "pusat" ||
                    auth.group_role === "provinsi"
                      ? false
                      : true
                  }
                  optionFilterProp="children">
                  {refKabkota.length &&
                    provinsi &&
                    refKabkota
                      .filter((item) => item.kode_provinsi === provinsi)
                      .map((item) => (
                        <Select.Option
                          key={`filKab${item.kode}`}
                          value={item.kode}>
                          {item.nama}
                        </Select.Option>
                      ))}
                </Select>
              </Form.Item>
            </div>
          )}
          <div>
            <Form.Item label="Status" name="status" required>
              <Select
                placeholder="Pilih Status"
                showSearch
                allowClear
                optionFilterProp="children">
                {statusList &&
                  statusList.map((item) => (
                    <Select.Option key={`statusList${item.id}`} value={item.id}>
                      {item.nama}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
          </div>
          <div>
            <Form.Item
              label={`Penerima BOS ${auths?.isTahun - 1}`}
              name="penerimaBos"
              required>
              <Select
                placeholder={`Pilih Status Penerima BOS ${auths?.isTahun - 1}`}
                showSearch
                allowClear
                optionFilterProp="children">
                {statusPenerimaBos &&
                  statusPenerimaBos.map((item) => (
                    <Select.Option
                      key={`statusPenerima${item.id}`}
                      value={item.id}>
                      {item.nama}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
          </div>
          <div>
            <Form.Item label="Tahap" name="tahap">
              <Radio.Group>
                <Radio value={1}>Tahap 1</Radio>
                <Radio value={2}> Tahap 2</Radio>
              </Radio.Group>
            </Form.Item>
          </div>
          {/* <div>
            <Form.Item label="Madrasah" name="madrasahId" required>
              <Select
                placeholder="Pilih Madrasah"
                showSearch
                allowClear
                optionFilterProp="children">
                {madrasah.length &&
                  provinsi &&
                  madrasah
                    .filter((item) => item.penerimaBos === penerimaBosKey)
                    .map((item) => (
                      <Select.Option
                        key={`filMad${item.madrasahId}`}
                        value={item.madrasahId}>
                        {item.namaMadrasah}
                      </Select.Option>
                    ))}
              </Select>
            </Form.Item>
          </div> */}
        </div>
      </Form>
    </div>
  );
};
export default FilterMadrasahUpload;
