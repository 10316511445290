/** @format */

import React, { useState } from "react";
import { Space, Table } from "antd";
import { BreadCrumb } from "../../../../components";
import { ButtonDropdownExport } from "../../../../components/Button";
import moment from "moment";
import "moment/locale/id";
import notifAlert from "../../../../components/NotifAlert";
import InputSearch from "../../../../components/InputSearch";
import { formatCurr } from "../../../../utils/helper";
// import jsPDF from "jspdf";
// import * as FileSaver from "file-saver";
// import ExcelJS from "exceljs";
// import autoTable from "jspdf-autotable";
import { useHistory } from "react-router-dom";
import FilterPengajuan from "../Component/FilterPengajuan";
import { exportDataPengajuan } from "../Component/ExportDataPengajuan";
import { useSelector } from "react-redux";

// const range = (start: number, end: number) => {
//   const result: any = [];
//   for (let i = start; i < end; i++) {
//     result.push(i);
//   }
//   return result;
// };

const SorlistPengajuanBos = () => {
  const route = useHistory();
  const dataState: any = route ? route.location.state : null;
  const itemBreadcrumb = [
    { path: "/", breadcrumbName: "Home" },
    { path: "/", breadcrumbName: "Pencairan Dokumen BOS" },
    { path: "/", breadcrumbName: "Pengajuan Pencairan" },
    {
      path: "/",
      breadcrumbName: `Batch ${dataState?.batch} Tahap ${dataState?.tahapan}`,
    },
  ];
  const auths = useSelector((state: any) => state.auth);
  const store = useSelector((state: any) => state.store);
  const refProvinsi = store?.provinsi || [];
  const refKabkota = store.kabkota || [];
  const tmpTable = dataState?.list_verifikasi || [];
  const [search, setSearch] = useState<any>(null);
  const [tmpFilter, setTmpFilter] = useState<any>(null);

  /** Filter Search */
  let dataTable = search
    ? tmpTable.filter((item: any) => {
        const val = search.toLowerCase();
        return (
          (item.provinsi !== null &&
            (item.provinsi || "").toString().toLowerCase().includes(val)) ||
          (item.nsm !== null &&
            (item.nsm || "").toString().toLowerCase().includes(val)) ||
          (item.kabkota !== null &&
            (item.kabkota || "").toString().toLowerCase().includes(val)) ||
          (item.nama_pemilik_rekening !== null &&
            (item.nama_pemilik_rekening || "")
              .toString()
              .toLowerCase()
              .includes(val)) ||
          (item.nomor_rekening !== null &&
            (item.nomor_rekening || "")
              .toString()
              .toLowerCase()
              .includes(val)) ||
          (item.nama_madrasah !== null &&
            (item.nama_madrasah || "").toString().toLowerCase().includes(val))
        );
      })
    : tmpTable;

  dataTable =
    (tmpFilter?.provinsi &&
      dataTable.filter((item) => item.kode_provinsi === tmpFilter.provinsi)) ||
    dataTable;
  dataTable =
    (tmpFilter?.kabkota &&
      dataTable.filter((item) => item.kode_kabkota === tmpFilter.kabkota)) ||
    dataTable;
  dataTable =
    (tmpFilter?.bank &&
      dataTable.filter(
        (item) =>
          item.nama_bank.toLowerCase() === tmpFilter?.bank?.toLowerCase(),
      )) ||
    dataTable;

  const handleExport = (type: any) => {
    if (dataTable.length > 0) {
      exportDataPengajuan(
        "Pengajuan",
        dataTable,
        type,
        auths?.isTahun,
        store,
        dataState,
      );
    } else {
      notifAlert({
        type: "warning",
        description: "Maaf data tidak tersedia!",
      });
    }
  };

  let columnsDokumen: any = [
    {
      title: "NSM",
      key: "nsm",
      dataIndex: "nsm",
    },
    {
      title: "Madrasah",
      dataIndex: "nama_madrasah",
      key: "nama_madrasah",
    },
    {
      title: "Jenjang",
      dataIndex: "jenjang",
      key: "jenjang",
      render: (jenjang) => <div className="uppercase">{jenjang}</div>,
    },
    {
      title: "Provinsi",
      dataIndex: "kode_provinsi",
      key: "kode_provinsi",
      render: (kode_provinsi) => {
        return (
          refProvinsi.find((item) => item.kode === kode_provinsi)?.nama || ""
        );
      },
    },
    {
      title: "Kabkota",
      dataIndex: "kode_kabkota",
      key: "kode_kabkota",
      render: (kode_kabkota) => {
        return (
          refKabkota.find((item) => item.kode === kode_kabkota)?.nama || ""
        );
      },
    },
    {
      title: "Alokasi Anggaran",
      key: "Nominal",
      children: [
        {
          title: "Tahap 1",
          dataIndex: "tahap1",
          key: "tahap1",
          render: (tahap1) => (
            <div className="text-right">{formatCurr(tahap1)}</div>
          ),
        },
        {
          title: "Tahap 2",
          key: "tahap2",
          dataIndex: "tahap2",
          render: (tahap2) => (
            <div className="text-right">{formatCurr(tahap2)}</div>
          ),
        },
        {
          title: "Total",
          key: "total",
          dataIndex: "total",
          render: (total) => (
            <div className="text-right">{formatCurr(total)}</div>
          ),
        },
      ],
    },
    {
      title: "Tahapan",
      key: "tahapan",
      dataIndex: "tahapan",
      render: (tahapan) => `Tahap ${tahapan}`,
    },
    {
      title: "Tanggal Verifikasi",
      key: "tanggal_verifikasi",
      dataIndex: "tanggal_verifikasi",
      render: (tanggal_verifikasi) =>
        moment(tanggal_verifikasi).format("dddd,DD MMM YYYY HH:mm:ss"),
    },
    {
      title: "Rekening Madrasah",
      key: "rekening",
      children: [
        {
          title: "Nama BANK",
          dataIndex: "nama_bank",
          key: "nama_bank",
        },
        {
          title: "No Rekening",
          key: "nomor_rekening",
          dataIndex: "nomor_rekening",
        },
        {
          title: "Nama Pemilik",
          key: "nama_pemilik_rekening",
          dataIndex: "nama_pemilik_rekening",
        },
        {
          title: "Cabang",
          key: "cabang",
          dataIndex: "cabang",
        },
      ],
    },
  ];

  const handleChangeValues = (values) => {
    setTmpFilter(values);
  };
  return (
    <>
      <BreadCrumb
        routes={itemBreadcrumb}
        title={`List Data Batch ${dataState?.batch} Tahap ${dataState?.tahapan}`}
        back={true}
        toBack={() =>
          route.push({
            pathname: `./`,
            state: {
              page: dataState?.page || 1,
              pageSize: dataState?.pageSize || 10,
              activeTabKey: dataState?.activeTabKey,
            },
          })
        }
      />
      <FilterPengajuan
        handleChangeValues={handleChangeValues}
        dataMadrasah={tmpTable}
      />
      <div className="m-5 p-5 bg-white shadow-md rounded">
        <div className="mb-4 flex justify-end">
          <div className="mr-4">
            <Space wrap>
              <ButtonDropdownExport handleExportFile={handleExport} />
            </Space>
          </div>
          <InputSearch
            className="w-3/4"
            onChange={(e: any) => setSearch(e.currentTarget.value)}
          />
        </div>
        <Table
          //   loading={loading}
          scroll={{ x: "1300" }}
          columns={columnsDokumen}
          dataSource={dataTable}
          bordered
        />
      </div>
    </>
  );
};

export default SorlistPengajuanBos;
