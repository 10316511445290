/** @format */

import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Modal,
  Space,
  Table,
  Button,
  Form,
  InputNumber,
  Tabs,
  Select,
  DatePicker,
  Radio,
} from "antd";
// import type { RangePickerProps } from "antd/es/date-picker";
import { BreadCrumb } from "../../../components";
import {
  ButtonDropdownExport,
  ButtonTableApproval,
  ButtonTableDelete,
  ButtonTableDetail,
  // ButtonTableEdit,
} from "../../../components/Button";
import moment from "moment";
import "moment/locale/id";
import notifAlert from "../../../components/NotifAlert";
import InputSearch from "../../../components/InputSearch";
import { formatCurr, getBatch } from "../../../utils/helper";
// import jsPDF from "jspdf";
// import * as FileSaver from "file-saver";
// import ExcelJS from "exceljs";
// import autoTable from "jspdf-autotable";
import { useHistory } from "react-router-dom";
import ModalPriviewListPencairan from "./Component/ModalPriviewListPencairan";
import ModalTanggalPencairan from "./Component/ModalTanggalPencairan";
import {
  deletePencairanSumberDanaBos,
  deleteVerifikasiSumberDanaBos,
  editPengajuanSumberDanaBos,
  editVerifikasiSumberDanaBos,
  getPlanningAll,
} from "../../../services/v2/planningservice";
import { setBos } from "../../../redux/actions/bosAction";
import { exportDataPengajuan } from "./Component/ExportDataPengajuan";
const { TabPane } = Tabs;
const { RangePicker } = DatePicker;

// const range = (start: number, end: number) => {
//   const result: any = [];
//   for (let i = start; i < end; i++) {
//     result.push(i);
//   }
//   return result;
// };
const PengajuanPencairanBos = () => {
  const itemBreadcrumb = [
    { path: "/", breadcrumbName: "Home" },
    { path: "/", breadcrumbName: "Pencairan Dokumen BOS" },
    { path: "/", breadcrumbName: "Pengajuan Pencairan" },
  ];
  const route = useHistory();
  const dataState: any = route ? route.location.state : null;
  const store = useSelector((state: any) => state.store);
  const refProvinsi = store?.provinsi || [];
  const refKabkota = store.kabkota || [];
  const dispatch = useDispatch();
  const listBatch = getBatch();
  const auths = useSelector((state: any) => state.auth);
  const [activeTabKey, setActiveTabKey] = useState<string>(
    dataState?.activeTabKey || "verifikasi",
  );
  const bos = useSelector((state: any) => state.bos);
  const tmpPengajuanSumberDanaBos = bos?.pengajuanSumberDanaBos || [];
  const tmpPenyaluranSumberDanaBos = bos?.penyaluranSumberDanaBos || [];
  const [formData] = Form.useForm();
  const [formPengajuan] = Form.useForm();
  // const [id, setID] = useState<any>(null);
  const auth = auths?.data || null;
  const [search, setSearch] = useState<any>(null);
  const [title, setTitle] = useState<any>(null);
  const [tmpMadrasah, setTmpMadrasah] = useState<any>([]);
  const [batchFilter, setBatchFilter] = useState<any>([]);
  const [dataMadrasah, setDataMadrasah] = useState<any>();
  const [tmpFilter, setTmpFilter] = useState<any>(null);
  const [openModal, setOpenModal] = useState<any>(false);
  const [openModalPriview, setOpenModalPriview] = useState<any>(false);
  const [openModalPencairan, setOpenModalPencairan] = useState<any>(false);
  const [dataPencairan, setDataPencairan] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(dataState?.page || 1);
  const [pageSize, setPageSize] = useState(dataState?.pageSize || 10);

  useEffect(() => {
    if (auths?.isLogin) {
      getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth?.isTahun]);

  /** Filter Search */
  let dataTable = search
    ? tmpMadrasah.filter((item: any) => {
        const val = search.toLowerCase();
        return (
          (item.provinsi !== null &&
            (item.provinsi || "").toString().toLowerCase().includes(val)) ||
          (item.nsm !== null &&
            (item.nsm || "").toString().toLowerCase().includes(val)) ||
          (item.kabkota !== null &&
            (item.kabkota || "").toString().toLowerCase().includes(val)) ||
          (item.nama_pemilik_rekening !== null &&
            (item.nama_pemilik_rekening || "")
              .toString()
              .toLowerCase()
              .includes(val)) ||
          (item.nomor_rekening !== null &&
            (item.nomor_rekening || "")
              .toString()
              .toLowerCase()
              .includes(val)) ||
          (item.nama_madrasah !== null &&
            (item.nama_madrasah || "").toString().toLowerCase().includes(val))
        );
      })
    : tmpMadrasah;
  dataTable =
    (tmpFilter?.tahap &&
      dataTable.filter((item) => item.tahapan === tmpFilter.tahap)) ||
    dataTable;
  dataTable =
    (tmpFilter?.tanggal &&
      dataTable.filter(
        (item) =>
          moment(tmpFilter.tanggal[0])
            .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
            .isBefore(moment(item.tanggal_verifikasi)) &&
          moment(tmpFilter.tanggal[1])
            .set({ hour: 23, minute: 59, second: 59, millisecond: 59 })
            .isAfter(moment(item.tanggal_verifikasi)),
      )) ||
    dataTable;

  let totalDataTable = dataTable.length;
  /**
   * Filter Pagination
   */
  dataTable = dataTable.filter((v, i) => {
    let start = pageSize * (page - 1);
    let end = start + pageSize;

    return i >= start && i < end;
  });

  // useEffect(() => {
  //   const tmpTable =
  //     (tmpFilter?.tahap &&
  //       tmpAlokasiSumberBos.filter(
  //         (item) => item.tahapan === tmpFilter.tahap,
  //       )) ||
  //     tmpAlokasiSumberBos;
  //   setTmpMadrasah(tmpTable);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [tmpFilter]);
  const getData = async () => {
    setLoading(true);
    // await deleteVerifikasiSumberDanaBos("0h1zg8qc93h0v");
    try {
      const verifikasiSumberDanaBos =
        (await getPlanningAll("portal-bos/verifikasi/list", {
          tahun: auths?.isTahun,
        })) || [];
      const pengajuanSumberDanaBos =
        (await getPlanningAll("portal-bos/pengajuan/list", {
          tahun: auths?.isTahun,
          status: 2,
        })) || [];
      const penyaluranSumberDanaBos =
        (await getPlanningAll("portal-bos/pengajuan/list", {
          tahun: auths?.isTahun,
          status: 3,
        })) || [];

      setTmpMadrasah(verifikasiSumberDanaBos);
      setTimeout(() => {
        dispatch(
          setBos({
            verifikasiSumberDanaBos,
            pengajuanSumberDanaBos,
            penyaluranSumberDanaBos,
          }),
        );
      }, 100);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  // const handleChangeValues = (values: any) => {
  //   setTmpFilter(values);
  // };

  const handleExportFile = (type: any) => {
    if (dataTable.length > 0) {
      let datas: any =
        (tmpFilter?.tanggal[0] &&
          tmpMadrasah.filter(
            (item) =>
              moment(tmpFilter.tanggal[0])
                .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
                .isBefore(moment(item.tanggal_verifikasi)) &&
              moment(tmpFilter.tanggal[1])
                .set({ hour: 23, minute: 59, second: 59, millisecond: 59 })
                .isAfter(moment(item.tanggal_verifikasi)),
          )) ||
        tmpMadrasah;
      exportDataPengajuan(
        "VERIFIKASI",
        datas,
        type,
        auths?.isTahun,
        store,
        dataState,
      );
    } else {
      notifAlert({
        type: "warning",
        description: "Maaf data tidak tersedia!",
      });
    }
  };

  const handleDelete = async (values: any, type: any) => {
    if (type === "verifikasi") {
      try {
        const res = await deleteVerifikasiSumberDanaBos(
          values?.dokumen_pencairan_id,
        );
        if (res) {
          notifAlert({
            type: "success",
            description: "Penghapusan Data Madrasah Berhasil",
          });
          getData();
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      let payload: any = {
        tahun: parseInt(auth?.isTahun),
        tahap: parseInt(values?.tahapan),
        batch: parseInt(values?.batch),
        status: type === "pengajuan" ? 2 : 3,
      };
      try {
        const res = await deletePencairanSumberDanaBos(payload);
        if (res) {
          notifAlert({
            type: "success",
            description: "Penghapusan Data Madrasah Berhasil",
          });
          getData();
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  let columnsDokumen: any = [
    {
      title: "NSM",
      key: "nsm",
      dataIndex: "nsm",
    },
    {
      title: "Madrasah",
      dataIndex: "nama_madrasah",
      key: "nama_madrasah",
      width: 200,
    },
    {
      title: "Jenjang",
      dataIndex: "jenjang",
      key: "jenjang",
      render: (jenjang) => <div className="uppercase">{jenjang}</div>,
    },
    {
      title: "Provinsi",
      dataIndex: "kode_provinsi",
      key: "kode_provinsi",
      width: 200,
      render: (kode_provinsi) => {
        return (
          refProvinsi.find((item) => item.kode === kode_provinsi)?.nama || ""
        );
      },
    },
    {
      title: "Kabkota",
      dataIndex: "kode_kabkota",
      key: "kode_kabkota",
      width: 200,
      render: (kode_kabkota) => {
        return (
          refKabkota.find((item) => item.kode === kode_kabkota)?.nama || ""
        );
      },
    },
    {
      title: "Alokasi Anggaran",
      key: "Nominal",
      children: [
        {
          title: "Tahap 1",
          dataIndex: "tahap1",
          key: "tahap1",
          render: (tahap1) => (
            <div className="text-right">{formatCurr(tahap1)}</div>
          ),
        },
        {
          title: "Tahap 2",
          key: "tahap2",
          dataIndex: "tahap2",
          render: (tahap2) => (
            <div className="text-right">{formatCurr(tahap2)}</div>
          ),
        },
        {
          title: "Nominal Non AA",
          key: "nominalNonAA",
          render: (record) => (
            <div className="text-right">
              {formatCurr(record.tahap2 - record.nilai_aa)}
            </div>
          ),
        },
        {
          title: "Nominal AA",
          key: "nilai_aa",
          dataIndex: "nilai_aa",
          render: (nilai_aa) => (
            <div className="text-right">{formatCurr(nilai_aa)}</div>
          ),
        },
        {
          title: "Total",
          key: "nominal_alokasi",
          dataIndex: "total",
          render: (total) => (
            <div className="text-right">{formatCurr(total)}</div>
          ),
        },
      ],
    },
    {
      title: "Tahapan",
      key: "tahapan",
      dataIndex: "tahapan",
      render: (tahapan) => `Tahap ${tahapan}`,
    },
    {
      title: "Tanggal Verifikasi",
      key: "tanggal_verifikasi",
      width: 300,
      dataIndex: "tanggal_verifikasi",
      render: (tanggal_verifikasi) =>
        moment(tanggal_verifikasi).format("dddd,DD MMM YYYY HH:mm:ss"),
    },
    {
      title: "Rekening Madrasah",
      key: "rekening",
      children: [
        {
          title: "Nama BANK",
          dataIndex: "nama_bank",
          key: "nama_bank",
        },
        {
          title: "No Rekening",
          key: "nomor_rekening",
          dataIndex: "nomor_rekening",
        },
        {
          title: "Nama Pemilik",
          key: "nama_pemilik_rekening",
          dataIndex: "nama_pemilik_rekening",
        },

        {
          title: "Cabang",
          key: "cabang",
          dataIndex: "cabang",
        },
      ],
    },

    {
      title: "Aksi",
      key: "aksi",
      width: "100%",
      fixed: "right",
      render: (record) => {
        return (
          <Space>
            {/* <ButtonTableEdit onClick={() => handleEdit(record)} /> */}
            <ButtonTableDelete
              onClick={() => {
                Modal.confirm({
                  title: "Perhatian",
                  content: "Yakin Hapus Data?",
                  centered: true,
                  wrapClassName: "erkam-custom-modal",
                  onOk() {
                    return handleDelete(record, "verifikasi");
                  },
                  okText: "Hapus",
                  cancelText: "Batal",
                  okType: "danger",
                });
              }}
            />
          </Space>
        );
      },
    },
  ];

  let columnsPengajuan: any = [
    {
      title: "Tanggal",
      dataIndex: "tanggal_pengajuan",
      key: "tanggal_pengajuan",
      width: 240,
      render: (tanggal_pengajuan) =>
        moment(tanggal_pengajuan).format("dddd,DD MMM YYYY HH:mm:ss"),
    },
    {
      title: "Batch",
      key: "batch",
      width: 100,
      dataIndex: "batch",
      render: (batch) => {
        let namaBatch = listBatch.find((item) => item.kode === batch) || null;
        return namaBatch?.nama;
      },
    },
    {
      title: "Tahapan",
      key: "tahapan",
      width: 100,
      dataIndex: "tahapan",
      render: (tahapan) => `Tahap ${tahapan}`,
    },
    {
      title: "Total Anggaran",
      key: "total_anggaran",
      width: 160,
      dataIndex: "total_anggaran",
      render: (total_anggaran) => (
        <div className="text-right">{formatCurr(total_anggaran)}</div>
      ),
    },
    {
      title: "Lembaga",
      key: "jumlah_lembaga",
      dataIndex: "jumlah_lembaga",
    },

    {
      title: "Periode",
      key: "periode",
      children: [
        {
          title: "Mulai",
          dataIndex: "tanggal_mulai_verifikasi",
          width: 200,
          key: "tanggal_mulai_verifikasi",
          render: (tanggal_mulai_verifikasi) =>
            moment(tanggal_mulai_verifikasi).format("DD MMMM YYYY"),
        },
        {
          title: "Akhir",
          dataIndex: "tanggal_akhir_verifikasi",
          width: 200,
          key: "tanggal_akhir_verifikasi",
          render: (tanggal_akhir_verifikasi) =>
            moment(tanggal_akhir_verifikasi).format("DD MMMM YYYY"),
        },
      ],
    },
    {
      title: "Aksi",
      key: "aksi",
      width: 120,
      fixed: "right",
      render: (record) => {
        return (
          <Space>
            <ButtonTableDetail
              tooltips="Shortlist"
              onClick={() =>
                route.push({
                  pathname: `/pencairan/pengajuan/sortlist`,
                  state: {
                    page: page,
                    pageSize: pageSize,
                    activeTabKey: activeTabKey,
                    ...record,
                  },
                })
              }
              // onClick={() => handlePriviewShortlist(record)}
            />
            <ButtonTableApproval
              tooltips={
                activeTabKey === "pengajuan" ? "Pencairan" : "Dicairkan"
              }
              onClick={() => handlePencairan(record)}
            />
            <ButtonTableDelete
              onClick={() => {
                Modal.confirm({
                  title: "Perhatian",
                  content: "Yakin Hapus Data?",
                  centered: true,
                  wrapClassName: "erkam-custom-modal",
                  onOk() {
                    return handleDelete(record, "pengajuan");
                  },
                  okText: "Hapus",
                  cancelText: "Batal",
                  okType: "danger",
                });
              }}
            />
          </Space>
        );
      },
    },
  ];

  let columnsPencairan: any = [
    {
      title: "Tanggal Penyaluran",
      dataIndex: "tanggal_penyaluran",
      width: 240,
      key: "tanggal_penyaluran",
      render: (tanggal_penyaluran) =>
        moment(tanggal_penyaluran).format("dddd,DD MMM YYYY HH:mm:ss"),
    },
    {
      title: "Tanggal Pengajuan",
      dataIndex: "tanggal_pengajuan",
      width: 240,
      key: "tanggal_pengajuan",
      render: (tanggal_pengajuan) =>
        moment(tanggal_pengajuan).format("dddd,DD MMM YYYY HH:mm:ss"),
    },
    {
      title: "Batch",
      key: "batch",
      width: 100,
      dataIndex: "batch",
      render: (batch) => {
        let namaBatch = listBatch.find((item) => item.kode === batch) || null;
        return namaBatch?.nama;
      },
    },
    {
      title: "Tahapan",
      key: "tahapan",
      width: 100,
      dataIndex: "tahapan",
      render: (tahapan) => `Tahap ${tahapan}`,
    },
    {
      title: "Total Anggaran",
      key: "total_anggaran",
      width: 160,
      dataIndex: "total_anggaran",
      render: (total_anggaran) => (
        <div className="text-right">{formatCurr(total_anggaran)}</div>
      ),
    },
    {
      title: "Lembaga",
      key: "jumlah_lembaga",
      dataIndex: "jumlah_lembaga",
    },

    {
      title: "Periode",
      key: "periode",
      children: [
        {
          title: "Mulai",
          dataIndex: "tanggal_mulai_verifikasi",
          key: "tanggal_mulai_verifikasi",
          width: 200,
          render: (tanggal_mulai_verifikasi) =>
            moment(tanggal_mulai_verifikasi).format("DD MMMM YYYY"),
        },
        {
          title: "Akhir",
          dataIndex: "tanggal_akhir_verifikasi",
          key: "tanggal_akhir_verifikasi",
          width: 200,
          render: (tanggal_akhir_verifikasi) =>
            moment(tanggal_akhir_verifikasi).format("DD MMMM YYYY"),
        },
      ],
    },
    {
      title: "Aksi",
      key: "aksi",
      width: 100,
      fixed: "right",
      render: (record) => {
        return (
          <Space>
            <ButtonTableDetail
              onClick={() =>
                route.push({
                  pathname: `/pencairan/pengajuan/sortlist`,
                  state: {
                    page: page,
                    pageSize: pageSize,
                    activeTabKey: activeTabKey,
                    ...record,
                  },
                })
              }
              tooltips="Shortlist"
            />
            <ButtonTableDelete
              onClick={() => {
                Modal.confirm({
                  title: "Perhatian",
                  content: "Yakin Hapus Data?",
                  centered: true,
                  wrapClassName: "erkam-custom-modal",
                  onOk() {
                    return handleDelete(record, "penyaluran");
                  },
                  okText: "Hapus",
                  cancelText: "Batal",
                  okType: "danger",
                });
              }}
            />
          </Space>
        );
      },
    },
  ];
  const handleSave = async (values: any) => {
    let payload: any = {
      tahun: parseInt(auth?.isTahun),
      tahap: parseInt(values?.tahap),
      batch: parseInt(values?.batch),
      start_tanggal_verifikasi: moment(values?.tanggal[0]).format(
        "YYYY-MM-DDT00:00:00",
      ),
      end_tanggal_verifikasi: moment(values?.tanggal[1]).format(
        "YYYY-MM-DDT23:59:59",
      ),
    };
    try {
      if (dataTable.length) {
        const res = await editVerifikasiSumberDanaBos(payload);
        if (res) {
          notifAlert({
            type: "success",
            description: "Data Madrasah berhasil diajukan ke Bank",
          });
          getData();
        }
      } else {
        notifAlert({
          type: "error",
          description: "Tidak ada data madrasah yang terpilih!!",
        });
      }
      handleResetFormPengajuan();
    } catch (error) {
      console.log(error);
    }
    setOpenModal(false);
  };

  const handleReset = () => {
    setOpenModal(false);
    formData.resetFields();
  };

  const handleChangeFormPengajuan = (values: any, all: any) => {
    setTmpFilter(all);
    let filterBatch: any = [];
    for (let obj in values) {
      if (obj === "tahap") {
        let tmpDataPencairan = [
          ...tmpPengajuanSumberDanaBos,
          ...tmpPenyaluranSumberDanaBos,
        ];
        tmpDataPencairan
          .filter(
            (item) => item.tahapan.toString() === values?.tahap?.toString(),
          )
          .map((item) => filterBatch.push(item?.batch));
        formPengajuan.setFieldsValue({ batch: null });
      }
    }
    setBatchFilter(filterBatch);
  };

  const handleResetFormPengajuan = () => {
    formPengajuan.resetFields();
    setTmpFilter(null);
  };

  // const handlePriviewShortlist = (values: any) => {
  //   setOpenModalPriview(true);
  //   setTitle("List Pengajuan Pencairan BOS");
  //   setDataPencairan(values);
  // };

  const closePriviewShortlist = () => {
    setOpenModalPriview(false);
    setDataPencairan(null);
  };

  const closePencairan = () => {
    setOpenModalPencairan(false);
    setDataPencairan(null);
    setTitle(null);
    setDataMadrasah(null);
  };

  const handlePencairan = (values: any) => {
    setOpenModalPencairan(true);
    setTitle("Pengajuan Pencairan BOS");
    setDataPencairan(values);
  };

  const handleSavePencairan = async (values: any) => {
    let payload: any = {
      tahun: parseInt(auth?.isTahun),
      tahap: parseInt(dataPencairan?.tahapan),
      batch: parseInt(dataPencairan?.batch),
      start_tanggal_verifikasi: moment(
        dataPencairan?.tanggal_mulai_verifikasi,
      ).format("YYYY-MM-DDT00:00:00"),
      end_tanggal_verifikasi: moment(
        dataPencairan?.tanggal_akhir_verifikasi,
      ).format("YYYY-MM-DDT23:59:59"),
      tanggal_penyaluran: moment(values?.tanggalPencairan).format(
        "YYYY-MM-DDTHH:mm:ss",
      ),
    };
    try {
      const res = await editPengajuanSumberDanaBos(payload);
      if (res) {
        notifAlert({
          type: "success",
          description: "Data berhasil di Salurkan",
        });
        setDataPencairan(null);
        getData();
      }
    } catch (error) {
      console.log(error);
    }
    setOpenModalPencairan(false);
  };

  return (
    <>
      <BreadCrumb
        routes={itemBreadcrumb}
        title="Pengajuan Pencairan"
        footer={
          <Tabs
            defaultActiveKey={activeTabKey}
            size="small"
            onChange={(key) => {
              setActiveTabKey(key);
            }}>
            <TabPane tab="Verifikasi" key="verifikasi" />
            <TabPane tab="Pengajuan" key="pengajuan" />
            <TabPane tab="Penyaluran" key="penyaluran" />
          </Tabs>
        }
      />
      {activeTabKey === "verifikasi" && (
        <>
          <div className="mx-5 mt-4 p-5 bg-white shadow-md rounded">
            <Form
              form={formPengajuan}
              name="formPencairan"
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 8 }}
              labelAlign="left"
              labelWrap
              layout="horizontal"
              onValuesChange={handleChangeFormPengajuan}
              onFinish={handleSave}>
              <Form.Item
                label="Tahapan"
                name="tahap"
                rules={[
                  {
                    required: true,
                    message: "Tahap tidak boleh kosong!",
                  },
                ]}>
                <Radio.Group>
                  <Radio value="1">Tahap 1</Radio>
                  <Radio value="2"> Tahap 2</Radio>
                </Radio.Group>
              </Form.Item>
              <Form.Item
                label="Batch"
                name="batch"
                rules={[
                  {
                    required: true,
                    message: "Batch tidak boleh kosong!",
                  },
                ]}>
                <Select
                  showSearch
                  optionFilterProp="children"
                  placeholder="Pilih Batch">
                  {listBatch?.length &&
                    listBatch
                      .filter((item) => !batchFilter.includes(item.kode))
                      .map((e: any, i: any) => {
                        return (
                          <Select.Option key={`role${i}`} value={e.kode}>
                            {e.nama}
                          </Select.Option>
                        );
                      })}
                </Select>
              </Form.Item>
              <Form.Item
                label="Tanggal Mulai dan Akhir"
                name="tanggal"
                rules={[
                  {
                    required: true,
                    message:
                      "Tanggal Mulai dan Tanggal Akhir tidak boleh kosong!",
                  },
                ]}>
                <RangePicker
                  style={{ width: "100%" }}
                  //   disabledDate={disabledDate}
                  //   disabledTime={disabledRangeTime}
                  placeholder={["Pilih Tanggal Mulai", "Pilih Tanggal Selesai"]}
                />
              </Form.Item>
              <Form.Item>
                <Button
                  type="ghost"
                  className="mr-2"
                  onClick={() => handleResetFormPengajuan()}>
                  Reset
                </Button>
                <Button onClick={() => formPengajuan.submit()} type="primary">
                  Ajukan Pencairan
                </Button>
              </Form.Item>
            </Form>
          </div>
          <div className="m-5 p-5 bg-white shadow-md rounded">
            <div className="mb-4 flex justify-end">
              <div className="mr-4">
                <Space wrap>
                  <ButtonDropdownExport handleExportFile={handleExportFile} />
                </Space>
              </div>
              <InputSearch
                className="w-3/4"
                onChange={(e: any) => setSearch(e.currentTarget.value)}
              />
            </div>
            <Table
              loading={loading}
              columns={columnsDokumen}
              dataSource={dataTable}
              bordered
              pagination={{
                total: totalDataTable,
                position: ["bottomRight"],
                defaultPageSize: pageSize,
                defaultCurrent: page,
                showTotal: (total) => `Total ${total} items`,
                onChange(page, pageSize) {
                  setPage(page);
                  setPageSize(pageSize);
                },
              }}
            />
          </div>
        </>
      )}
      {activeTabKey === "pengajuan" && (
        <>
          <div className="m-5 p-5 bg-white shadow-md rounded">
            {/* <div className="mb-4 flex justify-end">
              <div className="mr-4">
                <Space wrap>
                  <ButtonDropdownExport handleExportFile={handleExportFile} />
                </Space>
              </div>
              <InputSearch
                className="w-3/4"
                onChange={(e: any) => setSearch(e.currentTarget.value)}
              />
            </div> */}
            <Table
              loading={loading}
              columns={columnsPengajuan}
              dataSource={tmpPengajuanSumberDanaBos}
              bordered
            />
          </div>
        </>
      )}
      {activeTabKey === "penyaluran" && (
        <>
          <div className="m-5 p-5 bg-white shadow-md rounded">
            {/* <div className="mb-4 flex justify-end">
              <div className="mr-4">
                <Space wrap>
                  <ButtonDropdownExport handleExportFile={handleExportFile} />
                </Space>
              </div>
              <InputSearch
                className="w-3/4"
                onChange={(e: any) => setSearch(e.currentTarget.value)}
              />
            </div> */}
            <Table
              loading={loading}
              columns={columnsPencairan}
              dataSource={tmpPenyaluranSumberDanaBos}
              bordered
            />
          </div>
        </>
      )}
      <ModalPriviewListPencairan
        data={dataPencairan}
        openModal={openModalPriview}
        handleClose={closePriviewShortlist}
        title={title}
        store={store}
        tahun={auths?.isTahun}
      />
      <ModalTanggalPencairan
        data={dataPencairan}
        openModal={openModalPencairan}
        handleClose={closePencairan}
        title={title}
        handleSave={handleSavePencairan}
      />
      <Modal
        open={openModal}
        width={1000}
        title={`Alokasi Dana BOS`}
        onCancel={handleReset}
        footer={[
          <Button onClick={handleReset}>Batal</Button>,
          <Button type="primary" onClick={() => formData.submit()}>
            Simpan
          </Button>,
        ]}>
        <Form
          form={formData}
          name="formPencairan"
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 8 }}
          labelAlign="left"
          labelWrap
          layout="horizontal"
          onFinish={handleSave}>
          <Form.Item label="NSM">{dataMadrasah?.nsm || ""}</Form.Item>
          <Form.Item label="Madrasah">
            {dataMadrasah?.namaMadrasah || ""}
          </Form.Item>
          <Form.Item label="Jenjang">{dataMadrasah?.jenjang || ""}</Form.Item>
          <Form.Item label="Provinsi">
            {dataMadrasah?.namaProvinsi || ""}
          </Form.Item>
          <Form.Item label="Kabkota">
            {dataMadrasah?.namaKabkota || ""}
          </Form.Item>
          <Form.Item
            label="Tahap 1"
            name="tahap1"
            rules={[
              {
                required: true,
                message: "Nilai Tahap 1 tidak boleh kosong!",
              },
            ]}>
            <InputNumber
              style={{ width: "100%" }}
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
              prefix="Rp."
              placeholder="Nilai Tahap 1"
            />
          </Form.Item>
          <Form.Item
            label="Tahap 2"
            name="tahap2"
            rules={[
              {
                required: true,
                message: "Nilai Tahap 2 tidak boleh kosong!",
              },
            ]}>
            <InputNumber
              style={{ width: "100%" }}
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
              prefix="Rp."
              placeholder="Nilai Tahap 2"
            />
          </Form.Item>
          <Form.Item
            label="Jumlah Siswa"
            name="jumlahSiswa"
            rules={[
              {
                required: true,
                message: "Nilai Tahap 1 tidak boleh kosong!",
              },
            ]}>
            <InputNumber
              style={{ width: "100%" }}
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
              placeholder="Jumlah Siswa"
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default PengajuanPencairanBos;
