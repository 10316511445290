/** @format */
/**update fix */
import api from "../../../utils/api";
import { planningService } from "../constant";
// import { setStoreDB } from "../../../utils/StoreDB";
const basePath = `${planningService}`;

/** Get Laporan RAPBM rekap By Madrasah ID dan Tahun   */
type laporanRapbmRekapParams = {
  madrasahId?: string;
  tahun: number;
};

export const getLaporanRapbmRekap = async (params: laporanRapbmRekapParams) => {
  try {
    const response = await api.get(`${basePath}/laporan-rapbm-rekap`, {
      params,
    });
    // setStoreDB("laporanRapbmRekap", response.data.return);
    return response?.data?.return;
  } catch (error) {
    console.log(error);
  }
};

/** Get Laporan Erkam By Madrasah ID dan Tahun   */

export const getLaporanErkam = async (params: laporanRapbmRekapParams) => {
  try {
    const response = await api.get(`${basePath}/laporan-erkam`, {
      params,
    });
    // setStoreDB("laporanErkam", response.data.return);
    return response?.data?.return;
  } catch (error) {
    console.log(error);
  }
};

export const getLaporanErkamIndikatif = async (
  params: laporanRapbmRekapParams,
) => {
  try {
    const response = await api.get(`${basePath}/laporan-erkam-indikatif`, {
      params,
    });
    // setStoreDB("laporanErkam", response.data.return);
    return response?.data?.return;
  } catch (error) {
    console.log(error);
  }
};
/** Get Laporan Erkam By Madrasah ID dan Tahun   */

export const getLaporanRapbm = async (params: laporanRapbmRekapParams) => {
  try {
    const response = await api.get(`${basePath}/laporan-rapbm`, {
      params,
    });
    // setStoreDB("laporanErkam", response.data.return);
    return response?.data?.return;
  } catch (error) {
    console.log(error);
  }
};

/** Get Laporan Apbm Dashboard By  Tahun   */
// type laporanApbmDashboardParams = {
//   tahun: number;
// };

/** Get Laporan RKAKL Dashboard By  Tahun   */
type laporanRKAKLParams = {
  tahun: number;
};

export const getLaporanRkakl = async (params: laporanRKAKLParams) => {
  try {
    const response = await api.get(`${basePath}/rencana/report-rkakl-rekap`, {
      params,
    });
    return response?.data?.return;
  } catch (error) {
    console.log(error);
  }
};

export const getLaporanRkaklKonsolidasi = async (params) => {
  try {
    const response = await api.get(
      `${basePath}/rencana/report-rkakl-konsolidasi`,
      {
        params,
      },
    );
    return response?.data?.return;
  } catch (error) {
    console.log(error);
  }
};

/** Get Laporan APBM By Madrasah ID dan Tahun   */
type laporanAPBMPlanParams = {
  madrasahId?: string;
  tahun: any;
};

export const getLaporanAPBMPlanning = async (params: laporanAPBMPlanParams) => {
  try {
    const response = await api.get(`${basePath}/laporan-apbm`, {
      params,
    });
    return response?.data?.return;
  } catch (error) {
    console.log(error);
  }
};
