/** @format */

import React, { useEffect, useState } from "react";
import {
  Switch,
  Select,
  Divider,
  Form,
  Modal,
  InputNumber,
  Descriptions,
  Tag,
} from "antd";
import { useSelector } from "react-redux";

type FormModalActionType = {
  title: any;
  editData: any;
  openModal: boolean;
  hanldeClose: () => void;
  handleSave: (e: any) => void;
};

const ModalKomponenBiaya = ({
  title,
  openModal,
  editData,
  handleSave,
  hanldeClose,
}: FormModalActionType) => {
  const [form] = Form.useForm();
  const store = useSelector((state: any) => state.store);
  const auths = useSelector((state: any) => state.auth);
  const tmpKategoriKomponenBiaya = store.kategoriKomponenBiaya || [];
  //   const tmpKategoriBelanja = store.kategoriBelanja || [];
  const tmpSatuan = store.satuan || [];
  const refKomponenBiayaMaster = store.komponenBiayaMaster || [];
  const tmpProvinsi = store.provinsi || [];
  const tmpKabkota = store.kabkota || [];
  const [dataKomponenBiaya, setDataKomponenBiaya] = useState<any>(null);
  const [provinsi, setProvinsi] = useState<any>(null);
  const [enabledProvinsi, setEnabledProvinsi] = useState<any>(false);
  const [enabledKabkota, setEnabledKabkota] = useState<any>(false);

  const handleReset = () => {
    form.resetFields();
    hanldeClose();
    setDataKomponenBiaya(null);
    setProvinsi(null);
    setEnabledKabkota(false);
    setEnabledProvinsi(false);
  };

  useEffect(() => {
    if (editData && title === "Edit") {
      setProvinsi(editData.kode_provinsi);
      setEnabledKabkota(true);
      setEnabledProvinsi(true);
      let tmpKomponen: any =
        refKomponenBiayaMaster.find((item) => item.kode === editData.kode) ||
        null;
      setDataKomponenBiaya(tmpKomponen);
      form.setFieldsValue({
        komponenBiaya: editData.kode,
        provinsi: editData.kode_provinsi,
        kabkota: editData.kode_kabkota,
        harga1: editData.harga_1,
        harga2: editData.harga_2,
        harga3: editData.harga_3,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editData]);

  const handleAfterSave = (values: any) => {
    let listHarga: any = [];
    let hg1 = values.harga1 || 0;
    let hg2 = values.harga2 || 0;
    let hg3 = values.harga3 || 0;
    if (values.seluruhProvinsi) {
      tmpKabkota.length &&
        tmpKabkota
          // eslint-disable-next-line array-callback-return
          .map((item) => {
            let harga = {
              kodeKabKota: item.kode,
              kodeProvinsi: item.kode_provinsi,
              harga1: hg1,
              harga2: hg2,
              harga3: hg3,
            };
            listHarga.push(harga);
          });
    } else {
      if (values.seluruhKabkota) {
        tmpKabkota.length &&
          tmpKabkota
            .filter((item) => item.kode_provinsi === values.provinsi)
            // eslint-disable-next-line array-callback-return
            .map((item) => {
              let harga = {
                kodeKabKota: item.kode,
                kodeProvinsi: item.kode_provinsi,
                harga1: hg1,
                harga2: hg2,
                harga3: hg3,
              };
              listHarga.push(harga);
            });
      } else {
        let harga = {
          kodeKabKota: values.kabkota,
          kodeProvinsi: values.provinsi,
          harga1: hg1,
          harga2: hg2,
          harga3: hg3,
        };
        listHarga.push(harga);
      }
    }

    const payload = {
      kodeKategori: dataKomponenBiaya.kode_kategori,
      kode: dataKomponenBiaya.kode,
      nama: dataKomponenBiaya.nama,
      spesifikasi: dataKomponenBiaya.spesifikasi,
      deskripsi: dataKomponenBiaya.deskripsi || null,
      satuan: dataKomponenBiaya.satuan,
      jenisBelanja: dataKomponenBiaya.jenis_belanja,
      tahun: auths.isTahun,
      jenisPembelanjaanId: dataKomponenBiaya.jenis_pembelanjaan_id,
      statusMadrasah: dataKomponenBiaya.status_madrasah,
      belanjaLain: dataKomponenBiaya.belanja_lain ? true : false,
      harga: listHarga,
    };
    handleSave(payload);
    handleReset();
  };

  const handleValueChange = (val) => {
    for (let obj in val) {
      switch (obj) {
        case "komponenBiaya":
          let tmpKomponen: any =
            refKomponenBiayaMaster.find(
              (item) => item.kode === val.komponenBiaya,
            ) || null;
          setDataKomponenBiaya(tmpKomponen);
          break;
        case "seluruhProvinsi":
          let checkEnabled = val.seluruhProvinsi ? true : false;

          setEnabledProvinsi(checkEnabled);
          setEnabledKabkota(checkEnabled);
          if (checkEnabled) {
            form.setFieldsValue({
              kabkota: null,
              provinsi: null,
              seluruhKabkota: false,
            });
          }

          break;
        case "seluruhKabkota":
          let checkEnabledKab = val.seluruhKabkota ? true : false;
          setEnabledKabkota(checkEnabledKab);
          if (checkEnabledKab) {
            form.setFieldsValue({
              kabkota: null,
            });
          }

          break;
        case "provinsi":
          setProvinsi(val.provinsi);
          form.setFieldsValue({ kabkota: null });
          break;
      }
    }
  };

  function checkNameKategori(kode) {
    let tmpKategori =
      tmpKategoriKomponenBiaya.find((item) => item.kode === kode) || null;
    return tmpKategori ? tmpKategori.nama : "-";
  }
  function checkNameSatuan(kode) {
    let tmSatuan = tmpSatuan.find((item) => item.kode === kode) || null;
    return tmSatuan ? tmSatuan.nama : "-";
  }
  return (
    <Modal
      open={openModal}
      title={`${title} Komponen Biaya`}
      width={1000}
      okText="Simpan"
      cancelText="Batal"
      onCancel={handleReset}
      onOk={() => form.submit()}>
      <Form
        key="formKomponenBiaya"
        name="formKomponenBiaya"
        layout="vertical"
        form={form}
        initialValues={{ harga1: 0, harga2: 0, harga3: 0 }}
        onValuesChange={handleValueChange}
        onFinish={handleAfterSave}>
        <Form.Item
          label="Pilih Komponen Biaya"
          name="komponenBiaya"
          rules={[
            {
              required: true,
              message: "Komponen Biaya tidak boleh kosong!",
            },
          ]}>
          <Select
            placeholder="Pilih Komponen Biaya"
            allowClear
            showSearch
            optionFilterProp="children"
            disabled={(title === "Edit" && true) || false}>
            {refKomponenBiayaMaster.length &&
              refKomponenBiayaMaster.map((item) => {
                return (
                  <Select.Option key={`kodeKom${item.kode}`} value={item.kode}>
                    {item.kode} - {item.nama}
                  </Select.Option>
                );
              })}
          </Select>
        </Form.Item>
        <Descriptions
          column={{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 1, xs: 1 }}
          size="small"
          bordered>
          <Descriptions.Item label="Kode">
            {dataKomponenBiaya ? dataKomponenBiaya?.kode : "-"}
          </Descriptions.Item>
          <Descriptions.Item label="Nama">
            {dataKomponenBiaya ? dataKomponenBiaya?.nama : "-"}
          </Descriptions.Item>
          <Descriptions.Item label="Kategori">
            {dataKomponenBiaya
              ? checkNameKategori(dataKomponenBiaya?.kode_kategori)
              : "-"}
          </Descriptions.Item>
          <Descriptions.Item label="Jenis Belanja">
            {dataKomponenBiaya
              ? dataKomponenBiaya?.jenis_belanja.map((item) => (
                  <Tag color="blue">{item}</Tag>
                ))
              : "-"}
          </Descriptions.Item>
          <Descriptions.Item label="Satuan">
            {dataKomponenBiaya
              ? checkNameSatuan(dataKomponenBiaya?.satuan)
              : "-"}
          </Descriptions.Item>
          <Descriptions.Item label="Termasuk Biaya Lainnya">
            {dataKomponenBiaya ? (
              <Tag color={dataKomponenBiaya.belanja_lain ? "green" : "default"}>
                {dataKomponenBiaya.belanja_lain ? "Aktif" : "Tidak Aktif"}
              </Tag>
            ) : (
              "-"
            )}
          </Descriptions.Item>
          <Descriptions.Item label="Spesifikasi">
            {dataKomponenBiaya ? dataKomponenBiaya.spesifikasi : "-"}
          </Descriptions.Item>
          <Descriptions.Item label="Deskripsi">
            {dataKomponenBiaya ? dataKomponenBiaya.deskripsi : "-"}
          </Descriptions.Item>
        </Descriptions>
        <Divider />
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div>
            <Form.Item
              label="Provinsi"
              name="provinsi"
              rules={[
                {
                  required: !enabledProvinsi,
                  message: "Provinsi tidak boleh kosong!",
                },
              ]}>
              <Select
                showSearch
                disabled={enabledProvinsi}
                optionFilterProp="children"
                placeholder="Pilih Provinsi">
                {tmpProvinsi.length &&
                  tmpProvinsi.map((e: any, i: any) => {
                    return (
                      <Select.Option key={`prov${i}`} value={e.kode}>
                        {e.nama}
                      </Select.Option>
                    );
                  })}
              </Select>
            </Form.Item>
          </div>
          <div>
            <Form.Item
              label="Seluruh Provinsi"
              name="seluruhProvinsi"
              valuePropName="checked">
              <Switch disabled={title === "Edit" ? true : false} />
            </Form.Item>
          </div>
          <div>
            <Form.Item
              label="Kab / Kota"
              name="kabkota"
              rules={[
                {
                  required: !enabledKabkota,
                  message: "Kab Kota tidak boleh kosong!",
                },
              ]}>
              <Select
                showSearch
                disabled={enabledKabkota}
                optionFilterProp="children"
                placeholder="Pilih Kab / Kota">
                {tmpKabkota.length &&
                  provinsi &&
                  tmpKabkota
                    .filter((item) => item.kode_provinsi === provinsi)
                    .map((e: any, i: any) => {
                      return (
                        <Select.Option key={`kabkota${i}`} value={e.kode}>
                          {e.nama}
                        </Select.Option>
                      );
                    })}
              </Select>
            </Form.Item>
          </div>
          <div>
            <Form.Item
              label="Seluruh Kab / Kota"
              name="seluruhKabkota"
              valuePropName="checked">
              <Switch disabled={enabledProvinsi} />
            </Form.Item>
          </div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          <div>
            <Form.Item label="Harga 1" name="harga1" key={`harga_1`}>
              <InputNumber
                placeholder="0"
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                style={{ width: "100%" }}
              />
            </Form.Item>
          </div>
          <div>
            <Form.Item label="Harga 2" name="harga2" key={`harga_2`}>
              <InputNumber
                placeholder="0"
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                style={{ width: "100%" }}
              />
            </Form.Item>
          </div>
          <div>
            <Form.Item label="Harga 3" name="harga3" key={`harga_3`}>
              <InputNumber
                placeholder="0"
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                style={{ width: "100%" }}
              />
            </Form.Item>
          </div>
        </div>
      </Form>
    </Modal>
  );
};
export default ModalKomponenBiaya;
