/** @format */

import api from "../../../utils/api";
import { notificationService } from "../constant";
import axios from "axios";

import { Client } from "@stomp/stompjs";
import notifAlert from "../../../components/NotifAlert";
// import { CheckNotification } from "../../../components/Notification/checkNotification";
const apiPortalBos = axios.create({
  baseURL: "https://madrasahkeren.com/v1",
  withCredentials: false,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: `Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwczpcL1wvbWFkcmFzYWgtYm9zLWJlLnRlc3RcL3YxXC9hdXRoXC9sb2dpbiIsImlhdCI6MTYwOTgxODAwMCwiZXhwIjoxNjA5ODIxNjAwLCJuYmYiOjE2MDk4MTgwMDAsImp0aSI6IkxHZHVLaGVHc2s3VTdIQ0YiLCJzdWIiOjMsInBydiI6IjIzYmQ1Yzg5NDlmNjAwYWRiMzllNzAxYzQwMDg3MmRiN2E1OTc2ZjcifQ.P6WcoyCR61VMQ4i_uvVslsWzZ-okzwjgdCFtvN2hxZU`,
  },
});

// request interceptor
apiPortalBos.interceptors.request.use(
  (config) => {
    return config;
  },
  (error) => {
    // console.log(error);
    if (error.response.status === 400) {
      return error.response.message;
    }
    return Promise.reject(error);
  },
);

// response interceptor
apiPortalBos.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 400) {
      notifAlert({
        type: "warning",
        description: `${error.response.data.message}`,
        // description: `${error.response.data.message} untuk peserta bimtek`,
      });
    }

    if (error.response.status === 500) {
      notifAlert({
        type: "error",
        description:
          error.response.data.return || "Ada kesalahan data dari portal BOS!!",
      });
    }

    return Promise.reject(error);
  },
);

const basePath = `${notificationService}`;
const url =
  window.REACT_APP_SOCKET ||
  "wss://gateway-dev.erkam.cronos.co.id/api/v2/message-broker-services/socket";

export const connectSocket = (token: any) => {
  // let ws;
  // let disabled = false;
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const client = new Client({
    brokerURL: url,
    connectHeaders: {
      Authorization: `Bearer ${token}`,
    },
    debug: (str) => {
      console.log(str);
    },
    reconnectDelay: 5000,
    heartbeatIncoming: 4000,
    heartbeatOutgoing: 4000,
    onConnect: (frame) => {
      console.log(frame, "frame");
      // console.log("websocket -> connected");
      client.subscribe("/message", async (message) => {
        if (message.body) {
          let msg = JSON.parse(message.body);
          console.log(msg, "test");
          // console.log("from : " + message.body);
          // CheckNotification(msg);
          // localStorage.setItem("notification", msg);
          // this.msg.push(message.body);
        } else {
          console.log(JSON.stringify(message.body));
        }
      });
    },
    onStompError: (frame) => {
      console.log("websocket -> error");
      console.error(frame);
      console.log(
        "Broker reported error: " + frame.headers["message".toString()],
      );
      console.log("Additional details: " + frame.body);
    },
  });
  client.activate();
  return client;
};

export const getNotification = async (userId: any) => {
  try {
    const response = await api.get(`${basePath}/notification`, {
      params: {
        // page: 1,
        // size: 5,
        receiverUserId: userId,
        notificationStatus: "NOT_READ",
      },
    });
    return response.data.return;
  } catch (error) {
    console.log(error);
  }
};

export const changeStatusNotif = async (param: any) => {
  let uuid = param.id;
  const response = await api.put(
    `${basePath}/notification/update-status/${uuid}?notificationStatus=READ`,
  );
  return response.data.return;
};

/** Get Alokasi Dana BOS from portal BOS NSM dan Tahun   */
export const getAlokasiDanaBos = async (nsm: any, year: any) => {
  try {
    const response = await apiPortalBos.get<any>(
      `/pusat/alokasi-bos/madrasah?nsm=${nsm}&tahun=${year}`,
    );
    // console.log(response);
    return response.data?.data;
  } catch (error) {
    console.log(error);
  }
};

/** Get Alokasi Dana BKBA from portal BOS NSM dan Tahun   */
export const getAlokasiDanaBKBA = async (nsm: any, year: any) => {
  try {
    const response = await apiPortalBos.get<any>(
      `/pusat/shortlist-bkba/madrasah?nsm=${nsm}&tahun=${year}`,
    );
    // console.log(response);
    return response.data?.data;
  } catch (error) {
    console.log(error);
  }
};
