/** @format */

import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { BreadCrumb } from "../../../../components";
import moment from "moment";
import "moment/locale/id";
import { Table, Space, Form, Tag } from "antd";
import { useSelector } from "react-redux";
import { formatRupiah, uuidv4 } from "../../../../utils/helper";
import {
  ButtonLog,
  ButtonTableDetail,
  ButtonTableEditBlue,
} from "../../../../components/Button";
import InputSearch from "../../../../components/InputSearch";
import ModalListRincian from "../Component/ModalListRincian";
import FilterPengeluaranKegiatan from "../Component/FilterPengeluaranKegiatan";
// import { ExportToExcel } from "../../../../components/Export/ExportToExcel";

const PengeluaranKegiatan = () => {
  const route = useHistory();
  const dataState: any = route ? route.location.state : null;
  const itemBreadcrumb = [
    { path: "/", breadcrumbName: "Home" },
    { path: "/", breadcrumbName: "Realisasi" },
    { path: "/", breadcrumbName: "Pengeluaran Kegiatan" },
    { path: "/", breadcrumbName: "List" },
  ];
  const store = useSelector((state: any) => state.store);

  const tmpSubKegiatan = store.subKegiatan || [];
  const tmpPendapatanDefinitif = store.rencanaPendapatanDefinitif || [];
  // const tmpBiayaDetail = store.realisasiBiayaDetail || [];
  let tmpRealisasiBiaya = store.realisasiBiaya || [];

  const rencanaKegiatanId = tmpRealisasiBiaya.map(
    (item) => item.rencanaRincianKegiatanId,
  );

  const tmpRencanaRincianKegiatanDefinitif =
    (store.rencanaRincianKegiatanDefinitif?.length &&
      store.rencanaRincianKegiatanDefinitif
        .filter(
          (item: any) => item.kepalaMadrasahApproved === "1",
          // && item?.status === "DISETUJUI",
        )
        .map((item) => {
          const matchingItems = tmpRealisasiBiaya.filter(
            (item2) => item.id === item2.rencanaRincianKegiatanId,
          );

          if (matchingItems) {
            return {
              ...item,
              noNota: matchingItems.map((data) => ({
                no: data.noNotaFormat,
                status: data.status,
              })),
              numbNota: matchingItems.map((data) => data.noNotaFormat),
            };
          }
          return item;
        })) ||
    [];
  const [form] = Form.useForm();
  const [search, setSearch] = useState<any>(null);
  const [sumberDana, setSumberDana] = useState<any>(null);
  const [ketersediaanNota, setKetersediaanNota] = useState<any>("");
  const [waktuPelaksanaan, setWaktuPelaksanaan] = useState<any>(null);
  const [dataEdit, setDataEdit] = useState<any>(null);
  const [openModal, setOpenModal] = useState(false);
  const [title, setTitle] = useState("");
  const [page, setPage] = useState(dataState?.page || 1);
  const [pageSize, setPageSize] = useState(dataState?.pageSize || 10);
  const listMonth = moment.months();

  let filterListSumber: any = [];
  tmpRencanaRincianKegiatanDefinitif?.length &&
    tmpRencanaRincianKegiatanDefinitif.map((item) =>
      item.alokasiPendapatan.map((items) =>
        filterListSumber.push(items.rencana_pendapatan.id),
      ),
    );
  const listSumber: any =
    tmpPendapatanDefinitif.filter((item) =>
      filterListSumber.includes(item.id),
    ) || [];

  let dataTable: any = search
    ? tmpRencanaRincianKegiatanDefinitif.filter((item: any) => {
        const val = search.toLowerCase();
        const checkNota = item.numbNota
          .filter((el) => el.toLowerCase().includes(val))
          .pop();
        return (
          checkNota ||
          (item.rencanaKegiatan.nama_snp !== null &&
            (item.rencanaKegiatan.nama_snp || "")
              .toString()
              .toLowerCase()
              .includes(val)) ||
          (item.rencanaKegiatan.nama_kegiatan !== null &&
            (item.rencanaKegiatan.nama_kegiatan || "")
              .toString()
              .toLowerCase()
              .includes(val)) ||
          (item.rencanaKegiatan.nama_sub_kegiatan !== null &&
            (item.rencanaKegiatan.nama_sub_kegiatan || "")
              .toString()
              .toLowerCase()
              .includes(val)) ||
          (item.komponenBiayaNama !== null &&
            (item.komponenBiayaNama || "")
              .toString()
              .toLowerCase()
              .includes(val))
        );
      })
    : tmpRencanaRincianKegiatanDefinitif;

  if (ketersediaanNota === "Belum dibuat") {
    dataTable = tmpRencanaRincianKegiatanDefinitif.filter(
      (item) => !rencanaKegiatanId.includes(item.id),
    );
  } else {
    if (ketersediaanNota) {
      dataTable = dataTable.filter((item) =>
        tmpRealisasiBiaya.find(
          (realisasi) =>
            realisasi.rencanaRincianKegiatanId === item.id &&
            realisasi.status === ketersediaanNota,
        ),
      );
    }

    if (waktuPelaksanaan) {
      dataTable = dataTable.filter(
        (item) =>
          item.rencanaKegiatan.bulan_pelaksanaan_start <= waktuPelaksanaan &&
          item.rencanaKegiatan.bulan_pelaksanaan_end >= waktuPelaksanaan,
      );
    }

    if (sumberDana) {
      dataTable = dataTable.filter((item) =>
        item.alokasiPendapatan.some(
          (items) => items.rencana_pendapatan.id === sumberDana,
        ),
      );
    }
  }

  let totalDataTable = dataTable.length;

  const edmPrioritas = store?.edmPrioritas || [];
  let filterSubKegiatanEdm: any = [];
  edmPrioritas.length &&
    // eslint-disable-next-line array-callback-return
    edmPrioritas.map((item) => {
      let dataSub =
        tmpSubKegiatan.find((it) => it.kode === item.kode_subkegiatan) || null;
      if (dataSub) {
        filterSubKegiatanEdm.push(dataSub);
      }
    });

  const sortedDataTable = dataTable.sort((a, b) => {
    const kodeA = a.rencanaKegiatan.kode_sub_kegiatan;
    const kodeB = b.rencanaKegiatan.kode_sub_kegiatan;
    const kodeAIndex = filterSubKegiatanEdm.findIndex((d) => d.kode === kodeA);
    const kodeBIndex = filterSubKegiatanEdm.findIndex((d) => d.kode === kodeB);
    return parseInt(kodeAIndex) - parseInt(kodeBIndex);
  });

  /**
   * Filter Pagination
   */
  dataTable = sortedDataTable.filter((v, i) => {
    let start = pageSize * (page - 1);
    let end = start + pageSize;

    return i >= start && i < end;
  });

  /**
   * Data untuk tabel children pertama
   */
  let columns: any = [
    {
      title: "SNP",
      key: "snp",
      width: 300,
      render: (record) => record.rencanaKegiatan.nama_snp,
    },
    {
      title: "Kegiatan",
      key: "kegiatan",
      width: 300,
      render: (record) => record.rencanaKegiatan.nama_kegiatan,
    },
    {
      title: "Sub Kegiatan",
      key: "subKegiatan",
      width: 300,
      render: (record) => record.rencanaKegiatan.nama_sub_kegiatan,
    },
    {
      title: "Komponen Biaya",
      key: "komponenBiayaNama",
      dataIndex: "komponenBiayaNama",
      width: 300,
      render: (komponenBiayaNama, record) =>
        `${komponenBiayaNama} ${
          record?.ketBiayaLain ? "| " + record.ketBiayaLain : ""
        }`,
    },
    {
      title: "Sumber Dana (Rupiah)",
      children: [
        {
          title: "Rencana",
          key: "rencanaSumberDana",
          width: 300,
          render: (record) =>
            record.alokasiPendapatan.length &&
            record.alokasiPendapatan.map((item: any) => (
              <div key={uuidv4()} className="flex justify-between">
                <div>{item.rencana_pendapatan.nama_sumber_dana}</div>
                <div>{formatRupiah(item.jumlah)}</div>
              </div>
            )),
        },
        {
          title: "Realisasi",
          key: "realisasiSumberDana",
          width: 380,
          render: (record) => {
            let tmpAlokasiRealisasi =
              tmpRealisasiBiaya.filter((item) =>
                item.rencanaRincianKegiatanId.includes(record.id),
              ) || [];

            let listAlokasi: any = [];

            tmpAlokasiRealisasi?.length &&
              // eslint-disable-next-line array-callback-return
              tmpAlokasiRealisasi.map((items: any) => {
                items.biayaSumberDanas?.length &&
                  items.biayaSumberDanas
                    .sort((a, b) => a.kodeSumberDana - b.kodeSumberDana)
                    // eslint-disable-next-line array-callback-return
                    .map((item) => {
                      const kodeTipe = `${item.kodeSumberDana}${item.kodeTipeKas}`;

                      var jmlAlokasi = listAlokasi[kodeTipe]?.jumlah || 0;
                      var jumlahTotal = item.jumlah + jmlAlokasi;
                      listAlokasi[kodeTipe] = {
                        nama: `${item.namaSumberDana} - ${item.namaTipeKas}`,
                        jumlah: jumlahTotal,
                      };
                    });
              });

            return (
              (Object.keys(listAlokasi).length &&
                Object.keys(listAlokasi).map((item: any) => {
                  return (
                    <div className="flex justify-between">
                      <div>{listAlokasi[item]?.nama}</div>
                      <div>{formatRupiah(listAlokasi[item]?.jumlah)}</div>
                    </div>
                  );
                })) ||
              ""
            );
          },
        },
      ],
    },
    {
      title: "Total (Rupiah)",
      children: [
        {
          title: "Rencana",
          key: "jumlahTotal",
          dataIndex: "jumlahTotal",
          width: 200,
          render: (jumlahTotal) => (
            <div className="text-right">{formatRupiah(jumlahTotal)}</div>
          ),
        },
        {
          title: "Realisasi",
          key: "realisasiJumlah",
          width: 200,
          render: (record) => {
            let tmpAlokasiRealisasi =
              tmpRealisasiBiaya.filter((item) =>
                item.rencanaRincianKegiatanId.includes(record.id),
              ) || [];
            let jmlTotal: any = null;
            tmpAlokasiRealisasi?.length &&
              // eslint-disable-next-line array-callback-return
              tmpAlokasiRealisasi.map((items: any) => {
                items.biayaSumberDanas?.length &&
                  // eslint-disable-next-line array-callback-return
                  items.biayaSumberDanas?.map((item) => {
                    jmlTotal += item.jumlah;
                  });
              });
            let textColor =
              (record?.jumlahTotal === jmlTotal && "text-right") ||
              "text-right font-bold text-red-600";

            return (
              (jmlTotal && (
                <div className={textColor}>{formatRupiah(jmlTotal)}</div>
              )) ||
              ""
            );
            // formatRupiah(jumlahTotal)
          },
        },
      ],
    },
    {
      title: "Waktu Pelaksanaan",
      key: "waktuPelaksanaan",
      width: 200,
      render: (record) =>
        listMonth[record.rencanaKegiatan.bulan_pelaksanaan_start - 1] +
        " - " +
        listMonth[record.rencanaKegiatan.bulan_pelaksanaan_end - 1],
    },
    {
      title: "No Nota",
      key: "noNota",
      width: 600,
      render: (record) => (
        <div>
          {record.noNota.length < 1 ? (
            <Tag color="warning">Belum Dibuat</Tag>
          ) : (
            record.noNota.map((data) =>
              ketersediaanNota === null ||
              ketersediaanNota === "" ||
              ketersediaanNota === data.status ? (
                <p style={{ fontSize: "11px" }}>
                  <Tag color="success">{data.no}</Tag>
                </p>
              ) : null,
            )
          )}
        </div>
      ),
    },
    {
      title: "Rincian",
      key: "rincian",
      children: [
        {
          title: "Total",
          key: "Totalrincian",
          width: 85,
          align: "center",
          fixed: "right",
          render: (record) => {
            let tmpAlokasiRealisasi: any =
              tmpRealisasiBiaya.filter((item) =>
                item.rencanaRincianKegiatanId.includes(record.id),
              ) || [];
            return tmpAlokasiRealisasi.length;
          },
        },
        {
          title: "Disetujui",
          key: "TotalSetujui",
          width: 93,
          align: "center",
          fixed: "right",
          render: (record) => {
            let tmpAlokasiRealisasi: any =
              tmpRealisasiBiaya.filter(
                (item) =>
                  item.rencanaRincianKegiatanId.includes(record.id) &&
                  item.status === "SELESAI",
              ) || [];
            return tmpAlokasiRealisasi.length;
          },
        },
      ],
    },
    {
      title: "Aksi",
      key: "aksi",
      width: 120,
      align: "center",
      fixed: "right",
      render: (record) => (
        <Space>
          <ButtonTableEditBlue
            tooltips="Lihat Rincian"
            onClick={() => handleRincian(record)}
          />
          <ButtonTableDetail
            tooltips="Rincian Pembuatan Nota"
            onClick={() =>
              route.push({
                pathname: "list/nota/" + record.id,
                state: {
                  page: page,
                  pageSize: pageSize,
                  ...record,
                },
              })
            }
          />
        </Space>
      ),
    },
  ];

  const handleRincian = (record: any) => {
    setTitle("Informasi");
    setOpenModal(true);
    setDataEdit(record);
  };

  const handleChangeValues = (values) => {
    for (let obj in values) {
      switch (obj) {
        case "sumberDana":
          setSumberDana(values.sumberDana || null);
          break;
        case "ketersediaanNota":
          setKetersediaanNota(values.ketersediaanNota || null);
          break;
        case "waktuPelaksanaan":
          setWaktuPelaksanaan(values.waktuPelaksanaan || null);
          break;
      }
    }
  };

  const onRow = () => {
    const handleClick = (event) => {
      const table = document.querySelector(".ant-table-content") as HTMLElement;
      const clickX = event.clientX;
      if (table) {
        const tableWidth = table.offsetWidth;
        const isClickOnRightSide = clickX > tableWidth / 2;
        const scrollDirection = isClickOnRightSide ? 1 : -1;
        table.scrollLeft += scrollDirection * (tableWidth / 2);
      }
    };

    return {
      onClick: handleClick,
    };
  };

  const onHeaderRow = () => {
    return onRow();
  };

  // let totalJumlah: any = 0;
  // tmpRealisasiBiaya.map((item) => {
  //   totalJumlah += item.grandTotal;
  // });

  // console.log(tmpRencanaRincianKegiatanDefinitif);

  // let tst = tmpRencanaRincianKegiatanDefinitif.filter((item) =>
  //   item.alokasiPendapatan.some(
  //     (items) =>
  //       items.rencana_pendapatan.kode_sumber_dana === "apbn_bos_semester_2",
  //     // &&
  //     // items.rencana_pendapatan.kode_sumber_dana === "bkba_afirmasi",
  //   ),
  // );

  // console.log(tst);
  // let totalJumlahT: any = 0;
  // tst.map((item) => {
  //   totalJumlahT += item.jumlahTotal;
  // });
  // let tmpFilterRealisasi = tmpRealisasiBiaya.filter(
  //   (item) => parseInt(moment(item.tanggalRealisasi).format("M")) == 3,
  // );

  // console.log(tmpFilterRealisasi);
  // console.log(tst);
  // const lll = tst.map((item) => item.id);
  // console.log(lll);
  // console.log(tmpRealisasiBiaya);
  // console.log(totalJumlahT);
  // console.log(totalJumlah);

  // let xls: any = [];
  // tmpBiayaDetail.map((item) => {
  //   xls.push({
  //     biayaPph21: item.biayaPph21,
  //     biayaPph22: item.biayaPph22,
  //     biayaPph23: item.biayaPph23,
  //     biayaPpn: item.biayaPpn,

  //     grandPajak: item.grandPajak,
  //     grandPajakTerutang: item.grandPajakTerutang,
  //     grandSubTotal: item.grandSubTotal,
  //     grandTotal: item.grandTotal,
  //     hargaSatuan: item.hargaSatuan,
  //     id: item.id,
  //     komponenBiayaKategori: item.komponenBiayaKategori,
  //     komponenBiayaNama: item.komponenBiayaNama,
  //     komponenBiayaSatuan: item.komponenBiayaSatuan,
  //     komponenBiayaSpesifikasi: item.komponenBiayaSpesifikasi,
  //     kuantitas: item.kuantitas,
  //     pajakPpn: item.pajakPpn,
  //     pemungutPajakPph: item.pemungutPajakPph,
  //     pemungutPajakPpn: item.pemungutPajakPpn,
  //     rencanaRincianKegiatanId: item.rencanaRincianKegiatanId,
  //   });
  // });
  // tst.map((item: any) => {
  //   xls.push({
  //     ID_RINCIAN_KEGIATAN: item.id,
  //     ID_KEGIATAN: item.rencanaKegiatan.id,
  //     Rencana_QTY: item.totalKuantitas,
  //     Rencana_harga: item.hargaSatuan,
  //     Rencana_Jumlah: item.jumlahTotal,
  //   });
  // });
  // console.log(tmpRealisasiBiaya);
  // tmpRealisasiBiaya
  //   .filter((item) => !lll.includes(item.rencanaRincianKegiatanId))
  //   .map((item: any) => {
  //     // let TotalQY = 0;
  //     // let HargaSatuan = 0;
  //     let TipeKas = 0;
  //     item.biayaSumberDanas.map((items) => {
  //       TipeKas = items.namaTipeKas;
  //     });
  //     item.biayaDetails.map((items) => {
  //       xls.push({
  //         ID_Rincian_Biaya: item.id,
  //         ID_RINCIAN_KEGIATAN: item.rencanaRincianKegiatanId,
  //         Realisasi_QTY: items.kuantitas,
  //         Realisasi_harga: items.hargaSatuan,
  //         Realisasi_Jumlah: items.grandTotal,
  //       });
  //     });
  //   });
  // console.log(xls);
  // console.log(tmpRealisasiBiaya);
  // tmpRealisasiBiaya
  //   .filter((item) => lll.includes(item.rencanaRincianKegiatanId))
  //   // .filter((item) =>
  //   //   item.biayaSumberDanas.some(
  //   //     (iitem) => iitem.kodeSumberDana === "apbn_bos_semester_2",
  //   //   ),
  //   // )
  //   .filter((item) => item.status === "SELESAI")
  //   // .sort(
  //   //   (a: any, b: any) =>
  //   //     new Date(a.tanggalRealisasi).getTime() -
  //   //     new Date(b.tanggalRealisasi).getTime(),
  //   // )
  //   .map((item) => {
  //     xls.push({
  //       noNotaFormat: item.noNotaFormat,
  //       tanggalNota: moment(item.tanggalNota).format("DD MM YYYY"),
  //       tanggalRealisasi: moment(item.tanggalRealisasi).format("DD MM YYYY"),
  //       ID_Rincian_Biaya: item.id,
  //       ID_RINCIAN_KEGIATAN: item.rencanaRincianKegiatanId,
  //       keterangan: item.keterangan,
  //       status: item.status,
  //       jenisPajak: item.jenisPajak,
  //       grandPajak: item.grandPajak,
  //       grandPajakTerutang: item.grandPajakTerutang,
  //       grandPajakTerutangPph21: item.grandPajakTerutangPph21,
  //       grandPajakTerutangPph22: item.grandPajakTerutangPph22,
  //       grandPajakTerutangPph23: item.grandPajakTerutangPph23,
  //       grandPajakTerutangPpn: item.grandPajakTerutangPpn,
  //       grandSubTotal: item.grandSubTotal,
  //       grandTotal: item.grandTotal,
  //     });
  //   });

  // console.log(xls);
  // ExportToExcel(xls, "issu2");

  return (
    <>
      <BreadCrumb
        routes={itemBreadcrumb}
        title="Realisasi Pengeluaran Kegiatan"
      />
      <FilterPengeluaranKegiatan
        form={form}
        tmpListMonth={listMonth}
        tmpSumberDana={listSumber}
        handleChangeValues={handleChangeValues}
      />
      <div className="m-5 p-5 bg-white shadow-md rounded">
        <div className="mb-4 flex justify-end">
          <div className="mr-4">
            <Space size={[8, 8]} wrap>
              <ButtonLog
                onClick={() =>
                  route.push({
                    pathname: "logs",
                  })
                }
              />
            </Space>
          </div>
          <InputSearch
            className="w-3/4"
            onChange={(e: any) => setSearch(e.currentTarget.value)}
          />
        </div>
        <div className="w-full">
          <Table
            onHeaderRow={onHeaderRow} // Apply onHeaderRow for header row events
            onRow={onRow}
            rowKey={(record) => record.id}
            columns={columns}
            dataSource={dataTable}
            scroll={{ x: "1300" }}
            bordered
            pagination={{
              total: totalDataTable,
              position: ["bottomRight"],
              defaultPageSize: pageSize,
              defaultCurrent: page,
              showTotal: (total) => `Total ${total} items`,
              onChange(page, pageSize) {
                setPage(page);
                setPageSize(pageSize);
              },
            }}
          />
        </div>
      </div>
      <ModalListRincian
        openModal={openModal}
        data={dataEdit}
        handleClose={() => setOpenModal(false)}
        title={title}
      />
    </>
  );
};

export default PengeluaranKegiatan;
