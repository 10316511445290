/** @format */

import api from "../../../utils/api";
import { referenceService } from "../constant";

const basePath = `${referenceService}`;

/** Get All Reference Activated  */
export const getReferenceAll = async (ref: any, params: any): Promise<any> => {
  try {
    let response: any = await api.get<any>(`${basePath}/${ref}`, {
      params,
    });
    return response?.data?.return;
  } catch (error) {
    console.log(error);
  }
};

/** Get All Bank Activated  */
type activatedParams = {
  activated: number;
};
export const getBank = async (params: activatedParams) => {
  try {
    const response = await api.get<any>(`${basePath}/bank`, { params });
    return response?.data?.return;
  } catch (error) {
    console.log(error);
  }
};

/** Get All Tipe Penerima Activated  */

export const getTipePenerima = async (params: activatedParams) => {
  try {
    const response = await api.get<any>(`${basePath}/tipe-penerima`, {
      params,
    });
    return response?.data?.return;
  } catch (error) {
    console.log(error);
  }
};
