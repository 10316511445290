/** @format */

import React, { useState } from "react";
import { jsPDF } from "jspdf";
import { Modal, Button, Table, Alert, Tabs } from "antd";
import moment from "moment";
import "moment/locale/id";
import KemenAg from "../../assets/img/logo-kemenag.png";
import eRkam from "../../assets/img/logo-erkam.png";
import madrasahReform from "../../assets/img/madrasahReform.png";
import autoTable from "jspdf-autotable";
import { formatRupiah, getBulan, listAlpha } from "../../utils/helper";
// import notifAlert from "../../components/NotifAlert";
import buktiBos from "../../assets/img/backgroundBOS.png";
import {
  checkDataErkam,
  listDokumen,
  spesialBOSTahap2,
} from "../../utils/helper/helperBos";
import {
  getLaporanPertanggungJawaban,
  getLaporanPertanggungJawabanAxios,
} from "../../services/v2/reportservice";
import notifAlert from "../NotifAlert";
// import Barcode from "../../assets/img/barcode-kemenag.png";

type generateModal = {
  openModal: boolean;
  dataMadrasah: any;
  dataAction: any;
  dataBOS: any;
  dataLaporan: any;
  dataFile: any;
  action: any;
  onClose: () => void;
};

const ModalDokumenAction = (params: generateModal) => {
  const [pdfFile, setPdfFile] = useState<any>(null);
  const [dataLPJ, setDataLPJ] = useState<any>(null);
  const [tabActive, setTabActive] = useState<any>(null);
  const dataMadrasah = params?.dataMadrasah || null;
  const dataAction = params?.dataAction || null;
  const dataBos = params?.dataBOS || null;
  const dataLaporan = params?.dataLaporan || [];
  const dataFile = params?.dataFile || [];
  const checkDataMadrasah = spesialBOSTahap2().includes(dataMadrasah?.nsm);

  const showAlert = checkDataErkam(dataLaporan, checkDataMadrasah);
  let dataTableDokumen: any = listDokumen();

  let startMonth: number =
    (parseInt(params?.dataMadrasah?.tahap) === 1 && 7) || 1;
  let endMonth: any =
    (parseInt(params?.dataMadrasah?.tahap) === 2 &&
      parseInt(moment().format("MM")) - 1) ||
    12;
  // let startMonth: number =
  //   (parseInt(dataLPJ?.bulan) === 6 && 7) || 1;
  // let endMonth: any =
  //   (parseInt(dataLPJ?.bulan) === 12 &&
  //     parseInt(moment().format("MM")) - 1) ||
  //   12;
  let tmpListMonth: any = [];
  for (startMonth; startMonth <= endMonth; startMonth++) {
    tmpListMonth.push({
      label: getBulan(startMonth),
      // children: startMonth,
      key: `m${startMonth}`,
    });
  }
  // setListMonth(tmpListMonth);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [params]);

  React.useEffect(() => {
    downloadPdf();
    setTabActive(`m${endMonth}`);
    if (params.action === "docLpj1") {
      getDataLpj(dataMadrasah, endMonth);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataAction, dataFile, dataMadrasah, params]);

  const getDataLpj = async (tmpDataMadrasah: any, periode: any) => {
    if (tmpDataMadrasah?.tahap === 1 || tmpDataMadrasah?.checkDataMadrasah) {
      const tahunLpj: any = tmpDataMadrasah?.auth?.isTahun - 1;
      try {
        const res: any = await getLaporanPertanggungJawabanAxios(
          tahunLpj,
          tmpDataMadrasah?.auth,
          tmpDataMadrasah?.madrasahId,
          periode,
        );
        if (res) {
          setDataLPJ(res || null);
        } else {
          notifAlert({
            type: "error",
            description: `Madrasah belum menginputkan Laporan LPJ di bulan Juni ${tmpDataMadrasah?.auth?.isTahun}`,
          });
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      const payload: any = {
        tahun: tmpDataMadrasah?.auth?.isTahun,
        kodeSumberDana: "apbn_bos_semester_1",
        periode: periode,
        madrasahId: tmpDataMadrasah?.madrasahId,
      };

      try {
        const res = await getLaporanPertanggungJawaban(payload);
        setDataLPJ(res || null);
      } catch (error) {
        console.log(error);
        notifAlert({
          type: "error",
          description: `Madrasah belum menginputkan Laporan LPJ di bulan Juni ${tmpDataMadrasah?.auth?.isTahun}`,
        });
      }
    }
  };
  //   const handleDownload = (values: any) => {
  //     downloadPdf("download");
  //     notifAlert({
  //       type: "success",
  //       description: "Surat berhasil diunduh",
  //     });
  //   };

  const downloadPdf = (action?: any) => {
    const doc: any = new jsPDF({
      putOnlyUsedFonts: true,
      floatPrecision: 16,
    });
    var width = doc.internal.pageSize.getWidth();
    var height = doc.internal.pageSize.getHeight();
    let dataDokReguler: any = [
      {
        jenisMadrasah: "R",
        kodeDok: 1,
        statusValidasi: 1,
        tahap: dataAction?.tahap,
        penerimaBos: dataAction?.penerimaBos,
      },
      {
        jenisMadrasah: "R",
        kodeDok: 5,
        statusValidasi: 1,
        tahap: dataAction?.tahap,
        penerimaBos: dataAction?.penerimaBos,
      },
      ...dataFile,
    ];
    let dataDok: any =
      (dataAction?.jenisMadrasah === "R" &&
        [1, 2].includes(dataAction?.penerimaBos) &&
        dataDokReguler) ||
      dataFile;
    doc.addImage(buktiBos, "JPEG", 0, 0, width, height);
    var fline = 27;
    doc.setFontSize(14).setFont(undefined, "bold");
    doc.addImage(KemenAg, "JPEG", 30, 30, 24, 22);
    doc
      .text(120, 35, "KEMENTERIAN AGAMA REPUBLIK INDONESIA", {
        maxWidth: 130,
        align: "center",
      })
      .setFontSize(12)
      .setFont(undefined, "normal");
    doc
      .text(120, 40, "DIREKTORAT JENDRAL PENDIDIKAN ISLAM", {
        maxWidth: 130,
        align: "center",
      })
      .setFontSize(8);
    doc.text(120, 45, "Jalan Lapangan Banteng Barat Nomor 3-4 Jakarta 10710", {
      maxWidth: 130,
      align: "center",
    });
    doc.text(
      120,
      48,
      "Telepon (021) 3811523-3520951-3507479-3856817-3811654-3459273",
      {
        maxWidth: 130,
        align: "center",
      },
    );
    doc.text(120, 51, "Faksimili (021) 3859117 SITUS www.kemenag.go.id", {
      maxWidth: 130,
      align: "center",
    });
    doc.setLineWidth(1);
    doc.line(fline - 5, 56, 188, 56);
    doc.setFontSize(12).setFont(undefined, "bold");
    doc
      .text(
        25,
        70,
        `Tanda Bukti Upload Persyaratan Pencairan Dana BOS Tahap ${
          dataAction?.tahap || "-"
        }`,
        {
          maxWidth: 130,
          align: "left",
        },
      )
      .setFontSize(10)
      .setFont(undefined, "normal");
    doc.text(
      25,
      75,
      `Nomor : ${dataAction?.seqNo}/BOS/${moment(
        dataAction?.transferDate,
      ).format("MM")}/${moment(dataAction?.transferDate).format("YYYY")}`,
      {
        maxWidth: 130,
        align: "left",
      },
    );
    doc.setLineWidth(0.5);
    doc.rect(130, 75, 55, 7);
    doc.rect(130, 75, 55, 15).setFontSize(12);
    doc
      .text(157, 80, "Nominal", {
        maxWidth: 50,
        align: "center",
      })
      .setFont(undefined, "bold");
    doc
      .text(157, 87, `${formatRupiah(dataAction?.nilaiPencairan || 0)}`, {
        maxWidth: 50,
        align: "center",
      })
      .setFontSize(10)
      .setFont(undefined, "normal");
    doc.text(25, 95, "NSM", {
      maxWidth: 130,
      align: "left",
    });
    doc.text(65, 95, ":", {
      maxWidth: 130,
      align: "left",
    });
    doc.text(67, 95, `${dataAction?.nsmMadrasah || "-"}`, {
      maxWidth: 130,
      align: "left",
    });
    doc.text(25, 100, "NSM Lama", {
      maxWidth: 130,
      align: "left",
    });
    doc.text(65, 100, ":", {
      maxWidth: 130,
      align: "left",
    });
    doc.text(67, 100, "-", {
      maxWidth: 130,
      align: "left",
    });
    doc.text(25, 105, "Nama Madrasah", {
      maxWidth: 130,
      align: "left",
    });
    doc.text(65, 105, ":", {
      maxWidth: 130,
      align: "left",
    });
    doc.text(67, 105, `${dataAction?.namaMadrasah || "-"}`, {
      maxWidth: 130,
      align: "left",
    });
    doc.text(25, 110, "Nama Bank", {
      maxWidth: 130,
      align: "left",
    });
    doc.text(65, 110, ":", {
      maxWidth: 130,
      align: "left",
    });
    doc.text(67, 110, `${dataAction?.namaBank || "-"}`, {
      maxWidth: 130,
      align: "left",
    });
    doc.text(25, 115, "Pemilik Rekening BOS", {
      maxWidth: 130,
      align: "left",
    });
    doc.text(65, 115, ":", {
      maxWidth: 130,
      align: "left",
    });
    doc.text(67, 115, `${dataAction?.namaRekening || "-"}`, {
      maxWidth: 130,
      align: "left",
    });
    doc.text(25, 120, "Nomor Rekening BOS", {
      maxWidth: 130,
      align: "left",
    });
    doc.text(65, 120, ":", {
      maxWidth: 130,
      align: "left",
    });
    doc.text(67, 120, `${dataAction?.noRekening || "-"}`, {
      maxWidth: 130,
      align: "left",
    });
    doc.text(25, 125, "Alamat Madrasah", {
      maxWidth: 130,
      align: "left",
    });
    doc.text(65, 125, ":", {
      maxWidth: 130,
      align: "left",
    });
    doc.text(67, 125, `${dataAction?.alamat || "-"}`, {
      maxWidth: 130,
      align: "left",
    });
    doc.text(
      25,
      135,
      "Berikut adalah daftar berkas yang sudah diunggah oleh lembaga ke dalam aplikasi BOS Madrasah.",
      {
        maxWidth: 160,
        align: "justify",
      },
    );
    var headers: any = [
      { id: "no", dataKey: "no", padding: 0, header: "No" },
      {
        id: "nama",
        dataKey: "nama",
        padding: 0,
        header: "Nama Berkas Persyaratan",
      },
      {
        id: "tanggal",
        dataKey: "tanggal",
        padding: 0,
        header: "Tanggal Unggah",
      },
    ];
    autoTable(doc, {
      styles: { lineColor: 244, lineWidth: 0.1 },
      headStyles: {
        halign: "center",
        valign: "middle",
        fillColor: [0, 128, 0],
      }, // Cells in first column centered and green
      columnStyles: {
        0: { halign: "left", cellWidth: 10 },
        1: { halign: "left", cellWidth: 120 },
        2: { halign: "left", cellWidth: 35 },
      },
      startY: 140,
      margin: { top: 10, left: 25, right: 30 },
      columns: headers,
      body: dataDok
        .filter(
          (e) =>
            e.statusValidasi.toString() === "1" &&
            e.tahap === dataAction?.tahap &&
            e.penerimaBos?.toString() === dataAction?.penerimaBos?.toString(),
        )
        .map((e, i) => {
          const checkMadrasah = spesialBOSTahap2().includes(dataBos?.nsm);
          let namaFile: any =
            dataTableDokumen.find(
              (item) =>
                item.kode === e.kodeDok && item.tahap === dataAction?.tahap,
            )?.nama || "";
          if (e.kodeDok === 1 && checkMadrasah) {
            namaFile = "Laporan Pertanggung Jawaban (LPJ) Desember 2023";
          }

          return {
            no: i + 1,
            nama: namaFile,
            tanggal: moment(e.created_at).format("DD-MMM-YYYY"),
          };
        }),
    });
    let lastLine: any = 0;
    if (dataAction?.tahap === 2 && dataAction?.batch === 1) {
      lastLine = doc.lastAutoTable.finalY + 4;
      doc.setDrawColor(255, 0, 0);
      doc.rect(25, lastLine, 165, 20).setFont(undefined, "bold");
      doc
        .text(30, lastLine + 6, `Keterangan Nominal Pencairan BOS : `, {
          maxWidth: 160,
          align: "left",
        })
        .setFont(undefined, "normal");
      doc.text(
        35,
        lastLine + 12,
        `1. Nominal Alokasi Bisa Dicairkan : ${formatRupiah(
          dataBos?.apbn_bos_semester2 - dataBos?.nilai_aa,
        )}`,
        {
          maxWidth: 160,
          align: "left",
        },
      );
      doc.text(
        35,
        lastLine + 17,
        `2. Nominal Alokasi AA : ${formatRupiah(dataBos?.nilai_aa)}`,
        {
          maxWidth: 160,
          align: "left",
        },
      );
      lastLine = lastLine + 25;
    } else {
      lastLine = doc.lastAutoTable.finalY + 15;
    }

    doc.text(
      25,
      lastLine,
      `Persyaratan untuk mencairkan Dana BOS ${moment().format("YYYY")}`,
      {
        maxWidth: 160,
        align: "left",
      },
    );
    doc.text(25, lastLine + 5, `1. KTP Kamad dan Bendahara`, {
      maxWidth: 160,
      align: "left",
    });
    doc.text(25, lastLine + 10, `2. NPWP Madrasah / Yayasan`, {
      maxWidth: 160,
      align: "left",
    });
    doc.text(25, lastLine + 15, `3. Buku tabungan`, {
      maxWidth: 160,
      align: "left",
    });
    doc.text(25, lastLine + 20, `4. Print bukti`, {
      maxWidth: 160,
      align: "left",
    });
    doc.text(
      125,
      lastLine + 40,
      `Tanggal Download ${moment().format("DD MMMM YYYY")}`,
      {
        maxWidth: 160,
        align: "justify",
      },
    );

    doc.addImage(eRkam, "JPEG", fline + 53, 260, 12, 12);
    doc.addImage(madrasahReform, "JPEG", fline + 0, 260, 50, 12);

    const blobPDF = doc.output("bloburl");
    if (action === "download") {
      doc.save("Bukti Pencairan BOS");
    }

    setPdfFile(blobPDF);
  };

  const pdfLPJ = (dataMadrasah: any) => {
    const doc: any = new jsPDF({
      putOnlyUsedFonts: true,
      orientation: "landscape",
      floatPrecision: 16,
    });
    let tableTmp: any = [];
    // let tmpNamaSumberDana: any =
    //   sumberDana.find((item) => item.kode === listData?.kodeSumberDana) || null;
    dataMadrasah?.items?.length &&
      // eslint-disable-next-line array-callback-return
      dataMadrasah?.items?.map((item, i) => {
        let dataTmp = {
          id: listAlpha(i),
          nama: item.nama,
          saldoAwal: !isNaN(item.saldoAwal) ? formatRupiah(item.saldoAwal) : 0,
          penambahan: !isNaN(item.totalPenambahan)
            ? formatRupiah(item.totalPenambahan)
            : 0,
          pengurangan: !isNaN(item.totalPengurangan)
            ? formatRupiah(item.totalPengurangan)
            : 0,
          saldoAkhir: formatRupiah(item.saldoAkhir),
        };
        tableTmp.push(dataTmp);
        item.items.length &&
          // eslint-disable-next-line array-callback-return
          item.items.map((e, x) => {
            let itemTmp = {
              id: "",
              nama: `${x + 1}. ${e.nama}`,
              saldoAwal: formatRupiah(e.saldoAwal),
              penambahan: formatRupiah(e.penambahan),
              pengurangan: formatRupiah(e.pengurangan),
              saldoAkhir: formatRupiah(e.saldoAkhir),
            };
            tableTmp.push(itemTmp);
          });
      });

    var fline = 10;
    doc.setFont("times", "bold");
    doc.setFontSize(14);
    doc.rect(10, 6, 275, 22);
    doc.rect(60, 6, 177, 22);

    doc.text(
      `LAPORAN PERTANGGUNGJAWABAN BENDAHARA MADRASAH ${
        dataMadrasah?.status === "n" ? "NEGERI" : "SWASTA"
      }`,
      148,
      12,
      {
        align: "center",
      },
    );
    doc.setFontSize(12);
    doc.text("Form LPJ Pengeluaran", 35, 12, {
      align: "center",
      maxWidth: 30,
    });
    doc.text(`Tahun Anggaran ${dataMadrasah?.tahun}`, 260, 12, {
      align: "center",
      maxWidth: 40,
    });

    doc.text(
      `Bulan :${
        dataMadrasah?.bulan === 12
          ? "Desember"
          : moment(`${dataMadrasah?.tahun}-${dataMadrasah?.bulan}`).format(
              "MMMM",
            )
      } ${dataMadrasah?.tahun}`,
      150,
      18,
      { align: "center" },
    );
    doc.text("Nama Madrasah : " + dataMadrasah?.satuanKerja, 150, 24, {
      align: "center",
    });
    doc.setFont("times", "normal");
    doc.setFontSize(10);
    doc.text("Nama Madrasah", fline, 35);
    doc.text(": " + (dataMadrasah?.satuanKerja || "-"), fline + 40, 35);
    doc.text("NSM", fline, 40);
    doc.text(": " + (dataMadrasah?.nsm || "-"), fline + 40, 40);
    doc.text("Alamat dan Telp", fline, 45);
    doc.text(`: ${dataMadrasah?.alamatDanNoTelp}`, fline + 40, 45);
    doc.text("Tahun Anggaran", fline, 50);
    doc.text(": " + (dataMadrasah?.tahun || "-"), fline + 40, 50);
    doc.text("Sumber Dana", fline, 55);
    doc.text(
      ": " +
        ((dataMadrasah?.kodeSumberDana === "apbn_bos_semester_1" &&
          "APBN - BOS Tahap 1") ||
          "APBN - BOS Tahap 2"),
      fline + 40,
      55,
    );

    let lastHeader = 55;
    doc.text("I.", fline, lastHeader + 10);
    doc.text(
      "Keadaan Pembukuan bulan Pelaporan dengan saldo akhir BKU sebesar dan bukti terakhir Nomor:",
      fline + 5,
      lastHeader + 10,
    );
    var headers: any = [
      { id: "id", dataKey: "id", padding: 0, header: "No", width: 22 },
      {
        id: "nama",
        dataKey: "nama",
        padding: 0,
        header: "Jenis Buku Pembantu",
        width: 80,
      },
      {
        id: "saldoAwal",
        dataKey: "saldoAwal",
        padding: 0,
        header: "Saldo Awal",
        width: 40,
      },
      {
        id: "penambahan",
        dataKey: "penambahan",
        padding: 0,
        header: "Penambahan",
        width: 40,
      },
      {
        id: "pengurangan",
        dataKey: "pengurangan",
        padding: 0,
        header: "Pengurangan",
        width: 40,
      },
      {
        id: "saldoAkhir",
        dataKey: "saldoAkhir",
        padding: 0,
        header: "Saldo Akhir",
        width: 39,
      },
    ];
    let las: any = [];
    autoTable(doc, {
      styles: { lineColor: 244, lineWidth: 0.1 },
      headStyles: {
        halign: "center",
        valign: "middle",
        fillColor: [0, 128, 0],
      }, // Cells in first column centered and green
      columnStyles: {
        0: { halign: "center", cellWidth: 15 },
        1: { halign: "left", cellWidth: 95 },
        2: { halign: "right", cellWidth: 40 },
        3: { halign: "right", cellWidth: 40 },
        4: { halign: "right", cellWidth: 40 },
        5: { halign: "right", cellWidth: 40 },
      },
      startY: lastHeader + 15,
      margin: { top: 10, left: 15, right: 10 },
      columns: headers,
      body: tableTmp,
      didDrawPage: (d) => las.push(d.cursor),
    });
    let lastLine: any = 7 + (las.length !== 0 ? las[0].y : 0);
    const footerText = [
      {
        text: "II.",
        line: fline,
        space: 0,
        bottom: 5,
      },
      {
        text: "Keadaan Kas pada akhir Bulan",
        line: fline,
        space: 5,
        bottom: 0,
      },

      {
        text: "1. Uang Tunai di Brankas",
        line: fline,
        space: 5,
        bottom: 5,
      },
      {
        text: `${
          formatRupiah(dataMadrasah?.kasAkhirbulan?.saldoKasTunai) || "-"
        }`,
        line: fline,
        space: 200,
        bottom: 0,
      },
      {
        text: "2. Uang di rekening bank (terlampir Daftar Rincin Kas di Rekening)",
        line: fline,
        space: 5,
        bottom: 5,
      },
      {
        text: `${
          formatRupiah(dataMadrasah?.kasAkhirbulan?.saldoRekeningBank) || "-"
        }`,
        line: fline,
        space: 200,
        bottom: 0,
      },
      {
        text: "3. Jumlah Kas",
        line: fline,
        space: 5,
        bottom: 5,
      },
      {
        text: `${formatRupiah(dataMadrasah?.kasAkhirbulan?.jumlahKas) || "-"}`,
        line: fline,
        space: 200,
        bottom: 0,
      },
      {
        text: "III.",
        line: fline,
        space: 0,
        bottom: 5,
      },
      {
        text: "Selisih Kas (Saldo Akhir A atau B - 3) :",
        line: fline,
        space: 5,
        bottom: 0,
      },
      {
        text: `${formatRupiah(dataMadrasah?.selisihKas?.selisih) || "-"}`,
        line: fline,
        space: 200,
        bottom: 0,
      },
      {
        text: "IV.",
        line: fline,
        space: 0,
        bottom: 5,
      },
      {
        text: "Penjelasan Selisih Kas dan/atau selisih pembukuan (apabila ada) :",
        line: fline,
        space: 5,
        bottom: 0,
      },
      {
        text: `${dataMadrasah?.penjelasan || "-"}`,
        line: fline,
        space: 5,
        bottom: 5,
      },
    ];
    let tmpBottom = lastLine;
    // eslint-disable-next-line array-callback-return
    footerText.map((item) => {
      tmpBottom += item.bottom;
      if (tmpBottom > 195) {
        doc.addPage();
        tmpBottom = 15;
      }
      doc.text(item.text, fline + item.space, tmpBottom);
    });
    const blobPDF = doc.output("bloburl");
    return blobPDF;
  };

  /**
   * Data untuk tabel children pertama
   */
  let columns: any = [
    {
      title: "No Urut",
      key: "noUrut",
      width: 300,
      render: (_, k, i) => i + 1,
    },
    {
      title: "Sumber Dana",
      key: "namaSumberDana",
      dataIndex: "namaSumberDana",
      width: 300,
    },
    {
      title: "Rencana Pendapatan (Rupiah)",
      key: "rencanaPendapatan",
      dataIndex: "rencanaPendapatan",
      width: 300,
      render: (rencanaPendapatan) => (
        <div className="text-right">{formatRupiah(rencanaPendapatan || 0)}</div>
      ),
    },
    {
      title: "Rencana Belanja (Rupiah)",
      key: "rencanaBelanja",
      dataIndex: "rencanaBelanja",
      width: 300,
      render: (rencanaBelanja) => (
        <div className="text-right">{formatRupiah(rencanaBelanja || 0)}</div>
      ),
    },
    {
      title: "Tahap 1",
      key: "jumlahTotal",
      align: "center",
      width: 200,
      render: (record) => <div className="text-right">{formatRupiah(0)}</div>,
    },
    {
      title: "Tahap 2",
      key: "jumlahTotal",
      dataIndex: "jumlahTotal",
      align: "center",
      width: 200,
      render: (record) => <div className="text-right">{formatRupiah(0)}</div>,
    },
  ];

  dataMadrasah?.status === "n" && columns.splice(4, 2);

  const expandedRowDetail = (data: any) => {
    let dataTableKegiatan: any = data.rencanaKegiatanList.length
      ? data.rencanaKegiatanList
      : [];
    const columnsDetail: any = [
      {
        title: "No Urut",
        key: "no",
        width: 50,
        render: (_, record, i: number) => i + 1,
      },
      {
        title: "Kegiatan",
        key: "namaKegiatan",
        dataIndex: "namaKegiatan",
        width: 250,
      },
      {
        title: "Rencana Belanja (dalam Rp.)",
        key: "rencanaBelanja",
        dataIndex: "rencanaBelanja",
        align: "center",
        width: 180,
        render: (rencanaBelanja) => (
          <div className="text-right">{formatRupiah(rencanaBelanja)}</div>
        ),
      },
      {
        title: "Tahap 1",
        key: "tahap1",
        align: "center",
        width: 180,
        render: (record) => {
          let total: any =
            record?.kodeSumberDana !== "apbn_bos_semester_2"
              ? record?.rencanaBelanja
              : 0;
          return <div className="text-right">{formatRupiah(total)}</div>;
        },
      },
      {
        title: "Tahap 2",
        key: "tahap2",
        align: "center",
        width: 180,
        render: (record) => {
          let total: any =
            record?.kodeSumberDana === "apbn_bos_semester_2"
              ? record?.rencanaBelanja
              : 0;
          return <div className="text-right">{formatRupiah(total)}</div>;
        },
      },
    ];
    dataMadrasah?.status === "n" && columnsDetail.splice(3, 2);

    return (
      <Table
        columns={columnsDetail}
        dataSource={dataTableKegiatan}
        pagination={false}
        expandable={{
          expandedRowRender: (record) => expandedRowDetailSub(record),
        }}
        bordered
      />
    );
  };

  const expandedRowDetailSub = (dataSub: any) => {
    let dataTableSub: any = dataSub.rencanaSubKegiatanList.length
      ? dataSub.rencanaSubKegiatanList
      : [];
    const columnsDetailSub: any = [
      {
        title: "No Urut",
        key: "no",
        width: 80,
        render: (_, record, i: number) => i + 1,
      },
      {
        title: "Sub Kegiatan",
        key: "namaSubKegiatan",
        dataIndex: "namaSubKegiatan",
        width: 250,
      },
      {
        title: "Rencana Belanja (dalam Rp.)",
        key: "rencanaBelanja",
        dataIndex: "rencanaBelanja",
        align: "center",
        width: 180,
        render: (rencanaBelanja) => (
          <div className="text-right">{formatRupiah(rencanaBelanja)}</div>
        ),
      },
      {
        title: "Tahap 1",
        key: "tahap1",
        align: "center",
        width: 180,
        render: (record) => {
          let total: any =
            record?.kodeSumberDana !== "apbn_bos_semester_2"
              ? record?.rencanaBelanja
              : 0;
          return <div className="text-right">{formatRupiah(total)}</div>;
        },
      },
      {
        title: "Tahap 2",
        key: "tahap2",
        align: "center",
        width: 180,
        render: (record) => {
          let total: any =
            record?.kodeSumberDana === "apbn_bos_semester_2"
              ? record?.rencanaBelanja
              : 0;
          return <div className="text-right">{formatRupiah(total)}</div>;
        },
      },
    ];

    dataMadrasah?.status === "n" && columnsDetailSub.splice(3, 2);
    return (
      <Table
        columns={columnsDetailSub}
        dataSource={dataTableSub}
        pagination={false}
        expandable={{
          expandedRowRender: (record) => expandedRowDetailSubRincian(record),
        }}
        bordered
      />
    );
  };

  const expandedRowDetailSubRincian = (dataSubRincian: any) => {
    let dataTableSub: any = dataSubRincian.rencanaKegiatanList.length
      ? dataSubRincian.rencanaKegiatanList
      : [];
    const columnsDetailSubRincian: any = [
      {
        title: "No Urut",
        key: "no",
        width: 80,
        render: (_, record, i: number) => i + 1,
      },
      {
        title: "Kategori",
        key: "komponenBiayaKategori",
        dataIndex: "komponenBiayaKategori",
        width: 250,
      },
      {
        title: "Nama",
        key: "komponenBiayaNama",
        dataIndex: "komponenBiayaNama",
        width: 250,
      },
      {
        title: "Keterangan",
        key: "ketBiayaLain",
        dataIndex: "ketBiayaLain",
        width: 250,
      },
      {
        title: "Harga Satuan",
        key: "hargaSatuan",
        dataIndex: "hargaSatuan",
        align: "center",
        width: 180,
        render: (hargaSatuan) => (
          <div className="text-right">{formatRupiah(hargaSatuan)}</div>
        ),
      },
      {
        title: "Jumlah",
        key: "jumlah",
        // dataIndex: "jumlah",
        align: "center",
        width: 100,
        render: (record) => `${record.jumlah} ${record.satuan}`,
      },
      {
        title: "Total Harga",
        key: "totalHarga",
        dataIndex: "totalHarga",
        align: "center",
        width: 180,
        render: (totalHarga) => (
          <div className="text-right">{formatRupiah(totalHarga)}</div>
        ),
      },
      {
        title: "Tahap 1",
        key: "tahap1",
        align: "center",
        width: 180,
        render: (record) => {
          let total: any =
            record?.kodeSumberDana !== "apbn_bos_semester_2"
              ? record?.totalHarga
              : 0;
          return <div className="text-right">{formatRupiah(total)}</div>;
        },
      },
      {
        title: "Tahap 2",
        key: "tahap2",
        align: "center",
        width: 180,
        render: (record) => {
          let total: any =
            record?.kodeSumberDana === "apbn_bos_semester_2"
              ? record?.totalHarga
              : 0;
          return <div className="text-right">{formatRupiah(total)}</div>;
        },
      },
    ];
    dataMadrasah?.status === "n" && columnsDetailSubRincian.splice(7, 2);
    return (
      <Table
        columns={columnsDetailSubRincian}
        dataSource={dataTableSub}
        pagination={false}
        bordered
      />
    );
  };

  return (
    <>
      <Modal
        open={params.openModal}
        width={1300}
        title={
          params.action === "docLpj1"
            ? "Laporan Pertanggung Jawaban"
            : "Dokumen Pencairan Dana BOS"
        }
        onCancel={params.onClose}
        footer={[<Button onClick={params.onClose}>Kembali</Button>]}>
        <div className="grid grid-cols-1 md:grid-cols-1 gap-4">
          <div>
            {(params.action === "docLpj1" && (
              <>
                <Tabs
                  activeKey={tabActive}
                  size="small"
                  onChange={(key) => {
                    setTabActive(key);
                    getDataLpj(dataMadrasah, key.replace(/m/g, ""));
                  }}
                  items={tmpListMonth}
                />
                <embed
                  src={pdfLPJ(dataLPJ)}
                  width="100%"
                  height="700px"></embed>
              </>
            )) ||
              (params.action === "docRKAM6" && (
                <>
                  {showAlert && (
                    <div className="mb-4">
                      <Alert
                        className="mb-4"
                        message={<span className="font-bold">Perhatian</span>}
                        description={
                          <>
                            Rencana belanja definitif belum sesuai dengan
                            rencana pendapatan.
                          </>
                        }
                        type="error"
                        showIcon
                      />
                    </div>
                  )}
                  <Table
                    columns={columns}
                    dataSource={dataLaporan}
                    expandable={{
                      expandedRowRender: (record) => expandedRowDetail(record),
                    }}
                    scroll={{ x: "1300" }}
                    bordered
                  />
                </>
              )) || (
                <>
                  <div className="p-2 mb-2">
                    <Alert
                      message={
                        <span className="font-bold">
                          Persyaratan untuk mencairkan Dana BOS{" "}
                          {moment().format("YYYY")}
                        </span>
                      }
                      description={
                        <>
                          <div>1. KTP Kamad dan Bendahara</div>
                          <div>2. NPWP Madrasah / Yayasan</div>
                          <div>3. Buku tabungan</div>
                          <div>4. Print bukti</div>
                        </>
                      }
                      type="info"
                      showIcon
                    />
                  </div>
                  <embed src={`${pdfFile}`} width="100%" height="700px"></embed>
                </>
              )}
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ModalDokumenAction;
