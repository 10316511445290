/** @format */

import React, { useState, useEffect } from "react";
import { BreadCrumb } from "../../../components";
import moment from "moment";
import "moment/locale/id";
import { Table, Space } from "antd";
import * as FileSaver from "file-saver";
import { jsPDF } from "jspdf";
import ExcelJS from "exceljs";
import autoTable from "jspdf-autotable";
import { useDispatch, useSelector } from "react-redux";

import notifAlert from "../../../components/NotifAlert";
import { getMadrasah } from "../../../services/v2/usermanservice/madrasahservices";
// import { getLaporanAPBM } from "../../../services/v2/realizationservice/laporanservices";
import FilterMadrasah from "../Component/FilterMadrasah";
import { ButtonDropdownExport } from "../../../components/Button";
import { formatRupiah } from "../../../utils/helper";
import { setStore } from "../../../redux/actions";
import { getLaporanAPBMPlanning } from "../../../services/v2/planningservice/laporanservices";

const Apbm = () => {
  const itemBreadcrumb = [
    { path: "/", breadcrumbName: "Home" },
    { path: "/", breadcrumbName: "Laporan" },
    { path: "/", breadcrumbName: "Laporan Rencana" },
    { path: "/", breadcrumbName: "APBM" },
  ];

  const auths = useSelector((state: any) => state.auth);
  const store = useSelector((state: any) => state.store);
  const profile = store?.madrasahProfile || null;
  const refKabkota = store.kabkota || [];
  const refProvinsi = store.provinsi || [];
  const refKecamatan = store.kecamantan || [];
  const dispatch = useDispatch();
  const tmpListMadrasah = store?.listMadrasah || [];
  const auth = auths?.data || null;
  const groupRole = auth?.group_role || "";

  const [tableData, setTableData] = useState<any>(null);
  const [tmpMadrasah, setTmpMadrasah] = useState<any>([]);
  const [dataMadrasah, setDataMadrasah] = useState<any>(null);
  // const [page, setPage] = useState(1);
  // const [pageSize, setPageSize] = useState(10);
  const [tmpFilter, setTmpFilter] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  let totalSisa = 0;

  // let tmpDataRencana = tableData?.rencanaAnggaranList || [];
  // let tmpDataRealisasi = tableData?.realisasiList || [];
  let listFilter: any = [];
  let listBelanja: any = [];
  let listSumberDana: any = [];

  tableData?.length &&
    // eslint-disable-next-line array-callback-return
    tableData.map((item) => {
      if (!listFilter.includes(item.kodeSumberDana)) {
        listSumberDana.push({
          kodeSumberDana: item.kodeSumberDana,
          anggaranPendapatan: item?.anggaranPendapatan || 0,
          namaSumberDana: item.namaSumberdana,
          realisasiPendapatan: item?.realisasiPendapatan || 0,
        });
        listFilter.push(item.kodeSumberDana);
      }

      listBelanja.push({
        kodeSnp: item.kodeSnp,
        namaSnp: item.namaSnp,
        kodeSumberDana: item.kodeSumberDana,
        anggaranBelanja: item?.anggaranBelanja || 0,
        realisasiBelanja: item?.realisasiBelanja || 0,
      });
    });

  // revisi
  // tmpDataRencana.length &&
  // eslint-disable-next-line array-callback-return
  // tmpDataRencana.map((item) => {
  //   if (listSumberDana.indexOf(item.kodeSumberdana) === -1) {
  //     let tmpRealisasiPendapatan =
  //       tmpDataRealisasi.find(
  //         (items) => items.kodeSumberdana === item.kodeSumberdana,
  //       ) || null;
  //     listSumberDana.push({
  //       kodeSumberDana: item.kodeSumberdana,
  //       anggaranPendapatan: item?.anggaranPendapatan || 0,
  //       namaSumberDana: item.namaSumberdana,
  //       realisasiPendapatan: tmpRealisasiPendapatan?.anggaranPendapatan || 0,
  //     });
  //   }
  //   let tmpRealisasi =
  //     tmpDataRealisasi.find(
  //       (items) =>
  //         items.kodeSnp === item.kodeSnp &&
  //         items.kodeSumberdana === item.kodeSumberdana,
  //     ) || null;
  //   item.kodeSnp &&
  //     listBelanja.push({
  //       kodeSnp: item.kodeSnp,
  //       namaSnp: item.namaSnp,
  //       kodeSumberDana: item.kodeSumberdana,
  //       anggaranBelanja: item?.anggaranBelanja || 0,
  //       realisasiBelanja: tmpRealisasi?.anggaranBelanja || 0,
  //     });
  // });

  let dataTable: any = [];
  listSumberDana.length &&
    // eslint-disable-next-line array-callback-return
    listSumberDana.map((item) => {
      let listTable: any = [
        {
          noUrut: "1",
          uraian: "SUMBER DANA",
          anggaranPendapatan: null,
          realisasiPendapatan: null,
          persenPendapatan: null,
          anggaranBelanja: null,
          realisasiBelanja: null,
          persenBelanja: null,
          totalSaldo: null,
          persenSaldo: null,
        },
        {
          noUrut: "A.",
          uraian: item.namaSumberDana || "-",
          anggaranPendapatan:
            (item.anggaranPendapatan &&
              formatRupiah(item.anggaranPendapatan)) ||
            0,
          realisasiPendapatan:
            (item.realisasiPendapatan &&
              formatRupiah(item.realisasiPendapatan)) ||
            0,
          persenPendapatan:
            Math.round(
              (item.realisasiPendapatan / item.anggaranPendapatan) * 100,
            ) + "%",
          anggaranBelanja: null,
          realisasiBelanja: null,
          persenBelanja: null,
          totalSaldo:
            (item.anggaranPendapatan &&
              formatRupiah(item.anggaranPendapatan)) ||
            0,
          persenSaldo: null,
        },
      ];
      let listPengeluaran: any = [
        {
          noUrut: "2",
          uraian: "BELANJA",
          anggaranPendapatan: null,
          realisasiPendapatan: null,
          persenPendapatan: null,
          anggaranBelanja: null,
          realisasiBelanja: null,
          persenBelanja: null,
          totalSaldo: null,
          persenSaldo: null,
        },
      ];
      let detailPengeluaran: any = [];
      let totalPengeluaran: any = 0;
      let tmpListBelanja =
        listBelanja.filter(
          (items) => items.kodeSumberDana === item.kodeSumberDana,
        ) || [];
      tmpListBelanja.length &&
        tmpListBelanja
          .sort((a, b) => a.kodeSnp - b.kodeSnp)
          // eslint-disable-next-line array-callback-return
          .map((items, i) => {
            let no = i + 1;
            totalPengeluaran += items.realisasiBelanja;
            let itemPengeluaran = {
              noUrut: `A.${no}`,
              uraian: items.namaSnp || null,
              anggaranPendapatan: null,
              realisasiPendapatan: null,
              persenPendapatan: null,
              anggaranBelanja:
                (items.anggaranBelanja &&
                  formatRupiah(items.anggaranBelanja)) ||
                0,
              realisasiBelanja:
                (items.realisasiBelanja &&
                  formatRupiah(items.realisasiBelanja)) ||
                0,
              persenBelanja: !isNaN(
                Math.round(
                  (items.realisasiBelanja / items.anggaranBelanja) * 100,
                ),
              )
                ? Math.round(
                    (items.realisasiBelanja / items.anggaranBelanja) * 100,
                  ) + "%"
                : "",
              totalSaldo: null,
              persenSaldo: null,
            };
            detailPengeluaran.push(itemPengeluaran);
          });
      listPengeluaran = [...listPengeluaran, ...detailPengeluaran];
      listTable =
        (detailPengeluaran.length && [...listTable, ...listPengeluaran]) ||
        listTable;
      let dataTotal: any = {
        nama: item.namaSumberDana || "-",
        totalPendapatan: item.realisasiPendapatan || 0,
        totalBelanja: totalPengeluaran,
        persenTotal: !isNaN(
          Math.round((totalPengeluaran / item.realisasiPendapatan) * 100),
        )
          ? Math.round((totalPengeluaran / item.realisasiPendapatan) * 100) +
            "%"
          : "",
        sisaSaldo: item.realisasiPendapatan - totalPengeluaran,
        listTable: listTable,
      };
      dataTable.push(dataTotal);
    });

  const getData = async () => {
    setLoading(true);
    if (groupRole !== "madrasah") {
      if (tmpListMadrasah.length === 0) {
        let filter: any = "";
        if (groupRole === "provinsi") {
          filter = `?kodeProvinsi=${auth?.kode_provinsi}`;
        }
        if (groupRole === "kabkota") {
          filter = `?kodeProvinsi=${auth?.kode_provinsi}&kode_kabkota=${auth?.kode_kabkota}`;
        }
        // const filterMadrasah =
        //   // (groupRole === "provinsi" &&
        //   //   `?activated=1&kodeProvinsi=${profile?.kode_provinsi}`) ||
        //   // (groupRole === "kabkota" &&
        //   //   `?activated=1&kodeProvinsi=${profile?.kode_provinsi}&kode_kabkota=${profile?.kode_kabkota}`) ||
        //   `?activated=1`;
        const madrasah = await getMadrasah(filter);
        const listMadrasah = madrasah || [];
        setTimeout(() => {
          dispatch(setStore({ listMadrasah }));
        }, 100);
        setTmpMadrasah(madrasah);
      } else {
        setTmpMadrasah(tmpListMadrasah);
      }
      const payload = {
        tahun: auth?.isTahun,
        madrasahId: tmpFilter?.madrasahId,
      };
      if (tmpFilter?.madrasahId) {
        const resMadrasah = tmpListMadrasah.find((item) =>
          item.id.includes(tmpFilter?.madrasahId),
        );
        setDataMadrasah(resMadrasah);
        const res = await getLaporanAPBMPlanning(payload);
        setTableData(res || null);
      }
    } else {
      setDataMadrasah(profile);
      const payload = {
        tahun: auth?.isTahun,
        madrasahId: auth?.madrasah?.id,
      };
      const res = await getLaporanAPBMPlanning(payload);
      setTableData(res || null);
    }
    setLoading(false);
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tmpFilter, auth?.isTahun]);

  const handleChangeValues = (values) => {
    setTmpFilter(values);
    // getData();
  };

  /**
   * Data untuk tabel children pertama
   */
  let columns: any = [
    {
      title: "No",
      key: "noUrut",
      align: "center",
      dataIndex: "noUrut",
      width: 80,
    },
    {
      title: "Uraian",
      key: "uraian",
      dataIndex: "uraian",
      align: "center",
      width: 300,
      render: (uraian) => <div className="text-left">{uraian}</div>,
    },
    {
      title: "Pendapatan",
      align: "center",
      key: "pendapatan",
      children: [
        {
          title: "Anggaran",
          key: "anggaranPendapatan",
          dataIndex: "anggaranPendapatan",
          width: 200,
          render: (anggaranPendapatan) => (
            <div className="text-right">{anggaranPendapatan}</div>
          ),
        },
        {
          title: "Realisasi",
          key: "realisasiPendapatan",
          children: [
            {
              title: "Rupiah",
              key: "realisasiPendapatan",
              dataIndex: "realisasiPendapatan",
              width: 200,
              render: (realisasiPendapatan) => (
                <div className="text-right">{realisasiPendapatan}</div>
              ),
            },
            {
              title: "%",
              key: "persenPendapatan",
              dataIndex: "persenPendapatan",
              width: 80,
            },
          ],
        },
      ],
    },
    {
      title: "Belanja",
      align: "center",
      key: "belanja",
      children: [
        {
          title: "Anggaran",
          key: "anggaranBelanja",
          dataIndex: "anggaranBelanja",
          width: 200,
          render: (anggaranBelanja) => (
            <div className="text-right">{anggaranBelanja}</div>
          ),
        },
        {
          title: "Realisasi",
          key: "realisasiBelanja",
          children: [
            {
              title: "Rupiah",
              key: "realisasiBelanja",
              dataIndex: "realisasiBelanja",
              width: 200,
              render: (realisasiBelanja) => (
                <div className="text-right">{realisasiBelanja}</div>
              ),
            },
            {
              title: "%",
              key: "persenBelanja",
              dataIndex: "persenBelanja",
              width: 80,
            },
          ],
        },
      ],
    },
    {
      title: "Saldo",
      align: "center",
      key: "saldo",
      children: [
        {
          title: "Rupiah",
          key: "totalSaldo",
          dataIndex: "totalSaldo",
          width: 200,
          render: (totalSaldo) => (
            <div className="text-right">{totalSaldo}</div>
          ),
        },
        {
          title: "%",
          key: "persenSaldo",
          dataIndex: "persenSaldo",
          width: 80,
        },
      ],
    },
  ];

  const handleExportExcel = async () => {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const wb = new ExcelJS.Workbook();
    const ws = wb.addWorksheet("data RKAKL");
    ws.mergeCells("A2:G2");

    const headerTitleCell = ws.getCell("A2");
    headerTitleCell.font = {
      size: 14,
      underline: true,
      bold: true,
    };

    headerTitleCell.value = `RINCIAN ANGGARAN BELANJA KELOMPOK RINCIAN OUTPUT (OUTPUT) KEGIATAN T.A. ${auths?.isTahun}`;
    const listHeaderDetail = [
      {
        title: "Kementerian Negara/Lembaga",
        value: ": Kementerian Agama",
      },
      {
        title: "Unit Eselon II/Satker/Madrasah",
        value: ": Kementerian Agama",
      },
      {
        title: "Kegiatan",
        value: ": Pengelolaan dan Pembinaan Pendidikan Madrasah",
      },
      {
        title: "Sasaran Kegiatan",
        value: ": -",
      },
      {
        title: "Indikator Kinerja Kegiatan",
        value: ": Siswa MI Penerima BOS",
      },
      {
        title: "Kelompok Rincian Output (RO) Kegiatan",
        value: ": Bantuan Pendidikan Dasar dan Menengah",
      },
      {
        title: "Indikator Rincian Output (RO) Kegiatan",
        value:
          ": Realisasi Penyediaan Dukungan Operasional Penyelenggaraan Pendidikan",
      },
      {
        title: "Volume",
        value: ": 1 (Satu)",
      },
      {
        title: "Satuan Ukur",
        value: ": Layanan",
      },
      {
        title: "Alokasi Dana",
        value: ": -",
      },
    ];

    // eslint-disable-next-line array-callback-return
    listHeaderDetail.map((item, i) => {
      const row = i + 4;
      const headerTitleBody = ws.getCell(`A${row}`);
      const headerValueBody = ws.getCell(`B${row}`);
      headerTitleBody.font = {
        size: 12,
        underline: true,
        bold: true,
      };
      headerTitleBody.value = item.title;
      headerValueBody.font = {
        size: 12,
        underline: true,
      };
      headerValueBody.value = item.value;
    });

    // ws.columns = [
    //   { header: "Kode", key: "kode" },
    //   { header: "Uraian", key: "uraian" },
    //   { header: "Volume Output", key: "volumeOutput" },
    //   { header: "Rincian Perhitungan (Jumlah)", key: "rincianPerhitungan" },
    //   { header: "Harga Satuan", key: "hargaSatuan" },
    //   { header: "Jumlah Total", key: "jumlahTotal" },
    // ];
    const rowLast = ws.rowCount;
    let dataList: any = [];

    dataTable.length &&
      // eslint-disable-next-line array-callback-return
      dataTable.map((item) => {
        dataList.push([
          item.kode,
          item.uraian,
          item.volumeOutput,
          item.rincianPerhitungan,
          item.hargaSatuan,
          item.jumlahTotal,
        ]);
      });

    ws.addTable({
      name: "MyTable",
      ref: `A${rowLast + 2}`,
      headerRow: true,
      // totalsRow: true,
      style: {
        showFirstColumn: true,
        // theme: "TableStyleDark3",
        showRowStripes: true,
      },
      columns: [
        { name: "Kode" },
        { name: "Uraian" },
        { name: "Volume Output" },
        { name: "Rincian Perhitungan (Jumlah)" },
        { name: "Harga Satuan" },
        { name: "Jumlah Total" },
      ],
      rows: dataList,
    });

    const excelBuffer = await wb.xlsx.writeBuffer();
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, "Laporan-RKAKL" + fileExtension);
  };

  function handleExportPdf() {
    const namaKecamatan =
      refKecamatan.find((item) => item.kode === dataMadrasah?.kode_kecamatan) ||
      null;
    const namaKabkota =
      refKabkota.find((item) => item.kode === dataMadrasah?.kode_kabkota) ||
      null;
    const namaProvinsi =
      refProvinsi.find((item) => item.kode === dataMadrasah?.kode_provinsi) ||
      null;

    const doc: any = new jsPDF({
      putOnlyUsedFonts: true,
      orientation: "landscape",
      floatPrecision: 16,
    });

    var fline = 10;
    doc.setFont("times", "bold");
    doc.setFontSize(14);

    doc.text(`RENCANA ANGGARAN PENDAPATAN DAN BELANJA MADRASAH`, 150, 12, {
      align: "center",
    });
    doc.text(`TAHUN ANGGARAN ${auths?.isTahun}`, 150, 18, { align: "center" });
    doc.setFont("times", "normal");
    doc.setFontSize(10);
    doc.text("Madrasah/PPS", fline, 30);
    doc.text(`: ${dataMadrasah?.nama}`, fline + 60, 30);
    doc.text("NSM", fline, 35);
    doc.text(`: ${dataMadrasah?.nsm}`, fline + 60, 35);
    doc.text("Kecamatan", fline, 40);
    doc.text(`: ${namaKecamatan?.nama || "-"}`, fline + 60, 40);
    doc.text("Kabupaten / Kota", fline, 45);
    doc.text(`: ${namaKabkota?.nama || "-"}`, fline + 60, 45);
    doc.text("Provinsi", fline, 50);
    doc.text(`: ${namaProvinsi?.nama || "-"}`, fline + 60, 50);

    let las: any = [];
    let sisaTotalPdf = 0;
    dataTable.length &&
      // eslint-disable-next-line array-callback-return
      dataTable.map((item, i) => {
        let tableData: any = [];
        let sisa = item.totalPendapatan - item.totalBelanja;
        sisaTotalPdf += sisa;
        const head = [
          [
            { content: "No", rowSpan: 3 },
            { content: "Keterangan", rowSpan: 3 },
            { content: "Pendapatan", colSpan: 3 },
            { content: "Belanja", colSpan: 3 },
            { content: "Saldo", colSpan: 2 },
          ],
          [
            { content: "Anggaran", rowSpan: 2 },
            { content: "Realisasi", colSpan: 2 },
            { content: "Anggaran", rowSpan: 2 },
            { content: "Realisasi", colSpan: 2 },
            { content: "Rupiah", rowSpan: 2 },
            { content: "%", rowSpan: 2 },
          ],
          ["Rupiah", "%", "Rupiah", "%"],
        ];
        const foot = [
          [
            { content: `Total Belanja`, colSpan: 2 },
            "",
            "",
            (item.totalPendapatan && formatRupiah(item.totalPendapatan)) || 0,
            "",
            (item.totalBelanja && formatRupiah(item.totalBelanja)) || 0,
            "",
            (item.totalBelanja && formatRupiah(item.totalBelanja)) || 0,
            item.persenTotal || "",
          ],
          [
            {
              content: `Sisa Saldo Sumber Dana ${item.nama || ""}`,
              colSpan: 8,
            },
            {
              content: `${formatRupiah(
                item.totalPendapatan - item.totalBelanja,
              )}`,
              colSpan: 2,
              halign: "right",
            },
          ],
        ];
        // eslint-disable-next-line array-callback-return
        item.listTable.length &&
          // eslint-disable-next-line array-callback-return
          item.listTable.map((items) => {
            tableData.push([
              items.noUrut,
              items.uraian,
              items.anggaranPendapatan,
              items.realisasiPendapatan,
              items.persenPendapatan,
              items.anggaranBelanja,
              items.realisasiBelanja,
              items.persenBelanja,
              items.totalSaldo,
              items.persenSaldo,
            ]);
          });
        if (i === 0) {
          autoTable(doc, {
            styles: { lineColor: 244, lineWidth: 0.1 },
            margin: { left: 10, right: 10 },
            headStyles: {
              halign: "center",
              valign: "middle",
              fillColor: [0, 128, 0],
            }, // Cells in first column centered and green
            footStyles: {
              halign: "center",
              valign: "middle",
              fillColor: [0, 128, 0],
            }, // Cells in first column centered and green
            startY: 55,
            head: head,
            body: tableData,
            foot: foot,
            showHead: "firstPage",
            showFoot: "lastPage",
            columnStyles: {
              0: { halign: "center" },
              1: { halign: "left" },
              2: { halign: "right" },
              3: { halign: "right" },
              4: { halign: "center" },
              5: { halign: "right" },
              6: { halign: "right" },
              7: { halign: "center" },
              8: { halign: "right" },
              9: { halign: "center" },
            },
            didDrawPage: (d) => las.push(d.cursor),
            // didDrawCell: (data) => {
            //   console.log(data.column.index);
            // },
          });
        } else {
          autoTable(doc, {
            styles: { lineColor: 244, lineWidth: 0.1 },
            margin: { left: 10, right: 10 },
            headStyles: {
              halign: "center",
              valign: "middle",
              fillColor: [0, 128, 0],
            }, // Cells in first column centered and green
            footStyles: {
              halign: "center",
              valign: "middle",
              fillColor: [0, 128, 0],
            }, // Cells in first column centered and green
            head: head,
            body: tableData,
            foot: foot,
            showHead: "firstPage",
            showFoot: "lastPage",
            columnStyles: {
              0: { halign: "center" },
              1: { halign: "left" },
              2: { halign: "right" },
              3: { halign: "right" },
              4: { halign: "center" },
              5: { halign: "right" },
              6: { halign: "right" },
              7: { halign: "center" },
              8: { halign: "right" },
              9: { halign: "center" },
            },
            didDrawPage: (d) => las.push(d.cursor),
            // didDrawCell: (data) => {
            //   console.log(data.column.index);
            // },
          });
        }
      });
    autoTable(doc, {
      styles: { lineColor: 244, lineWidth: 0.1 },
      margin: { left: 10, right: 10 },
      headStyles: {
        halign: "center",
        valign: "middle",
        fillColor: [0, 128, 0],
      }, // Cells in first column centered and green
      head: [["Total Sisa Saldo Sumber Dana", formatRupiah(sisaTotalPdf)]],
      showHead: "firstPage",
      didDrawPage: (d) => las.push(d.cursor),
    });

    let lastLine: any = doc.lastAutoTable.finalY + 5;
    const footerText = [
      {
        text: "Mengetahui",
        line: fline,
        space: 0,
        bottom: 10,
      },
      {
        text: moment().format("dddd, DD MMMM YYYY"),
        line: fline,
        space: 210,
        bottom: 0,
      },
      {
        text: "Kepala Madrasah",
        line: fline,
        space: 0,
        bottom: 5,
      },
      {
        text: "Bendahara Madrasah",
        line: fline,
        space: 210,
        bottom: 0,
      },
      {
        text:
          (dataMadrasah?.nama_kepala && `( ${dataMadrasah?.nama_kepala} )`) ||
          "(.................)",
        line: fline,
        space: 0,
        bottom: 30,
      },
      {
        text:
          (dataMadrasah?.nama_bendahara &&
            `( ${dataMadrasah?.nama_bendahara} )`) ||
          "(.................)",
        line: fline,
        space: 210,
        bottom: 0,
      },
    ];
    let tmpBottom = lastLine;
    // eslint-disable-next-line array-callback-return
    footerText.map((item) => {
      tmpBottom += item.bottom;
      if (tmpBottom > 195) {
        doc.addPage();
        tmpBottom = 15;
      }
      doc.text(item.text, fline + item.space, tmpBottom);
    });

    doc.output("dataurlnewwindow", { filename: "laporan-apbm" });
    notifAlert({
      type: "success",
      description: "Data berhasil di export",
    });
  }

  const handleExportFile = (type) => {
    if (dataTable.length > 0) {
      if (type === "pdf") {
        handleExportPdf();
      } else if (type === "excel") {
        handleExportExcel();
      }
    } else {
      notifAlert({
        type: "warning",
        description:
          "Harap pilih data yang ingin di export melalui fungsi filter!",
      });
    }
  };

  return (
    <>
      <BreadCrumb routes={itemBreadcrumb} title="APBM" />
      {groupRole !== "madrasah" && (
        <FilterMadrasah
          handleChangeValues={handleChangeValues}
          madrasah={tmpMadrasah}
        />
      )}
      <div className="m-5 p-5 bg-white shadow-md rounded">
        <div className="mb-4 flex justify-end">
          <Space size={[8, 8]} wrap>
            <ButtonDropdownExport handleExportFile={handleExportFile} />
          </Space>
        </div>
        <div className="w-full">
          {(dataTable.length &&
            dataTable.map((item, i) => {
              return (
                <Table
                  // sticky
                  // rowKey={(record) => record.id}
                  columns={columns}
                  dataSource={item.listTable}
                  // scroll={{ x: 1300 }}
                  showHeader={i > 0 ? false : true}
                  bordered
                  loading={loading}
                  summary={
                    () => {
                      let sisa = item.totalPendapatan - item.totalBelanja;
                      totalSisa += sisa;
                      return (
                        <Table.Summary fixed="bottom">
                          <Table.Summary.Row
                            style={{
                              backgroundColor: `green`,
                              color: `#ffffff`,
                            }}>
                            <Table.Summary.Cell
                              index={0}
                              align="center"
                              colSpan={2}>
                              <strong>Total Belanja</strong>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell
                              index={4}
                              align="right"></Table.Summary.Cell>
                            <Table.Summary.Cell index={5} align="right">
                              {(item.totalPendapatan &&
                                formatRupiah(item.totalPendapatan)) ||
                                0}
                            </Table.Summary.Cell>
                            <Table.Summary.Cell
                              index={6}
                              align="center"></Table.Summary.Cell>
                            <Table.Summary.Cell
                              index={7}
                              align="center"></Table.Summary.Cell>
                            <Table.Summary.Cell index={8} align="right">
                              {" "}
                              {(item.totalBelanja &&
                                formatRupiah(item.totalBelanja)) ||
                                0}
                            </Table.Summary.Cell>
                            <Table.Summary.Cell
                              index={9}
                              align="center"></Table.Summary.Cell>
                            <Table.Summary.Cell index={10} align="right">
                              {" "}
                              {(item.totalBelanja &&
                                formatRupiah(item.totalBelanja)) ||
                                0}
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={11} align="center">
                              {item.persenTotal}
                            </Table.Summary.Cell>
                          </Table.Summary.Row>
                          <Table.Summary.Row
                            style={{
                              backgroundColor: `green`,
                              color: `#ffffff`,
                            }}>
                            <Table.Summary.Cell
                              index={0}
                              align="center"
                              colSpan={8}>
                              <strong>
                                Sisa Saldo Sumber Dana {item.nama || ""}
                              </strong>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell
                              index={4}
                              align="right"
                              colSpan={2}>
                              {formatRupiah(
                                item.totalPendapatan - item.totalBelanja,
                              )}
                            </Table.Summary.Cell>
                          </Table.Summary.Row>
                          {i + 1 === dataTable.length && (
                            <>
                              <Table.Summary.Row
                                style={{
                                  backgroundColor: `green`,
                                  color: `#ffffff`,
                                }}>
                                <Table.Summary.Cell
                                  index={0}
                                  align="center"
                                  colSpan={8}>
                                  <strong>Total Sisa Saldo Sumber Dana</strong>
                                </Table.Summary.Cell>
                                <Table.Summary.Cell
                                  index={4}
                                  align="right"
                                  colSpan={2}>
                                  {formatRupiah(totalSisa)}
                                </Table.Summary.Cell>
                              </Table.Summary.Row>
                            </>
                          )}
                        </Table.Summary>
                      );
                    }
                    // )
                  }
                  pagination={false}
                />
              );
            })) || (
            <Table
              // sticky
              // rowKey={(record) => record.id}
              columns={columns}
              dataSource={[]}
              // scroll={{ x: 1300 }}
              loading={loading}
              showHeader={true}
              bordered
              summary={
                () => (
                  <Table.Summary fixed="bottom">
                    <Table.Summary.Row
                      style={{
                        backgroundColor: `green`,
                        color: `#ffffff`,
                      }}>
                      <Table.Summary.Cell index={0} align="center" colSpan={2}>
                        <strong>Total Belanja</strong>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell
                        index={4}
                        align="center"></Table.Summary.Cell>
                      <Table.Summary.Cell
                        index={5}
                        align="center"></Table.Summary.Cell>
                      <Table.Summary.Cell
                        index={6}
                        align="center"></Table.Summary.Cell>
                      <Table.Summary.Cell
                        index={7}
                        align="center"></Table.Summary.Cell>
                      <Table.Summary.Cell
                        index={8}
                        align="center"></Table.Summary.Cell>
                      <Table.Summary.Cell
                        index={9}
                        align="center"></Table.Summary.Cell>
                      <Table.Summary.Cell
                        index={10}
                        align="center"></Table.Summary.Cell>
                      <Table.Summary.Cell
                        index={11}
                        align="center"></Table.Summary.Cell>
                    </Table.Summary.Row>
                    <Table.Summary.Row
                      style={{
                        backgroundColor: `green`,
                        color: `#ffffff`,
                      }}>
                      <Table.Summary.Cell index={0} align="center" colSpan={8}>
                        <strong>Sisa Saldo Sumber Dana</strong>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={10} align="center" colSpan={2}>
                        0
                      </Table.Summary.Cell>
                    </Table.Summary.Row>
                  </Table.Summary>
                )
                // )
              }
              pagination={false}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default Apbm;
