/** @format */

import { Statistic } from "antd";
import { countPersentase } from "../../utils/helper";

export const DokumenStatistic = (listData: any) => {
  let totalPenyaluran: any = 0;
  let totalPencairanBank: any = 0;
  let totalPengajuan: any = 0;
  let totalBelumDicair: any = 0;
  let grandTotal: any = 0;

  listData?.listData?.length &&
    // eslint-disable-next-line array-callback-return
    listData?.listData?.map((item: any) => {
      totalPenyaluran += item?.total_anggaran_verifikasi;
      totalPencairanBank += item?.total_lembaga_pencairan_bank;
      totalPengajuan += item?.total_anggaran_pengajuan;
      totalBelumDicair += item?.total_anggaran_penyaluran;
    });
  grandTotal =
    totalPenyaluran + totalPencairanBank + totalPengajuan + totalBelumDicair;
  return (
    <div className="grid grid-cols-3 md:flex-row p-5  gap-4">
      <div className="flex-1 bg-white shadow-md p-4">
        <Statistic title="Verifikasi Anggaran" value={totalPenyaluran} />
      </div>
      <div className="flex-1 bg-green shadow-md p-4">
        <Statistic title="Pengajuan Pencairan" value={totalPengajuan} />
      </div>
      <div className="flex-1 bg-gray shadow-md p-4">
        <Statistic
          title="Persentasi"
          value={countPersentase(totalPengajuan, grandTotal)}
        />
      </div>
      <div className="flex-1 bg-white shadow-md p-4">
        <Statistic title="Belum Dicairkan" value={totalBelumDicair} />
      </div>
      <div className="flex-1 bg-green shadow-md p-4">
        <Statistic title="Pencairan Bank" value={totalPencairanBank} />
      </div>
      <div className="flex-1 bg-gray shadow-md p-4">
        <Statistic
          title="Persentasi"
          value={countPersentase(totalBelumDicair, grandTotal)}
        />
      </div>
    </div>
  );
};
