/** @format */
import { BosActions } from "../actionsName";

/** Set Bos */
export const setBos = (data) => ({
  type: BosActions.SET_BOS,
  data: data,
});

/** Delete Bos */
export const setClearBos = () => ({
  type: BosActions.CLEAR_BOS,
});
