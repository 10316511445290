/** @format */

import { Modal, Button, Table, Tag, Space } from "antd";
import moment from "moment";
import "moment/locale/id";
import { ButtonTableDelete } from "../Button";

type generateModal = {
  openModalDataDouble: boolean;
  loading: boolean;
  fileDoubleDok: any;
  onPriview: (e) => void;
  onDeleteFile: (e) => void;
  onClose: () => void;
};
const ModalDoubleDokumenBos = ({
  openModalDataDouble,
  loading,
  fileDoubleDok,
  onPriview,
  onDeleteFile,
  onClose,
}: generateModal) => {
  let columnsDokumenFile: any = [
    {
      title: "Nama File",
      dataIndex: "namaDok",
      key: "namaDok",
    },
    {
      title: "Status",
      dataIndex: "statusValidasi",
      key: "statusValidasi",
      render: (statusValidasi) => (
        <Tag
          color={
            (statusValidasi.toString() === "2" && "red") ||
            (statusValidasi.toString() === "1" && "#008000") ||
            "#ffca27"
          }>
          {(statusValidasi.toString() === "2" && "DiTolak") ||
            (statusValidasi.toString() === "1" && "Disetujui") ||
            "Belum Disetujui"}
        </Tag>
      ),
    },
    {
      title: "Tanggal Upload",
      dataIndex: "created_at",
      key: "created_at",
      render: (created_at) => moment(created_at).format("DD MMMM YYYY HH:mm"),
    },
    {
      title: "Aksi",
      key: "aksi",
      render: (record) => {
        return (
          <Space>
            <Button onClick={() => onPriview(record?.namaFile)}>
              Lihat Dokumen
            </Button>
            <ButtonTableDelete onClick={() => onDeleteFile(record)} />
          </Space>
        );
      },
    },
  ];

  return (
    <Modal
      open={openModalDataDouble}
      width={1200}
      title={`Lihat Dokumen Double`}
      onCancel={onClose}
      footer={[<Button onClick={onClose}>Kembali</Button>]}>
      <Table
        loading={loading}
        columns={columnsDokumenFile}
        dataSource={fileDoubleDok}
        bordered
        pagination={false}
      />
    </Modal>
  );
};
export default ModalDoubleDokumenBos;
