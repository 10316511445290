/** @format */

import React from "react";
import { Modal, Table, Descriptions } from "antd";
import moment from "moment";
import "moment/locale/id";
import { formatCurr } from "../../../../utils/helper";
import { exportDataPengajuan } from "./ExportDataPengajuan";

type FormActionType = {
  openModal: boolean;
  title: string;
  data: any;
  tahun: any;
  store: any;
  handleClose: () => void;
};

const ModalPriviewListPencairan = ({
  openModal,
  title,
  data,
  tahun,
  store,
  handleClose,
}: FormActionType) => {
  const dataTable = data?.list_verifikasi || [];
  let columnsDokumen: any = [
    {
      title: "NSM",
      key: "nsm",
      dataIndex: "nsm",
    },
    {
      title: "Madrasah",
      dataIndex: "nama_madrasah",
      key: "nama_madrasah",
    },
    {
      title: "Jenjang",
      dataIndex: "jenjang",
      key: "jenjang",
      render: (jenjang) => <div className="uppercase">{jenjang}</div>,
    },
    {
      title: "Provinsi",
      dataIndex: "provinsi",
      key: "provinsi",
    },
    {
      title: "Kabkota",
      dataIndex: "kabkota",
      key: "kabkota",
    },
    {
      title: "Alokasi Anggaran",
      key: "Nominal",
      children: [
        {
          title: "Tahap 1",
          dataIndex: "tahap1",
          key: "tahap1",
          render: (tahap1) => (
            <div className="text-right">{formatCurr(tahap1)}</div>
          ),
        },
        {
          title: "Tahap 2",
          key: "tahap2",
          dataIndex: "tahap2",
          render: (tahap2) => (
            <div className="text-right">{formatCurr(tahap2)}</div>
          ),
        },
        {
          title: "Total",
          key: "total",
          dataIndex: "total",
          render: (total) => (
            <div className="text-right">{formatCurr(total)}</div>
          ),
        },
      ],
    },
    {
      title: "Tahapan",
      key: "tahapan",
      dataIndex: "tahapan",
      render: (tahapan) => `Tahap ${tahapan}`,
    },
    {
      title: "Tanggal Verifikasi",
      key: "tanggal_verifikasi",
      dataIndex: "tanggal_verifikasi",
      render: (tanggal_verifikasi) =>
        moment(tanggal_verifikasi).format("dddd,DD MMM YYYY HH:mm:ss"),
    },
    {
      title: "Rekening Madrasah",
      key: "rekening",
      children: [
        {
          title: "Nama BANK",
          dataIndex: "nama_bank",
          key: "nama_bank",
        },
        {
          title: "No Rekening",
          key: "nomor_rekening",
          dataIndex: "nomor_rekening",
        },
        {
          title: "Nama Pemilik",
          key: "nama_pemilik_rekening",
          dataIndex: "nama_pemilik_rekening",
        },
        {
          title: "Cabang",
          key: "cabang",
          dataIndex: "cabang",
        },
      ],
    },
  ];

  const handleExport = () => {
    exportDataPengajuan(title, data, "excel", tahun, store, null);
  };

  return (
    <Modal
      open={openModal}
      title={`${title}`}
      width={1200}
      onCancel={handleClose}
      cancelText="Kembali"
      okText="Export"
      onOk={handleExport}>
      <div className="p-2">
        <Descriptions
          labelStyle={{ fontWeight: 600 }}
          size="small"
          layout="vertical"
          column={{ xxl: 3, xl: 3, lg: 3, md: 3, sm: 1, xs: 1 }}>
          <Descriptions.Item label="Batch">
            Batch {data?.batch}
          </Descriptions.Item>
          <Descriptions.Item label="Tahap">{data?.tahapan}</Descriptions.Item>
          <Descriptions.Item label="Lembaga">
            {data?.jumlah_lembaga}
          </Descriptions.Item>
          <Descriptions.Item label="Tanggal Pengajuan">
            {moment(data?.tanggal_pengajuan).format(
              "dddd,DD MMM YYYY HH:mm:ss",
            )}
          </Descriptions.Item>
          <Descriptions.Item label="Tanggal Penyaluran">
            {(data?.tanggal_penyaluran !== "-" &&
              moment(data?.tanggal_penyaluran).format(
                "dddd,DD MMM YYYY HH:mm:ss",
              )) ||
              "-"}
          </Descriptions.Item>
          <Descriptions.Item label="Periode">
            {moment(data?.tanggal_mulai_verifikasi).format("DD MMM YYYY")} -{" "}
            {moment(data?.tanggal_akhir_verifikasi).format("DD MMM YYYY")}{" "}
          </Descriptions.Item>
        </Descriptions>
      </div>
      <Table columns={columnsDokumen} dataSource={dataTable} bordered />
    </Modal>
  );
};
export default ModalPriviewListPencairan;
