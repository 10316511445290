/**
 * update fix
 *
 * @format
 */

import api from "../../../utils/api";
import { planningService } from "../constant";
const basePath = `${planningService}/rencana-tanggal-bkba`;

/** Post Rencana Tanggal Bkba  */
export const postRencanaTanggalBkba = async (params: any) => {
  const response = await api.post<any>(`${basePath}`, params);
  return response?.data?.return;
};

/** Edit Rencana Tanggal Bkba  */
export const editRencanaTanggalBkba = async (params: any) => {
  const id = params.id;
  const response = await api.put<any>(`${basePath}/${id}`, params);
  return response?.data?.return;
};

/** Delete RencanaTanggal  */
export const deleteRencanaTanggalBkba = async (id: string) => {
  const response = await api.delete(`${basePath}/${id}`);
  return response?.data?.return;
};
