/** @format */
import moment from "moment";
import "moment/locale/id";
import { formatCurr } from "../../../../utils/helper";
import jsPDF from "jspdf";
import * as FileSaver from "file-saver";
import ExcelJS from "exceljs";
import autoTable from "jspdf-autotable";
import notifAlert from "../../../../components/NotifAlert";

export const exportDataPengajuan = (
  title,
  dataExport,
  type,
  tahun,
  store,
  dataState,
) => {
  if (type === "excel") {
    if (title === "VERIFIKASI") {
      handleExcelVerifikasi(title, dataExport, tahun, store);
    } else {
      handleExcel(title, dataExport, tahun, store, dataState);
    }
  } else {
    handlePDFVerifikasi(title, dataExport, tahun, store);
  }
};

const handleExcel = async (title, dataExport, tahun, storeDb, dataState) => {
  const dataTable = dataExport || [];
  const dataList = dataExport.find((item, i) => i === 0) || null;
  const refProvinsi = storeDb?.provinsi || [];
  const refKabkota = storeDb?.kabkota || [];
  var ExcelJSWorkbook = new ExcelJS.Workbook();
  var worksheet = ExcelJSWorkbook.addWorksheet("Data");
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  worksheet.mergeCells("A1:M1");

  worksheet.getColumn("A").width = 5;
  worksheet.getColumn("B").width = 14;
  worksheet.getColumn("C").width = 40;
  worksheet.getColumn("D").width = 10;
  worksheet.getColumn("E").width = 30;
  worksheet.getColumn("F").width = 30;
  worksheet.getColumn("G").width = 30;
  worksheet.getColumn("H").width = 14;
  worksheet.getColumn("I").width = 14;
  worksheet.getColumn("J").width = 20;
  worksheet.getColumn("K").width = 25;
  worksheet.getColumn("L").width = 20;
  worksheet.getColumn("M").width = 40;
  worksheet.getColumn("N").width = 40;
  worksheet.getColumn("O").width = 40;
  worksheet.getColumn("P").width = 40;
  worksheet.getColumn("Q").width = 40;
  worksheet.getColumn("R").width = 40;
  worksheet.getColumn("S").width = 40;

  const headerCell = worksheet.getCell("A1");
  headerCell.font = {
    size: 11,
    underline: true,
    bold: true,
  };
  headerCell.alignment = { vertical: "middle", horizontal: "center" };
  headerCell.value = `DATA ${title} ALOKASI SUMBER DANA BOS T.A ${tahun}`;

  let dataTmp: any = [];
  dataTable.map((e, i) => {
    return dataTmp.push([
      i + 1,
      e.nsm,
      e.nama_madrasah,
      e.jenjang,
      refProvinsi.find((item) => item.kode === e.kode_provinsi)?.nama || "",
      refKabkota.find((item) => item.kode === e.kode_kabkota)?.nama || "",
      e.tahapan,
      dataState?.batch,
      formatCurr(e.tahap1),
      formatCurr(e.tahap2),
      formatCurr(e.tahap2 - e.nilai_aa),
      formatCurr(e.nilai_aa),
      formatCurr(e.total),
      // e.jumlahSiswa,
      e.nama_bank,
      e.cabang,
      e.nomor_rekening,
      e.nama_pemilik_rekening,
      moment(e.tanggal_verifikasi).format("dddd,DD MMM YYYY HH:mm:ss"),
      (e.tanggal_pengajuan !== "-" &&
        moment(e.tanggal_pengajuan).format("dddd,DD MMM YYYY HH:mm:ss")) ||
        "-",
      (e.tanggal_penyaluran !== "-" &&
        moment(e.tanggal_penyaluran).format("dddd,DD MMM YYYY HH:mm:ss")) ||
        "-",
    ]);
  });

  worksheet.addTable({
    name: "Table",
    ref: "A3",
    headerRow: true,
    style: {
      showRowStripes: true,
    },
    columns: [
      { name: "No" },
      { name: "NSM" },
      { name: "Madrasah" },
      { name: "Jenjang" },
      { name: "Provinsi" },
      { name: "Kabupaten" },
      { name: "Tahapan" },
      { name: "Batch" },
      { name: "Tahapan I" },
      { name: "Tahapan II" },
      { name: "Nominal Non AA" },
      { name: "Nominal AA" },
      { name: "Total" },
      // { name: "Jumlah Siswa" },
      { name: "Bank" },
      { name: "Cabang" },
      { name: "No Rekening" },
      { name: "Nama Pemilik Rekening" },
      { name: "Tanggal Verifikasi" },
      { name: "Tanggal Pengajuan" },
      { name: "Tanggal Penyaluran" },
    ],
    rows: dataTmp,
  });

  const excelBuffer = await ExcelJSWorkbook.xlsx.writeBuffer();
  const data = new Blob([excelBuffer], { type: fileType });
  if (title === "Pengajuan") {
    FileSaver.saveAs(
      data,
      `Alokasi-Sumber-Dana-BOS-tahap${dataState?.tahapan}-batch${dataState?.batch} ${fileExtension}`,
    );
  } else {
    FileSaver.saveAs(
      data,
      `Alokasi-Sumber-Dana-BOS-tahap${dataList?.tahapan}${fileExtension}`,
    );
  }

  notifAlert({
    type: "success",
    description: "Data berhasil di export",
  });
};

const handleExcelVerifikasi = async (title, dataExport, tahun, storeDb) => {
  const dataTable = dataExport;
  const refProvinsi = storeDb?.provinsi || [];
  const refKabkota = storeDb?.kabkota || [];
  var ExcelJSWorkbook = new ExcelJS.Workbook();
  var worksheet = ExcelJSWorkbook.addWorksheet("Data");
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  worksheet.mergeCells("A1:M1");

  worksheet.getColumn("A").width = 5;
  worksheet.getColumn("B").width = 14;
  worksheet.getColumn("C").width = 40;
  worksheet.getColumn("D").width = 10;
  worksheet.getColumn("E").width = 30;
  worksheet.getColumn("F").width = 30;
  worksheet.getColumn("G").width = 30;
  worksheet.getColumn("H").width = 14;
  worksheet.getColumn("I").width = 14;
  worksheet.getColumn("J").width = 20;
  worksheet.getColumn("K").width = 25;
  worksheet.getColumn("L").width = 20;
  worksheet.getColumn("M").width = 40;
  worksheet.getColumn("N").width = 40;
  worksheet.getColumn("O").width = 40;
  worksheet.getColumn("P").width = 40;
  worksheet.getColumn("Q").width = 40;
  worksheet.getColumn("R").width = 40;
  worksheet.getColumn("S").width = 40;
  worksheet.getColumn("T").width = 40;
  worksheet.getColumn("U").width = 40;

  const headerCell = worksheet.getCell("A1");
  headerCell.font = {
    size: 11,
    underline: true,
    bold: true,
  };
  headerCell.alignment = { vertical: "middle", horizontal: "center" };
  headerCell.value = `DATA ${title} ALOKASI SUMBER DANA BOS T.A ${tahun}`;

  let dataTmp: any = [];
  dataTable.map((e, i) => {
    return dataTmp.push([
      i + 1,
      e.nsm,
      e.nama_madrasah,
      e.jenjang,
      refProvinsi.find((item) => item.kode === e.kode_provinsi)?.nama || "",
      refKabkota.find((item) => item.kode === e.kode_kabkota)?.nama || "",
      e.tahapan,
      formatCurr(e.tahap1),
      formatCurr(e.tahap2),
      formatCurr(e.tahap2 - e.nilai_aa),
      formatCurr(e.nilai_aa),
      formatCurr(e.total),
      // e.jumlahSiswa,
      e.nama_bank,
      e.cabang,
      e.nomor_rekening,
      e.nama_pemilik_rekening,
      moment(e.tanggal_verifikasi).format("dddd,DD MMM YYYY HH:mm:ss"),
    ]);
  });

  worksheet.addTable({
    name: "Table",
    ref: "A3",
    headerRow: true,
    style: {
      showRowStripes: true,
    },
    columns: [
      { name: "No" },
      { name: "NSM" },
      { name: "Madrasah" },
      { name: "Jenjang" },
      { name: "Provinsi" },
      { name: "Kabupaten" },
      { name: "Tahapan" },
      { name: "Tahapan I" },
      { name: "Tahapan II" },
      { name: "Nominal Non AA" },
      { name: "Nominal AA" },
      { name: "Total" },
      // { name: "Jumlah Siswa" },
      { name: "Bank" },
      { name: "Cabang" },
      { name: "No Rekening" },
      { name: "Nama Pemilik Rekening" },
      { name: "Tanggal Verifikasi" },
    ],
    rows: dataTmp,
  });

  const excelBuffer = await ExcelJSWorkbook.xlsx.writeBuffer();
  const data = new Blob([excelBuffer], { type: fileType });
  FileSaver.saveAs(data, `Alokasi-Sumber-Dana-BOS ${fileExtension}`);
  notifAlert({
    type: "success",
    description: "Data berhasil di export",
  });
};
const handlePDFVerifikasi = (title, dataExport, tahun, storeDb) => {
  const dataTable = dataExport;
  const refProvinsi = storeDb?.provinsi || [];
  const refKabkota = storeDb?.kabkota || [];
  const doc: any = new jsPDF({
    putOnlyUsedFonts: true,
    orientation: "landscape",
    floatPrecision: 16,
  });

  doc.setFont("times", "bold");
  doc.text(`${title} SUMBER DANA BOS T.A ${tahun}`, 150, 10, {
    align: "center",
  });

  var headers: any = [
    { id: "id", dataKey: "id", padding: 0, header: "No", width: 22 },
    {
      id: "nsm",
      dataKey: "nsm",
      padding: 0,
      header: "NSM",
      width: 50,
    },
    {
      id: "nama",
      dataKey: "nama",
      padding: 0,
      header: "Madrasah",
      width: 50,
    },
    {
      id: "jenjang",
      dataKey: "jenjang",
      padding: 0,
      header: "Jenjang",
      width: 40,
    },
    {
      id: "jumlahSiswa",
      dataKey: "jumlahSiswa",
      padding: 0,
      header: "Tahap",
      width: 30,
    },
    {
      id: "provinsi",
      dataKey: "provinsi",
      padding: 0,
      header: "Provinsi",
      width: 50,
    },
    {
      id: "kabkota",
      dataKey: "kabkota",
      padding: 0,
      header: "Kab / Kota",
      width: 50,
    },
    {
      id: "tahapanI",
      dataKey: "tahapanI",
      padding: 0,
      header: "Tahapan I",
      width: 50,
    },
    {
      id: "tahapanII",
      dataKey: "tahapanII",
      padding: 0,
      header: "Tahapan II",
      width: 50,
    },
    {
      id: "nonAA",
      dataKey: "nonAA",
      padding: 0,
      header: "Non AA",
      width: 50,
    },
    {
      id: "aa",
      dataKey: "aa",
      padding: 0,
      header: "AA",
      width: 50,
    },
    {
      id: "total",
      dataKey: "total",
      padding: 0,
      header: "Total",
      width: 50,
    },
    {
      id: "bank",
      dataKey: "bank",
      padding: 0,
      header: "Bank",
      width: 39,
    },
    {
      id: "noRekening",
      dataKey: "noRekening",
      padding: 0,
      header: "No Rekening",
      width: 39,
    },
    {
      id: "namaPemilikRekening",
      dataKey: "namaPemilikRekening",
      padding: 0,
      header: "Nama Pemilik Rekening",
      width: 39,
    },
  ];
  let las: any = [];
  // const count = type === "pdf_all" ? tableData.length : 10;
  autoTable(doc, {
    styles: { lineColor: 244, lineWidth: 0.1, fontSize: 6 },
    headStyles: {
      halign: "center",
      valign: "middle",
      fillColor: [0, 128, 0],
    }, // Cells in first column centered and green
    columnStyles: {
      0: { halign: "center", cellWidth: 8 },
      1: { halign: "center", cellWidth: 22 },
      2: { halign: "left", cellWidth: 38 },
      3: { halign: "center", cellWidth: 13 },
      4: { halign: "center", cellWidth: 12 },
      5: { halign: "left", cellWidth: 20 },
      6: { halign: "left", cellWidth: 20 },
      7: { halign: "right", cellWidth: 17 },
      8: { halign: "right", cellWidth: 17 },
      9: { halign: "right", cellWidth: 17 },
      10: { halign: "right", cellWidth: 17 },
      11: { halign: "right", cellWidth: 17 },
      12: { halign: "left", cellWidth: 24 },
      13: { halign: "left", cellWidth: 25 },
      14: { halign: "left", cellWidth: 24 },
    },
    startY: 15,
    margin: { top: 3, left: 3, right: 3 },
    columns: headers,
    body: dataTable.map((e, i) => {
      return {
        id: i + 1,
        nsm: e.nsm,
        nama: e.nama_madrasah,
        jenjang: e.jenjang,
        jumlahSiswa: e.tahapan,
        provinsi:
          refProvinsi.find((item) => item.kode === e.kode_provinsi)?.nama || "",
        kabkota:
          refKabkota.find((item) => item.kode === e.kode_kabkota)?.nama || "",
        tahapanI: formatCurr(e.tahap1),
        tahapanII: formatCurr(e.tahap2),
        nonAA: formatCurr(e.tahap2 - e.nilai_aa),
        aa: formatCurr(e.nilai_aa),
        total: formatCurr(e.total),
        bank: e.nama_bank,
        noRekening: e.nomor_rekening,
        namaPemilikRekening: e.nama_pemilik_rekening,
      };
    }),
    didDrawPage: (d) => las.push(d.cursor),
  });
  var blob = doc.output("blob", { filename: "alokasi-sumber-dana-bos" });
  window.open(URL.createObjectURL(blob));
  // doc.output("dataurlnewwindow", { filename: "alokasi-sumber-dana-bos" });
  notifAlert({
    type: "success",
    description: "Data berhasil di export",
  });
};
