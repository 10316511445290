/** @format */

import React, { useState, useEffect } from "react";
import { BreadCrumb } from "../../../components";
import { Table, Space, Form, Modal, Tag, Switch, Input } from "antd";
import { useSelector } from "react-redux";
import notifAlert from "../../../components/NotifAlert";
import {
  ButtonTableDelete,
  ButtonTableEdit,
  ButtonTambah,
} from "../../../components/Button";
import InputSearch from "../../../components/InputSearch";
import { uuidv4 } from "../../../utils/helper";
import {
  deleteTipePencairan,
  postTipePencairan,
} from "../../../services/v2/referenceservice/tipepencairan";
import { getReferenceAll } from "../../../services/v2/referenceservice";

const TipePencairan = () => {
  const itemBreadcrumb = [
    { path: "/", breadcrumbName: "Home" },
    { path: "/", breadcrumbName: "Referensi" },
    { path: "/", breadcrumbName: "Tipe Pencairan" },
  ];
  const [form] = Form.useForm();
  const auths = useSelector((state: any) => state.auth);
  const auth = auths.data || null;
  const kodeRole = auth?.kode_role;

  const [refTipePencairan, setRefTipePencairan] = useState<any>([]);
  const [search, setSearch] = useState<any>(null);
  const [id, setID] = useState<any>(null);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const [title, setTitle] = useState<any>("");
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState<any>(false);
  /** Filter Search */
  let dataTable: any = search
    ? refTipePencairan.filter((item: any) => {
        const val = search.toLowerCase();
        return (
          (item.nama !== null &&
            (item.nama || "").toString().toLowerCase().includes(val)) ||
          (item.kode !== null &&
            (item.kode || "").toString().toLowerCase().includes(val))
        );
      })
    : refTipePencairan;
  const totalDataTable = dataTable.length;
  /**
   * Filter Pagination
   */
  dataTable = dataTable.filter((v, i) => {
    let start = pageSize * (page - 1);
    let end = start + pageSize;

    return i >= start && i < end;
  });

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getData = async () => {
    setLoading(true);
    try {
      const tipePencairan = await getReferenceAll("tipe-pencairan", {
        activated: 1,
        tahun: auth.isTahun,
      });
      setRefTipePencairan(tipePencairan || []);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };
  const columnTmp = [
    {
      title: "Kode",
      dataIndex: "kode",
      key: "kode",
      onFilter: (value, record) => record.kode.indexOf(value) === 0,
      sorter: (a, b) => a.kode - b.kode,
    },
    {
      title: "Nama",
      dataIndex: "nama",
      key: "nama",
      onFilter: (value, record) => record.nama.indexOf(value) === 0,
      sorter: (a, b) => a.nama - b.nama,
    },
    {
      title: "Madrasah",
      dataIndex: "isNegeri",
      key: "isNegeri",
      render: (isNegeri) =>
        (isNegeri === "0" && <Tag color="default">Swasta</Tag>) || (
          <Tag color="success">Negeri</Tag>
        ),
    },
    {
      title: "Aksi",
      key: "aksi",

      render: (record) => {
        return (
          record.kode !== "4" && (
            <Space>
              <ButtonTableEdit onClick={() => handleEdit(record)} />
              <ButtonTableDelete
                onClick={() => {
                  Modal.confirm({
                    title: "Perhatian",
                    content: "Yakin Hapus Data?",
                    onOk() {
                      return handleDelete(record);
                    },
                    onCancel() {},
                    okText: "Hapus",
                    cancelText: "Batal",
                    okType: "danger",
                  });
                }}
              />
            </Space>
          )
        );
      },
    },
  ];

  kodeRole !== "super_admin_pusat" &&
    // kodeRole !== "admin_pusat" &&
    delete columnTmp[3];

  const handleEdit = (record: any) => {
    setTitle("Edit");
    setOpenModal(true);
    setID(record.id);
    form.setFieldsValue({
      kode: record.kode,
      nama: record.nama,
      isNegeri: record.isNegeri === "0" ? false : true,
    });
  };

  const handleDelete = async (record: any) => {
    setLoading(true);
    try {
      await deleteTipePencairan(record.id);
      getData();
      notifAlert({
        type: "success",
        description: "Hapus data berhasil",
      });
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const handleTambah = () => {
    setTitle("Tambah");
    setID(uuidv4());
    setOpenModal(true);
    form.resetFields();
  };

  const handleSave = async (values: any) => {
    const payload: any = {
      id: id,
      activated: 1,
      kode: values.kode,
      nama: values.nama,
      isNegeri: values.isNegeri ? "1" : "0",
    };
    setLoading(true);
    try {
      await postTipePencairan(payload);
      getData();
      notifAlert({
        type: "success",
        description: "Data berhasil disimpan",
      });
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
    setOpenModal(false);
  };

  const validateKode = (_, values) => {
    const val = values.toLowerCase();

    const checkKode: any =
      refTipePencairan
        .filter((item) => !item.id.includes(id))
        .find(
          (item) =>
            item.kode !== null &&
            (item.kode || "").toString().toLowerCase() === val,
        ) || null;
    if (checkKode) {
      return Promise.reject("Kode Sudah Digunakan!");
    } else {
      return Promise.resolve();
    }
  };
  return (
    <>
      <BreadCrumb routes={itemBreadcrumb} title="Tipe Pencairan" />
      <div className="m-5 p-5 bg-white shadow-md rounded">
        <div className="mb-4 flex justify-end">
          {
            //kodeRole === "admin_pusat" ||
            kodeRole === "super_admin_pusat" && (
              <div className="mr-4">
                <ButtonTambah onClick={handleTambah} />
              </div>
            )
          }

          <InputSearch
            className="w-3/4"
            onChange={(e: any) => setSearch(e.currentTarget.value)}
          />
        </div>
        <div className="w-full">
          <Table
            rowKey={(record) => record.id}
            columns={columnTmp}
            loading={loading}
            dataSource={dataTable}
            bordered
            pagination={{
              total: totalDataTable,
              position: ["bottomRight"],
              defaultPageSize: pageSize,
              defaultCurrent: page,
              showTotal: (total) => `Total ${total} items`,
              onChange(page, pageSize) {
                setPage(page);
                setPageSize(pageSize);
              },
            }}
          />
        </div>
      </div>
      <Modal
        open={openModal}
        title={`${title} Tipe Pencairan`}
        onCancel={() => setOpenModal(!openModal)}
        okText="Simpan"
        confirmLoading={loading}
        cancelText="Batal"
        onOk={() => form.submit()}>
        <Form
          form={form}
          key="formTipePencairan"
          name="formTipePencairan"
          layout="vertical"
          initialValues={{ koefisien: 0 }}
          onFinish={handleSave}>
          <Form.Item
            label="Kode"
            name="kode"
            rules={[
              { required: true, message: "Kode tidak boleh kosong!" },
              { validator: validateKode },
            ]}>
            <Input placeholder="Kode" />
          </Form.Item>
          <Form.Item
            label="Nama"
            name="nama"
            rules={[{ required: true, message: "Nama tidak boleh kosong!" }]}>
            <Input placeholder="Nama" />
          </Form.Item>

          <Form.Item
            label="Jenis Madrasah"
            name="isNegeri"
            valuePropName="checked">
            <Switch checkedChildren="Negeri" unCheckedChildren="Swasta" />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
export default TipePencairan;
