/** @format */

import React, { useState, useEffect } from "react";
import { BreadCrumb } from "../../components";
import {
  Table,
  Select,
  Space,
  Form,
  Modal,
  Input,
  Badge,
  // Dropdown,
  // Button,
} from "antd";
import moment from "moment";
import "moment/locale/id";
import { useDispatch, useSelector } from "react-redux";
import notifAlert from "../../components/NotifAlert";
import { ButtonTableDelete, ButtonTambah } from "../../components/Button";
import InputSearch from "../../components/InputSearch";
import {
  getComments,
  getMenuPage,
  postComment,
  deleteComment,
} from "../../services/v2/notificationservice/comment";
import { getMadrasah } from "../../services/v2/usermanservice/madrasahservices";
import { setStore } from "../../redux/actions";
import { uuidv4 } from "../../utils/helper";

const Komentar = () => {
  const itemBreadcrumb = [
    { path: "/", breadcrumbName: "Home" },
    { path: "/", breadcrumbName: "Komentar" },
  ];
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const auths = useSelector((state: any) => state.auth);
  const store = useSelector((state: any) => state.store);
  const auth = auths.data;
  const profile = store?.profile || null;
  const groupRole = auth?.group_role || null;
  const kodeRole = auth?.kode_role;
  const tmpMadrasah = store?.listMadrasah || [];
  const [search, setSearch] = useState<any>(null);
  const [loading, setLoading] = useState<any>(false);
  const [madrasah, setMadrasah] = useState<any>([]);
  const [menupage, setMenupage] = useState<any>([]);
  const [tableData, setTableData] = useState<any>([]);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [title, setTitle] = useState<any>("");
  const [openModal, setOpenModal] = useState(false);
  /** Filter Search */
  let dataTable: any = search
    ? tableData.filter((item: any) => {
        const val = search.toLowerCase();
        return (
          (item.kodeRole !== null &&
            (item.kodeRole || "").toString().toLowerCase().includes(val)) ||
          (item.message !== null &&
            (item.message || "").toString().toLowerCase().includes(val))
        );
      })
    : tableData;
  // dataTable = dataTable.filter((item) => item.userId === auth?.user_id);
  const totalDataTable = dataTable.length;

  /**
   * Filter Pagination
   */
  dataTable = dataTable.filter((v, i) => {
    let start = pageSize * (page - 1);
    let end = start + pageSize;

    return i >= start && i < end;
  });

  const columnTmp: any = [
    // {
    //   title: "User Role",
    //   dataIndex: "kodeRole",
    //   key: "kodeRole",
    //   onFilter: (value, record) => record.kodeRole.indexOf(value) === 0,
    //   sorter: (a, b) => a.kodeRole - b.kodeRole,
    // },
    {
      title: "Madrasah",
      dataIndex: "madrasahId",
      key: "madrasahId",
      onFilter: (value, record) => record.madrasahId.indexOf(value) === 0,
      sorter: (a, b) => a.madrasahId.length - b.madrasahId.length,
      render: (madrasahId) =>
        madrasah.length &&
        madrasah
          .filter((item) => item.id.includes(madrasahId))
          .map((item) => item.nama),
    },
    {
      title: "Menu Page",
      dataIndex: "notificationPage",
      key: "notificationPage",
    },
    {
      title: "Komentar",
      dataIndex: "message",
      key: "message",
    },
    {
      title: "Status",
      dataIndex: "notificationStatus",
      key: "notificationStatus",
      width: 180,
      render: (notificationStatus) => (
        <Badge
          status={
            (notificationStatus === "READ" && "success") ||
            (notificationStatus === "NOT_READ" && "error") ||
            "default"
          }
          text={
            notificationStatus === "NOT_READ" ? "Belum Dibaca" : "Sudah Dibaca"
          }
        />
      ),
    },
    {
      title: "Tanggal",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (created_at) =>
        moment(created_at).format("dddd,DD MMM YYYY HH:mm:ss"),
    },
    {
      title: "Aksi",
      key: "aksi",
      align: "center",
      width: 120,
      fixed: "right",
      render: (record) => (
        <Space>
          {record.senderUserId === auth.user_id && (
            <ButtonTableDelete
              onClick={() => {
                Modal.confirm({
                  title: "Perhatian",
                  content: "Yakin Hapus Data?",
                  onOk() {
                    return handleDelete(record);
                  },
                  onCancel() {},
                  okText: "Hapus",
                  cancelText: "Batal",
                  okType: "danger",
                });
              }}
            />
          )}
        </Space>
      ),
    },
  ];

  const handleDelete = async (record: any) => {
    try {
      await deleteComment(record.id);
      notifAlert({
        type: "success",
        description: "Komentar berhasil di hapus",
      });
      getData();
    } catch (error) {
      console.log(error);
    }
  };

  const handleSave = async (values: any) => {
    let payload = {
      trxId: uuidv4(),
      madrasahId: values.madrasah,
      message: values.message,
      commentPage: values.page,
      tahun: auth.isTahun,
      trxDate: `${moment().format("YYYY-MM-DDTHH:mm:ss")}`,
    };
    try {
      await postComment(payload);
      notifAlert({
        type: "success",
        description: "Komentar berhasil di kirim",
      });
      getData();
    } catch (error) {
      console.log(error);
    }
    setOpenModal(false);
  };

  const handleTambah = () => {
    setTitle("Tambah");
    setOpenModal(true);
    form.resetFields();
  };

  const getData = async () => {
    setLoading(true);
    let filter: any = "";
    if (groupRole === "provinsi") {
      filter = `?kodeProvinsi=${auth?.kode_provinsi}`;
    }
    if (groupRole === "kabkota") {
      filter = `?kodeProvinsi=${auth?.kode_provinsi}&kode_kabkota=${auth?.kode_kabkota}`;
    }
    try {
      const res = await getComments();
      const pageTmp = await getMenuPage();
      if (tmpMadrasah.length === 0) {
        let refMadrasah: any = await getMadrasah(filter);
        const listMadrasah = refMadrasah;
        setTimeout(() => {
          dispatch(setStore({ listMadrasah }));
        }, 100);
        setMadrasah(refMadrasah || []);
      } else {
        setMadrasah(tmpMadrasah || []);
      }
      setMenupage(pageTmp);
      setTableData(res);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  let listMadrasah =
    (profile?.list_madrasah_id_pengawas?.length &&
      madrasah.filter((item) =>
        profile?.list_madrasah_id_pengawas.some((items) =>
          items.id.includes(item.id),
        ),
      )) ||
    madrasah;

  return (
    <>
      <BreadCrumb routes={itemBreadcrumb} title="Komentar" />
      <div className="m-5 p-5 bg-white shadow-md rounded">
        <div className="mb-4 flex justify-end">
          <Space size={[8, 8]} wrap>
            {kodeRole !== "pengawas_pusat" && (
              <div className="mr-4">
                <ButtonTambah onClick={handleTambah} loading={loading} />
              </div>
            )}
          </Space>
          <InputSearch
            className="w-3/4"
            onChange={(e: any) => setSearch(e.currentTarget.value)}
          />
        </div>
        <div className="w-full">
          <Table
            rowKey={(record) => record.id}
            columns={columnTmp}
            loading={loading}
            dataSource={dataTable}
            bordered
            pagination={{
              total: totalDataTable,
              position: ["bottomRight"],
              defaultPageSize: pageSize,
              defaultCurrent: page,
              showTotal: (total) => `Total ${total} items`,
              onChange(page, pageSize) {
                setPage(page);
                setPageSize(pageSize);
              },
            }}
          />
        </div>
      </div>
      <Modal
        open={openModal}
        title={`${title} Komentar`}
        onCancel={() => setOpenModal(!openModal)}
        okText="Simpan"
        cancelText="Batal"
        onOk={() => form.submit()}>
        <Form
          form={form}
          key="formKomentar"
          name="formKomentar"
          layout="vertical"
          onFinish={handleSave}>
          <Form.Item
            label="Madrasah"
            name="madrasah"
            rules={[
              { required: true, message: "Madrasah tidak boleh kosong!" },
            ]}>
            <Select
              placeholder="Madrasah"
              allowClear
              showSearch
              optionFilterProp="children">
              {listMadrasah.length &&
                listMadrasah
                  .filter(
                    (item) =>
                      item.kode_kabkota === profile?.kode_kabkota &&
                      item.kode_provinsi === profile?.kode_provinsi,
                  )
                  .map((item) => {
                    return (
                      <Select.Option key={`madras${item.id}`} value={item.id}>
                        {`${item.nama} - ${item.nsm}`}
                      </Select.Option>
                    );
                  })}
            </Select>
          </Form.Item>
          <Form.Item
            label="Page"
            name="page"
            rules={[{ required: true, message: "Page tidak boleh kosong!" }]}>
            <Select
              placeholder="Page"
              allowClear
              showSearch
              optionFilterProp="children">
              {menupage.length &&
                menupage.map((item) => {
                  return (
                    <Select.Option key={`madras${item.name}`} value={item.name}>
                      {item.name}
                    </Select.Option>
                  );
                })}
            </Select>
          </Form.Item>
          <Form.Item
            label="Komentar"
            name="message"
            rules={[
              {
                required: true,
                message: "Komentar tidak boleh kosong!",
              },
            ]}>
            <Input.TextArea placeholder="Komentar" />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default Komentar;
