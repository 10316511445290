/** @format */
import React, { useState, useEffect } from "react";
import { BreadCrumb } from "../../../components";
import moment from "moment";
import "moment/locale/id";
import { Table, Space } from "antd";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import * as FileSaver from "file-saver";
import ExcelJS from "exceljs";
import { formatRupiah } from "../../../utils/helper";

import { useDispatch, useSelector } from "react-redux";
import { ButtonDropdownExport } from "../../../components/Button";
import InputSearch from "../../../components/InputSearch";
import notifAlert from "../../../components/NotifAlert";
import { getMadrasah } from "../../../services/v2/usermanservice/madrasahservices";
import {
  getLaporanBKUTunai,
  getLaporanDaftarSumberDana,
} from "../../../services/v2/reportservice";
import { setStore } from "../../../redux/actions";
import FilterSumberDanaPeriode from "../Component/FilterSumberDanaPeriode";

const BukuKasPembantu = () => {
  const itemBreadcrumb = [
    { path: "/", breadcrumbName: "Home" },
    { path: "/", breadcrumbName: "Laporan" },
    { path: "/", breadcrumbName: "Laporan Penatausahaan" },
    { path: "/", breadcrumbName: "Buku Pembantu Kas Tunai" },
  ];
  const auths = useSelector((state: any) => state.auth);
  const store = useSelector((state: any) => state.store);
  const profile = store?.madrasahProfile || null;
  // const refBank = store.rekeningBelanja || [];
  // const refTipeKas = store.tipeKas || [];
  const dispatch = useDispatch();
  const tmpListMadrasah = store?.listMadrasah || [];
  const refKabkota = store.kabkota || [];
  const refProvinsi = store.provinsi || [];
  const refKecamatan = store.kecamantan || [];
  const auth = auths?.data || null;
  const groupRole = auth?.group_role || "";
  const [dataMadrasah, setDataMadrasah] = useState<any>(null);
  const [tableData, setTableData] = useState<any>([]);
  const [realisasiSumber, setRealisasiSumber] = useState<any>([]);
  // const [tmpData, setTmpData] = useState<any>(null);
  const [tmpMadrasah, setTmpMadrasah] = useState<any>([]);
  const [search, setSearch] = useState<any>(null);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [tmpFilter, setTmpFilter] = useState<any>(null);
  const [loading, setLoading] = useState(false);

  let dataTable: any = search
    ? tableData.filter((item: any) => {
        const val = search.toLowerCase();
        return (
          (item.noKode !== null &&
            (item.noKode || "").toString().toLowerCase().includes(val)) ||
          (item.uraian !== null &&
            (item.uraian || "").toString().toLowerCase().includes(val)) ||
          (item.noBukti !== null &&
            (item.noBukti || "").toString().toLowerCase().includes(val))
        );
      })
    : tableData;
  // dataTable = dataTable.filter(
  //   // eslint-disable-next-line eqeqeq
  //   (item) => moment(item?.tglTrx).format("M") == tmpData?.bulan,
  // );
  let totalDataTable = dataTable.length;

  /**
   * Filter Pagination
   */
  dataTable = dataTable
    // .sort((a, b) => a.tglTrx - b.tglTrx)
    .filter((v, i) => {
      let start = pageSize * (page - 1);
      let end = start + pageSize;

      return i >= start && i < end;
    });

  const getData = async (tmpFilter) => {
    setLoading(true);
    if (groupRole !== "madrasah") {
      if (tmpListMadrasah.length === 0) {
        // const filterMadrasah =
        //   // (groupRole === "provinsi" &&
        //   //   `?activated=1&kodeProvinsi=${profile?.kode_provinsi}`) ||
        //   // (groupRole === "kabkota" &&
        //   //   `?activated=1&kodeProvinsi=${profile?.kode_provinsi}&kode_kabkota=${profile?.kode_kabkota}`) ||
        //   `?activated=1`;
        let filter: any = "";
        if (groupRole === "provinsi") {
          filter = `?kodeProvinsi=${auth?.kode_provinsi}`;
        }
        if (groupRole === "kabkota") {
          filter = `?kodeProvinsi=${auth?.kode_provinsi}&kode_kabkota=${auth?.kode_kabkota}`;
        }
        const madrasah = await getMadrasah(filter);
        const listMadrasah = madrasah || [];
        setTimeout(() => {
          dispatch(setStore({ listMadrasah }));
        }, 100);
        setTmpMadrasah(madrasah);
      } else {
        setTmpMadrasah(tmpListMadrasah);
      }

      const payload = {
        tahun: moment(tmpFilter?.periode).format("YYYY"),
        periode: moment(tmpFilter?.periode).format("MM"),
        madrasahId: tmpFilter?.madrasahId,
        kodeSumberDana: tmpFilter?.sumberDana,
      };

      if (tmpFilter?.madrasahId) {
        const tmpSumberDana = await getLaporanDaftarSumberDana({
          tahun: auth?.isTahun,
          madrasahId: tmpFilter?.madrasahId,
        });
        setRealisasiSumber(tmpSumberDana);
      }

      if (
        tmpFilter?.periode &&
        tmpFilter?.madrasahId &&
        tmpFilter?.sumberDana
      ) {
        const resMadrasah = tmpListMadrasah.find((item) =>
          item.id.includes(tmpFilter?.madrasahId),
        );
        setDataMadrasah(resMadrasah);
        const res = await getLaporanBKUTunai(payload);
        setTableData(res || []);
        // setTmpData(res);
      }
    } else {
      setDataMadrasah(profile);
      const tmpSumberDana = await getLaporanDaftarSumberDana({
        tahun: auth?.isTahun,
        madrasahId: auth?.madrasah?.id,
      });

      setRealisasiSumber(tmpSumberDana);

      const payload = {
        tahun: moment(tmpFilter?.periode).format("YYYY"),
        periode: moment(tmpFilter?.periode).format("MM"),
        madrasahId: auth?.madrasah?.id,
        kodeSumberDana: tmpFilter?.sumberDana,
      };
      if (tmpFilter?.periode && tmpFilter?.sumberDana) {
        const res = await getLaporanBKUTunai(payload);
        setTableData(res || []);
        // setTmpData(res);
      }
    }

    setLoading(false);
  };

  useEffect(() => {
    getData(tmpFilter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tmpFilter, auth?.isTahun]);

  const handleExportFile = (type) => {
    if (dataTable.length > 0) {
      if (type === "pdf") {
        handleExportPdf();
      } else if (type === "excel") {
        handleExportExcel();
      }
    } else {
      notifAlert({
        type: "warning",
        description:
          "Harap pilih data yang ingin di export melalui fungsi filter!",
      });
    }
  };

  /**
   * Data untuk tabel children pertama
   */
  let columns: any = [
    {
      title: "No Urut",
      key: "noUrut",
      width: 80,
      render: (_, record, i: number) => i + 1 + (page - 1) * pageSize,
    },
    {
      title: "Tanggal",
      key: "tglTrx",
      dataIndex: "tglTrx",
      width: 250,
      render: (tglTrx) =>
        tglTrx && moment(tglTrx).format("dddd, YYYY-MMM-DD HH:mm:ss"),
    },
    {
      title: "No. Kode",
      key: "noKode",
      dataIndex: "noKode",
      width: 280,
    },
    {
      title: "No. Bukti",
      key: "noBukti",
      dataIndex: "noBukti",
      width: 180,
    },
    {
      title: "Uraian",
      key: "uraian",
      dataIndex: "uraian",
      width: 400,
    },
    {
      title: "Penerimaan (Debit)",
      key: "debit",
      dataIndex: "debit",
      align: "right",
      width: 250,
      render: (debit) => formatRupiah(debit),
    },
    {
      title: "Pengeluaran (Kredit)",
      key: "credit",
      dataIndex: "credit",
      align: "right",
      width: 250,
      render: (credit) => formatRupiah(credit),
    },
    {
      title: "Saldo",
      key: "saldo",
      dataIndex: "saldo",
      align: "right",
      width: 250,
      render: (saldo) => (
        <div className="text-right">{formatRupiah(saldo)}</div>
      ),
    },
  ];

  // const handleUraian = (data: any) => {
  //   let tmpUraian: any = null;
  //   const findRekening =
  //     (data.kodeTipeKas === "rekening_bank" &&
  //       refBank.find(
  //         (dataRekening) => dataRekening.id === data.rekeningBankId,
  //       )) ||
  //     null;
  //   const findTipeKas =
  //     refTipeKas.find((item) => item.kode === data.kodeTipeKas) || null;

  //   if (data.referensi === "realisasi_pendapatan") {
  //     if (data.kodeTipeKas === "tunai") {
  //       tmpUraian = `Penerimaan Pindah Buku ${data.namaSumberDana}, Kas: ${findTipeKas?.nama}`;
  //     } else {
  //       tmpUraian = `Penerimaan ${data.kodeSumberDana}, Kas: ${findTipeKas?.nama}, No Rekening: ${findRekening?.no_rekening}, An: ${findRekening?.no_rekening_nama}`;
  //     }
  //   } else if (data.referensi === "realisasi_biaya") {
  //     tmpUraian = `Dibayarkan ${data.komponenBiayaNama}, Qty ${data.kuantitas}, @ ${data.hargaSatuan}`;
  //   } else if (data.referensi === "realisasi_pindah_buku") {
  //     if (data.toKodeTipeKas === "tunai") {
  //       tmpUraian = `Penerimaan Pindah Buku ${data.namaSumberDana}, Kas: ${findTipeKas?.nama}`;
  //     } else {
  //       tmpUraian = `Penerimaan Pindah Buku ${data.namaSumberDana}, Kas: ${findTipeKas?.nama}, No Rekening: ${findRekening?.no_rekening}, An: ${findRekening?.no_rekening_nama} `;
  //     }
  //   } else if (data.referensi === "realisasi_pajak") {
  //     if (data.kodeTipeKas === "tunai") {
  //       tmpUraian = `Pengeluaran Pajak ${data.namaSumberDana}, Kas: ${findTipeKas?.nama}`;
  //     } else {
  //       tmpUraian = `Pengeluaran Pajak ${data.namaSumberDana}, Kas: ${findTipeKas?.nama}, No Rekening: ${findRekening?.no_rekening}, An: ${findRekening?.no_rekening_nama}`;
  //     }
  //   }
  //   return tmpUraian;
  // };

  const handleChangeValues = (values) => {
    setTmpFilter(values);
  };

  function handleExportPdf() {
    const doc: any = new jsPDF({
      putOnlyUsedFonts: true,
      orientation: "landscape",
      floatPrecision: 16,
    });
    var fline = 10;
    doc.setFont("times", "bold");
    doc.text("BUKU PEMBANTU KAS TUNAI", 150, 10, { align: "center" });
    doc.text(
      "Periode : " +
        moment(tmpFilter?.periode).format("MMMM") +
        " " +
        moment(tmpFilter?.periode).format("YYYY"),
      150,
      18,
      { align: "center" },
    );
    doc.setFont("times", "normal");
    doc.setFontSize(12);
    doc.rect(225, 25, 60, 7);
    doc.rect(225, 25, 60, 25);
    doc.text("Format BOS K-2", 228, 30);
    doc.text("Diisi oleh Bendahara Madrasah", 228, 38);
    doc.text("Disimpan di Madrasah", 228, 48);
    doc.text("Nama Madrasah", fline, 30);
    doc.text(": " + (dataMadrasah && dataMadrasah?.nama), fline + 40, 30);
    doc.text("NSM", fline, 35);
    doc.text(": " + (dataMadrasah && dataMadrasah?.nsm), fline + 40, 35);
    doc.text("Kecamatan", fline, 40);
    doc.text(
      ": " +
        (dataMadrasah && dataMadrasah?.kode_kecamatan
          ? refKecamatan
              .filter((e) => e.kode === dataMadrasah?.kode_kecamatan)
              .map((e) => e.nama)
          : "-"),
      fline + 40,
      40,
    );
    doc.text("Kabupaten / Kota", fline, 45);
    doc.text(
      ": " +
        (dataMadrasah && dataMadrasah?.kode_kabkota
          ? refKabkota
              .filter((e) => e.kode === dataMadrasah?.kode_kabkota)
              .map((e) => e.nama)
          : "-"),
      fline + 40,
      45,
    );
    doc.text("Provinsi", fline, 50);
    doc.text(
      ": " +
        (dataMadrasah && dataMadrasah?.kode_provinsi
          ? refProvinsi
              .filter((e) => e.kode === dataMadrasah?.kode_provinsi)
              .map((e) => e.nama)
          : "-"),
      fline + 40,
      50,
    );
    var headers: any = [
      { id: "id", dataKey: "id", padding: 0, header: "No Urut", width: 22 },
      {
        id: "tanggal",
        dataKey: "tanggal",
        padding: 0,
        header: "Tanggal",
        width: 50,
      },
      {
        id: "nokode",
        dataKey: "nokode",
        padding: 0,
        header: "No Kode",
        width: 40,
      },
      {
        id: "nobukti",
        dataKey: "nobukti",
        padding: 0,
        header: "No Bukti",
        width: 40,
      },
      {
        id: "uraian",
        dataKey: "uraian",
        padding: 0,
        header: "Uraian",
        width: 70,
      },
      {
        id: "debit",
        dataKey: "debit",
        padding: 0,
        header: "Penerimaan (Debit)",
        width: 39,
      },
      {
        id: "kredit",
        dataKey: "kredit",
        padding: 0,
        header: "Pengeluaran (Kredit)",
        width: 39,
      },
      { id: "saldo", dataKey: "saldo", padding: 0, header: "Saldo", width: 39 },
    ];
    let las: any = [];
    autoTable(doc, {
      styles: { lineColor: 244, lineWidth: 0.1 },
      headStyles: {
        halign: "center",
        valign: "middle",
        fillColor: [0, 128, 0],
      }, // Cells in first column centered and green
      columnStyles: {
        0: { halign: "center", cellWidth: 12 },
        1: { halign: "center", cellWidth: 20 },
        2: { halign: "center", cellWidth: 40 },
        3: { halign: "center", cellWidth: 40 },
        5: { halign: "right", cellWidth: 30 },
        6: { halign: "right", cellWidth: 30 },
        7: { halign: "right", cellWidth: 30 },
      },
      startY: 55,
      margin: { top: 10, left: 10, right: 10 },
      columns: headers,
      body: dataTable.map((e, i) => {
        return {
          id: i + 1,
          tanggal: e.tglTrx
            ? moment(e.tglTrx).format("dddd,DD MMM YYYY HH:mm:ss")
            : "",
          nokode: e.noKode,
          nobukti: e.noBukti,
          uraian: e.uraian,
          debit: formatRupiah(e.debit),
          kredit: formatRupiah(e.credit),
          saldo: formatRupiah(e.saldo),
        };
      }),
      didDrawPage: (d) => las.push(d.cursor),
    });
    let lastLine: any = doc.lastAutoTable.finalY + 5;
    const footerText = [
      {
        text: "Mengetahui",
        line: fline,
        space: 0,
        bottom: 10,
      },
      {
        text: moment().format("dddd, DD MMMM YYYY"),
        line: fline,
        space: 210,
        bottom: 0,
      },
      {
        text: "Kepala Madrasah",
        line: fline,
        space: 0,
        bottom: 5,
      },
      {
        text: "Bendahara Madrasah",
        line: fline,
        space: 210,
        bottom: 0,
      },
      {
        text:
          (dataMadrasah?.nama_kepala && `( ${dataMadrasah?.nama_kepala} )`) ||
          "(.................)",
        line: fline,
        space: 0,
        bottom: 30,
      },
      {
        text:
          (dataMadrasah?.nama_bendahara &&
            `( ${dataMadrasah?.nama_bendahara} )`) ||
          "(.................)",
        line: fline,
        space: 210,
        bottom: 0,
      },
    ];
    let tmpBottom = lastLine;
    // eslint-disable-next-line array-callback-return
    footerText.map((item) => {
      tmpBottom += item.bottom;
      if (tmpBottom > 195) {
        doc.addPage();
        tmpBottom = 15;
      }
      doc.text(item.text, fline + item.space, tmpBottom);
    });
    doc.output("dataurlnewwindow", {
      filename: "laporan-buku-pembantu-kas-tunai",
    });
    notifAlert({
      type: "success",
      description: "Data berhasil di export",
    });
  }

  const handleExportExcel = async () => {
    // const dataMadrasah =
    //   (groupRole === "madrasah" && auth?.madrasah) ||
    //   tmpMadrasah.find((item) => item.id === tmpFilter?.madrasahId) ||
    //   null;
    // const fileType =
    //   "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    // const fileExtension = ".xlsx";
    // var ws = XLSX.utils.json_to_sheet(
    //   [
    //     { A: "BUKU PEMBANTU KAS TUNAI" },
    //     {
    //       A:
    //         "Periode : " +
    //         moment(tmpFilter?.periode).format("MMMM") +
    //         " " +
    //         moment(tmpFilter?.periode).format("YYYY"),
    //     },
    //     { A: "" },
    //     {
    //       A: "Nama Madrasah :" + (dataMadrasah && dataMadrasah?.nama),
    //     },
    //     { A: "NSM :" + (dataMadrasah && dataMadrasah?.nsm) },
    //     {
    //       A:
    //         "Kecamatan :" +
    //         (dataMadrasah && dataMadrasah?.kode_kecamatan
    //           ? refKecamatan
    //               .filter((e) => e.kode === dataMadrasah?.kode_kecamatan)
    //               .map((e) => e.nama)
    //           : "-"),
    //     },
    //     {
    //       A:
    //         "Kabupaten / Kota :" +
    //         (dataMadrasah && dataMadrasah?.kode_kabkota
    //           ? refKabkota
    //               .filter((e) => e.kode === dataMadrasah?.kode_kabkota)
    //               .map((e) => e.nama)
    //           : "-"),
    //     },
    //     {
    //       A:
    //         "Provinsi :" +
    //         (dataMadrasah && dataMadrasah?.kode_provinsi
    //           ? refProvinsi
    //               .filter((e) => e.kode === dataMadrasah?.kode_provinsi)
    //               .map((e) => e.nama)
    //           : "-"),
    //     },
    //   ],
    //   { skipHeader: true },
    // );
    // const merge = [
    //   { s: { r: 0, c: 0 }, e: { r: 0, c: 8 } },
    //   { s: { r: 1, c: 0 }, e: { r: 1, c: 8 } },
    // ];
    // ws["!merges"] = merge;
    // ws["A1"].s = {
    //   font: {
    //     sz: 24,
    //     bold: true,
    //   },
    //   alignment: {
    //     vertical: "center",
    //     horizontal: "center",
    //   },
    // };
    // let dataTmp: any = [];
    // dataTable.map((e, i) => {
    //   return dataTmp.push({
    //     "No Urut": i + 1,
    //     Tanggal: e.tglTrx
    //       ? moment(e.tglTrx).format("dddd,DD MMM YYYY HH:mm:ss")
    //       : "",
    //     "No Kode": e.noKode,
    //     "No Bukti": e.noBukti,
    //     Uraian: e.uraian,
    //     "Penerimaan (Debit)": formatRupiah(e.debit),
    //     "Pengeluaran (Kredit)": formatRupiah(e.credit),
    //     Saldo: formatRupiah(e.jumlah),
    //   });
    // });
    // XLSX.utils.sheet_add_json(ws, dataTmp, { origin: "A10" });
    // ws["A10"].s = {
    //   fill: {
    //     patternType: "solid",
    //     bgColor: { rgb: "000000" }, // 'FFFFAA00',
    //     // fgColor: 'FFBBAA00'
    //   },
    //   font: {
    //     bold: true,
    //   },
    //   alignment: {
    //     vertical: "center",
    //     horizontal: "center",
    //   },
    // };
    // const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    // const excelBuffer = XLSX.write(wb, {
    //   bookType: "xlsx",
    //   type: "binary",
    //   bookSST: true,
    //   cellStyles: true,
    // });
    // const data = new Blob([sheet_to_blob(excelBuffer)], { type: fileType });
    var ExcelJSWorkbook = new ExcelJS.Workbook();
    var worksheet = ExcelJSWorkbook.addWorksheet("Data");
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";

    worksheet.mergeCells("A1:I1");
    const headerCell = worksheet.getCell("A1");
    headerCell.font = {
      size: 11,
      underline: true,
      bold: true,
    };
    headerCell.alignment = { vertical: "middle", horizontal: "center" };
    headerCell.value = "BUKU PEMBANTU KAS TUNAI";
    worksheet.mergeCells("A2:I2");
    const subHeaderCell = worksheet.getCell("A2");
    subHeaderCell.font = {
      size: 11,
      underline: true,
      bold: true,
    };
    subHeaderCell.alignment = { vertical: "middle", horizontal: "center" };
    subHeaderCell.value = `Periode : ${moment(tmpFilter?.periode).format(
      "MMMM",
    )} ${moment(tmpFilter?.periode).format("YYYY")}`;
    const namaKecamatan =
      refKecamatan.find((item) => item.kode === dataMadrasah?.kode_kecamatan) ||
      null;
    const namaKabkota =
      refKabkota.find((item) => item.kode === dataMadrasah?.kode_kabkota) ||
      null;
    const namaProvinsi =
      refProvinsi.find((item) => item.kode === dataMadrasah?.kode_provinsi) ||
      null;
    worksheet.getCell("A4").value = "Nama Madrasah";
    worksheet.getCell("B4").value = `: ${dataMadrasah?.nama || "-"}`;
    worksheet.getCell("A5").value = "NSM";
    worksheet.getCell("B5").value = `: ${dataMadrasah?.nsm || "-"}`;
    worksheet.getCell("A6").value = "Kecamatan";
    worksheet.getCell("B6").value = `: ${namaKecamatan?.nama || "-"}`;
    worksheet.getCell("A7").value = "Kabupaten / Kota";
    worksheet.getCell("B7").value = `: ${namaKabkota?.nama || "-"}`;
    worksheet.getCell("A8").value = "Provinsi";
    worksheet.getCell("B8").value = `: ${namaProvinsi?.nama || "-"}`;
    worksheet.mergeCells("G4:H4");
    const headerLabel = worksheet.getCell("G4");
    headerLabel.border = {
      top: { style: "double", color: { argb: "00000000" } },
      left: { style: "double", color: { argb: "00000000" } },
      bottom: { style: "double", color: { argb: "00000000" } },
      right: { style: "double", color: { argb: "00000000" } },
    };
    headerLabel.value = "Format BOS K-2";
    worksheet.mergeCells("G5:H8");
    const bodyLabel = worksheet.getCell("G5");
    bodyLabel.border = {
      top: { style: "double", color: { argb: "00000000" } },
      left: { style: "double", color: { argb: "00000000" } },
      bottom: { style: "double", color: { argb: "00000000" } },
      right: { style: "double", color: { argb: "00000000" } },
    };
    bodyLabel.value =
      "Diisi oleh Bendahara Madrasah \n \n Disimpan di Madrasah";

    let dataTmp: any = [];
    dataTable.map((e, i) => {
      return dataTmp.push([
        i + 1,
        e.tglTrx ? moment(e.tglTrx).format("dddd,DD MMM YYYY HH:mm:ss") : "",
        e.noKode,
        e.noBukti,
        e.uraian,
        formatRupiah(e.debit),
        formatRupiah(e.credit),
        formatRupiah(e.saldo),
      ]);
    });

    worksheet.addTable({
      name: "Table",
      ref: "A10",
      headerRow: true,
      style: {
        showRowStripes: true,
      },
      columns: [
        { name: "No Urut" },
        { name: "Tanggal" },
        { name: "No Kode" },
        { name: "No Bukti" },
        { name: "Uraian" },
        { name: "Penerimaan (Debit)" },
        { name: "Pengeluaran (Kredit)" },
        { name: "Saldo" },
      ],
      rows: dataTmp,
    });

    let lastLine = 11 + dataTmp.length;
    worksheet.getCell(`A${lastLine}`).value = "Mengetahui";
    worksheet.getCell(`H${lastLine}`).value =
      moment().format("dddd, DD MMMM YYYY");
    worksheet.getCell(`A${lastLine + 2}`).value = "Kepala Madrasah";
    worksheet.getCell(`H${lastLine + 2}`).value = "Bendahara Madrasah";
    worksheet.getCell(`A${lastLine + 5}`).value =
      (dataMadrasah?.nama_kepala && `( ${dataMadrasah?.nama_kepala} )`) ||
      "(.................)";
    worksheet.getCell(`H${lastLine + 5}`).value =
      (dataMadrasah?.nama_bendahara && `( ${dataMadrasah?.nama_bendahara} )`) ||
      "(.................)";
    const excelBuffer = await ExcelJSWorkbook.xlsx.writeBuffer();
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, "Buku-Kas-Pembantu-Tunai" + fileExtension);
    notifAlert({
      type: "success",
      description: "Data berhasil di export",
    });
  };

  return (
    <>
      <BreadCrumb routes={itemBreadcrumb} title="Buku Pembantu Kas Tunai" />
      <FilterSumberDanaPeriode
        handleChangeValues={handleChangeValues}
        groupRole={groupRole}
        madrasah={tmpMadrasah}
        realisasiSumberDana={realisasiSumber}
      />
      <div className="m-5 p-5 bg-white shadow-md rounded">
        <div className="mb-4 flex justify-end">
          <div className="mr-4">
            <Space size={[8, 8]} wrap>
              <ButtonDropdownExport handleExportFile={handleExportFile} />
            </Space>
          </div>
          <InputSearch
            className="w-3/4"
            onChange={(e: any) => setSearch(e.currentTarget.value)}
          />
        </div>
        <div className="w-full">
          <Table
            sticky
            // rowKey={(record) => record.noNotaFormat}
            columns={columns}
            dataSource={dataTable}
            scroll={{ x: 1300 }}
            bordered
            loading={loading}
            // summary={() =>
            //   tmpData && (
            //     <Table.Summary fixed="top">
            //       <Table.Summary.Row style={{ backgroundColor: `#f1f1f1` }}>
            //         <Table.Summary.Cell index={0} colSpan={5}>
            //           <strong>Saldo Awal</strong>
            //         </Table.Summary.Cell>
            //         <Table.Summary.Cell index={2}>-</Table.Summary.Cell>
            //         <Table.Summary.Cell index={3}>-</Table.Summary.Cell>
            //         <Table.Summary.Cell index={4} align="right">
            //           {formatRupiah(tmpData?.saldoAwal)}
            //         </Table.Summary.Cell>
            //       </Table.Summary.Row>
            //     </Table.Summary>
            //   )
            // }
            pagination={{
              total: totalDataTable,
              position: ["bottomRight"],
              defaultPageSize: pageSize,
              defaultCurrent: page,
              pageSizeOptions: ["10", "25", "50", "100"],
              showSizeChanger: true,
              pageSize: pageSize,
              showTotal: (total) => `Total ${total} items`,
              onChange(page, pageSize) {
                setPage(page);
                setPageSize(pageSize);
              },
            }}
          />
        </div>
      </div>
    </>
  );
};

export default BukuKasPembantu;
