/**
 * eslint-disable jsx-a11y/anchor-is-valid
 *
 * @format
 */

/** @format */

import { useState, useEffect } from "react";
import { BreadCrumb } from "../../../../components";
import {
  Select,
  Modal,
  Space,
  Button,
  Form,
  Input,
  Switch,
  Tag,
  Table,
} from "antd";
import { useSelector } from "react-redux";
import { uuidv4 } from "../../../../utils/helper";
import {
  ButtonTableApproval,
  ButtonTableDelete,
  ButtonTableEdit,
  ButtonTableRoles,
  ButtonTambah,
} from "../../../../components/Button";
import notifAlert from "../../../../components/NotifAlert";
import InputSearch from "../../../../components/InputSearch";
import {
  deleteUsers,
  editUsers,
  postUsers,
} from "../../../../services/v2/usermanservice/managementservices";
import { useHistory } from "react-router-dom";
import { Pagination } from "antd";
import { getUsermanAllPage } from "../../../../services/v2/usermanservice/indexpage";

const Pusat = () => {
  const route = useHistory();
  const itemBreadcrumb = [
    { path: "/", breadcrumbName: "Home" },
    { path: "/", breadcrumbName: "Pengaturan" },
    { path: "/", breadcrumbName: "Managemen User" },
    { path: "/", breadcrumbName: "Pusat" },
  ];
  const [form] = Form.useForm();
  const [search, setSearch] = useState<any>(null);
  const store = useSelector((state: any) => state.store);
  const authTmp = useSelector((state: any) => state.auth);
  const auth = authTmp?.data || null;
  const kodeRole = auth?.kode_role || null;
  const tmpRole = store.role || [];
  const [isLoading, setLoading] = useState(false);
  const [openModalAction, setOpenModalAction] = useState(false);
  const [tableData, setTableData] = useState<any>([]);
  const [title, setTitle] = useState("");
  const [id, setID] = useState("");

  interface State {
    tableData: any[];
    currentPage: number;
    totalPages: number;
    pageSize: number;
  }

  const PAGE_SIZE = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const [totalElements, setTotalElements] = useState(0);

  const state: State = {
    tableData,
    currentPage,
    totalPages: totalElements,
    pageSize: PAGE_SIZE,
  };

  const getData = async (
    page?: number,
    size: number | null = null,
    _search?: string,
  ) => {
    setLoading(true);
    const res = await getUsermanAllPage("management-user/get-user-page", {
      activated: 1,
      group: "pusat",
      page: currentPage - 1,
      size: size !== null ? size : undefined,
      search: _search || search,
    });

    setTableData(res?.return || []);
    setTotalElements(res?.totalElements);
    setLoading(false);
  };

  const getDataReset = async (page?: number, size: number | null = null) => {
    setLoading(true);
    const res = await getUsermanAllPage("management-user/get-user-page", {
      activated: 1,
      group: "pusat",
      page: currentPage - 1,
      size: size !== null ? size : undefined,
    });

    setTableData(res.return || []);
    setTotalElements(res.totalElements);
    setLoading(false);
  };

  useEffect(() => {
    getData(0, PAGE_SIZE);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length >= 3) {
      setSearch(e.target.value);
      setCurrentPage(1);
      getData(0, PAGE_SIZE, e.target.value);
    } else if (e.target.value.length === 0) {
      setCurrentPage(1);
      getDataReset(0, PAGE_SIZE);
    }
  };

  const handlePageChange = async (pagex: number) => {
    setCurrentPage(pagex);
    // await getData(0, state.pageSize, search);
  };

  function paginationItemRender(current, type, originalElement) {
    if (type === "prev") {
      return (
        <button
          className="ant-pagination-item-link"
          type="button"
          onClick={() => handlePageChange(state.currentPage - 1)}>
          <span role="img" aria-label="left" className="anticon anticon-left">
            <svg
              viewBox="64 64 896 896"
              focusable="false"
              data-icon="left"
              width="1em"
              height="1em"
              fill="currentColor"
              aria-hidden="true">
              <path d="M724 218.3V141c0-6.7-7.7-10.4-12.9-6.3L260.3 486.8a31.86 31.86 0 000 50.3l450.8 352.1c5.3 4.1 12.9.4 12.9-6.3v-77.3c0-4.9-2.3-9.6-6.1-12.6l-360-281 360-281.1c3.8-3 6.1-7.7 6.1-12.6z"></path>
            </svg>
          </span>
        </button>
      );
    }
    if (type === "next") {
      return (
        <button
          className="ant-pagination-item-link"
          type="button"
          onClick={() => handlePageChange(state.currentPage + 1)}>
          <span role="img" aria-label="left" className="anticon anticon-right">
            <svg
              viewBox="64 64 896 896"
              focusable="false"
              data-icon="right"
              width="1em"
              height="1em"
              fill="currentColor"
              aria-hidden="true">
              <path d="M765.7 486.8L314.9 134.7A7.97 7.97 0 00302 141v77.3c0 4.9 2.3 9.6 6.1 12.6l360 281.1-360 281.1c-3.9 3-6.1 7.7-6.1 12.6V883c0 6.7 7.7 10.4 12.9 6.3l450.8-352.1a31.96 31.96 0 000-50.4z"></path>
            </svg>
          </span>
        </button>
      );
    }
    if (type === "page") {
      return (
        <button
          className={`pagination-button w-full ${
            current === state.currentPage ? "active" : ""
          }`}
          onClick={() => {
            handlePageChange(current);
          }}
          style={{
            backgroundColor: current === state.currentPage ? "green" : "",
            color: current === state.currentPage ? "white" : "",
          }}>
          {current}
        </button>
      );
    }
    return originalElement;
  }

  const dataTable = search
    ? tableData.filter((item: any) => {
        const val = search.toLowerCase();
        return (
          (item.kode_role !== null &&
            (item.kode_role || "").toLowerCase().includes(val)) ||
          (item.profile.nik !== null &&
            (item.profile.nik || "").toLowerCase().includes(val)) ||
          (item.profile.nama !== null &&
            (item.profile.nama || "").toLowerCase().includes(val)) ||
          (item.profile.user.email !== null &&
            (item.profile.user.email || "").toLowerCase().includes(val))
        );
      })
    : tableData;

  function handleEdit(record: any) {
    setOpenModalAction(true);
    setTitle("Edit");
    setID(record.id);
    form.setFieldsValue({
      kodeRole: record.kodeRole,
      nama: record.profile.nama,
      nik: record.profile.nik,
      status: record.profile.user.valid_email,
      email: record.profile.user.email,
    });
  }

  const handleTambah = () => {
    setOpenModalAction(true);
    setTitle("Tambah");
    setID(uuidv4());
    form.resetFields();
  };

  const handleDelete = async (record: any) => {
    setLoading(true);
    try {
      await deleteUsers(record.id);
      getData(0, PAGE_SIZE);
      notifAlert({ type: "success", description: "Data berhasil dihapus" });
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const handleAktif = async (record: any) => {
    setLoading(true);
    try {
      await deleteUsers(record.id);
      getData(0, PAGE_SIZE);
      notifAlert({ type: "success", description: "Data berhasil aktif" });
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const handleSave = async (values: any) => {
    setLoading(true);
    let payload: any = {
      nik: values.nik,
      password: values.password,
      email: values.email,
      nama: values.nama,
      activated: 1,
      kode_role: values.kodeRole,
      require_email: values.status ? 1 : 0,
    };
    if (title === "Tambah") {
      try {
        await postUsers(payload);
        getData(0, PAGE_SIZE);
        notifAlert({ type: "success", description: "Data berhasil disimpan" });
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        await editUsers(id, payload);
        getData(0, PAGE_SIZE);
        notifAlert({ type: "success", description: "Data berhasil disimpan" });
      } catch (error) {
        console.log(error);
      }
    }

    handleReset();
    setLoading(false);
  };

  const handleReset = () => {
    form.resetFields();
    setOpenModalAction(false);
  };

  const columns: any = [
    {
      title: "Nama",
      key: "nama",
      width: 200,
      onFilter: (value, record) => record.profile.nama.indexOf(value) === 0,
      sorter: (a, b) => a.profile?.nama - b.profile?.nama,
      render: (record) => record.profile?.nama,
    },
    {
      title: "Username",
      key: "nik",
      width: 200,
      onFilter: (value, record) => record.profile.nik.indexOf(value) === 0,
      sorter: (a, b) => a.profile?.nik - b.profile?.nik,
      render: (record) => record.profile?.nik,
    },
    {
      title: "Email",
      key: "email",
      width: 300,
      onFilter: (value, record) =>
        record.profile.user.email.indexOf(value) === 0,
      sorter: (a, b) => a.profile?.user?.email - b.profile?.user?.email,
      render: (record) => record.profile?.user?.email,
    },
    {
      title: "Role",
      key: "kode_role",
      dataIndex: "role",
      width: 190,
      render: (role) =>
        (role.length &&
          role.map((item) => {
            return <Tag>{item.nama || "-"}</Tag>;
          })) ||
        "-",
    },
    {
      title: "Validasi Email",
      key: "validEmail",
      width: 100,
      render: (record) =>
        record?.profile?.user?.valid_email ? (
          <Tag color="#87d068">Aktif</Tag>
        ) : (
          <Tag>Tidak Aktif</Tag>
        ),
    },
    {
      title: "Status",
      key: "status",
      fixed: "right",
      width: 100,
      filters: [
        {
          text: "Aktif",
          value: 1,
        },
        {
          text: "Tidak Aktif",
          value: 0,
        },
      ],
      onFilter: (value: string, record) =>
        record?.profile?.user?.activated === value,
      render: (record) =>
        record?.profile?.user?.activated ? (
          <Tag color="#87d068">Aktif</Tag>
        ) : (
          <Tag>Tidak Aktif</Tag>
        ),
    },
    {
      title: "Aksi",
      fixed: "right",
      width: 140,
      render: (record) => {
        return (
          (record?.userId === auth?.user_id ||
            kodeRole === "super_admin_pusat") && (
            <Space size="small">
              <ButtonTableEdit onClick={() => handleEdit(record)} />
              <ButtonTableRoles
                disabled={
                  (record?.kodeRole === "admin_pusat_bos" && true) || false
                }
                onClick={() =>
                  route.push({
                    pathname: `pusat/role/${record.id}`,
                    state: record,
                  })
                }
              />
              {record?.profile?.user?.activated ? (
                <ButtonTableDelete
                  onClick={() => {
                    Modal.confirm({
                      title: "Perhatian",
                      content: "Yakin Hapus Data?",
                      onOk() {
                        return handleDelete(record);
                      },
                      onCancel() {},
                      okText: "Hapus",
                      cancelText: "Batal",
                      okType: "danger",
                    });
                  }}
                />
              ) : (
                <ButtonTableApproval
                  tooltips="Aktif"
                  onClick={() => {
                    Modal.confirm({
                      title: "Perhatian",
                      content: "Yakin Mengaktifkan Data Akun?",
                      onOk() {
                        return handleAktif(record);
                      },
                      onCancel() {},
                      okText: "Aktif",
                      cancelText: "Batal",
                      okType: "primary",
                    });
                  }}
                />
              )}
            </Space>
          )
        );
      },
    },
  ];

  // const handleTableChange = (pagination, filters, sorter, extra) => {
  //   console.log("params", pagination, filters, sorter, extra);
  // };

  return (
    <>
      <BreadCrumb routes={itemBreadcrumb} title="Pusat" />
      <div className="m-5 p-5 bg-white shadow-md rounded">
        <div className="mb-4 flex justify-end">
          {kodeRole === "super_admin_pusat" && (
            <div className="mr-4">
              <ButtonTambah onClick={handleTambah} />
            </div>
          )}

          <InputSearch className="w-3/4" onChange={handleChange} />
        </div>

        <div className="w-full mb-8">
          <Table
            rowKey={(record) => record.id}
            columns={columns}
            // onChange={handleTableChange}
            dataSource={dataTable}
            bordered
            loading={isLoading}
            scroll={{ x: "1300" }}
            pagination={false}
          />
        </div>
        <div className="w-full flex justify-end">
          <Pagination
            total={totalElements}
            pageSize={10}
            itemRender={paginationItemRender}
            showSizeChanger={false}
          />
        </div>
      </div>
      <Modal
        open={openModalAction}
        title={`${title} Pusat`}
        onCancel={handleReset}
        footer={[
          <Button key="back" onClick={handleReset}>
            Batal
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={isLoading}
            onClick={() => form.submit()}>
            Simpan
          </Button>,
        ]}>
        <Form
          form={form}
          key="formRekeningBelanja"
          name="formRekeningBelanja"
          layout="vertical"
          onFinish={handleSave}>
          <Form.Item
            label="Role"
            name="kodeRole"
            rules={[{ required: true, message: "Role tidak boleh kosong!" }]}>
            <Select
              showSearch
              optionFilterProp="children"
              placeholder="Pilih Role">
              {tmpRole?.length &&
                tmpRole.map((e: any, i: any) => {
                  return (
                    <Select.Option key={`role${i}`} value={e.kode}>
                      {e.nama}
                    </Select.Option>
                  );
                })}
            </Select>
          </Form.Item>
          <Form.Item
            label="Nama"
            name="nama"
            rules={[
              {
                required: true,
                message: "Nama tidak boleh kosong!",
              },
            ]}>
            <Input placeholder="Nama" />
          </Form.Item>
          <Form.Item
            label="NIK"
            name="nik"
            rules={[
              {
                required: true,
                message: "NIK tidak boleh kosong!",
              },
            ]}>
            <Input placeholder="NIK" />
          </Form.Item>
          <Form.Item
            label="Email"
            name="email"
            rules={[
              {
                type: "email",
                message: "Email tidak valid!",
              },
              {
                required: true,
                message: "Email tidak boleh kosong!",
              },
            ]}>
            <Input type="email" placeholder="Email" />
          </Form.Item>
          <Form.Item
            label="Password"
            name="password"
            rules={[
              {
                required: true,
                message: "Password tidak boleh kosong!",
              },
              {
                validator: (_, value) =>
                  value && value.length >= 6
                    ? Promise.resolve()
                    : Promise.reject("Password minimal 6 karakter"),
              },
            ]}>
            <Input.Password placeholder="Password" />
          </Form.Item>
          <Form.Item label="Status" name="status" valuePropName="checked">
            <Switch />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default Pusat;
