/** @format */

import axios from "axios";
import notifAlert from "../../../components/NotifAlert";

export const baseURL: string =
  window.REACT_APP_API_URL_BIMTEK ||
  "https://gw-services.bimtekedmerkamkemenag.com/erkam/riil";
export const apiKey: string =
  window.REACT_APP_BIMTEK_KEY || "ede45fb5-2f81-48e2-a457-542c77119b6f";
const apiBimtek = axios.create({
  baseURL:
    window.REACT_APP_API_URL_BIMTEK ||
    "https://gw-services.bimtekedmerkamkemenag.com/erkam/riil",
  withCredentials: false,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

// request interceptor
apiBimtek.interceptors.request.use(
  (config) => {
    // const token = localStorage.getItem("auth");
    // if (token) {
    //   config.headers["Authorization"] = "Bearer " + JSON.parse(token).token;
    // }
    return config;
  },
  (error) => {
    // console.log(error);
    if (error.response.status === 400) {
      return error.response.message;
    }
    return Promise.reject(error);
  },
);

// response interceptor
apiBimtek.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 400) {
      notifAlert({
        type: "warning",
        description: `${error.response.data.message}`,
        // description: `${error.response.data.message} untuk peserta bimtek`,
      });
    }

    if (error.response.status === 500) {
      notifAlert({
        type: "error",
        description: error.response.data.return || "Maaf server lagi error!!",
      });
    }

    return Promise.reject(error);
  },
);

export const getCheckUserBimtex = async (params?: any) => {
  try {
    const response = await apiBimtek.get<any>(
      `/cek-status-akun?api-key=${apiKey}`,
      {
        params,
      },
    );
    // console.log(response);
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const checkStatusClassBimtex = async (params?: any) => {
  try {
    const response = await apiBimtek.post<any>(
      `/cek-status-kelas?api-key=${apiKey}`,
      params,
    );
    console.log(response);
    return response;
  } catch (error) {
    console.log(error);
  }
};
