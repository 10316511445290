/** @format */

import React, { useState, useEffect } from "react";
import { BreadCrumb } from "../../../../components";
import {
  Table,
  Select,
  Modal,
  Space,
  Button,
  Form,
  Input,
  Switch,
  Tag,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { uuidv4 } from "../../../../utils/helper";
import {
  ButtonTableApproval,
  ButtonTableDelete,
  ButtonTableEdit,
  ButtonTableRoles,
  ButtonTambah,
} from "../../../../components/Button";
import notifAlert from "../../../../components/NotifAlert";
import InputSearch from "../../../../components/InputSearch";
import { getUsermanAll } from "../../../../services/v2/usermanservice";
import {
  deleteUsers,
  editUsers,
  postUsers,
} from "../../../../services/v2/usermanservice/managementservices";
import { getMadrasah } from "../../../../services/v2/usermanservice/madrasahservices";
import { useHistory } from "react-router-dom";
import { setStore } from "../../../../redux/actions";
import { Pagination } from "antd";
import { getUsermanAllPage } from "../../../../services/v2/usermanservice/indexpage";

const Madrasah = () => {
  const route = useHistory();
  const itemBreadcrumb = [
    { path: "/", breadcrumbName: "Home" },
    { path: "/", breadcrumbName: "Pengaturan" },
    { path: "/", breadcrumbName: "Managemen User" },
    { path: "/", breadcrumbName: "Madrasah" },
  ];

  const [form] = Form.useForm();
  const [search, setSearch] = useState<any>(null);
  const store = useSelector((state: any) => state.store);
  const authTmp = useSelector((state: any) => state.auth);
  const auth = authTmp?.data || null;
  const kodeRole = auth?.kode_role || null;
  const roleAkses = [
    "admin_pusat",
    "admin_provinsi",
    "admin_kabkota",
    "super_admin_pusat",
  ];
  // const profile = store?.profile || null;
  const groupRole = auth?.group_role || null;
  const refProvinsi = store.provinsi || [];
  const refKabkota = store.kabkota || [];
  const [tmpRole, setTmpRole] = useState<any>([]);
  const [isLoading, setLoading] = useState(false);
  const [openModalAction, setOpenModalAction] = useState(false);
  const [tableData, setTableData] = useState<any>([]);
  const [madrasah, setMadrasah] = useState<any>([]);
  const [provinsi, setProvinsi] = useState<any>(null);
  const [kabkota, setKabkota] = useState<any>(null);
  const [title, setTitle] = useState("");
  const [id, setID] = useState("");
  const dispatch = useDispatch();
  const tmpListMadrasah = store?.listMadrasah || [];

  interface State {
    tableData: any[];
    currentPage: number;
    totalPages: number;
    pageSize: number;
  }

  const PAGE_SIZE = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const [totalElements, setTotalElements] = useState(0);

  const state: State = {
    tableData,
    currentPage,
    totalPages: totalElements,
    pageSize: PAGE_SIZE,
  };

  const dataTable = search
    ? tableData.filter((item: any) => {
        const val = search.toLowerCase();
        return (
          (item?.kode_role !== null &&
            (item?.kode_role || "").toLowerCase().includes(val)) ||
          (item?.profile?.nik !== null &&
            (item?.profile?.nik || "").toLowerCase().includes(val)) ||
          (item?.profile?.nama !== null &&
            (item?.profile?.nama || "").toLowerCase().includes(val)) ||
          (item?.profile?.nama !== null &&
            (item?.profile?.nama || "").toLowerCase().includes(val)) ||
          (item?.madrasah?.nama !== null &&
            (item?.madrasah?.nama || "").toLowerCase().includes(val)) ||
          (item?.madrasah?.nsm !== null &&
            (item?.madrasah?.nsm || "").toLowerCase().includes(val))
        );
      })
    : tableData;

  const getData = async (
    page?: number,
    size: number | null = null,
    _search?: string,
  ) => {
    setLoading(true);
    const res = await getUsermanAllPage("management-user/get-user-page", {
      activated: 1,
      group: "madrasah",
      page: currentPage - 1,
      size: size !== null ? size : undefined,
      search: _search || search,
    });

    if (tmpListMadrasah.length === 0) {
      let filter: any = "";
      if (groupRole === "provinsi") {
        filter = `?kodeProvinsi=${auth?.kode_provinsi}`;
      }
      if (groupRole === "kabkota") {
        filter = `?kodeProvinsi=${auth?.kode_provinsi}&kode_kabkota=${auth?.kode_kabkota}`;
      }
      const madrasah = await getMadrasah(filter);
      const listMadrasah = madrasah || [];
      setTimeout(() => {
        dispatch(setStore({ listMadrasah }));
      }, 100);
      setMadrasah(madrasah);
    } else {
      setMadrasah(tmpListMadrasah);
    }
    const refRole = await getUsermanAll("role", {
      activated: 1,
      group: "madrasah",
    });
    setTmpRole(refRole || []);
    setTableData(res.return || []);
    setTotalElements(res.totalElements);

    setLoading(false);
  };

  const getDataReset = async (page?: number, size: number | null = null) => {
    setLoading(true);
    const res = await getUsermanAllPage("management-user/get-user-page", {
      activated: 1,
      group: "madrasah",
      page: currentPage - 1,
      size: size !== null ? size : undefined,
    });

    if (tmpListMadrasah.length === 0) {
      let filter: any = "";
      if (groupRole === "provinsi") {
        filter = `?kodeProvinsi=${auth?.kode_provinsi}`;
      }
      if (groupRole === "kabkota") {
        filter = `?kodeProvinsi=${auth?.kode_provinsi}&kode_kabkota=${auth?.kode_kabkota}`;
      }
      const madrasah = await getMadrasah(filter);
      const listMadrasah = madrasah || [];
      setTimeout(() => {
        dispatch(setStore({ listMadrasah }));
      }, 100);
      setMadrasah(madrasah);
    } else {
      setMadrasah(tmpListMadrasah);
    }
    const refRole = await getUsermanAll("role", {
      activated: 1,
      group: "madrasah",
    });
    setTmpRole(refRole || []);
    setTableData(res.return || []);
    setTotalElements(res.totalElements);

    setLoading(false);
  };

  useEffect(() => {
    getData(0, PAGE_SIZE);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length >= 3) {
      setSearch(e.target.value);
      setCurrentPage(1);
      getData(0, PAGE_SIZE, e.target.value);
    }
    if (e.target.value.length === 0) {
      setSearch(e.target.value);
      setCurrentPage(1);
      getDataReset(0, PAGE_SIZE);
    }
  };

  const handlePageChange = async (pagex: number) => {
    setCurrentPage(pagex);
    // await getData(0, state.pageSize, search);
  };

  function paginationItemRender(current, type, originalElement) {
    if (type === "prev") {
      return (
        <button
          className="ant-pagination-item-link"
          type="button"
          onClick={() => handlePageChange(state.currentPage - 1)}>
          <span role="img" aria-label="left" className="anticon anticon-left">
            <svg
              viewBox="64 64 896 896"
              focusable="false"
              data-icon="left"
              width="1em"
              height="1em"
              fill="currentColor"
              aria-hidden="true">
              <path d="M724 218.3V141c0-6.7-7.7-10.4-12.9-6.3L260.3 486.8a31.86 31.86 0 000 50.3l450.8 352.1c5.3 4.1 12.9.4 12.9-6.3v-77.3c0-4.9-2.3-9.6-6.1-12.6l-360-281 360-281.1c3.8-3 6.1-7.7 6.1-12.6z"></path>
            </svg>
          </span>
        </button>
      );
    }
    if (type === "next") {
      return (
        <button
          className="ant-pagination-item-link"
          type="button"
          onClick={() => handlePageChange(state.currentPage + 1)}>
          <span role="img" aria-label="left" className="anticon anticon-right">
            <svg
              viewBox="64 64 896 896"
              focusable="false"
              data-icon="right"
              width="1em"
              height="1em"
              fill="currentColor"
              aria-hidden="true">
              <path d="M765.7 486.8L314.9 134.7A7.97 7.97 0 00302 141v77.3c0 4.9 2.3 9.6 6.1 12.6l360 281.1-360 281.1c-3.9 3-6.1 7.7-6.1 12.6V883c0 6.7 7.7 10.4 12.9 6.3l450.8-352.1a31.96 31.96 0 000-50.4z"></path>
            </svg>
          </span>
        </button>
      );
    }
    if (type === "page") {
      return (
        <button
          className={`pagination-button w-full ${
            current === state.currentPage ? "active" : ""
          }`}
          onClick={() => handlePageChange(current)}
          style={{
            backgroundColor: current === state.currentPage ? "green" : "",
            color: current === state.currentPage ? "white" : "",
          }}>
          {current}
        </button>
      );
    }
    return originalElement;
  }

  function handleEdit(record: any) {
    setOpenModalAction(true);
    setTitle("Edit");
    setID(record.id);
    setProvinsi(record.madrasah.kode_provinsi);
    setKabkota(record.madrasah.kode_kabkota);
    form.setFieldsValue({
      kodeRole: record.kodeRole,
      nama: record.profile.nama,
      nik: record.profile.nik,
      status: record.profile.user.valid_email,
      email: record.profile.user.email,
      madrasah: record.madrasah.id,
      kabkota: record.madrasah.kode_kabkota,
      provinsi: record.madrasah.kode_provinsi,
    });
  }

  const handleTambah = () => {
    setOpenModalAction(true);
    setTitle("Tambah");
    setID(uuidv4());
    form.resetFields();
  };

  const handleDelete = async (record: any) => {
    setLoading(true);
    try {
      await deleteUsers(record.id);
      getData(0, PAGE_SIZE);
      notifAlert({ type: "success", description: "Data berhasil dihapus" });
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const handleAktif = async (record: any) => {
    setLoading(true);
    try {
      await deleteUsers(record.id);
      getData(0, PAGE_SIZE);
      notifAlert({ type: "success", description: "Data berhasil aktif" });
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const handleSave = async (values: any) => {
    setLoading(true);
    const tmpMadrasah =
      madrasah.find((item) => item.id.includes(values.madrasah)) || null;
    let payload: any = {
      nik: values.nik,
      password: values.password,
      email: values.email,
      nama: values.nama,
      activated: 1,
      kode_role: values.kodeRole,
      kode_kabkota: values.kabkota,
      kode_provinsi: values.provinsi,
      require_email: values.status ? 1 : 0,
      madrasah_id: tmpMadrasah && tmpMadrasah.id,
      kantor_kabkota_id: tmpMadrasah && tmpMadrasah.kantor_kabkota_id,
      kantor_provinsi_id: tmpMadrasah && tmpMadrasah.kantor_provinsi_id,
      kantor_pusat_id: tmpMadrasah && tmpMadrasah.kantor_pusat_id,
    };
    if (title === "Tambah") {
      try {
        await postUsers(payload);
        notifAlert({ type: "success", description: "Data berhasil disimpan" });
        getData(0, PAGE_SIZE);
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        await editUsers(id, payload);
        getData(0, PAGE_SIZE);
        notifAlert({ type: "success", description: "Data berhasil disimpan" });
      } catch (error) {
        console.log(error);
      }
    }

    handleReset();
    setLoading(false);
  };

  const handleReset = () => {
    setProvinsi(null);
    setKabkota(null);
    form.resetFields();
    setOpenModalAction(false);
  };

  const columns: any = [
    {
      title: "NSM",
      key: "provinsi",
      width: 200,
      onFilter: (value, record) => record?.madrasah?.nsm.indexOf(value) === 0,
      sorter: (a, b) => a.madrasah?.nsm - b.madrasah?.nsm,
      render: (record) => record.madrasah?.nsm,
    },
    {
      title: "Madrasah",
      key: "provinsi",
      width: 200,
      onFilter: (value, record) => record?.madrasah?.nama.indexOf(value) === 0,
      sorter: (a, b) => a.madrasah?.nama - b.madrasah?.nama,
      render: (record) => record.madrasah?.nama,
    },
    {
      title: "Nama",
      key: "nama",
      width: 200,
      onFilter: (value, record) => record.profile.nama.indexOf(value) === 0,
      sorter: (a, b) => a.profile?.nama - b.profile?.nama,
      render: (record) => record.profile?.nama,
    },
    {
      title: "Username",
      key: "nik",
      width: 200,
      onFilter: (value, record) => record.profile.nik.indexOf(value) === 0,
      sorter: (a, b) => a.profile?.nik - b.profile?.nik,
      render: (record) => record.profile?.nik,
    },
    {
      title: "Email",
      key: "email",
      width: 300,
      onFilter: (value, record) =>
        record.profile.user.email.indexOf(value) === 0,
      sorter: (a, b) => a.profile?.user?.email - b.profile?.user?.email,
      render: (record) => record.profile?.user?.email,
    },
    {
      title: "Role",
      key: "kode_role",
      dataIndex: "role",
      width: 180,
      render: (role) =>
        (role.length &&
          role.map((item) => {
            return <Tag>{item?.nama || "-"}</Tag>;
          })) ||
        "-",
    },
    {
      title: "Validasi Email",
      // dataIndex: "activated",
      key: "validEmail",
      width: 100,
      render: (record) =>
        record?.profile?.user?.valid_email ? (
          <Tag color="#87d068">Aktif</Tag>
        ) : (
          <Tag>Tidak Aktif</Tag>
        ),
    },
    {
      title: "Status",
      key: "status",
      fixed: "right",
      width: 100,
      filters: [
        {
          text: "Aktif",
          value: 1,
        },
        {
          text: "Tidak Aktif",
          value: 0,
        },
      ],
      onFilter: (value: string, record) =>
        record?.profile?.user?.activated === value,
      render: (record) =>
        record?.profile?.user?.activated ? (
          <Tag color="#87d068">Aktif</Tag>
        ) : (
          <Tag>Tidak Aktif</Tag>
        ),
    },
    {
      title: "Aksi",
      fixed: "right",
      width: 140,
      render: (record) => {
        let listRole: any = record?.role || [];
        let disabledButton =
          listRole.find((item) =>
            ["pusat", "provinsi", "kabkota"].includes(item.group),
          ) || false;
        return (
          <Space size="small">
            <ButtonTableEdit
              disabled={disabledButton}
              onClick={() => handleEdit(record)}
            />
            <ButtonTableRoles
              disabled={disabledButton}
              onClick={() =>
                route.push({
                  pathname: `madrasah/role/${record.id}`,
                  state: record,
                })
              }
            />
            {record?.profile?.user?.activated ? (
              <ButtonTableDelete
                disabled={disabledButton}
                onClick={() => {
                  Modal.confirm({
                    title: "Perhatian",
                    content: "Yakin Hapus Data?",
                    onOk() {
                      return handleDelete(record);
                    },
                    onCancel() {},
                    okText: "Hapus",
                    cancelText: "Batal",
                    okType: "danger",
                  });
                }}
              />
            ) : (
              <ButtonTableApproval
                disabled={disabledButton}
                tooltips="Aktif"
                onClick={() => {
                  Modal.confirm({
                    title: "Perhatian",
                    content: "Yakin Mengaktifkan Data Akun?",
                    onOk() {
                      return handleAktif(record);
                    },
                    onCancel() {},
                    okText: "Aktif",
                    cancelText: "Batal",
                    okType: "primary",
                  });
                }}
              />
            )}
          </Space>
        );
      },
    },
  ];

  // kodeRole !== "admin_pusat" &&
  //   kodeRole !== "super_admin_pusat" &&
  //   delete columns[7];
  !roleAkses.includes(kodeRole) && delete columns[7];
  const handleChange = (values) => {
    for (let obj in values) {
      switch (obj) {
        case "provinsi":
          setProvinsi(values.provinsi);
          setKabkota(null);
          form.setFieldsValue({ madrasah: null, kabkota: null });
          break;
        case "kabkota":
          setKabkota(values.kabkota);
          form.setFieldsValue({ madrasah: null });
          break;
      }
    }
  };

  return (
    <>
      <BreadCrumb routes={itemBreadcrumb} title="Madrasah" />
      <div className="m-5 p-5 bg-white shadow-md rounded">
        <div className="mb-4 flex justify-end">
          {roleAkses.includes(kodeRole) && (
            <div className="mr-4">
              <ButtonTambah onClick={handleTambah} loading={isLoading} />
            </div>
          )}
          <InputSearch className="w-3/4" onChange={handleSearch} />
        </div>
        <div className="w-full mb-8">
          <Table
            rowKey={(record) => record.id}
            columns={columns}
            dataSource={dataTable}
            bordered
            loading={isLoading}
            scroll={{ x: "1300" }}
            pagination={false}
          />
        </div>
        <div className="w-full flex justify-end">
          <></>
          <Pagination
            total={totalElements}
            pageSize={10}
            itemRender={paginationItemRender}
            showSizeChanger={false}
          />
        </div>
      </div>
      <Modal
        open={openModalAction}
        width={850}
        title={`${title} Madrasah`}
        onCancel={handleReset}
        footer={[
          <Button key="back" onClick={handleReset}>
            Batal
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={isLoading}
            onClick={() => form.submit()}>
            Simpan
          </Button>,
        ]}>
        <Form
          form={form}
          key="formRekeningBelanja"
          name="formRekeningBelanja"
          layout="vertical"
          onValuesChange={handleChange}
          onFinish={handleSave}>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div>
              <Form.Item
                label="Role"
                name="kodeRole"
                rules={[
                  { required: true, message: "Role tidak boleh kosong!" },
                ]}>
                <Select
                  showSearch
                  optionFilterProp="children"
                  placeholder="Pilih Role">
                  {tmpRole?.length &&
                    tmpRole.map((e: any, i: any) => {
                      return (
                        <Select.Option key={`role${i}`} value={e.kode}>
                          {e.nama}
                        </Select.Option>
                      );
                    })}
                </Select>
              </Form.Item>
            </div>
            <div>
              <Form.Item
                label="Nama"
                name="nama"
                rules={[
                  {
                    required: true,
                    message: "Nama tidak boleh kosong!",
                  },
                ]}>
                <Input placeholder="Nama" />
              </Form.Item>
            </div>
            <div>
              <Form.Item
                label="NIK"
                name="nik"
                rules={[
                  {
                    required: true,
                    message: "NIK tidak boleh kosong!",
                  },
                ]}>
                <Input type="number" placeholder="NIK" />
              </Form.Item>
            </div>
            <div>
              <Form.Item
                label="Email"
                name="email"
                rules={[
                  {
                    type: "email",
                    message: "Email tidak valid!",
                  },
                  {
                    required: true,
                    message: "Email tidak boleh kosong!",
                  },
                ]}>
                <Input type="email" placeholder="Email" />
              </Form.Item>
            </div>
            <div>
              <Form.Item
                label="Provinsi"
                name="provinsi"
                rules={[
                  { required: true, message: "Provinsi tidak boleh kosong!" },
                ]}>
                <Select
                  showSearch
                  optionFilterProp="children"
                  placeholder="Pilih Provinsi">
                  {refProvinsi?.length &&
                    refProvinsi.map((e: any, i: any) => {
                      return (
                        <Select.Option key={`prov${i}`} value={e.kode}>
                          {e.nama}
                        </Select.Option>
                      );
                    })}
                </Select>
              </Form.Item>
            </div>
            <div>
              <Form.Item
                label="Kab / Kota"
                name="kabkota"
                rules={[
                  { required: true, message: "Kab / Kota tidak boleh kosong!" },
                ]}>
                <Select
                  showSearch
                  optionFilterProp="children"
                  placeholder="Pilih Kab / Kota">
                  {provinsi &&
                    refKabkota?.length &&
                    refKabkota
                      .filter((item) => item.kode_provinsi === provinsi)
                      .map((e: any, i: any) => {
                        return (
                          <Select.Option key={`role${i}`} value={e.kode}>
                            {e.nama}
                          </Select.Option>
                        );
                      })}
                </Select>
              </Form.Item>
            </div>
            <div>
              <Form.Item
                label="Madrasah"
                name="madrasah"
                rules={[
                  { required: true, message: "Madrasah tidak boleh kosong!" },
                ]}>
                <Select
                  showSearch
                  optionFilterProp="children"
                  placeholder="Pilih Madrasah">
                  {provinsi &&
                    kabkota &&
                    madrasah?.length &&
                    madrasah
                      .filter(
                        (item) =>
                          item.kode_kabkota === kabkota &&
                          item.kode_provinsi === provinsi,
                      )
                      .map((e: any, i: any) => {
                        return (
                          <Select.Option key={`role${i}`} value={e.id}>
                            {e.nama} | {e.nsm}
                          </Select.Option>
                        );
                      })}
                </Select>
              </Form.Item>
            </div>
            <div>
              <Form.Item
                label="Password"
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Password tidak boleh kosong!",
                  },
                  {
                    validator: (_, value) =>
                      value && value.length >= 6
                        ? Promise.resolve()
                        : Promise.reject("Password minimal 6 karakter"),
                  },
                ]}>
                <Input.Password placeholder="Password" />
              </Form.Item>
            </div>
          </div>
          <Form.Item
            label="Memerlukan email valid?"
            name="status"
            valuePropName="checked">
            <Switch />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default Madrasah;
