/** @format */

import React, { useState, useEffect } from "react";
import { BreadCrumb } from "../../../../components";
import {
  Table,
  Select,
  Modal,
  Space,
  Button,
  Form,
  Input,
  Switch,
  Tag,
  Pagination,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { uuidv4 } from "../../../../utils/helper";
import {
  ButtonDropdownActive,
  ButtonTableDelete,
  ButtonTableEdit,
  ButtonTableRoles,
  ButtonTambah,
} from "../../../../components/Button";
import notifAlert from "../../../../components/NotifAlert";
import { setStore } from "../../../../redux/actions";
import InputSearch from "../../../../components/InputSearch";
import { getUsermanAll } from "../../../../services/v2/usermanservice";
import {
  deletePengawas,
  editPengawas,
  // editUsers,
  postPengawas,
} from "../../../../services/v2/usermanservice/managementservices";
import { useHistory } from "react-router-dom";
import { getMadrasah } from "../../../../services/v2/usermanservice/madrasahservices";
import { getUsermanAllPage } from "../../../../services/v2/usermanservice/indexpage";

const Pengawas = () => {
  const route = useHistory();
  const itemBreadcrumb = [
    { path: "/", breadcrumbName: "Home" },
    { path: "/", breadcrumbName: "Pengaturan" },
    { path: "/", breadcrumbName: "Managemen User" },
    { path: "/", breadcrumbName: "Pengawas" },
  ];
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [search, setSearch] = useState<any>(null);
  const store = useSelector((state: any) => state.store);
  const authTmp = useSelector((state: any) => state.auth);
  const auth = authTmp?.data || null;
  const profile = store?.profile || null;
  const groupRole = auth?.group_role || null;
  const kodeRole = auth?.kode_role || null;
  const [tmpRole, setTmpRole] = useState<any>([]);
  const [openModalAction, setOpenModalAction] = useState(false);
  const [dataUser, setDataUser] = useState<any>(null);
  const [tableData, setTableData] = useState<any>([]);
  const refProvinsi = store?.provinsi || [];
  const refKabkota = store?.kabkota || [];
  const [madrasah, setMadrasah] = useState<any>([]);
  const [title, setTitle] = useState("");
  const [id, setID] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState<any>([]);
  const [selectedDataPengawas, setSelectedDataPengawas] = useState<any>([]);
  const tmpListMadrasah = store?.listMadrasah || [];
  const columns: any = [
    {
      title: "Kab / Kota",
      key: "kodeKabkota",
      dataIndex: "kodeKabkota",
      width: 200,
      render: (kodeKabkota) =>
        refKabkota.find((item) => item?.kode === kodeKabkota)?.nama || "-",
    },
    {
      title: "Nama",
      key: "nama",
      width: 200,
      onFilter: (value, record) => record?.nama.indexOf(value) === 0,
      sorter: (a, b) => a?.profile?.nama - b?.profile?.nama,
      render: (record) => record?.profile?.nama,
    },
    {
      title: "Username",
      key: "nik",
      width: 200,
      onFilter: (value, record) => record.nik.indexOf(value) === 0,
      sorter: (a, b) => a?.profile?.nik - b?.profile?.nik,
      render: (record) => record?.profile?.nik,
    },
    {
      title: "Email",
      key: "email",
      width: 300,
      onFilter: (value, record) => record?.email.indexOf(value) === 0,
      sorter: (a, b) => a?.profile?.user.email - b?.profile?.user.email,
      render: (record) => record?.profile?.user.email,
    },
    {
      title: "Madrasah",
      key: "madrasah",
      dataIndex: "listMadrasahIdPengawas",
      width: 230,
      render: (listMadrasahIdPengawas) => {
        return (
          listMadrasahIdPengawas?.length &&
          listMadrasahIdPengawas?.map((item) => {
            let namaMadrasah =
              tmpListMadrasah.find((items) => items.id.includes(item?.id)) ||
              null;
            return (
              namaMadrasah?.nama && (
                <Tag
                  color={
                    (namaMadrasah?.jenjang?.kode === "ra" && "purple") ||
                    (namaMadrasah?.jenjang?.kode === "ma" && "geekblue") ||
                    (namaMadrasah?.jenjang?.kode === "mts" && "blue") ||
                    (namaMadrasah?.jenjang?.kode === "mi" && "cyan") ||
                    "blue"
                  }>
                  {namaMadrasah.nama}
                </Tag>
              )
            );
          })
        );
      },
    },
    {
      title: "Role",
      key: "kodeRole",
      dataIndex: "kodeRole",
      width: 230,
      render: (kodeRole) =>
        tmpRole.find((items) => items.kode === kodeRole)?.nama || "-",
    },
    {
      title: "Valid Email",
      key: "validEmail",
      width: 80,
      render: (record) =>
        record?.profile?.requireEmail === 1 ? (
          <Tag color="#87d068">Aktif</Tag>
        ) : (
          <Tag>Tidak Aktif</Tag>
        ),
    },
    {
      title: "Validasi Email",
      key: "validEmail",
      width: 100,
      render: (record) =>
        record?.profile?.user?.valid_email ? (
          <Tag color="#87d068">Aktif</Tag>
        ) : (
          <Tag>Tidak Aktif</Tag>
        ),
    },
    {
      title: "Status",
      key: "status",
      fixed: "right",
      width: 100,
      filters: [
        {
          text: "Aktif",
          value: 1,
        },
        {
          text: "Tidak Aktif",
          value: 0,
        },
      ],
      onFilter: (value: string, record) =>
        record?.profile?.user?.activated === value,
      render: (record) =>
        record?.profile?.user?.activated ? (
          <Tag color="#87d068">Aktif</Tag>
        ) : (
          <Tag>Tidak Aktif</Tag>
        ),
    },
    {
      title: "Aksi",
      fixed: "right",
      width: 140,
      render: (record) => (
        <Space size="small">
          <ButtonTableEdit onClick={() => handleEdit(record)} />
          <ButtonTableRoles
            onClick={() =>
              route.push({
                pathname: `pengawas/role/${record.id}`,
                state: record,
              })
            }
          />
          <ButtonTableDelete
            onClick={() => {
              Modal.confirm({
                title: "Perhatian",
                content: "Yakin Hapus Data?",
                onOk() {
                  return handleDelete(record);
                },
                onCancel() {},
                okText: "Hapus",
                cancelText: "Batal",
                okType: "danger",
              });
            }}
          />
        </Space>
      ),
    },
  ];

  interface State {
    tableData: any[];
    currentPage: number;
    totalPages: number;
    pageSize: number;
  }

  const PAGE_SIZE = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const [totalElements, setTotalElements] = useState(0);

  const state: State = {
    tableData,
    currentPage,
    totalPages: totalElements,
    pageSize: PAGE_SIZE,
  };

  const getData = async (
    page?: number,
    size: number | null = null,
    _search?: string,
  ) => {
    setLoading(true);

    const res = await getUsermanAllPage("management-user/get-user-page", {
      activated: 1,
      group: "pengawas",
      page: currentPage - 1,
      size: size !== null ? size : undefined,
      search: _search || search,
    });
    const refRole = await getUsermanAll("role", {
      activated: 1,
      group: "pengawas",
    });

    if (tmpListMadrasah.length === 0) {
      let filter: any = "";
      if (groupRole === "provinsi") {
        filter = `?kodeProvinsi=${auth?.kode_provinsi}`;
      }
      if (groupRole === "kabkota") {
        filter = `?kodeProvinsi=${auth?.kode_provinsi}&kode_kabkota=${auth?.kode_kabkota}`;
      }
      const madrasah = await getMadrasah(filter);
      const listMadrasah = madrasah || [];
      setTimeout(() => {
        dispatch(setStore({ listMadrasah }));
      }, 100);
      setMadrasah(madrasah);
    } else {
      setMadrasah(tmpListMadrasah);
    }

    const response =
      (groupRole === "provinsi" &&
        res.return.filter(
          (item) => item.kodeProvinsi === profile?.kode_provinsi,
        )) ||
      (groupRole === "kabkota" &&
        res.return.filter(
          (item) =>
            item.kodeProvinsi === profile?.kode_provinsi &&
            item.kodeKabkota === profile?.kode_kabkota,
        ));
    setTmpRole(refRole || []);
    setTableData(response || []);
    setTotalElements(res.totalElements);
    setLoading(false);
  };

  const getDataReset = async (page?: number, size: number | null = null) => {
    setLoading(true);

    const res = await getUsermanAllPage("management-user/get-user-page", {
      activated: 1,
      group: "pengawas",
      page: currentPage - 1,
      size: size !== null ? size : undefined,
    });
    const refRole = await getUsermanAll("role", {
      activated: 1,
      group: "pengawas",
    });

    if (tmpListMadrasah.length === 0) {
      let filter: any = "";
      if (groupRole === "provinsi") {
        filter = `?kodeProvinsi=${auth?.kode_provinsi}`;
      }
      if (groupRole === "kabkota") {
        filter = `?kodeProvinsi=${auth?.kode_provinsi}&kode_kabkota=${auth?.kode_kabkota}`;
      }
      const madrasah = await getMadrasah(filter);
      const listMadrasah = madrasah || [];
      setTimeout(() => {
        dispatch(setStore({ listMadrasah }));
      }, 100);
      setMadrasah(madrasah);
    } else {
      setMadrasah(tmpListMadrasah);
    }

    const response =
      (groupRole === "provinsi" &&
        res.return.filter(
          (item) => item.kodeProvinsi === profile?.kode_provinsi,
        )) ||
      (groupRole === "kabkota" &&
        res.return.filter(
          (item) =>
            item.kodeProvinsi === profile?.kode_provinsi &&
            item.kodeKabkota === profile?.kode_kabkota,
        ));

    setTmpRole(refRole || []);
    setTableData(response || []);
    setTotalElements(res.totalElements);
    setLoading(false);
  };

  useEffect(() => {
    getData(0, PAGE_SIZE);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length >= 3) {
      setSearch(e.target.value);
      setCurrentPage(1);
      getData(0, PAGE_SIZE, e.target.value);
    }
    if (e.target.value.length === 0) {
      setSearch(0);
      setCurrentPage(1);
      getDataReset(0, PAGE_SIZE);
    }
  };

  const handlePageChange = async (pagex: number) => {
    setCurrentPage(pagex);
    // await getData(0, state.pageSize, search);
  };

  function paginationItemRender(current, type, originalElement) {
    if (type === "prev") {
      return (
        <button
          className="ant-pagination-item-link"
          type="button"
          onClick={() => handlePageChange(state.currentPage - 1)}>
          <span role="img" aria-label="left" className="anticon anticon-left">
            <svg
              viewBox="64 64 896 896"
              focusable="false"
              data-icon="left"
              width="1em"
              height="1em"
              fill="currentColor"
              aria-hidden="true">
              <path d="M724 218.3V141c0-6.7-7.7-10.4-12.9-6.3L260.3 486.8a31.86 31.86 0 000 50.3l450.8 352.1c5.3 4.1 12.9.4 12.9-6.3v-77.3c0-4.9-2.3-9.6-6.1-12.6l-360-281 360-281.1c3.8-3 6.1-7.7 6.1-12.6z"></path>
            </svg>
          </span>
        </button>
      );
    }
    if (type === "next") {
      return (
        <button
          className="ant-pagination-item-link"
          type="button"
          onClick={() => handlePageChange(state.currentPage + 1)}>
          <span role="img" aria-label="left" className="anticon anticon-right">
            <svg
              viewBox="64 64 896 896"
              focusable="false"
              data-icon="right"
              width="1em"
              height="1em"
              fill="currentColor"
              aria-hidden="true">
              <path d="M765.7 486.8L314.9 134.7A7.97 7.97 0 00302 141v77.3c0 4.9 2.3 9.6 6.1 12.6l360 281.1-360 281.1c-3.9 3-6.1 7.7-6.1 12.6V883c0 6.7 7.7 10.4 12.9 6.3l450.8-352.1a31.96 31.96 0 000-50.4z"></path>
            </svg>
          </span>
        </button>
      );
    }
    if (type === "page") {
      return (
        <button
          className={`pagination-button w-full ${
            current === state.currentPage ? "active" : ""
          }`}
          onClick={() => handlePageChange(current)}
          style={{
            backgroundColor: current === state.currentPage ? "green" : "",
            color: current === state.currentPage ? "white" : "",
          }}>
          {current}
        </button>
      );
    }
    return originalElement;
  }

  const dataTable = search
    ? tableData.filter((item: any) => {
        const val = search.toLowerCase();
        return (
          (item?.kode_role !== null &&
            (item?.kode_role || "").toLowerCase().includes(val)) ||
          (item?.profile?.nik !== null &&
            (item?.profile?.nik || "").toLowerCase().includes(val)) ||
          (item?.profile?.nama !== null &&
            (item?.profile?.nama || "").toLowerCase().includes(val)) ||
          (item?.profile?.user?.email !== null &&
            (item?.profile?.user?.email || "").toLowerCase().includes(val))
        );
      })
    : tableData;

  function handleEdit(record: any) {
    let editListMadrasah: any =
      (record?.listMadrasahIdPengawas?.length &&
        record?.listMadrasahIdPengawas.map((item) => item.id)) ||
      [];
    setOpenModalAction(true);
    setTitle("Edit");
    setID(record?.userId);
    setDataUser(record);
    form.setFieldsValue({
      kodeRole: record.kodeRole,
      nama: record.profile.nama,
      nik: record.profile.nik,
      status: record.profile.user.valid_email,
      email: record.profile.user.email,
      madrasah: editListMadrasah,
    });
  }

  const handleTambah = () => {
    setOpenModalAction(true);
    setTitle("Tambah");
    setID(uuidv4());
    form.resetFields();
  };

  const handleDelete = async (record: any) => {
    setLoading(true);
    try {
      await deletePengawas(record.userId);
      notifAlert({ type: "success", description: "Data berhasil dihapus" });
      getData();
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const handleSave = async (values: any) => {
    setLoading(true);
    let listMadrasah: any = [];
    values?.madrasah.length &&
      // eslint-disable-next-line array-callback-return
      values.madrasah.map((item) => {
        let tmMadrasah: any =
          tmpListMadrasah.find((items) => items.id.includes(item)) || null;
        tmMadrasah &&
          listMadrasah.push({
            email: tmMadrasah.email,
            alamat: tmMadrasah.alamat_jalan,
            nsm: tmMadrasah.nsm,
            kode_kabkota: tmMadrasah.kode_kabkota,
            kode_provinsi: tmMadrasah.kode_provinsi,
            id: tmMadrasah.id,
          });
      });
    let payload: any = {
      nik: values.nik,
      password: values.password,
      email: values.email,
      nama: values.nama,
      activated: 1,
      kode_role: values.kodeRole,
      kode_kabkota: profile?.kode_kabkota,
      kode_provinsi: profile?.kode_provinsi,
      require_email: values.status ? 1 : 0,
      list_madrasah_id_pengawas: listMadrasah,
      kantor_kabkota_id: profile?.kantor_kabkota_id,
      kantor_provinsi_id: profile?.kantor_provinsi_id,
      kantor_pusat_id: profile?.kantor_pusat_id,
      role_user_id: dataUser?.id || id,
    };

    if (title === "Tambah") {
      try {
        await postPengawas(payload);
        notifAlert({ type: "success", description: "Data berhasil disimpan" });
        getData();
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        await editPengawas(id, payload);
        notifAlert({ type: "success", description: "Data berhasil disimpan" });
        getData();
      } catch (error) {
        console.log(error);
      }
    }

    handleReset();
    setLoading(false);
  };

  const handleReset = () => {
    setDataUser(null);
    form.resetFields();
    setOpenModalAction(false);
  };

  // const handleChange = (values) => {
  //   for (let obj in values) {
  //     switch (obj) {
  //       case "provinsi":
  //         setProvinsi(values.provinsi);
  //         setKabkota(null);
  //         form.setFieldsValue({ madrasah: null, kabkota: null });
  //         break;
  //       case "kabkota":
  //         setKabkota(values.kabkota);
  //         form.setFieldsValue({ madrasah: null });
  //         break;
  //     }
  //   }
  // };

  const onSelectChange = (selectedRowKeys, selectedRows) => {
    let pengawasDetailsTmp: any = [];
    setSelectedRowKeys(selectedRowKeys);
    selectedRows.length &&
      // eslint-disable-next-line array-callback-return
      selectedRows.map((item) => {
        // item.biayaSumberDanas
        //   .filter(
        //     (items) =>
        //       items.rencanaPendapatanId &&
        //       items.rencanaPendapatanId.includes(tmpSumber?.idSumberDana),
        //   )
        //   // eslint-disable-next-line array-callback-return
        //   .map((items) => {
        //     pajakDetailsTmp.push({
        //       biayaId: item.id,
        //       biayaDetails: item.biayaDetails,
        //       biayaSumberDanaId: items.id,
        //       pajakTotal: items.grandPajakTerutang,
        //       tanggalRealisasi: item.tanggalRealisasi,
        //     });
        //   });
        // console.log(item);
        pengawasDetailsTmp.push(item);
      });
    setSelectedDataPengawas(pengawasDetailsTmp);
    // handleTotalKas(pajakDetailsTmp);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const handleActive = (active) => {
    console.log(active);
    console.log(selectedDataPengawas);
    // if (dataTable.length > 0) {
    //   if (type === "pdf") {
    //     handleExportPdf();
    //   } else if (type === "excel") {
    //     handleExportExcel();
    //   }
    // } else {
    //   notifAlert({
    //     type: "warning",
    //     description:
    //       "Harap pilih data yang ingin di export melalui fungsi filter!",
    //   });
    // }
  };
  return (
    <>
      <BreadCrumb routes={itemBreadcrumb} title="Pengawas" />
      <div className="m-5 p-5 bg-white shadow-md rounded">
        <div className="mb-4 flex justify-end">
          {(kodeRole === "admin_provinsi" || kodeRole === "admin_kabkota") && (
            <div className="mr-4">
              <ButtonTambah onClick={handleTambah} loading={isLoading} />
              {(selectedDataPengawas.length && (
                <ButtonDropdownActive handleSetActive={handleActive} />
              )) ||
                ""}
            </div>
          )}

          <InputSearch className="w-3/4" onChange={handleChange} />
        </div>
        {/* <div className="w-full mb-4">
          <Alert message="Batasan staf madrasah 8 akun" type="info" showIcon />
        </div> */}
        <div className="w-full">
          <Table
            rowKey={(record) => record.id}
            columns={columns}
            rowSelection={rowSelection}
            dataSource={dataTable}
            bordered
            loading={isLoading}
            tableLayout="auto"
            scroll={{ x: "1300" }}
            pagination={false}
          />
        </div>
        <div className="w-full flex justify-end">
          <></>
          <Pagination
            total={totalElements}
            pageSize={10}
            itemRender={paginationItemRender}
            showSizeChanger={false}
          />
        </div>
      </div>
      <Modal
        open={openModalAction}
        title={`${title} Pengawas`}
        width={850}
        onCancel={handleReset}
        footer={[
          <Button key="back" onClick={handleReset}>
            Batal
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={isLoading}
            onClick={() => form.submit()}>
            Simpan
          </Button>,
        ]}>
        <Form
          form={form}
          key="formPengawas"
          name="formPengawas"
          layout="vertical"
          // onValuesChange={handleChange}
          initialValues={{
            kodeRole:
              groupRole === "kabkota" ? "pengawas" : "pengawas_provinsi",
            kabkota: groupRole === "kabkota" ? profile?.kode_kabkota : null,
            provinsi: profile?.kode_provinsi,
          }}
          onFinish={handleSave}>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div>
              <Form.Item
                label="Nama"
                name="nama"
                rules={[
                  {
                    required: true,
                    message: "Nama tidak boleh kosong!",
                  },
                ]}>
                <Input placeholder="Nama" />
              </Form.Item>
            </div>
            <div>
              <Form.Item
                label="NIK"
                name="nik"
                rules={[
                  {
                    required: true,
                    message: "NIK tidak boleh kosong!",
                  },
                ]}>
                <Input type="number" placeholder="NIK" />
              </Form.Item>
            </div>
            <div>
              <Form.Item
                label="Email"
                name="email"
                rules={[
                  {
                    type: "email",
                    message: "Email tidak valid!",
                  },
                  {
                    required: true,
                    message: "Email tidak boleh kosong!",
                  },
                ]}>
                <Input type="email" placeholder="Email" />
              </Form.Item>
            </div>
            <div>
              <Form.Item
                label="Role"
                name="kodeRole"
                rules={[
                  { required: true, message: "Role tidak boleh kosong!" },
                ]}>
                <Select
                  showSearch
                  optionFilterProp="children"
                  disabled={true}
                  placeholder="Pilih Role">
                  {tmpRole?.length &&
                    tmpRole.map((e: any, i: any) => {
                      return (
                        <Select.Option key={`role${i}`} value={e.kode}>
                          {e.nama}
                        </Select.Option>
                      );
                    })}
                </Select>
              </Form.Item>
            </div>
            <div>
              <Form.Item
                label="Provinsi"
                name="provinsi"
                rules={[
                  { required: true, message: "Provinsi tidak boleh kosong!" },
                ]}>
                <Select
                  showSearch
                  disabled={true}
                  optionFilterProp="children"
                  placeholder="Pilih Provinsi">
                  {refProvinsi?.length &&
                    refProvinsi.map((e: any, i: any) => {
                      return (
                        <Select.Option key={`prov${i}`} value={e.kode}>
                          {e.nama}
                        </Select.Option>
                      );
                    })}
                </Select>
              </Form.Item>
            </div>
            <div>
              <Form.Item
                label="Kab / Kota"
                name="kabkota"
                rules={[
                  { required: true, message: "Kab / Kota tidak boleh kosong!" },
                ]}>
                <Select
                  showSearch
                  disabled={groupRole === "kabkota" ? true : false}
                  optionFilterProp="children"
                  placeholder="Pilih Kab / Kota">
                  {refKabkota?.length &&
                    refKabkota.map((e: any, i: any) => {
                      return (
                        <Select.Option key={`role${i}`} value={e.kode}>
                          {e.nama}
                        </Select.Option>
                      );
                    })}
                </Select>
              </Form.Item>
            </div>
            <div>
              <Form.Item
                label="Madrasah"
                name="madrasah"
                rules={[
                  { required: true, message: "Madrasah tidak boleh kosong!" },
                ]}>
                <Select
                  showSearch
                  mode="multiple"
                  optionFilterProp="children"
                  placeholder="Pilih Madrasah">
                  {madrasah?.length &&
                    madrasah
                      .filter(
                        (item) =>
                          item.kode_kabkota === profile?.kode_kabkota &&
                          item.kode_provinsi === profile?.kode_provinsi,
                      )
                      .map((e: any, i: any) => {
                        return (
                          <Select.Option key={`role${i}`} value={e.id}>
                            {e.nama} | {e.nsm}
                          </Select.Option>
                        );
                      })}
                </Select>
              </Form.Item>
            </div>
            <div>
              <Form.Item
                label="Password"
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Password tidak boleh kosong!",
                  },
                  {
                    validator: (_, value) =>
                      value && value.length >= 6
                        ? Promise.resolve()
                        : Promise.reject("Password minimal 6 karakter"),
                  },
                ]}>
                <Input.Password placeholder="Password" />
              </Form.Item>
            </div>
          </div>
          <Form.Item
            label="Memerlukan email valid?"
            name="status"
            valuePropName="checked">
            <Switch />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default Pengawas;
