/** @format */

import React, { useState } from "react";
import {
  Select,
  Input,
  Form,
  DatePicker,
  InputNumber,
  Modal,
  Divider,
  Descriptions,
} from "antd";
import type { RangePickerProps } from "antd/es/date-picker";
import moment from "moment";
import "moment/locale/id";
import { ButtonUploads } from "../../../../components/Uploads";
import { formatRupiah } from "../../../../utils/helper";
import { useSelector } from "react-redux";

type FormActionType = {
  form: any;
  openModal: boolean;
  title: string;
  store: any;
  dataEdit: any;
  files: any;
  confirmLoading: boolean;
  handleFileUpload: (e: any) => void;
  handleSave: (e: any) => void;
  handleClose: () => void;
};

const ModalPendapatan = ({
  form,
  openModal,
  title,
  store,
  files,
  dataEdit,
  confirmLoading,
  handleFileUpload,
  handleSave,
  handleClose,
}: FormActionType) => {
  const auths = useSelector((state: any) => state.auth);
  const tmpTipeKas = store.tipeKas || [];
  const tmpRekeningBelanja = store.rekeningBelanja || [];
  const tmpSumberDana = store.rencanaPendapatanDefinitif || [];
  const tmpRealisasiPendapatan = store.realisasiPendapatan || [];
  const tmpMadrasah = store.madrasahProfile || null;
  const tipeKas = form.getFieldValue("tipeKas");
  const [enabledRekening, setEnabledRekening] = useState<any>(false);
  const [validUpload, setValidUpload] = useState<any>([]);
  const [errorUpload, setErrorUpload] = useState<any>(null);
  const [disabledTipeKas, setDisabledTipeKas] = useState<any>(false);
  const [fieldTipeKas, setFieldTipeKas] = useState<any>(true);
  const [saldoRencanaPendapatan, setSaldoRencanaPendapatan] = useState<any>(0);
  const [sisaSaldo, setSisaSaldo] = useState<any>(0);
  const [saldoRealisasiPendapatan, setSaldoRealisasiPendapatan] =
    useState<any>(0);
  // eslint-disable-next-line arrow-body-style
  const disabledDate: RangePickerProps["disabledDate"] = (current) => {
    return current.year() !== moment(`${auths.isTahun}-12-01`).year();
  };

  React.useEffect(() => {
    setValidUpload(files);

    if (dataEdit) {
      hitungPendapatan(dataEdit?.rencanaPendapatanId);
    }

    if (tipeKas === "rekening_bank") {
      return setEnabledRekening(true);
    }

    return setEnabledRekening(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tipeKas, files, dataEdit]);

  const handleChange = (values) => {
    for (let obj in values) {
      if (obj === "tipeKas") {
        if (values.tipeKas === "rekening_bank") {
          return setEnabledRekening(true);
        }

        return setEnabledRekening(false);
      } else if (obj === "sumberDana") {
        let listSumberDana = [
          "apbn_bos",
          "apbn_bos_semester_1",
          "apbn_bos_semester_2",
          "bkba_kinerja",
          "bkba_afirmasi",
          "ba_rehab_berat",
        ];
        setFieldTipeKas(values?.sumberDana ? false : true);
        let ts: any =
          tmpSumberDana.find((item: any) =>
            item?.id.includes(values.sumberDana),
          ) || null;
        if (listSumberDana.includes(ts?.kode_sumber_dana)) {
          if (
            tmpMadrasah?.status === "n" &&
            tmpMadrasah?.jenjang === "Madrasah Ibtidaiyah"
          ) {
            setDisabledTipeKas(false);
          } else {
            form.setFieldsValue({ tipeKas: "rekening_bank" });
            setDisabledTipeKas(true);
          }
        } else {
          setDisabledTipeKas(false);
        }
        hitungPendapatan(values.sumberDana);
      }
    }
  };

  const hitungPendapatan = (idSumberDana) => {
    let ts: any =
      tmpSumberDana.find((item: any) => item?.id.includes(idSumberDana)) ||
      null;
    let totalRealisasi: any = 0;
    let editSaldo = 0;
    if (dataEdit?.rencanaPendapatanId === idSumberDana) {
      editSaldo = dataEdit?.jumlah;
    }

    tmpRealisasiPendapatan
      .filter((item) => item.rencanaPendapatanId.includes(idSumberDana))
      // eslint-disable-next-line array-callback-return
      .map((item) => {
        totalRealisasi += item.jumlah;
      });
    let tmpSisaSaldo = editSaldo + (ts?.jumlah || 0) - totalRealisasi;
    setSisaSaldo(tmpSisaSaldo);
    setSaldoRencanaPendapatan(ts?.jumlah || 0);
    setSaldoRealisasiPendapatan(totalRealisasi);
  };

  const checkFiles = (values: any) => {
    let messageTmp: any = { status: "success", messages: "" };
    if (!values.length) {
      setErrorUpload({
        status: "error",
        messages: "Maaf dokumen tidak boleh kosong!",
      });
      return Promise.reject();
    } else {
      setErrorUpload(messageTmp);
      return Promise.resolve();
    }
  };

  const validationAfterUpload = (values: any) => {
    handleFileUpload(values);
    setValidUpload(values);
    checkFiles(values);
  };

  const handleBeforeClose = () => {
    setSaldoRencanaPendapatan(0);
    setSaldoRealisasiPendapatan(0);
    setFieldTipeKas(true);
    handleClose();
  };

  const checkValidationSaldo = (sisaSaldo) => {
    if (sisaSaldo < 0) {
      return Promise.reject("Saldo dari Sumber Dana tidak mencukupi!");
    }
    return Promise.resolve();
  };

  return (
    <Modal
      open={openModal}
      title={`${title} Nota Pendapatan`}
      onCancel={handleBeforeClose}
      okText="Simpan"
      width={800}
      cancelText="Batal"
      confirmLoading={confirmLoading}
      onOk={() => form.submit()}>
      <Form
        form={form}
        key="modalRealisasiPendapatan"
        name="modalRealisasiPendapatan"
        layout="vertical"
        onValuesChange={handleChange}
        onFinish={handleSave}>
        <div className="grid grid-cols-1  md:items-center  md:grid-cols-2 gap-2 ">
          <div>
            <Form.Item
              label="Sumber Dana"
              name="sumberDana"
              rules={[
                {
                  required: true,
                  message: "Sumber Dana tidak boleh kosong!",
                },
              ]}>
              <Select
                showSearch
                optionFilterProp="children"
                placeholder="Pilih Sumber Dana">
                {tmpSumberDana.length &&
                  tmpSumberDana.map((item: any, i: any) => {
                    return (
                      <Select.Option key={`snp${i}`} value={item.id}>
                        {item.nama_sumber_dana}
                      </Select.Option>
                    );
                  })}
              </Select>
            </Form.Item>
          </div>
          <div>
            <Form.Item
              label="Tipe Kas"
              name="tipeKas"
              rules={[
                {
                  required: true,
                  message: "Tipe Kas tidak boleh kosong!",
                },
              ]}>
              <Select
                showSearch
                disabled={fieldTipeKas}
                optionFilterProp="children"
                placeholder="Pilih Tipe Kas">
                {tmpTipeKas.length &&
                  tmpTipeKas.map((e: any, i: any) => {
                    let enableOption = disabledTipeKas
                      ? e.kode === "rekening_bank"
                        ? false
                        : true
                      : false;

                    return (
                      <Select.Option
                        key={`tipekas${i}`}
                        value={e.kode}
                        disabled={enableOption}>
                        {e.nama}
                      </Select.Option>
                    );
                  })}
              </Select>
            </Form.Item>
          </div>
          {enabledRekening && (
            <div>
              <Form.Item
                label="No Rekening"
                name="noRekening"
                rules={[
                  {
                    required: true,
                    message: "No Rekening tidak boleh kosong!",
                  },
                ]}>
                <Select
                  showSearch
                  optionFilterProp="children"
                  placeholder="Pilih No Rekening">
                  {tmpRekeningBelanja.length &&
                    tmpRekeningBelanja.map((e: any, i: any) => {
                      return (
                        <Select.Option key={`noRek${i}`} value={e.id}>
                          {e.nama_bank} - {e.no_rekening}
                        </Select.Option>
                      );
                    })}
                </Select>
              </Form.Item>
            </div>
          )}
          <div>
            <Form.Item
              label="Tanggal Nota"
              name="tanggalNota"
              rules={[
                {
                  required: true,
                  message: "Tanggal Nota tidak boleh kosong!",
                },
              ]}>
              <DatePicker disabledDate={disabledDate} showTime />
            </Form.Item>
          </div>
          <div>
            <Form.Item
              label="Nilai Pendapatan"
              name="nilaiPendapatan"
              rules={[
                {
                  required: true,
                  message: "Nilai Pendapatan tidak boleh kosong!",
                },
                {
                  validator: () => checkValidationSaldo(sisaSaldo),
                },
              ]}>
              <InputNumber
                style={{ width: "100%" }}
                min={0}
                max={sisaSaldo}
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                prefix="Rp."
                placeholder="Nilai Pendapatan"
              />
            </Form.Item>
          </div>
          <div>
            <Form.Item label="Keterangan" name="keterangan">
              <Input.TextArea placeholder="Keterangan" />
            </Form.Item>
          </div>
        </div>
        <Form.Item
          label="Upload Dokumen"
          required
          name="files"
          validateStatus={errorUpload?.status}
          hasFeedback
          help={errorUpload?.messages}
          rules={[
            {
              validator: () => checkFiles(validUpload),
            },
          ]}>
          <ButtonUploads
            files={files}
            handleFileUpload={validationAfterUpload}
          />
        </Form.Item>
      </Form>
      <Divider />
      <div className="flex justify-start md:justify-end gap-4">
        <div className="md:w-1/2">
          <Descriptions
            column={1}
            contentStyle={{
              fontWeight: 600,
              justifyContent: "end",
              fontSize: 18,
            }}>
            <Descriptions.Item label="Saldo Rencana Pendapatan">
              {formatRupiah(saldoRencanaPendapatan)}
            </Descriptions.Item>
            <Descriptions.Item label="Saldo Realisasi Pendapatan">
              {formatRupiah(saldoRealisasiPendapatan)}
            </Descriptions.Item>
            <Descriptions.Item label="Sisa Saldo">
              <div className="text-right font-extrabold text-xl">
                {formatRupiah(
                  saldoRencanaPendapatan - saldoRealisasiPendapatan,
                )}
              </div>
            </Descriptions.Item>
          </Descriptions>
        </div>
      </div>
    </Modal>
  );
};
export default ModalPendapatan;
