/** @format */

import React, { useState, useEffect } from "react";
import { BreadCrumb } from "../../../components";
import moment from "moment";
import "moment/locale/id";
import { Table, Space } from "antd";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import * as FileSaver from "file-saver";
import ExcelJS from "exceljs";
import { formatRupiah } from "../../../utils/helper";

import { useDispatch, useSelector } from "react-redux";
import { ButtonDropdownExport } from "../../../components/Button";
import InputSearch from "../../../components/InputSearch";
import notifAlert from "../../../components/NotifAlert";
import { getLaporanBKUKonsolidasi } from "../../../services/v2/reportservice";

import FilterSumberDanaMin from "../Component/FilterSumberDanaMin";
import { getMadrasah } from "../../../services/v2/usermanservice/madrasahservices";
import { setStore } from "../../../redux/actions";

const BukuKasUmumKonsolidasiMin = () => {
  const itemBreadcrumb = [
    { path: "/", breadcrumbName: "Home" },
    { path: "/", breadcrumbName: "Laporan" },
    { path: "/", breadcrumbName: "Laporan Penatausahaan" },
    { path: "/", breadcrumbName: "Buku Kas Umum Konsolidasi MIN" },
  ];

  const auths = useSelector((state: any) => state.auth);
  const store = useSelector((state: any) => state.store);
  const dispatch = useDispatch();
  const refKabkota = store.kabkota || [];
  const refProvinsi = store.provinsi || [];
  const refKecamatan = store.kecamantan || [];
  const auth = auths?.data || null;
  const groupRole = auth?.group_role || "";
  const tmpListMadrasah = store?.listMadrasah || [];
  const [tableData, setTableData] = useState<any>([]);
  // const [tmpData, setTmpData] = useState<any>(null);
  // const [dataMadrasah, setDataMadrasah] = useState<any>(null);
  const [search, setSearch] = useState<any>(null);
  // const [page, setPage] = useState(1);
  // const [pageSize, setPageSize] = useState(10);
  const [tmpFilter, setTmpFilter] = useState<any>(null);
  const [loading, setLoading] = useState(false);

  let dataTable: any = search
    ? tableData.filter((item: any) => {
        const val = search.toLowerCase();
        return (
          (item.nsm !== null &&
            (item.nsm || "").toString().toLowerCase().includes(val)) ||
          (item.nsmMadrasah !== null &&
            (item.nsmMadrasah || "").toString().toLowerCase().includes(val))
        );
      })
    : tableData;

  // let totalDataTable = dataTable.length;

  /**
   * Filter Pagination
   */
  // dataTable = dataTable
  //   // .sort((a, b) => a.tglTrx - b.tglTrx)
  //   .filter((v, i) => {
  //     let start = pageSize * (page - 1);
  //     let end = start + pageSize;

  //     return i >= start && i < end;
  //   });

  const getData = async (tmpFilter: any) => {
    setLoading(true);
    if (groupRole !== "madrasah") {
      if (tmpListMadrasah.length === 0) {
        let filter: any = "";
        if (groupRole === "provinsi") {
          filter = `?kodeProvinsi=${auth?.kode_provinsi}`;
        }
        if (groupRole === "kabkota") {
          filter = `?kodeProvinsi=${auth?.kode_provinsi}&kode_kabkota=${auth?.kode_kabkota}`;
        }
        const madrasah = await getMadrasah(filter);
        const listMadrasah = madrasah || [];
        setTimeout(() => {
          dispatch(setStore({ listMadrasah }));
        }, 100);
      }

      const payload = {
        tahun: moment(tmpFilter?.periode).format("YYYY"),
        bulan: moment(tmpFilter?.periode).format("MM"),
        kodeKabKota: tmpFilter?.kodeKabkota,
        status: "n",
        jenjang: "mi",
        kodeSumberDana: "apbn_bos",
      };

      if (tmpFilter?.periode) {
        const res = await getLaporanBKUKonsolidasi(payload);
        setTableData(res || []);
      }
    }

    setLoading(false);
  };

  useEffect(() => {
    getData(tmpFilter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tmpFilter, auth?.isTahun]);

  const handleExportFile = (type) => {
    if (dataTable.length > 0) {
      if (type === "pdf") {
        handleExportPdf();
      } else if (type === "excel") {
        handleExportExcel();
      }
    } else {
      notifAlert({
        type: "warning",
        description:
          "Harap pilih data yang ingin di export melalui fungsi filter!",
      });
    }
  };

  /**
   * Data untuk tabel children pertama
   */
  let columnTmp: any = [
    {
      title: "NSM",
      dataIndex: "nsm",
      key: "nsm",
      width: 80,
    },
    {
      title: "Madrasah",
      dataIndex: "namaMadrasah",
      key: "namaMadrasah",
      width: 140,
    },
    {
      title: "Provinsi",
      dataIndex: "kodeProvinsi",
      key: "kodeProvinsi",
      width: 140,
      render: (kodeProvinsi) => {
        let prov =
          refProvinsi.find((item) => item.kode === kodeProvinsi) || null;
        return prov?.nama;
      },
    },
    {
      title: "Kab / Kota",
      dataIndex: "kodeKabkota",
      key: "kodeKabkota",
      width: 140,
      render: (kodeKabkota) => {
        let kabkota =
          refKabkota.find((item) => item.kode === kodeKabkota) || null;
        return kabkota?.nama;
      },
    },
  ];

  /**
   * Data untuk tabel children pertama
   */
  let columns: any = [
    // {
    //   title: "No Urut",
    //   key: "noUrut",
    //   width: 80,
    //   render: (_, record, i: number) => i ,
    // },
    {
      title: "Tanggal",
      key: "tglTrx",
      dataIndex: "tglTrx",
      width: 250,
      render: (tglTrx) =>
        tglTrx && moment(tglTrx).format("dddd, YYYY-MMM-DD HH:mm:ss"),
    },
    {
      title: "No. Kode",
      key: "noKode",
      dataIndex: "noKode",
      width: 280,
    },
    {
      title: "No. Bukti",
      key: "noBukti",
      dataIndex: "noBukti",
      width: 180,
    },
    {
      title: "Uraian",
      key: "uraian",
      dataIndex: "uraian",
      width: 400,
    },
    {
      title: "Penerimaan (Debit)",
      key: "debit",
      dataIndex: "debit",
      align: "right",
      width: 250,
      render: (debit) => formatRupiah(debit),
    },
    {
      title: "Pengeluaran (Kredit)",
      key: "credit",
      dataIndex: "credit",
      align: "right",
      width: 250,
      render: (credit) => formatRupiah(credit),
    },
    {
      title: "Saldo",
      key: "saldo",
      dataIndex: "saldo",
      align: "right",
      width: 250,
      render: (saldo) => (
        <div className="text-right">{formatRupiah(saldo)}</div>
      ),
    },
  ];

  const handleChangeValues = (values) => {
    setTmpFilter(values);
    getData(values);
  };

  function handleExportPdf() {
    const doc: any = new jsPDF({
      putOnlyUsedFonts: true,
      orientation: "landscape",
      floatPrecision: 16,
    });
    var fline = 10;
    doc.setFont("times", "bold");
    doc.text("BUKU KAS UMUM KONSOLIDASI MIN", 150, 10, { align: "center" });
    doc.text(
      "Periode : " +
        moment(tmpFilter?.periode).format("MMMM") +
        " " +
        moment(tmpFilter?.periode).format("YYYY"),
      150,
      18,
      { align: "center" },
    );
    tableData.length &&
      // eslint-disable-next-line array-callback-return
      tableData.map((item, no) => {
        if (no !== 0) {
          doc.addPage();
        }
        let dataMadrasah: any =
          tmpListMadrasah.find((iitem) => iitem.nsm === item.nsm) || null;
        doc.setFont("times", "normal");
        doc.setFontSize(12);
        doc.rect(225, 25, 60, 7);
        doc.rect(225, 25, 60, 25);
        doc.text("Format BOS K-2", 228, 30);
        doc.text("Diisi oleh Bendahara Madrasah", 228, 38);
        doc.text("Disimpan di Madrasah", 228, 48);
        doc.text("Nama Madrasah", fline, 30);
        doc.text(": " + (dataMadrasah && dataMadrasah?.nama), fline + 40, 30);
        doc.text("NSM", fline, 35);
        doc.text(": " + (dataMadrasah && dataMadrasah?.nsm), fline + 40, 35);
        doc.text("Kecamatan", fline, 40);
        doc.text(
          ": " +
            (dataMadrasah && dataMadrasah?.kode_kecamatan
              ? refKecamatan
                  .filter((e) => e.kode === dataMadrasah?.kode_kecamatan)
                  .map((e) => e.nama)
              : "-"),
          fline + 40,
          40,
        );
        doc.text("Kabupaten / Kota", fline, 45);
        doc.text(
          ": " +
            (dataMadrasah && dataMadrasah?.kode_kabkota
              ? refKabkota
                  .filter((e) => e.kode === dataMadrasah?.kode_kabkota)
                  .map((e) => e.nama)
              : "-"),
          fline + 40,
          45,
        );
        doc.text("Provinsi", fline, 50);
        doc.text(
          ": " +
            (dataMadrasah && dataMadrasah?.kode_provinsi
              ? refProvinsi
                  .filter((e) => e.kode === dataMadrasah?.kode_provinsi)
                  .map((e) => e.nama)
              : "-"),
          fline + 40,
          50,
        );
        var headers: any = [
          { id: "id", dataKey: "id", padding: 0, header: "No Urut", width: 22 },
          {
            id: "tanggal",
            dataKey: "tanggal",
            padding: 0,
            header: "Tanggal",
            width: 50,
          },
          {
            id: "nokode",
            dataKey: "nokode",
            padding: 0,
            header: "No Kode",
            width: 40,
          },
          {
            id: "nobukti",
            dataKey: "nobukti",
            padding: 0,
            header: "No Bukti",
            width: 40,
          },
          {
            id: "uraian",
            dataKey: "uraian",
            padding: 0,
            header: "Uraian",
            width: 70,
          },
          {
            id: "debit",
            dataKey: "debit",
            padding: 0,
            header: "Penerimaan (Debit)",
            width: 39,
          },
          {
            id: "kredit",
            dataKey: "kredit",
            padding: 0,
            header: "Pegeluaran (Kredit)",
            width: 39,
          },
          {
            id: "saldo",
            dataKey: "saldo",
            padding: 0,
            header: "Saldo",
            width: 39,
          },
        ];
        let las: any = [];
        autoTable(doc, {
          styles: { lineColor: 244, lineWidth: 0.1 },
          headStyles: {
            halign: "center",
            valign: "middle",
            fillColor: [0, 128, 0],
          }, // Cells in first column centered and green
          columnStyles: {
            0: { halign: "center", cellWidth: 12 },
            1: { halign: "center", cellWidth: 20 },
            2: { halign: "center", cellWidth: 40 },
            3: { halign: "center", cellWidth: 40 },
            5: { halign: "right", cellWidth: 30 },
            6: { halign: "right", cellWidth: 30 },
            7: { halign: "right", cellWidth: 30 },
          },
          startY: 55,
          margin: { top: 10, left: 10, right: 10 },
          columns: headers,
          body:
            item?.detail.length &&
            item?.detail.map((e, i) => {
              return {
                id: i + 1,
                tanggal: e.tglTrx
                  ? moment(e.tglTrx).format("dddd,DD MMM YYYY HH:mm:ss")
                  : "",
                nokode: e.noKode,
                nobukti: e.noBukti,
                uraian: e.uraian,
                debit: formatRupiah(e.debit),
                kredit: formatRupiah(e.credit),
                saldo: formatRupiah(e.saldo),
              };
            }),
          didDrawPage: (d) => las.push(d.cursor),
        });
        let lastLine: any = doc.lastAutoTable.finalY + 5;
        const footerText = [
          {
            text: "Mengetahui",
            line: fline,
            space: 0,
            bottom: 10,
          },
          {
            text: moment().format("dddd, DD MMMM YYYY"),
            line: fline,
            space: 210,
            bottom: 0,
          },
          {
            text: "Kepala Madrasah",
            line: fline,
            space: 0,
            bottom: 5,
          },
          {
            text: "Bendahara Madrasah",
            line: fline,
            space: 210,
            bottom: 0,
          },
          {
            text:
              (dataMadrasah?.nama_kepala &&
                `( ${dataMadrasah?.nama_kepala} )`) ||
              "(.................)",
            line: fline,
            space: 0,
            bottom: 30,
          },
          {
            text:
              (dataMadrasah?.nama_bendahara &&
                `( ${dataMadrasah?.nama_bendahara} )`) ||
              "(.................)",
            line: fline,
            space: 210,
            bottom: 0,
          },
        ];
        let tmpBottom = lastLine;
        // eslint-disable-next-line array-callback-return
        footerText.map((item) => {
          tmpBottom += item.bottom;
          if (tmpBottom > 195) {
            doc.addPage();
            tmpBottom = 15;
          }
          doc.text(item.text, fline + item.space, tmpBottom);
        });
      });

    doc.output("dataurlnewwindow", { filename: "laporan-bku-min-konsolidasi" });
    notifAlert({
      type: "success",
      description: "Data berhasil di export",
    });
  }

  const handleExportExcel = async () => {
    var ExcelJSWorkbook = new ExcelJS.Workbook();
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";

    tableData.length &&
      // eslint-disable-next-line array-callback-return
      tableData.map((item, no) => {
        var worksheet = ExcelJSWorkbook.addWorksheet(`${item.namaMadrasah}`);

        worksheet.mergeCells("A1:I1");
        const headerCell = worksheet.getCell("A1");
        headerCell.font = {
          size: 11,
          underline: true,
          bold: true,
        };
        headerCell.alignment = { vertical: "middle", horizontal: "center" };
        headerCell.value = "BUKU KAS UMUM MIN KONSOLIDASI";
        worksheet.mergeCells("A2:I2");
        const subHeaderCell = worksheet.getCell("A2");
        subHeaderCell.font = {
          size: 11,
          underline: true,
          bold: true,
        };
        subHeaderCell.alignment = { vertical: "middle", horizontal: "center" };
        subHeaderCell.value = `Periode : ${moment(tmpFilter?.periode).format(
          "MMMM",
        )} ${moment(tmpFilter?.periode).format("YYYY")}`;
        let dataMadrasah: any =
          tmpListMadrasah.find((iitem) => iitem.nsm === item.nsm) || null;
        const namaKecamatan =
          refKecamatan.find(
            (item) => item.kode === dataMadrasah?.kode_kecamatan,
          ) || null;
        const namaKabkota =
          refKabkota.find((item) => item.kode === dataMadrasah?.kode_kabkota) ||
          null;
        const namaProvinsi =
          refProvinsi.find(
            (item) => item.kode === dataMadrasah?.kode_provinsi,
          ) || null;
        worksheet.getCell("A4").value = "Nama Madrasah";
        worksheet.getCell("B4").value = `: ${dataMadrasah?.nama || "-"}`;
        worksheet.getCell("A5").value = "NSM";
        worksheet.getCell("B5").value = `: ${dataMadrasah?.nsm || "-"}`;
        worksheet.getCell("A6").value = "Kecamatan";
        worksheet.getCell("B6").value = `: ${namaKecamatan?.nama || "-"}`;
        worksheet.getCell("A7").value = "Kabupaten / Kota";
        worksheet.getCell("B7").value = `: ${namaKabkota?.nama || "-"}`;
        worksheet.getCell("A8").value = "Provinsi";
        worksheet.getCell("B8").value = `: ${namaProvinsi?.nama || "-"}`;
        worksheet.mergeCells("G4:H4");
        const headerLabel = worksheet.getCell("G4");
        headerLabel.border = {
          top: { style: "double", color: { argb: "00000000" } },
          left: { style: "double", color: { argb: "00000000" } },
          bottom: { style: "double", color: { argb: "00000000" } },
          right: { style: "double", color: { argb: "00000000" } },
        };
        headerLabel.value = "Format BOS K-2";
        worksheet.mergeCells("G5:H8");
        const bodyLabel = worksheet.getCell("G5");
        bodyLabel.border = {
          top: { style: "double", color: { argb: "00000000" } },
          left: { style: "double", color: { argb: "00000000" } },
          bottom: { style: "double", color: { argb: "00000000" } },
          right: { style: "double", color: { argb: "00000000" } },
        };
        bodyLabel.value =
          "Diisi oleh Bendahara Madrasah \n \n Disimpan di Madrasah";

        let dataTmp: any = [];
        item?.detail.length &&
          item.detail.map((e, i) => {
            return dataTmp.push([
              i + 1,
              e.tglTrx
                ? moment(e.tglTrx).format("dddd,DD MMM YYYY HH:mm:ss")
                : "",
              e.noKode,
              e.noBukti,
              e.uraian,
              formatRupiah(e.debit),
              formatRupiah(e.credit),
              formatRupiah(e.saldo),
            ]);
          });

        worksheet.addTable({
          name: "Table",
          ref: "A10",
          headerRow: true,
          style: {
            showRowStripes: true,
          },
          columns: [
            { name: "No Urut" },
            { name: "Tanggal" },
            { name: "No Kode" },
            { name: "No Bukti" },
            { name: "Uraian" },
            { name: "Penerimaan (Debit)" },
            { name: "Pengeluaran (Kredit)" },
            { name: "Saldo" },
          ],
          rows: dataTmp,
        });

        let lastLine = 11 + dataTmp.length;
        worksheet.getCell(`A${lastLine}`).value = "Mengetahui";
        worksheet.getCell(`H${lastLine}`).value =
          moment().format("dddd, DD MMMM YYYY");
        worksheet.getCell(`A${lastLine + 2}`).value = "Kepala Madrasah";
        worksheet.getCell(`H${lastLine + 2}`).value = "Bendahara Madrasah";
        worksheet.getCell(`A${lastLine + 5}`).value =
          (dataMadrasah?.nama_kepala && `( ${dataMadrasah?.nama_kepala} )`) ||
          "(.................)";
        worksheet.getCell(`H${lastLine + 5}`).value =
          (dataMadrasah?.nama_bendahara &&
            `( ${dataMadrasah?.nama_bendahara} )`) ||
          "(.................)";
      });
    const excelBuffer = await ExcelJSWorkbook.xlsx.writeBuffer();
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, "Buku-Kas-Umum-Konsolidasi-Min" + fileExtension);
    notifAlert({
      type: "success",
      description: "Data berhasil di export",
    });
  };

  /**
   * Membuka baris Table detail
   */
  const expandedRowDetail = (record) => {
    const dataDetailTmp: any = record?.detail || [];
    return <Table columns={columns} dataSource={dataDetailTmp} bordered />;
  };
  return (
    <>
      <BreadCrumb
        routes={itemBreadcrumb}
        title="Buku Kas Umum Konsolidasi MIN"
      />
      <FilterSumberDanaMin
        handleChangeValues={handleChangeValues}
        groupRole={groupRole}
      />
      <div className="m-5 p-5 bg-white shadow-md rounded">
        <div className="mb-4 flex justify-end">
          <div className="mr-4">
            <Space size={[8, 8]} wrap>
              <ButtonDropdownExport handleExportFile={handleExportFile} />
            </Space>
          </div>
          <InputSearch
            className="w-3/4"
            onChange={(e: any) => setSearch(e.currentTarget.value)}
          />
        </div>
        <div className="w-full">
          <Table
            expandable={{
              expandedRowRender: (record) => expandedRowDetail(record),
            }}
            rowKey={(record) => record.nsm}
            columns={columnTmp}
            dataSource={dataTable}
            loading={loading}
            bordered
          />
        </div>
      </div>
    </>
  );
};

export default BukuKasUmumKonsolidasiMin;
