/** @format */
import React, { useState, useEffect } from "react";
import { notification, Alert, Button, Modal } from "antd";
import { useHistory } from "react-router-dom";
import { getLaporanRAPBMDashboard } from "../../services/v2/reportservice";

const notifAlert = (props) => {
  switch (props.type) {
    case "error":
      Modal.error({
        title: "Kesalahan",
        content: props.description,
        centered: true,
        wrapClassName: "erkam-custom-modal",
      });
      break;
    case "success":
      Modal.success({
        title: "Sukses",
        content: props.description,
        centered: true,
        wrapClassName: "erkam-custom-modal",
      });
      break;
    case "warning":
      Modal.warning({
        title: "Perhatian",
        content: props.description,
        centered: true,
        wrapClassName: "erkam-custom-modal",
      });
      break;
    case "info":
      Modal.info({
        title: "Information",
        content: props.description,
        centered: true,
        wrapClassName: "erkam-custom-modal",
      });
      break;
  }
};
export default notifAlert;

export const notificationAlert = (props) => {
  return notification[props.type]({
    message:
      (props.type === "error" && "Kesalahan") ||
      (props.type === "success" && "Berhasil") ||
      (props.type === "warning" && "Perhatian") ||
      (props.type === "info" && "Information"),
    description: props.description,
  });
};

type validModal = {
  dataAuth: any;
  dataYear: any;
};
export const AlertValidasiBos = ({ dataAuth, dataYear }: validModal) => {
  const route = useHistory();
  const [openAlert, setOpenAlert] = useState<any>(false);
  const [keterangan, setKeterangan] = useState<any>(null);
  const getData = async () => {
    if (dataAuth?.group_role === "madrasah") {
      const tmpLaporanRapbmDashboard =
        (await getLaporanRAPBMDashboard({
          tahun: dataYear,
        })) || null;
      if (
        tmpLaporanRapbmDashboard &&
        tmpLaporanRapbmDashboard?.anggaranSisa < 0
      ) {
        setOpenAlert(true);
        let keteranganTmp = (
          <>
            Mohon Maaf untuk menyesuaikan <strong>Rencana Belanja</strong>{" "}
            dikarenakan ada selisih sebesar{" "}
            <strong>Rp {tmpLaporanRapbmDashboard?.anggaranSisa}</strong>,
            silahkan sesuaikan <strong>Rencana Belanja Pagu Definitif</strong>{" "}
            anda.{" "}
            <Button
              type="text"
              onClick={() => route.push("/rencana/definitif/belanja")}>
              <strong>Klik Disini</strong>
            </Button>
          </>
        );
        setKeterangan(keteranganTmp);
      }
    }
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataAuth]);

  return (
    (openAlert && (
      <div className="px-5 mt-4">
        <Alert
          message="Perhatian"
          description={keterangan}
          type="error"
          showIcon
        />
      </div>
    )) ||
    ""
  );
};
