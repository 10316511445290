/** @format */

import React, { useState, useEffect } from "react";
import { Modal, Button } from "antd";
import { SettingOutlined } from "@ant-design/icons";
import DanaBos from "../../assets/img/danabos.svg";
import { getAlokasiDanaBos } from "../../services/v2/notificationservice";
import { getPlanningAll } from "../../services/v2/planningservice";
import { formatCurr } from "../../utils/helper";
import { useHistory } from "react-router-dom";
import {
  postAlokasiSumberDanaBos,
  updateAlokasiSumberDanaBos,
} from "../../services/v2/planningservice/rencanapendapatandefinitif";
import { useDispatch } from "react-redux";
import { setStore } from "../../redux/actions";

type validModal = {
  dataAuth: any;
  dataStore: any;
  dataYear: any;
};
const ModalValidasiDanaBos = ({
  dataAuth,
  dataStore,
  dataYear,
}: validModal) => {
  const route = useHistory();
  const dispatch = useDispatch();
  const [open, setOpen] = useState<any>(false);
  const [loading, setLoading] = useState<any>(false);
  const [action, setAction] = useState<any>("");
  const [dataPortalBos, setDataPortalBos] = useState<any>(null);
  const [dataPortalBosErkam, setDataPortalBosErkam] = useState<any>(null);
  const dataSiswa = dataStore?.madrasahProfile?.students || [];
  const profileMadrasah = dataStore?.madrasahProfile || null;

  const handlePlanning = async () => {
    setLoading(true);
    if (action === "Buat") {
      try {
        await postAlokasiSumberDanaBos(
          dataPortalBos?.apbn_bos_semester1,
          dataPortalBos?.apbn_bos_semester2,
          dataYear,
          dataSiswa.length ? dataSiswa[0].jumlah_siswa : 0,
        );
        route.push({
          pathname: "/rencana/definitif/pendapatan",
        });
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        await updateAlokasiSumberDanaBos(
          dataPortalBos?.apbn_bos_semester2,
          dataYear,
        );
        const rencanaRekapSumberDanaBelanjaDefinitif = await getPlanningAll(
          "rencana-rekap-sumber-dana-belanja-definitif",
          { tahun: dataYear },
        );
        const rencanaPendapatanDefinitif = await getPlanningAll(
          "rencana-pendapatan",
          { tahun: dataYear },
        );
        const dataTmp = {
          rencanaPendapatanDefinitif,
          rencanaRekapSumberDanaBelanjaDefinitif,
        };
        setTimeout(() => {
          dispatch(setStore(dataTmp));
        }, 100);
        route.push({
          pathname: "/rencana/definitif/pendapatan",
        });
      } catch (error) {
        console.log(error);
      }
    }
    setOpen(false);
    setLoading(false);
  };

  const getData = async () => {
    setLoading(true);
    if (
      dataAuth?.group_role === "madrasah" &&
      dataAuth?.madrasah?.status === "s" &&
      dataYear === 2023
    ) {
      const dataAlokasiBos =
        (await getAlokasiDanaBos(dataAuth?.madrasah?.nsm, dataYear)) || null;

      const dataAlokasiBosErkam =
        (await getPlanningAll("portal-bos", {
          nsm: dataAuth?.madrasah?.nsm,
          tahun: dataYear,
        })) || null;
      setDataPortalBos(dataAlokasiBos);
      setDataPortalBosErkam(dataAlokasiBosErkam);
      if (dataAlokasiBos && dataAlokasiBosErkam) {
        if (
          dataAlokasiBosErkam?.apbn_bos_semester2 >
          dataAlokasiBos?.apbn_bos_semester2
        ) {
          setOpen(true);
          setAction("Ubah");
        } else if (
          dataAlokasiBosErkam?.apbn_bos_semester2 <
          dataAlokasiBos?.apbn_bos_semester2
        ) {
          setAction("Tambah");
          setOpen(true);
        } else {
          setAction("");
          setOpen(false);
        }
      } else if (dataAlokasiBos && !dataAlokasiBosErkam) {
        setOpen(true);
        setAction("Buat");
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataAuth, profileMadrasah]);

  return (
    <Modal
      title="Informasi Penyesuaian Alokasi BOS"
      open={open}
      destroyOnClose={false}
      footer={null}
      width={800}
      wrapClassName="modalBos"
      centered
      closable={false}>
      <div className="flex flex-col-reverse md:flex-row gap-4 mb-2 p-2">
        <div className="flex-1">
          <div className="text-3xl font-bold mb-2">
            Penyesuaian
            <br /> Alokasi BOS!
          </div>
          <div className="mb-10">
            {(action === "Buat" && (
              <>
                Terdapat penyesuaian Alokasi Dana BOS. <br />
                Madrasah Anda mendapatkan <strong>Alokasi Dana Bos</strong>
                <br />
                senilai <strong>Rp</strong>{" "}
                <span className="text-green-500">
                  {formatCurr(dataPortalBos?.nominal)}
                </span>
              </>
            )) ||
              (action === "Tambah" && (
                <>
                  Terdapat penyesuaian Alokasi Dana BOS. <br />
                  Madrasah Anda mendapatkan <strong>Penambahan</strong>
                  <br />
                  Alokasi Dana dan sebelumnya <strong>Rp</strong>{" "}
                  <span className="text-red-500">
                    {formatCurr(dataPortalBosErkam?.apbn_bos_semester2)}
                  </span>
                  <br />
                  menjadi <strong>Rp</strong>{" "}
                  <span className="text-green-500">
                    {formatCurr(dataPortalBos?.apbn_bos_semester2)}
                  </span>
                </>
              )) ||
              (action === "Ubah" && (
                <>
                  Terdapat penyesuaian Alokasi Dana BOS. <br />
                  Madrasah Anda mendapatkan <strong>Penyesuaian</strong>
                  <br />
                  Alokasi Dana dan sebelumnya <strong>Rp</strong>{" "}
                  <span className="text-red-500">
                    {formatCurr(dataPortalBosErkam?.apbn_bos_semester2)}
                  </span>
                  <br />
                  menjadi <strong>Rp</strong>{" "}
                  <span className="text-green-500">
                    {formatCurr(dataPortalBos?.apbn_bos_semester2)}
                  </span>
                </>
              ))}
          </div>
          <Button
            type="primary"
            shape="round"
            loading={loading}
            icon={<SettingOutlined />}
            onClick={handlePlanning}
            size="large">
            {action} Perencanaan
          </Button>
        </div>
        <div className="flex-1">
          <img alt="dana Bos" className="w-4/5" src={DanaBos} />
        </div>
      </div>
    </Modal>
  );
};
export default ModalValidasiDanaBos;
