/** @format */

import React, { useState } from "react";
import { Upload } from "antd";
import axios from "axios";
import ModalLihatDokumenBos from "../Modal/ModalLihatDokumenBos";

type uploadsType = {
  fileList: any;
  tmpFile: any;
};
export const UploadPriview = ({ fileList, tmpFile }: uploadsType) => {
  const [fileShow, setFileShow] = useState<any>(null);
  const [openModal, setOpenModal] = useState<any>(null);

  const handlePreview = (values: any) => {
    let filePriview: any =
      tmpFile.find((item) => item?.id === values?.uid) || null;
    let tmpMimeTipe: any = mimeTipeFile(filePriview);
    axios
      .get(values?.url, { responseType: "arraybuffer" })
      .then((response: any) => {
        const blob = new Blob([response.data], {
          type: tmpMimeTipe,
        });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        setOpenModal(true);
        setFileShow(link);
      });
  };

  const mimeTipeFile = (tmpFileSelect: any) => {
    let mimeTipe: any = null;
    switch (tmpFileSelect?.fileType) {
      case "jpg":
      case "jpeg":
        mimeTipe = "image/jpeg";
        break;
      case "png":
        mimeTipe = "image/png";
        break;
      case "pdf":
        mimeTipe = "application/pdf";
        break;
      default:
        mimeTipe = "application/pdf";
        break;
    }
    return mimeTipe;
  };

  const handleClose = () => {
    setOpenModal(false);
    setFileShow(null);
  };

  return (
    <div>
      <h3>Dokumen </h3>
      <Upload
        listType="picture-card"
        fileList={fileList}
        showUploadList={{ showRemoveIcon: false }}
        onPreview={handlePreview}>
        {fileList.length >= 8 ? null : ""}
      </Upload>
      <ModalLihatDokumenBos
        openModalPriview={openModal}
        pdfFile={fileShow}
        onClose={handleClose}
      />
    </div>
  );
};
