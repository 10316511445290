/** @format */

import React, { useState, useEffect } from "react";
import { BreadCrumb } from "../../../components";
import moment from "moment";
import "moment/locale/id";
import { Table, Space } from "antd";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import * as FileSaver from "file-saver";
import { formatCurr, uuidv4 } from "../../../utils/helper";
import ExcelJS, { Alignment, Borders, Fill, Font } from "exceljs";
import { useDispatch, useSelector } from "react-redux";
import { ButtonDropdownExport } from "../../../components/Button";
// import { getLaporanRealisasi } from "../../../services/v2/realizationservice/laporanservices";
import { getMadrasah } from "../../../services/v2/usermanservice/madrasahservices";
import notifAlert from "../../../components/NotifAlert";
import FilterMadrasah from "../Component/FilterMadrasah";
import { setStore } from "../../../redux/actions";
import { getLaporanAPBMPlanning } from "../../../services/v2/planningservice/laporanservices";

const LaporanRealisasi = () => {
  const itemBreadcrumb = [
    { path: "/", breadcrumbName: "Home" },
    { path: "/", breadcrumbName: "Laporan" },
    { path: "/", breadcrumbName: "Laporan Realisasi" },
    { path: "/", breadcrumbName: "Realisasi Keuangan" },
  ];
  const auths = useSelector((state: any) => state.auth);
  const store = useSelector((state: any) => state.store);
  const profile = store?.madrasahProfile || null;
  const refKabkota = store.kabkota || [];
  const refProvinsi = store.provinsi || [];
  const refKecamatan = store.kecamantan || [];
  const auth = auths?.data || null;
  const groupRole = auth?.group_role || "";
  const dispatch = useDispatch();
  const tmpListMadrasah = store?.listMadrasah || [];
  const [tableData, setTableData] = useState<any>(null);
  const [tmpMadrasah, setTmpMadrasah] = useState<any>([]);
  const [dataMadrasah, setDataMadrasah] = useState<any>(null);
  // const [page, setPage] = useState(1);
  // const [pageSize, setPageSize] = useState(10);
  const [tmpFilter, setTmpFilter] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  let totalSisa = 0;

  // let tmpDataRencana = tableData?.rencanaAnggaranList || [];
  // let tmpDataRealisasi = tableData?.realisasiList || [];
  let listFilter: any = [];
  let listBelanja: any = [];
  let listSumberDana: any = [];

  tableData?.length &&
    // eslint-disable-next-line array-callback-return
    tableData.map((item) => {
      if (!listFilter.includes(item.kodeSumberDana)) {
        listSumberDana.push({
          kodeSumberDana: item.kodeSumberDana,
          anggaranPendapatan: item?.anggaranPendapatan || 0,
          namaSumberDana: item.namaSumberdana,
          realisasiPendapatan: item?.realisasiPendapatan || 0,
        });
        listFilter.push(item.kodeSumberDana);
      }

      listBelanja.push({
        kodeSnp: item.kodeSnp,
        namaSnp: item.namaSnp,
        kodeSumberDana: item.kodeSumberDana,
        anggaranBelanja: item?.anggaranBelanja || 0,
        realisasiBelanja: item?.realisasiBelanja || 0,
        listKegiatan: item?.kegiatans,
      });
    });

  let dataTable: any = [];
  listSumberDana.length &&
    // eslint-disable-next-line array-callback-return
    listSumberDana.map((item) => {
      let listTable: any = [
        {
          noUrut: 1,
          uraian: "SUMBER DANA",
          anggaranPendapatan: null,
          realisasiPendapatan: null,
          persenPendapatan: null,
          anggaranBelanja: null,
          realisasiBelanja: null,
          persenBelanja: null,
          totalSaldo: null,
          persenSaldo: null,
        },
        {
          noUrut: "A.",
          uraian: item.namaSumberDana || "-",
          anggaranPendapatan: item.anggaranPendapatan,
          realisasiPendapatan: item.realisasiPendapatan,
          persenPendapatan:
            Math.round(
              (item.realisasiPendapatan / item.anggaranPendapatan) * 100,
            ) + "%",
          anggaranBelanja: null,
          realisasiBelanja: null,
          persenBelanja: null,
          totalSaldo: item.anggaranPendapatan || 0,
          persenSaldo: null,
        },
      ];
      let listPengeluaran: any = [
        {
          noUrut: 2,
          uraian: "BELANJA",
          anggaranPendapatan: null,
          realisasiPendapatan: null,
          persenPendapatan: null,
          anggaranBelanja: null,
          realisasiBelanja: null,
          persenBelanja: null,
          totalSaldo: null,
          persenSaldo: null,
        },
      ];
      let detailPengeluaran: any = [];
      let totalPengeluaran: any = 0;
      let tmpListBelanja =
        listBelanja.filter(
          (items) => items.kodeSumberDana === item.kodeSumberDana,
        ) || [];
      tmpListBelanja.length &&
        tmpListBelanja
          .sort((a, b) => a.kodeSnp - b.kodeSnp)
          // eslint-disable-next-line array-callback-return
          .map((items, i) => {
            let no = i + 1;
            totalPengeluaran += items.realisasiBelanja;
            let itemPengeluaran = {
              noUrut: `A.${no}`,
              uraian: items.namaSnp || null,
              anggaranPendapatan: null,
              realisasiPendapatan: null,
              persenPendapatan: null,
              anggaranBelanja: items.anggaranBelanja,
              realisasiBelanja: items.realisasiBelanja,
              persenBelanja: !isNaN(
                Math.round(
                  (items.realisasiBelanja / items.anggaranBelanja) * 100,
                ),
              )
                ? Math.round(
                    (items.realisasiBelanja / items.anggaranBelanja) * 100,
                  ) + "%"
                : "",
              totalSaldo: null,
              persenSaldo: null,
              key: uuidv4(),
              ...items,
            };
            detailPengeluaran.push(itemPengeluaran);
          });
      listPengeluaran = [...listPengeluaran, ...detailPengeluaran];
      listTable =
        (detailPengeluaran.length && [...listTable, ...listPengeluaran]) ||
        listTable;
      let dataTotal: any = {
        nama: item.namaSumberDana || "-",
        totalPendapatan: item.realisasiPendapatan || 0,
        totalBelanja: totalPengeluaran,
        persenTotal: !isNaN(
          Math.round((totalPengeluaran / item.realisasiPendapatan) * 100),
        )
          ? Math.round((totalPengeluaran / item.realisasiPendapatan) * 100) +
            "%"
          : "",
        sisaSaldo: item.realisasiPendapatan - totalPengeluaran,
        listTable: listTable,
      };
      dataTable.push(dataTotal);
    });

  const getData = async (tmpFilter) => {
    setLoading(true);
    if (groupRole !== "madrasah") {
      if (tmpListMadrasah.length === 0) {
        let filter: any = "";
        if (groupRole === "provinsi") {
          filter = `?kodeProvinsi=${auth?.kode_provinsi}`;
        }
        if (groupRole === "kabkota") {
          filter = `?kodeProvinsi=${auth?.kode_provinsi}&kode_kabkota=${auth?.kode_kabkota}`;
        }
        const madrasah = await getMadrasah(filter);
        const listMadrasah = madrasah || [];
        setTimeout(() => {
          dispatch(setStore({ listMadrasah }));
        }, 100);
        setTmpMadrasah(madrasah);
      } else {
        setTmpMadrasah(tmpListMadrasah);
      }
      const payload = {
        tahun: auth?.isTahun,
        madrasahId: tmpFilter?.madrasahId,
      };
      if (tmpFilter?.madrasahId) {
        const resMadrasah = tmpListMadrasah.find((item) =>
          item.id.includes(tmpFilter?.madrasahId),
        );
        setDataMadrasah(resMadrasah);
        let res: any = await getLaporanAPBMPlanning(payload);
        if (auth?.kode_role === "bkba_pengawas") {
          res = res.filter((item) => item.kodeSumberDana === "ba_rehab_berat");
        }
        setTableData(res || null);
      }
    } else {
      setDataMadrasah(profile);
      const payload = {
        tahun: auth?.isTahun,
        madrasahId: auth?.madrasah?.id,
      };
      const res = await getLaporanAPBMPlanning(payload);
      setTableData(res || null);
    }
    setLoading(false);
  };

  useEffect(() => {
    getData(tmpFilter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tmpFilter, auth?.isTahun]);

  const handleChangeValues = (values) => {
    setTmpFilter(values);
    // getData(values);
  };

  /**
   * Data untuk tabel children pertama
   */
  let columns: any = [
    {
      title: "No",
      key: "noUrut",
      align: "center",
      dataIndex: "noUrut",
      width: 80,
      render: (noUrut) => (
        <div
          className={`text-left ${
            ([1, 2].includes(noUrut) && "font-bold") || ""
          } `}>
          {noUrut}
        </div>
      ),
    },
    {
      title: "Uraian",
      key: "uraian",
      dataIndex: "uraian",
      align: "center",
      width: 300,
      render: (uraian, record) => (
        <div
          className={`text-left ${
            ([1, 2].includes(record.noUrut) && "font-bold") || ""
          } `}>
          {uraian}
        </div>
      ),
    },
    {
      title: "Pendapatan",
      align: "center",
      key: "pendapatan",
      children: [
        {
          title: "Anggaran",
          key: "anggaranPendapatan",
          dataIndex: "anggaranPendapatan",
          width: 200,
          render: (anggaranPendapatan) =>
            (anggaranPendapatan && (
              <div className="text-right">{formatCurr(anggaranPendapatan)}</div>
            )) ||
            "",
        },
        {
          title: "Realisasi",
          key: "realisasiPendapatan",
          children: [
            {
              title: "Rupiah",
              key: "realisasiPendapatan",
              dataIndex: "realisasiPendapatan",
              width: 200,
              render: (realisasiPendapatan) =>
                (realisasiPendapatan && (
                  <div className="text-right">
                    {formatCurr(realisasiPendapatan)}
                  </div>
                )) ||
                "",
            },
            {
              title: "%",
              key: "persenPendapatan",
              dataIndex: "persenPendapatan",
              width: 80,
            },
          ],
        },
      ],
    },
    {
      title: "Belanja",
      align: "center",
      key: "belanja",
      children: [
        {
          title: "Anggaran",
          key: "anggaranBelanja",
          dataIndex: "anggaranBelanja",
          width: 200,
          render: (anggaranBelanja) =>
            (anggaranBelanja && (
              <div className="text-right">{formatCurr(anggaranBelanja)}</div>
            )) ||
            "",
        },
        {
          title: "Realisasi",
          key: "realisasiBelanja",
          children: [
            {
              title: "Rupiah",
              key: "realisasiBelanja",
              dataIndex: "realisasiBelanja",
              width: 200,
              render: (realisasiBelanja) =>
                (realisasiBelanja && (
                  <div className="text-right">
                    {formatCurr(realisasiBelanja)}
                  </div>
                )) ||
                "",
            },
            {
              title: "%",
              key: "persenBelanja",
              dataIndex: "persenBelanja",
              width: 80,
            },
          ],
        },
      ],
    },
    {
      title: "Saldo",
      align: "center",
      key: "saldo",
      children: [
        {
          title: "Rupiah",
          key: "totalSaldo",
          dataIndex: "totalSaldo",
          width: 200,
          render: (totalSaldo) =>
            (totalSaldo && (
              <div className="text-right">{formatCurr(totalSaldo)}</div>
            )) ||
            "",
        },
        {
          title: "%",
          key: "persenSaldo",
          dataIndex: "persenSaldo",
          width: 80,
        },
      ],
    },
  ];

  const handleExportExcel = async () => {
    const namaKecamatan =
      refKecamatan.find((item) => item.kode === dataMadrasah?.kode_kecamatan) ||
      null;
    const namaKabkota =
      refKabkota.find((item) => item.kode === dataMadrasah?.kode_kabkota) ||
      null;
    const namaProvinsi =
      refProvinsi.find((item) => item.kode === dataMadrasah?.kode_provinsi) ||
      null;
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const wb = new ExcelJS.Workbook();
    const ws = wb.addWorksheet("Data Realisasi");
    ws.mergeCells("A2:M2");

    const headerTitleCell = ws.getCell("A2");
    headerTitleCell.font = {
      size: 14,
      underline: true,
      bold: true,
    };
    let headCol: any = [
      { col: "A", size: 5 },
      { col: "B", size: 50 },
      { col: "C", size: 20 },
      { col: "D", size: 20 },
      { col: "E", size: 20 },
      { col: "F", size: 20 },
      { col: "G", size: 20 },
      { col: "H", size: 20 },
      { col: "I", size: 20 },
      { col: "J", size: 20 },
      { col: "K", size: 20 },
      { col: "L", size: 20 },
      { col: "M", size: 20 },
    ];

    headCol.map((item: any) => {
      return (ws.getColumn(`${item.col}`).width = item.size);
    });

    headerTitleCell.alignment = { vertical: "middle", horizontal: "center" };
    headerTitleCell.value = `RINCIAN REALISASI KEUANGAN MADRASAH T.A. ${auths?.isTahun}`;
    const listHeaderDetail = [
      {
        title: "Madrasah/PPS",
        value: `: ${dataMadrasah?.nama}`,
      },
      {
        title: "NSM",
        value: `: ${dataMadrasah?.nsm}`,
      },
      {
        title: "Kecamatan",
        value: `: ${namaKecamatan?.nama || "-"}`,
      },
      {
        title: "Kabupaten / Kota",
        value: `: ${namaKabkota?.nama || "-"}`,
      },
      {
        title: "Provinsi",
        value: `: ${namaProvinsi?.nama || "-"}`,
      },
    ];

    // eslint-disable-next-line array-callback-return
    listHeaderDetail.map((item, i) => {
      const row = i + 4;
      const headerTitleBody = ws.getCell(`A${row}`);
      const headerValueBody = ws.getCell(`C${row}`);

      headerTitleBody.font = {
        size: 12,
        underline: true,
        bold: true,
      };

      headerTitleBody.value = item.title;
      headerValueBody.font = {
        size: 12,
        underline: true,
      };
      headerValueBody.value = item.value;
    });

    let warnaPutih = "FFFFFF";
    let warnaHijau = "1A8300";
    // eslint-disable-next-line array-callback-return
    listHeaderDetail.map((item, i) => {
      const row = i + 4;
      ws.mergeCells(`A${row}:B${row}`);
      const headerTitleBody = ws.getCell(`A${row}`);
      const headerValueBody = ws.getCell(`C${row}`);
      headerTitleBody.font = {
        size: 12,
        underline: true,
        bold: true,
      };
      headerTitleBody.value = item.title;
      headerValueBody.font = {
        size: 12,
        underline: true,
      };
      headerValueBody.value = item.value;
    });

    // define styles
    const headerStyle = {
      font: {
        size: 11,
        underline: true,
        bold: true,
        color: { argb: warnaPutih },
      } as Partial<Font>,
      fill: {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: warnaHijau },
      } as Fill,
      border: {
        top: { style: "double", color: { argb: warnaPutih } },
        left: { style: "double", color: { argb: warnaPutih } },
        bottom: { style: "double", color: { argb: warnaPutih } },
        right: { style: "double", color: { argb: warnaPutih } },
      } as Partial<Borders>,
      alignment: {
        vertical: "middle",
        horizontal: "center",
      } as Partial<Alignment>,
    };

    // eslint-disable-next-line array-callback-return
    await (dataTable || []).map((item, i) => {
      let rowLast = ws.rowCount;
      // let tableData: any = [];
      // merge row each title based on data showed
      ws.mergeCells(`A${rowLast + 2}:A${rowLast + 4}`);
      ws.mergeCells(`B${rowLast + 2}:B${rowLast + 4}`);
      ws.mergeCells(`C${rowLast + 2}:E${rowLast + 2}`);
      ws.mergeCells(`C${rowLast + 3}:C${rowLast + 4}`);
      ws.mergeCells(`D${rowLast + 3}:D${rowLast + 4}`);
      ws.mergeCells(`E${rowLast + 3}:E${rowLast + 4}`);
      ws.mergeCells(`F${rowLast + 2}:M${rowLast + 2}`);
      ws.mergeCells(`F${rowLast + 3}:I${rowLast + 3}`);
      ws.mergeCells(`J${rowLast + 3}:M${rowLast + 3}`);

      let headerNo = ws.getCell(`A${rowLast + 2}:A${rowLast + 4}`);
      let headerUraian = ws.getCell(`B${rowLast + 2}:B${rowLast + 4}`);
      let headerPendapatan = ws.getCell(`C${rowLast + 2}`);
      let headerAnggaran = ws.getCell(`C${rowLast + 3}`);
      let headerRealisasi = ws.getCell(`D${rowLast + 3}`);
      let headerPercentage = ws.getCell(`E${rowLast + 3}`);
      let headerBelanja = ws.getCell(`F${rowLast + 2}`);
      let headerAnggaran2 = ws.getCell(`F${rowLast + 3}`);
      let headerRealisasi2 = ws.getCell(`J${rowLast + 3}`);
      let headerKoefisienRencana = ws.getCell(`F${rowLast + 4}`);
      let headerHargaRencana = ws.getCell(`G${rowLast + 4}`);
      let headerPajak = ws.getCell(`H${rowLast + 4}`);
      let headerTotalRencana = ws.getCell(`I${rowLast + 4}`);
      let headerKoefisienRealisasi = ws.getCell(`J${rowLast + 4}`);
      let headerHargaRealisasi = ws.getCell(`K${rowLast + 4}`);
      let headerPajakRealisasi = ws.getCell(`L${rowLast + 4}`);
      let headerTotalRealisasi = ws.getCell(`M${rowLast + 4}`);

      // define style each title

      headerNo.font = headerStyle.font;
      headerNo.fill = headerStyle.fill;
      headerNo.border = headerStyle.border;
      headerNo.alignment = headerStyle.alignment;
      headerNo.value = "NO";
      headerUraian.font = headerStyle.font;
      headerUraian.fill = headerStyle.fill;
      headerUraian.border = headerStyle.border;
      headerUraian.alignment = headerStyle.alignment;
      headerUraian.value = "Uraian";
      headerPendapatan.font = headerStyle.font;
      headerPendapatan.fill = headerStyle.fill;
      headerPendapatan.border = headerStyle.border;
      headerPendapatan.alignment = headerStyle.alignment;
      headerPendapatan.value = `Pendapatan`;
      headerAnggaran.font = headerStyle.font;
      headerAnggaran.fill = headerStyle.fill;
      headerAnggaran.border = headerStyle.border;
      headerAnggaran.alignment = headerStyle.alignment;
      headerAnggaran.value = `Anggaran`;
      headerRealisasi.font = headerStyle.font;
      headerRealisasi.fill = headerStyle.fill;
      headerRealisasi.border = headerStyle.border;
      headerRealisasi.alignment = headerStyle.alignment;
      headerRealisasi.value = `Realisasi`;
      headerPercentage.font = headerStyle.font;
      headerPercentage.fill = headerStyle.fill;
      headerPercentage.border = headerStyle.border;
      headerPercentage.alignment = headerStyle.alignment;
      headerPercentage.value = `%`;
      headerBelanja.font = headerStyle.font;
      headerBelanja.fill = headerStyle.fill;
      headerBelanja.border = headerStyle.border;
      headerBelanja.alignment = headerStyle.alignment;
      headerBelanja.value = `Belanja`;
      headerAnggaran2.font = headerStyle.font;
      headerAnggaran2.fill = headerStyle.fill;
      headerAnggaran2.border = headerStyle.border;
      headerAnggaran2.alignment = headerStyle.alignment;
      headerAnggaran2.value = `Anggaran`;
      headerRealisasi2.font = headerStyle.font;
      headerRealisasi2.fill = headerStyle.fill;
      headerRealisasi2.border = headerStyle.border;
      headerRealisasi2.alignment = headerStyle.alignment;
      headerRealisasi2.value = `Realisasi`;
      headerKoefisienRencana.font = headerStyle.font;
      headerKoefisienRencana.fill = headerStyle.fill;
      headerKoefisienRencana.border = headerStyle.border;
      headerKoefisienRencana.alignment = headerStyle.alignment;
      headerKoefisienRencana.value = `Koefisien`;
      headerHargaRencana.font = headerStyle.font;
      headerHargaRencana.fill = headerStyle.fill;
      headerHargaRencana.border = headerStyle.border;
      headerHargaRencana.alignment = headerStyle.alignment;
      headerHargaRencana.value = `Harga Satuan`;
      headerPajak.font = headerStyle.font;
      headerPajak.fill = headerStyle.fill;
      headerPajak.border = headerStyle.border;
      headerPajak.value = `Pajak`;
      headerPajak.alignment = headerStyle.alignment;
      headerTotalRencana.font = headerStyle.font;
      headerTotalRencana.fill = headerStyle.fill;
      headerTotalRencana.border = headerStyle.border;
      headerTotalRencana.alignment = headerStyle.alignment;
      headerTotalRencana.value = `Jumlah`;
      headerKoefisienRealisasi.font = headerStyle.font;
      headerKoefisienRealisasi.fill = headerStyle.fill;
      headerKoefisienRealisasi.border = headerStyle.border;
      headerKoefisienRealisasi.alignment = headerStyle.alignment;
      headerKoefisienRealisasi.value = `Koefisien`;
      headerHargaRealisasi.font = headerStyle.font;
      headerHargaRealisasi.fill = headerStyle.fill;
      headerHargaRealisasi.border = headerStyle.border;
      headerHargaRealisasi.alignment = headerStyle.alignment;
      headerHargaRealisasi.value = `Harga Satuan`;
      headerPajakRealisasi.font = headerStyle.font;
      headerPajakRealisasi.fill = headerStyle.fill;
      headerPajakRealisasi.border = headerStyle.border;
      headerPajakRealisasi.alignment = headerStyle.alignment;
      headerPajakRealisasi.value = `Pajak`;
      headerTotalRealisasi.font = headerStyle.font;
      headerTotalRealisasi.fill = headerStyle.fill;
      headerTotalRealisasi.border = headerStyle.border;
      headerTotalRealisasi.alignment = headerStyle.alignment;
      headerTotalRealisasi.value = `Jumlah`;
      let tableData: any = parsingData(item.listTable);
      // #Body
      tableData.length &&
        // eslint-disable-next-line array-callback-return
        tableData.map((items) => {
          ws.addRow(items);
        });

      // #Footer
      rowLast = ws.rowCount;
      ws.addRow([
        "Total Belanja",
        "",
        "",
        (item.totalPendapatan && formatCurr(item.totalPendapatan)) || 0,
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        (item.totalBelanja && formatCurr(item.totalBelanja)) || 0,
      ]);
      ws.mergeCells(`A${rowLast + 1}:B${rowLast + 1}`);
      ws.addRow([
        `Sisa Saldo Sumber Dana ${item.nama || ""}`,
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        `${formatCurr(item.totalPendapatan - item.totalBelanja)}`,
        "",
      ]);
      ws.mergeCells(`A${rowLast + 2}:H${rowLast + 2}`);
      ws.mergeCells(`I${rowLast + 2}:M${rowLast + 2}`);
      // add style each footer
      for (
        let charCode = "A".charCodeAt(0);
        charCode <= "M".charCodeAt(0);
        charCode++
      ) {
        let currentLetter = String.fromCharCode(charCode);
        for (let i = 1; i <= 2; i++) {
          let footerTotal = ws.getCell(`${currentLetter}${rowLast + i}`);

          footerTotal.font = headerStyle.font;
          footerTotal.fill = headerStyle.fill;
          footerTotal.border = headerStyle.border;
          footerTotal.alignment = headerStyle.alignment;
        }
      }
    });

    ws.addRow([]);

    ws.mergeCells(`A${ws.rowCount}:H${ws.rowCount}`);
    ws.mergeCells(`I${ws.rowCount}:J${ws.rowCount}`);

    ws.addRow([]);
    ws.addRow([]);
    ws.addRow([
      "Mengetahui",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      `${moment().format("dddd, DD MMMM YYYY")}`,
      "",
    ]);
    ws.addRow([
      "Kepala Madrasah",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "Bendahara Madrasah",
      "",
    ]);
    ws.addRow([]);
    ws.addRow([]);
    ws.addRow([]);
    ws.addRow([
      (dataMadrasah?.nama_kepala && `( ${dataMadrasah?.nama_kepala} )`) ||
        "(.................)",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      (dataMadrasah?.nama_bendahara && `( ${dataMadrasah?.nama_bendahara} )`) ||
        "(.................)",
      "",
    ]);

    // Auto-fit column widths based on content
    ws.columns.forEach((column: any) => {
      let maxLength = 0;
      column.eachCell({ includeEmpty: true }, (cell) => {
        const cellValue = cell.value ? cell.value.toString() : "";
        const columnLength = cellValue.length;
        if (columnLength > maxLength) {
          maxLength = columnLength;
        }
      });
      column.width = maxLength < 10 ? 10 : maxLength + 2; // Set a minimum width of 10
    });

    const excelBuffer = await wb.xlsx.writeBuffer();
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, "Laporan-Realisasi-Keuangan" + fileExtension);
  };

  function handleExportPdf() {
    const namaKecamatan =
      refKecamatan.find((item) => item.kode === dataMadrasah?.kode_kecamatan) ||
      null;
    const namaKabkota =
      refKabkota.find((item) => item.kode === dataMadrasah?.kode_kabkota) ||
      null;
    const namaProvinsi =
      refProvinsi.find((item) => item.kode === dataMadrasah?.kode_provinsi) ||
      null;

    const doc: any = new jsPDF({
      putOnlyUsedFonts: true,
      orientation: "landscape",
      floatPrecision: 16,
    });

    var fline = 10;
    doc.setFont("times", "bold");
    doc.setFontSize(14);

    doc.text(`RINCIAN REALISASI KEUANGAN MADRASAH`, 150, 12, {
      align: "center",
    });
    doc.text(`TAHUN ANGGARAN ${auths?.isTahun}`, 150, 18, { align: "center" });
    doc.setFont("times", "normal");
    doc.setFontSize(10);
    doc.text("Madrasah/PPS", fline, 30);
    doc.text(`: ${dataMadrasah?.nama}`, fline + 60, 30);
    doc.text("NSM", fline, 35);
    doc.text(`: ${dataMadrasah?.nsm}`, fline + 60, 35);
    doc.text("Kecamatan", fline, 40);
    doc.text(`: ${namaKecamatan?.nama || "-"}`, fline + 60, 40);
    doc.text("Kabupaten / Kota", fline, 45);
    doc.text(`: ${namaKabkota?.nama || "-"}`, fline + 60, 45);
    doc.text("Provinsi", fline, 50);
    doc.text(`: ${namaProvinsi?.nama || "-"}`, fline + 60, 50);

    let las: any = [];
    dataTable.length &&
      // eslint-disable-next-line array-callback-return
      dataTable.map((item, i) => {
        const head = [
          [
            { content: "No", rowSpan: 3 },
            { content: "Keterangan", rowSpan: 3 },
            { content: "Pendapatan", colSpan: 3 },
            { content: "Belanja", colSpan: 8 },
          ],
          [
            { content: "Anggaran", rowSpan: 2 },
            { content: "Realisasi", rowSpan: 2 },
            { content: "%", rowSpan: 2 },
            { content: "Anggaran", colSpan: 4 },
            { content: "Realisasi", colSpan: 4 },
          ],
          [
            { content: "Koefisien" },
            { content: "Harga" },
            { content: "Pajak" },
            { content: "Jumlah" },
            { content: "Koefisien" },
            { content: "Harga" },
            { content: "Pajak" },
            { content: "Jumlah" },
          ],
        ];

        const foot = [
          [
            { content: `Total `, colSpan: 3, halign: "left" },
            // null,
            {
              content:
                (item.totalPendapatan && formatCurr(item.totalPendapatan)) || 0,
              colSpan: 2,
              halign: "right",
            },
            { content: ``, colSpan: 7 },
            {
              content:
                (item.totalBelanja && formatCurr(item.totalBelanja)) || 0,
              halign: "right",
            },
          ],
          [
            {
              content: `Sisa Saldo Sumber Dana ${item.nama || ""}`,
              colSpan: 12,
              halign: "left",
            },
            {
              content: `${formatCurr(
                item.totalPendapatan - item.totalBelanja,
              )}`,

              halign: "right",
            },
          ],
        ];

        let tableData: any = parsingData(item.listTable);

        if (i === 0) {
          autoTable(doc, {
            styles: { lineColor: 244, lineWidth: 0.1, fontSize: 7 },
            margin: { left: 4, right: 4 },
            headStyles: {
              halign: "center",
              valign: "middle",
              fillColor: [0, 128, 0],
            }, // Cells in first column centered and green
            footStyles: {
              halign: "center",
              valign: "middle",
              fillColor: [0, 128, 0],
            }, // Cells in first column centered and green
            startY: 55,
            head: head,
            body: tableData,
            foot: foot,
            showHead: "firstPage",
            showFoot: "lastPage",
            columnStyles: {
              0: { halign: "center", cellWidth: 8 },
              1: { halign: "left", cellWidth: 65 },
              2: { halign: "right", cellWidth: 22 },
              3: { halign: "right", cellWidth: 22 },
              4: { halign: "center", cellWidth: 12 },
              5: { halign: "center", cellWidth: 15 },
              6: { halign: "right", cellWidth: 20 },
              7: { halign: "right", cellWidth: 22 },
              8: { halign: "right", cellWidth: 23 },
              9: { halign: "center", cellWidth: 15 },
              10: { halign: "right", cellWidth: 20 },
              11: { halign: "right", cellWidth: 22 },
              12: { halign: "right", cellWidth: 23 },
            },
            didDrawPage: (d) => las.push(d.cursor),
          });
        } else {
          autoTable(doc, {
            styles: { lineColor: 244, lineWidth: 0.1, fontSize: 7 },
            margin: { left: 4, right: 4 },
            headStyles: {
              halign: "center",
              valign: "middle",
              fillColor: [0, 128, 0],
            }, // Cells in first column centered and green
            footStyles: {
              halign: "center",
              valign: "middle",
              fillColor: [0, 128, 0],
            }, // Cells in first column centered and green
            head: head,
            body: tableData,
            foot: foot,
            showHead: "firstPage",
            showFoot: "lastPage",
            columnStyles: {
              0: { halign: "center", cellWidth: 8 },
              1: { halign: "left", cellWidth: 65 },
              2: { halign: "right", cellWidth: 22 },
              3: { halign: "right", cellWidth: 22 },
              4: { halign: "center", cellWidth: 12 },
              5: { halign: "center", cellWidth: 15 },
              6: { halign: "right", cellWidth: 20 },
              7: { halign: "right", cellWidth: 22 },
              8: { halign: "right", cellWidth: 23 },
              9: { halign: "center", cellWidth: 15 },
              10: { halign: "right", cellWidth: 20 },
              11: { halign: "right", cellWidth: 22 },
              12: { halign: "right", cellWidth: 23 },
            },
            didDrawPage: (d) => las.push(d.cursor),
            // didDrawCell: (data) => {
            //   console.log(data.column.index);
            // },
          });
        }
      });

    let lastLine: any = doc.lastAutoTable.finalY + 5;
    const footerText = [
      {
        text: "Mengetahui",
        line: fline,
        space: 0,
        bottom: 10,
      },
      {
        text: moment().format("dddd, DD MMMM YYYY"),
        line: fline,
        space: 210,
        bottom: 0,
      },
      {
        text: "Kepala Madrasah",
        line: fline,
        space: 0,
        bottom: 5,
      },
      {
        text: "Bendahara Madrasah",
        line: fline,
        space: 210,
        bottom: 0,
      },
      {
        text:
          (dataMadrasah?.nama_kepala && `( ${dataMadrasah?.nama_kepala} )`) ||
          "(.................)",
        line: fline,
        space: 0,
        bottom: 30,
      },
      {
        text:
          (dataMadrasah?.nama_bendahara &&
            `( ${dataMadrasah?.nama_bendahara} )`) ||
          "(.................)",
        line: fline,
        space: 210,
        bottom: 0,
      },
    ];
    let tmpBottom = lastLine;
    // eslint-disable-next-line array-callback-return
    footerText.map((item) => {
      tmpBottom += item.bottom;
      if (tmpBottom > 195) {
        doc.addPage();
        tmpBottom = 15;
      }
      doc.text(item.text, fline + item.space, tmpBottom);
    });

    // doc.output("dataurlnewwindow", { filename: "laporan-realisasi-keuangan" });
    var blob = doc.output("blob", { filename: "laporan-realisasi-keuangan" });
    window.open(URL.createObjectURL(blob));
    notifAlert({
      type: "success",
      description: "Data berhasil di export",
    });
  }

  const parsingData = (listTable: any) => {
    let tableData: any = [];
    // eslint-disable-next-line array-callback-return
    listTable.length &&
      // eslint-disable-next-line array-callback-return
      listTable.map((items) => {
        tableData.push([
          items.noUrut,
          items.uraian,
          (items.anggaranPendapatan && formatCurr(items.anggaranPendapatan)) ||
            null,
          (items.realisasiPendapatan &&
            formatCurr(items.realisasiPendapatan)) ||
            null,
          items.persenPendapatan,
          null,
          null,
          null,
          (items.anggaranBelanja && formatCurr(items.anggaranBelanja)) || null,
          null,
          null,
          null,
          (items.realisasiBelanja && formatCurr(items.realisasiBelanja)) ||
            null,
        ]);
        items?.listKegiatan?.length &&
          // eslint-disable-next-line array-callback-return
          items?.listKegiatan?.map((iitem: any) => {
            let totalBelanja = hitungTotalAnggaran(
              iitem?.subKegiatans,
              "kegiatan",
            );

            tableData.push([
              iitem.kodeKegiatan,
              iitem.namaKegiatan,
              null,
              null,
              null,
              null,
              null,
              null,
              formatCurr(totalBelanja?.anggaranBelanja || 0),
              null,
              null,
              null,
              formatCurr(totalBelanja?.realisasiBelanja || 0),
            ]);

            iitem?.subKegiatans?.length &&
              // eslint-disable-next-line array-callback-return
              iitem?.subKegiatans?.map((iitems) => {
                let totalBelanjaSub: any = hitungTotalAnggaran(
                  iitems?.komponenBiayas,
                  "subKegiatan",
                );
                tableData.push([
                  null,
                  `${iitems.kodeSubKegiatan} ${iitems.namaSubKegiatan}`,
                  null,
                  null,
                  null,
                  null,
                  null,
                  null,
                  formatCurr(totalBelanjaSub?.anggaranBelanja || 0),
                  null,
                  null,
                  null,
                  formatCurr(totalBelanjaSub?.realisasiBelanja || 0),
                ]);
                iitems?.komponenBiayas?.length &&
                  // eslint-disable-next-line array-callback-return
                  iitems?.komponenBiayas?.map((iitemss: any) => {
                    // hitung rencana
                    let pajakRencana = handleHitungPajak(
                      iitemss?.pajak,
                      iitemss?.hargaSatuan,
                      iitemss?.persenPajak,
                    );
                    let hargaRencanaPajak = handleHitungSatuanPajak(
                      iitemss?.pajak,
                      iitemss?.hargaSatuan,
                      iitemss?.persenPajak,
                    );
                    let hargaRencana = pajakRencana + hargaRencanaPajak;
                    let totalRencana = hargaRencana * iitemss.totalKuantitas;

                    // hitung realisasi
                    let pajakRealisasi = handleHitungPajak(
                      iitemss?.pajak,
                      iitemss?.realisasiHargaSatuan,
                      iitemss?.persenPajak,
                    );
                    let hargaRealisasiPajak = handleHitungSatuanPajak(
                      iitemss?.pajak,
                      iitemss?.realisasiHargaSatuan,
                      iitemss?.persenPajak,
                    );
                    let hargaRealisasi = pajakRealisasi + hargaRealisasiPajak;
                    let totalRealisasi =
                      hargaRealisasi * iitemss.realisasiKuantitas;

                    tableData.push([
                      null,
                      `> ${iitemss.namaKomponenBiaya}`,
                      null,
                      null,
                      null,
                      `${iitemss.totalKuantitas} ${iitemss.satuan}`,
                      formatCurr(hargaRencanaPajak),
                      formatCurr(pajakRencana),
                      formatCurr(totalRencana),
                      `${iitemss.realisasiKuantitas} ${iitemss.satuan}`,
                      formatCurr(hargaRealisasiPajak),
                      formatCurr(pajakRealisasi),
                      formatCurr(totalRealisasi),
                    ]);
                  });
              });
          });
      });
    return tableData;
  };

  const handleExportFile = (type) => {
    if (dataTable.length > 0) {
      if (type === "pdf") {
        handleExportPdf();
      } else if (type === "excel") {
        handleExportExcel();
      }
    } else {
      notifAlert({
        type: "warning",
        description:
          "Harap pilih data yang ingin di export melalui fungsi filter!",
      });
    }
  };

  const expandedRowDetail = (data: any) => {
    let dataTableKegiatan: any = data?.listKegiatan?.length
      ? data?.listKegiatan
      : [];
    dataTableKegiatan = dataTableKegiatan.map((item: any) => {
      let anggaranBelanja: any = 0;
      let realisasiBelanja: any = 0;
      item?.subKegiatans?.length &&
        // eslint-disable-next-line array-callback-return
        item?.subKegiatans?.map((items) => {
          let anggaranBelanjaSub: any = 0;
          let realisasiBelanjaSub: any = 0;
          items?.komponenBiayas?.length &&
            // eslint-disable-next-line array-callback-return
            items?.komponenBiayas?.map((itemss: any) => {
              let pajakRealisasi = handleHitungPajak(
                itemss?.pajak,
                itemss?.realisasiHargaSatuan,
                itemss?.persenPajak,
              );

              let pajakRencana = handleHitungPajak(
                itemss?.pajak,
                itemss?.hargaSatuan,
                itemss?.persenPajak,
              );

              let hargaSatuanRealisasi =
                handleHitungSatuanPajak(
                  itemss?.pajak,
                  itemss?.realisasiHargaSatuan,
                  itemss?.persenPajak,
                ) + pajakRealisasi;
              let hargaSatuanRencana =
                handleHitungSatuanPajak(
                  itemss?.pajak,
                  itemss?.hargaSatuan,
                  itemss?.persenPajak,
                ) + pajakRencana;
              let totalRealisasi: any =
                hargaSatuanRealisasi * itemss?.realisasiKuantitas;
              let totalAnggaran: any =
                hargaSatuanRencana * itemss?.totalKuantitas;
              realisasiBelanjaSub += totalRealisasi;
              anggaranBelanjaSub += totalAnggaran;
            });
          realisasiBelanja += realisasiBelanjaSub;
          anggaranBelanja += anggaranBelanjaSub;
        });

      return {
        key: uuidv4(),
        realisasiBelanja: realisasiBelanja,
        anggaranBelanja: anggaranBelanja,
        ...item,
      };
    });

    const columnsDetail: any = [
      {
        title: "No",
        key: "no",
        width: 50,
        render: (_, record, i: number) => i + 1,
      },
      {
        title: "Kode",
        key: "kodeKegiatan",
        dataIndex: "kodeKegiatan",
        width: 50,
      },
      {
        title: "Kegiatan",
        key: "namaKegiatan",
        dataIndex: "namaKegiatan",
        width: 250,
      },
      {
        title: "Belanja",
        align: "center",
        key: "belanja",
        children: [
          {
            title: "Rencana (Rp.)",
            key: "anggaranBelanja",
            dataIndex: "anggaranBelanja",
            width: 200,
            render: (anggaranBelanja) => (
              <div className="text-right">{formatCurr(anggaranBelanja)}</div>
            ),
          },
          {
            title: "Realisasi (Rp.)",
            key: "realisasiBelanja",
            dataIndex: "realisasiBelanja",
            width: 200,
            render: (realisasiBelanja) => (
              <div className="text-right">{formatCurr(realisasiBelanja)}</div>
            ),
          },
        ],
      },
    ];

    return (
      <Table
        columns={columnsDetail}
        dataSource={dataTableKegiatan}
        pagination={false}
        expandable={{
          expandedRowRender: (record) => expandedRowDetailSub(record),
        }}
        bordered
      />
    );
  };

  const expandedRowDetailSub = (data: any) => {
    let dataTableSubKegiatan: any = data?.subKegiatans?.length
      ? data?.subKegiatans
      : [];
    dataTableSubKegiatan = dataTableSubKegiatan.map((item: any) => {
      let anggaranBelanja: any = 0;
      let realisasiBelanja: any = 0;
      item?.komponenBiayas?.length &&
        // eslint-disable-next-line array-callback-return
        item?.komponenBiayas?.map((items: any) => {
          let pajakRealisasi = handleHitungPajak(
            items?.pajak,
            items?.realisasiHargaSatuan,
            items?.persenPajak,
          );

          let pajakRencana = handleHitungPajak(
            items?.pajak,
            items?.hargaSatuan,
            items?.persenPajak,
          );

          let hargaSatuanRealisasi =
            handleHitungSatuanPajak(
              items?.pajak,
              items?.realisasiHargaSatuan,
              items?.persenPajak,
            ) + pajakRealisasi;
          let hargaSatuanRencana =
            handleHitungSatuanPajak(
              items?.pajak,
              items?.hargaSatuan,
              items?.persenPajak,
            ) + pajakRencana;
          let totalRealisasi: any =
            hargaSatuanRealisasi * items?.realisasiKuantitas;
          let totalAnggaran: any = hargaSatuanRencana * items?.totalKuantitas;
          realisasiBelanja += totalRealisasi;
          anggaranBelanja += totalAnggaran;
        });
      return {
        key: uuidv4(),
        realisasiBelanja: realisasiBelanja,
        anggaranBelanja: anggaranBelanja,
        ...item,
      };
    });
    const columnsDetail: any = [
      {
        title: "No",
        key: "no",
        width: 50,
        render: (_, record, i: number) => i + 1,
      },
      {
        title: "Kode",
        key: "kodeSubKegiatan",
        dataIndex: "kodeSubKegiatan",
        width: 50,
      },
      {
        title: "Sub Kegiatan",
        key: "namaSubKegiatan",
        dataIndex: "namaSubKegiatan",
        width: 250,
      },
      {
        title: "Belanja",
        align: "center",
        key: "belanja",
        children: [
          {
            title: "Rencana (Rp.)",
            key: "anggaranBelanja",
            dataIndex: "anggaranBelanja",
            width: 200,
            render: (anggaranBelanja) => (
              <div className="text-right">{formatCurr(anggaranBelanja)}</div>
            ),
          },
          {
            title: "Realisasi (Rp.)",
            key: "realisasiBelanja",
            dataIndex: "realisasiBelanja",
            width: 200,
            render: (realisasiBelanja) => (
              <div className="text-right">{formatCurr(realisasiBelanja)}</div>
            ),
          },
        ],
      },
    ];

    return (
      <Table
        columns={columnsDetail}
        dataSource={dataTableSubKegiatan}
        pagination={false}
        expandable={{
          expandedRowRender: (record) => expandedRowDetailSubRincian(record),
        }}
        bordered
      />
    );
  };

  const expandedRowDetailSubRincian = (dataSubRincian: any) => {
    let dataTableSub: any = dataSubRincian.komponenBiayas.length
      ? dataSubRincian.komponenBiayas
      : [];
    const columnsDetailSubRincian: any = [
      {
        title: "No",
        key: "no",
        width: 80,
        render: (_, record, i: number) => i + 1,
      },

      {
        title: "Nama",
        key: "namaKomponenBiaya",
        dataIndex: "namaKomponenBiaya",
        width: 250,
      },
      {
        title: "Rencana",
        align: "center",
        key: "rencana",
        children: [
          {
            title: "Harga Satuan",
            key: "hargaSatuan",
            // dataIndex: "hargaSatuan",
            width: 200,
            render: (record) => (
              <div className="text-right">
                {formatCurr(
                  handleHitungSatuanPajak(
                    record?.pajak,
                    record?.hargaSatuan,
                    record?.persenPajak,
                  ),
                )}
              </div>
            ),
          },
          {
            title: "Kuantitas",
            key: "totalKuantitas",
            // dataIndex: "totalKuantitas",
            width: 70,
            render: (record) => (
              <>{`${record.totalKuantitas} ${record.satuan}`}</>
            ),
          },
          {
            title: "Pajak",
            key: "pajakRencana",
            width: 70,
            render: (record) => {
              let pajakRencana = handleHitungPajak(
                record?.pajak,
                record?.hargaSatuan,
                record?.persenPajak,
              );

              return (
                <div className="text-right">{formatCurr(pajakRencana)}</div>
              );
            },
          },
          {
            title: "Total",
            key: "total",
            width: 200,
            render: (record) => {
              let pajakRencana = handleHitungPajak(
                record?.pajak,
                record?.hargaSatuan,
                record?.persenPajak,
              );
              let hargaRencanaPajak = handleHitungSatuanPajak(
                record?.pajak,
                record?.hargaSatuan,
                record?.persenPajak,
              );
              let hargaRencana = pajakRencana + hargaRencanaPajak;
              let totalRencana = hargaRencana * record.totalKuantitas;
              return (
                <div className="text-right">{formatCurr(totalRencana)}</div>
              );
            },
          },
        ],
      },
      {
        title: "Realisasi",
        align: "center",
        key: "realisasi",
        children: [
          {
            title: "Harga Satuan",
            key: "realisasiHargaSatuan",
            width: 200,
            render: (record) => (
              <div className="text-right">
                {formatCurr(
                  handleHitungSatuanPajak(
                    record?.pajak,
                    record?.realisasiHargaSatuan,
                    record?.persenPajak,
                  ),
                )}
              </div>
            ),
          },
          {
            title: "Kuantitas",
            key: "realisasiKuantitas",
            width: 70,
            render: (record) => (
              <>{`${record.realisasiKuantitas} ${record.satuan}`}</>
            ),
          },
          {
            title: "Pajak",
            key: "pajakRealisasi",
            // dataIndex: "totalKuantitas",
            width: 70,
            render: (record) => {
              let pajakRealisasi = handleHitungPajak(
                record?.pajak,
                record?.realisasiHargaSatuan,
                record?.persenPajak,
              );

              return (
                <div className="text-right">{formatCurr(pajakRealisasi)}</div>
              );
            },
          },
          {
            title: "Total",
            key: "totalRealisasi",
            width: 200,
            render: (record) => {
              let pajakRealisasi = handleHitungPajak(
                record?.pajak,
                record?.realisasiHargaSatuan,
                record?.persenPajak,
              );
              let hargaRealisasiPajak = handleHitungSatuanPajak(
                record?.pajak,
                record?.realisasiHargaSatuan,
                record?.persenPajak,
              );
              let hargaRealisasi = pajakRealisasi + hargaRealisasiPajak;
              let totalRealisasi = hargaRealisasi * record.realisasiKuantitas;
              return (
                <div className="text-right">{formatCurr(totalRealisasi)}</div>
              );
            },
          },
        ],
      },
    ];
    return (
      <Table
        columns={columnsDetailSubRincian}
        dataSource={dataTableSub}
        pagination={false}
        bordered
      />
    );
  };

  const handleHitungPajak = (
    type: any,
    hargaSatuan: any,
    koefisienPajak: any,
  ) => {
    let totalPajak =
      (type === "ppn" && Math.round(hargaSatuan * (koefisienPajak / 100))) ||
      (type === "termasukPajak" &&
        Math.round(
          hargaSatuan -
            Math.round(hargaSatuan * (100 / (koefisienPajak + 100))),
        )) ||
      0;
    return totalPajak;
  };

  const handleHitungSatuanPajak = (
    type: any,
    hargaSatuan: any,
    koefisienPajak: any,
  ) => {
    let totalHargaPajak =
      (type === "termasukPajak" &&
        Math.round(hargaSatuan * (100 / (koefisienPajak + 100)))) ||
      hargaSatuan;
    return totalHargaPajak;
  };

  const hitungTotalAnggaran = (data: any, status: any) => {
    let anggaranBelanja: any = 0;
    let realisasiBelanja: any = 0;
    if (status === "kegiatan") {
      data.length &&
        // eslint-disable-next-line array-callback-return
        data.map((items) => {
          let anggaranBelanjaSub: any = 0;
          let realisasiBelanjaSub: any = 0;
          items?.komponenBiayas?.length &&
            // eslint-disable-next-line array-callback-return
            items?.komponenBiayas?.map((itemss: any) => {
              let pajakRealisasi = handleHitungPajak(
                itemss?.pajak,
                itemss?.realisasiHargaSatuan,
                itemss?.persenPajak,
              );

              let pajakRencana = handleHitungPajak(
                itemss?.pajak,
                itemss?.hargaSatuan,
                itemss?.persenPajak,
              );

              let hargaSatuanRealisasi =
                handleHitungSatuanPajak(
                  itemss?.pajak,
                  itemss?.realisasiHargaSatuan,
                  itemss?.persenPajak,
                ) + pajakRealisasi;
              let hargaSatuanRencana =
                handleHitungSatuanPajak(
                  itemss?.pajak,
                  itemss?.hargaSatuan,
                  itemss?.persenPajak,
                ) + pajakRencana;
              let totalRealisasi: any =
                hargaSatuanRealisasi * itemss?.realisasiKuantitas;
              let totalAnggaran: any =
                hargaSatuanRencana * itemss?.totalKuantitas;
              realisasiBelanjaSub += totalRealisasi;
              anggaranBelanjaSub += totalAnggaran;
            });
          realisasiBelanja += realisasiBelanjaSub;
          anggaranBelanja += anggaranBelanjaSub;
        });
      return {
        anggaranBelanja: anggaranBelanja,
        realisasiBelanja: realisasiBelanja,
      };
    } else {
      data.length &&
        // eslint-disable-next-line array-callback-return
        data.map((itemss: any) => {
          let pajakRealisasi = handleHitungPajak(
            itemss?.pajak,
            itemss?.realisasiHargaSatuan,
            itemss?.persenPajak,
          );

          let pajakRencana = handleHitungPajak(
            itemss?.pajak,
            itemss?.hargaSatuan,
            itemss?.persenPajak,
          );

          let hargaSatuanRealisasi =
            handleHitungSatuanPajak(
              itemss?.pajak,
              itemss?.realisasiHargaSatuan,
              itemss?.persenPajak,
            ) + pajakRealisasi;
          let hargaSatuanRencana =
            handleHitungSatuanPajak(
              itemss?.pajak,
              itemss?.hargaSatuan,
              itemss?.persenPajak,
            ) + pajakRencana;
          let totalRealisasi: any =
            hargaSatuanRealisasi * itemss?.realisasiKuantitas;
          let totalAnggaran: any = hargaSatuanRencana * itemss?.totalKuantitas;
          realisasiBelanja += totalRealisasi;
          anggaranBelanja += totalAnggaran;
        });
      return {
        anggaranBelanja: anggaranBelanja,
        realisasiBelanja: realisasiBelanja,
      };
    }
    // return
  };
  return (
    <>
      <BreadCrumb routes={itemBreadcrumb} title="Realisasi Keuangan" />
      {groupRole !== "madrasah" && (
        <FilterMadrasah
          handleChangeValues={handleChangeValues}
          madrasah={tmpMadrasah}
        />
      )}
      <div className="m-5 p-5 bg-white shadow-md rounded">
        <div className="mb-4 flex justify-end">
          <Space size={[8, 8]} wrap>
            <ButtonDropdownExport handleExportFile={handleExportFile} />
          </Space>
        </div>
        <div className="w-full">
          {(dataTable.length &&
            dataTable.map((item, i) => {
              return (
                <Table
                  columns={columns}
                  dataSource={item.listTable}
                  expandable={{
                    expandedRowRender: (record) => expandedRowDetail(record),
                    rowExpandable: (record) => record?.key,
                  }}
                  showHeader={i > 0 ? false : true}
                  bordered
                  loading={loading}
                  summary={() => {
                    let sisa = item.totalPendapatan - item.totalBelanja;
                    totalSisa += sisa;
                    return (
                      <Table.Summary fixed="bottom">
                        <Table.Summary.Row
                          style={{
                            backgroundColor: `green`,
                            color: `#ffffff`,
                          }}>
                          <Table.Summary.Cell
                            index={0}
                            align="center"
                            colSpan={3}>
                            <strong>Total Belanja</strong>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell
                            index={4}
                            align="right"></Table.Summary.Cell>
                          <Table.Summary.Cell index={5} align="right">
                            {(item.totalPendapatan &&
                              formatCurr(item.totalPendapatan)) ||
                              0}
                          </Table.Summary.Cell>
                          <Table.Summary.Cell
                            index={6}
                            align="center"></Table.Summary.Cell>
                          <Table.Summary.Cell
                            index={7}
                            align="center"></Table.Summary.Cell>
                          <Table.Summary.Cell index={8} align="right">
                            {" "}
                            {(item.totalBelanja &&
                              formatCurr(item.totalBelanja)) ||
                              0}
                          </Table.Summary.Cell>
                          <Table.Summary.Cell
                            index={9}
                            align="center"></Table.Summary.Cell>
                          <Table.Summary.Cell index={10} align="right">
                            {" "}
                            {(item.totalBelanja &&
                              formatCurr(item.totalBelanja)) ||
                              0}
                          </Table.Summary.Cell>
                          <Table.Summary.Cell index={11} align="center">
                            {item.persenTotal}
                          </Table.Summary.Cell>
                        </Table.Summary.Row>
                        <Table.Summary.Row
                          style={{
                            backgroundColor: `green`,
                            color: `#ffffff`,
                          }}>
                          <Table.Summary.Cell
                            index={0}
                            align="center"
                            colSpan={9}>
                            <strong>
                              Sisa Saldo Sumber Dana {item.nama || ""}
                            </strong>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell
                            index={4}
                            align="right"
                            colSpan={2}>
                            {formatCurr(
                              item.totalPendapatan - item.totalBelanja,
                            )}
                          </Table.Summary.Cell>
                        </Table.Summary.Row>
                        {i + 1 === dataTable.length && (
                          <>
                            <Table.Summary.Row
                              style={{
                                backgroundColor: `green`,
                                color: `#ffffff`,
                              }}>
                              <Table.Summary.Cell
                                index={0}
                                align="center"
                                colSpan={9}>
                                <strong>Total Sisa Saldo Sumber Dana</strong>
                              </Table.Summary.Cell>
                              <Table.Summary.Cell
                                index={4}
                                align="right"
                                colSpan={2}>
                                {formatCurr(totalSisa)}
                              </Table.Summary.Cell>
                            </Table.Summary.Row>
                          </>
                        )}
                      </Table.Summary>
                    );
                  }}
                  pagination={false}
                />
              );
            })) || (
            <Table
              columns={columns}
              dataSource={[]}
              loading={loading}
              showHeader={true}
              bordered
              summary={() => (
                <Table.Summary fixed="bottom">
                  <Table.Summary.Row
                    style={{
                      backgroundColor: `green`,
                      color: `#ffffff`,
                    }}>
                    <Table.Summary.Cell index={0} align="center" colSpan={3}>
                      <strong>Total Belanja</strong>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell
                      index={4}
                      align="center"></Table.Summary.Cell>
                    <Table.Summary.Cell
                      index={5}
                      align="center"></Table.Summary.Cell>
                    <Table.Summary.Cell
                      index={6}
                      align="center"></Table.Summary.Cell>
                    <Table.Summary.Cell
                      index={7}
                      align="center"></Table.Summary.Cell>
                    <Table.Summary.Cell
                      index={8}
                      align="center"></Table.Summary.Cell>
                    <Table.Summary.Cell
                      index={9}
                      align="center"></Table.Summary.Cell>
                    <Table.Summary.Cell
                      index={10}
                      align="center"></Table.Summary.Cell>
                    <Table.Summary.Cell
                      index={11}
                      align="center"></Table.Summary.Cell>
                  </Table.Summary.Row>
                  <Table.Summary.Row
                    style={{
                      backgroundColor: `green`,
                      color: `#ffffff`,
                    }}>
                    <Table.Summary.Cell index={0} align="center" colSpan={9}>
                      <strong>Sisa Saldo Sumber Dana</strong>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={10} align="center" colSpan={2}>
                      0
                    </Table.Summary.Cell>
                  </Table.Summary.Row>
                </Table.Summary>
              )}
              pagination={false}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default LaporanRealisasi;
