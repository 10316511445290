/** @format */
import { useEffect, useState } from "react";
import { Alert } from "antd";
import moment from "moment";
import "moment/locale/id";

const timerToString = (countDown) => {
  const hours = (
    "0" + Math.floor((countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
  ).slice(-2);
  const minutes = (
    "0" + Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60))
  ).slice(-2);
  const seconds = ("0" + Math.floor((countDown % (1000 * 60)) / 1000)).slice(
    -2,
  );

  return hours + ":" + minutes + ":" + seconds;
};

type informationCountdownParams = {
  lastDate: any;
};
export const InformationCountdown = ({
  lastDate,
}: informationCountdownParams) => {
  let now = moment();

  let lastUpdate: any = now;
  lastDate.length &&
    // eslint-disable-next-line array-callback-return
    lastDate.map((item: any) => {
      lastUpdate = item?.lastUpdate;
    });

  // if (moment(now).isBefore(moment(startDay).add(3, "hours"))) {
  //   lastUpdate = startDay;
  // } else if (moment(now).isBefore(moment(startDay).add(6, "hours"))) {
  //   lastUpdate = moment(startDay).add(3, "hours");
  // } else if (moment(now).isBefore(moment(startDay).add(9, "hours"))) {
  //   lastUpdate = moment(startDay).add(6, "hours");
  // } else if (moment(now).isBefore(moment(startDay).add(12, "hours"))) {
  //   lastUpdate = moment().set({
  //     hour: 9,
  //     minute: 0,
  //     second: 0,
  //     millisecond: 0,
  //   });
  // } else if (moment(now).isBefore(moment(startDay).add(15, "hours"))) {
  //   lastUpdate = moment().set({
  //     hour: 12,
  //     minute: 0,
  //     second: 0,
  //     millisecond: 0,
  //   });
  // } else if (moment(now).isBefore(moment(startDay).add(18, "hours"))) {
  //   lastUpdate = moment(startDay).add(15, "hours");
  // } else if (moment(now).isBefore(moment(startDay).add(21, "hours"))) {
  //   lastUpdate = moment(startDay).add(18, "hours");
  // } else {
  //   lastUpdate = moment(startDay).add(21, "hours");
  // }
  let tmpEndDate = moment(lastUpdate).add(3, "hours").valueOf();
  const countDownDate = new Date(tmpEndDate).getTime();

  const [countDown, setCountDown] = useState(
    countDownDate - new Date().getTime(),
  );

  useEffect(() => {
    const interval = setInterval(() => {
      setCountDown(countDownDate - new Date().getTime());
    }, 1000);

    return () => clearInterval(interval);
  }, [countDownDate]);

  const keterangan = (
    <>
      <div>{`Update terakhir laporan pada jam ${moment(lastUpdate).format(
        "HH:mm:ss",
      )} Laporan ini akan di update dalam waktu 
    ${timerToString(countDown)} , `}</div>
    </>
  );
  //  <div className="text-xs">
  //         *) Bagi madrasah yang urutan transaksinya belum sesuai bisa menghubungi
  //         Live agen
  //         <div>
  //           Persyaratan yang harus dikirim “NSM, Nama Madrasah, Screenshot Data
  //           Transaksi, Nomor WA yang bisa dihubungi"
  //         </div>
  //       </div>
  return lastDate.length ? (
    <>
      <div className="px-5 mt-4">
        <Alert
          message="Perhatian"
          description={keterangan}
          type="info"
          showIcon
        />
      </div>
    </>
  ) : (
    <></>
  );
};
