/** @format */

import React, { useState } from "react";
import { jsPDF } from "jspdf";
import {
  Form,
  Modal,
  Button,
  Input,
  Badge,
  Alert,
  // InputNumber,
  // DatePicker,
} from "antd";

import angkaTerbilang from "@develoka/angka-terbilang-js";
import { formatRupiah, toTitleCase } from "../../utils/helper";
import notifAlert from "../../components/NotifAlert";
import KemenAg from "../../assets/img/logo-kemenag.png";
import Barcode from "../../assets/img/barcode-kemenag.png";
// import TTDKemenag from "../../assets/img/ttd-kemenag.png";

type generateModal = {
  openModal: boolean;
  dataMadrasah: any;
  rekBos: any;
  onClose: () => void;
};

const ModalGenerateManual = (params: generateModal) => {
  const [form] = Form.useForm();
  const [nomor, setNomor] = useState<any>(null);
  // const [tanggal, setTanggal] = useState<any>(null);
  const [alamat, setAlamat] = useState<any>(null);
  const [kecamatan, setKecamatan] = useState<any>(null);
  // const [nilai, setNilai] = useState<any>(null);
  const dataMadrasah = params?.dataMadrasah || null;
  const dataRekBos =
    params?.rekBos.find((item) => item.nsm === dataMadrasah?.nsm) || null;
  const [pdfFile, setPdfFile] = useState<any>(null);

  const handleValuesChange = (values: any) => {
    for (let obj in values) {
      switch (obj) {
        case "nomor":
          setNomor(values.nomor || null);
          break;
        // case "tanggal":
        //   setTanggal(values.tanggal || null);
        //   break;
        case "alamat":
          setAlamat(values.alamat || null);
          break;
        case "kecamatan":
          setKecamatan(values.kecamatan || null);
          break;
        // case "nilai":
        //   setNilai(values.nilai || null);
        //   break;
      }
    }
  };

  React.useEffect(() => {
    downloadPdf();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nomor, alamat, kecamatan, dataMadrasah]);

  const handleDownload = (values: any) => {
    downloadPdf("download");
    notifAlert({
      type: "success",
      description: "Surat berhasil diunduh",
    });
  };

  const downloadPdf = (action?: any) => {
    const doc: any = new jsPDF({
      putOnlyUsedFonts: true,
      floatPrecision: 16,
    });

    var fline = 20;
    var firstline = 15;
    /** Page 1 */
    doc.setFont("times", "normal");
    doc.setFontSize(11);
    doc.rect(125, 12, 52, 7);
    doc.rect(125, 12, 52, 22);
    doc.text("FORMULIR BOS-06 ", 133, 17);
    doc.text("Perjanjian Kerja sama", 133, 24);
    doc.text("(Ditandatangani oleh ", 133, 28);
    doc.text("Kepala Madrasah dan PPK) ", 128, 32);
    doc.addImage(KemenAg, "JPEG", 94, firstline, 24, 22);
    doc.text(
      `PERJANJIAN KERJA SAMA`,
      105,
      firstline + 32,
      null,
      null,
      "center",
    );
    doc.text(`ANTARA`, 105, firstline + 37, null, null, "center");
    doc.text(
      `PEJABAT PEMBUAT KOMITMEN`,
      105,
      firstline + 42,
      null,
      null,
      "center",
    );
    doc.text(
      ` DIREKTORAT KURIKULUM, SARANA, KELEMBAGAAN, DAN KESISWAAN MADRASAH`,
      105,
      firstline + 47,
      null,
      null,
      "center",
    );
    doc.text(`DENGAN`, 105, firstline + 52, null, null, "center");
    doc.text(
      `KEPALA MADRASAH ${dataMadrasah?.nama || ""}`,
      105,
      firstline + 57,
      null,
      null,
      "center",
    );
    doc.text(`TENTANG`, 105, firstline + 62, null, null, "center");
    doc.text(
      `PEMANFAATAN DANA BANTUAN OPERASIONAL SEKOLAH UNTUK`,
      105,
      firstline + 67,
      null,
      null,
      "center",
    );
    doc.text(
      `MADRASAH TAHUN ANGGARAN 2024`,
      105,
      firstline + 72,
      null,
      null,
      "center",
    );
    doc.text(
      `NOMOR: B.43.1/Dt.I.I.3/Ks01.7/01/2024`,
      67,
      firstline + 80,
      null,
      null,
      "left",
    );
    if (!nomor) {
      badgeBorder("1", 91, 83, "m");
    }

    doc.text(
      `NOMOR: ${nomor || "............."}`,
      67,
      firstline + 85,
      null,
      null,
      "left",
    );

    doc.text(
      fline,
      firstline + 95,
      `Pada hari ini Senin tanggal lima belas bulan Januari tahun Dua ribu dua puluh empat, yang bertanda tangan di bawah ini:`,
      {
        maxWidth: 160,
        align: "justify",
      },
    );
    doc.text("1.", fline, firstline + 110, null, null, "left");

    doc.text(
      `Muchamad Sidik Sisdiyanto`,
      fline + 5,
      firstline + 110,
      null,
      null,
      "left",
    );
    doc.text(":", fline + 70, firstline + 110, null, null, "left");
    doc.text(
      fline + 75,
      firstline + 110,
      `Pejabat Pembuat Komitmen, dalam hal ini bertindak untuk dan atas nama Direktorat Jendral Pendidikan Islam Kementerian Agama Republik Indonesia, yang berkedudukan di Jalan Lapangan Banteng Barat 3-4 Jakarta, selanjutnya disebut **PIHAK KESATU** .`,
      {
        maxWidth: 85,
        align: "justify",
      },
    );
    if (!dataMadrasah?.namaKepala) {
      badgeBorder("3", 39, 143, "m");
    }
    doc.text("2.", fline, firstline + 145, null, null, "left");
    doc.text(
      `${dataMadrasah?.namaKepala || "............."}`,
      fline + 5,
      firstline + 145,
      null,
      null,
      "left",
    );

    doc.text(":", fline + 70, firstline + 145, null, null, "left");
    // if (!dataMadrasah?.namaKepala) {
    //   badgeBorder("3", 39, 143, "m");
    // }
    // if (!alamat) {
    //   badgeBorder("4", 177, 153, "m");
    // }
    // if (!kecamatan) {
    //   badgeBorder("5", 109, 157, "m");
    // }
    doc.text(
      fline + 75,
      firstline + 145,
      `Kepala Madrasah ${
        dataMadrasah?.nama || "............."
      }, dalam hal ini bertindak untuk dan atas nama ${
        dataMadrasah?.nama || ""
      }, yang berkedudukan di ${alamat || "............."}  ,${
        kecamatan || "............."
      } selanjutnya disebut **PIHAK KEDUA** .`,
      {
        maxWidth: 85,
        align: "justify",
      },
    );
    doc.text(
      fline,
      firstline + 175,
      `PIHAK KESATU dan PIHAK KEDUA secara bersama-sama disebut PARA PIHAK, dan masing-masing disebut PIHAK. PARA PIHAK menerangkan terlebih dahulu bahwa berdasarkan:`,
      {
        maxWidth: 160,
        align: "justify",
      },
    );
    doc.text("1.", fline + 1, firstline + 195, null, null, "left");
    doc.text(
      fline + 7,
      firstline + 195,
      `Undang-Undang Republik Indonesia Nomor 17 Tahun 2003 tentang Keuangan Negara (Lembaran Negara Republik Indonesia Tahun 2003 Nomor 47, Tambahan Lembaran Negara Republik Indonesia Nomor 4286);`,
      {
        maxWidth: 152,
        align: "justify",
      },
    );
    doc.text("2.", fline + 1, firstline + 213, null, null, "left");
    doc.text(
      fline + 7,
      firstline + 213,
      `Undang-Undang Nomor 20 Tahun 2003 tentang Sistem Pendidikan Nasional (Lembaran Negara Republik Indonesia Tahun 2003 Nomor 78, Tambahan Lembaran Negara Republik Indonesia Nomor 4301);`,
      {
        maxWidth: 152,
        align: "justify",
      },
    );
    doc.text("3.", fline + 1, firstline + 229, null, null, "left");
    doc.text(
      fline + 7,
      firstline + 229,
      `Undang-Undang Nomor 1 Tahun 2004 tentang Perbendaharaan Negara (Lembaran Negara Republik Indonesia Tahun 2004 Nomor 5, Tambahan Lembaran Negara Republik Indonesia Nomor 4355);`,
      {
        maxWidth: 152,
        align: "justify",
      },
    );
    pdfFooter();
    doc.addPage();
    /** Page 2 */
    doc.text("4.", fline + 1, firstline + 5, null, null, "left");
    doc.text(
      fline + 7,
      firstline + 5,
      `Undang-Undang Nomor 39 Tahun 2008 tentang Kementerian Negara (Lembaran Negara Republik Indonesia Tahun 2008 Nomor 166, Tambahan Lembaran Negara Republik Indonesia Nomor 4916);`,
      {
        maxWidth: 152,
        align: "justify",
      },
    );
    doc.text("5.", fline + 1, firstline + 22, null, null, "left");
    doc.text(
      fline + 7,
      firstline + 22,
      `Peraturan Pemerintah Nomor 48 Tahun 2008 tentang Pendanaan Pendidikan (Lembaran Negara Republik Indonesia Tahun 2008 Nomor 91, Tambahan Lembaran Negara Republik Indonesia Nomor 4864);`,
      {
        maxWidth: 152,
        align: "justify",
      },
    );

    doc.text("6.", fline + 1, firstline + 39, null, null, "left");
    doc.text(
      fline + 7,
      firstline + 39,
      `Peraturan Pemerintah Nomor 17 Tahun 2010 tentang Pengelolaan dan Penyelenggaraan Pendidikan (Lembaran Negara Republik Indonesia Tahun 2010 Nomor 23, Tambahan Lembaran Negara Republik Indonesia Nomor 5105) sebagaimana telah diubah dengan Peraturan Pemerintah Nomor 66 Tahun 2010 tentang Perubahan atas Peraturan Pemerintah Nomor 17 Tahun 2010 tentang Pengelolaan dan Penyelenggaraan Pendidikan (Lembaran Negara Republik Indonesia Tahun 2010 Nomor 112, Tambahan Lembaran Negara Republik Indonesia Nomor 5157);`,
      {
        maxWidth: 152,
        align: "justify",
      },
    );
    doc.text("7.", fline + 1, firstline + 74, null, null, "left");
    doc.text(
      fline + 7,
      firstline + 74,
      `Peraturan Pemerintah Nomor 57 tahun 2021 tentang Standar Nasional Pendidikan (Lembaran Negara Republik Indonesia Tahun 2021 Nomor 87);`,
      {
        maxWidth: 152,
        align: "justify",
      },
    );
    doc.text("8.", fline + 1, firstline + 88, null, null, "left");
    doc.text(
      fline + 7,
      firstline + 88,
      `Peraturan Presiden(PERPRES) Nomor 12 Tahun 2021 tentang Perubahan Atas Peraturan Presiden Nomor 16 Tahun 2018 tentang Pengadaan Barang / Jasa Pemerintah;`,
      {
        maxWidth: 152,
        align: "justify",
      },
    );
    doc.text("9.", fline + 1, firstline + 100, null, null, "left");
    doc.text(
      fline + 7,
      firstline + 100,
      `Peraturan Menteri Keuangan Nomor 190/PMK.05/2012 tentang Tata Cara Pembayaran Dalam Rangka Pelaksanaan Anggaran Pendapatan dan Belanja Negara (Berita Negara Republik Indonesia Tahun 2012 Nomor 1191) sebagaimana telah diubah dengan Peraturan Menteri Keuangan Republik Indonesia Nomor 178/PMK.05/2018 tentang Perubahan Atas Peraturan Menteri Keuangan Nomor 190/PMK.05/2012 tentang Tata Cara Pembayaran Dalam Rangka Pelaksanaan Anggaran Pendapatan dan Belanja Negara (Berita Negara Republik Indonesia Tahun 2018 Nomor 1736);`,
      {
        maxWidth: 152,
        align: "justify",
      },
    );

    doc.text("10.", fline + 1, firstline + 137, null, null, "left");
    doc.text(
      fline + 7,
      firstline + 137,
      `Peraturan Menteri Keuangan Nomor 168/PMK.05/2015 tentang Mekanisme Pelaksanaan Anggaran Bantuan Pemerintah pada Kementerian Negara/Lembaga (Berita Negara Republik Indonesia Tahun 2015 Nomor 1340) sebagaimana telah diubah dengan Peraturan Menteri Keuangan Nomor 173/PMK.05/2016 tentang Perubahan Atas Peraturan Menteri Keuangan Nomor 168/PMK.05/2015 tentang Mekanisme Pelaksanaan Anggaran Bantuan Pemerintah pada Kementerian Negara/Lembaga (Berita Negara Republik Indonesia Tahun 2016 Nomor 1745);`,
      {
        maxWidth: 152,
        align: "justify",
      },
    );

    doc.text("11.", fline, firstline + 175, null, null, "left");
    doc.text(
      fline + 7,
      firstline + 175,
      `Peraturan Menteri Agama Nomor 67 Tahun 2015 tentang Bantuan Pemerintah Pada Kementerian Agama (Berita Negara Republik Indonesia Tahun 2015 Nomor 1655) sebagaimana telah beberapa kali diubah terakhir dengan Peraturan Menteri Agama Nomor 21 Tahun 2019 tentang Perubahan Ketiga Atas Peraturan Menteri Agama Nomor 67 Tahun 2015 tentang Bantuan Pemerintah Pada Kementerian Agama (Berita Negara Republik Indonesia Tahun 2019 Nomor 1131);`,
      {
        maxWidth: 152,
        align: "justify",
      },
    );

    doc.text("12.", fline, firstline + 203, null, null, "left");
    doc.text(
      fline + 7,
      firstline + 203,
      `Peraturan Menteri Agama Nomor 42 Tahun 2016 tentang Organisasi dan Tata Kerja Kementerian Agama (Berita Negara Republik Indonesia Tahun 2016 Nomor 1495);`,
      {
        maxWidth: 152,
        align: "justify",
      },
    );

    doc.text("13.", fline, firstline + 220, null, null, "left");
    doc.text(
      fline + 7,
      firstline + 220,
      `Peraturan Menteri Agama Nomor 19 Tahun 2019 tentang Organisasi Dan Tata Kerja Instansi Vertikal Kementerian Agama (Berita Negara Republik Indonesia Tahun 2019 Nomor 1115); Peraturan Menteri Agama Nomor 6 Tahun 2020 tentang Pejabat Perbendaharaan Negara Pada Kementerian Agama (Berita Negara Republik Indonesia Tahun 2020 Nomor 172);`,
      {
        maxWidth: 152,
        align: "justify",
      },
    );
    pdfFooter();
    doc.addPage();
    /** Page 3 */

    doc.text("14.", fline, firstline + 5, null, null, "left");
    doc.text(
      fline + 7,
      firstline + 5,
      `Peraturan Menteri Keuangan Nomor 60/PMK.02/2021 tentang Standar Biaya Masukan Tahun Anggaran 2022 (Berita Negara Republik Indonesia Tahun 2021 Nomor 658);`,
      {
        maxWidth: 152,
        align: "justify",
      },
    );

    doc.text(
      "PARA PIHAK menyatakan bahwa:",
      fline + 1,
      firstline + 25,
      null,
      null,
      "left",
    );
    doc.text("1.", fline + 1, firstline + 33, null, null, "left");
    doc.text(
      fline + 7,
      firstline + 33,
      `PIHAK KESATU menyalurkan Dana Bantuan Operasional Sekolah pada Madrasah (selanjutnya disingkat "Bantuan Operasional") kepada PIHAK KEDUA yang bertujuan untuk membantu pembiayaan operasional penyelenggaraan pendidikan dalam rangka peningkatan aksesibilitas dan mutu pembelajaran siswa;`,
      {
        maxWidth: 152,
        align: "justify",
      },
    );
    doc.text("2.", fline + 1, firstline + 56, null, null, "left");
    doc.text(
      fline + 7,
      firstline + 56,
      `Dokumen-dokumen berikut merupakan satu-kesatuan dan bagian yang tidak terpisahkan dari Perjanjian Kerjasama ini:`,
      {
        maxWidth: 152,
        align: "justify",
      },
    );
    doc.text("a.", fline + 10, firstline + 68, null, null, "left");
    doc.text(fline + 15, firstline + 68, `Adendum Perjanjian Kerja Sama; dan`, {
      maxWidth: 152,
      align: "justify",
    });
    doc.text("b.", fline + 10, firstline + 74, null, null, "left");
    doc.text(fline + 15, firstline + 74, `Perubahan RKAM;`, {
      maxWidth: 152,
      align: "justify",
    });

    doc.text(
      fline + 1,
      firstline + 85,
      `PARA PIHAK sepakat dan setuju untuk menandatangani Surat Perjanjian ini dengan ketentuan dan syarat-syarat yang tercantum dalam pasal-pasal tersebut di bawah ini.`,
      {
        maxWidth: 156,
        align: "justify",
      },
    );

    doc.text(fline + 80, firstline + 105, `Pasal 1`, {
      maxWidth: 152,
      align: "center",
    });
    doc.text(fline + 80, firstline + 110, `MAKSUD DAN TUJUAN`, {
      maxWidth: 152,
      align: "center",
    });

    doc.text("(1)", fline + 1, firstline + 117, null, null, "left");
    doc.text(
      fline + 9,
      firstline + 117,
      `Perjanjian Kerjasama ini dimaksudkan sebagai landasan hukum pelaksanaan kerjasama Pemanfaatan Dana Bantuan Operasional yang bersumber dari DIPA Satker Direktorat Jenderal Pendidikan Islam (426302) Tahun Anggaran 2024 dari PIHAK KESATU kepada PIHAK KEDUA.`,
      {
        maxWidth: 150,
        align: "justify",
      },
    );

    doc.text("(2)", fline + 1, firstline + 140, null, null, "left");
    doc.text(
      fline + 9,
      firstline + 140,
      `Perjanjian Kerjasama ini bertujuan untuk mengatur pelaksanaan Penyaluran dan Pemanfaatan Dana Bantuan Operasional dilakukan secara lebih efektif, efisien dan akuntabel.`,
      {
        maxWidth: 150,
        align: "justify",
      },
    );

    doc.text(fline + 80, firstline + 162, `Pasal 2`, {
      maxWidth: 152,
      align: "center",
    });
    doc.text(fline + 80, firstline + 167, `NILAI BANTUAN`, {
      maxWidth: 152,
      align: "center",
    });

    doc.text("(1)", fline + 1, firstline + 174, null, null, "left");
    if (!dataMadrasah?.nilaiPencairan) {
      badgeBorder("6", 70, 177, "m");
    }
    doc.text(
      fline + 9,
      firstline + 174,
      `Jumlah Bantuan Operasional yang disalurkan oleh PIHAK KESATU kepada PIHAK KEDUA senilai ${
        (dataMadrasah?.nilaiPencairan &&
          formatRupiah(dataMadrasah?.nilaiPencairan) +
            " , " +
            toTitleCase(
              angkaTerbilang(dataMadrasah?.nilaiPencairan) + " rupiah",
            )) ||
        "............."
      }`,
      {
        maxWidth: 150,
        align: "justify",
      },
    );

    doc.text("(2)", fline + 1, firstline + 190, null, null, "left");
    doc.text(
      fline + 9,
      firstline + 190,
      `Jumlah Bantuan Operasional sebagaimana dimaksud pada ayat (1) akan disalurkan dalam 2 Tahap, dengan ketentuan sebagai berikut`,
      {
        maxWidth: 150,
        align: "justify",
      },
    );
    doc.text("a.", fline + 10, firstline + 202, null, null, "left");
    doc.text(
      fline + 15,
      firstline + 202,
      `Tahap 1 disalurkan senilai 50% dari jumlah Bantuan Operasional, dan`,
      {
        maxWidth: 152,
        align: "justify",
      },
    );
    doc.text("b.", fline + 10, firstline + 210, null, null, "left");
    doc.text(
      fline + 15,
      firstline + 210,
      `Tahap 2 disalurkan senilai 50% dari jumlah Bantuan Operasional.`,
      {
        maxWidth: 152,
        align: "justify",
      },
    );

    doc.text("(3)", fline + 1, firstline + 218, null, null, "left");
    doc.text(
      fline + 9,
      firstline + 218,
      `Nilai Bantuan Operasional sebagaimana dimaksud pada ayat (1) dituangkan dalam daftar perhitungan sebagaimana tercantum pada e-RKAM Versi 2; https://erkam.kemenag.go.id/ yang merupakan satu kesatuan dan menjadi bagian yang tidak dapat dipisahkan dengan perjanjian ini.`,
      {
        maxWidth: 150,
        align: "justify",
      },
    );
    pdfFooter();
    doc.addPage();
    /** Page 4 */
    doc.text(fline + 80, firstline + 5, `Pasal 3`, {
      maxWidth: 152,
      align: "center",
    });
    doc.text(fline + 80, firstline + 10, `PEMBEBANAN DANA`, {
      maxWidth: 152,
      align: "center",
    });
    doc.text(
      fline,
      firstline + 17,
      `Dana Bantuan Operasional ini dibebankan pada DIPA Satuan Kerja Ditjen Pendidikan Islam (426302) Tahun Anggaran 2024 dengan kode pembebanan 2129.QEK.005.006.007`,
      {
        maxWidth: 160,
        align: "justify",
      },
    );

    doc.text(fline + 80, firstline + 30, `Pasal 4`, {
      maxWidth: 152,
      align: "center",
    });
    doc.text(fline + 80, firstline + 35, `TATA CARA PENYALURAN`, {
      maxWidth: 152,
      align: "center",
    });
    doc.text("(1)", fline + 1, firstline + 42, null, null, "left");
    doc.text(
      fline + 9,
      firstline + 42,
      `Penyaluran Dana Bantuan Operasional ini dilakukan melalui pengajuan Surat Perintah Membayar (SPM) kepada KPPN IV Jakarta oleh PIHAK KESATU untuk selanjutnya diterbitkan Surat Perintah Pencairan Dana (SP2D) yang ditujukan kepada PIHAK KEDUA melalui Bank Penyalur untuk selanjutnya ditransfer kepada Rekening PIHAK KEDUA Nomor Rekening ${
        dataRekBos?.no_rekening || "............."
      } Atas Nama ${dataRekBos?.no_rekening_nama || "............."}`,
      {
        maxWidth: 150,
        align: "justify",
      },
    );
    doc.text("(2)", fline + 1, firstline + 72, null, null, "left");
    doc.text(
      fline + 9,
      firstline + 72,
      `Ketentuan tentang Penyaluran Dana Bantuan melalui Bank Penyalur dituangkan dalam Perjanjian Kerja Sama terpisah antara Pejabat Pembuat Komitmen dan Bank Penyalur.`,
      {
        maxWidth: 150,
        align: "justify",
      },
    );
    doc.text("(3)", fline + 1, firstline + 84, null, null, "left");
    doc.text(
      fline + 9,
      firstline + 84,
      `Pencairan pembayaran dilakukan dalam dua tahap setelah PIHAK KEDUA mengajukan syarat-syarat pencairan kepada PIHAK KESATU dengan dilampiri:`,
      {
        maxWidth: 150,
        align: "justify",
      },
    );
    doc.text("a.", fline + 10, firstline + 96, null, null, "left");
    doc.text(fline + 15, firstline + 96, `Laporan Pertanggungjawaban (LPJ);`, {
      maxWidth: 152,
      align: "justify",
    });
    doc.text("b.", fline + 10, firstline + 102, null, null, "left");
    doc.text(
      fline + 15,
      firstline + 102,
      `Surat Permohonan Pencairan Dana BOS;`,
      {
        maxWidth: 152,
        align: "justify",
      },
    );
    doc.text("c.", fline + 10, firstline + 109, null, null, "left");
    doc.text(
      fline + 15,
      firstline + 109,
      `Surat Pernyataan Tanggung Jawab Mutlak (SPTJM);`,
      {
        maxWidth: 143,
        align: "justify",
      },
    );
    doc.text("d.", fline + 10, firstline + 116, null, null, "left");
    doc.text(
      fline + 15,
      firstline + 116,
      `Surat Perjanjian Kerja Sama yang sudah ditandatangani PPK dan Kepala Madrasah;`,
      {
        maxWidth: 143,
        align: "justify",
      },
    );
    doc.text("e.", fline + 10, firstline + 125, null, null, "left");
    doc.text(
      fline + 15,
      firstline + 125,
      `Surat Pernyataan Tanggung Jawab Belanja (SPTJB) (khusus pencairan Tahap Kedua); dan`,
      {
        maxWidth: 143,
        align: "justify",
      },
    );
    doc.text("f.", fline + 10, firstline + 135, null, null, "left");
    doc.text(
      fline + 15,
      firstline + 135,
      `Rencana Kegiatan dan Anggaran Madrasah (RKAM).`,
      {
        maxWidth: 145,
        align: "justify",
      },
    );

    doc.text("(4)", fline + 1, firstline + 147, null, null, "left");
    doc.text(
      fline + 9,
      firstline + 147,
      `PIHAK KESATU memproses tagihan dan menerbitkan Surat Perintah Membayar sebagaimana dimaksud pada ayat (1) paling lambat 2 (dua) hari kerja setelah diterima tagihan dari PIHAK KEDUA secara benar dan lengkap.`,
      {
        maxWidth: 150,
        align: "justify",
      },
    );

    doc.text(fline + 80, firstline + 164, `Pasal 5`, {
      maxWidth: 152,
      align: "center",
    });
    doc.text(fline + 80, firstline + 169, `HAK DAN KEWAJIBAN`, {
      maxWidth: 152,
      align: "center",
    });
    doc.text("(1)", fline + 1, firstline + 176, null, null, "left");
    doc.text(
      fline + 9,
      firstline + 176,
      `PIHAK KESATU mempunyai hak dan kewajiban sebagai berikut:`,
      {
        maxWidth: 150,
        align: "justify",
      },
    );
    doc.text("a.", fline + 10, firstline + 184, null, null, "left");
    doc.text(
      fline + 15,
      firstline + 183,
      `PIHAK KESATU berhak melakukan monitoring penggunaan dana Bantuan Operasional yang dilaksanakan oleh PIHAK KEDUA;`,
      {
        maxWidth: 143,
        align: "justify",
      },
    );
    doc.text("b.", fline + 10, firstline + 195, null, null, "left");
    doc.text(
      fline + 15,
      firstline + 195,
      `PIHAK KESATU berhak meminta laporan secara periodik mengenai penggunaan Bantuan Operasional yang dilakukan oleh PIHAK KEDUA;`,
      {
        maxWidth: 143,
        align: "justify",
      },
    );
    doc.text("c.", fline + 10, firstline + 208, null, null, "left");
    doc.text(
      fline + 15,
      firstline + 208,
      `PIHAK KESATU dengan mempertimbangkan data jumlah siswa dan ketersediaan anggaran berhak melakukan penambahan atau pengurangan atas jumlah bantuan operasional yang diterima PIHAK KEDUA sebagaimana dimaksud dalam Pasal 2 ayat (1) sesuai dengan ketentuan perundang-undangan; dan`,
      {
        maxWidth: 143,
        align: "justify",
      },
    );
    doc.text("d.", fline + 10, firstline + 231, null, null, "left");
    doc.text(
      fline + 15,
      firstline + 231,
      `PIHAK KESATU berkewajiban menyalurkan dana Bantuan Operasional kepada PIHAK KEDUA setelah dipenuhi syarat-syarat penyaluran dana Bantuan Operasional;`,
      {
        maxWidth: 143,
        align: "justify",
      },
    );
    pdfFooter();
    doc.addPage();
    /** Page 5 */
    doc.text("(2)", fline + 1, firstline + 5, null, null, "left");
    doc.text(
      fline + 9,
      firstline + 5,
      `PIHAK KEDUA mempunyai hak dan kewajiban sebagai berikut:`,
      {
        maxWidth: 150,
        align: "justify",
      },
    );
    doc.text("a.", fline + 10, firstline + 12, null, null, "left");
    doc.text(
      fline + 15,
      firstline + 12,
      `PIHAK KEDUA berhak untuk menerima dana Bantuan Operasional sebagaimana ditetapkan dalam Pasal 2 setelah persyaratan penyaluran dana Bantuan Operasional dinyatakan lengkap oleh PIHAK KESATU;`,
      {
        maxWidth: 143,
        align: "justify",
      },
    );
    doc.text("b.", fline + 10, firstline + 30, null, null, "left");
    doc.text(
      fline + 15,
      firstline + 30,
      `PIHAK KEDUA berkewajiban menggunakan dana Bantuan Operasional sesuai dengan Petunjuk Teknis Pengelolaan Bantuan Operasional yang ditetapkan oleh PIHAK KESATU;`,
      {
        maxWidth: 143,
        align: "justify",
      },
    );
    doc.text("c.", fline + 10, firstline + 47, null, null, "left");
    doc.text(
      fline + 15,
      firstline + 47,
      `PIHAK KEDUA berkewajiban melaporkan penggunaan dana Bantuan Operasional secara periodik kepada PIHAK KESATU sesuai dengan Petunjuk Teknis Pengelolaan Bantuan Operasional yang ditetapkan oleh PIHAK KESATU; `,
      {
        maxWidth: 143,
        align: "justify",
      },
    );
    doc.text("d.", fline + 10, firstline + 64, null, null, "left");
    doc.text(
      fline + 15,
      firstline + 64,
      `PIHAK KEDUA berkewajiban menyetorkan ke Kas Negara sisa dana Bantuan Operasional yang tidak digunakan sampai dengan akhir tahun anggaran 2024 paling lambat tanggal 13 Januari 2025; dan`,
      {
        maxWidth: 143,
        align: "justify",
      },
    );
    doc.text("e.", fline + 10, firstline + 81, null, null, "left");
    doc.text(
      fline + 15,
      firstline + 81,
      `PIHAK KEDUA berkewajiban memberikan keterangan-keterangan yang diperlukan untuk pengawasan/pemeriksaan yang dilakukan oleh PIHAK KESATU dan aparat pengawas lainnya;`,
      {
        maxWidth: 143,
        align: "justify",
      },
    );

    doc.text(fline + 80, firstline + 100, `Pasal 6`, {
      maxWidth: 152,
      align: "center",
    });
    doc.text(fline + 80, firstline + 105, `PERNYATAAN KESANGGUPAN`, {
      maxWidth: 152,
      align: "center",
    });
    doc.text(
      fline,
      firstline + 112,
      `Dengan menandatangani Perjanjian ini, PIHAK KEDUA menyatakan kesanggupan untuk:`,
      {
        maxWidth: 160,
        align: "justify",
      },
    );
    doc.text("(1)", fline + 1, firstline + 119, null, null, "left");
    doc.text(
      fline + 9,
      firstline + 119,
      `Menggunakan dana Bantuan Operasional sesuai dengan Petunjuk Teknis Pengelolaan Bantuan Operasional yang ditetapkan oleh PIHAK KESATU;`,
      {
        maxWidth: 150,
        align: "justify",
      },
    );
    doc.text("(2)", fline + 1, firstline + 130, null, null, "left");
    doc.text(
      fline + 9,
      firstline + 130,
      `Menyetorkan ke Kas Negara sisa dana Bantuan Operasional yang tidak digunakan sampai dengan akhir tahun anggaran 2024 paling lambat tanggal 13 Januari 2025.`,
      {
        maxWidth: 150,
        align: "justify",
      },
    );
    doc.text(fline + 80, firstline + 145, `Pasal 7`, {
      maxWidth: 152,
      align: "center",
    });
    doc.text(fline + 80, firstline + 150, `SANKSI`, {
      maxWidth: 152,
      align: "center",
    });
    doc.text(
      fline,
      firstline + 157,
      `Dalam hal PIHAK KEDUA tidak melaksanakan sebagian atau seluruhnya isi perjanjian ini, PIHAK KESATU akan mengenakan Sanksi berupa sanksi administratif sampai dengan sanksi penghentian penyaluran dana Bantuan Operasional termasuk melaporkan kepada pihak yang berwajib apabila ditemukan unsur tindak pidana.`,
      {
        maxWidth: 160,
        align: "justify",
      },
    );
    doc.text(fline + 80, firstline + 180, `Pasal 8`, {
      maxWidth: 152,
      align: "center",
    });
    doc.text(fline + 80, firstline + 185, `LAPORAN BERKALA PENGGUNAAN DANA`, {
      maxWidth: 152,
      align: "center",
    });
    doc.text("(1)", fline + 1, firstline + 192, null, null, "left");
    doc.text(
      fline + 9,
      firstline + 192,
      `PIHAK KEDUA berkewajiban melaporkan penggunaan dana Operasional Sekolah pada Madrasah paling sedikit secara berkala per Semester kepada PIHAK KESATU.`,
      {
        maxWidth: 150,
        align: "justify",
      },
    );
    doc.text("(2)", fline + 1, firstline + 205, null, null, "left");
    doc.text(
      fline + 9,
      firstline + 205,
      `PIHAK KEDUA berkewajiban melaporkan penggunaan dana Bantuan melalui aplikasi e-RKAM.`,
      {
        maxWidth: 150,
        align: "justify",
      },
    );
    pdfFooter();
    doc.addPage();
    /** Page 6 */
    doc.text(fline + 80, firstline + 5, `Pasal 9`, {
      maxWidth: 152,
      align: "center",
    });
    doc.text(
      fline + 80,
      firstline + 10,
      `LAPORAN PERTANGGUNGJAWABAN AKHIR TAHUN`,
      {
        maxWidth: 152,
        align: "center",
      },
    );

    doc.text("(1)", fline + 1, firstline + 17, null, null, "left");
    doc.text(
      fline + 9,
      firstline + 17,
      `PIHAK KEDUA pada akhir tahun anggaran berkewajiban menyampaikan laporan pertanggungjawaban penggunaan dana Bantuan Operasional Sekolah pada Madrasah Tahun Anggaran 2024 kepada PIHAK KESATU paling lambat pada tanggal 15 Januari 2025`,
      {
        maxWidth: 150,
        align: "justify",
      },
    );
    doc.text("(2)", fline + 1, firstline + 39, null, null, "left");
    doc.text(
      fline + 9,
      firstline + 39,
      `PIHAK KEDUA pada akhir tahun anggaran berkewajiban menyampaikan laporan pertanggungjawaban penggunaan dana Bantuan Operasional Sekolah pada Madrasah Tahun Anggaran 2024 kepada PIHAK KESATU melalui aplikasi e-RKAM`,
      {
        maxWidth: 150,
        align: "justify",
      },
    );

    doc.text(fline + 80, firstline + 65, `Pasal 10`, {
      maxWidth: 152,
      align: "center",
    });
    doc.text(fline + 80, firstline + 70, `PENGAKHIRAN PERJANJIAN KERJASAMA`, {
      maxWidth: 152,
      align: "center",
    });
    doc.text("(1)", fline + 1, firstline + 78, null, null, "left");
    doc.text(
      fline + 9,
      firstline + 78,
      `Perjanjian ini berakhir sampai dengan 31 Desember 2024.`,
      {
        maxWidth: 150,
        align: "justify",
      },
    );

    doc.text("(2)", fline + 1, firstline + 84, null, null, "left");
    doc.text(
      fline + 9,
      firstline + 84,
      `Surat Perjanjian dapat diakhiri oleh salah satu pihak sebelum jangka waktu Perjanjian berakhir atas terjadinya salah satu kondisi antara lain:`,
      {
        maxWidth: 150,
        align: "justify",
      },
    );

    doc.text("a.", fline + 10, firstline + 95, null, null, "left");
    doc.text(
      fline + 15,
      firstline + 95,
      `Ada ketentuan perundang-undangan dan/atau kebijakan Pemerintah yang tidak memungkinkan berlangsungnya Surat Perjanjian ini; dan`,
      {
        maxWidth: 143,
        align: "justify",
      },
    );
    doc.text("b.", fline + 10, firstline + 108, null, null, "left");
    doc.text(
      fline + 15,
      firstline + 108,
      `Salah satu Pihak mengakhiri Surat Perjanjian ini karena adanya Peristiwa Wanprestasi terhadap ketentuan Hak dan Kewajiban sebagaimana diatur pada Pasal 6 Surat Perjanjian ini.`,
      {
        maxWidth: 143,
        align: "justify",
      },
    );
    doc.text("(3)", fline + 1, firstline + 125, null, null, "left");
    doc.text(
      fline + 9,
      firstline + 125,
      `PIHAK yang berkehendak untuk mengakhiri Surat Perjanjian ini sebagaimana dimaksud pada ayat (2) b. dengan ketentuan sebagai berikut :`,
      {
        maxWidth: 150,
        align: "justify",
      },
    );
    doc.text("a.", fline + 10, firstline + 136, null, null, "left");
    doc.text(
      fline + 15,
      firstline + 136,
      `Wajib memberitahukan secara tertulis kepada PIHAK lainnya dalam waktu 30 (tiga puluh) hari kalender sebelum tanggal pengakhiran yang dikehendaki;`,
      {
        maxWidth: 143,
        align: "justify",
      },
    );
    doc.text("b.", fline + 10, firstline + 147, null, null, "left");
    doc.text(
      fline + 15,
      firstline + 147,
      `Tidak menghapuskan hak, kewajiban dan tanggung jawab masing-masing pihak yang masih harus dilakukan dan/atau diselesaikan terhadap pihak lainnya berdasarkan ketentuan-ketentuan dalam Surat Perjanjian ini; dan`,
      {
        maxWidth: 143,
        align: "justify",
      },
    );
    doc.text("c.", fline + 10, firstline + 163, null, null, "left");
    doc.text(
      fline + 15,
      firstline + 163,
      `PARA PIHAK sepakat dan setuju untuk mengenyampingkan ketentuan Pasal 1266 Kitab Undang-Undang Hukum Perdata, sehingga pengakhiran Surat Perjanjian dengan alasan sebagaimana diatur dalam Surat Perjanjian ini secara sah cukup dilakukan dengan pemberitahuan tertulis dari masing-masing pihak dan tidak memerlukan penetapan atau putusan Pengadilan.`,
      {
        maxWidth: 143,
        align: "justify",
      },
    );
    doc.text(fline + 80, firstline + 197, `Pasal 11`, {
      maxWidth: 152,
      align: "center",
    });
    doc.text(fline + 80, firstline + 203, `PENYELESAIAN PERSELISIHAN`, {
      maxWidth: 152,
      align: "center",
    });
    doc.text("(1)", fline + 1, firstline + 210, null, null, "left");
    doc.text(
      fline + 9,
      firstline + 210,
      `Para Pihak berkewajiban untuk berupaya sungguh-sungguh menyelesaikan secara damai semua perselisihan yang timbul dari atau berhubungan dengan Surat perjanjian ini atau interpretasinya selama atau setelah pelaksanaan pekerjaan ini. Penyelesaian secara damai dapat dilakukan melalui musyawarah secara langsung antara PARA PIHAK atau melalui perantaraan pihak ketiga yang disepakati oleh Para Pihak dalam bentuk mediasi.`,
      {
        maxWidth: 150,
        align: "justify",
      },
    );

    pdfFooter();
    doc.addPage();
    /** Page 7 */
    doc.text("(2)", fline + 1, firstline + 5, null, null, "left");
    doc.text(
      fline + 9,
      firstline + 5,
      `Apabila penyelesaian perselisihan tidak dapat dilakukan oleh PARA PIHAK secara musyawarah, PARA PIHAK menetapkan Pengadilan Negeri Jakarta Pusat sebagai tempat penyelesaian perselisihan.`,
      {
        maxWidth: 150,
        align: "justify",
      },
    );

    doc.text(fline + 80, firstline + 23, `Pasal 12`, {
      maxWidth: 152,
      align: "center",
    });
    doc.text(fline + 80, firstline + 28, `PENUTUP`, {
      maxWidth: 152,
      align: "center",
    });
    doc.text("(1)", fline + 1, firstline + 35, null, null, "left");
    doc.text(
      fline + 9,
      firstline + 35,
      `PARA PIHAK menyatakan telah menyetujui untuk melaksanakan perjanjian ini sesuai dengan ketentuan perundang-undangan yang berlaku.`,
      {
        maxWidth: 150,
        align: "justify",
      },
    );
    doc.text("(2)", fline + 1, firstline + 46, null, null, "left");
    doc.text(
      fline + 9,
      firstline + 46,
      `Perjanjian ini dibuat dalam rangkap 2 (dua) terdiri dari 2 (dua) asli bermaterai cukup untuk PIHAK KESATU dan PIHAK KEDUA.`,
      {
        maxWidth: 150,
        align: "justify",
      },
    );
    doc.text("(3)", fline + 1, firstline + 57, null, null, "left");
    doc.text(
      fline + 9,
      firstline + 57,
      `Perjanjian ini berlaku sejak tanggal ditetapkan.`,
      {
        maxWidth: 150,
        align: "justify",
      },
    );

    doc.text(fline + 39, firstline + 80, `UNTUK DAN ATAS NAMA`, {
      maxWidth: 65,
      align: "center",
    });

    doc.text(
      fline + 39,
      firstline + 85,
      `DIREKTORAT JENDERAL PENDIDIKAN ISLAM`,
      {
        maxWidth: 165,
        align: "center",
      },
    );

    doc.text(fline + 39, firstline + 90, `PEJABAT PEMBUAT KOMITMEN,`, {
      maxWidth: 65,
      align: "center",
    });

    doc.text(fline + 125, firstline + 80, `UNTUK DAN ATAS NAMA`, {
      maxWidth: 65,
      align: "center",
    });

    doc.text(
      fline + 125,
      firstline + 85,
      `${dataMadrasah?.nama || "............."}`,
      {
        maxWidth: 65,
        align: "center",
      },
    );
    // if (!dataMadrasah?.namaKomite) {
    //   badgeBorder("*", 66, 118, "k");
    // }
    // doc.addImage(TTDKemenag, "JPEG", fline + 30, firstline + 95, 18, 18);
    doc.text(fline + 39, firstline + 120, `MUCHAMAD SIDIK SISDIYANTO`, {
      maxWidth: 100,
      align: "center",
    });
    if (!dataMadrasah?.namaKepala) {
      badgeBorder("3", 152, 113, "m");
    }
    doc.text(
      fline + 125,
      firstline + 115,
      `${dataMadrasah?.namaKepala || "............."}`,
      {
        maxWidth: 65,
        align: "center",
      },
    );
    doc.text(
      fline + 125,
      firstline + 120,
      `KEPALA ${dataMadrasah?.nama || "............."}`,
      {
        maxWidth: 65,
        align: "center",
      },
    );
    pdfFooter();

    function badgeBorder(textNo: any, noX: any, noY: any, colour: any) {
      colour === "m"
        ? doc.setFillColor(255, 77, 79)
        : doc.setFillColor(250, 172, 20);
      doc.circle(noX, firstline + noY, 2, "FD");
      doc.setFontSize(8);
      doc.text(textNo, noX - 0.5, firstline + (noY + 1), null, null);
      doc.setFontSize(11);
    }

    function pdfFooter() {
      doc.setLineWidth(0.5);
      doc.line(fline - 5, 260, 192, 260);
      doc.addImage(Barcode, "JPEG", fline - 5, 262, 14, 14);
      doc.setFont("helvetica", "normal");
      doc.setFontSize(8);
      doc.text(
        fline + 13,
        265,
        "Dokumen ini telah ditandatangani secara elektronik menggunakan sertifikat elektronik yang diterbitkan BSrE. Untuk memastikan keasliannya, silahkan scan QRCode dan pastikan diarahkan ke alamat https://tte.kemenag.go.id atau kunjungi halaman https://tte.kemenag.go.id",
        {
          maxWidth: 150,
          align: "justify",
        },
      );
      doc.text("Token : yZc0QZ", fline + 13, 275, null, null, "left");
      doc.setFont("times", "normal");
      doc.setFontSize(11);
    }

    const blobPDF = doc.output("bloburl");
    if (action === "download") {
      doc.save("surat-perjanjian-kerjasama");
    }

    setPdfFile(blobPDF);
  };

  return (
    <>
      <Modal
        open={params.openModal}
        width={1200}
        title="Generate Surat Perjanjian Kerjasama"
        onCancel={params.onClose}
        footer={[
          <Button onClick={params.onClose}>Kembali</Button>,
          <Button type="primary" onClick={() => form.submit()}>
            Download
          </Button>,
        ]}>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          <div className="col-span-2">
            <embed
              // aria-labelledby="rkam"
              src={`${pdfFile}#toolbar=0`}
              // type="application/pdf"
              width="100%"
              height="700px"></embed>
          </div>
          <div className="col-span-1">
            <div className="mb-4">
              <Alert
                message="Informasi"
                description={
                  <ol className="list-disc">
                    <li>
                      Lengkapi data dokumen dibawah ini berdasarkan nomor{" "}
                      <Badge status="error" count={"*"} showZero /> yang ada
                      pada sebelah kiri.
                    </li>
                  </ol>
                }
                type="info"
                showIcon
              />
            </div>
            <Form
              form={form}
              name="formGenerate"
              layout="vertical"
              onValuesChange={handleValuesChange}
              onFinish={handleDownload}>
              <Form.Item
                label={
                  <>
                    Nomor <Badge count={1} />
                  </>
                }
                rules={[
                  {
                    required: true,
                    message: "Nomor tidak boleh kosong!",
                  },
                ]}
                name="nomor">
                <Input placeholder="Nomor" />
              </Form.Item>
              <Form.Item
                label={
                  <>
                    Alamat <Badge count={2} />
                  </>
                }
                rules={[
                  {
                    required: true,
                    message: "Alamat tidak boleh kosong!",
                  },
                ]}
                name="alamat">
                <Input.TextArea placeholder="Alamat" />
              </Form.Item>
              <Form.Item
                label={
                  <>
                    Kecamatan <Badge count={3} />
                  </>
                }
                rules={[
                  {
                    required: true,
                    message: "Kecamatan tidak boleh kosong!",
                  },
                ]}
                name="kecamatan">
                <Input placeholder="Kecamatan" />
              </Form.Item>
              {/* <Form.Item
                label={
                  <>
                    Jumlah Bantuan Operational <Badge count={6} />
                  </>
                }
                name="nilai"
                rules={[
                  {
                    required: true,
                    message: "Jumlah Bantuan Operational tidak boleh kosong!",
                  },
                ]}>
                <InputNumber
                  style={{ width: "100%" }}
                  formatter={(value) =>
                    `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  }
                  prefix="Rp."
                  placeholder="Silahkan isi Nilai Bantuan"
                />
              </Form.Item> */}
            </Form>
          </div>
        </div>
      </Modal>
    </>
  );
};
export default ModalGenerateManual;
