/** @format */

import React, { useEffect } from "react";
import { Switch, TreeSelect, Select, Input, Form, Modal } from "antd";
import { useSelector } from "react-redux";
import { treeNested, treeNestedBelanja } from "../../../../utils/helper";

type FormModalActionType = {
  title: any;
  editData: any;
  statusMadrasah: any;
  openModal: boolean;
  hanldeClose: () => void;
  handleSave: (e: any) => void;
};

const ModalMasterKomponenBiaya = ({
  title,
  openModal,
  statusMadrasah,
  editData,
  handleSave,
  hanldeClose,
}: FormModalActionType) => {
  const [form] = Form.useForm();
  const store = useSelector((state: any) => state.store);
  const tmpKategoriKomponenBiaya = store.kategoriKomponenBiaya || [];
  const tmpKategoriBelanja = store?.kategoriBelanja || [];
  const tmpSatuan = store.satuan || [];
  const refJenisPembiayaan = store.jenisPembiayaan || [];
  const refKomponenBiayaMaster = store.komponenBiayaMaster || [];

  const dataSelectKategoriBiaya = treeNested(tmpKategoriKomponenBiaya);
  const dataSelectJenisBiaya = treeNestedBelanja(tmpKategoriBelanja);

  const handleReset = () => {
    form.resetFields();
    hanldeClose();
  };

  useEffect(() => {
    if (editData && title === "Edit") {
      form.setFieldsValue({
        kategoriKomponenBiaya: editData.kode_kategori,
        kode: editData.kode,
        nama: editData.nama,
        spesifikasi: editData.spesifikasi,
        deskripsi: editData.deskripsi,
        satuan: editData.satuan,
        jenisBelanja: editData.jenis_belanja || [],
        belanjaLain: editData.belanja_lain ? true : false,
        jenisPembiayaan: editData.jenis_pembelanjaan_id,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editData]);

  const handleAfterSave = (values: any) => {
    const payload = {
      kodeKategori: values.kategoriKomponenBiaya,
      kode: values.kode,
      nama: values.nama,
      spesifikasi: values.spesifikasi,
      deskripsi: values.deskripsi || null,
      satuan: values.satuan,
      jenisBelanja: values.jenisBelanja,
      tahun: 0,
      statusMadrasah: statusMadrasah,
      belanjaLain: values.belanjaLain ? true : false,
      jenisPembelanjaanId: values.jenisPembiayaan,
    };
    handleSave(payload);
    handleReset();
  };

  const validateKode = (_, values) => {
    const val = values.toLowerCase();
    const tmpFilterKomponentBiaya =
      (editData &&
        refKomponenBiayaMaster.filter((item) => item.kode !== editData.kode)) ||
      refKomponenBiayaMaster;
    const checkKode: any =
      tmpFilterKomponentBiaya.find(
        (item) =>
          item.kode !== null &&
          (item.kode || "").toString().toLowerCase() === val,
      ) || null;
    if (checkKode) {
      return Promise.reject("Kode Sudah Digunakan!");
    } else {
      return Promise.resolve();
    }
  };

  return (
    <Modal
      open={openModal}
      title={`${title} Master Komponen Biaya`}
      width={1000}
      okText="Simpan"
      cancelText="Batal"
      onCancel={handleReset}
      onOk={() => form.submit()}>
      <Form
        key="formMasterKomponenBiaya"
        name="formMasterKomponenBiaya"
        layout="vertical"
        form={form}
        //        onValuesChange={handleValueChange}
        onFinish={handleAfterSave}>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div>
            <Form.Item
              label="Kategori Komponen Biaya"
              name="kategoriKomponenBiaya"
              rules={[
                {
                  required: true,
                  message: "Kategori Komponen Biaya tidak boleh kosong!",
                },
              ]}>
              <TreeSelect
                showSearch
                style={{ width: "100%" }}
                dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
                placeholder="Kategori Komponen Biaya"
                allowClear
                treeData={dataSelectKategoriBiaya}
              />
            </Form.Item>
          </div>
          <div>
            <Form.Item
              label="Jenis Belanja"
              name="jenisBelanja"
              rules={[
                {
                  required: true,
                  message: "Jenis Belanja tidak boleh kosong!",
                },
              ]}>
              <TreeSelect
                showSearch
                multiple
                style={{ width: "100%" }}
                dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
                placeholder="Jenis Belanja"
                allowClear
                treeData={dataSelectJenisBiaya}
              />
            </Form.Item>
          </div>
          <div>
            <Form.Item
              label="Kode Komponen Biaya"
              name="kode"
              rules={[
                {
                  required: true,
                  message: "Kode Komponen Biaya tidak boleh kosong!",
                },
                { validator: validateKode },
              ]}>
              <Input
                placeholder="Kode"
                disabled={(title === "Edit" && true) || false}
              />
            </Form.Item>
          </div>
          <div>
            <Form.Item
              label="Nama Komponen Biaya"
              name="nama"
              rules={[
                {
                  required: true,
                  message: "Nama Komponen Biaya tidak boleh kosong!",
                },
              ]}>
              <Input placeholder="Nama" />
            </Form.Item>
          </div>
          <div>
            <Form.Item
              label="Satuan"
              name="satuan"
              rules={[
                { required: true, message: "Satuan tidak boleh kosong!" },
              ]}>
              <Select
                showSearch
                optionFilterProp="children"
                placeholder="Pilih Satuan">
                {tmpSatuan.length &&
                  tmpSatuan.map((e: any, i: any) => {
                    return (
                      <Select.Option key={`satuan${i}`} value={e.kode}>
                        {e.nama}
                      </Select.Option>
                    );
                  })}
              </Select>
            </Form.Item>
          </div>
          <div>
            <Form.Item
              label="Spesifikasi"
              name="spesifikasi"
              rules={[
                {
                  required: true,
                  message: "Spesifikasi tidak boleh kosong!",
                },
              ]}>
              <Input.TextArea placeholder="Spesifikasi" />
            </Form.Item>
          </div>
          <div>
            <Form.Item label="Deskripsi" name="deskripsi">
              <Input.TextArea placeholder="Deskripsi" />
            </Form.Item>
          </div>
          <div>
            <Form.Item
              label="Termasuk Biaya Lainnya"
              name="belanjaLain"
              valuePropName="checked">
              <Switch />
            </Form.Item>
          </div>
          <div>
            <Form.Item
              label="Jenis Pembiayaan"
              name="jenisPembiayaan"
              rules={[
                {
                  required: true,
                  message: "Jenis pembiayaan tidak boleh kosong!",
                },
              ]}>
              <Select
                showSearch
                optionFilterProp="children"
                placeholder="Pilih Jenis Pembiayaan">
                {refJenisPembiayaan.length &&
                  refJenisPembiayaan.map((e: any, i: any) => {
                    return (
                      <Select.Option key={`jenisPembiayaan${i}`} value={e.id}>
                        {e.description}
                      </Select.Option>
                    );
                  })}
              </Select>
            </Form.Item>
          </div>
        </div>
      </Form>
    </Modal>
  );
};
export default ModalMasterKomponenBiaya;
