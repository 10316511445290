/** @format */

import React, { useState, useEffect } from "react";
import {
  Modal,
  Upload,
  Typography,
  Button,
  Alert,
  Table,
  Tag,
  Space,
  Descriptions,
  Tooltip,
} from "antd";
import type { UploadProps } from "antd";
import moment from "moment";
import "moment/locale/id";
import {
  editPengajuanVerifikasiDokumenBos,
  getPlanningAll,
} from "../../../../../services/v2/planningservice";
import {
  deleteDokumenRencanaDefinitif,
  editDokumenRencanaDefinitif,
  getDokumenRencanaDefinitif,
  postDokumenRencanaDefinitif,
} from "../../../../../services/v2/planningservice/rencanapendapatandefinitif";
import {
  formatRupiah,
  openInNewTab,
  uuidv4,
} from "../../../../../utils/helper";
import {
  checkDataErkam,
  checkDataLpj,
  checkFileDok,
  checkPenerimaBOSID,
  getStatusDokumen,
  getTanggalPengajuanBos,
  listDokumen,
  spesialBOSTahap2,
  validasiListDokumen,
} from "../../../../../utils/helper/helperBos";
import {
  CopyOutlined,
  DownloadOutlined,
  FileDoneOutlined,
  ReloadOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import {
  ButtonTableDelete,
  ButtonTableDetail,
  ButtonTableDownload,
} from "../../../../../components/Button";
import axios from "axios";
import { planningService } from "../../../../../services/v2/constant";
import { getReferenceAll } from "../../../../../services/v2/referenceservice";
import { useDispatch, useSelector } from "react-redux";
import { getLaporanErkam } from "../../../../../services/v2/planningservice/laporanservices";
import { setStore } from "../../../../../redux/actions";
import { getLaporanPengembalianDanaAxios } from "../../../../../services/v2/reportservice";
import ModalLihatDokumenBos from "../../../../../components/Modal/ModalLihatDokumenBos";
import ModalGenerateDoc from "../../../PaguInd/Belanja/Component/ModalGenerateDoc";
import ModalDokumenAction from "../../../../../components/Modal/ModalActionDokumen";
import ModalDoubleDokumenBos from "../../../../../components/Modal/ModalDoubleDocument";
const { Text } = Typography;
export const baseURL: string =
  window.REACT_APP_API_URL ||
  // "https://api-gateway.erkam-v2.kemenag.go.id/api";
  "https://staging-api-gateway.erkam-v2.kemenag.go.id/api";
export const publicURL: string =
  window.REACT_APP_PUBLIC_URL ||
  "https://staging-frontend.erkam-v2.kemenag.go.id/";

type FormModalActionType = {
  dataSumber: any;
  realisasiPendapatanHeader: any;
  openModal: boolean;
  auth: any;
  hanldeClose: () => void;
};

const ModalDokumenBos = ({
  dataSumber,
  realisasiPendapatanHeader,
  openModal,
  auth,
  hanldeClose,
}: FormModalActionType) => {
  const dispatch = useDispatch();
  const store = useSelector((state: any) => state.store);
  const listDoc = ["docLpj1", "docTBUP", "docRKAM6"];
  const [loading, setLoading] = useState<any>(false);
  const [statusBos, setStatusBos] = useState<any>(null);
  const [title, setTitle] = useState("");
  const [idUpload, setIdUpload] = useState<any>(null);
  const [verificationButton, setVerificationButton] = useState<any>(false);
  const [dataPencairan, setDataPencairan] = useState<any>(null);
  const [fileList, setFileList] = useState<any>([]);
  const [fileUpload, setFileUpload] = useState<any>(null);
  const [dataBos, setDataBos] = useState<any>(null);
  const [nilaiPencairan, setNilaiPencairan] = useState<any>(null);
  const [nilaiPengembalianDana, setNilaiPengembalianDana] = useState<any>(null);
  const [dataLaporan, setDataLaporan] = useState<any>([]);
  const [pdfFile, setPdfFile] = useState<any>(null);
  const [dataAction, setDataAction] = useState<any>(null);
  const [fileDoubleDok, setFileDoubleDok] = useState<any>([]);
  const [dataRekBos, setDataRekBos] = useState<any>([]);
  const tmpLaporanRKAM = store.laporanRKAM || [];
  let dataTableDokumen = listDokumen();
  const [statusDokumen, setStatusDokumen] = useState<any>(getStatusDokumen(0));

  const [messageInformation, setMessageInformation] = useState<any>(null);
  const [openModalGenerate, setOpenModalGenerate] = useState(false);
  const [openModalPriview, setOpenModalPriview] = useState(false);
  const [openModalAction, setOpenModalAction] = useState(false);
  const [openModalDataDouble, setOpenModalDataDouble] = useState(false);
  const tmpSpesialBOSTahap2 = spesialBOSTahap2();
  const checkDataMadrasah = spesialBOSTahap2().includes(auth?.madrasah?.nsm);
  const showAlert = checkDataErkam(dataLaporan, checkDataMadrasah);
  const alertLpj = checkDataLpj(
    realisasiPendapatanHeader,
    dataSumber,
    checkDataMadrasah,
  );
  const listJadwalPengajuan = getTanggalPengajuanBos();

  const getJadwal =
    listJadwalPengajuan.find(
      (item) =>
        moment().isAfter(item.startDate) && moment().isBefore(item.endDate),
    ) || null;

  useEffect(() => {
    buttonValidation();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusBos, fileList, nilaiPengembalianDana, dataSumber]);

  useEffect(() => {
    getPortalBos(dataSumber);
    getDataDokumen();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataSumber]);

  const getPortalBos = async (values: any) => {
    const dataPortalBos = await getPlanningAll("portal-bos", {
      nsm: auth?.madrasah?.nsm,
      tahun: auth?.isTahun,
    });

    const dataRekBos =
      (await getReferenceAll("rekening-portalbos", {
        activated: 1,
        nsm: auth?.madrasah?.nsm,
        tahun: auth?.isTahun,
      })) || [];

    setDataRekBos(dataRekBos);

    getStatusPencairan(
      values?.kode_sumber_dana === "apbn_bos_semester_1" ? 1 : 2,
      dataRekBos,
    );

    const tmpTotalNilai =
      (dataPortalBos &&
        dataPortalBos["apbn_bos_semester1"] +
          dataPortalBos["apbn_bos_semester2"]) ||
      "1";

    const tahunLpj: any = auth?.isTahun - 1;
    const tmpNilaiPengambalianDana: any = await getLaporanPengembalianDanaAxios(
      tahunLpj,
      auth,
      auth?.madrasah?.id,
    );
    setNilaiPengembalianDana(tmpNilaiPengambalianDana || { sisa: 0 });

    if (tmpLaporanRKAM.length === 0) {
      const laporanRKAM = await getLaporanErkam({
        tahun: auth?.isTahun,
        madrasahId: auth?.madrasah?.id,
      });

      setTimeout(() => {
        dispatch(setStore({ laporanRKAM }));
      }, 100);
      setDataLaporan(laporanRKAM || []);
    } else {
      setDataLaporan(tmpLaporanRKAM || []);
    }

    setNilaiPencairan(tmpTotalNilai);
    setDataBos(dataPortalBos || null);
    setDataAction({
      tahap: (values?.kode_sumber_dana === "apbn_bos_semester_1" && 1) || 2,
      checkDataMadrasah: checkDataMadrasah,
      auth: auth,
      madrasahId: auth?.madrasah?.id,
      status: "s",
    });
  };

  const getStatusPencairan = async (tahap: any, daRek: any) => {
    axios
      .get(
        `${baseURL}${planningService}/dokumen-pencairan/nsm?tahun=${auth?.isTahun}&nsm=${auth?.madrasah?.nsm}&tahap=${tahap}`,
        {
          withCredentials: false,
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Headers":
              "Content-Type,X-Amz-Date,Authorization,X-Api-Key,X-Amz-Security-Token,X-Erkam-Year",
            "Access-Control-Allow-Credentials": true,
            Authorization: "Bearer " + auth?.token,
            "X-Erkam-Year": auth?.isTahun,
          },
        },
      )
      .then(function (response: any) {
        if (response?.data?.return) {
          let res: any = response?.data?.return;
          const dataRek: any =
            daRek.find((item) => item.nsm === auth?.madrasah?.nsm) || null;
          let dataTmp: any = {
            ...res,
            namaBank: dataRek?.nama_bank,
            namaRekening: dataRek?.no_rekening_nama,
            noRekening: dataRek?.no_rekening,
            alamat: auth?.madrasah?.alamat_jalan,
          };

          setDataPencairan(dataTmp);
        } else {
          setDataPencairan(null);
        }
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });
  };

  const handleAjukanVerifikasi = async () => {
    setLoading(true);
    let payload: any = {
      tahap: dataSumber?.kode_sumber_dana === "apbn_bos_semester_1" ? 1 : 2,
      tahun: auth?.isTahun,
      nsm: auth?.madrasah?.nsm,
      keterangan: null,
      statusPenerimaBos: statusBos,
    };

    try {
      await editPengajuanVerifikasiDokumenBos(payload);
      Modal.success({
        title: "Sukses",
        content: "Dokumen Berhasil diajukan ke verifikator",
        centered: true,
        wrapClassName: "erkam-custom-modal",
      });
      handleBeforeClose();
      getDataDokumen();
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const getDataDokumen = async () => {
    setLoading(true);
    let params: any = `?tahun=${auth?.isTahun}&madrasahId=${auth?.madrasah?.id}`;
    try {
      const res = await getDokumenRencanaDefinitif(params);
      let result: any =
        (res?.length && res.filter((item) => item?.jenisMadrasah === "R")) ||
        [];
      setFileList(result || []);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const uploadFile = async (params) => {
    setLoading(true);
    let fileData: any = new FormData();
    fileData.append("id", idUpload);
    fileData.append("file", params.file);
    fileData.append("kodeSumberDana", dataSumber?.kode_sumber_dana);
    fileData.append("namaSumberDana", dataSumber?.nama_sumber_dana);
    fileData.append("keterangan", "");
    fileData.append("tahun", auth.isTahun);
    fileData.append("kodeDok", fileUpload.kode);
    fileData.append("namaDok", params.file.name);
    fileData.append("rencanaPendapatanId", dataSumber?.id);
    fileData.append("jenisMadrasah", "R");
    fileData.append("penerimaBos", statusBos);
    fileData.append("tahap", fileUpload.tahap);
    if (title === "upload") {
      try {
        await postDokumenRencanaDefinitif(fileData);
        getDataDokumen();
        Modal.success({
          title: "Sukses",
          content: "Dokumen berhasil upload",
          centered: true,
          wrapClassName: "erkam-custom-modal",
        });
        return params;
      } catch (error) {
        console.log(error);
        Modal.error({
          title: "Gagal",
          content:
            "Maaf, sedang ada kesalahan pada sisi server. coba beberapa saat lagi",
          okType: "danger",
          centered: true,
          wrapClassName: "erkam-custom-modal",
        });
      }
    } else {
      try {
        await editDokumenRencanaDefinitif(fileData, idUpload);
        getDataDokumen();
        Modal.success({
          title: "Sukses",
          content: "Dokumen berhasil upload",
          centered: true,
          wrapClassName: "erkam-custom-modal",
        });
        return params;
      } catch (error) {
        console.log(error);
        Modal.error({
          title: "Gagal",
          content:
            "Maaf, sedang ada kesalahan pada sisi server. coba beberapa saat lagi",
          okType: "danger",
          centered: true,
          wrapClassName: "erkam-custom-modal",
        });
      }
    }
    setLoading(false);
  };

  const props: UploadProps = {
    name: "file",
    customRequest: uploadFile,
    accept: "application/pdf",
    showUploadList: false,
    headers: {
      authorization: "authorization-text",
    },
    beforeUpload: (file) => {
      let isPDF = file.type === "application/pdf";
      const maxFile = file.size >= 2000000 ? false : true;
      if (!isPDF) {
        Modal.warning({
          title: "Peringatan!",
          content: "Dokumen yang di upload harus .pdf",
          centered: true,
          wrapClassName: "erkam-custom-modal",
        });
      }
      if (!maxFile) {
        Modal.warning({
          title: "Peringatan!",
          content: "Maksimal file 2M",
          centered: true,
          wrapClassName: "erkam-custom-modal",
        });
        isPDF = false;
      }
      return isPDF || Upload.LIST_IGNORE;
    },
    onChange(info) {
      if (info.file.status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === "done") {
        Modal.success({
          title: "Sukses",
          content: `${info.file.name} file berhasil upload`,
          centered: true,
          wrapClassName: "erkam-custom-modal",
        });
      } else if (info.file.status === "error") {
        Modal.error({
          title: "Kesalahan",
          content: `${info.file.name} file gagal upload.`,
          okType: "danger",
          centered: true,
          wrapClassName: "erkam-custom-modal",
        });
      }
    },
    progress: {
      strokeColor: {
        "0%": "#108ee9",
        "100%": "#87d068",
      },
      strokeWidth: 3,
      format: (percent) => percent && `${parseFloat(percent.toFixed(2))}%`,
    },
  };

  let columnsDokumen: any = [
    {
      title: "Nama",
      dataIndex: "nama",
      width: 110,
      key: "nama",
      render: (nama, record) => {
        if (!listDoc.includes(record.key)) {
          return (
            <>
              <Text>{nama}</Text>
            </>
          );
        } else {
          if (record.key === "docLpj1" && checkDataMadrasah) {
            nama = "Laporan Pertanggung Jawaban (LPJ) Desember 2023";
          }
          return nama;
        }
      },
    },
    {
      title: "Nama File",
      key: "namaFile",
      width: 110,
      render: (record) => {
        let namaFile = checkFileDok(
          fileList,
          record.kode,
          record.tahap,
          statusBos,
        );

        if (record.key === "docBPD") {
          let infoPengembalian: any =
            (nilaiPengembalianDana?.sisa !== 0 && (
              <div className="text-red-500">{`Lampirkan bukti pengembalian dana sebesar saldo  transaksi ${formatRupiah(
                nilaiPengembalianDana?.sisa,
              )}`}</div>
            )) ||
            "";
          return (
            (namaFile && namaFile?.namaDok && namaFile?.namaDok) ||
            infoPengembalian
          );
        } else {
          return (namaFile?.namaDok && namaFile?.namaDok) || "";
        }
      },
    },
    {
      title: "Tanggal Upload",
      key: "tanggalUpload",
      width: 70,
      render: (record) => {
        let namaFile = checkFileDok(
          fileList,
          record.kode,
          record.tahap,
          statusBos,
        );

        return (
          (namaFile?.updated_at &&
            moment(namaFile?.created_at).format("DD MMM YYYY")) ||
          ""
        );
      },
    },
    {
      title: "Tanggal Verifikasi",
      key: "tanggalVerifikasi",
      width: 70,
      render: (record) => {
        let namaFile = checkFileDok(
          fileList,
          record.kode,
          record.tahap,
          statusBos,
        );
        return (
          (namaFile?.statusValidasi.toString() === "1" &&
            namaFile?.tanggal_verifikasi_dokumen &&
            moment(namaFile?.tanggal_verifikasi_dokumen).format(
              "DD MMM YYYY",
            )) ||
          "" ||
          ""
        );
      },
    },
    {
      title: "Keterangan",
      width: 150,
      key: "keterangan",
      render: (record) => {
        let namaFile = checkFileDok(
          fileList,
          record.kode,
          record.tahap,
          statusBos,
        );

        if (record.key === "docRKAM6" && showAlert) {
          return (
            <div className="mb-4">
              <Alert
                className="mb-4"
                message={
                  <>
                    Rencana belanja definitif belum sesuai dengan rencana
                    pendapatan.
                  </>
                }
                type="error"
                showIcon
              />
            </div>
          );
        } else if (record.key === "docLpj1" && alertLpj) {
          return (
            <div className="mb-4">
              <Alert
                className="mb-4"
                message={alertLpj}
                type="error"
                showIcon
              />
            </div>
          );
        } else {
          return (namaFile?.keterangan && namaFile?.keterangan) || "";
        }
      },
    },
    {
      title: "Status",
      width: 80,
      key: "statusValidasi",
      render: (record) => {
        let namaFile = checkFileDok(
          fileList,
          record.kode,
          record.tahap,
          statusBos,
        );
        if (!["docLpj1", "docRKAM6"].includes(record?.key)) {
          return namaFile ? (
            <Tag
              color={
                (namaFile?.statusValidasi.toString() === "2" && "red") ||
                (namaFile?.statusValidasi.toString() === "1" && "#008000") ||
                "#ffca27"
              }>
              {(namaFile?.statusValidasi.toString() === "2" && "DiTolak") ||
                (namaFile?.statusValidasi.toString() === "1" && "Disetujui") ||
                "Belum Disetujui"}
            </Tag>
          ) : record?.key !== "docTBUP" ? (
            <Tag>Dokumen Kosong</Tag>
          ) : (
            <Tag
              color={dataPencairan?.statusPencairan === 3 ? "#008000" : "gray"}>
              {dataPencairan?.statusPencairan === 3
                ? "Disetujui"
                : "Belum Disetujui"}
            </Tag>
          );
        }
      },
    },
    {
      title: "Upload (Max 2M)",
      key: "upload",
      fixed: "right",
      width: 140,
      render: (record) => {
        let namaFile = checkFileDok(
          fileList,
          record.kode,
          record.tahap,
          statusBos,
        );
        let doubleDok: any =
          fileList.filter(
            (item) =>
              item.kodeDok === record.kode &&
              item.tahap === record.tahap &&
              item.penerimaBos === checkPenerimaBOSID(statusBos),
          ) || [];
        let linkDoc: any = record?.doc || null;
        if (record?.key === "docPPDB2" && checkDataMadrasah) {
          linkDoc = "/doc/Surat_Permohonan_Pencairan_Dana_BOS_TA_2024_1.docx";
        }

        if (
          record?.key === "docKPBT7" &&
          dataPencairan?.tahap === 2 &&
          dataPencairan?.batch === 1
        ) {
          linkDoc = "/doc/Kuitansi_Penerimaan_BOS_2024.docx";
        }

        return (
          <Space>
            {record?.kode === 4 && (
              <Button
                icon={<FileDoneOutlined />}
                type="primary"
                onClick={() => setOpenModalGenerate(true)}>
                Generate
              </Button>
            )}
            {!namaFile ? (
              !listDoc.includes(record?.key) ? (
                <>
                  {record?.key !== "docBPD" ? (
                    <Upload maxCount={1} {...props}>
                      <Button
                        loading={loading}
                        icon={<UploadOutlined />}
                        onClick={() => handleAfterUpload(record)}>
                        Upload
                      </Button>
                    </Upload>
                  ) : (
                    (nilaiPengembalianDana?.sisa !== 0 && (
                      <Upload maxCount={1} {...props}>
                        <Button
                          icon={<UploadOutlined />}
                          onClick={() => handleAfterUpload(record)}>
                          Upload
                        </Button>
                      </Upload>
                    )) ||
                    ""
                  )}
                </>
              ) : (
                <Button
                  icon={
                    record?.key !== "docTBUP" ? (
                      <ReloadOutlined />
                    ) : (
                      <DownloadOutlined />
                    )
                  }
                  onClick={() => handleAction(record?.key)}
                  disabled={
                    record?.key !== "docTBUP"
                      ? false
                      : dataPencairan?.statusPencairan === 3
                      ? false
                      : true
                  }>
                  {record?.key !== "docTBUP" ? "Lihat Data" : "Download"}
                </Button>
              )
            ) : (
              <>
                {!listDoc.includes(record?.key) ? (
                  <>
                    <ButtonTableDetail
                      tooltips="Lihat Dokumen"
                      loading={loading}
                      onClick={() => priviewDoc(namaFile.namaFile)}
                    />
                    <ButtonTableDelete
                      disabled={
                        (namaFile?.statusValidasi?.toString() === "1" &&
                          true) ||
                        // (namaFile?.statusVerifikasiDokumen?.toString() ===
                        //   "1" &&
                        //   true) ||
                        false
                      }
                      onClick={() => {
                        Modal.confirm({
                          title: "Perhatian",
                          content: "Yakin Hapus Data?",
                          onOk() {
                            return handleDeleteUpload(namaFile.id);
                          },
                          onCancel() {},
                          okText: "Hapus",
                          cancelText: "Batal",
                          okType: "danger",
                          centered: true,
                          wrapClassName: "erkam-custom-modal",
                        });
                      }}
                    />
                    {doubleDok.length >= 2 && (
                      <Tooltip title="Double Dokumen">
                        <Button
                          onClick={() => handleDoubleDokumen(doubleDok)}
                          icon={<CopyOutlined />}
                        />
                      </Tooltip>
                    )}
                  </>
                ) : (
                  <Button
                    icon={
                      record?.key !== "docTBUP" ? (
                        <ReloadOutlined />
                      ) : (
                        <DownloadOutlined />
                      )
                    }
                    disabled={
                      record?.key !== "docTBUP"
                        ? false
                        : dataPencairan?.statusPencairan === 3
                        ? false
                        : true
                    }
                    onClick={() => handleAction(record?.key)}>
                    {record?.key !== "docTBUP" ? "Lihat Data" : "Download"}
                  </Button>
                )}
              </>
            )}
            {linkDoc && (
              <ButtonTableDownload
                onClick={() => openInNewTab(publicURL + linkDoc)}
              />
            )}
          </Space>
        );
      },
    },
  ];

  const getDownloadDokumen = async (params: any) => {
    axios
      .get(
        `${baseURL}${planningService}/dokumen-rencana-definitif/download?fileName=${params}`,
        {
          withCredentials: false,
          responseType: "arraybuffer",

          headers: {
            Accept: "application/pdf",
            "Content-Type": "application/pdf",
            "Access-Control-Allow-Headers":
              "Content-Type,X-Amz-Date,Authorization,X-Api-Key,X-Amz-Security-Token,X-Erkam-Year",
            "Access-Control-Allow-Credentials": true,
            Authorization: "Bearer " + auth?.token,
            "X-Erkam-Year": auth?.isTahun,
          },
        },
      )
      .then(function (response: any) {
        setOpenModalPriview(true);
        const blob = new Blob([response.data], {
          type: "application/pdf",
        });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        setPdfFile(link);
        setLoading(false);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
        setLoading(false);
      });
  };

  const priviewDoc = async (params: any) => {
    try {
      setLoading(true);
      await getDownloadDokumen(params);
    } catch (error) {
      console.log(error);
    }
  };

  const handleDeleteUpload = async (idFile: any) => {
    try {
      await deleteDokumenRencanaDefinitif(idFile);
      Modal.success({
        title: "Sukses",
        content: "Penghapusan Dokumen Berhasil",
        centered: true,
        wrapClassName: "erkam-custom-modal",
      });
      getDataDokumen();
    } catch (error) {
      console.log(error);
      Modal.error({
        title: "Gagal",
        content:
          "Maaf, sedang ada kesalahan pada sisi server. coba beberapa saat lagi",
        okType: "danger",
        centered: true,
        wrapClassName: "erkam-custom-modal",
      });
    }
  };

  const handleAfterUpload = (record: any) => {
    setFileUpload(record);
    setTitle("upload");
    setIdUpload(uuidv4());
  };

  const handleAction = async (action: any) => {
    setOpenModalAction(true);
    setTitle(action);
  };

  const handleDoubleDokumen = (fileDouble: any) => {
    setFileDoubleDok(fileDouble);
    setOpenModalDataDouble(true);
  };

  const handleDeleteAction = async (values) => {
    const res = await deleteDokumenRencanaDefinitif(values?.id);
    if (res) {
      let fileDoubleTmp: any =
        fileDoubleDok.filter((item) => item.id !== values.id) || [];
      setFileDoubleDok(fileDoubleTmp);
      Modal.success({
        title: "Sukses",
        content: "Dokumen berhasil dihapus",
        centered: true,
        wrapClassName: "erkam-custom-modal",
      });
      getDataDokumen();
      // handleDokumen(dataMadrasah, dataMadrasah?.tahap);
    }
  };

  const handleBeforeClose = () => {
    hanldeClose();
    setStatusBos(null);
  };

  const buttonValidation = () => {
    let penerimaBos: any = checkPenerimaBOSID(statusBos);

    let tahapTmp: any =
      (dataSumber?.kode_sumber_dana === "apbn_bos_semester_1" && 1) || 2;

    let listVerifikasiDoc: any =
      validasiListDokumen("R", tahapTmp, penerimaBos) || [];

    let dataFileTmp: any =
      (fileList.length &&
        fileList.filter(
          (item) => item.tahap === tahapTmp && item.penerimaBos === penerimaBos,
        )) ||
      [];

    if (nilaiPengembalianDana?.sisa === 0) {
      listVerifikasiDoc = listVerifikasiDoc.filter((item) => item !== 7);
      handleVerifikasiDokumen(listVerifikasiDoc, dataFileTmp);
    } else {
      handleVerifikasiDokumen(listVerifikasiDoc, dataFileTmp);
    }
  };

  const handleVerifikasiDokumen = (arrList: any, arrDoc: any) => {
    let validTolak: any =
      arrDoc.find((item: any) => item?.statusValidasi === 2) || null;

    let validList: any = arrDoc.map((item) => item?.kodeDok) || [];
    let validVerifikasi: any =
      arrDoc.find((item: any) => item?.statusVerifikasiDokumen === 1) || null;

    if (dataPencairan) {
      setVerificationButton(true);
      setStatusDokumen(
        getStatusDokumen(
          (dataPencairan?.statusPencairan === 1 && 3) ||
            (dataPencairan?.statusPencairan === 2 && 5) ||
            (dataPencairan?.statusPencairan === 3 && 6) ||
            (dataPencairan?.statusPencairan === 4 && 7),
        ),
      );
      return;
    }

    if (validVerifikasi) {
      setStatusDokumen(getStatusDokumen(2));
      setVerificationButton(true);
      return;
    }

    if (!getJadwal) {
      const lastJadwal: any =
        listJadwalPengajuan
          .reverse()
          .find((item) => moment().isAfter(item.endDate)) || null;
      let textInformation: any = (
        <div className="text-red-500 font-bold">
          Batas Pengajuan BOS TAHAP {lastJadwal?.tahap} BATCH{" "}
          {lastJadwal?.batch} akan berakhir pada{" "}
          {moment(lastJadwal?.endDate).format("DD MMMM YYYY")} pukul 17.00 WIB
        </div>
      );
      setMessageInformation(textInformation);
      setVerificationButton(true);
    } else {
      let textInformation: any = (
        <div className="text-green-500 font-bold">
          Pengajuan BOS TAHAP {getJadwal?.tahap} BATCH {getJadwal?.batch} sedang
          berjalan di mulai dari{" "}
          {moment(getJadwal?.startDate).format("DD MMMM YYYY")} sampai dengan{" "}
          {moment(getJadwal?.endDate).format("DD MMMM YYYY")} pukul 17.00 WIB
        </div>
      );
      setMessageInformation(textInformation);
      if (validTolak) {
        setStatusDokumen(getStatusDokumen(1));
        setVerificationButton(true);
      } else if (arrList.filter((item) => !validList.includes(item)).length) {
        setVerificationButton(true);
      } else if (checkDataErkam(dataLaporan, tmpSpesialBOSTahap2)) {
        setStatusDokumen(getStatusDokumen(0));
        setVerificationButton(true);
        return;
      } else if (alertLpj) {
        setVerificationButton(true);
      } else {
        setStatusDokumen(getStatusDokumen(0));
        setVerificationButton(false);
      }
    }
  };

  const handleCloseDouble = () => {
    setOpenModalDataDouble(false);
    setFileDoubleDok(null);
  };

  if (
    !tmpSpesialBOSTahap2.includes(auth?.madrasah?.nsm) &&
    dataSumber?.kode_sumber_dana === "apbn_bos_semester_2"
  ) {
    dataTableDokumen = dataTableDokumen.filter(
      (item) => !["docPPK5", "docBPD", "docPTJM3"].includes(item.key),
    );
  } else {
    dataTableDokumen = dataTableDokumen.filter(
      (item) => !["docSPTJB"].includes(item.key),
    );
  }

  const checkDataNilai = (dataBos: any, dataSumber: any) => {
    let nilaiTahap: any = 0;
    if (dataBos && dataSumber) {
      nilaiTahap =
        (dataSumber?.kode_sumber_dana === "apbn_bos_semester_1" &&
          dataBos?.apbn_bos_semester1) ||
        dataBos?.apbn_bos_semester2;
    }
    return nilaiTahap;
  };

  dataTableDokumen =
    dataTableDokumen.filter((item) => {
      let kodeId =
        dataSumber?.kode_sumber_dana === "apbn_bos_semester_1" ? 1 : 2;

      return (
        item.tahap === kodeId &&
        item.key !== (([3, 4].includes(statusBos) && "docLpj1") || "docSPPD")
      );
    }) || [];

  return (
    <Modal
      open={openModal}
      title={`Upload Dokumen Tahap ${
        (dataSumber?.kode_sumber_dana === "apbn_bos_semester_1" && 1) || 2
      }`}
      width={1300}
      onCancel={handleBeforeClose}
      footer={[
        <Button key="back" onClick={handleBeforeClose}>
          Kembali
        </Button>,
        statusBos && statusBos !== "bukanPenerimaTahap1" && (
          <Button
            loading={loading}
            disabled={verificationButton}
            onClick={handleAjukanVerifikasi}
            type="primary">
            Ajukan Verifikasi
          </Button>
        ),
      ]}>
      {!statusBos ? (
        <>
          <div className="text-center mb-4">
            {(dataSumber?.kode_sumber_dana === "apbn_bos_semester_1" &&
              `Apakah Madrasah sudah pernah menerima bantuan Dana BOS
          ${auth?.isTahun - 1}`) ||
              `Apakah Madrasah sudah pernah menerima bantuan Dana BOS Tahap 1
          ${auth?.isTahun}`}
            ?
          </div>
          <div className="grid grid-cols-2 gap-4 ">
            <Button type="primary" onClick={() => setStatusBos(1)}>
              {(dataSumber?.kode_sumber_dana === "apbn_bos_semester_1" &&
                `Penerima BOS ${auth?.isTahun - 1}`) ||
                `Penerima BOS Tahap 1 ${auth?.isTahun}`}
            </Button>
            <Button
              type="primary"
              onClick={() =>
                setStatusBos(
                  (dataSumber?.kode_sumber_dana === "apbn_bos_semester_1" &&
                    4) ||
                    "bukanPenerimaTahap1",
                )
              }>
              {(dataSumber?.kode_sumber_dana === "apbn_bos_semester_1" &&
                `Bukan Penerima BOS ${auth?.isTahun - 1}`) ||
                `Bukan Penerima BOS Tahap 1 ${auth?.isTahun}`}
            </Button>
          </div>
        </>
      ) : statusBos === "bukanPenerimaTahap1" ? (
        <>
          <div className="text-center mb-4">
            Apakah Madrasah sudah pernah menerima bantuan Dana BOS
            {auth?.isTahun - 1} ?
          </div>
          <div className="grid grid-cols-2 gap-4 ">
            <Button type="primary" onClick={() => setStatusBos(2)}>
              Penerima BOS {auth?.isTahun - 1}
            </Button>
            <Button type="primary" onClick={() => setStatusBos(3)}>
              Bukan Penerima BOS {auth?.isTahun - 1}
            </Button>
          </div>
        </>
      ) : (
        <>
          <div className="flex items-center justify-center mb-4">
            <div className="flex-none text-center">
              <div className="mb-2 text-xl font-bold">
                {auth?.madrasah?.nama || ""}
              </div>
              <Descriptions layout="vertical" bordered column={5}>
                <Descriptions.Item
                  label={`Alokasi Dana BOS TA. ${auth?.isTahun}`}>
                  <div className="mb-2 text-xl font-bold">
                    {formatRupiah(nilaiPencairan)}
                  </div>
                </Descriptions.Item>
                <Descriptions.Item
                  label={`Nominal ${dataSumber?.nama_sumber_dana}`}>
                  <div className="mb-2 text-xl font-bold">
                    {formatRupiah(checkDataNilai(dataBos, dataSumber))}
                  </div>
                </Descriptions.Item>
                {dataPencairan?.tahap === 2 && dataPencairan?.batch === 1 && (
                  <>
                    <Descriptions.Item label={`Nominal Non AA`}>
                      <div className="mb-2 text-xl font-bold">
                        {formatRupiah(
                          checkDataNilai(dataBos, dataSumber) -
                            dataBos?.nilai_aa,
                        )}
                      </div>
                    </Descriptions.Item>
                    <Descriptions.Item label={`Nominal AA`}>
                      <div className="mb-2 text-xl font-bold">
                        {formatRupiah(dataBos?.nilai_aa)}
                      </div>
                    </Descriptions.Item>
                  </>
                )}
                <Descriptions.Item label="Jumlah Siswa">
                  <div className="mb-2 text-xl font-bold">
                    {dataBos?.jumlahSiswa} Orang
                  </div>
                </Descriptions.Item>
              </Descriptions>
            </div>
          </div>
          <Table
            className="mb-2"
            columns={columnsDokumen}
            dataSource={dataTableDokumen}
            loading={loading}
            scroll={{ x: 1200 }}
            bordered
            pagination={false}
          />
          <div className="text-xs mb-2">
            <div className="flex items-center gap-4">
              <Alert
                message={
                  <div className="p-2">
                    <div>
                      Print Bukti Upload dapat di akses ketika status dokumen
                      menjadi <span className="font-bold">Pencairan</span>.
                    </div>
                    {messageInformation}
                  </div>
                }
                type="info"
                showIcon
              />
              <div className="flex-auto">
                <div className="text-lg text-center font-bold mb-2">
                  Status Dokumen
                </div>
                <div className="text-center">
                  <Tooltip title={statusDokumen?.status?.toolstips}>
                    <Tag color={statusDokumen?.status?.color}>
                      {statusDokumen?.status?.infoMessage}
                    </Tag>
                    {(dataPencairan?.statusPencairan === 1 && (
                      <div className="mt-2">
                        Tanggal Verifikasi:{" "}
                        {moment(dataPencairan?.created_at)
                          .utc()
                          .format("DD MMMM YYYY")}
                      </div>
                    )) ||
                      (dataPencairan?.statusPencairan > 1 && (
                        <>
                          <div className="mt-2">
                            {`Batch ${dataPencairan?.batch}`}
                          </div>

                          <div className="mt-2">
                            Tanggal:{" "}
                            {moment(dataPencairan?.submissionDate)
                              .utc()
                              .format("DD MMMM YYYY")}
                          </div>
                        </>
                      ))}
                  </Tooltip>
                </div>
              </div>
            </div>
          </div>
          <ModalLihatDokumenBos
            onClose={() => setOpenModalPriview(!openModalPriview)}
            openModalPriview={openModalPriview}
            pdfFile={pdfFile}
          />

          <ModalGenerateDoc
            openModal={openModalGenerate}
            nilaiPencairan={nilaiPencairan}
            rekBos={dataRekBos}
            onClose={() => setOpenModalGenerate(!openModalGenerate)}
          />

          <ModalDokumenAction
            openModal={openModalAction}
            dataAction={dataPencairan}
            dataLaporan={dataLaporan}
            dataFile={fileList}
            dataBOS={dataBos}
            dataMadrasah={dataAction}
            onClose={() => setOpenModalAction(false)}
            action={title}
          />

          <ModalDoubleDokumenBos
            openModalDataDouble={openModalDataDouble}
            loading={loading}
            onPriview={priviewDoc}
            onDeleteFile={handleDeleteAction}
            fileDoubleDok={fileDoubleDok}
            onClose={handleCloseDouble}
          />
        </>
      )}
    </Modal>
  );
};
export default ModalDokumenBos;
