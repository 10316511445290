/** @format */
import React from "react";

import { useSelector } from "react-redux";

import ModalValidasiDanaBos from "../../components/Modal/ModalValidasiDanaBos";

const DashboardLooker = () => {
  const store = useSelector((state: any) => state.store);
  const auths = useSelector((state: any) => state.auth);
  const auth = auths?.data;
  const isTahun = auths.isTahun;
  // kode_provinsi
  let urlLooker: any =
    (auth?.group_role === "pusat" &&
      "https://lookerstudio.google.com/embed/reporting/3612fe9d-9b00-4731-9638-a17907623f56/page/toucD") ||
    (auth?.group_role === "provinsi" &&
      `https://lookerstudio.google.com/embed/reporting/ccbeb14c-aedf-4af0-a6d1-4362f38aec0e/page/toucD/edit?params=%7B"df70":"include%25EE%2580%25800%25EE%2580%2580IN%25EE%2580%2580${auth?.kode_provinsi}"%7D`) ||
    (auth?.group_role === "kabkota" &&
      `https://lookerstudio.google.com/embed/reporting/681da8ac-6f99-4f5b-b28f-f67b5b1b3700/page/toucD/edit?params=%7B"df61":"include%25EE%2580%25800%25EE%2580%2580IN%25EE%2580%2580${auth?.kode_kabkota}"%7D`) ||
    // "https://lookerstudio.google.com/embed/reporting/7513ed5b-92ae-43d8-adf5-d50cc6036a1a/page/toucD";
    (auth?.madrasah?.nsm &&
      `https://lookerstudio.google.com/embed/reporting/7513ed5b-92ae-43d8-adf5-d50cc6036a1a/page/toucD?params=%7B"df40":"include%25EE%2580%25800%25EE%2580%2580IN%25EE%2580%2580${auth?.madrasah?.nsm}"%7D`) ||
    "https://lookerstudio.google.com/embed/reporting/7513ed5b-92ae-43d8-adf5-d50cc6036a1a/page/toucD";
  return (
    <>
      <ModalValidasiDanaBos
        dataAuth={auth}
        dataStore={store}
        dataYear={isTahun}
      />
      <div className="p-5 h-screen flex">
        <iframe
          id="looker"
          key="frameLooker"
          title="frameLooker"
          src={urlLooker}
          className="w-full"></iframe>
      </div>
    </>
  );
};

export default DashboardLooker;
