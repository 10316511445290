/** @format */

import api from "../../../utils/api";
import { planningService } from "../constant";

const basePath = `${planningService}/rencana-pendapatan`;

/** Get Alokasi Rencana Pendapatan Definitif  By ID*/
export const getRencanaPendapatanDefinitifListAlokasi = async (params: any) => {
  const response = await api.get(`${basePath}/listalokasi/${params}`);
  return response?.data?.return;
};

/** Get Logs Rencana Pendapatan Definitif  By ID*/
export const getRencanaPendapatanDefinitifLogs = async () => {
  const response = await api.get(`${basePath}/logs`);
  return response?.data?.return;
};

/** Post Rencana Pendapatan Definitif  */
export const postRencanaPendapatanDefinitif = async (params: any) => {
  const response = await api.post(`${basePath}`, params);
  return response?.data?.return;
};

/** Edit Rencana Pendapatan Definitif */
export const editRencanaPendapatanDefinitif = async (params: any) => {
  const id = params.id;
  const response = await api.put(`${basePath}/${id}`, params);
  return response?.data?.return;
};

/** import  Rencana Pendapatan Definitif */
export const importRencanaPendapatanDefinitif = async (params: any) => {
  const response = await api.post(
    `${basePath}/import-dari-indikatif?tahun=${params}`,
  );
  return response?.data?.return;
};

/** Change locked status */
export const changeLockedStatus = async (params: any, id: any) => {
  const response = await api.post(
    `${basePath}/update-status-locked?tahun=${params}&madrasahId=${id}`,
  );
  return response?.data?.return;
};

/** Delete Rencana Pendapatan Definitif */
export const deleteRencanaPedapatanDefinitif = async (params: any) => {
  try {
    const response = await api.delete(`${basePath}/${params}`);
    return response?.data?.return;
  } catch (error) {
    console.log(error);
  }
};

/** Get Dokumen Rencana Definitif  By ID*/
export const getDokumenRencanaDefinitif = async (params: any) => {
  const response = await api.get(
    `${planningService}/dokumen-rencana-definitif${params}`,
  );
  return response?.data?.return;
};

/** Post Dokumen Rencana Definitif  */
export const postDokumenRencanaDefinitif = async (params: any) => {
  const response = await api.post(
    `${planningService}/dokumen-rencana-definitif`,
    params,
    {
      headers: { "Content-Type": "multipart/form-data" },
    },
  );
  return response?.data?.return;
};

/** Edit Dokumen Rencana  Definitif */
export const editDokumenRencanaDefinitif = async (params: any, id: any) => {
  const response = await api.put(
    `${planningService}/dokumen-rencana-definitif/${id}`,
    params,
    {
      headers: { "Content-Type": "multipart/form-data" },
    },
  );
  return response?.data?.return;
};

/** Status Dokumen Rencana  Definitif */
export const statusDokumenRencanaDefinitif = async (id: any) => {
  const response = await api.put(
    `${planningService}/dokumen-rencana-definitif/status-validasi/${id}`,
  );
  return response?.data?.return;
};

/** Delete Dokumen Rencana  Definitif */
export const deleteDokumenRencanaDefinitif = async (params: any) => {
  try {
    const response = await api.delete(
      `${planningService}/dokumen-rencana-definitif/${params}`,
    );
    return response?.data?.return;
  } catch (error) {
    console.log(error);
  }
};

/** Update validasi Alokasi sumber dana bos */
export const updateAlokasiSumberDanaBos = async (nominal: any, tahun: any) => {
  const response = await api.put(
    `${planningService}/rencana-pendapatan/update-bos-tahap-2?nominalBosTahap2=${nominal}&tahun=${tahun}`,
  );
  return response?.data?.return;
};

/** Post Alokasi sumber dana bos auto sync from portal bos */
export const postAlokasiSumberDanaBos = async (
  tahap1: any,
  tahap2: any,
  tahun: any,
  jumlahSiswa: any,
) => {
  const response = await api.post(
    `${planningService}/bos/tambah?nominalBosTahap1=${tahap1}&nominalBosTahap2=${tahap2}&tahun=${tahun}&jumlahSiswa=${jumlahSiswa}`,
  );
  return response?.data?.return;
};

/** Delete All Transaction Rencana  Definitif */
export const deleteAllTrasactionRencanaDefinitif = async (params: any) => {
  try {
    const response = await api.delete(
      `${planningService}/all/${params?.madrasah?.id}/${params?.isTahun}`,
    );
    return response?.data?.return;
  } catch (error) {
    console.log(error);
  }
};
