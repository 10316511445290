/** @format */

import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Space, Table, Button, Tabs } from "antd";
import { BreadCrumb } from "../../../../../components";
import { ButtonDropdownExport } from "../../../../../components/Button";
// import moment from "moment";
// import "moment/locale/id";
import notifAlert from "../../../../../components/NotifAlert";
import InputSearch from "../../../../../components/InputSearch";

import { useHistory } from "react-router-dom";
import { DokumenStatistic } from "../../../../../components/Statistic";
const { TabPane } = Tabs;

const ListPencairanKabkota = () => {
  const route = useHistory();
  const dataState: any = route ? route.location.state : null;
  const itemBreadcrumb = [
    { path: "/", breadcrumbName: "Home" },
    { path: "/", breadcrumbName: "Laporan" },
    { path: "/", breadcrumbName: "Laporan Rencana" },
    { path: "/", breadcrumbName: "Pencairan Dokumen BOS" },
    { path: "/", breadcrumbName: `Kab - Kota ${dataState?.namaKabkota}` },
  ];
  const auths = useSelector((state: any) => state.auth);
  const store = useSelector((state: any) => state.store);
  const [activeTabKey, setActiveTabKey] = useState<string>("n");
  const listMadrasah = store?.listMadrasah || [];
  const auth = auths?.data || null;
  const [search, setSearch] = useState<any>(null);
  const [tmpMadrasah, setTmpMadrasah] = useState<any>([]);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (auths?.isLogin) {
      getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth?.isTahun, activeTabKey]);

  /** Filter Search */
  let dataTable: any = search
    ? tmpMadrasah.filter((item: any) => {
        const val = search.toLowerCase();
        return (
          (item.nsm !== null &&
            (item.nsm || "").toString().toLowerCase().includes(val)) ||
          (item.nama !== null &&
            (item.nama || "").toString().toLowerCase().includes(val))
        );
      })
    : tmpMadrasah;

  const getData = async () => {
    setLoading(true);
    try {
      let listTmp: any =
        listMadrasah
          .filter(
            (item) =>
              item.kode_kabkota === dataState?.kodeKabkota?.toString() &&
              item.status === activeTabKey,
          )
          .map((item, i) => {
            return {
              no: i + 1,
              statusPencairan: "belumCair",
              tanggalPencairan: null,
              ...item,
            };
          }) || [];
      //   //    let dataTmp:any =  refKabkota.filter(item)
      setTmpMadrasah(listTmp);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const handleExportFile = (type: any) => {
    if (dataTable.length > 0) {
      if (type === "pdf") {
        handleExportPdf();
      } else if (type === "excel") {
        handleExportExcel();
      }
    } else {
      notifAlert({
        type: "warning",
        description: "Maaf data tidak tersedia!",
      });
    }
  };

  const handleExportExcel = async () => {};

  function handleExportPdf() {}

  let columnsDokumen: any = [
    {
      title: "NSM",
      key: "nsm",
      dataIndex: "nsm",
    },
    {
      title: "Madrasah",
      dataIndex: "nama",
      key: "nama",
    },
    {
      title: "Negeri/Swasta",
      dataIndex: "status",
      key: "status",
      render: (status) => (status === "n" && "Negeri") || "Swasta",
    },
    {
      title: "Jenjang",
      dataIndex: "jenjang",
      key: "jenjang",
      render: (jenjang) => jenjang?.nama,
    },
    {
      title: "Status Pencairan",
      key: "statusPencairan",
      dataIndex: "statusPencairan",
      render: (statusPencairan) =>
        (statusPencairan === "belumCair" && "Belum Pencairan") ||
        "Sudah Pencairan",
    },
    {
      title: "Tanggal Pencairan",
      key: "tanggalPencairan",
      dataIndex: "tanggalPencairan",
    },
    {
      title: "Nilai",
      key: "nilaiPencairan",
    },
    {
      title: "Aksi",
      key: "aksi",
      render: (record) => {
        return (
          <Space>
            <Button type="primary">Lihat Detail</Button>
          </Space>
        );
      },
    },
  ];

  return (
    <>
      <BreadCrumb
        routes={itemBreadcrumb}
        title={`Kab - Kota ${dataState?.namaKabkota}`}
        back={true}
        toBack={() =>
          route.push({
            pathname: `../listprov/${dataState?.kode_provinsi}`,
            state: {
              page: dataState?.page || 1,
              pageSize: dataState?.pageSize || 10,
              ...dataState?.dataProvinsi,
            },
          })
        }
        footer={
          <Tabs
            defaultActiveKey="n"
            size="small"
            onChange={(key) => {
              setActiveTabKey(key);
            }}>
            <TabPane tab="Madrasah Negeri" key="n" />
            <TabPane tab="Madrasah Swasta" key="s" />
          </Tabs>
        }
      />
      <DokumenStatistic />
      <div className="m-5 p-5 bg-white shadow-md rounded">
        <div className="mb-4 flex justify-end">
          <div className="mr-4">
            <Space wrap>
              <ButtonDropdownExport handleExportFile={handleExportFile} />
            </Space>
          </div>
          <InputSearch
            className="w-3/4"
            onChange={(e: any) => setSearch(e.currentTarget.value)}
          />
        </div>
        <Table
          loading={loading}
          columns={columnsDokumen}
          dataSource={dataTable}
          bordered
        />
      </div>
    </>
  );
};

export default ListPencairanKabkota;
