/** @format */

import React, { useState, useEffect } from "react";
import { BreadCrumb } from "../../../components";

import autoTable from "jspdf-autotable";
import jsPDF from "jspdf";
// import { ExportToExcel } from '../../../components/Export/ExportToExcel';
// import { formatCurr } from "../../../utils/helper";

import moment from "moment";
import "moment/locale/id";

import { Table, Space } from "antd";
import FilterMadrasah from "../Component/FilterMadrasah";
import { useDispatch, useSelector } from "react-redux";
import { getMadrasah } from "../../../services/v2/usermanservice/madrasahservices";
import { getLaporanOutputKegiatan } from "../../../services/v2/reportservice";
import { uuidv4 } from "@antv/xflow-core";
import { ButtonDropdownExport } from "../../../components/Button";
import notifAlert from "../../../components/NotifAlert";
import { setStore } from "../../../redux/actions";

const LaporanOutputKegiatan = () => {
  const itemBreadcrumb = [
    { path: "/", breadcrumbName: "Home" },
    { path: "/", breadcrumbName: "Laporan" },
    { path: "/", breadcrumbName: "Laporan Realisasi" },
    { path: "/", breadcrumbName: "Output Kegiatan" },
  ];
  const auths = useSelector((state: any) => state.auth);
  const auth = auths?.data || null;
  const store = useSelector((state: any) => state.store);
  const profile = store?.madrasahProfile || null;
  const groupRole = auth?.group_role || "";
  const refKabkota = store.kabkota || [];
  const refProvinsi = store.provinsi || [];
  const refKecamatan = store.kecamantan || [];
  const [tmpMadrasah, setTmpMadrasah] = useState<any>([]);
  const [tmpFilter, setTmpFilter] = useState<any>(null);
  const [dataMadrasah, setDataMadrasah] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState<any>([]);
  const dispatch = useDispatch();
  const tmpListMadrasah = store?.listMadrasah || [];
  // const [page, setPage] = useState(1);
  // const [pageSize, setPageSize] = useState(10);

  let dataTable =
    tableData?.length &&
    tableData.map((item) => {
      return { ...item, key: uuidv4() };
    });

  // let totalDataTable = dataTable.length;

  /**
   * Filter Pagination
   */
  // dataTable = dataTable.filter((v, i) => {
  //   let start = pageSize * (page - 1);
  //   let end = start + pageSize;

  //   return i >= start && i < end;
  // });

  const getData = async () => {
    setLoading(true);
    if (groupRole !== "madrasah") {
      if (tmpListMadrasah.length === 0) {
        let filter: any = "";
        if (groupRole === "provinsi") {
          filter = `?kodeProvinsi=${auth?.kode_provinsi}`;
        }
        if (groupRole === "kabkota") {
          filter = `?kodeProvinsi=${auth?.kode_provinsi}&kode_kabkota=${auth?.kode_kabkota}`;
        }
        const madrasah = await getMadrasah(filter);
        const listMadrasah = madrasah || [];
        setTimeout(() => {
          dispatch(setStore({ listMadrasah }));
        }, 100);
        setTmpMadrasah(madrasah);
      } else {
        setTmpMadrasah(tmpListMadrasah);
      }
      const payload = {
        tahun: auth?.isTahun,
        madrasahId: tmpFilter?.madrasahId,
      };
      if (tmpFilter?.madrasahId) {
        const resMadrasah = tmpListMadrasah.find((item) =>
          item.id.includes(tmpFilter?.madrasahId),
        );
        setDataMadrasah(resMadrasah);
        const res = await getLaporanOutputKegiatan(payload);
        setTableData(res || []);
      }
    } else {
      setDataMadrasah(profile);
      const payload = {
        tahun: auth?.isTahun,
        madrasahId: auth?.madrasah?.id,
      };
      try {
        const res = await getLaporanOutputKegiatan(payload);
        setTableData(res || []);
      } catch (error) {
        console.log(error);
      }
    }

    setLoading(false);
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tmpFilter, auth?.isTahun]);

  const columns = [
    {
      title: "No",
      key: "no",
      render: (_, rec, i) => i + 1,
    },
    {
      title: "SNP",
      dataIndex: "namaSnp",
      key: "namaSnp",
    },
    {
      title: "Kegiatan",
      dataIndex: "namaKegiatan",
      key: "namaKegiatan",
    },
    {
      title: "Sub Kegiatan",
      dataIndex: "namaSubKegiatan",
      key: "namaSubKegiatan",
    },
    {
      title: "Output Kegiatan",
      children: [
        {
          title: "Indikator",
          dataIndex: "indikatorOutput",
          key: "indikatorOutput",
        },
        {
          title: "Target",
          key: "target",
          render: (record) =>
            `${record.indikatorOutputTarget || "-"} ${
              record.satuanIndikator || "-"
            }`,
        },
        {
          title: "Realisasi",
          key: "realisasi",
          render: (record) =>
            `${record.realisasiTarget || "-"} ${record.satuanRealisasi || "-"}`,
        },
      ],
    },
    {
      title: "Capaian",
      key: "capaian",
      render: (record) => {
        let qty = Math.round(
          (parseInt(record.realisasiTarget) /
            parseInt(record.indikatorOutputTarget)) *
            100,
        );
        return `${qty} %`;
      },
    },
    {
      title: "Sumber Dana",
      dataIndex: "sumberDana",
      key: "sumberDana",
    },
    {
      title: "Keterangan",
      dataIndex: "keterangan",
      key: "keterangan",
    },
  ];

  const handleChangeValues = (values: any) => {
    setTmpFilter(values);
    // getData();
  };

  const handleExportFile = (type) => {
    if (dataTable.length > 0) {
      if (type === "pdf") {
        handleExportPdf();
      } else if (type === "excel") {
        // handleExportExcel();
      }
    } else {
      notifAlert({
        type: "warning",
        description:
          "Harap pilih data yang ingin di export melalui fungsi filter!",
      });
    }
  };

  function handleExportPdf() {
    const namaKecamatan =
      refKecamatan.find((item) => item.kode === dataMadrasah?.kode_kecamatan) ||
      null;
    const namaKabkota =
      refKabkota.find((item) => item.kode === dataMadrasah?.kode_kabkota) ||
      null;
    const namaProvinsi =
      refProvinsi.find((item) => item.kode === dataMadrasah?.kode_provinsi) ||
      null;

    const doc: any = new jsPDF({
      putOnlyUsedFonts: true,
      orientation: "landscape",
      floatPrecision: 16,
    });

    let tableTmp: any = [];

    dataTable.length &&
      // eslint-disable-next-line array-callback-return
      dataTable.map((item, i) => {
        let qty = Math.round(
          (parseInt(item.realisasiTarget) /
            parseInt(item.indikatorOutputTarget)) *
            100,
        );

        tableTmp.push([
          i + 1,
          item.namaSnp,
          item.namaKegiatan,
          item.namaSubKegiatan,
          item.indikatorOutput,
          `${item.indikatorOutputTarget || "-"} ${item.satuanIndikator || "-"}`,
          `${item.realisasiTarget || "-"} ${item.satuanRealisasi || "-"}`,
          `${qty} %`,
          item.sumberDana,
          item.keterangan,
        ]);
      });

    var fline = 10;
    doc.setFont("times", "bold");
    doc.setFontSize(14);

    doc.text(`RENCANA KEGIATAN DAN ANGGARAN MADRASAH`, 150, 12, {
      align: "center",
    });
    doc.text(`OUTPUT KEGIATAN TAHUN ANGGARAN ${auths?.isTahun}`, 150, 18, {
      align: "center",
    });
    doc.setFont("times", "normal");
    doc.setFontSize(10);
    doc.text("Madrasah/PPS", fline, 30);
    doc.text(`: ${dataMadrasah?.nama}`, fline + 60, 30);
    doc.text("NSM", fline, 35);
    doc.text(`: ${dataMadrasah?.nsm}`, fline + 60, 35);
    doc.text("Kecamatan", fline, 40);
    doc.text(`: ${namaKecamatan?.nama || "-"}`, fline + 60, 40);
    doc.text("Kabupaten / Kota", fline, 45);
    doc.text(`: ${namaKabkota?.nama || "-"}`, fline + 60, 45);
    doc.text("Provinsi", fline, 50);
    doc.text(`: ${namaProvinsi?.nama || "-"}`, fline + 60, 50);

    let las: any = [];
    autoTable(doc, {
      styles: { lineColor: 244, lineWidth: 0.1 },
      headStyles: {
        halign: "center",
        valign: "middle",
        fillColor: [0, 128, 0],
      }, // Cells in first column centered and green
      // columnStyles: {
      //   0: { halign: "center" },
      //   1: { halign: "left" },
      //   2: { halign: "left" },
      //   3: { halign: "left" },
      //   4: { halign: "center" },
      //   5: { halign: "center" },
      //   6: { halign: "center" },
      //   7: { halign: "right" },
      // },
      startY: 55,
      margin: { top: 10, left: 10, right: 10 },
      // columns: headers,
      head: [
        [
          { content: "No", rowSpan: 2 },
          { content: "SNP", rowSpan: 2 },
          { content: "Kegiatan", rowSpan: 2 },
          { content: "Sub Kegiatan", rowSpan: 2 },
          { content: "Output Kegiatan", colSpan: 3 },
          { content: "Capaian", rowSpan: 2 },
          { content: "Sumber Dana", rowSpan: 2 },
          { content: "Keterangan", rowSpan: 2 },
        ],
        ["Indikator", "Target", "Realisasi"],
      ],
      body: tableTmp,
      didDrawPage: (d) => las.push(d.cursor),
    });

    let lastLine: any = doc.lastAutoTable.finalY + 5;
    const footerText = [
      {
        text: "Mengetahui",
        line: fline,
        space: 0,
        bottom: 10,
      },
      {
        text: moment().format("dddd, DD MMMM YYYY"),
        line: fline,
        space: 210,
        bottom: 0,
      },
      {
        text: "Kepala Madrasah",
        line: fline,
        space: 0,
        bottom: 5,
      },
      {
        text: "Bendahara Madrasah",
        line: fline,
        space: 210,
        bottom: 0,
      },
      {
        text:
          (dataMadrasah?.nama_kepala && `( ${dataMadrasah?.nama_kepala} )`) ||
          "(.................)",
        line: fline,
        space: 0,
        bottom: 30,
      },
      {
        text:
          (dataMadrasah?.nama_bendahara &&
            `( ${dataMadrasah?.nama_bendahara} )`) ||
          "(.................)",
        line: fline,
        space: 210,
        bottom: 0,
      },
    ];
    let tmpBottom = lastLine;
    // eslint-disable-next-line array-callback-return
    footerText.map((item) => {
      tmpBottom += item.bottom;
      if (tmpBottom > 195) {
        doc.addPage();
        tmpBottom = 15;
      }
      doc.text(item.text, fline + item.space, tmpBottom);
    });

    doc.output("dataurlnewwindow", { filename: "laporan-output-kegiatan" });
    notifAlert({
      type: "success",
      description: "Data berhasil di export",
    });
  }

  return (
    <>
      <BreadCrumb routes={itemBreadcrumb} title="Output Kegiatan" />
      {groupRole !== "madrasah" && (
        <FilterMadrasah
          handleChangeValues={handleChangeValues}
          madrasah={tmpMadrasah}
        />
      )}
      <div className="m-5 p-5 bg-white shadow-sm">
        <div className="mb-4 flex justify-end">
          <Space size={[8, 8]} wrap>
            <ButtonDropdownExport handleExportFile={handleExportFile} />
          </Space>
        </div>
        <div className="w-full">
          <Table
            scroll={{ x: 1300 }}
            style={{ fontSize: 10 }}
            columns={columns}
            dataSource={dataTable}
            bordered
            loading={loading}
          />
        </div>
      </div>
    </>
  );
};

export default LaporanOutputKegiatan;
