/** @format */

import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { BreadCrumb } from "../../../../components";
import { Table } from "antd";
import { useSelector } from "react-redux";
import InputSearch from "../../../../components/InputSearch";

const ListMadrasah = () => {
  const route = useHistory();
  const dataState: any = route ? route.location.state : null;
  const itemBreadcrumb = [
    { path: "/", breadcrumbName: "Home" },
    { path: "/", breadcrumbName: "Pengaturan" },
    { path: "/", breadcrumbName: "Tanggal eRKAM BKBA" },
    { path: "/", breadcrumbName: "Daftar Madrasah Penerima BKBA" },
  ];
  const store = useSelector((state: any) => state.store);
  const refProvinsi = store.provinsi || [];
  const refKabkota = store.kabkota || [];
  // const [loading, setLoading] = useState<any>(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const tmpListMadrasah = store?.listMadrasah || [];
  const [search, setSearch] = useState<any>(null);
  const dataListMadrasahBkba =
    tmpListMadrasah.filter((item) => dataState?.nsm?.includes(item.nsm)) || [];
  let dataTable = search
    ? dataListMadrasahBkba.filter((item: any) => {
        const val = search.toLowerCase();
        return (
          (item.nsm !== null && (item.nsm || "").toLowerCase().includes(val)) ||
          (item.nama !== null &&
            (item.nama || "").toLowerCase().includes(val)) ||
          (item?.jenjang?.nama !== null &&
            (item?.jenjang?.nama || "").toLowerCase().includes(val))
        );
      })
    : dataListMadrasahBkba;

  let totalDataTable = dataTable.length;
  /**
   * Filter Pagination
   */
  dataTable = dataTable.filter((v, i) => {
    let start = pageSize * (page - 1);
    let end = start + pageSize;

    return i >= start && i < end;
  });

  const columns: any = [
    {
      title: "NSM",
      dataIndex: "nsm",
      key: "nsm",
    },
    {
      title: "Madrasah",
      dataIndex: "nama",
      key: "nama",
    },
    {
      title: "Jenjang",
      key: "jenjang",
      render: (record) => record.jenjang.nama,
    },
    {
      title: "Provinsi",
      dataIndex: "kode_provinsi",
      key: "kode_provinsi",
      render: (kode_provinsi) =>
        refProvinsi
          .filter((item) => item.kode === kode_provinsi)
          .map((item) => item.nama),
    },
    {
      title: "Kab / Kota",
      dataIndex: "kode_kabkota",
      key: "kode_kabkota",
      render: (kode_kabkota) =>
        refKabkota
          .filter((item) => item.kode === kode_kabkota)
          .map((item) => item.nama),
    },
  ];

  return (
    <>
      <BreadCrumb
        routes={itemBreadcrumb}
        title="Daftar Madrasah Penerima BKBA"
        back={true}
        toBack={() => route.goBack()}
      />
      <div className="m-5 p-5 bg-white shadow-md rounded">
        <div className="mb-4 flex justify-end">
          <InputSearch
            className="w-3/4"
            onChange={(e: any) => setSearch(e.currentTarget.value)}
          />
        </div>

        <div className="w-full">
          <Table
            // loading={loading}
            rowKey={(record) => record?.nsm}
            columns={columns}
            tableLayout="auto"
            dataSource={dataTable}
            bordered
            pagination={{
              total: totalDataTable,
              position: ["bottomRight"],
              defaultPageSize: pageSize,
              defaultCurrent: page,
              showTotal: (total) => `Total ${total} items`,
              onChange(page, pageSize) {
                setPage(page);
                setPageSize(pageSize);
              },
            }}
          />
        </div>
      </div>
    </>
  );
};
export default ListMadrasah;
