/** @format */

import React, { useState, useEffect } from "react";
import { BreadCrumb } from "../../../components";
import moment from "moment";
import "moment/locale/id";
import { Table, Space } from "antd";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import {
  formatRupiah,
  formatRupiahLPJ,
  listAlpha,
  uuidv4,
} from "../../../utils/helper";
import * as FileSaver from "file-saver";
import ExcelJS from "exceljs";
import { useDispatch, useSelector } from "react-redux";
import { ButtonDropdownExport } from "../../../components/Button";
import notifAlert from "../../../components/NotifAlert";
import { getMadrasah } from "../../../services/v2/usermanservice/madrasahservices";
import {
  getLaporanDaftarSumberDana,
  getLaporanPertanggungJawaban,
  postLaporanPertanggungJawaban,
} from "../../../services/v2/reportservice";
import { setStore } from "../../../redux/actions";
import FilterSumberDanaPeriodeLPJ from "../Component/FilterSumberDanaPeriodeLPJ";
import { getRealizationAll } from "../../../services/v2/realizationservice";

const LaporanPertanggungJawaban = () => {
  const itemBreadcrumb = [
    { path: "/", breadcrumbName: "Home" },
    { path: "/", breadcrumbName: "Laporan" },
    { path: "/", breadcrumbName: "Laporan Penatausahaan" },
    { path: "/", breadcrumbName: "Pertanggung Jawaban" },
  ];

  const auths = useSelector((state: any) => state.auth);
  const store = useSelector((state: any) => state.store);
  const refKabkota = store.kabkota || [];
  const profile = store?.madrasahProfile || null;
  const auth = auths?.data || null;
  let checkValidYear = moment().isAfter(moment(`${auths.isTahun}-12-31`));
  const groupRole = auth?.group_role || "";
  const [dataMadrasah, setDataMadrasah] = useState<any>(null);
  const [tableData, setTableData] = useState<any>([]);
  const [totalJumlahKas, setJumlahKas] = useState<any>(null);
  const [selisihKas, setSelisihKas] = useState<any>(null);
  const [tmpMadrasah, setTmpMadrasah] = useState<any>([]);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [tmpFilter, setTmpFilter] = useState<any>(null);
  const [sumberDana, setSumberDana] = useState<any>([]);
  const [listData, setListData] = useState<any>(null);
  const [pengembalianDana, setPengembalianDana] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const [loadingPost, setLoadingPost] = useState(false);
  const dispatch = useDispatch();
  const tmpListMadrasah = store?.listMadrasah || [];
  const [periodeTmp, setPeriodeTmp] = useState<any>(null);
  let dataTable = tableData.map((item) => {
    return { ...item, key: uuidv4() };
  });

  let totalDataTable = dataTable.length;

  /**
   * Filter Pagination
   */
  dataTable = dataTable.filter((v, i) => {
    let start = pageSize * (page - 1);
    let end = start + pageSize;

    return i >= start && i < end;
  });

  const handleSubmit = async (tmpFilter) => {
    try {
      // const date = new Date(tmpFilter?.periode);
      // const monthNumber = ("0" + (date.getMonth() + 1)).slice(-2);
      const payload: any = {
        tahun: auth?.isTahun,
        sumberDana: tmpFilter?.sumberDana,
        uangTunaiDiBrankas: Number(tmpFilter?.uangTunaiDiBrankas),
        uangDiRekening: Number(tmpFilter?.uangDiRekening),
        penjelasanSelisihKas: tmpFilter?.penjelasanSelisihKas,
        periode: moment(tmpFilter?.periode).format("MM"),
      };

      setLoadingPost(true);
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const resultPost = await postLaporanPertanggungJawaban(payload);
      // should be get post response, there are some error throw failed
      if (resultPost) {
        getData(tmpFilter);
        setLoadingPost(false);
        notifAlert({
          type: "success",
          description: "LPJ berhasil ditambah",
        });
      } else {
        setLoadingPost(false);
        throw new Error("Failed post");
      }
    } catch (error) {
      notifAlert({
        type: "error",
        description: "LPJ gagal ditambah",
      });
      console.log(error);
    }
  };

  const getData = async (tmpFilter) => {
    setLoading(true);
    setPeriodeTmp(tmpFilter?.periode?.format("MM"));
    if (groupRole !== "madrasah") {
      if (tmpListMadrasah?.length === 0) {
        let filter: any = "";
        if (groupRole === "provinsi") {
          filter = `?kodeProvinsi=${auth?.kode_provinsi}`;
        }
        if (groupRole === "kabkota") {
          filter = `?kodeProvinsi=${auth?.kode_provinsi}&kode_kabkota=${auth?.kode_kabkota}`;
        }
        const madrasah = await getMadrasah(filter);
        const listMadrasah = madrasah || [];
        setTimeout(() => {
          dispatch(setStore({ listMadrasah }));
        }, 100);
        setTmpMadrasah(madrasah);
      } else {
        setTmpMadrasah(tmpListMadrasah);
      }

      if (tmpFilter?.madrasahId) {
        let pengembalianTmp: any =
          (await getRealizationAll("pengembalian-dana/sisa-dana-bku", {
            tahun: auth?.isTahun,
            madrasahId: tmpFilter?.madrasahId,
          })) || null;
        setPengembalianDana(
          (pengembalianTmp?.grand_total_pengembalian !== 0 &&
            pengembalianTmp) ||
            null,
        );

        const resMadrasah = tmpListMadrasah.find((item) =>
          item.id.includes(tmpFilter?.madrasahId),
        );
        setDataMadrasah(resMadrasah);
        const payload = {
          tahun: auth?.isTahun,
          madrasahId: tmpFilter?.madrasahId,
        };

        // const findMadrasah =
        //   tmpMadrasah.find((item) => item.id.includes(tmpFilter?.madrasahId)) ||
        //   null;

        const tmpSumberDana = await getLaporanDaftarSumberDana(payload);

        setSumberDana(
          tmpSumberDana ||
            // .length
            // ? findMadrasah?.status === "n"
            //   ? tmpSumberDana.filter((item) => item.kode === "apbn_bos")
            //   : tmpSumberDana.filter(
            //       (item) =>
            //         item.kode === "apbn_bos_semester_1" ||
            //         item.kode === "apbn_bos_semester_2",
            //     )
            [],
        );
      }

      if (tmpFilter?.sumberDana && tmpFilter?.periode) {
        const payloadLpj: any = {
          tahun: auth?.isTahun,
          madrasahId: tmpFilter?.madrasahId,
          kodeSumberDana: tmpFilter?.sumberDana,
          // sumberDana: tmpFilter?.sumberDana,
          // uangTunaiDiBrankas: tmpFilter?.uangTunaiDiBrankas,
          // uangDiRekening: tmpFilter?.uangDiRekening,
          // penjelasanSelisihKas: tmpFilter?.penjelasanSelisihKas,
          periode: moment(tmpFilter?.periode).format("MM"),
        };
        const res = (await getLaporanPertanggungJawaban(payloadLpj)) || null;
        // setTableData((res && res.items) || []);
        setListData(res || null);
        setTableData(res?.items || []);
        setJumlahKas(res?.kasAkhirbulan || null);
        setSelisihKas(res?.selisihKas || null);
        // setTmpData(res);
      } else {
        setTableData([]);
        // setTmpData(null);
      }
    } else {
      setDataMadrasah(profile);
      let pengembalianTmp: any =
        (await getRealizationAll("pengembalian-dana/sisa-dana-bku", {
          tahun: auth?.isTahun,
          madrasahId: auth?.madrasah?.id,
        })) || null;
      setPengembalianDana(
        (pengembalianTmp?.grand_total_pengembalian !== 0 && pengembalianTmp) ||
          null,
      );

      const payload: any = {
        tahun: auth?.isTahun,
        kodeSumberDana: tmpFilter?.sumberDana,
        periode: moment(tmpFilter?.periode).format("MM"),
        madrasahId: profile?.id,
      };

      const tmpSumberDana = await getLaporanDaftarSumberDana(payload);

      setSumberDana(tmpSumberDana || []);

      try {
        const res = await getLaporanPertanggungJawaban(payload);
        setListData(res || null);
        setTableData(res?.items || []);
        setJumlahKas(res?.kasAkhirbulan || null);
        setSelisihKas(res?.selisihKas || null);
      } catch (error) {
        console.log(error);
      }
    }

    setLoading(false);
  };

  const handleChangeValues = (values) => {
    setTmpFilter(values);
    // getData(values);
  };

  useEffect(() => {
    if (tmpFilter !== null) {
      getData(tmpFilter);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth?.isTahun, tmpFilter]);

  /**
   * Data untuk tabel children pertama
   */
  let columns: any = [
    {
      title: "No",
      key: "no",
      width: 80,
      render: (_, record, i: number) => {
        let nn = i + (page - 1) * pageSize;
        return listAlpha(nn);
      },
    },
    {
      title: "Jenis Buku Pembantu",
      key: "nama",
      dataIndex: "nama",
      width: 250,
    },
    {
      title: "Saldo Awal",
      key: "saldoAwal",
      dataIndex: "saldoAwal",
      align: "right",
      width: 180,
      render: (saldoAwal) => formatRupiah(saldoAwal),
    },
    {
      title: "Penambahan",
      key: "totalPenambahan",
      dataIndex: "totalPenambahan",
      align: "right",
      width: 180,
      render: (totalPenambahan) => formatRupiah(totalPenambahan),
    },
    {
      title: "Pengurangan",
      key: "totalPengurangan",
      dataIndex: "totalPengurangan",
      align: "right",
      width: 180,
      render: (totalPengurangan) => formatRupiah(totalPengurangan),
    },
    {
      title: "Saldo Akhir",
      key: "saldoAkhir",
      dataIndex: "saldoAkhir",
      align: "right",
      width: 180,
      render: (saldoAkhir) => formatRupiah(saldoAkhir),
    },
  ];

  const expandedRowDetail = (record: any) => {
    let dataTableSub: any = record.items.length ? record.items : [];
    const columnsDetail: any = [
      {
        title: "No Urut",
        key: "no",
        width: 80,
        render: (_, record, i: number) => i + 1 + (page - 1) * pageSize,
      },
      {
        title: "Nama",
        key: "nama",
        dataIndex: "nama",
        //render: (nama, record, index) => `${index + 1}. ${nama}`,
        width: 250,
      },
      {
        title: "Saldo Awal",
        key: "saldoAwal",
        dataIndex: "saldoAwal",
        align: "right",
        width: 180,
        render: (saldoAwal) => formatRupiah(saldoAwal),
      },
      {
        title: "Penambahan",
        key: "penambahan",
        dataIndex: "penambahan",
        align: "right",
        width: 180,
        render: (penambahan) => formatRupiah(penambahan),
      },
      {
        title: "Pengurangan",
        key: "pengurangan",
        dataIndex: "pengurangan",
        align: "right",
        width: 180,
        render: (pengurangan) => formatRupiah(pengurangan),
      },
      {
        title: "Saldo Akhir",
        key: "saldoAkhir",
        dataIndex: "saldoAkhir",
        align: "right",
        width: 180,
        render: (saldoAkhir) => formatRupiah(saldoAkhir),
      },
    ];
    return (
      <Table
        rowKey={(record) => uuidv4()}
        columns={columnsDetail}
        dataSource={dataTableSub}
        pagination={false}
        bordered
        showHeader={false}
        style={{ marginLeft: "8px" }}
      />
    );
  };

  const handleExportFile = (type) => {
    if (dataTable.length > 0) {
      if (type === "pdf") {
        handleExportPdf();
      } else if (type === "excel") {
        handleExportExcel();
      }
    } else {
      notifAlert({
        type: "warning",
        description:
          "Harap pilih data yang ingin di export melalui fungsi filter!",
      });
    }
  };

  function handleExportPdf() {
    const doc: any = new jsPDF({
      putOnlyUsedFonts: true,
      orientation: "landscape",
      floatPrecision: 16,
    });
    let tableTmp: any = [];
    let tmpNamaSumberDana: any =
      sumberDana.find((item) => item.kode === listData?.kodeSumberDana) || null;
    tableData.length &&
      // eslint-disable-next-line array-callback-return
      tableData.map((item, i) => {
        let dataTmp = {
          id: listAlpha(i),
          nama: item.nama,
          saldoAwal: !isNaN(item.saldoAwal) ? formatRupiah(item.saldoAwal) : 0,
          penambahan: !isNaN(item.totalPenambahan)
            ? formatRupiah(item.totalPenambahan)
            : 0,
          pengurangan: !isNaN(item.totalPengurangan)
            ? formatRupiah(item.totalPengurangan)
            : 0,
          saldoAkhir: formatRupiah(item.saldoAkhir),
        };
        tableTmp.push(dataTmp);
        item.items.length &&
          // eslint-disable-next-line array-callback-return
          item.items.map((e, x) => {
            let itemTmp = {
              id: "",
              nama: `${x + 1}. ${e.nama}`,
              saldoAwal: formatRupiah(e.saldoAwal),
              penambahan: formatRupiah(e.penambahan),
              pengurangan: formatRupiah(e.pengurangan),
              saldoAkhir: formatRupiah(e.saldoAkhir),
            };
            tableTmp.push(itemTmp);
          });
      });
    let namaKabkota: any =
      dataMadrasah && dataMadrasah?.kode_kabkota
        ? refKabkota.find((e) => e.kode === dataMadrasah?.kode_kabkota)
        : null;

    // let namaProvinsi: any =
    //   dataMadrasah && dataMadrasah?.kode_provinsi
    //     ? refProvinsi.find((e) => e.kode === dataMadrasah?.kode_provinsi)
    //     : null;
    var fline = 10;
    doc.setFont("times", "bold");
    doc.setFontSize(12);
    doc.rect(10, 6, 275, 22);
    doc.rect(60, 6, 177, 22);

    doc.text(
      `LAPORAN PERTANGGUNGJAWABAN BENDAHARA MADRASAH ${
        dataMadrasah?.status === "n" ? "NEGERI" : "SWASTA"
      }`,
      148,
      12,
      {
        align: "center",
      },
    );
    doc.setFontSize(12);
    doc.text("Form LPJ Pengeluaran", 35, 12, {
      align: "center",
      maxWidth: 30,
    });
    doc.text(
      `Tahun Anggaran ${moment(tmpFilter?.periode).format("YYYY")}`,
      260,
      12,
      {
        align: "center",
        maxWidth: 40,
      },
    );

    doc.text(
      "Bulan : " +
        moment(tmpFilter?.periode).format("MMMM") +
        " " +
        moment(tmpFilter?.periode).format("YYYY"),
      150,
      18,
      { align: "center" },
    );
    doc.text("Nama Madrasah : " + listData?.satuanKerja, 150, 24, {
      align: "center",
    });
    doc.setFont("times", "normal");
    doc.setFontSize(10);
    doc.text("Nama Madrasah", fline, 32);
    doc.text(": " + (listData?.satuanKerja || "-"), fline + 40, 32);
    doc.text("NSM", fline, 37);
    doc.text(": " + (listData?.nsm || "-"), fline + 40, 37);
    doc.text("Alamat dan Telp", fline, 42);
    doc.text(`: ${listData?.alamatDanNoTelp}`, fline + 40, 42);
    doc.text("Tahun Anggaran", fline, 47);
    doc.text(": " + (listData?.tahun || "-"), fline + 40, 47);
    doc.text("Sumber Dana", fline, 52);
    doc.text(": " + (tmpNamaSumberDana?.nama || "-"), fline + 40, 52);

    let lastHeader = 52;
    doc.text("I.", fline, lastHeader + 5);
    doc.text(
      "Keadaan Pembukuan bulan Pelaporan dengan saldo akhir BKU sebesar dan bukti terakhir Nomor:",
      fline + 5,
      lastHeader + 5,
    );
    var headers: any = [
      { id: "id", dataKey: "id", padding: 0, header: "No", width: 22 },
      {
        id: "nama",
        dataKey: "nama",
        padding: 0,
        header: "Jenis Buku Pembantu",
        width: 80,
      },
      {
        id: "saldoAwal",
        dataKey: "saldoAwal",
        padding: 0,
        header: "Saldo Awal",
        width: 40,
      },
      {
        id: "penambahan",
        dataKey: "penambahan",
        padding: 0,
        header: "Penambahan",
        width: 40,
      },
      {
        id: "pengurangan",
        dataKey: "pengurangan",
        padding: 0,
        header: "Pengurangan",
        width: 40,
      },
      {
        id: "saldoAkhir",
        dataKey: "saldoAkhir",
        padding: 0,
        header: "Saldo Akhir",
        width: 39,
      },
    ];
    let las: any = [];
    autoTable(doc, {
      styles: { lineColor: 244, lineWidth: 0.1, fontSize: 9 },
      headStyles: {
        halign: "center",
        valign: "middle",
        fillColor: [0, 128, 0],
      }, // Cells in first column centered and green
      columnStyles: {
        0: { halign: "center", cellWidth: 15, fontSize: 9 },
        1: { halign: "left", cellWidth: 95, fontSize: 9 },
        2: { halign: "right", cellWidth: 40, fontSize: 9 },
        3: { halign: "right", cellWidth: 40, fontSize: 9 },
        4: { halign: "right", cellWidth: 40, fontSize: 9 },
        5: { halign: "right", cellWidth: 40, fontSize: 9 },
      },
      startY: lastHeader + 7,
      margin: { top: 7, left: 15, right: 10 },
      columns: headers,
      body: tableTmp,
      didDrawPage: (d) => las.push(d.cursor),
    });
    let lastLine: any = 4 + (las.length !== 0 ? las[0].y : 0);
    const footerText = [
      {
        text: "II.",
        line: fline,
        space: 0,
        bottom: 0,
      },
      {
        text: "Keadaan Kas pada akhir Bulan",
        line: fline,
        space: 5,
        bottom: 0,
      },

      {
        text: "1. Uang Tunai di Brankas",
        line: fline,
        space: 5,
        bottom: 5,
      },
      {
        text: `${formatRupiah(totalJumlahKas?.saldoKasTunai) || "-"}`,
        line: fline,
        space: 200,
        bottom: 0,
      },
      {
        text: "2. Uang di rekening bank (terlampir Daftar Rincin Kas di Rekening)",
        line: fline,
        space: 5,
        bottom: 5,
      },
      {
        text: `${formatRupiah(totalJumlahKas?.saldoRekeningBank) || "-"}`,
        line: fline,
        space: 200,
        bottom: 0,
      },
      {
        text: "3. Jumlah Kas",
        line: fline,
        space: 5,
        bottom: 5,
      },
      {
        text: `${formatRupiah(totalJumlahKas?.jumlahKas) || "-"}`,
        line: fline,
        space: 200,
        bottom: 0,
      },
      {
        text: "III.",
        line: fline,
        space: 0,
        bottom: 5,
      },
      {
        text: "Selisih Kas (Saldo Akhir A atau B - 3) :",
        line: fline,
        space: 5,
        bottom: 0,
      },
      {
        text: `${formatRupiah(selisihKas?.selisih) || "-"}`,
        line: fline,
        space: 200,
        bottom: 0,
      },
      {
        text: "IV.",
        line: fline,
        space: 0,
        bottom: 5,
      },
      {
        text: "Penjelasan Selisih Kas dan/atau selisih pembukuan (apabila ada) :",
        line: fline,
        space: 5,
        bottom: 0,
      },
      {
        text: `${listData?.penjelasan || "-"}`,
        line: fline,
        space: 5,
        bottom: 5,
      },
      {
        text:
          periodeTmp?.toString() === "12" && checkValidYear && pengembalianDana
            ? informationPengembalian(pengembalianDana)
            : "",
        line: fline,
        space: 0,
        bottom: 5,
      },

      {
        text: "Mengetahui",
        line: fline,
        space: 0,
        bottom: 5,
      },
      {
        text: `${namaKabkota?.nama || ""}, ${moment(tmpFilter?.periode)
          .endOf("month")
          .format("DD MMMM YYYY")}`,
        line: fline,
        space: 210,
        bottom: 0,
      },
      {
        text: "Kepala Madrasah",
        line: fline,
        space: 0,
        bottom: 5,
      },
      {
        text: "Bendahara Madrasah",
        line: fline,
        space: 210,
        bottom: 0,
      },
      {
        text:
          (dataMadrasah?.nama_kepala && `( ${dataMadrasah?.nama_kepala} )`) ||
          "(.................)",
        line: fline,
        space: 0,
        bottom: 15,
      },
      {
        text:
          (dataMadrasah?.nama_bendahara &&
            `( ${dataMadrasah?.nama_bendahara} )`) ||
          "(.................)",
        line: fline,
        space: 210,
        bottom: 0,
      },
    ];
    let tmpBottom = lastLine;
    // eslint-disable-next-line array-callback-return
    footerText.map((item) => {
      tmpBottom += item.bottom;
      if (tmpBottom > 195) {
        doc.addPage();
        tmpBottom = 15;
      }
      doc.text(item.text, fline + item.space, tmpBottom);
    });

    doc.output("dataurlnewwindow", { filename: "laporan-pertanggung-jawaban" });
    notifAlert({
      type: "success",
      description: "Data berhasil di export",
    });
  }

  const handleExportExcel = async () => {
    var ExcelJSWorkbook = new ExcelJS.Workbook();
    var worksheet = ExcelJSWorkbook.addWorksheet("Data");
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    let namaKabkota: any =
      dataMadrasah && dataMadrasah?.kode_kabkota
        ? refKabkota.find((e) => e.kode === dataMadrasah?.kode_kabkota)
        : null;
    let tmpNamaSumberDana: any =
      sumberDana.find((item) => item.kode === listData?.kodeSumberDana) || null;
    worksheet.mergeCells("A1:A3");
    const headerCell1 = worksheet.getCell("A1");
    headerCell1.font = {
      size: 11,
      underline: true,
      bold: true,
    };

    headerCell1.border = {
      top: { style: "double", color: { argb: "00000000" } },
      left: { style: "double", color: { argb: "00000000" } },
      bottom: { style: "double", color: { argb: "00000000" } },
      right: { style: "double", color: { argb: "00000000" } },
    };
    headerCell1.alignment = {
      vertical: "middle",
      horizontal: "center",
      wrapText: true,
    };
    headerCell1.value = "Form LPJ Pengeluaran";

    const headerColA = worksheet.getColumn("A");
    headerColA.width = 20;
    const headerColB = worksheet.getColumn("B");
    headerColB.width = 4;
    const headerColC = worksheet.getColumn("C");
    headerColC.width = 25;
    const headerColD = worksheet.getColumn("D");
    headerColD.width = 16;
    const headerColE = worksheet.getColumn("E");
    headerColE.width = 16;
    const headerColF = worksheet.getColumn("F");
    headerColF.width = 16;
    const headerColG = worksheet.getColumn("G");
    headerColG.width = 16;
    const headerColH = worksheet.getColumn("H");
    headerColH.width = 20;

    worksheet.mergeCells("B1:G1");
    const headerCell2 = worksheet.getCell("B1");
    headerCell2.font = {
      size: 11,
      underline: true,
      bold: true,
    };
    headerCell2.border = {
      top: { style: "double", color: { argb: "00000000" } },
      left: { style: "double", color: { argb: "00000000" } },
      // bottom: { style: "double", color: { argb: "00000000" } },
      right: { style: "double", color: { argb: "00000000" } },
    };
    headerCell2.alignment = { vertical: "middle", horizontal: "center" };
    headerCell2.value = `LAPORAN PERTANGGUNGJAWABAN BENDAHARA MADRASAH ${
      dataMadrasah?.status === "n" ? "NEGERI" : "SWASTA"
    }`;

    worksheet.mergeCells("B2:G2");
    const headerCell3 = worksheet.getCell("B2");
    headerCell3.font = {
      size: 11,
      underline: true,
      bold: true,
    };
    headerCell3.border = {
      // top: { style: "double", color: { argb: "00000000" } },
      left: { style: "double", color: { argb: "00000000" } },
      // bottom: { style: "double", color: { argb: "00000000" } },
      right: { style: "double", color: { argb: "00000000" } },
    };
    headerCell3.alignment = { vertical: "middle", horizontal: "center" };
    headerCell3.value = `BULAN : ${moment(tmpFilter?.periode).format(
      "MMMM",
    )} ${moment(tmpFilter?.periode).format("YYYY")}`;

    worksheet.mergeCells("B3:G3");
    const headerCell23 = worksheet.getCell("B3");
    headerCell23.font = {
      size: 11,
      underline: true,
      bold: true,
    };
    headerCell23.border = {
      // top: { style: "double", color: { argb: "00000000" } },
      left: { style: "double", color: { argb: "00000000" } },
      bottom: { style: "double", color: { argb: "00000000" } },
      right: { style: "double", color: { argb: "00000000" } },
    };
    headerCell23.alignment = { vertical: "middle", horizontal: "center" };
    headerCell23.value = `Nama Madrasah : ${dataMadrasah?.nama || "-"}`;

    worksheet.mergeCells("H1:H3");
    const headerCell4 = worksheet.getCell("H1");
    headerCell4.font = {
      size: 11,
      underline: true,
      bold: true,
    };
    headerCell4.border = {
      top: { style: "double", color: { argb: "00000000" } },
      left: { style: "double", color: { argb: "00000000" } },
      bottom: { style: "double", color: { argb: "00000000" } },
      right: { style: "double", color: { argb: "00000000" } },
    };
    headerCell4.alignment = { vertical: "middle", horizontal: "center" };
    headerCell4.value = `Tahun Anggaran ${moment(tmpFilter?.periode).format(
      "YYYY",
    )}`;

    worksheet.getCell("A7").value = "Nama Madrasah";
    worksheet.getCell("C7").value = `: ${listData?.satuanKerja || "-"}`;
    worksheet.getCell("A8").value = "NSM";
    worksheet.getCell("C8").value = `: ${listData?.nsm || "-"}`;
    worksheet.getCell("A9").value = "Alamat dan Telp.";
    worksheet.getCell("C9").value = `: ${listData?.alamatDanNoTelp || "-"}`;
    worksheet.getCell("A10").value = "Tahun Anggaran";
    worksheet.getCell("C10").value = `: ${listData?.tahun || "-"}`;
    worksheet.getCell("A11").value = "Sumber Dana";
    worksheet.getCell("C11").value = `: ${tmpNamaSumberDana?.nama || "-"}`;

    worksheet.mergeCells("A13:K13");
    worksheet.getCell("A13").value =
      "I. Keadaan Pembukuan bulan Pelaporan dengan saldo akhir BKU sebesar dan bukti terakhir Nomor:";

    let tableTmp: any = [];

    tableData.length &&
      // eslint-disable-next-line array-callback-return
      tableData.map((item, i) => {
        let dataTmp = [
          listAlpha(i),
          item.nama,
          !isNaN(item.saldoAwal) ? formatRupiah(item.saldoAwal) : 0,
          !isNaN(item.totalPenambahan) ? formatRupiah(item.totalPenambahan) : 0,
          !isNaN(item.totalPengurangan)
            ? formatRupiah(item.totalPengurangan)
            : 0,
          formatRupiah(item.saldoAkhir),
        ];
        tableTmp.push(dataTmp);
        item.items.length &&
          // eslint-disable-next-line array-callback-return
          item.items.map((e, x) => {
            let itemTmp = [
              "",
              `${x + 1}. ${e.nama}`,
              formatRupiah(e.saldoAwal),
              formatRupiah(e.penambahan),
              formatRupiah(e.pengurangan),
              formatRupiah(e.saldoAkhir),
            ];
            tableTmp.push(itemTmp);
          });
      });
    worksheet.addTable({
      name: "Table",
      ref: "B14",
      headerRow: true,
      style: {
        showRowStripes: true,
      },
      columns: [
        { name: "No" },
        { name: "Jenis Buku Pembantu" },
        { name: "Saldo Awal" },
        { name: "Penambahan" },
        { name: "Pengurangan" },
        { name: "Saldo Akhir" },
      ],
      rows: tableTmp,
    });

    let lastLine = 22 + tableTmp.length;
    worksheet.mergeCells(`A${lastLine}:K${lastLine}`);
    worksheet.getCell(`A${lastLine}`).value =
      "II. Keadaan Kas pada akhir Bulan";
    worksheet.mergeCells(`B${lastLine + 1}:K${lastLine + 1}`);
    worksheet.getCell(`B${lastLine + 1}`).value = `1. Uang Tunai di Brankas ${
      formatRupiah(totalJumlahKas?.saldoKasTunai) || "-"
    }`;
    worksheet.mergeCells(`B${lastLine + 2}:K${lastLine + 2}`);
    worksheet.getCell(
      `B${lastLine + 2}`,
    ).value = `2. Uang di rekening bank (terlampir Daftar Rincin Kas di Rekening) ${
      formatRupiah(totalJumlahKas?.saldoRekeningBank) || "-"
    }`;
    worksheet.mergeCells(`B${lastLine + 3}:K${lastLine + 3}`);
    worksheet.getCell(`B${lastLine + 3}`).value = `3. Jumlah Kas ${
      formatRupiah(totalJumlahKas?.jumlahKas) || "-"
    }`;
    worksheet.mergeCells(`A${lastLine + 5}:K${lastLine + 5}`);
    worksheet.getCell(
      `A${lastLine + 5}`,
    ).value = `III. Selisih Kas (Saldo Akhir A atau B - 3) : ${
      formatRupiah(selisihKas?.selisih) || "-"
    }`;
    worksheet.mergeCells(`A${lastLine + 7}:K${lastLine + 7}`);
    worksheet.getCell(
      `A${lastLine + 7}`,
    ).value = `IV. Penjelasan Selisih Kas dan/atau selisih pembukuan (apabila ada) : ${
      listData?.penjelasan || "-"
    }`;
    if (periodeTmp?.toString() === "12" && checkValidYear && pengembalianDana) {
      let keteranganPengembalianDana: any =
        pengembalianDana?.detail.length &&
        pengembalianDana?.detail.find((item, i) => i === 0);
      worksheet.mergeCells(`A${lastLine + 8}:K${lastLine + 8}`);
      worksheet.getCell(
        `A${lastLine + 8}`,
      ).value = `Sisa Dana Akhir Tahun sebesar ${formatRupiah(
        pengembalianDana?.grand_total_pengembalian,
      )},  ${keteranganPengembalianDana?.keterangan}, bukti setor terlampir`;
    }
    worksheet.getCell(`A${lastLine + 10}`).value = "Mengetahui";
    worksheet.getCell(`H${lastLine + 10}`).value = `${
      namaKabkota?.nama || ""
    }, ${moment(tmpFilter?.periode).endOf("month").format("DD MMMM YYYY")}`;
    worksheet.getCell(`A${lastLine + 12}`).value = "Kepala Madrasah";
    worksheet.getCell(`H${lastLine + 12}`).value = "Bendahara Madrasah";
    worksheet.getCell(`A${lastLine + 15}`).value =
      (dataMadrasah?.nama_kepala && `( ${dataMadrasah?.nama_kepala} )`) ||
      "(.................)";
    worksheet.getCell(`H${lastLine + 15}`).value =
      (dataMadrasah?.nama_bendahara && `( ${dataMadrasah?.nama_bendahara} )`) ||
      "(.................)";
    const excelBuffer = await ExcelJSWorkbook.xlsx.writeBuffer();
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, "laporan-pertanggung-jawaban" + fileExtension);

    notifAlert({
      type: "success",
      description: "Data berhasil di export",
    });
  };

  const informationPengembalian = (pengembalianDana) => {
    let keteranganPengembalianDana: any =
      pengembalianDana?.detail.length &&
      pengembalianDana?.detail.find((item, i) => i === 0);

    return `Sisa Dana Akhir Tahun sebesar ${formatRupiah(
      pengembalianDana?.grand_total_pengembalian,
    )},  ${keteranganPengembalianDana?.keterangan}, bukti setor terlampir`;
  };

  return (
    <>
      <BreadCrumb routes={itemBreadcrumb} title="Pertanggung Jawaban" />
      <FilterSumberDanaPeriodeLPJ
        handleChange={handleChangeValues}
        handleSubmit={handleSubmit}
        groupRole={groupRole}
        madrasah={tmpMadrasah}
        realisasiSumberDana={sumberDana}
        loading={loadingPost}
      />

      <div className="m-5 p-5 bg-white shadow-md rounded">
        <div className="mb-4 flex justify-end">
          <div className="mx-4 mt-4">
            <Space size={[8, 8]} wrap>
              <ButtonDropdownExport handleExportFile={handleExportFile} />
            </Space>
          </div>
        </div>
        <div className="grid grid-cols-12 md:items-center gap-4">
          <div className="col-span-3">Kementerian/Lembaga</div>
          <div className="col-span-9">: {listData?.lembaga || "-"}</div>
          <div className="col-span-3">Unit Organisasi</div>
          <div className="col-span-9">: {listData?.unitOrganisasi || "-"}</div>
          <div className="col-span-3">Provinsi/Kab/Kota</div>
          <div className="col-span-9">: {listData?.provinsiKabKota || "-"}</div>
          <div className="col-span-3">Satuan Kerja</div>
          <div className="col-span-9">: {listData?.satuanKerja || "-"}</div>
          <div className="col-span-3">Alamat dan Telp</div>
          <div className="col-span-9">: {listData?.Alamat || "-"}</div>
          <div className="col-span-3">No. Krws & Kewenangan</div>
          <div className="col-span-9">: {listData?.krws || "-"}</div>
          <div className="col-span-3">Dokumen</div>
          <div className="col-span-9">: {listData?.dokumen || "-"}</div>
          <div className="col-span-3">Nomor Dokumen</div>
          <div className="col-span-9">: {listData?.nomorDokumen || "-"}</div>
          <div className="col-span-3">Tanggal Dokumen</div>
          <div className="col-span-9">: {listData?.tanggalDokumen || "-"}</div>
          <div className="col-span-3">Tahun Anggaran</div>
          <div className="col-span-9">: {listData?.tahun || "-"}</div>
          <div className="col-span-3">KPPN</div>
          <div className="col-span-9">: {listData?.kppn || "-"}</div>
        </div>
      </div>
      <div className="m-5 p-5 bg-white shadow-md rounded">
        <div className="w-full">
          <Table
            sticky
            columns={columns}
            dataSource={dataTable}
            scroll={{ x: 1300 }}
            bordered
            loading={loading}
            expandable={{
              expandedRowRender: (record) => expandedRowDetail(record),
            }}
            pagination={{
              total: totalDataTable,
              position: ["bottomRight"],
              defaultPageSize: pageSize,
              defaultCurrent: page,
              showTotal: (total) => `Total ${total} items`,
              onChange(page, pageSize) {
                setPage(page);
                setPageSize(pageSize);
              },
            }}
          />
        </div>
      </div>
      <div className="m-5 p-5 bg-white shadow-md rounded mb-12">
        <div className="font-bold mb-5">Keadaan Kas pada akhir Bulan</div>
        <div className="flex md:items-center mb-2 gap-4">
          <div className="flex-1">1. Uang Tunai di Brankas</div>
          <div className="flex-1">
            : {formatRupiahLPJ(totalJumlahKas?.saldoKasTunai)}
          </div>
        </div>
        <div className="flex md:items-center mb-2 gap-4">
          <div className="flex-1">2. Uang di rekening bank</div>
          <div className="flex-1">
            : {formatRupiahLPJ(totalJumlahKas?.saldoRekeningBank)}
          </div>
        </div>
        <div className="flex md:items-center mb-2 gap-4">
          <div className="flex-1">3. Jumlah Kas</div>
          <div className="flex-1">
            : {formatRupiahLPJ(totalJumlahKas?.jumlahKas)}
          </div>
        </div>
        <div className="flex md:items-center mb-2 gap-4">
          <div className="flex-1 font-bold">
            Selisih Kas (Saldo Akhir A atau B - 3)
          </div>
          <div className="flex-1">: {formatRupiahLPJ(selisihKas?.selisih)}</div>
        </div>
        <div className="flex md:items-center mb-2 gap-4">
          <div className="flex-1 font-bold">
            Penjelasan Selisih Kas dan/atau selisih pembukuan (apabila ada)
          </div>
          <div className="flex-1">
            : {listData?.penjelasan || "Tidak ada selisih kas"}
          </div>
        </div>
        {periodeTmp?.toString() === "12" &&
          checkValidYear &&
          pengembalianDana &&
          informationPengembalian(pengembalianDana)}
      </div>
    </>
  );
};

export default LaporanPertanggungJawaban;
