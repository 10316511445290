/**
 * eslint-disable @typescript-eslint/no-unused-vars
 *
 * @format
 */

/** @format */

import React, { useState } from "react";
import { Form, Select, DatePicker, Button, InputNumber } from "antd";
import { useSelector } from "react-redux";
import TextArea from "antd/lib/input/TextArea";

type filterProps = {
  handleChange: (values: any) => void;
  handleSubmit: (values: any) => void;
  groupRole: any;
  madrasah: any;
  realisasiSumberDana: any;
  loading: boolean;
};

const FilterSumberDanaPeriodeLPJ = ({
  // eslint-disable-next-line no-useless-rename
  handleChange: handleChange,
  // eslint-disable-next-line no-useless-rename
  handleSubmit: handleSubmit,
  groupRole,
  madrasah,
  realisasiSumberDana,
  loading,
}: filterProps) => {
  const [form] = Form.useForm();
  const store = useSelector((state: any) => state.store);
  const auth = store?.profile || null;
  const initialValue = {
    provinsi: auth?.group_role === "pusat" ? null : auth?.kode_provinsi,
    kabkota:
      auth?.group_role === "pusat" || auth?.group_role === "provinsi"
        ? null
        : auth?.kode_kabkota,
  };
  const [provinsi, setProvinsi] = useState<any>(
    auth?.group_role === "pusat" ? null : auth?.kode_provinsi,
  );
  const [kabkota, setKabkota] = useState<any>(
    auth?.group_role === "pusat" || auth?.group_role === "provinsi"
      ? null
      : auth?.kode_kabkota,
  );
  const refProvinsi = store.provinsi || [];
  const refKabkota = store.kabkota || [];

  const handleValueChange = (val) => {
    for (let obj in val) {
      if (obj === "provinsi") {
        setProvinsi(val.provinsi);
        form.setFieldsValue({ kabkota: null, madrasahId: null });
      }
      if (obj === "kabkota") {
        setKabkota(val.kabkota);
        form.setFieldsValue({ madrasahId: null });
      }
    }
    const values = form.getFieldsValue();
    handleChange(values);
  };

  const handleSave = () => {
    const val = form.getFieldsValue();
    let tmpFilter: any = {
      madrasahId: null,
      periode: null,
      sumberDana: null,
      uangTunaiDiBrankas: null,
      uangDiRekening: null,
      penjelasanSelisihKas: null,
    };
    for (let obj in val) {
      switch (obj) {
        case "madrasahId":
          tmpFilter = { ...tmpFilter, madrasahId: val.madrasahId || null };
          break;
        case "periode":
          tmpFilter = { ...tmpFilter, periode: val.periode || null };
          break;
        case "sumberDana":
          tmpFilter = { ...tmpFilter, sumberDana: val.sumberDana || null };
          break;
        case "uangTunaiDiBrankas":
          tmpFilter = {
            ...tmpFilter,
            uangTunaiDiBrankas: val.uangTunaiDiBrankas || null,
          };
          break;
        case "uangDiRekening":
          tmpFilter = {
            ...tmpFilter,
            uangDiRekening: val.uangDiRekening || null,
          };
          break;
        case "penjelasanSelisihKas":
          tmpFilter = {
            ...tmpFilter,
            penjelasanSelisihKas: val.penjelasanSelisihKas || null,
          };
          break;
      }
    }
    handleSubmit(tmpFilter);
    form.setFieldsValue({
      periode: val.periode,
      sumberDana: val.sumberDana,
      uangTunaiDiBrankas: null,
      uangDiRekening: null,
      penjelasanSelisihKas: null,
    });
  };

  let refMadrasah =
    (auth?.list_madrasah_id_pengawas?.length &&
      madrasah.filter((item) =>
        auth?.list_madrasah_id_pengawas.some((items) =>
          items.id.includes(item.id),
        ),
      )) ||
    madrasah;

  if (auth?.kode_role === "bkba_pengawas") {
    realisasiSumberDana = realisasiSumberDana.filter(
      (item) => item.kode === "ba_rehab_berat",
    );
  }
  return (
    <div className="m-5 p-5 bg-white shadow-md rounded">
      <Form
        form={form}
        key="formFilterPeriode"
        name="formFilterPeriode"
        initialValues={initialValue}
        labelAlign="left"
        labelCol={{ span: 6 }}
        onValuesChange={handleValueChange}
        onFinish={handleSave}>
        <div className="grid grid-cols-1  md:items-center  md:grid-cols-2 gap-4 ">
          {groupRole !== "madrasah" && (
            <>
              <div>
                <Form.Item label="Provinsi" name="provinsi" required>
                  <Select
                    placeholder="Pilih Provinsi"
                    showSearch
                    allowClear
                    disabled={auth?.group_role === "pusat" ? false : true}
                    optionFilterProp="children">
                    {refProvinsi.length &&
                      refProvinsi.map((item) => (
                        <Select.Option
                          key={`filProv${item.kode}`}
                          value={item.kode}>
                          {item.nama}
                        </Select.Option>
                      ))}
                  </Select>
                </Form.Item>
              </div>
              <div>
                <Form.Item label="Kab / Kota" name="kabkota" required>
                  <Select
                    placeholder="Pilih Kab / Kota"
                    showSearch
                    allowClear
                    disabled={
                      auth?.group_role === "pusat" ||
                      auth?.group_role === "provinsi"
                        ? false
                        : true
                    }
                    optionFilterProp="children">
                    {refKabkota.length &&
                      provinsi &&
                      refKabkota
                        .filter((item) => item.kode_provinsi === provinsi)
                        .map((item) => (
                          <Select.Option
                            key={`filKab${item.kode}`}
                            value={item.kode}>
                            {item.nama}
                          </Select.Option>
                        ))}
                  </Select>
                </Form.Item>
              </div>
              <div>
                <Form.Item label="Madrasah" name="madrasahId" required>
                  <Select
                    placeholder="Pilih Madrasah"
                    showSearch
                    allowClear
                    optionFilterProp="children">
                    {refMadrasah?.length &&
                      provinsi &&
                      kabkota &&
                      refMadrasah
                        .filter(
                          (item) =>
                            item.kode_provinsi === provinsi &&
                            item.kode_kabkota === kabkota,
                        )
                        .map((item) => (
                          <Select.Option
                            key={`filMad${item.id}`}
                            value={item.id}>
                            {item.nama} | {item.nsm}
                          </Select.Option>
                        ))}
                  </Select>
                </Form.Item>
              </div>
            </>
          )}
          <div>
            <Form.Item label="Periode" name="periode" required>
              <DatePicker picker="month" />
            </Form.Item>
          </div>
          <div>
            <Form.Item label="Sumber Dana" name="sumberDana" required>
              <Select
                placeholder="Pilih Sumber Dana"
                showSearch
                allowClear
                optionFilterProp="children">
                {realisasiSumberDana.length &&
                  realisasiSumberDana.map((item) => (
                    <Select.Option key={`${item.kode}sumbKo`} value={item.kode}>
                      {item.nama}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
          </div>
          <div
            style={{
              display: auth?.group_role === "madrasah" ? "block" : "none",
            }}>
            <Form.Item
              label="Uang Tunai di Brankas"
              name="uangTunaiDiBrankas"
              required
              labelCol={{ span: 8 }}>
              <InputNumber
                style={{ width: "100%" }}
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                prefix="Rp."
                placeholder="Masukkan Nominal"
              />
            </Form.Item>
          </div>
          <br />
          <div
            style={{
              display: auth?.group_role === "madrasah" ? "block" : "none",
            }}>
            <Form.Item
              labelCol={{ span: 8 }}
              label="Uang di Rekening Bank"
              name="uangDiRekening"
              required>
              <InputNumber
                style={{ width: "100%" }}
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                prefix="Rp."
                placeholder="Masukkan Nominal"
              />
            </Form.Item>
          </div>
          <br />
          <div
            style={{
              width: "1200px",
              display: auth?.group_role === "madrasah" ? "block" : "none",
            }}>
            <Form.Item
              label="Penjelasan Selisih Kas dan/atau selisih pembukuan (apabila ada) :"
              name="penjelasanSelisihKas"
              labelCol={{ span: 10 }}
              required>
              <TextArea
                rows={3}
                placeholder="Masukkan Penjelasan Selisih Kas"
              />
            </Form.Item>
          </div>
        </div>
        <div style={{ display: "flex", justifyContent: "end" }}>
          <Button
            key="submit"
            type="primary"
            onClick={() => form.submit()}
            hidden={!(auth?.group_role === "madrasah")}
            loading={loading}>
            Simpan
          </Button>
        </div>
      </Form>
    </div>
  );
};
export default FilterSumberDanaPeriodeLPJ;
