/** @format */

import api from "../../../utils/api";
import { notificationService } from "../constant";

const basePath = `${notificationService}`;

export const getPengumuman = async (param?: any) => {
  const response = await api.get(`${basePath}/announcement`, {
    params: param,
  });
  return response.data.return;
};

export const getComments = async (param?: any) => {
  try {
    const response = await api.get(`${basePath}/comments`, {
      params: param,
    });
    return response.data.return;
  } catch (error) {
    console.log(error);
  }
};

export const deleteComment = async (params: any) => {
  const response = await api.delete(`${basePath}/comment/${params}`);
  return response.data.return;
};

export const postComment = async (params) => {
  const response = await api.post(`${basePath}/comment`, params, {
    headers: { "Content-Type": "application/json" },
  });
  return response.data.return;
};

export const editComment = async (params: any) => {
  let uuid = params.id;
  const response = await api.put(`${basePath}/comment/${uuid}`, params);
  return response.data.return;
};

export const changeStatusComment = async (param: any) => {
  let uuid = param.id;
  const response = await api.put(
    `${basePath}/comment/update-status/${uuid}?notificationStatus=READ`,
  );
  return response.data.return;
};

export const postCommentDeleteAll = async (params) => {
  const response = await api.post(`${basePath}/comment-on-delete-all`, params, {
    headers: { "Content-Type": "application/json" },
  });
  return response.data.return;
};

export const getCommentDeleteAll = async (param?: any) => {
  try {
    const response = await api.get(`${basePath}/comment-on-delete-all`, {
      params: param,
    });
    return response.data.return;
  } catch (error) {
    console.log(error);
  }
};

export const getMenuPage = () => {
  const response: any = [
    {
      path: "/laporan/laporan-rkam",
      name: "RKAM",
    },
    {
      path: "/laporan/laporan-rkakl",
      name: "RKAKL",
    },
    // {
    //   path: "/laporan/rkakl-konsolidasi-min",
    //   name: "RKAKL Konsolidasi MIN",
    // },
    {
      path: "/laporan/laporan-rapbm",
      name: "RAPBM",
    },
    {
      path: "/laporan/apbm",
      name: "APBM",
    },
    {
      path: "/laporan/buku-kas-umum",
      name: "BKU",
    },
    // {
    //   path: "/laporan/buku-kas-umum-konsolidasi-min",
    //   name: "BKU Konsolidasi MIN",
    // },
    {
      path: "/laporan/buku-pembantu-pajak",
      name: "Buku Pembantu Pajak",
    },
    {
      path: "/laporan/buku-pembantu-kas-tunai",
      name: "Buku Pembantu Kas Tunai",
    },
    {
      path: "/laporan/pertanggung-jawaban",
      name: "Pertanggung Jawaban",
    },
    // {
    //   path: "/laporan/laporan-realisasi",
    //   name: "Realisasi Keuangan",
    // },
    {
      path: "/laporan/laporan-rincian-keuangan",
      name: "Realisasi Keuangan",
    },
    {
      path: "/laporan/output-kegiatan",
      name: "Output Kegiatan",
    },
    // {
    //   path: "/laporan/laporan-calk",
    //   name: "Catatan Atas Laporan Keuangan (CALK)",
    // },
  ];
  return response;
};
