/** @format */
import React, { useState, useEffect } from "react";
import { Modal } from "antd";

import { useSelector } from "react-redux";
// import { getPengumuman } from "../../services/v2/notificationservice/comment";
import ModalValidasiDanaBos from "../../components/Modal/ModalValidasiDanaBos";
import ModalInformation from "../../components/Modal/ModalInformation";

const Dashboard = () => {
  const store = useSelector((state: any) => state.store);
  const auths = useSelector((state: any) => state.auth);
  const auth = auths.data;
  const isTahun = auths.isTahun;
  interface Announcement {
    id: string;
    sender: string;
    receiver: string;
    status: string;
    title: string;
    description: string;
    startDate: string;
    endDate: string;
    createdAt: string;
  }

  let listLooker: any = [
    {
      pusat: `https://lookerstudio.google.com/embed/reporting/3612fe9d-9b00-4731-9638-a17907623f56/page/toucD?params=%7B%22df67%22:%22include%25EE%2580%25801%25EE%2580%2580IN%25EE%2580%2580${isTahun}%22%7D`,
      provinsi: `https://lookerstudio.google.com/embed/reporting/ccbeb14c-aedf-4af0-a6d1-4362f38aec0e/page/toucD?params=%7B"df70":"include%25EE%2580%25800%25EE%2580%2580IN%25EE%2580%2580${auth?.kode_provinsi}%22,%22df74%22:%22include%25EE%2580%25801%25EE%2580%2580IN%25EE%2580%2580${isTahun}%22%7D`,
      kabkota: `https://lookerstudio.google.com/embed/reporting/681da8ac-6f99-4f5b-b28f-f67b5b1b3700/page/toucD?params=%7B"df61":"include%25EE%2580%25800%25EE%2580%2580IN%25EE%2580%2580${auth?.kode_kabkota}%22,%22df68%22:%22include%25EE%2580%25801%25EE%2580%2580IN%25EE%2580%2580${isTahun}%22%7D`,
      pengawas: `https://lookerstudio.google.com/embed/reporting/681da8ac-6f99-4f5b-b28f-f67b5b1b3700/page/toucD?params=%7B"df61":"include%25EE%2580%25800%25EE%2580%2580IN%25EE%2580%2580${auth?.kode_kabkota}%22,%22df68%22:%22include%25EE%2580%25801%25EE%2580%2580IN%25EE%2580%2580${isTahun}%22%7D`,
      madrasah: `https://lookerstudio.google.com/embed/u/0/reporting/7513ed5b-92ae-43d8-adf5-d50cc6036a1a/page/toucD?params=%7B%22df40%22:%22include%25EE%2580%25800%25EE%2580%2580IN%25EE%2580%2580${auth?.madrasah?.nsm}%22,%22df33%22:%22include%25EE%2580%25801%25EE%2580%2580IN%25EE%2580%2580${isTahun}%22%7D`,
      tahun: 2023,
    },
    {
      pusat: `https://lookerstudio.google.com/embed/reporting/51106c68-7562-4ee7-bb07-a19769fee547/page/toucD?params=%7B"df62":"include%25EE%2580%25801%25EE%2580%2580IN%25EE%2580%2580${isTahun}"%7D`,
      provinsi: ` https://lookerstudio.google.com/embed/reporting/cc31817c-2275-4450-9c09-2c4bcf66b475/page/toucD?params=%7B"df70":"include%25EE%2580%25800%25EE%2580%2580IN%25EE%2580%2580${auth?.kode_provinsi}","df69":"include%25EE%2580%25801%25EE%2580%2580IN%25EE%2580%2580${isTahun}"%7D`,
      kabkota: `https://lookerstudio.google.com/embed/reporting/78621659-e5cf-4252-a6d5-1a6aa36f59fc/page/toucD?params=%7B"df61":"include%25EE%2580%25800%25EE%2580%2580IN%25EE%2580%2580${auth?.kode_kabkota}","df62":"include%25EE%2580%25801%25EE%2580%2580IN%25EE%2580%2580${isTahun}"%7D`,
      pengawas: `https://lookerstudio.google.com/embed/reporting/78621659-e5cf-4252-a6d5-1a6aa36f59fc/page/toucD?params=%7B"df61":"include%25EE%2580%25800%25EE%2580%2580IN%25EE%2580%2580${auth?.kode_kabkota}","df62":"include%25EE%2580%25801%25EE%2580%2580IN%25EE%2580%2580${isTahun}"%7D`,
      madrasah: `https://lookerstudio.google.com/embed/reporting/d7b97e9f-aa79-4795-b987-8f4be5c31bfe/page/toucD?params=%7B"df40":"include%25EE%2580%25800%25EE%2580%2580IN%25EE%2580%2580${auth?.madrasah?.nsm}","df32":"include%25EE%2580%25801%25EE%2580%2580IN%25EE%2580%2580${isTahun}"%7D`,
      tahun: 2024,
    },
    {
      pusat: `https://lookerstudio.google.com/embed/reporting/51106c68-7562-4ee7-bb07-a19769fee547/page/toucD?params=%7B"df62":"include%25EE%2580%25801%25EE%2580%2580IN%25EE%2580%2580${isTahun}"%7D`,
      provinsi: ` https://lookerstudio.google.com/embed/reporting/cc31817c-2275-4450-9c09-2c4bcf66b475/page/toucD?params=%7B"df70":"include%25EE%2580%25800%25EE%2580%2580IN%25EE%2580%2580${auth?.kode_provinsi}","df69":"include%25EE%2580%25801%25EE%2580%2580IN%25EE%2580%2580${isTahun}"%7D`,
      kabkota: `https://lookerstudio.google.com/embed/reporting/78621659-e5cf-4252-a6d5-1a6aa36f59fc/page/toucD?params=%7B"df61":"include%25EE%2580%25800%25EE%2580%2580IN%25EE%2580%2580${auth?.kode_kabkota}","df62":"include%25EE%2580%25801%25EE%2580%2580IN%25EE%2580%2580${isTahun}"%7D`,
      pengawas: `https://lookerstudio.google.com/embed/reporting/78621659-e5cf-4252-a6d5-1a6aa36f59fc/page/toucD?params=%7B"df61":"include%25EE%2580%25800%25EE%2580%2580IN%25EE%2580%2580${auth?.kode_kabkota}","df62":"include%25EE%2580%25801%25EE%2580%2580IN%25EE%2580%2580${isTahun}"%7D`,
      madrasah: `https://lookerstudio.google.com/embed/reporting/d7b97e9f-aa79-4795-b987-8f4be5c31bfe/page/toucD?params=%7B"df40":"include%25EE%2580%25800%25EE%2580%2580IN%25EE%2580%2580${auth?.madrasah?.nsm}","df32":"include%25EE%2580%25801%25EE%2580%2580IN%25EE%2580%2580${isTahun}"%7D`,
      tahun: 2025,
    },
  ];
  let dumpLooker: any =
    listLooker.find((item) => item.tahun?.toString() === isTahun?.toString()) ||
    null;
  let urlLooker: any =
    (dumpLooker && dumpLooker[auth?.group_role]) ||
    "https://lookerstudio.google.com/embed/reporting/51106c68-7562-4ee7-bb07-a19769fee547/page/toucD";

  urlLooker =
    (auth?.kode_role === "bkba_pengawas" &&
      "https://lookerstudio.google.com/embed/reporting/62eb7544-111c-4501-9cee-1a2447b4ac07/page/toucD/edit?params=%7B%22df19%22:%22include%25EE%2580%25800%25EE%2580%2580IN%25EE%2580%2580BA%2520REHAB%2520BERAT%22%7D") ||
    urlLooker;
  const [data, setData] = useState<Announcement[]>([]);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (auths?.isOnline && auths?.isLogin) {
      const fetchData = async () => {
        try {
          // const dataPengumuman = (await getPengumuman()) || [];
          const dataPengumuman = [];
          if (dataPengumuman.length) {
            setVisible(true);
          }
          setData(dataPengumuman);
          // console.log({ dataPengumuman });
        } catch (error) {
          console.error("error.message");
        }
      };

      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOk = () => {
    setVisible(false);

    // localStorage.setItem("isPopupClosed", "true");
  };

  const handleCancel = () => {
    setVisible(false);
    // localStorage.setItem("isPopupClosed", "true");
  };

  return (
    <>
      <ModalValidasiDanaBos
        dataAuth={auth}
        dataStore={store}
        dataYear={isTahun}
      />
      {auth?.group_role !== "pusat" && <ModalInformation />}
      <div className="p-5 h-screen flex">
        <iframe
          id="looker"
          key="frameLooker"
          title="frameLooker"
          src={urlLooker}
          className="w-full"></iframe>
      </div>
      <Modal
        title="Pengumuman !"
        open={visible}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="OK"
        cancelText="Tutup"
        maskClosable={false}
        keyboard={false}>
        <div>
          {data.map((dataPengumuman) => (
            <div key={dataPengumuman.id} className="mb-4">
              <text>{dataPengumuman.title} :</text>
              <br />
              <text> {dataPengumuman.description}</text>
              <br />
              {dataPengumuman.title === "Pemberitahuan Pengisian LPJ" && (
                <a
                  style={{ color: "green" }}
                  href="/laporan/pertanggung-jawaban">
                  Silakan klik disini
                </a>
              )}
            </div>
          ))}
        </div>
      </Modal>
    </>
  );
};

export default Dashboard;
