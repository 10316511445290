/** @format */

import React, { useEffect, useState } from "react";
import { Form, Modal, Button, Descriptions, Divider, Input } from "antd";
import moment from "moment";
import "moment/locale/id";
import { formatRupiah } from "../../../../../utils/helper";
import { approvalRencanaRincianKegiatanIndikatif } from "../../../../../services/v2/planningservice/rencanakegiatanindikatif";
import { approvalRencanaRincianKegiatanDefinitif } from "../../../../../services/v2/planningservice/rencanakegiatandefinitif";
import { useDispatch, useSelector } from "react-redux";
import { checkStatusClassBimtex } from "../../../../../services/v2/usermanservice/bimtekservices";
import { getPlanningAll } from "../../../../../services/v2/planningservice";
import { changeLockedStatus } from "../../../../../services/v2/planningservice/rencanapendapatandefinitif";
import { setStore } from "../../../../../redux/actions";

type approvalModal = {
  openModal: boolean;
  data: any;
  title: any;
  onReload: () => void;
  onClose: () => void;
};

const ModalApproval = (params: approvalModal) => {
  const tmpData = params.data;
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const store = useSelector((state: any) => state.store);
  const auths = useSelector((state: any) => state.auth);
  // const tmpRencanaPendapatanDefinitif = store.rencanaPendapatanDefinitif || [];
  const auth = auths?.data;
  const tmpPajak = store?.pajak || [];
  const dataPajak: any =
    tmpPajak.find((item) => item.kode === tmpData?.pajak) || null;
  const listMonth = moment.months();
  const [form] = Form.useForm();
  const [komentar, setKomentar] = useState<any>(null);


  const handleChange = (val) => {
    setKomentar(val.keterangan);
  };

  const handleSave = async (values) => {
    let payload: any = {
      ...tmpData,
      komentar: values === 'DISETUJUI' ? '' : komentar,
      status: values,
    };

    setLoading(true);

    const approvalAction = async () => {
      try {
        params.title === "Approval Rencana Rincian Kegiatan Indikatif" ? await approvalRencanaRincianKegiatanIndikatif(payload) : await approvalRencanaRincianKegiatanDefinitif(payload)
        const rencanaRekapSumberDanaBelanjaDefinitif = await getPlanningAll(
          "rencana-rekap-sumber-dana-belanja-definitif",
          { tahun: auth.isTahun },
        );
        checkStatusLms(rencanaRekapSumberDanaBelanjaDefinitif);
        params.onReload();
      } catch (error) {
        console.log(error);
      }
    }

    const modalReject = () => {
      Modal.warning({
        title: 'Tidak dapat melanjutkan proses',
        content: 'Harap isi keterangan!',
        centered: true,
        wrapClassName: "erkam-custom-modal",
      });
      setLoading(false)
    }

    const checkStatus = values === 'DITOLAK' || params?.data?.status === 'MENUNGGU';
    if ((values === 'DISETUJUI' || checkStatus) && komentar?.length > 1) {
      approvalAction();
      Modal.success({
        title: 'Sukses',
        content: 'Data rincian kegiatan berhasil disimpan',
        centered: true,
        wrapClassName: "erkam-custom-modal",
      }); 
      
    } else if (values === 'DITOLAK' && (params?.data?.status === 'MENUNGGU' || komentar?.length < 1)) {
      modalReject();
      return params?.openModal;
    } else if (values === 'DISETUJUI' && komentar === null) {
      approvalAction();
      Modal.success({
        title: 'Sukses',
        content: 'Data rincian kegiatan berhasil disimpan',
        centered: true,
        wrapClassName: "erkam-custom-modal",
      }); 
    } else {
      approvalAction();
      Modal.success({
        title: 'Sukses',
        content: 'Data rincian kegiatan berhasil disimpan',
        centered: true,
        wrapClassName: "erkam-custom-modal",
      }); 
    }

    setLoading(false);
    params.onClose();
  };

  //check status to lms
  const checkStatusLms = (dataBelanja: any) => {
    let listUser = store?.users || [];
    let totalPendapatan = 0;
    let totalBelanja = 0;
    // let filterSumber =
    //   auth?.madrasah?.status === "n" ? "apbn_bos" : "apbn_bos_semester_1";
    // let statusSumber =
    //   tmpRencanaPendapatanDefinitif.find(
    //     (item) => item.kode_sumber_dana === filterSumber,
    //   ) || null;
    // if (!statusSumber?.locked) {
    dataBelanja?.length &&
      // eslint-disable-next-line array-callback-return
      dataBelanja.map((item) => {
        totalPendapatan += item.jumlah;
        totalBelanja += item.sub_total_rencana_belanja;
      });

    if (totalBelanja === totalPendapatan) {
      let listUserStatus: any = [];
      const isProd: any =
        window.REACT_APP_API_URL_EDM ===
          "https://latihan-edm-fe.erkam-v2.kemenag.go.id/"
          ? true
          : false;
      listUser.length &&
        listUser
          .filter((item) =>
            ["kepala_madrasah", "bendahara_madrasah", "staf_madrasah"].includes(
              item.kodeRole,
            ),
          )
          // eslint-disable-next-line array-callback-return
          .map((item) => {
            let emailRecieve = item?.profile?.user?.email;
            let nsmRecieve = item?.madrasah?.nsm;
            listUserStatus.push({
              email: emailRecieve,
              erkam: 1,
              nsm: nsmRecieve,
            });
          });
      if (listUserStatus.length && isProd) {
        sendStatus(listUserStatus);
      }
    }
    // }
  };

  //check for bimtek
  const sendStatus = async (payload: any) => {
    try {
      const test = await checkStatusClassBimtex(payload);
      console.log(test);
      await changeLockedStatus(auth?.isTahun, auth?.madrasah?.id);
      const rencanaPendapatanDefinitif = await getPlanningAll(
        "rencana-pendapatan",
        { tahun: auth.isTahun },
      );
      const dataTmp = {
        rencanaPendapatanDefinitif,
      };
      setTimeout(() => {
        dispatch(setStore(dataTmp));
      }, 100);
      Modal.success({
        title: 'Sukses',
        content: 'Selamat anda berhasil menyelesaikan bimtek eRKAM v.2',
        centered: true,
        wrapClassName: "erkam-custom-modal",
      }); 
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (tmpData) {
      form.setFieldsValue({ keterangan: tmpData?.komentar })
      setKomentar(tmpData?.komentar);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tmpData])

  return (
    <>
      <Modal
        open={params.openModal}
        width={1000}
        title={params.title}
        onCancel={params.onClose}
        footer={[
          <Button onClick={params.onClose}>Batal</Button>,
          <Button
            loading={loading}
            onClick={() => handleSave("DITOLAK")}
            danger>
            Tolak
          </Button>,
          <Button
            type="primary"
            loading={loading}
            onClick={() => handleSave("DISETUJUI")}>
            Disetujui
          </Button>,
        ]}>
        <div className="mb-2 flex flex-col md:flex-row gap-4">
          <div className="flex-1">
            <Divider plain orientation="left">
              Detail Rincian
            </Divider>
            <Descriptions size="small" bordered column={1}>
              <Descriptions.Item label="Komponen Biaya">
                {tmpData?.komponenBiayaNama}
              </Descriptions.Item>
              <Descriptions.Item label="Jenis Belanja">
                {tmpData?.namaJenisBelanja}
              </Descriptions.Item>
              <Descriptions.Item label="Tipe Pencairan">
                {tmpData?.tipePencairanNama}
              </Descriptions.Item>
              <Descriptions.Item label="Jenis Pajak">
                {(dataPajak && dataPajak.deskripsi) || tmpData?.pajak}
              </Descriptions.Item>
            </Descriptions>
            <Divider plain orientation="left">
              Koefisien
            </Divider>
            <Descriptions size="small" className="mb-2" bordered column={1}>
              {/* {[...Array(4)].map((_: any, i: number) => (
                <Descriptions.Item label={`Koefisien ${i + 1}`}>
                  {tmpData &&
                    tmpData[`koef${i + 1}Jumlah`] +
                      " " +
                      tmpData[`koef${i + 1}Satuan`]}
                </Descriptions.Item>
              ))} */}
              {[...Array(4)].map((_: any, i: number) => {
                return (
                  tmpData &&
                  tmpData[`koef${i + 1}Jumlah`] > 0 && (
                    <Descriptions.Item label={`Koefisien ${i + 1}`}>
                      {tmpData &&
                        tmpData[`koef${i + 1}Jumlah`] +
                        " " +
                        tmpData[`koef${i + 1}Satuan`]}
                    </Descriptions.Item>
                  )
                );
              })}
            </Descriptions>
            <Form
              form={form}
              key="formApproval"
              layout="vertical"
              onValuesChange={handleChange}>
              <Form.Item label="Keterangan jika ditolak" name="keterangan">
                <Input.TextArea placeholder={'Keterangan'} />
              </Form.Item>
            </Form>
          </div>
          <div className="flex-1">
            <Divider plain orientation="left">
              Alokasi Pendapatan
            </Divider>
            <Descriptions size="small" bordered>
              {tmpData?.alokasiPendapatan.length &&
                tmpData?.alokasiPendapatan.map((item: any) => (
                  <Descriptions.Item
                    label={item.rencana_pendapatan.nama_sumber_dana}
                    span={3}>
                    {formatRupiah(item.jumlah)}
                  </Descriptions.Item>
                ))}
            </Descriptions>
            <Divider plain orientation="left">
              Anggaran Kas Bulanan
            </Divider>
            <Descriptions size="small" bordered>
              {listMonth.map((item: any, i: number) => (
                <Descriptions.Item key={`jm${i}`} label={item}>
                  {tmpData && tmpData[`jumlahBulan${i + 1}`]}
                </Descriptions.Item>
              ))}
            </Descriptions>
            <Divider />
            <Descriptions
              size="small"
              labelStyle={{ fontWeight: 600 }}
              column={1}>
              <Descriptions.Item label="Jumlah Kuantitas">
                {tmpData?.totalKuantitas}
              </Descriptions.Item>
              <Descriptions.Item label="Harga Satuan">
                {formatRupiah(tmpData?.hargaSatuan)}
              </Descriptions.Item>
              <Descriptions.Item label="Total Harga">
                {formatRupiah(tmpData?.jumlahTotal || 0)}
              </Descriptions.Item>
            </Descriptions>
          </div>
        </div>
      </Modal>
    </>
  );
};
export default ModalApproval;
