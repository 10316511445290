/** @format */

import React, { useState, useEffect } from "react";
import { BreadCrumb } from "../../../components";
import ExcelJS from "exceljs";
import * as FileSaver from "file-saver";
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import { formatRupiah } from "../../../utils/helper";
import moment from "moment";
import "moment/locale/id";
import { Table, Space } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getMadrasah } from "../../../services/v2/usermanservice/madrasahservices";
import { getLaporanRkaklKonsolidasi } from "../../../services/v2/planningservice/laporanservices";
import { ButtonDropdownExport } from "../../../components/Button";
import notifAlert from "../../../components/NotifAlert";
import { setStore } from "../../../redux/actions";
import FilterRkaklMin from "../Component/FilterRkaklMin";
import InputSearch from "../../../components/InputSearch";

const RkaklKonsolidasiMin = () => {
  const itemBreadcrumb = [
    { path: "/", breadcrumbName: "Home" },
    { path: "/", breadcrumbName: "Laporan" },
    { path: "/", breadcrumbName: "RKAKL Konsolidasi MIN" },
  ];
  const auths = useSelector((state: any) => state.auth);
  const store = useSelector((state: any) => state.store);
  const refKabkota = store.kabkota || [];
  const refProvinsi = store.provinsi || [];
  const auth = auths?.data || null;

  const groupRole = auth?.group_role || "";
  const [tmpMadrasah, setTmpMadrasah] = useState<any>([]);
  const [tmpFilter, setTmpFilter] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState<any>([]);
  const dispatch = useDispatch();
  const tmpListMadrasah = store?.listMadrasah || [];
  const [search, setSearch] = useState<any>(null);

  let dataTable: any = search
    ? tableData.filter((item: any) => {
        const val = search.toLowerCase();
        return (
          (item.nsm !== null &&
            (item.nsm || "").toString().toLowerCase().includes(val)) ||
          (item.nsmMadrasah !== null &&
            (item.nsmMadrasah || "").toString().toLowerCase().includes(val))
        );
      })
    : tableData;

  const handleChangeValues = (values: any) => {
    setTmpFilter(values);
    getData();
  };

  /**
   * Data untuk tabel children pertama
   */
  let columnTmp: any = [
    {
      title: "NSM",
      dataIndex: "nsm",
      key: "nsm",
      width: 80,
    },
    {
      title: "Madrasah",
      dataIndex: "namaMadrasah",
      key: "namaMadrasah",
      width: 140,
    },
    {
      title: "Provinsi",
      dataIndex: "kodeProvinsi",
      key: "kodeProvinsi",
      width: 140,
      render: (kodeProvinsi) => {
        let prov =
          refProvinsi.find((item) => item.kode === kodeProvinsi) || null;
        return prov?.nama;
      },
    },
    {
      title: "Kab / Kota",
      dataIndex: "kodeKabkota",
      key: "kodeKabkota",
      width: 140,
      render: (kodeKabkota) => {
        let kabkota =
          refKabkota.find((item) => item.kode === kodeKabkota) || null;
        return kabkota?.nama;
      },
    },
  ];

  let columns: any = [
    {
      title: "Kode",
      key: "kode1",
      align: "center",
      children: [
        {
          title: "1",
          dataIndex: "kode",
          key: "kode",
          width: 180,
        },
      ],
    },
    {
      title: "Uraian",
      key: "uraian2",
      align: "center",
      children: [
        {
          title: "2",
          dataIndex: "uraian",
          key: "uraian",
          width: 550,
        },
      ],
    },
    {
      title: "Volume Output",
      key: "volumeOutput3",
      align: "center",
      children: [
        {
          title: "3",
          dataIndex: "volumeOutput",
          key: "volumeOutput",
          width: 250,
        },
      ],
    },
    {
      title: "Rincian Perhitungan (Jumlah)",
      key: "rincianPerhitungan4",
      align: "center",
      children: [
        {
          title: "4",
          dataIndex: "rincianPerhitungan",
          key: "rincianPerhitungan",
          width: 250,
        },
      ],
    },
    {
      title: "Harga Satuan",
      key: "hargaSatuan5",
      align: "center",
      children: [
        {
          title: "5",
          dataIndex: "hargaSatuan",
          key: "hargaSatuan",
          width: 250,
        },
      ],
      // render: (jumlahTotal) => formatRupiah(jumlahTotal),
    },
    {
      title: "Jumlah Total",
      key: "jumlahTotal6",
      align: "center",
      children: [
        {
          title: "6",
          dataIndex: "jumlahTotal",
          key: "jumlahTotal",
          width: 250,
        },
      ],
      // render: (jumlahTotal) => formatRupiah(jumlahTotal),
    },
  ];

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tmpFilter, auth?.isTahun]);

  const getData = async () => {
    setLoading(true);
    if (groupRole !== "madrasah") {
      if (tmpListMadrasah.length === 0) {
        let filter: any = "";
        if (groupRole === "provinsi") {
          filter = `?kodeProvinsi=${auth?.kode_provinsi}`;
        }
        if (groupRole === "kabkota") {
          filter = `?kodeProvinsi=${auth?.kode_provinsi}&kode_kabkota=${auth?.kode_kabkota}`;
        }
        const madrasah = await getMadrasah(filter);
        const listMadrasah = madrasah || [];
        setTimeout(() => {
          dispatch(setStore({ listMadrasah }));
        }, 100);
        setTmpMadrasah(madrasah);
      } else {
        setTmpMadrasah(tmpListMadrasah);
      }
      const payload: any = {
        tahun: auth?.isTahun,
        kodeKabKota:
          auth?.group_role === "pusat" || auth?.group_role === "provinsi"
            ? tmpFilter?.kodeKabkota
            : auth?.kode_kabkota,
        status: "n",
        jenjang: "mi",
      };

      if (groupRole === "kabkota") {
        const response = await getLaporanRkaklKonsolidasi(payload);
        setTableData(response || []);
      } else if (tmpFilter?.kodeKabkota) {
        const response = await getLaporanRkaklKonsolidasi(payload);
        setTableData(response || []);
      }
    }

    setLoading(false);
  };

  const handleExportExcel = async () => {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const wb = new ExcelJS.Workbook();
    tableData.length &&
      // eslint-disable-next-line array-callback-return
      tableData.map((item, no) => {
        var ws = wb.addWorksheet(`${item.namaMadrasah}`);
        ws.mergeCells("A2:G2");

        const headerTitleCell = ws.getCell("A2");
        headerTitleCell.font = {
          size: 14,
          underline: true,
          bold: true,
        };

        headerTitleCell.value = `RENCANA KINERJA ANGGARAN KEMENTERIAN / LEMBAGA \n KEGIATAN T.A. ${auths?.isTahun}`;
        const dataTitles =
          (item?.detail.length && renderTitles(item?.detail)) || [];

        // eslint-disable-next-line array-callback-return
        // eslint-disable-next-line array-callback-return
        dataTitles.length &&
          // eslint-disable-next-line array-callback-return
          dataTitles.map((item, i) => {
            if (i > 1) {
              const row = i + 3;
              const headerTitleBody = ws.getCell(`A${row}`);
              const headerValueBody = ws.getCell(`B${row}`);
              headerTitleBody.font = {
                size: 12,
                underline: true,
                bold: true,
              };
              headerTitleBody.value = item.kode;
              headerValueBody.font = {
                size: 12,
                underline: true,
              };
              headerValueBody.value = item.uraian;
            }
          });
        ws.getColumn("A").width = 20;
        ws.getColumn("B").width = 60;
        ws.getColumn("C").width = 30;
        ws.getColumn("D").width = 30;
        ws.getColumn("E").width = 30;
        ws.getColumn("F").width = 30;
        // ws.columns = [
        //   { header: "Kode", key: "kode" },
        //   { header: "Uraian", key: "uraian" },
        //   { header: "Volume Output", key: "volumeOutput" },
        //   { header: "Rincian Perhitungan (Jumlah)", key: "rincianPerhitungan" },
        //   { header: "Harga Satuan", key: "hargaSatuan" },
        //   { header: "Jumlah Total", key: "jumlahTotal" },
        // ];
        const rowLast = ws.rowCount;
        let dataList: any = [];
        let dataTmp: any =
          (item?.detail.length && renderListData(item?.detail)) || [];
        dataTmp.length &&
          // eslint-disable-next-line array-callback-return
          dataTmp.map((item) => {
            dataList.push([
              item.kode,
              item.uraian,
              item.volumeOutput,
              item.rincianPerhitungan,
              item.hargaSatuan,
              item.jumlahTotal,
            ]);
          });

        ws.addTable({
          name: "MyTable",
          ref: `A${rowLast + 2}`,
          headerRow: true,
          // totalsRow: true,
          style: {
            showFirstColumn: true,
            // theme: "TableStyleDark3",
            showRowStripes: true,
          },
          columns: [
            { name: "Kode" },
            { name: "Uraian" },
            { name: "Volume Output" },
            { name: "Rincian Perhitungan (Jumlah)" },
            { name: "Harga Satuan" },
            { name: "Jumlah Total" },
          ],
          rows: dataList,
        });
      });
    const excelBuffer = await wb.xlsx.writeBuffer();
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, "Laporan-RKAKL" + fileExtension);
  };

  function handleExportPdf() {
    const doc: any = new jsPDF({
      putOnlyUsedFonts: true,
      orientation: "landscape",
      floatPrecision: 16,
    });

    tableData.length &&
      // eslint-disable-next-line array-callback-return
      tableData.map((item, no) => {
        if (no !== 0) {
          doc.addPage();
        }
        let tableTmp: any = [];
        let dataMadrasah: any =
          tmpMadrasah.find((iitem) => iitem.nsm === item.nsm) || null;
        let dataTmp: any =
          (item?.detail.length && renderListData(item?.detail)) || [];
        dataTmp.length &&
          // eslint-disable-next-line array-callback-return
          dataTmp.map((item) => {
            tableTmp.push([
              item.kode,
              item.uraian,
              item.volumeOutput,
              item.rincianPerhitungan,
              item.hargaSatuan,
              item.jumlahTotal,
            ]);
          });
        let dataTitles: any =
          (item?.detail.length && renderTitles(item?.detail)) || [];
        var fline = 10;
        doc.setFont("times", "bold");
        doc.setFontSize(14);

        doc.text(`RENCANA KINERJA ANGGARAN KEMENTERIAN / LEMBAGA`, 150, 12, {
          align: "center",
        });
        doc.text(`KEGIATAN T.A. ${auths?.isTahun}`, 150, 18, {
          align: "center",
        });
        doc.setFont("times", "normal");
        doc.setFontSize(10);
        let noLine = 30;
        dataTitles.length &&
          // eslint-disable-next-line array-callback-return
          dataTitles.map((item: any, i: any) => {
            if (i > 1) {
              doc.text(item.kode, fline, noLine);
              doc.text(`: ${item.uraian}`, fline + 60, noLine);
              noLine += 5;
            }
          });

        var headers: any = [
          {
            id: "kode",
            dataKey: "kode",
            padding: 0,
            header: "Kode",
            width: 42,
          },
          {
            id: "uraian",
            dataKey: "uraian",
            padding: 0,
            header: "Uraian",
            width: 80,
          },
          {
            id: "volumeOutput",
            dataKey: "volumeOutput",
            padding: 0,
            header: "Volume Output",
            width: 40,
          },
          {
            id: "rincianPerhitungan",
            dataKey: "rincianPerhitungan",
            padding: 0,
            header: "Rincian Perhitungan (Jumlah)",
            width: 40,
          },
          {
            id: "hargaSatuan",
            dataKey: "hargaSatuan",
            padding: 0,
            header: "Harga Satuan",
            width: 40,
          },
          {
            id: "jumlahTotal",
            dataKey: "jumlahTotal",
            padding: 0,
            header: "Jumlah Total",
            width: 39,
          },
        ];

        let las: any = [];
        autoTable(doc, {
          styles: { lineColor: 244, lineWidth: 0.1 },
          headStyles: {
            halign: "center",
            valign: "middle",
            fillColor: [0, 128, 0],
          }, // Cells in first column centered and green
          columnStyles: {
            0: { halign: "center", cellWidth: 23 },
            1: { halign: "left", cellWidth: 95 },
            2: { halign: "right", cellWidth: 38 },
            3: { halign: "right", cellWidth: 40 },
            4: { halign: "right", cellWidth: 40 },
            5: { halign: "right", cellWidth: 40 },
          },
          startY: 90,
          margin: { top: 10, left: 10, right: 10 },
          columns: headers,
          body: tableTmp,
          didDrawPage: (d) => las.push(d.cursor),
        });
        let lastLine: any = 7 + (las.length !== 0 ? las[0].y : 0);
        const footerText = [
          {
            text: "Mengetahui",
            line: fline,
            space: 0,
            bottom: 10,
          },
          {
            text: moment().format("dddd, DD MMMM YYYY"),
            line: fline,
            space: 210,
            bottom: 0,
          },
          {
            text: "Kepala Madrasah",
            line: fline,
            space: 0,
            bottom: 5,
          },
          {
            text: "Bendahara Madrasah",
            line: fline,
            space: 210,
            bottom: 0,
          },
          {
            text:
              (dataMadrasah?.nama_komite &&
                `( ${dataMadrasah?.nama_komite} )`) ||
              "(.................)",
            line: fline,
            space: 0,
            bottom: 30,
          },
          {
            text:
              (dataMadrasah?.nama_kepala &&
                `( ${dataMadrasah?.nama_kepala} )`) ||
              "(.................)",
            line: fline,
            space: 210,
            bottom: 0,
          },
        ];
        let tmpBottom = lastLine;
        // eslint-disable-next-line array-callback-return
        footerText.map((item) => {
          tmpBottom += item.bottom;
          if (tmpBottom > 195) {
            doc.addPage();
            tmpBottom = 15;
          }
          doc.text(item.text, fline + item.space, tmpBottom);
        });
      });
    doc.output("dataurlnewwindow", {
      filename: "laporan-rkakl-min-konsolidasi",
    });
    notifAlert({
      type: "success",
      description: "Data berhasil di export",
    });
  }

  const handleExportFile = (type) => {
    if (dataTable.length > 0) {
      if (type === "pdf") {
        handleExportPdf();
      } else if (type === "excel") {
        handleExportExcel();
      }
    } else {
      notifAlert({
        type: "warning",
        description:
          "Harap pilih data yang ingin di export melalui fungsi filter!",
      });
    }
  };

  /**
   * Membuka baris Table detail
   */
  const expandedRowDetail = (record) => {
    const dataDetailTmp: any =
      (record?.detail && renderListData(record?.detail)) || [];
    return <Table columns={columns} dataSource={dataDetailTmp} bordered />;
  };

  const renderTitles = (dataDetail) => {
    let dataTitles: any =
      (dataDetail.length && dataDetail[0]?.rencanaTitles) || [];
    return dataTitles;
  };
  const renderListData = (dataDetail) => {
    let tmpDetail: any = [];
    let dataHeaders: any =
      (dataDetail.length && dataDetail[0].rencanaHeaders) || [];
    let dataRincian: any =
      (dataDetail.length && dataDetail[0].rencanaKegiatans) || [];
    dataHeaders.length &&
      // eslint-disable-next-line array-callback-return
      dataHeaders.map((item) => {
        let tmpDataHeader: any = {
          kode: item.kode || null,
          uraian: item.uraian || null,
          volumeOutput: item.volumeOutput || null,
          rincianPerhitungan: `${item.totalKuantitas || ""} ${
            item.unitSatuan || ""
          }`,
          hargaSatuan:
            (item.hargaSatuan && formatRupiah(item.hargaSatuan)) || null,
          jumlahTotal:
            (item.jumlahTotal && formatRupiah(item.jumlahTotal)) || null,
        };
        tmpDetail.push(tmpDataHeader);
      });
    dataRincian.length &&
      // eslint-disable-next-line array-callback-return
      dataRincian.map((item) => {
        let tmpDataRincian: any = {
          kode: item.kode || null,
          uraian: item.deskripsi || null,
          volumeOutput: null,
          rincianPerhitungan: null,
          hargaSatuan: null,
          jumlahTotal: null,
        };
        tmpDetail.push(tmpDataRincian);
        item.rencanaKegiatans.length &&
          // eslint-disable-next-line array-callback-return
          item.rencanaKegiatans.map((items) => {
            let tmpDataRencana: any = {
              kode: items.kodeJenisBelanja || null,
              uraian: items.namaJenisBelanja || null,
              volumeOutput: null,
              rincianPerhitungan: null,
              hargaSatuan: null,
              jumlahTotal:
                (items.jumlahTotal && formatRupiah(items.jumlahTotal)) || null,
            };
            tmpDetail.push(tmpDataRencana);
            items.rincianKegiatans.length &&
              // eslint-disable-next-line array-callback-return
              items.rincianKegiatans.map((itemss) => {
                let tmpDataRencanaRincian: any = {
                  kode: null,
                  uraian: itemss.komponenBiayaNama || null,
                  volumeOutput: itemss.volumeOutput || null,
                  rincianPerhitungan: `${item.totalKuantitas || ""} ${
                    item.unitSatuan || ""
                  }`,
                  hargaSatuan:
                    (itemss.jumlahTotal && formatRupiah(itemss.jumlahTotal)) ||
                    null,
                  jumlahTotal:
                    (itemss.jumlahTotal && formatRupiah(itemss.jumlahTotal)) ||
                    null,
                };
                tmpDetail.push(tmpDataRencanaRincian);
              });
          });
      });
    return tmpDetail;
  };
  return (
    <>
      <BreadCrumb routes={itemBreadcrumb} title="RKAKL Konsolidasi MIN" />
      {groupRole !== "madrasah" && (
        <FilterRkaklMin
          handleChangeValues={handleChangeValues}
          groupRole={groupRole}
        />
      )}
      <div className="m-5 p-5 bg-white shadow-md rounded">
        <div className="mb-4 flex justify-end">
          <div className="mr-4">
            <Space size={[8, 8]} wrap>
              <ButtonDropdownExport handleExportFile={handleExportFile} />
            </Space>
          </div>
          <InputSearch
            className="w-3/4"
            onChange={(e: any) => setSearch(e.currentTarget.value)}
          />
        </div>
        <div className="w-full">
          <Table
            expandable={{
              expandedRowRender: (record) => expandedRowDetail(record),
            }}
            rowKey={(record) => record.nsm}
            columns={columnTmp}
            dataSource={dataTable}
            loading={loading}
            bordered
          />
          {/* <Table
            expandable={{
              expandedRowRender: (record) => expandedRowKegiatan(record),
            }}
            rowKey={(record) => record.id}
            columns={columnTmp}
            dataSource={dataTable}
            bordered
            pagination={{
              total: totalDataTable,
              position: ["bottomRight"],
              defaultPageSize: pageSize,
              defaultCurrent: page,
              showTotal: (total) => `Total ${total} items`,
              onChange(page, pageSize) {
                setPage(page);
                setPageSize(pageSize);
              },
            }}
          /> */}
        </div>
      </div>
    </>
  );
};

export default RkaklKonsolidasiMin;
