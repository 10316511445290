/** @format */

import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal, Space, Table, Button, Form, InputNumber } from "antd";
import { BreadCrumb } from "../../../components";
import {
  ButtonDropdownExport,
  ButtonTableDelete,
  ButtonTableEdit,
} from "../../../components/Button";
import notifAlert from "../../../components/NotifAlert";

import InputSearch from "../../../components/InputSearch";
import { formatCurr } from "../../../utils/helper";
import jsPDF from "jspdf";
import * as FileSaver from "file-saver";
import ExcelJS from "exceljs";
import autoTable from "jspdf-autotable";
import { useHistory } from "react-router-dom";
import {
  deleteAlokasiSumberDanaBos,
  editAlokasiSumberDanaBos,
  getPlanningAll,
} from "../../../services/v2/planningservice";
import { setBos } from "../../../redux/actions/bosAction";
export const publicURL: string =
  window.REACT_APP_PUBLIC_URL ||
  "https://staging-frontend.erkam-v2.kemenag.go.id/";

const AlokasiSumberDanaBos = () => {
  const itemBreadcrumb = [
    { path: "/", breadcrumbName: "Home" },
    { path: "/", breadcrumbName: "Pencairan Dokumen BOS" },
    { path: "/", breadcrumbName: "Alokasi Sumber Dana BOS" },
  ];
  const route = useHistory();
  const dataState: any = route ? route.location.state : null;
  const auths = useSelector((state: any) => state.auth);
  const store = useSelector((state: any) => state.store);
  const bos = useSelector((state: any) => state.bos);
  const refProvinsi = store.provinsi || [];
  const refKabkota = store.kabkota || [];
  const dispatch = useDispatch();
  // const tmpListMadrasah = store?.listMadrasah || [];
  const tmpAlokasiSumberBos = bos?.alokasiSumberDanaBos || [];
  const [formData] = Form.useForm();
  // const [id, setID] = useState<any>(null);
  const auth = auths?.data || null;
  const [search, setSearch] = useState<any>(null);
  const [tmpMadrasah, setTmpMadrasah] = useState<any>([]);
  const [dataMadrasah, setDataMadrasah] = useState<any>();
  // const [tmpFilter, setTmpFilter] = useState<any>(null);
  const [openModal, setOpenModal] = useState<any>(false);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(dataState?.page || 1);
  const [pageSize, setPageSize] = useState(dataState?.pageSize || 10);

  useEffect(() => {
    getData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auths?.isTahun]);
  // let dataTable: any = tmpMadrasah.map((item) => {
  //   let madrasahData: any =
  //     tmpListMadrasah.find((iitem: any) => iitem.nsm === item.nsm) || null;
  //   let namaProvinsi: any =
  //     refProvinsi.find(
  //       (items) =>
  //         items?.kode.toString() === madrasahData?.kode_provinsi.toString(),
  //     ) || null;
  //   let namaKabkota: any =
  //     refKabkota.find(
  //       (items) =>
  //         items?.kode.toString() === madrasahData?.kode_kabkota.toString(),
  //     ) || null;

  //   return {
  //     namaProvinsi: namaProvinsi?.nama,
  //     namaKabkota: namaKabkota?.nama,
  //     namaMadrasah: madrasahData?.nama,
  //     jenjang: madrasahData?.jenjang?.nama,
  //     ...item,
  //   };
  // });
  let dataTable: any = tmpMadrasah || [];
  /** Filter Search */
  dataTable = search
    ? dataTable.filter((item: any) => {
        const val = search.toLowerCase();
        return (
          (item.nsm !== null &&
            (item.nsm || "").toString().toLowerCase().includes(val)) ||
          (item.namaMadrasah !== null &&
            (item.namaMadrasah || "").toString().toLowerCase().includes(val)) ||
          (item.jumlahSiswa !== null &&
            (item.jumlahSiswa || "").toString().toLowerCase().includes(val))
        );
      })
    : dataTable;
  let totalDataTable = dataTable.length;
  /**
   * Filter Pagination
   */
  dataTable = dataTable.filter((v, i) => {
    let start = pageSize * (page - 1);
    let end = start + pageSize;

    return i >= start && i < end;
  });

  const getData = async () => {
    setLoading(true);
    try {
      // if (tmpAlokasiSumberBos.length === 0) {
      const res =
        (await getPlanningAll("portal-bos-without-nsm", {
          tahun: auth.isTahun,
        })) || [];
      if (auth?.kode_role !== "admin_pusat_bos") {
        const alokasiSumberDanaBos = res.filter(
          (item) => item.kodeProvinsi === auth?.kode_provinsi,
        );
        setTmpMadrasah(alokasiSumberDanaBos);
        setTimeout(() => {
          dispatch(setBos({ alokasiSumberDanaBos }));
        }, 100);
      } else {
        const alokasiSumberDanaBos = res;
        setTmpMadrasah(alokasiSumberDanaBos);
        setTimeout(() => {
          dispatch(setBos({ alokasiSumberDanaBos }));
        }, 100);
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  // const handleChangeValues = (values: any) => {
  //   setTmpFilter(values);
  // };

  const handleExportFile = (type: any) => {
    if (dataTable.length > 0) {
      if (type === "pdf") {
        handleExportPdf();
      } else if (type === "excel") {
        handleExportExcel();
      }
    } else {
      notifAlert({
        type: "warning",
        description: "Maaf data tidak tersedia!",
      });
    }
  };

  const handleExportExcel = async () => {
    var ExcelJSWorkbook = new ExcelJS.Workbook();
    var worksheet = ExcelJSWorkbook.addWorksheet("Data");
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";

    worksheet.mergeCells("A1:M1");

    worksheet.getColumn("A").width = 5;
    worksheet.getColumn("B").width = 14;
    worksheet.getColumn("C").width = 40;
    worksheet.getColumn("D").width = 10;
    worksheet.getColumn("E").width = 30;
    worksheet.getColumn("F").width = 30;
    worksheet.getColumn("G").width = 30;
    worksheet.getColumn("H").width = 30;
    worksheet.getColumn("I").width = 30;
    worksheet.getColumn("J").width = 30;
    worksheet.getColumn("L").width = 35;
    worksheet.getColumn("M").width = 20;
    worksheet.getColumn("N").width = 25;
    worksheet.getColumn("O").width = 20;
    worksheet.getColumn("P").width = 40;

    const headerCell = worksheet.getCell("A1");
    headerCell.font = {
      size: 11,
      underline: true,
      bold: true,
    };
    headerCell.alignment = { vertical: "middle", horizontal: "center" };
    headerCell.value = `ALOKASI SUMBER DANA BOS T.A ${auth.isTahun}`;

    let dataTmp: any = [];
    tmpMadrasah.map((e, i) => {
      return dataTmp.push([
        i + 1,
        e.nsm,
        e.namaMadrasah,
        e.jenjang,
        refProvinsi.find((item) => item.kode === e.kodeProvinsi).nama || "",
        refKabkota.find((item) => item.kode === e.kodeKabKota).nama || "",
        formatCurr(e.apbn_bos_semester1),
        formatCurr(e.apbn_bos_semester2),
        formatCurr(e.apbn_bos_semester2 - e.nilaiAa),
        formatCurr(e.nilaiAa),
        formatCurr(e.apbn_bos_semester1 + e.apbn_bos_semester2),
        e.jumlahSiswa,
        e.namaBank,
        e.nomorRekening,
        e.namaPemilikRekening,
      ]);
    });

    worksheet.addTable({
      name: "Table",
      ref: "A3",
      headerRow: true,
      style: {
        showRowStripes: true,
      },
      columns: [
        { name: "No" },
        { name: "NSM" },
        { name: "Madrasah" },
        { name: "Jenjang" },
        { name: "Provinsi" },
        { name: "Kabupaten" },
        { name: "Tahapan I" },
        { name: "Tahapan II" },
        { name: "Non AA" },
        { name: "AA" },
        { name: "Total" },
        { name: "Jumlah Siswa" },
        { name: "Bank" },
        { name: "No Rekening" },
        { name: "Nama Pemilik Rekening" },
      ],
      rows: dataTmp,
    });

    const excelBuffer = await ExcelJSWorkbook.xlsx.writeBuffer();
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, `Alokasi-Sumber-Dana-BOS${fileExtension}`);
    notifAlert({
      type: "success",
      description: "Data berhasil di export",
    });
  };

  const handleEdit = (record) => {
    setOpenModal(true);
    setDataMadrasah(record);
    // console.log(record);
    // setID(record.nsm)

    formData.setFieldsValue({
      // jumlahSiswa: record.jumlahSiswa,
      tahap1: record.apbn_bos_semester1,
      tahap2: record.apbn_bos_semester2,
    });
  };

  function handleExportPdf() {
    const doc: any = new jsPDF({
      putOnlyUsedFonts: true,
      orientation: "landscape",
      floatPrecision: 16,
    });

    doc.setFont("times", "bold");
    doc.text(`ALOKASI SUMBER DANA BOS T.A ${auth.isTahun}`, 150, 10, {
      align: "center",
    });

    var headers: any = [
      { id: "id", dataKey: "id", padding: 0, header: "No", width: 22 },
      {
        id: "nsm",
        dataKey: "nsm",
        padding: 0,
        header: "NSM",
        width: 50,
      },
      {
        id: "nama",
        dataKey: "nama",
        padding: 0,
        header: "Madrasah",
        width: 50,
      },
      {
        id: "jenjang",
        dataKey: "jenjang",
        padding: 0,
        header: "Jenjang",
        width: 40,
      },
      {
        id: "jumlahSiswa",
        dataKey: "jumlahSiswa",
        padding: 0,
        header: "Jumlah Siswa",
        width: 30,
      },
      {
        id: "provinsi",
        dataKey: "provinsi",
        padding: 0,
        header: "Provinsi",
        width: 50,
      },
      {
        id: "kabkota",
        dataKey: "kabkota",
        padding: 0,
        header: "Kab / Kota",
        width: 50,
      },
      {
        id: "tahapanI",
        dataKey: "tahapanI",
        padding: 0,
        header: "Tahapan I",
        width: 50,
      },
      {
        id: "tahapanII",
        dataKey: "tahapanII",
        padding: 0,
        header: "Tahapan II",
        width: 50,
      },
      {
        id: "nonAA",
        dataKey: "nonAA",
        padding: 0,
        header: "Non AA",
        width: 50,
      },
      {
        id: "aa",
        dataKey: "aa",
        padding: 0,
        header: "AA",
        width: 50,
      },
      {
        id: "total",
        dataKey: "total",
        padding: 0,
        header: "Total",
        width: 50,
      },
      {
        id: "bank",
        dataKey: "bank",
        padding: 0,
        header: "Bank",
        width: 39,
      },
      {
        id: "noRekening",
        dataKey: "noRekening",
        padding: 0,
        header: "No Rekening",
        width: 39,
      },
      {
        id: "namaPemilikRekening",
        dataKey: "namaPemilikRekening",
        padding: 0,
        header: "Nama Pemilik Rekening",
        width: 39,
      },
    ];
    let las: any = [];
    // const count = type === "pdf_all" ? tableData.length : 10;
    autoTable(doc, {
      styles: { lineColor: 244, lineWidth: 0.1, fontSize: 6 },
      headStyles: {
        halign: "center",
        valign: "middle",
        fillColor: [0, 128, 0],
      }, // Cells in first column centered and green
      columnStyles: {
        0: { halign: "center", cellWidth: 8 },
        1: { halign: "center", cellWidth: 22 },
        2: { halign: "left", cellWidth: 38 },
        3: { halign: "center", cellWidth: 13 },
        4: { halign: "center", cellWidth: 12 },
        5: { halign: "left", cellWidth: 20 },
        6: { halign: "left", cellWidth: 20 },
        7: { halign: "right", cellWidth: 17 },
        8: { halign: "right", cellWidth: 17 },
        9: { halign: "right", cellWidth: 17 },
        10: { halign: "right", cellWidth: 17 },
        11: { halign: "right", cellWidth: 17 },
        12: { halign: "left", cellWidth: 24 },
        13: { halign: "left", cellWidth: 25 },
        14: { halign: "left", cellWidth: 24 },
      },
      startY: 15,
      margin: { top: 3, left: 3, right: 3 },
      columns: headers,
      body: tmpAlokasiSumberBos.map((e, i) => {
        return {
          id: i + 1,
          nsm: e.nsm,
          nama: e.namaMadrasah,
          jenjang: e.jenjang,
          jumlahSiswa: e.jumlahSiswa,
          provinsi:
            refProvinsi.find((item) => item.kode === e.kodeProvinsi)?.nama ||
            "",
          kabkota:
            refKabkota.find((item) => item.kode === e.kodeKabKota)?.nama || "",
          tahapanI: formatCurr(e.apbn_bos_semester1),
          tahapanII: formatCurr(e.apbn_bos_semester2),
          nonAA: formatCurr(e.apbn_bos_semester2 - e.nilaiAa),
          aa: formatCurr(e.nilaiAa),
          total: formatCurr(e.apbn_bos_semester1 + e.apbn_bos_semester2),
          bank: e.namaBank,
          noRekening: e.nomorRekening,
          namaPemilikRekening: e.namaPemilikRekening,
        };
      }),
      didDrawPage: (d) => las.push(d.cursor),
    });
    var blob = doc.output("blob", { filename: "alokasi-sumber-dana-bos" });
    window.open(URL.createObjectURL(blob));
    // doc.output("dataurlnewwindow", { filename: "alokasi-sumber-dana-bos" });
    notifAlert({
      type: "success",
      description: "Data berhasil di export",
    });
  }

  const handleDelete = async (record: any) => {
    try {
      let payload = `${record?.nsm}?tahun=${record?.tahun}`;
      const res = await deleteAlokasiSumberDanaBos(payload);
      if (res) {
        const alokasiSumberDanaBos =
          (await getPlanningAll("portal-bos-without-nsm", {
            tahun: auth.isTahun,
          })) || [];
        setTmpMadrasah(alokasiSumberDanaBos);
        setTimeout(() => {
          dispatch(setBos({ alokasiSumberDanaBos }));
        }, 100);
        notifAlert({
          type: "success",
          description: "Penghapusan Dokumen Berhasil",
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  let columnsDokumen: any = [
    {
      title: "NSM",
      key: "nsm",
      dataIndex: "nsm",
    },
    {
      title: "Madrasah",
      dataIndex: "namaMadrasah",
      key: "namaMadrasah",
    },
    {
      title: "Jenjang",
      dataIndex: "jenjang",
      key: "jenjang",
      render: (jenjang) => <div className="uppercase">{jenjang}</div>,
    },
    {
      title: "Provinsi",
      dataIndex: "kodeProvinsi",
      key: "kodeProvinsi",
      render: (kodeProvinsi) => {
        let namaProv: any =
          refProvinsi.find((item) => item.kode === kodeProvinsi) || null;
        return namaProv?.nama || "";
      },
    },
    {
      title: "Kabkota",
      dataIndex: "kodeKabKota",
      key: "kodeKabKota",
      render: (kodeKabKota) => {
        let namaKabkota: any =
          refKabkota.find((item) => item.kode === kodeKabKota) || null;
        return namaKabkota?.nama || "";
      },
    },
    {
      title: "Alokasi Anggaran",
      key: "Nominal",
      children: [
        {
          title: "Tahap 1",
          dataIndex: "apbn_bos_semester1",
          key: "apbn_bos_semester1",
          render: (apbn_bos_semester1) => (
            <div className="text-right">{formatCurr(apbn_bos_semester1)}</div>
          ),
        },
        {
          title: "Tahap 2",
          key: "apbn_bos_semester2",
          dataIndex: "apbn_bos_semester2",
          render: (apbn_bos_semester2) => (
            <div className="text-right">{formatCurr(apbn_bos_semester2)}</div>
          ),
        },
        {
          title: "Nominal Non AA",
          key: "nonAA",

          render: (record) => (
            <div className="text-right">
              {formatCurr(record?.apbn_bos_semester2 - record.nilaiAa)}
            </div>
          ),
        },
        {
          title: "Nominal AA",
          key: "nilaiAa",
          dataIndex: "nilaiAa",
          render: (nilaiAa) => (
            <div className="text-right">{formatCurr(nilaiAa)}</div>
          ),
        },
        {
          title: "Total",
          key: "total",
          render: (record) => (
            <div className="text-right">
              {formatCurr(
                record.apbn_bos_semester1 + record.apbn_bos_semester2,
              )}
            </div>
          ),
        },
      ],
    },
    {
      title: "Jumlah Siswa",
      key: "jumlahSiswa",
      dataIndex: "jumlahSiswa",
    },
    {
      title: "Rekening Madrasah",
      key: "rekening",
      children: [
        {
          title: "Nama BANK",
          dataIndex: "namaBank",
          key: "namaBank",
        },
        {
          title: "No Rekening",
          key: "nomorRekening",
          dataIndex: "nomorRekening",
        },
        {
          title: "Nama Pemilik",
          key: "namaPemilikRekening",
          dataIndex: "namaPemilikRekening",
        },
      ],
    },
    {
      title: "Aksi",
      key: "aksi",
      width: "100%",
      fixed: "right",
      render: (record) => {
        return (
          <Space>
            <ButtonTableEdit onClick={() => handleEdit(record)} />
            <ButtonTableDelete
              onClick={() => {
                Modal.confirm({
                  title: "Perhatian",
                  content: "Yakin Hapus Data?",
                  centered: true,
                  wrapClassName: "erkam-custom-modal",
                  onOk() {
                    return handleDelete(record);
                  },
                  okText: "Hapus",
                  cancelText: "Batal",
                  okType: "danger",
                });
              }}
            />
          </Space>
        );
      },
    },
  ];

  const handleSave = async (values: any) => {
    let payload: any = {
      nsm: dataMadrasah?.nsm,
      nominalBosTahap1: values.tahap1,
      nominalBosTahap2: values.tahap2,
      nsmMadrasah: dataMadrasah?.nsm,
      tahun: dataMadrasah?.tahun,
    };
    try {
      const res = await editAlokasiSumberDanaBos(payload);
      if (res) {
        const alokasiSumberDanaBos =
          (await getPlanningAll("portal-bos-without-nsm", {
            tahun: auth.isTahun,
          })) || [];
        setTmpMadrasah(alokasiSumberDanaBos);
        setTimeout(() => {
          dispatch(setBos({ alokasiSumberDanaBos }));
        }, 100);
        notifAlert({
          type: "success",
          description: "Data Alokasi Sumber Dana berhasil diubah",
        });
      }
    } catch (error) {
      console.log(error);
    }
    setOpenModal(false);
  };

  const handleReset = () => {
    setOpenModal(false);
    formData.resetFields();
  };

  const checkValidationSaldo = (_, values) => {
    if (values <= 0) {
      return Promise.reject("Saldo alokasi tidak boleh 0!");
    }
    return Promise.resolve();
  };

  auth?.kode_role !== "admin_pusat_bos" && delete columnsDokumen[8];

  return (
    <>
      <BreadCrumb routes={itemBreadcrumb} title="Alokasi Sumber Dana BOS" />
      <div className="m-5 p-5 bg-white shadow-md rounded">
        <div className="mb-4 flex justify-end">
          <div className="mr-4">
            <Space wrap>
              <ButtonDropdownExport handleExportFile={handleExportFile} />
            </Space>
          </div>
          <InputSearch
            className="w-3/4"
            onChange={(e: any) => setSearch(e.currentTarget.value)}
          />
        </div>
        <Table
          loading={loading}
          columns={columnsDokumen}
          dataSource={dataTable}
          bordered
          pagination={{
            total: totalDataTable,
            position: ["bottomRight"],
            defaultPageSize: pageSize,
            defaultCurrent: page,
            showTotal: (total) => `Total ${total} items`,
            onChange(page, pageSize) {
              setPage(page);
              setPageSize(pageSize);
            },
          }}
        />
      </div>
      <Modal
        open={openModal}
        width={1000}
        title={`Alokasi Dana BOS`}
        onCancel={handleReset}
        footer={[
          <Button onClick={handleReset}>Batal</Button>,
          <Button type="primary" onClick={() => formData.submit()}>
            Simpan
          </Button>,
        ]}>
        <Form
          form={formData}
          name="formPencairan"
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 8 }}
          labelAlign="left"
          labelWrap
          layout="horizontal"
          onFinish={handleSave}>
          <Form.Item label="NSM">{dataMadrasah?.nsm || ""}</Form.Item>
          <Form.Item label="Madrasah">
            {dataMadrasah?.namaMadrasah || ""}
          </Form.Item>
          <Form.Item label="Jenjang">
            <div className="uppercase">{dataMadrasah?.jenjang || ""}</div>
          </Form.Item>
          <Form.Item label="Jumlah Siswa">
            {dataMadrasah?.jumlahSiswa || ""}
          </Form.Item>
          <Form.Item label="Provinsi">
            {refProvinsi.find(
              (item) => item.kode === dataMadrasah?.kodeProvinsi,
            )?.nama || ""}
          </Form.Item>
          <Form.Item label="Kabkota">
            {refKabkota.find((item) => item.kode === dataMadrasah?.kodeKabKota)
              ?.nama || ""}
          </Form.Item>
          <Form.Item
            label="Tahap 1"
            name="tahap1"
            rules={[
              {
                required: true,
                message: "Nilai Tahap 1 tidak boleh kosong!",
              },
              {
                validator: checkValidationSaldo,
              },
            ]}>
            <InputNumber
              style={{ width: "100%" }}
              maxLength={15}
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
              prefix="Rp."
              placeholder="Nilai Tahap 1"
            />
          </Form.Item>
          <Form.Item
            label="Tahap 2"
            name="tahap2"
            rules={[
              {
                required: true,
                message: "Nilai Tahap 2 tidak boleh kosong!",
              },
              {
                validator: checkValidationSaldo,
              },
            ]}>
            <InputNumber
              style={{ width: "100%" }}
              maxLength={15}
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
              prefix="Rp."
              placeholder="Nilai Tahap 2"
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default AlokasiSumberDanaBos;
