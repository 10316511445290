/** @format */

import React, { useState } from "react";
import moment from "moment";
import { Form, Select, DatePicker } from "antd";
import { useSelector } from "react-redux";

type filterProps = {
  handleChangeValues: (values: any) => void;
  groupRole: any;
};

const FilterSumberDanaMin = ({
  handleChangeValues,
  groupRole,
}: filterProps) => {
  const [form] = Form.useForm();
  const store = useSelector((state: any) => state.store);
  const auths = useSelector((state: any) => state.auth);
  const auth = store?.profile || null;
  const initialValue = {
    provinsi: auth?.group_role === "pusat" ? null : auth?.kode_provinsi,
    kabkota:
      auth?.group_role === "pusat" || auth?.group_role === "provinsi"
        ? null
        : auth?.kode_kabkota,
  };
  const [provinsi, setProvinsi] = useState<any>(
    auth?.group_role === "pusat" ? null : auth?.kode_provinsi,
  );
  const refProvinsi = store.provinsi || [];
  const refKabkota = store.kabkota || [];

  const handleChange = (values) => {
    for (let obj in values) {
      if (obj === "provinsi") {
        setProvinsi(values.provinsi);
        form.setFieldsValue({ kabkota: null });
      }
    }

    changeField();
  };

  const changeField = () => {
    const val = form.getFieldsValue();
    let tmpFilter: any = {
      periode: null,
      kodeKabkota: null,
    };
    for (let obj in val) {
      switch (obj) {
        case "periode":
          tmpFilter = { ...tmpFilter, periode: val.periode || null };
          break;
        case "kabkota":
          tmpFilter = { ...tmpFilter, kodeKabkota: val.kabkota || null };
          break;
      }
    }
    handleChangeValues(tmpFilter);
  };

  function disabledYear(current) {
    return current.year() !== moment(`${auths.isTahun}-12-01`).year();
  }

  return (
    <div className="m-5 p-5 bg-white shadow-md rounded">
      <Form
        form={form}
        key="formFilterPeriode"
        name="formFilterPeriode"
        initialValues={initialValue}
        labelAlign="left"
        labelCol={{ span: 6 }}
        onValuesChange={handleChange}>
        <div className="grid grid-cols-1  md:items-center  md:grid-cols-2 gap-4 ">
          {groupRole !== "madrasah" && (
            <>
              <div>
                <Form.Item label="Provinsi" name="provinsi" required>
                  <Select
                    placeholder="Pilih Provinsi"
                    showSearch
                    allowClear
                    disabled={auth?.group_role === "pusat" ? false : true}
                    optionFilterProp="children">
                    {refProvinsi.length &&
                      refProvinsi.map((item) => (
                        <Select.Option
                          key={`filProv${item.kode}`}
                          value={item.kode}>
                          {item.nama}
                        </Select.Option>
                      ))}
                  </Select>
                </Form.Item>
              </div>
              <div>
                <Form.Item label="Kab / Kota" name="kabkota" required>
                  <Select
                    placeholder="Pilih Kab / Kota"
                    showSearch
                    allowClear
                    disabled={
                      auth?.group_role === "pusat" ||
                      auth?.group_role === "provinsi"
                        ? false
                        : true
                    }
                    optionFilterProp="children">
                    {refKabkota.length &&
                      provinsi &&
                      refKabkota
                        .filter((item) => item.kode_provinsi === provinsi)
                        .map((item) => (
                          <Select.Option
                            key={`filKab${item.kode}`}
                            value={item.kode}>
                            {item.nama}
                          </Select.Option>
                        ))}
                  </Select>
                </Form.Item>
              </div>
            </>
          )}
          <div>
            <Form.Item label="Periode" name="periode" required>
              <DatePicker picker="month" disabledDate={disabledYear} />
            </Form.Item>
          </div>
        </div>
      </Form>
    </div>
  );
};
export default FilterSumberDanaMin;
