/** @format */

import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Space, Table, Button } from "antd";
import { BreadCrumb } from "../../../../components";
import { ButtonDropdownExport } from "../../../../components/Button";
import notifAlert from "../../../../components/NotifAlert";
import InputSearch from "../../../../components/InputSearch";
import jsPDF from "jspdf";
import * as FileSaver from "file-saver";
import ExcelJS from "exceljs";
import autoTable from "jspdf-autotable";
import { useHistory } from "react-router-dom";
import { DokumenStatistic } from "../../../../components/Statistic";
import { formatCurr } from "../../../../utils/helper";
import { getPlanningAll } from "../../../../services/v2/planningservice";

const RekapitulasiPencairanBosKabkota = () => {
  const route = useHistory();
  const dataState: any = route ? route.location.state : null;
  const [page, setPage] = useState(dataState?.page || 1);
  const [pageSize, setPageSize] = useState(dataState?.pageSize || 10);
  const auths = useSelector((state: any) => state.auth);
  const auth = auths?.data || null;
  const store = useSelector((state: any) => state.store);
  const refKabkota = store.kabkota || [];
  const refProvinsi = store.provinsi || [];
  const dataProvinsi =
    refProvinsi.find((item) => item.kode === dataState?.kode_provinsi) || null;
  const [search, setSearch] = useState<any>(null);
  const [tmpMadrasah, setTmpMadrasah] = useState<any>([]);
  const itemBreadcrumb = [
    { path: "/", breadcrumbName: "Home" },
    { path: "/", breadcrumbName: "Pencairan Dokumen BOS" },
    {
      path: "/",
      breadcrumbName: `Rekapitulasi Pencairan BOS Tahap ${dataState?.tahap}`,
    },
    { path: "/", breadcrumbName: `Provinsi ${dataProvinsi?.nama}` },
  ];
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (auths?.isLogin) {
      getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth?.isTahun, dataState]);

  /** Filter Search */

  let dataTable: any = search
    ? tmpMadrasah.filter((item: any) => {
        const val = search.toLowerCase();
        return (
          (item.kabkota !== null &&
            (item.kabkota || "").toString().toLowerCase().includes(val)) ||
          (item.kode_kabkota !== null &&
            (item.kode_kabkota || "").toString().toLowerCase().includes(val))
        );
      })
    : tmpMadrasah;
  let totalDataTable = dataTable.length;

  const getData = async () => {
    setLoading(true);
    try {
      const kabkotaTmp =
        (await getPlanningAll(
          `portal-bos/rekapitulasi/list/provinsi/${dataState?.kode_provinsi}`,
          {
            tahun: auth.isTahun,
            tahap: dataState?.tahap,
            provinsiId: dataState?.kode_provinsi,
          },
        )) || [];
      //    let dataTmp:any =  refKabkota.filter(item)
      setTmpMadrasah(kabkotaTmp);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  // const handleChangeValues = (values: any) => {
  //   setTmpFilter(values);
  // };

  const handleExportFile = (type: any) => {
    if (dataTable.length > 0) {
      if (type === "pdf") {
        handleExportPdf();
      } else if (type === "excel") {
        handleExportExcel();
      }
    } else {
      notifAlert({
        type: "warning",
        description: "Maaf data tidak tersedia!",
      });
    }
  };

  const handleExportExcel = async () => {
    var ExcelJSWorkbook = new ExcelJS.Workbook();
    var worksheet = ExcelJSWorkbook.addWorksheet("Data");
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";

    worksheet.mergeCells("A1:M1");

    worksheet.getColumn("A").width = 5;
    worksheet.getColumn("B").width = 14;
    worksheet.getColumn("C").width = 40;
    worksheet.getColumn("D").width = 10;
    worksheet.getColumn("E").width = 30;
    worksheet.getColumn("F").width = 30;
    worksheet.getColumn("G").width = 30;
    worksheet.getColumn("H").width = 30;
    worksheet.getColumn("I").width = 30;
    worksheet.getColumn("J").width = 20;
    worksheet.getColumn("K").width = 25;
    worksheet.getColumn("L").width = 20;
    worksheet.getColumn("M").width = 40;

    const headerCell = worksheet.getCell("A1");
    headerCell.font = {
      size: 11,
      underline: true,
      bold: true,
    };
    headerCell.alignment = { vertical: "middle", horizontal: "center" };
    headerCell.value = `ALOKASI SUMBER DANA BOS T.A ${auth.isTahun}`;

    let dataTmp: any = [];
    tmpMadrasah.map((e, i) => {
      return dataTmp.push([
        i + 1,
        refKabkota.find((item) => item.kode === e.kode_kabkota).nama || "",
        e?.lembaga || 0,
        formatCurr(e.total_anggaran_verifikasi),
        e?.total_lembaga_verifikasi || 0,
        formatCurr(e.total_anggaran_pengajuan),
        e?.total_lembaga_pengajuan || 0,
        formatCurr(e.total_anggaran_belum_pencairan),
        e?.total_lembaga_belum_pencairan || 0,
        formatCurr(e.total_anggaran_pencairan_bank),
        e?.total_lembaga_pencairan_bank || 0,
      ]);
    });

    worksheet.addTable({
      name: "Table",
      ref: "A3",
      headerRow: true,
      style: {
        showRowStripes: true,
      },
      columns: [
        { name: "No" },
        { name: "KabKota" },
        { name: "Lembaga" },
        { name: "Verifikasi" },
        { name: "Lembaga" },
        { name: "Pengajuan" },
        { name: "Lembaga" },
        { name: "Belum Mencairkan" },
        { name: "Lembaga" },
        { name: "Pencairan Bank" },
        { name: "Lembaga" },
      ],
      rows: dataTmp,
    });

    const excelBuffer = await ExcelJSWorkbook.xlsx.writeBuffer();
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, `rekapitulasi-bos${fileExtension}`);
    notifAlert({
      type: "success",
      description: "Data berhasil di export",
    });
  };

  function handleExportPdf() {
    const doc: any = new jsPDF({
      putOnlyUsedFonts: true,
      orientation: "landscape",
      floatPrecision: 16,
    });

    doc.setFont("times", "bold");
    doc.text(`REKAPITULASI  BOS T.A ${auth.isTahun}`, 150, 10, {
      align: "center",
    });

    var headers: any = [
      { id: "id", dataKey: "id", padding: 0, header: "No", width: 22 },
      {
        id: "kabkota",
        dataKey: "kabkota",
        padding: 0,
        header: "Kab / Kota",
        width: 50,
      },
      {
        id: "lembaga",
        dataKey: "lembaga",
        padding: 0,
        header: "Lembaga",
        width: 50,
      },
      {
        id: "verifikasiAnggaran",
        dataKey: "verifikasiAnggaran",
        padding: 0,
        header: "Verifikasi",
        width: 50,
      },
      {
        id: "verifikasiLembaga",
        dataKey: "verifikasiLembaga",
        padding: 0,
        header: "Lembaga",
        width: 50,
      },
      {
        id: "pengajuanAnggaran",
        dataKey: "pengajuanAnggaran",
        padding: 0,
        header: "Pengajuan",
        width: 50,
      },
      {
        id: "pengajuanLembaga",
        dataKey: "pengajuanLembaga",
        padding: 0,
        header: "Lembaga",
        width: 39,
      },
      {
        id: "belumMencairkan",
        dataKey: "belumMencairkan",
        padding: 0,
        header: "Belum Mencairakan",
        width: 39,
      },
      {
        id: "belumMencairkanLembaga",
        dataKey: "belumMencairkanLembaga",
        padding: 0,
        header: "Lembaga",
        width: 39,
      },
      {
        id: "pencairanBank",
        dataKey: "pencairanBank",
        padding: 0,
        header: "Pencairan Bank",
        width: 39,
      },
      {
        id: "pencairanBankLembaga",
        dataKey: "pencairanBankLembaga",
        padding: 0,
        header: "Lembaga",
        width: 39,
      },
    ];
    let las: any = [];
    // const count = type === "pdf_all" ? tableData.length : 10;
    autoTable(doc, {
      styles: { lineColor: 244, lineWidth: 0.1, fontSize: 9 },
      headStyles: {
        halign: "center",
        valign: "middle",
        fillColor: [0, 128, 0],
      }, // Cells in first column centered and green
      columnStyles: {
        0: { halign: "center", cellWidth: 10 },
        1: { halign: "center", cellWidth: 32 },
        2: { halign: "center", cellWidth: 20 },
        3: { halign: "right", cellWidth: 30 },
        4: { halign: "center", cellWidth: 25 },
        5: { halign: "right", cellWidth: 30 },
        6: { halign: "center", cellWidth: 25 },
        7: { halign: "right", cellWidth: 30 },
        8: { halign: "center", cellWidth: 25 },
        9: { halign: "right", cellWidth: 30 },
        10: { halign: "center", cellWidth: 25 },
        11: { halign: "right", cellWidth: 30 },
        12: { halign: "center", cellWidth: 25 },
      },
      startY: 15,
      margin: { top: 3, left: 3, right: 3 },
      columns: headers,
      body: tmpMadrasah.map((e, i) => {
        return {
          id: i + 1,
          kabkota:
            refKabkota.find((item) => item.kode === e.kode_kabkota)?.nama || "",
          lembaga: e?.lembaga || 0,
          verifikasiAnggaran: formatCurr(e.total_anggaran_verifikasi),
          verifikasiLembaga: e?.total_lembaga_verifikasi || 0,
          pengajuanAnggaran: formatCurr(e.total_anggaran_pengajuan),
          pengajuanLembaga: e?.total_lembaga_pengajuan || 0,
          belumMencairkan: formatCurr(e.total_anggaran_belum_pencairan),
          belumMencairkanLembaga: e?.total_lembaga_belum_pencairan || 0,
          pencairanBank: formatCurr(e.total_anggaran_pencairan_bank),
          pencairanBankLembaga: e?.total_lembaga_pencairan_bank || 0,
        };
      }),
      didDrawPage: (d) => las.push(d.cursor),
    });
    var blob = doc.output("blob", { filename: "rekapitulasi-sumber-dana-bos" });
    window.open(URL.createObjectURL(blob));
    // doc.output("dataurlnewwindow", { filename: "alokasi-sumber-dana-bos" });
    notifAlert({
      type: "success",
      description: "Data berhasil di export",
    });
  }

  // const handleDeleteUpload = async (idFile: any) => {
  //   try {
  //     await deleteDokumenPencairan(idFile);
  //     notifAlert({
  //       type: "success",
  //       description: "Penghapusan Dokumen Berhasil",
  //     });
  //     getData();
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  let columnsDokumen: any = [
    {
      title: "No",
      key: "no",
      width: 70,
      render: (_, record, i: number) => i + 1 + (page - 1) * pageSize,
    },
    {
      title: "Kab / Kota",
      dataIndex: "kode_kabkota",
      key: "kode_kabkota",
      width: 230,
      render: (kode_kabkota) => {
        let namaKabko =
          refKabkota.find((item) => item.kode === kode_kabkota) || null;
        return namaKabko?.nama;
      },
    },
    {
      title: "Lembaga",
      dataIndex: "lembaga",
      width: 100,
      key: "lembaga",
      render: (lembaga) => `${lembaga || 0} `,
    },
    {
      title: "Verifikasi",
      key: "Penyaluran",
      children: [
        {
          title: "Anggaran",
          key: "total_anggaran_verifikasi",
          dataIndex: "total_anggaran_verifikasi",
          width: 200,
          render: (total_anggaran_verifikasi) => (
            <div className="text-right">
              {formatCurr(total_anggaran_verifikasi)}
            </div>
          ),
        },
        {
          title: "Lembaga",
          dataIndex: "total_lembaga_verifikasi",
          key: "total_lembaga_verifikasi",
          width: 100,
          render: (total_lembaga_verifikasi) =>
            `${total_lembaga_verifikasi || 0} `,
        },
      ],
    },
    {
      title: "Pengajuan Pencairan",
      key: "pengajuan",
      children: [
        {
          title: "Anggaran",
          key: "total_anggaran_pengajuan",
          dataIndex: "total_anggaran_pengajuan",
          width: 200,
          render: (total_anggaran_pengajuan) => (
            <div className="text-right">
              {formatCurr(total_anggaran_pengajuan)}
            </div>
          ),
        },
        {
          title: "Lembaga",
          dataIndex: "total_lembaga_pengajuan",
          key: "total_lembaga_pengajuan",
          width: 100,
          render: (total_lembaga_pengajuan) =>
            `${total_lembaga_pengajuan || 0} `,
        },
        // {
        //   title: "%",
        //   key: "persentasePengajuan",
        //   // render: (penyaluranTotalMadrasah) => `${penyaluranTotalMadrasah} `,
        // },
      ],
    },
    {
      title: "Belum Mencairkan",
      key: "belumMencairkan",
      children: [
        {
          title: "Anggaran",
          key: "total_anggaran_belum_pencairan",
          dataIndex: "total_anggaran_belum_pencairan",
          width: 200,
          render: (total_anggaran_belum_pencairan) => (
            <div className="text-right">
              {formatCurr(total_anggaran_belum_pencairan)}
            </div>
          ),
        },
        {
          title: "Lembaga",
          dataIndex: "total_lembaga_belum_pencairan",
          key: "total_lembaga_belum_pencairan",
          width: 100,
          render: (total_lembaga_belum_pencairan) =>
            `${total_lembaga_belum_pencairan || 0} `,
        },
        // {
        //   title: "%",
        //   key: "persentasePengajuan",
        //   // render: (penyaluranTotalMadrasah) => `${penyaluranTotalMadrasah} `,
        // },
      ],
    },
    {
      title: "Pencairan Bank",
      key: "pencairanBank",
      children: [
        {
          title: "Anggaran",
          key: "total_anggaran_pencairan_bank",
          width: 200,
          dataIndex: "total_anggaran_pencairan_bank",
          render: (total_anggaran_pencairan_bank) => (
            <div className="text-right">
              {formatCurr(total_anggaran_pencairan_bank)}
            </div>
          ),
        },
        {
          title: "Lembaga",
          dataIndex: "total_lembaga_pencairan_bank",
          width: 100,
          key: "total_lembaga_pencairan_bank",
          render: (total_lembaga_pencairan_bank) =>
            `${total_lembaga_pencairan_bank || 0} `,
        },
        // {
        //   title: "%",
        //   key: "persentasePengajuan",
        //   // render: (penyaluranTotalMadrasah) => `${penyaluranTotalMadrasah} `,
        // },
      ],
    },
    {
      title: "Aksi",
      key: "aksi",
      width: 140,
      fixed: "right",
      render: (record) => {
        return (
          <Space>
            {/* <ButtonTableApproval onClick={() => handleAfterApproval(record)} /> */}
            <Button
              type="primary"
              onClick={() =>
                route.push({
                  pathname: `/pencairan/rekapitulasi/listkabkota/${record.kantor_kabkota_id}`,
                  state: {
                    page: page,
                    pageSize: pageSize,
                    dataProvinsi: dataState,
                    tahap: dataState?.tahap,
                    ...record,
                  },
                })
              }>
              Lihat Detail
            </Button>
          </Space>
        );
      },
    },
  ];

  return (
    <>
      <BreadCrumb
        routes={itemBreadcrumb}
        title={`Provinsi ${dataProvinsi?.nama}`}
        back={true}
        toBack={() =>
          route.push({
            pathname: "../",
            state: {
              page: dataState?.page || 1,
              pageSize: dataState?.pageSize || 10,
              tahap: dataState?.tahap || 1,
            },
          })
        }
      />
      <DokumenStatistic listData={tmpMadrasah} />
      <div className="m-5 p-5 bg-white shadow-md rounded">
        <div className="mb-4 flex justify-end">
          <div className="mr-4">
            <Space wrap>
              <ButtonDropdownExport handleExportFile={handleExportFile} />
            </Space>
          </div>
          <InputSearch
            className="w-3/4"
            onChange={(e: any) => setSearch(e.currentTarget.value)}
          />
        </div>
        <Table
          loading={loading}
          columns={columnsDokumen}
          scroll={{ x: "1300" }}
          dataSource={dataTable}
          bordered
          pagination={{
            total: totalDataTable,
            position: ["bottomRight"],
            defaultPageSize: pageSize,
            defaultCurrent: page,
            showTotal: (total) => `Total ${total} items`,
            onChange(page, pageSize) {
              setPage(page);
              setPageSize(pageSize);
            },
          }}
        />
      </div>
    </>
  );
};

export default RekapitulasiPencairanBosKabkota;
