/** @format */

import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Space, Table, Button } from "antd";
import { BreadCrumb } from "../../../components";
import { ButtonDropdownExport } from "../../../components/Button";
// import moment from "moment";
// import "moment/locale/id";
import jsPDF from "jspdf";
import * as FileSaver from "file-saver";
import ExcelJS from "exceljs";
import autoTable from "jspdf-autotable";
import notifAlert from "../../../components/NotifAlert";
import InputSearch from "../../../components/InputSearch";
import { useHistory } from "react-router-dom";
import { formatCurr } from "../../../utils/helper";
import ModalRekonsiliasi from "../../../components/Modal/ModalRekonsiliasi";
import { getLaporanBKUBPK } from "../../../services/v2/reportservice";

const TransaksiSPJMadrasah = () => {
  const route = useHistory();
  const dataState: any = route ? route.location.state : null;
  const auths = useSelector((state: any) => state.auth);
  // const store = useSelector((state: any) => state.store);
  const auth = auths?.data || null;
  const pathTmp =
    (dataState?.backUrl === "prov" && `../prov`) ||
    `../listprov/${dataState?.dataProvinsi?.kodeProvinsi}`;

  const [search, setSearch] = useState<any>(null);
  const [openModal, setOpenModal] = useState<any>(false);
  const [dataRekonsiliasi, setDataRekonsiliasi] = useState<any>([]);
  const [dataMadrasah, setDataMadrasah] = useState<any>(null);
  const [tmpMadrasah, setTmpMadrasah] = useState<any>([]);
  const itemBreadcrumb = [
    { path: "/", breadcrumbName: "Home" },
    { path: "/", breadcrumbName: "Laporan BKU dan SPJ" },
    {
      path: "/",
      breadcrumbName: `Sumber Dana ${dataState?.sumberDana?.namaSumberDana}`,
    },
    // {
    //   path: "/",
    //   breadcrumbName: `Provinsi ${dataState?.dataProvinsi?.namaProvinsi}`,
    // },
    { path: "/", breadcrumbName: `${dataState?.namaKabKota}` },
  ];
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (auths?.isLogin) {
      getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth?.isTahun, dataState]);

  /** Filter Search */
  let dataTable: any = search
    ? tmpMadrasah.filter((item: any) => {
        const val = search.toLowerCase();
        return (
          (item.nsm !== null &&
            (item.nsm || "").toString().toLowerCase().includes(val)) ||
          (item.namaMadrasah !== null &&
            (item.namaMadrasah || "").toString().toLowerCase().includes(val))
        );
      })
    : tmpMadrasah;

  const getData = async () => {
    setLoading(true);
    try {
      const kabkotaTmp = dataState?.listMadrasah || [];
      setTmpMadrasah(kabkotaTmp);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const handleExportFile = (type: any) => {
    if (dataTable.length > 0) {
      if (type === "pdf") {
        handleExportPdf();
      } else if (type === "excel") {
        handleExportExcel();
      }
    } else {
      notifAlert({
        type: "warning",
        description: "Maaf data tidak tersedia!",
      });
    }
  };

  const handleExportExcel = async () => {
    var ExcelJSWorkbook = new ExcelJS.Workbook();
    var worksheet = ExcelJSWorkbook.addWorksheet("Data");
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";

    worksheet.mergeCells("A1:E1");

    worksheet.getColumn("A").width = 5;
    worksheet.getColumn("B").width = 14;
    worksheet.getColumn("C").width = 40;
    worksheet.getColumn("D").width = 30;
    worksheet.getColumn("E").width = 30;

    const headerCell = worksheet.getCell("A1");
    headerCell.font = {
      size: 11,
      underline: true,
      bold: true,
    };
    headerCell.alignment = { vertical: "middle", horizontal: "center" };
    headerCell.value = `LAPORAN BKU dan SPJ  SUMBER DANA ${dataState?.sumberDana?.namaSumberDana} T.A ${auth.isTahun} KAB/KOTA ${dataState?.namaKabKota}`;

    let dataTmp: any = [];
    tmpMadrasah.map((e, i) => {
      return dataTmp.push([
        i + 1,
        e.nsm,
        e.namaMadrasah,
        // e.jenjang,
        // e.jumlah_siswa,
        formatCurr(e.realisasiPendapatan),
        formatCurr(e.realisasiBelanja),
      ]);
    });

    worksheet.addTable({
      name: "Table",
      ref: "A3",
      headerRow: true,
      style: {
        showRowStripes: true,
      },
      columns: [
        { name: "No" },
        { name: "NSM" },
        { name: "Madrasah" },
        // { name: "Negeri / Swasta" },
        // { name: "Jenjang" },
        // { name: "Jumlah Siswa" },
        { name: "Pendapatan" },
        { name: "Belanja" },
        // { name: "Nominal" },
      ],
      rows: dataTmp,
    });

    const excelBuffer = await ExcelJSWorkbook.xlsx.writeBuffer();
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, `Transaksi-spj-Sumber-Dana${fileExtension}`);
    notifAlert({
      type: "success",
      description: "Data berhasil di export",
    });
  };

  function handleExportPdf() {
    const doc: any = new jsPDF({
      putOnlyUsedFonts: true,
      orientation: "landscape",
      floatPrecision: 16,
    });

    doc.setFont("times", "bold");
    doc.text(
      `LAPORAN BKU dan SPJ  SUMBER DANA ${dataState?.sumberDana?.namaSumberDana} T.A ${auth.isTahun}`,
      150,
      10,
      {
        align: "center",
      },
    );

    var headers: any = [
      { id: "id", dataKey: "id", padding: 0, header: "No", width: 22 },
      {
        id: "nsm",
        dataKey: "nsm",
        padding: 0,
        header: "NSM",
        width: 50,
      },
      {
        id: "namaMadrasah",
        dataKey: "namaMadrasah",
        padding: 0,
        header: "Madrasah",
        width: 50,
      },
      {
        id: "realisasiPendapatan",
        dataKey: "realisasiPendapatan",
        padding: 0,
        header: "Pendapatan",
        width: 50,
      },
      {
        id: "realisasiBelanja",
        dataKey: "realisasiBelanja",
        padding: 0,
        header: "Belanja",
        width: 50,
      },
    ];
    let las: any = [];
    // const count = type === "pdf_all" ? tableData.length : 10;
    autoTable(doc, {
      styles: { lineColor: 244, lineWidth: 0.1, fontSize: 9 },
      headStyles: {
        halign: "center",
        valign: "middle",
        fillColor: [0, 128, 0],
      }, // Cells in first column centered and green
      columnStyles: {
        0: { halign: "center", cellWidth: 20 },
        1: { halign: "center", cellWidth: 35 },
        2: { halign: "left", cellWidth: 100 },
        3: { halign: "right", cellWidth: 55 },
        4: { halign: "right", cellWidth: 55 },
      },
      startY: 25,
      margin: { top: 14, left: 15, right: 14 },
      columns: headers,
      body: tmpMadrasah.map((e, i) => {
        return {
          id: i + 1,
          nsm: e.nsm,
          namaMadrasah: e.namaMadrasah,
          realisasiPendapatan: formatCurr(e.realisasiPendapatan),
          realisasiBelanja: formatCurr(e.realisasiBelanja),
        };
      }),
      didDrawPage: (d) => las.push(d.cursor),
    });
    var blob = doc.output("blob", { filename: "transksi-spj-sumber-dana" });
    window.open(URL.createObjectURL(blob));
    // doc.output("dataurlnewwindow", { filename: "alokasi-sumber-dana-bos" });
    notifAlert({
      type: "success",
      description: "Data berhasil di export",
    });
  }

  let columns: any = [
    {
      title: "NSM",
      key: "nsm",
      width: 150,
      dataIndex: "nsm",
    },
    {
      title: "Madrasah",
      width: 230,
      dataIndex: "namaMadrasah",
      key: "namaMadrasah",
    },
    // {
    //   title: "Negeri / Swasta",
    //   dataIndex: "status",
    //   key: "status",
    //   width: 110,
    //   render: (status) => (status === "n" && "Negeri") || "Swasta",
    // },
    // {
    //   title: "Jenjang",
    //   dataIndex: "jenjang",
    //   width: 100,
    //   key: "jenjang",
    //   render: (jenjang) => <div className="uppercase">{jenjang}</div>,
    // },
    {
      title: "Pendapatan",
      key: "realisasiPendapatan",
      width: 130,
      dataIndex: "realisasiPendapatan",
      render: (realisasiPendapatan) => (
        <div className="text-right">{formatCurr(realisasiPendapatan)}</div>
      ),
    },
    {
      title: "Belanja",
      key: "realisasiBelanja",
      width: 130,
      dataIndex: "realisasiBelanja",
      render: (realisasiBelanja) => (
        <div className="text-right">{formatCurr(realisasiBelanja)}</div>
      ),
    },
    {
      title: "Aksi",
      key: "aksi",
      width: 160,
      fixed: "right",
      render: (record) => {
        return (
          <Space>
            <Button type="primary" onClick={() => handleAfterLaporan(record)}>
              Lihat Detail
            </Button>
          </Space>
        );
      },
    },
  ];

  const handleAfterLaporan = async (values) => {
    setLoading(true);
    try {
      const payload: any = {
        tahun: values?.tahun,
        nsm: values?.nsm,
        kodeSumberDana: values?.kodeSumberDana,
      };
      const res = await getLaporanBKUBPK(payload);
      if (res) {
        setDataMadrasah({
          namaKabkota: dataState?.namaKabKota,
          namaSumberDana: dataState?.sumberDana?.namaSumberDana,
          namaProvinsi: dataState?.namaProvinsi,
          ...values,
        });
        setDataRekonsiliasi(res || []);
        setOpenModal(true);
      }
    } catch (error) {
      console.log(error);
    }

    setLoading(false);
  };

  const handleClose = () => {
    setOpenModal(false);
    setDataRekonsiliasi([]);
    setDataMadrasah(null);
  };

  return (
    <>
      <BreadCrumb
        routes={itemBreadcrumb}
        title={`Kab - Kota ${dataState?.namaKabKota}`}
        back={true}
        toBack={() =>
          route.push({
            pathname: pathTmp,
            state: {
              page: dataState?.page || 1,
              pageSize: dataState?.pageSize || 10,
              tahap: dataState?.tahap,
              sumberDana: dataState?.sumberDana,
              namaProvinsi: dataState?.namaProvinsi,
              listMadrasah: dataState?.listKabkota || [],
              ...dataState?.dataProvinsi,
            },
          })
        }
      />
      <div className="m-5 p-5 bg-white shadow-md rounded">
        <div className="mb-4 flex justify-end">
          <div className="mr-4">
            <Space wrap>
              <ButtonDropdownExport handleExportFile={handleExportFile} />
            </Space>
          </div>
          <InputSearch
            className="w-3/4"
            onChange={(e: any) => setSearch(e.currentTarget.value)}
          />
        </div>
        <Table
          loading={loading}
          scroll={{ x: "1300" }}
          columns={columns}
          dataSource={dataTable}
          bordered
        />
      </div>
      <ModalRekonsiliasi
        openModal={openModal}
        onClose={handleClose}
        dataFile={dataRekonsiliasi}
        dataMadrasah={dataMadrasah}
      />
    </>
  );
};

export default TransaksiSPJMadrasah;
