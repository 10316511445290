/** @format */

import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Button, Table, Modal, Space, Typography, Badge } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { BreadCrumb } from "../../../../components";
import { fixedFunding, formatRupiah } from "../../../../utils/helper";
import { uuidv4 } from "../../../../utils/helper";
import { useDispatch, useSelector } from "react-redux";

import notifAlert, {
  AlertValidasiBos,
} from "../../../../components/NotifAlert";
import {
  ButtonDeleteAll,
  ButtonLog,
  ButtonPlus,
  ButtonTableDelete,
  ButtonTableEdit,
  ButtonTambah,
} from "../../../../components/Button";
import { NotifMessage } from "../../../../components/NotifMessage";
import InputSearch from "../../../../components/InputSearch";
import { setStore } from "../../../../redux/actions";
import {
  // changeLockedStatus,
  deleteAllTrasactionRencanaDefinitif,
  deleteRencanaPedapatanDefinitif,
  editRencanaPendapatanDefinitif,
  importRencanaPendapatanDefinitif,
  postRencanaPendapatanDefinitif,
} from "../../../../services/v2/planningservice/rencanapendapatandefinitif";
import { getPlanningAll } from "../../../../services/v2/planningservice";
import ModalDeleteAllTransaksi from "../../../../components/Modal/ModalDeleteAllTransaksi";
import { postCommentDeleteAll } from "../../../../services/v2/notificationservice/comment";
import ModalFormPendapatan from "./Component/ModalFormPendapatan";

import ModalDokumenBos from "./Component/ModalDokumenBos";

const { Text } = Typography;
export const baseURL: string =
  window.REACT_APP_API_URL ||
  // "https://api-gateway.erkam-v2.kemenag.go.id/api";
  "https://staging-api-gateway.erkam-v2.kemenag.go.id/api";
export const publicURL: string =
  window.REACT_APP_PUBLIC_URL ||
  "https://staging-frontend.erkam-v2.kemenag.go.id/";

const PaguDefPendapatan = () => {
  const itemBreadcrumb = [
    { path: "/", breadcrumbName: "Home" },
    { path: "/", breadcrumbName: "Rencana" },
    { path: "/", breadcrumbName: "Pagu Definitif" },
    { path: "/", breadcrumbName: "Pendapatan" },
  ];

  const route = useHistory();
  const dispatch = useDispatch();
  const auths = useSelector((state: any) => state.auth);
  const store = useSelector((state: any) => state.store);
  const auth = auths?.data || null;
  const kodeRole = auth?.kode_role;
  const storeSumberDana = store.sumberDana || [];
  const tmpRencanaPendapatanDefinitif = store.rencanaPendapatanDefinitif || [];
  const tmpRencanaTanggal = store.rencanaTanggal || [];
  const tmpRencanaTanggalBkba = store.rencanaTanggalBkba || [];
  const realisasiPendapatanHeader = store.realisasiPendapatanHeader || null;
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const [id, setID] = useState<any>(null);
  const [dataSumber, setDataSumber] = useState<any>(null);
  const [dataEdit, setDataEdit] = useState<any>(null);
  const [title, setTitle] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [openModalUpload, setOpenModalUpload] = useState(false);
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [search, setSearch] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const [btnAction, setBtnAction] = useState(false);

  const tmpFilterSumberDana = tmpRencanaPendapatanDefinitif
    .filter((item: any) => !item.id.includes(id))
    .map((item: any) => item.kode_sumber_dana);

  /** Filter Search */
  let dataTable: any = search
    ? tmpRencanaPendapatanDefinitif.filter((item: any) => {
        const val = search.toLowerCase();
        return (
          (item.nama_sumber_dana !== null &&
            (item.nama_sumber_dana || "")
              .toString()
              .toLowerCase()
              .includes(val)) ||
          (item.keterangan !== null &&
            (item.keterangan || "").toString().toLowerCase().includes(val))
        );
      })
    : tmpRencanaPendapatanDefinitif;
  let totalDataTable = dataTable.length;
  /**
   * Filter Pagination
   */
  dataTable = dataTable.filter((v, i) => {
    let start = pageSize * (page - 1);
    let end = start + pageSize;

    return i >= start && i < end;
  });

  /**
   * Filter Sumber Dana By status Madrasah
   */
  const tmpSumberDana =
    auth?.madrasah?.status === "n"
      ? storeSumberDana.filter((item: any) => item.madrasah_n === "1")
      : auth?.madrasah?.status === "ra"
      ? storeSumberDana.filter((item: any) => item.ra === "1")
      : storeSumberDana.filter((item: any) => item.madrasah_s === "1");

  /**
   * Data untuk tabel children pertama
   */
  let columns: any = [
    {
      title: "Tahun",
      dataIndex: "tahun",
      key: "tahun",
      onFilter: (value, record) => record.tahun.indexOf(value) === 0,
      sorter: (a, b) => a.tahun - b.tahun,
    },
    {
      title: "Sumber Dana",
      key: "nama_sumber_dana",
      dataIndex: "nama_sumber_dana",
      onFilter: (value, record) => record.nama_sumber_dana.indexOf(value) === 0,
      sorter: (a, b) => a.nama_sumber_dana.localeCompare(b.nama_sumber_dana),
    },
    {
      title: "Jumlah",
      key: "jumlah",
      dataIndex: "jumlah",
      align: "right",
      onFilter: (value, record) => record.jumlah.indexOf(value) === 0,
      sorter: (a, b) => a.jumlah - b.jumlah,
      render: (jumlah) => formatRupiah(jumlah),
    },
    {
      title: "Keterangan",
      key: "keterangan",
      dataIndex: "keterangan",
    },
  ];

  if (kodeRole === `kepala_madrasah`) {
    let listBos = ["apbn_bos_semester_1", "apbn_bos_semester_2"];
    columns.push({
      title: "Aksi",
      key: "aksi",
      render: (record) => (
        <Space>
          <ButtonTableEdit
            onClick={() => handleEdit(record)}
            disabled={
              btnAction || fixedFunding.includes(record.kode_sumber_dana)
            }
          />
          <ButtonTableDelete
            disabled={
              btnAction || fixedFunding.includes(record.kode_sumber_dana)
            }
            onClick={() => {
              Modal.confirm({
                title: "Perhatian",
                content: "Yakin Hapus Data?",
                onOk() {
                  return handleDelete(record);
                },
                onCancel() {},
                okText: "Hapus",
                cancelText: "Batal",
                okType: "danger",
                centered: true,
                wrapClassName: "erkam-custom-modal",
              });
            }}
          />
          {listBos.includes(record.kode_sumber_dana) && (
            <Button
              // disabled={
              //   spesialBOSPengajuan().includes(auth?.madrasah?.nsm)
              //     ? false
              //     : record?.kode_sumber_dana === "apbn_bos_semester_2"
              //     ? false
              //     : true
              // }
              icon={<UploadOutlined />}
              onClick={() => handleUpload(record)}>
              Upload Dokumen BOS
            </Button>
          )}
        </Space>
      ),
    });
  }

  const handleTambah = () => {
    setOpenModal(true);
    setTitle("Tambah");
    setID(uuidv4());
    setDataEdit(null);
  };

  const handleUpload = (record: any) => {
    setOpenModalUpload(true);
    setID(record.id);
    setDataSumber(record);
    // getPortalBos({ sumberDana: record.kode_sumber_dana });
    // getDataDokumen();
    // setStatusBos(null);
  };

  const handleEdit = (record: any) => {
    setOpenModal(true);
    setTitle("Edit");
    setID(record.id);
    setDataEdit(record);
  };

  const handleDelete = async (record: any) => {
    try {
      const result = await deleteRencanaPedapatanDefinitif(record.id);
      if (result) {
        notifAlert({
          type: "success",
          description: "Penghapusan Data Pendapatan Definitif Berhasil",
        });
      }

      const rencanaRekapSumberDanaBelanjaDefinitif = await getPlanningAll(
        "rencana-rekap-sumber-dana-belanja-definitif",
        { tahun: auth.isTahun },
      );
      const rencanaPendapatanDefinitif = await getPlanningAll(
        "rencana-pendapatan",
        { tahun: auth.isTahun },
      );
      const dataTmp = {
        rencanaPendapatanDefinitif,
        rencanaRekapSumberDanaBelanjaDefinitif,
      };
      setTimeout(() => {
        dispatch(setStore(dataTmp));
      }, 100);
    } catch (error) {
      console.log(error);
    }
  };

  const handleDeleteAll = async (values: any) => {
    try {
      const resultDelete = await deleteAllTrasactionRencanaDefinitif(auth);
      if (resultDelete) {
        let payload: any = {
          madrasahId: auth?.madrasah?.id,
          message: values?.keterangan,
          commentPage: "Planning",
          actionUser: "CLEAR_ALL",
        };
        await postCommentDeleteAll(payload);
        notifAlert({
          type: "success",
          description: "Penghapusan Semua Data Transaksi Definitif Berhasil",
        });
        getRefreshAllData();
      }
      setOpenModalDelete(false);
    } catch (error) {
      console.log(error);
    }
  };

  const confirmImport = () => {
    Modal.confirm({
      title: "Perhatian",
      content: informationImport,
      onOk() {
        return handleImport();
      },
      onCancel() {},
      okText: "Import",
      cancelText: "Batal",
      okType: "primary",
      centered: true,
      wrapClassName: "erkam-custom-modal",
    });
  };

  const handleImport = async () => {
    const tmpImport = await importRencanaPendapatanDefinitif(auth.isTahun);
    if (tmpImport.length > 0) {
      getRefreshAllData();
      Modal.success({
        title: "Sukses",
        content: "Proses import data dari indikatif berhasil di import",
        centered: true,
        wrapClassName: "erkam-custom-modal",
      });
    }
  };

  const getRefreshAllData = async () => {
    setLoading(true);
    try {
      const rencanaPendapatanDefinitif = await getPlanningAll(
        "rencana-pendapatan",
        { tahun: auth.isTahun },
      );

      const rencanaKegiatanDefinitif = await getPlanningAll(
        "rencana/kegiatan-definitif",
        { tahun: auth.isTahun },
      );

      const rencanaRincianKegiatanDefinitif = await getPlanningAll(
        "rencana-rincian-kegiatan-definitif",
        { tahun: auth.isTahun },
      );

      const rencanaRekapSumberDanaBelanjaDefinitif = await getPlanningAll(
        "rencana-rekap-sumber-dana-belanja-definitif",
        { tahun: auth.isTahun },
      );

      const dataTmp = {
        rencanaKegiatanDefinitif,
        rencanaRincianKegiatanDefinitif,
        rencanaPendapatanDefinitif,
        rencanaRekapSumberDanaBelanjaDefinitif,
      };
      setTimeout(() => {
        dispatch(setStore(dataTmp));
      }, 100);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const handleSave = async (values: any) => {
    setLoading(true);

    let namaSumber = tmpSumberDana.filter(
      (item: any) => item.kode === values.sumberDana,
    );
    let payload: any = {
      id: id,
      activated: "1",
      jumlah: values.nilaiPendapatan,
      kode_sumber_dana: values.sumberDana,
      nama_sumber_dana: namaSumber[0].nama,
      tahun: auth.isTahun,
      keterangan: values.keterangan,
    };
    if (title === "Tambah") {
      try {
        await postRencanaPendapatanDefinitif(payload);
        Modal.success({
          title: "Sukses",
          content: "Penambahan Data Pendapatan Definitif Berhasil",
          centered: true,
          wrapClassName: "erkam-custom-modal",
        });

        const rencanaRekapSumberDanaBelanjaDefinitif = await getPlanningAll(
          "rencana-rekap-sumber-dana-belanja-definitif",
          { tahun: auth.isTahun },
        );
        const rencanaPendapatanDefinitif = await getPlanningAll(
          "rencana-pendapatan",
          { tahun: auth.isTahun },
        );
        const dataTmp = {
          rencanaPendapatanDefinitif,
          rencanaRekapSumberDanaBelanjaDefinitif,
        };
        setTimeout(() => {
          dispatch(setStore(dataTmp));
        }, 100);
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        await editRencanaPendapatanDefinitif(payload);

        Modal.success({
          title: "Sukses",
          content: "Penyimpanan Data Pendapatan Definitif Berhasil",
          centered: true,
          wrapClassName: "erkam-custom-modal",
        });
        const rencanaRekapSumberDanaBelanjaDefinitif = await getPlanningAll(
          "rencana-rekap-sumber-dana-belanja-definitif",
          { tahun: auth.isTahun },
        );
        const rencanaPendapatanDefinitif = await getPlanningAll(
          "rencana-pendapatan",
          { tahun: auth.isTahun },
        );
        const dataTmp = {
          rencanaPendapatanDefinitif,
          rencanaRekapSumberDanaBelanjaDefinitif,
        };
        setTimeout(() => {
          dispatch(setStore(dataTmp));
        }, 100);
      } catch (error) {
        console.log(error);
      }
    }
    setOpenModal(false);
    setLoading(false);
  };

  return (
    <>
      <BreadCrumb
        routes={itemBreadcrumb}
        title="Pendapatan Definitif"
        extra={
          kodeRole === `kepala_madrasah` && [
            <ButtonDeleteAll
              disabled={btnAction}
              // style={{ background: "#f44436", color: "white", borderRadius: 4 }}

              key="delAllTransaction"
              danger
              onClick={() => setOpenModalDelete(true)}
              title="Hapus Semua Data"
            />,
          ]
        }
      />
      <AlertValidasiBos dataAuth={auth} dataYear={auth.isTahun} />
      <div className="m-5 p-5 bg-white shadow-sm">
        <div className="mb-4">
          <NotifMessage
            tanggalErkam={tmpRencanaTanggal}
            tanggalBkba={tmpRencanaTanggalBkba}
            module="pendapatanDefinitif"
            setAction={(action: boolean) => setBtnAction(action)}
          />
        </div>
        <div className="mb-4 flex justify-end">
          <div className="mr-4">
            <Space wrap>
              {kodeRole === `kepala_madrasah` && (
                <>
                  <ButtonTambah onClick={handleTambah} disabled={btnAction} />
                  <ButtonPlus
                    title="Import Indikatif"
                    onClick={confirmImport}
                    type="primary"
                    disabled={btnAction}
                  />
                </>
              )}
              <ButtonLog
                onClick={() => route.push("/rencana/definitif/pendapatan/logs")}
              />
            </Space>
          </div>
          <InputSearch
            className="w-3/4"
            onChange={(e: any) => setSearch(e.currentTarget.value)}
          />
        </div>
        <div className="w-full">
          <Table
            rowKey={(record) => record.id}
            columns={columns}
            dataSource={dataTable}
            bordered
            pagination={{
              total: totalDataTable,
              position: ["bottomRight"],
              defaultPageSize: pageSize,
              defaultCurrent: page,
              showTotal: (total) => `Total ${total} items`,
              onChange(page, pageSize) {
                setPage(page);
                setPageSize(pageSize);
              },
            }}
          />
        </div>
      </div>
      <ModalFormPendapatan
        openModal={openModal}
        title={title}
        editData={dataEdit}
        loading={loading}
        tmpSumberDana={tmpSumberDana}
        tmpFilterSumberDana={tmpFilterSumberDana}
        auth={auth}
        hanldeClose={() => setOpenModal(false)}
        handleSave={(e: any) => handleSave(e)}
      />

      <ModalDokumenBos
        auth={auth}
        openModal={openModalUpload}
        dataSumber={dataSumber}
        hanldeClose={() => {
          setOpenModalUpload(false);
          setDataSumber(null);
        }}
        realisasiPendapatanHeader={realisasiPendapatanHeader}
      />

      <ModalDeleteAllTransaksi
        openModal={openModalDelete}
        informationMessage="Anda yakin akan menghapus seluruh data transaksi rencana pagu definitif?"
        module="Rencana"
        onClose={() => setOpenModalDelete(false)}
        onDelete={handleDeleteAll}
      />
    </>
  );
};
const informationImport = (
  <>
    <p>
      Dengan menyetujui dan klik tombol <Text strong>Import</Text>, maka semua{" "}
      <Text strong>Rencana Indikatif</Text> : <br />
      <Badge status="default" /> Pendapatan Indikatif <br />
      <Badge status="default" /> Belanja Indikatif <br />
      <Badge status="default" /> Rincian Belanja Indikatif <br />
      akan di duplikasi kemudian di import ke dalam{" "}
      <Text strong>Rencana Definitif</Text>
    </p>
  </>
);
export default PaguDefPendapatan;
