/** @format */

export const dataDokumen = () => [
  { kodeProvinsi: 11, totalMadrasah: 1359, totalSelesai: 0 },
  { kodeProvinsi: 12, totalMadrasah: 2898, totalSelesai: 10 },
  { kodeProvinsi: 13, totalMadrasah: 803, totalSelesai: 120 },
  { kodeProvinsi: 14, totalMadrasah: 1601, totalSelesai: 120 },
  { kodeProvinsi: 15, totalMadrasah: 948, totalSelesai: 220 },
  { kodeProvinsi: 16, totalMadrasah: 1472, totalSelesai: 20 },
  { kodeProvinsi: 17, totalMadrasah: 321, totalSelesai: 20 },
  { kodeProvinsi: 18, totalMadrasah: 1806, totalSelesai: 25 },
  { kodeProvinsi: 19, totalMadrasah: 135, totalSelesai: 21 },
  { kodeProvinsi: 21, totalMadrasah: 178, totalSelesai: 21 },
  { kodeProvinsi: 31, totalMadrasah: 834, totalSelesai: 21 },
];

export default dataDokumen;
