/** @format */

import api from "../../../utils/api";
import { realizationService } from "../constant";
const basePath = `${realizationService}/realizations`;

export const getRealizationAll = async (
  ref: any,
  params: any,
): Promise<any> => {
  try {
    let response: any = await api.get<any>(`${basePath}/${ref}`, {
      params,
    });
    return response.data.return;
  } catch (error) {
    console.log(error);
  }
};

export const postUploadNotaRealisasi = async (params: any) => {
  try {
    let response: any = await api.post<any>(
      `${basePath}/upload/nota-realisasi`,
      params,
      {
        headers: { "Content-Type": "multipart/form-data" },
      },
    );
    return response.data.return;
  } catch (error) {
    console.log(error);
  }
};

export const deleteUploadNotaRealisasi = async (id: any) => {
  try {
    let response: any = await api.delete<any>(
      `${basePath}/upload/nota-realisasi/${id}`,
    );
    return response.data.return;
  } catch (error) {
    console.log(error);
  }
};
