/** @format */
import React, { useState, useEffect } from "react";
import { Modal, Button, Table } from "antd";
import moment from "moment";
import ExcelJS from "exceljs";
import "moment/locale/id";
import * as FileSaver from "file-saver";
import { formatRupiah } from "../../utils/helper";
import notifAlert from "../NotifAlert";
import { UploadPriview } from "../Uploads/UploadPriview";

type generateModal = {
  openModal: boolean;
  dataFile: any;
  dataMadrasah: any;
  onClose: () => void;
};
const ModalRekonsiliasi = ({
  openModal,
  dataFile,
  dataMadrasah,
  onClose,
}: generateModal) => {
  const [fileList, setFileList] = useState<any>([]);
  useEffect(() => {
    setFileList(dataFile);
  }, [dataFile]);

  let columns: any = [
    {
      title: "Tanggal",
      key: "tanggal",
      dataIndex: "tangalInput",
      width: 250,
      render: (tangalInput) =>
        (tangalInput &&
          tangalInput !== "-" &&
          moment(tangalInput).format("dddd, YYYY-MMM-DD HH:mm:ss")) ||
        "-",
    },
    {
      title: "Tanggal Realisasi",
      key: "tanggal",
      dataIndex: "tglTrx",
      width: 250,
      render: (tglTrx) =>
        tglTrx && moment(tglTrx).format("dddd, YYYY-MMM-DD HH:mm:ss"),
    },
    {
      title: "No. Kode",
      key: "noKode",
      dataIndex: "noKode",
      width: 280,
    },
    {
      title: "No. Bukti",
      key: "noBukti",
      dataIndex: "noBukti",
      width: 180,
    },
    {
      title: "Dokumen SPJ",
      key: "dokumen",
      dataIndex: "fileList",
      width: 180,
      render: (fileList) => {
        let fileTmp: any = [];
        let listTmp: any = [];
        if (fileList.length) {
          // eslint-disable-next-line array-callback-return
          fileList.map((item: any) => {
            fileTmp.push({
              status: "done",
              uid: item.noReferensi,
              name: item.fileName,
              url: item.fileUrl,
              thumbUrl: item.fileUrl,
            });
            listTmp.push({
              id: item.noReferensi,
              fileType: item.fileType,
              ...item,
            });
          });
        }
        return <UploadPriview fileList={fileTmp} tmpFile={listTmp} />;
      },
    },

    {
      title: "Uraian",
      key: "uraian",
      dataIndex: "uraian",
      width: 400,
    },
    {
      title: "Penerimaan (Debit)",
      key: "debit",
      dataIndex: "debit",
      align: "center",
      width: 250,
      render: (debit) => (
        <div className="text-right">{formatRupiah(debit)}</div>
      ),
    },
    {
      title: "Pengeluaran (Kredit)",
      key: "credit",
      dataIndex: "credit",
      align: "center",
      width: 250,
      render: (credit) => (
        <div className="text-right">{formatRupiah(credit)}</div>
      ),
    },
    {
      title: "Saldo",
      key: "saldo",
      dataIndex: "saldo",
      align: "center",
      width: 250,
      render: (saldo) => (
        <div className="text-right">{formatRupiah(saldo)}</div>
      ),
    },
  ];

  const handleExport = async () => {
    var ExcelJSWorkbook = new ExcelJS.Workbook();
    var worksheet = ExcelJSWorkbook.addWorksheet("Data");
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";

    worksheet.mergeCells("A1:I1");

    worksheet.getColumn("A").width = 5;
    worksheet.getColumn("B").width = 14;
    worksheet.getColumn("C").width = 40;
    worksheet.getColumn("D").width = 30;
    worksheet.getColumn("E").width = 30;
    worksheet.getColumn("F").width = 30;
    worksheet.getColumn("G").width = 30;
    worksheet.getColumn("H").width = 40;
    worksheet.getColumn("I").width = 50;
    worksheet.getColumn("J").width = 50;

    const headerCell = worksheet.getCell("A1");
    headerCell.font = {
      size: 11,
      underline: true,
      bold: true,
    };
    headerCell.alignment = { vertical: "middle", horizontal: "center" };
    headerCell.value = `NSM ; ${dataMadrasah?.nsm}`;
    const headerCell2 = worksheet.getCell("A2");
    headerCell2.font = {
      size: 11,
      underline: true,
      bold: true,
    };
    headerCell2.alignment = { vertical: "middle", horizontal: "center" };
    headerCell2.value = `NAMA ; ${dataMadrasah?.namaMadrasah}`;
    const headerCell3 = worksheet.getCell("A3");
    headerCell3.font = {
      size: 11,
      underline: true,
      bold: true,
    };
    headerCell3.alignment = { vertical: "middle", horizontal: "center" };
    headerCell3.value = `SUMBER DANA ; ${dataMadrasah?.namaSumberDana}`;
    const headerCell4 = worksheet.getCell("A4");
    headerCell4.font = {
      size: 11,
      underline: true,
      bold: true,
    };
    headerCell4.alignment = { vertical: "middle", horizontal: "center" };
    headerCell4.value = `TAHUN ; ${dataMadrasah?.tahun}`;

    let dataTmp: any = [];
    fileList.map((e, i) => {
      return dataTmp.push([
        i + 1,
        e.tglTrx && moment(e.tglTrx).format("dddd, YYYY-MMM-DD HH:mm:ss"),
        e.noKode,
        e.noBukti,
        e.uraian,
        formatRupiah(e.debit),
        formatRupiah(e.credit),
        formatRupiah(e.saldo),
        e?.fileList?.length && e?.fileList.map((item) => item?.fileUrl),
      ]);
    });

    worksheet.addTable({
      name: "Table",
      ref: "A6",
      headerRow: true,
      style: {
        showRowStripes: true,
      },
      columns: [
        { name: "No" },
        { name: "Tanggal Realisasi" },
        { name: "No. Kode" },
        { name: "No. Bukti" },
        { name: "Uraian" },
        { name: "Penerimaan (Debit)" },
        { name: "Pengeluaran (Kredit)" },
        { name: "Saldo" },
        { name: "Dokumen SPJ" },
      ],
      rows: dataTmp,
    });

    const excelBuffer = await ExcelJSWorkbook.xlsx.writeBuffer();
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, `Laporan-BKU-SPJ-Sumber-Dana${fileExtension}`);
    notifAlert({
      type: "success",
      description: "Data berhasil di export",
    });
  };
  return (
    <>
      <Modal
        open={openModal}
        width={1300}
        title={`Laporan BKU dan SPJ Madrasah`}
        onCancel={onClose}
        footer={[
          <Button onClick={onClose}>Kembali</Button>,
          <Button key="submit" type="primary" onClick={handleExport}>
            Export
          </Button>,
        ]}>
        <div className="mb-4 pr-2 text-right">
          <Button key="submit" type="primary" onClick={handleExport}>
            Export
          </Button>
        </div>

        <Table
          scroll={{ x: "1380" }}
          rowClassName="editable-row"
          columns={columns}
          dataSource={fileList}
          bordered
        />
      </Modal>
    </>
  );
};
export default ModalRekonsiliasi;
