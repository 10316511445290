/** @format */

import React, { useState } from "react";
import { BreadCrumb } from "../../../../components";
import { useHistory } from "react-router-dom";
import {
  addFormData,
  formatRupiah,
  statusValidationAction,
  uuidv4,
} from "../../../../utils/helper";
import moment from "moment";
import "moment/locale/id";
import { Table, Modal, Badge, Space, Form } from "antd";
import {
  ButtonLog,
  ButtonTableApproval,
  ButtonTableDelete,
  ButtonTableDetail,
  ButtonTableEdit,
  ButtonTableEditBlue,
  ButtonTambah,
} from "../../../../components/Button";
import InputSearch from "../../../../components/InputSearch";
import { useDispatch, useSelector } from "react-redux";
import HeaderRealisasi from "../../../../components/HeaderRealisasi";
import {
  deleteRealisasiPengembalianDana,
  editRealisasiPengembalianDana,
  postRealisasiPengembalianDana,
} from "../../../../services/v2/realizationservice/pengembaliandanaservices";
import notifAlert from "../../../../components/NotifAlert";
import { setStore } from "../../../../redux/actions";
import ModalApprovalPengembalianDana from "../Component/ModalApprovalPengembalianDana";
import ModalTanggalRealisasiPengembalianDana from "../Component/ModalTanggalRealisasi";
import ModalPengembalianDana from "../Component/ModalPengembalianDana";
import { getReportAll } from "../../../../services/v2/reportservice";
import {
  getRealizationAll,
  postUploadNotaRealisasi,
} from "../../../../services/v2/realizationservice";

const PengembalianDana = () => {
  const route = useHistory();
  const itemBreadcrumb = [
    { path: "/", breadcrumbName: "Home" },
    { path: "/", breadcrumbName: "Realisasi" },
    { path: "/", breadcrumbName: "Pengembalian Dana" },
    { path: "/", breadcrumbName: "List" },
  ];
  const dispatch = useDispatch();
  const store = useSelector((state: any) => state.store);
  const auths = useSelector((state: any) => state.auth);
  const auth = auths?.data;
  const kodeRole = auth?.kode_role;
  const tmpRealisasiPengembalianDana = store.realisasiPengembalianDana || [];
  const tmpMetodePembayaran = store.metodePembayaran || [];
  const tmpRekeningBelanja = store.rekeningBelanja || [];
  const tmpBank = store.bank || [];
  const tmpTipeKas = store.tipeKas || [];
  const tmpPenerima = store.penerimaRekening || [];
  const listSumberDana = store?.rencanaPendapatanDefinitif || [];
  const [search, setSearch] = useState<any>(null);
  const [formTanggal] = Form.useForm();
  const [openModal, setOpenModal] = useState(false);
  const [openModalApproval, setOpenModalApproval] = useState(false);
  const [openModalTanggal, setOpenModalTanggal] = useState(false);
  const [title, setTitle] = useState("");
  const [dataEdit, setDataEdit] = useState<any>(null);
  const [id, setID] = useState<any>(null);
  const [dataFile, setDataFile] = useState<any>([]);
  const [fileUpload, setFileUpload] = useState<any>([]);
  const [confirmLoading, setConfirmLoading] = useState<any>(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const statusValidasi = statusValidationAction();
  /** Filter Search */
  let dataTable: any = search
    ? tmpRealisasiPengembalianDana.filter((item: any) => {
        const val = search.toLowerCase();
        return (
          (item.pendapatan.rencanaPendapatanName !== null &&
            (item.pendapatan.rencanaPendapatanName || "")
              .toString()
              .toLowerCase()
              .includes(val)) ||
          (item.keterangan !== null &&
            (item.keterangan || "").toString().toLowerCase().includes(val)) ||
          (item.noNotaFormat !== null &&
            (item.noNotaFormat || "").toString().toLowerCase().includes(val))
        );
      })
    : tmpRealisasiPengembalianDana;
  let totalDataTable = dataTable.length;

  /**
   * Filter Pagination
   */
  dataTable = dataTable.filter((v, i) => {
    let start = pageSize * (page - 1);
    let end = start + pageSize;

    return i >= start && i < end;
  });

  /**
   * Data untuk tabel children pertama
   */
  let columns: any = [
    {
      title: "No Nota",
      dataIndex: "noNotaFormat",
      key: "noNotaFormat",
      width: 320,
      onFilter: (value, record) => record.noNotaFormat.indexOf(value) === 0,
      sorter: (a, b) => a.noNotaFormat.localeCompare(b.noNotaFormat),
    },
    {
      title: "Sumber Dana",
      key: "kodeSumberDana",
      dataIndex: "kodeSumberDana",
      width: 150,
      render: (kodeSumberDana) => {
        let namaSumberDana: any =
          listSumberDana?.find(
            (item: any) =>
              item.kode_sumber_dana === kodeSumberDana?.toLowerCase(),
          ) || null;
        return namaSumberDana?.nama_sumber_dana || "";
      },
    },
    {
      title: "Tipe Kas",
      dataIndex: "kodeTipeKas",
      key: "kodeTipeKas",
      width: 150,
      onFilter: (value, record) => record.kodeTipeKas.indexOf(value) === 0,
      sorter: (a, b) => a.kodeTipeKas.localeCompare(b.kodeTipeKas),
      render: (kodeTipeKas) =>
        (tmpTipeKas.length &&
          tmpTipeKas.find((item: any) => item.kode === kodeTipeKas)?.nama) ||
        "-",
    },
    {
      title: "Rekening Bank",
      key: "rekeningBank",
      dataIndex: "rekeningBankId",
      width: 180,
      render: (rekeningBankId) => {
        let dataBank: any =
          (tmpRekeningBelanja.length &&
            tmpRekeningBelanja.find((item) => item.id === rekeningBankId)) ||
          null;
        return (
          (dataBank && `${dataBank?.nama_bank} (${dataBank?.no_rekening})`) ||
          "-"
        );
      },
    },

    {
      title: "Penerima",
      dataIndex: "penerimaId",
      key: "penerimaId",
      width: 150,
      render: (penerimaId) =>
        (tmpPenerima.length &&
          tmpPenerima.find((item) => item.id === penerimaId)?.nama) ||
        "-",
    },
    {
      title: "Metode Pembayaran",
      key: "kodeMetodePembayaran",
      dataIndex: "kodeMetodePembayaran",
      width: 150,
      render: (kodeMetodePembayaran) =>
        (tmpMetodePembayaran.length &&
          tmpMetodePembayaran.find((item) => item.kode === kodeMetodePembayaran)
            ?.nama) ||
        "-",
    },
    {
      title: "Rekening Bank Penerima",
      key: "penerimaRekeningId",
      dataIndex: "penerimaRekeningId",
      width: 180,
      render: (penerimaRekeningId, record) => {
        let rekeningPenerima: any = penerimaRekeningId || null;
        let dataPenerima: any =
          (tmpPenerima.length &&
            tmpPenerima.find((item) => item.id === record?.penerimaId)
              .penerimaRekenings) ||
          [];
        let dataBankPenerima: any =
          (dataPenerima.length &&
            dataPenerima.find((item) => item.id === penerimaRekeningId)) ||
          null;
        let dataBank: any =
          (dataBankPenerima &&
            tmpBank.find(
              (item) => item.kode === dataBankPenerima?.kode_bank,
            )) ||
          null;
        return (
          (rekeningPenerima &&
            `${dataBank?.nama} (${dataBankPenerima?.no_rekening})`) ||
          "-"
        );
      },
    },
    {
      title: "Keterangan",
      dataIndex: "keterangan",
      key: "keterangan",
      width: 150,
    },
    {
      title: "Jumlah",
      dataIndex: "grandTotal",
      key: "grandTotal",
      width: 180,
      render: (grandTotal) => formatRupiah(grandTotal),
    },
    {
      title: "Tanggal Nota",
      dataIndex: "tanggalNota",
      key: "tanggalNota",
      width: 150,
      render: (tanggalNota) =>
        tanggalNota
          ? moment(tanggalNota).format("dddd,DD MMM YYYY HH:mm:ss")
          : "-",
    },
    {
      title: "Tanggal Realisasi",
      dataIndex: "tanggalRealisasi",
      key: "tanggalRealisasi",
      width: 150,
      render: (tanggalRealisasi) =>
        tanggalRealisasi
          ? moment(tanggalRealisasi).format("dddd,DD MMM YYYY HH:mm:ss")
          : "-",
    },
    {
      title: "No Referensi",
      dataIndex: "realisasiNoReferensi",
      key: "realisasiNoReferensi",
      width: 150,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: 150,
      fixed: "right",
      render: (status) => (
        <>
          <Badge
            status={
              (status === "Selesai" && "success") ||
              (status === "Menunggu Tanggal Realisasi" && "processing") ||
              (status === "Menunggu" && "warning") ||
              (status === "Tidak Disetujui" && "error") ||
              "default"
            }
            text={status}
          />
        </>
      ),
    },
    {
      title: "Aksi",
      key: "aksi",
      width: 180,
      align: "center",
      fixed: "right",
      render: (record) => (
        <Space>
          <ButtonTableEditBlue
            tooltips="Lihat Detail"
            onClick={() => handleDetailApprove(record, "Detail")}
          />
          {kodeRole === "kepala_madrasah" && (
            <ButtonTableApproval
              tooltips="Approval"
              onClick={() => handleDetailApprove(record, "Approval")}
            />
          )}
          {(kodeRole === "bendahara_madrasah" ||
            kodeRole === "staf_madrasah") && (
            <>
              {record.status === "Menunggu Tanggal Realisasi" && (
                <ButtonTableDetail
                  tooltips="Set Tanggal Realisasi"
                  onClick={() => handleTanggalRealisasi(record)}
                />
              )}
              <ButtonTableEdit
                disabled={!statusValidasi.includes(record.status)}
                tooltips={
                  !statusValidasi.includes(record.status)
                    ? "Data harus di Tolak dahulu oleh kepala madrasah"
                    : "Ubah"
                }
                onClick={() => handleEdit(record)}
              />
              <ButtonTableDelete
                disabled={!statusValidasi.includes(record.status)}
                tooltips={
                  !statusValidasi.includes(record.status)
                    ? "Data harus di Tolak dahulu oleh kepala madrasah"
                    : "Hapus"
                }
                onClick={() => {
                  Modal.confirm({
                    title: "Perhatian",
                    content: "Yakin Hapus Data?",
                    onOk() {
                      return handleDelete(record);
                    },
                    onCancel() {},
                    okText: "Hapus",
                    cancelText: "Batal",
                    okType: "danger",
                  });
                }}
              />
            </>
          )}
        </Space>
      ),
    },
  ];

  const handleTanggalRealisasi = (record: any) => {
    setOpenModalTanggal(true);
    formTanggal.setFieldsValue({
      tanggalRealisasi: record.tanggalRealisasi
        ? moment(record.tanggalRealisasi)
        : null,
      noReferensi: record.realisasiNoReferensi,
    });
    setTitle("Set Tanggal Realisasi");
    getFileList(record);
    setDataEdit(record);
    setID(record.id);
  };

  const handleDetailApprove = (record: any, action: any) => {
    setTitle(action);
    getFileList(record);
    setOpenModalApproval(true);
    setDataEdit(record);
    setID(record.id);
  };

  const handleSaveApproval = async (action: any) => {
    try {
      const payload = {
        keterangan: "",
      };
      const res = await editRealisasiPengembalianDana(payload, id, action);
      if (res) {
        const realisasiPengembalianDana =
          (await getRealizationAll("pengembalian-dana", {
            tahun: auths.isTahun,
          })) || [];

        const realisasiPendapatanHeader = await getReportAll(
          "pendapatan/header",
          {
            tahun: auths.isTahun,
          },
        );
        notifAlert({
          type: "success",
          description: "Data Berhasil di simpan",
        });
        setTimeout(() => {
          dispatch(
            setStore({ realisasiPengembalianDana, realisasiPendapatanHeader }),
          );
        }, 100);
      }
    } catch (error) {
      console.log(error);
    }
    setOpenModalApproval(false);
  };

  const handleSaveTanggal = async (values: any) => {
    try {
      const payload = {
        realisasi_no_referensi: values.noReferensi,
        tanggal_realisasi: moment(values.tanggalRealisasi).format(
          "YYYY-MM-DD HH:mm:ss",
        ),
      };
      const res = await editRealisasiPengembalianDana(
        payload,
        id,
        "/realisasi",
      );
      if (res) {
        const realisasiPengembalianDana =
          (await getRealizationAll("pengembalian-dana", {
            tahun: auths.isTahun,
          })) || [];
        const realisasiPendapatanHeader = await getReportAll(
          "pendapatan/header",
          {
            tahun: auths.isTahun,
          },
        );
        notifAlert({
          type: "success",
          description: "Data Berhasil di simpan",
        });
        setTimeout(() => {
          dispatch(
            setStore({ realisasiPengembalianDana, realisasiPendapatanHeader }),
          );
        }, 100);
      }
    } catch (error) {
      console.log(error);
    }
    setOpenModalTanggal(false);
  };

  const handleEdit = (record: any) => {
    setID(record.id);
    setOpenModal(true);
    setTitle("Edit");
    getFileList(record);
    setDataEdit(record);
  };

  const getFileList = async (record: any) => {
    let result: any = [];
    result =
      (await getRealizationAll("upload/nota-realisasi", {
        noReferensi: record.id,
        tahun: auth?.isTahun,
        topik: "realisasiPengembalianDana",
        nsm: auth.madrasah.nsm,
      })) || [];
    setDataFile(result);
  };

  const handleDelete = async (record: any) => {
    try {
      await deleteRealisasiPengembalianDana(record.id);
      const realisasiPengembalianDana =
        (await getRealizationAll("pengembalian-dana", {
          tahun: auths.isTahun,
        })) || [];
      const realisasiPendapatanHeader = await getReportAll(
        "pendapatan/header",
        {
          tahun: auths.isTahun,
        },
      );
      notifAlert({
        type: "success",
        description: "Data Berhasil di hapus",
      });
      setTimeout(() => {
        dispatch(
          setStore({ realisasiPengembalianDana, realisasiPendapatanHeader }),
        );
      }, 100);
    } catch (error) {
      console.log(error);
    }
  };

  const handleTambah = () => {
    setTitle("Tambah");
    setID(uuidv4());
    setDataFile([]);
    setFileUpload([]);
    setOpenModal(true);
    setDataEdit(null);
  };

  const handleSave = async (values: any) => {
    setConfirmLoading(true);
    const payload = {
      id: id,
      tahun: auths.isTahun,
      ...values,
    };

    if (title === "Tambah") {
      try {
        const res = await postRealisasiPengembalianDana(payload);
        uploadNotaRealisasi(res, fileUpload, title);
        const realisasiPengembalianDana =
          (await getRealizationAll("pengembalian-dana", {
            tahun: auths.isTahun,
          })) || [];
        const realisasiPendapatanHeader = await getReportAll(
          "pendapatan/header",
          {
            tahun: auths.isTahun,
          },
        );

        notifAlert({
          type: "success",
          description: "Data Berhasil di simpan",
        });
        setTimeout(() => {
          dispatch(
            setStore({ realisasiPengembalianDana, realisasiPendapatanHeader }),
          );
        }, 100);
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        const res = await editRealisasiPengembalianDana(payload, id, "");
        uploadNotaRealisasi(res, fileUpload, title);
        const realisasiPengembalianDana =
          (await getRealizationAll("pengembalian-dana", {
            tahun: auths.isTahun,
          })) || [];
        const realisasiPendapatanHeader = await getReportAll(
          "pendapatan/header",
          {
            tahun: auths.isTahun,
          },
        );
        notifAlert({
          type: "success",
          description: "Data Berhasil di simpan",
        });
        setTimeout(() => {
          dispatch(
            setStore({ realisasiPengembalianDana, realisasiPendapatanHeader }),
          );
        }, 100);
      } catch (error) {
        console.log(error);
      }
    }
    setOpenModal(false);
    setConfirmLoading(false);
  };

  const uploadNotaRealisasi = async (res: any, fileUpload: any, title: any) => {
    if (title === "Tambah") {
      if (fileUpload.length) {
        let fileUploadTmp: any = [];
        fileUpload.map(async (item: any) => {
          fileUploadTmp.push(item.originFileObj);
        });
        let payload: any = {
          noReferensi: res.id,
          tahun: res.tahun,
          topik: "realisasiPengembalianDana",
          noNota: res.noNotaFormat,
          nsm: auth.madrasah?.nsm,
          files: fileUploadTmp,
        };
        let params: any = addFormData(payload);
        await postUploadNotaRealisasi(params);
      }
    } else {
      if (fileUpload.length) {
        let fileUploadTmp: any = [];
        fileUpload
          .filter((item) => item.status === "uploading")
          .map(async (item: any) => {
            fileUploadTmp.push(item.originFileObj);
          });
        let payload: any = {
          noReferensi: res.id,
          tahun: res.tahun,
          topik: "realisasiPengembalianDana",
          noNota: res.noNotaFormat,
          nsm: auth.madrasah?.nsm,
          files: fileUploadTmp,
        };
        let params: any = addFormData(payload);
        await postUploadNotaRealisasi(params);
      }
    }
  };

  const handleFileUpload = (values: any) => {
    setFileUpload(values);
  };

  return (
    <>
      <BreadCrumb routes={itemBreadcrumb} title="Realisasi Pengembalian Dana" />
      <HeaderRealisasi />
      <div className="m-5 p-5 bg-white shadow-md rounded">
        <div className="mb-4 flex justify-end">
          <div className="mr-4">
            <Space size={[8, 8]} wrap>
              {kodeRole !== "kepala_madrasah" && (
                <ButtonTambah onClick={handleTambah} />
              )}
              <ButtonLog
                onClick={() =>
                  route.push({
                    pathname: "logs",
                  })
                }
              />
            </Space>
          </div>
          <InputSearch
            className="w-3/4"
            onChange={(e: any) => setSearch(e.currentTarget.value)}
          />
        </div>
        <div className="w-full">
          <Table
            rowKey={(record) => record.id}
            columns={columns}
            dataSource={dataTable}
            scroll={{ x: "1300" }}
            // tableLayout="auto"
            bordered
            pagination={{
              total: totalDataTable,
              position: ["bottomRight"],
              defaultPageSize: pageSize,
              defaultCurrent: page,
              showTotal: (total) => `Total ${total} items`,
              onChange(page, pageSize) {
                setPage(page);
                setPageSize(pageSize);
              },
            }}
          />
        </div>
      </div>
      <ModalPengembalianDana
        openModal={openModal}
        title={title}
        data={dataEdit}
        handleClose={() => {
          setOpenModal(false);
          setDataEdit(null);
        }}
        confirmLoading={confirmLoading}
        files={dataFile}
        handleFileUpload={handleFileUpload}
        handleSave={handleSave}
        store={store}
      />
      <ModalApprovalPengembalianDana
        title={title}
        store={store}
        openModal={openModalApproval}
        data={dataEdit}
        files={dataFile}
        handleClose={() => setOpenModalApproval(false)}
        handleSave={handleSaveApproval}
      />
      <ModalTanggalRealisasiPengembalianDana
        openModal={openModalTanggal}
        title={title}
        form={formTanggal}
        data={dataEdit}
        files={dataFile}
        handleSave={handleSaveTanggal}
        handleClose={() => setOpenModalTanggal(false)}
        store={store}
      />
    </>
  );
};

export default PengembalianDana;
