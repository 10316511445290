/** @format */

import React, { useState } from "react";
import BreadCrumb from "../../../components/BreadCrumb";
import { useHistory } from "react-router-dom";
import { ExportToExcel } from "../../../components/Export/ExportToExcel";
import logo from "../../../assets/img/user.jpg";
import { EditOutlined, DownloadOutlined } from "@ant-design/icons";

import {
  Row,
  Col,
  Card,
  Avatar,
  Divider,
  Button,
  Table,
  Tabs,
  Descriptions,
} from "antd";
import { baseURL } from "../../../utils/api";
import { useDispatch, useSelector } from "react-redux";
import notifAlert from "../../../components/NotifAlert";
import InputSearch from "../../../components/InputSearch";
import { getReferenceAll } from "../../../services/v2/referenceservice";
import { setStore } from "../../../redux/actions";
import ModalInformation from "../../../components/Modal/ModalInformation";

const { TabPane } = Tabs;
/**
 *
 * @returns Tampilan menu profile madrasah
 */
const ProfilMadrasah = () => {
  const itemBreadcrumb = [
    { path: "/", breadcrumbName: "Home" },
    { path: "/", breadcrumbName: "Profil Madrasah" },
  ];

  const route = useHistory();
  const dispatch = useDispatch();
  const store = useSelector((state: any) => state.store);
  // const auths = useSelector((state: any) => state.auth);
  // const auth = auths?.data;
  const profile: any = store?.profile?.madrasah || null;
  // const madrasahProfile: any = store?.madrasahProfile || null;
  const madrasahAssets: any =
    (store?.madrasahProfile?.assets?.length &&
      store?.madrasahProfile?.assets[0]) ||
    null;
  const madrasahSiswa: any =
    (store?.madrasahProfile?.students?.length &&
      store?.madrasahProfile?.students[0]) ||
    null;
  const provinsi: any = store.provinsi || [];
  const kabkota: any = store.kabkota || [];
  const kecamatan: any = store.kecamatan || [];
  const tmpRole = store.role || [];
  const rekeningBelanja: any = store.rekeningBelanja || [];
  const [stafMadrasah, setStafMadrasah] = useState<any>([]);
  const [refKelurahan, setRefKelurahan] = useState<any>([]);
  const [activeTabKey, setActiveTabKey] = useState<string>("pm1");

  const getData = async () => {
    const tmpKelurahan = store.kelurahan || [];
    if (tmpKelurahan.length) {
      setRefKelurahan(tmpKelurahan);
    } else {
      const kelurahan = await getReferenceAll("kelurahan", { activated: 1 });
      setRefKelurahan(kelurahan || []);
      setTimeout(() => {
        dispatch(setStore({ kelurahan }));
      }, 100);
    }

    setStafMadrasah(store?.users || []);
  };

  React.useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const contentList: Record<string, React.ReactNode> = {
    pm1: (
      <div className="p-5">
        <div className="mb-4">
          <Row gutter={[16, 16]}>
            <Col
              className="gutter-row"
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 8 }}
              lg={{ span: 8 }}
              xl={{ span: 8 }}>
              <Card
                className="default w-full bg-green-500"
                title="Logo Madrasah"
                headStyle={{
                  background: "green",
                  textAlign: "center",
                  color: "#fff",
                  fontWeight: "bold",
                }}
                bodyStyle={{ background: "#d1fae5" }}>
                <div className="flex flex-col items-center justify-center">
                  <div className="mb-1">
                    <Avatar
                      shape={profile?.logo ? "square" : "circle"}
                      size={{
                        xs: 150,
                        sm: 150,
                        md: 200,
                        lg: 200,
                        xl: 200,
                        xxl: 200,
                      }}
                      src={
                        profile?.logo
                          ? `${baseURL}/v2/user-services/logo/` + profile?.logo
                          : logo
                      }
                    />
                  </div>
                  <Divider />
                  <Button
                    type="primary"
                    onClick={() => route.push("/profile-madrasah/edit")}
                    block>
                    <EditOutlined /> Edit Profil
                  </Button>
                </div>
              </Card>
            </Col>
            <Col
              className="gutter-row"
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 16 }}
              lg={{ span: 16 }}
              xl={{ span: 16 }}>
              <Card
                className="default w-full bg-green-500"
                title="Informasi Madrasah"
                headStyle={{
                  background: "green",
                  color: "#fff",
                  fontWeight: "bold",
                }}
                bodyStyle={{ background: "#d1fae5" }}>
                <Descriptions
                  labelStyle={{ fontWeight: "bold" }}
                  size="small"
                  layout="vertical"
                  column={{ xxl: 3, xl: 3, lg: 3, md: 3, sm: 1, xs: 1 }}>
                  <Descriptions.Item label="Nama Lembaga">
                    {profile?.nama}
                  </Descriptions.Item>
                  <Descriptions.Item label="Ketua Komite">
                    {profile?.nik_komite}
                    <br />
                    {profile?.nama_komite}
                  </Descriptions.Item>
                  <Descriptions.Item label="NSM">
                    {profile?.nsm}
                  </Descriptions.Item>
                  <Descriptions.Item label="PPK">
                    {profile?.kode_level_ppk
                      ? (profile?.kode_level_ppk === "kankemenag" &&
                          "Kantor Kementerian Agama") ||
                        (profile?.kode_level_ppk === "kanwil" &&
                          "Kantor Wilayah") ||
                        (profile?.kode_level_ppk === "madrasah" && "Madrasah")
                      : "-"}
                  </Descriptions.Item>
                  <Descriptions.Item label="NPSN">
                    {profile?.npsn}
                  </Descriptions.Item>
                  <Descriptions.Item label="Status">
                    {profile?.status
                      ? (profile?.status === "n" && "Negeri") ||
                        (profile?.status === "s" && "Swasta")
                      : "-"}
                  </Descriptions.Item>
                  <Descriptions.Item label="Jenjang">
                    {profile?.jenjang?.nama}
                  </Descriptions.Item>
                  <Descriptions.Item label="Telepon">
                    {profile?.telp}
                  </Descriptions.Item>
                  <Descriptions.Item label="Email">
                    {profile?.email}
                  </Descriptions.Item>
                  <Descriptions.Item label="Website">
                    {profile?.website}
                  </Descriptions.Item>
                  <Descriptions.Item label="NPWP">
                    {profile?.npwp}
                  </Descriptions.Item>
                  <Descriptions.Item label="Provinsi">
                    {profile?.kode_provinsi
                      ? provinsi?.length &&
                        provinsi
                          .filter((e: any) => e.kode === profile?.kode_provinsi)
                          .map((e: any) => e.nama)
                      : ""}
                  </Descriptions.Item>
                  <Descriptions.Item label="Kabupaten / Kota">
                    {profile?.kode_kabkota
                      ? kabkota?.length &&
                        kabkota
                          .filter((e: any) => e.kode === profile?.kode_kabkota)
                          .map((e: any) => e.nama)
                      : ""}
                  </Descriptions.Item>
                  <Descriptions.Item label="Kecamatan">
                    {profile?.kode_kecamatan
                      ? kecamatan?.length &&
                        kecamatan
                          .filter(
                            (e: any) => e.kode === profile?.kode_kecamatan,
                          )
                          .map((e: any) => e.nama)
                      : ""}
                  </Descriptions.Item>
                  <Descriptions.Item label="Kelurahan / Desa">
                    {profile?.kode_kelurahan
                      ? refKelurahan?.length &&
                        refKelurahan
                          .filter((e: any) => e.kode === profile.kode_kelurahan)
                          .map((e: any) => e.nama)
                      : ""}
                  </Descriptions.Item>
                  <Descriptions.Item label="RT">
                    {profile?.rt}
                  </Descriptions.Item>
                  <Descriptions.Item label="RW">
                    {profile?.rw}
                  </Descriptions.Item>
                  <Descriptions.Item label="Kode Pos">
                    {profile?.kode_pos}
                  </Descriptions.Item>
                  <Descriptions.Item label="Alamat Lengkap">
                    {profile?.alamat_jalan}
                  </Descriptions.Item>
                </Descriptions>
              </Card>
            </Col>
          </Row>
        </div>
        <div className="mb-4">
          <Card
            headStyle={{
              background: "green",
              color: "#fff",
              fontWeight: "bold",
            }}
            bodyStyle={{ background: "#d1fae5" }}
            title="Daftar Pengurus Madrasah">
            <TableUser tmpRole={tmpRole} params={stafMadrasah} />
          </Card>
        </div>
        <div className="mb-4">
          <Card
            headStyle={{
              background: "green",
              color: "#fff",
              fontWeight: "bold",
            }}
            bodyStyle={{ background: "#d1fae5" }}
            title="Daftar Rekening Bank">
            <TableRekeningBank params={rekeningBelanja} />
          </Card>
        </div>
      </div>
    ),
    pm2: (
      <div className="p-5">
        <div className="mb-4">
          <Card
            headStyle={{
              background: "green",
              color: "#fff",
              fontWeight: "bold",
            }}
            bodyStyle={{ background: "#d1fae5" }}
            title="Data Siswa">
            <Descriptions
              labelStyle={{ fontWeight: "bold" }}
              size="small"
              layout="vertical"
              column={{ xxl: 3, xl: 3, lg: 3, md: 3, sm: 1, xs: 1 }}>
              <Descriptions.Item label="Jumlah Siswa Perempuan">
                {madrasahSiswa?.student_count_dto?.studentFemale || 0} Orang
              </Descriptions.Item>
              <Descriptions.Item label="Jumlah Siswa Laki-Laki">
                {madrasahSiswa?.student_count_dto?.studentMale || 0} Orang
              </Descriptions.Item>
            </Descriptions>
          </Card>
        </div>
        <div className="mb-4">
          <Card
            headStyle={{
              background: "green",
              color: "#fff",
              fontWeight: "bold",
            }}
            bodyStyle={{ background: "#d1fae5" }}
            title="Data Guru">
            <Descriptions
              labelStyle={{ fontWeight: "bold" }}
              size="small"
              layout="vertical"
              column={{ xxl: 3, xl: 3, lg: 3, md: 3, sm: 1, xs: 1 }}>
              <Descriptions.Item label="Jumlah Guru PNS">
                {madrasahSiswa?.personnel_count_dto?.teacherPns || 0} Orang
              </Descriptions.Item>
              <Descriptions.Item label="Jumlah Guru Non PNS">
                {madrasahSiswa?.personnel_count_dto?.teacherNonPns || 0} Orang
              </Descriptions.Item>
              <Descriptions.Item label="Staf Pengajar">
                {madrasahSiswa?.personnel_count_dto?.personnelEducationStaff ||
                  0}{" "}
                Orang
              </Descriptions.Item>
            </Descriptions>
          </Card>
        </div>
      </div>
    ),
    pm3: (
      <div className="p-5">
        <div className="mb-4">
          <Row gutter={[16, 16]}>
            <Col
              className="gutter-row"
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 24 }}
              lg={{ span: 24 }}
              xl={{ span: 24 }}>
              <div className="mb-4">
                <Card
                  className="default w-full bg-green-500 mb-4"
                  title="Data Ruangan"
                  headStyle={{
                    background: "green",
                    color: "#fff",
                    fontWeight: "bold",
                  }}
                  bodyStyle={{ background: "#d1fae5" }}>
                  <Descriptions
                    labelStyle={{ fontWeight: "bold" }}
                    size="small"
                    layout="vertical"
                    column={{ xxl: 3, xl: 3, lg: 3, md: 3, sm: 1, xs: 1 }}>
                    <Descriptions.Item label="Jumlah Rombel">
                      {madrasahSiswa?.jumlah_rombel || 0} Rombel
                    </Descriptions.Item>
                    <Descriptions.Item label="Jumlah Ruang Kelas">
                      {madrasahAssets?.class_total || 0} Ruangan
                    </Descriptions.Item>
                    <Descriptions.Item label="Perpustakaan">
                      {(madrasahAssets?.library_total && "Ada") || "Tidak Ada"}
                    </Descriptions.Item>
                    <Descriptions.Item label="Mesjid">
                      {(madrasahAssets?.mosque_total && "Ada") || "Tidak Ada"}
                    </Descriptions.Item>
                    <Descriptions.Item label="Lapangan Olah Raga">
                      {(madrasahAssets?.sport_total && "Ada") || "Tidak Ada"}
                    </Descriptions.Item>
                  </Descriptions>
                </Card>
              </div>
              <div className="mb-4">
                <Card
                  className="default w-full bg-green-500 mb-4"
                  title="Data Toilet"
                  headStyle={{
                    background: "green",
                    color: "#fff",
                    fontWeight: "bold",
                  }}
                  bodyStyle={{ background: "#d1fae5" }}>
                  <Descriptions
                    labelStyle={{ fontWeight: "bold" }}
                    size="small"
                    layout="vertical"
                    column={{ xxl: 3, xl: 3, lg: 3, md: 3, sm: 1, xs: 1 }}>
                    <Descriptions.Item label="Jumlah Toilet Siswa">
                      {madrasahAssets?.shared_student_toilet_total || 0} Unit
                    </Descriptions.Item>
                    <Descriptions.Item label="Siswa Perempuan">
                      {madrasahAssets?.female_student_toilet_total || 0} Unit
                    </Descriptions.Item>
                    <Descriptions.Item label="Siswa Laki-Laki">
                      {madrasahAssets?.male_student_toilet_total || 0} Unit
                    </Descriptions.Item>
                    <Descriptions.Item label="Jumlah Toilet Guru">
                      {madrasahAssets?.shared_teacher_toilet_total || 0} Unit
                    </Descriptions.Item>
                    <Descriptions.Item label="Guru Perempuan">
                      {madrasahAssets?.female_teacher_toilet_total || 0} Unit
                    </Descriptions.Item>
                    <Descriptions.Item label="Guru Laki-Laki">
                      {madrasahAssets?.male_teacher_toilet_total || 0} Unit
                    </Descriptions.Item>
                  </Descriptions>
                </Card>
              </div>
              <div className="mb-4">
                <Card
                  className="default w-full bg-green-500 mb-4"
                  title="Data Asrama"
                  headStyle={{
                    background: "green",
                    color: "#fff",
                    fontWeight: "bold",
                  }}
                  bodyStyle={{ background: "#d1fae5" }}>
                  <Descriptions
                    labelStyle={{ fontWeight: "bold" }}
                    size="small"
                    layout="vertical"
                    column={{ xxl: 3, xl: 3, lg: 3, md: 3, sm: 1, xs: 1 }}>
                    <Descriptions.Item label="Jumlah Asrama Laki-Laki">
                      {madrasahAssets?.male_dorm_total || 0} Unit
                    </Descriptions.Item>
                    <Descriptions.Item label="Jumlah Asrama Perempuan">
                      {madrasahAssets?.female_dorm_total || 0} Unit
                    </Descriptions.Item>
                  </Descriptions>
                </Card>
              </div>
              <div className="mb-4">
                <Card
                  className="default w-full bg-green-500"
                  title="Data Laboratorium"
                  headStyle={{
                    background: "green",
                    color: "#fff",
                    fontWeight: "bold",
                  }}
                  bodyStyle={{ background: "#d1fae5" }}>
                  <Descriptions
                    labelStyle={{ fontWeight: "bold" }}
                    size="small"
                    layout="vertical"
                    column={{ xxl: 3, xl: 3, lg: 3, md: 3, sm: 1, xs: 1 }}>
                    <Descriptions.Item label="Laboratorium Kimia">
                      {(madrasahAssets?.chemistry_lab_total && "Ada") ||
                        "Tidak Ada"}
                    </Descriptions.Item>
                    <Descriptions.Item label="Laboratorium Biologi">
                      {(madrasahAssets?.biology_lab_total && "Ada") ||
                        "Tidak Ada"}
                    </Descriptions.Item>
                    <Descriptions.Item label="Laboratorium Bahasa">
                      {(madrasahAssets?.library_total && "Ada") || "Tidak Ada"}
                    </Descriptions.Item>
                    <Descriptions.Item label="Laboratorium Komputer">
                      {(madrasahAssets?.computer_lab_total && "Ada") ||
                        "Tidak Ada"}
                    </Descriptions.Item>
                    <Descriptions.Item label="Laboratorium Agama">
                      {(madrasahAssets?.language_lab_total && "Ada") ||
                        "Tidak Ada"}
                    </Descriptions.Item>
                    <Descriptions.Item label="Laboratorium Fisika">
                      {(madrasahAssets?.physics_lab_total && "Ada") ||
                        "Tidak Ada"}
                    </Descriptions.Item>
                  </Descriptions>
                </Card>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    ),
  };
  return (
    <>
      <BreadCrumb
        routes={itemBreadcrumb}
        title="Profil Madrasah"
        extra={[
          <Button
            type="primary"
            onClick={() => {
              route.push("/profile-madrasah/edit");
            }}
            key="3">
            <EditOutlined />
            Edit Profil
          </Button>,
        ]}
        footer={
          <Tabs
            defaultActiveKey="pm1"
            size="small"
            onChange={(key) => {
              setActiveTabKey(key);
            }}>
            <TabPane tab="Umum" key="pm1" />
            <TabPane tab="Siswa dan Guru" key="pm2" />
            <TabPane tab="Sarana dan Prasarana" key="pm3" />
          </Tabs>
        }
      />
      {contentList[activeTabKey]}
    </>
  );
};

const TableUser = ({ tmpRole, params }) => {
  const [search, setSearch] = useState<any>(null);
  const tmpTableData: any = params;
  const [isLoadingExport, setIsLoadingExport] = useState(false);
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);

  let tableData = search
    ? tmpTableData.filter((item: any) => {
        const val = search.toLowerCase();
        return (
          (item.profile.nama !== null &&
            (item.profile.nama || "").toLowerCase().includes(val)) ||
          (item.profile.nik !== null &&
            (item.profile.nik || "").toLowerCase().includes(val)) ||
          (item.profile.user.email !== null &&
            (item.profile.user.email || "").toLowerCase().includes(val)) ||
          (item.kodeRole !== null &&
            (item.kodeRole || "").toLowerCase().includes(val))
        );
      })
    : tmpTableData;
  /**
   * Filter Pagination
   */
  const totalDataTable: any = tableData.length;
  let tmpDataTable = tableData.filter((v, i) => {
    let start = pageSize * (page - 1);
    let end = start + pageSize;

    return i >= start && i < end;
  });

  function handleButtonExport() {
    setIsLoadingExport(true);
    try {
      let xls = tableData.map((el: any) => {
        return {
          NAMA: el.profile.nama,
          NIK: el.profile.nik,
          EMAIL: el.profile.user.email,
          ROLE: el.role.nama,
        };
      });
      ExportToExcel(xls, "daftar-pengurus-madrasah");
      notifAlert({ type: "success", description: "Data berhasil di export" });
    } catch (error) {
      notifAlert({ type: "error", description: "Data Gagal di export" });
    }
    setIsLoadingExport(false);
  }

  /** Columns Daftar Pengurus */
  const columsDaftarPengurus = [
    {
      title: "NIK",
      key: "nik",
      onFilter: (value, record) => record.profile?.nik.indexOf(value) === 0,
      sorter: (a, b) => a.profile?.nik - b.profile?.nik,
      render: (record) => record.profile?.nik,
    },
    {
      title: "NAMA",
      key: "nama",
      onFilter: (value, record) => record.profile?.nama?.indexOf(value) === 0,
      sorter: (a, b) => a.profile?.nama?.length - b.profile?.nama?.length,
      render: (record) => record.profile?.nama,
    },
    {
      title: "EMAIL",
      key: "email",
      onFilter: (value, record) =>
        record.profile?.user?.email?.indexOf(value) === 0,
      sorter: (a, b) =>
        a.profile?.user?.email?.length - b.profile?.user?.email?.length,
      render: (record) => record.profile?.user?.email,
    },
    {
      title: "ROLE",
      dataIndex: "kodeRole",
      key: "kodeRole",
      onFilter: (value, record) => record.kodeRole.indexOf(value) === 0,
      sorter: (a, b) => a.kodeRole.length - b.kodeRole.length,
      render: (kodeRole) =>
        tmpRole.filter((e) => e.kode === kodeRole).map((e) => e.nama),
    },
  ];

  return (
    <>
      <div className="flex mb-3">
        <div className="flex-1">
          <InputSearch
            key="seacrhPengurus"
            placeholder="Cari..."
            onChange={(e: any) => setSearch(e.currentTarget.value)}
          />
        </div>
        <div className="flex-1 ">
          <div className="float-right">
            <Button
              key="butPengurus"
              type="default"
              loading={isLoadingExport}
              onClick={handleButtonExport}>
              <DownloadOutlined />
              Export
            </Button>
          </div>
        </div>
      </div>
      <Table
        rowKey={(record) => record.id}
        columns={columsDaftarPengurus}
        dataSource={tmpDataTable}
        bordered
        pagination={{
          total: totalDataTable,
          position: ["bottomRight"],
          defaultPageSize: pageSize,
          defaultCurrent: page,
          showTotal: (total) => `Total ${total} items`,
          onChange(page, pageSize) {
            setPage(page);
            setPageSize(pageSize);
          },
        }}
      />
    </>
  );
};

const TableRekeningBank = ({ params }) => {
  const tmpTableData: any = params;
  const [search, setSearch] = useState<any>(null);
  const [isLoadingExport, setIsLoadingExport] = useState(false);
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);

  const tableData = search
    ? tmpTableData.filter((item: any) => {
        const val = search.toLowerCase();
        return (
          (item.kode_bank !== null &&
            (item.kode_bank || "").toLowerCase().includes(val)) ||
          (item.nama_bank !== null &&
            (item.nama_bank || "").toLowerCase().includes(val)) ||
          (item.no_rekening !== null &&
            (item.no_rekening || "").toLowerCase().includes(val)) ||
          (item.keterangan !== null &&
            (item.keterangan || "").toLowerCase().includes(val)) ||
          (item.no_rekening_nama !== null &&
            (item.no_rekening_nama || "").toLowerCase().includes(val)) ||
          (item.kode_tipe_rekening !== null &&
            (item.kode_tipe_rekening || "").toLowerCase().includes(val)) ||
          (item.cabang_bank !== null &&
            (item.cabang_bank || "").toLowerCase().includes(val))
        );
      })
    : tmpTableData;

  /**
   * Filter Pagination
   */
  const totalDataTable: any = tableData.length;
  let tmpDataTable = tableData.filter((v, i) => {
    let start = pageSize * (page - 1);
    let end = start + pageSize;

    return i >= start && i < end;
  });

  function handleButtonExport() {
    setIsLoadingExport(true);
    try {
      let xls = tableData.map((el: any) => {
        return {
          "KODE BANK": el.kode_bank,
          "NAMA BANK": el.nama_bank,
          CABANG: el.cabang_bank,
          "NO REKENING": el.no_rekening,
          "NAMA NO REKENING": el.no_rekening_nama,
          KETERANGAN: el.keterangan,
          "TIPE REKENING": el.kode_tipe_rekening,
        };
      });
      ExportToExcel(xls, "data-rekening-bank");
      notifAlert({ type: "success", description: "Data berhasil di export" });
    } catch (error) {
      notifAlert({ type: "error", description: "Data Gagal di export" });
    }
    setIsLoadingExport(false);
  }

  return (
    <>
      <div className="flex mb-3">
        <div className="flex-1">
          <InputSearch
            key="searhRekening"
            placeholder="Cari..."
            onChange={(e: any) => setSearch(e.currentTarget.value)}
          />
        </div>
        <div className="flex-1 ">
          <div className="float-right">
            <Button
              key="butRekBank"
              type="default"
              loading={isLoadingExport}
              onClick={handleButtonExport}>
              <DownloadOutlined />
              Export
            </Button>
          </div>
        </div>
      </div>
      <div className="">
        <Table
          rowKey={(record) => record.id}
          // scroll={{ x: 1500 }}
          columns={columsDaftarRekening}
          dataSource={tmpDataTable}
          bordered
          pagination={{
            total: totalDataTable,
            position: ["bottomRight"],
            defaultPageSize: pageSize,
            defaultCurrent: page,
            showTotal: (total) => `Total ${total} items`,
            onChange(page, pageSize) {
              setPage(page);
              setPageSize(pageSize);
            },
          }}
        />
      </div>
      <ModalInformation />
    </>
  );
};

/** Columns Daftar Rekening */
const columsDaftarRekening = [
  {
    title: "KODE BANK",
    dataIndex: "kode_bank",
    key: "kode_bank",
    onFilter: (value, record) => record.kode_bank.indexOf(value) === 0,
    sorter: (a, b) => a.kode_bank - b.kode_bank,
  },
  {
    title: "NAMA BANK",
    dataIndex: "nama_bank",
    key: "nama_bank",
    onFilter: (value, record) => record.nama_bank.indexOf(value) === 0,
    sorter: (a, b) => a.nama_bank.length - b.nama_bank.length,
  },
  {
    title: "CABANG",
    dataIndex: "cabang_bank",
    key: "cabang_bank",
    onFilter: (value, record) => record.cabang_bank.indexOf(value) === 0,
    sorter: (a, b) => a.cabang_bank.length - b.cabang_bank.length,
  },
  {
    title: "NO REKENING",
    dataIndex: "no_rekening",
    key: "no_rekening",
    onFilter: (value, record) => record.no_rekening.indexOf(value) === 0,
    sorter: (a, b) => a.no_rekening.length - b.no_rekening.length,
  },
  {
    title: "NAMA REKENING",
    dataIndex: "no_rekening_nama",
    key: "no_rekening_nama",
    onFilter: (value, record) => record.no_rekening_nama.indexOf(value) === 0,
    sorter: (a, b) => a.no_rekening_nama.length - b.no_rekening_nama.length,
  },
  {
    title: "KETERANGAN",
    dataIndex: "keterangan",
    key: "keterangan",
  },
  // {
  //   title: "TIPE REKENING",
  //   dataIndex: "kode_tipe_rekening",
  //   key: "kode_tipe_rekening",
  // },
];
export default ProfilMadrasah;
