/** @format */

import React, { useState } from "react";
import { Button, Modal } from "antd";
import Information from "../../assets/img/background-info.svg";

const ModalInformation = () => {
  const [open, setOpen] = useState<any>(
    localStorage.getItem("informationIndikatif") ? false : true,
  );
  const handleClose = () => {
    setOpen(false);
    localStorage.setItem("informationIndikatif", "false");
  };
  return (
    <Modal
      open={open}
      destroyOnClose={true}
      footer={null}
      width={1200}
      onCancel={handleClose}
      wrapClassName="modalBos "
      className="rounded-xl shadow-xl"
      centered
      bodyStyle={{
        backgroundImage: `url(${Information})`,
        backgroundSize: `cover`,
        minHeight: 500,
      }}
      closable={true}>
      <div className="w-1/3  w-2/3 text-center h-full px-10 my-15 py-10 align-center">
        <div className="text-4xl font-bold mb-6 text-green-700">PENTING</div>
        <div className="mb-4 text-lg shadow rounded-md  border-4 border-blue-500 p-4">
          <div className="mb-4 text-center bg-blue-500 text-white font-bold">
            Blokir AA sudah dibuka
          </div>
          <div className="mb-2 text-md">
            <span className="font-bold">* </span>
            Untuk pengajuan{" "}
            <span className="font-bold">BOS 2024 Tahap 2 Batch 2 </span> dan
            selanjutnya, pengajuan dilakukan secara penuh sesuai dana tahap II.
          </div>
          <div className="mb-2 text-md">
            <span className="font-bold">* </span>
            Bagi Madrasah yang sudah melakukan pengajuan dan telah di verifikasi
            pada <span className="font-bold">BOS 2024 Tahap 2 Batch 1 </span>,
            Dana AA akan dicairkan pada periode pencairan{" "}
            <span className="font-bold">Tahap II Batch 2</span>.
          </div>
        </div>
        <div className="mb-2 text-lg">
          Bagi madrasah yang telah menerima dana{" "}
          <span className="font-bold">BOS 2024</span>, agar input realisasi
          dilakukan secara bulanan serta <span className="font-bold">BKU</span>{" "}
          dan <span className="font-bold">LPJ</span> bulanan dibuat paling
          lambat tanggal 10 bulan berikutnya{" "}
          <p className="font-bold  mt-4 text-sm">
            <span className="text-red-500">(*) </span> Pesan ini terkirim ke
            akun madrasah, kab/kota, provinsi dan auditor!
          </p>
        </div>
        <Button
          size="large"
          className="mt-10"
          type="primary"
          onClick={handleClose}>
          <div className=" mx-10 font-bold text-white">Kembali</div>
        </Button>
      </div>
    </Modal>
  );
};
export default ModalInformation;
