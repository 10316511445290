/**
 * @format
 */

import React, { useState, useEffect } from "react";
import {
  Button,
  Steps,
  Form,
  Input,
  Divider,
  Table,
  Upload,
  Tag,
  Space,
  Modal,
  Radio,
  Typography,
  Alert,
  Descriptions,
  Result,
  Tooltip,
} from "antd";
import {
  UploadOutlined,
  FileDoneOutlined,
  ReloadOutlined,
  DownloadOutlined,
} from "@ant-design/icons";
import type { UploadProps } from "antd";
import { useHistory } from "react-router-dom";
import axios from "axios";
import Background from "../../assets/img/background-image.svg";
import { FooterLogin, HeaderLogo } from "../../components/LoginArea";
import {
  ButtonTableDelete,
  ButtonTableDetail,
  ButtonTableDownload,
} from "../../components/Button";
import { formatRupiah, openInNewTab, uuidv4 } from "../../utils/helper";
import notifAlert from "../../components/NotifAlert";
import { planningService } from "../../services/v2/constant";
import ModalGenerateManual from "../../components/Modal/ModalGenerateManual";
import {
  deleteFileMadrasahManual,
  editUploadManual,
  getFileMadrasahManual,
  getMadrasahManualByNsm,
  getPortalBosManual,
  postMadrasahManual,
  postUploadManual,
  statusVerifikasiUploadManual,
} from "../../services/v2/planningservice/uploadmanualservices";
import moment from "moment";
import "moment/locale/id";
import ModalDokumenAction from "../../components/Modal/ModalActionDokumen";
import {
  getStatusDokumen,
  getTanggalPengajuanBos,
  listDokumen,
  spesialBOSTahap2,
  validasiListDokumen,
} from "../../utils/helper/helperBos";
export const baseURL: string =
  window.REACT_APP_API_URL || "https://api-gateway.erkam-v2.kemenag.go.id/api";
// "https://staging-api-gateway.erkam-v2.kemenag.go.id/api";
export const publicURL: string =
  window.REACT_APP_PUBLIC_URL || "https://api-gateway.erkam-v2.kemenag.go.id";
// "https://staging-frontend.erkam-v2.kemenag.go.id/";
const { Text } = Typography;

const UploadManual = () => {
  const credentials = "und5sy2kn9HekqKBf0FpLieVb2Fv4kD2";
  const listDoc = ["docTBUP"];
  const [form] = Form.useForm();
  const [formData] = Form.useForm();
  const [loading, setLoading] = useState<any>(false);
  const isTahun = new Date().getFullYear();
  // const [id, setID] = useState<any>(null);
  // const [dataSumber, setDataSumber] = useState<any>(null);
  const [idUpload, setIdUpload] = useState<any>(null);
  const [pdfFile, setPdfFile] = useState<any>(null);
  const [fileUpload, setFileUpload] = useState<any>(null);
  const [fileList, setFileList] = useState<any>([]);
  const [openModalPriview, setOpenModalPriview] = useState(false);
  const [openModalGenerate, setOpenModalGenerate] = useState(false);
  const [openModalAction, setOpenModalAction] = useState(false);
  const [tahap, setTahap] = useState<any>(null);
  const [dataBos, setDataBos] = useState<any>(null);
  const [dataRekBos, setDataRekBos] = useState<any>([]);
  const [penerimaBos, setPenerimaBos] = useState<any>(null);
  const [bukanPenerimaBos, setBukanPenerimaBos] = useState<any>(null);
  const [dataMadrasah, setDataMadrasah] = useState<any>(null);
  const [dataAction, setDataAction] = useState<any>(null);
  const [nilaiPencairan, setNilaiPencairan] = useState<any>(null);
  const [nominalAlokasiBos, setNominalAlokasiBos] = useState<any>(null);
  const [verificationButton, setVerificationButton] = useState<any>(false);
  const [title, setTitle] = useState("");
  const listJadwalPengajuan = getTanggalPengajuanBos();
  const [messageInformation, setMessageInformation] = useState<any>(null);
  const tmpSpesialBOSTahap2 = spesialBOSTahap2();
  const checkDataMadrasah = tmpSpesialBOSTahap2.includes(dataMadrasah?.nsm);
  const [statusDokumen, setStatusDokumen] = useState<any>(getStatusDokumen(0));
  const getJadwal =
    listJadwalPengajuan.find(
      (item) =>
        moment().isAfter(item.startDate) && moment().isBefore(item.endDate),
    ) || null;

  let dataTableDokumen = listDokumen();

  const route = useHistory();
  const steps = [
    {
      title: "Verifikasi",
      content: "verification",
    },
    {
      title: "Data Madrasah",
      content: "madrasahData",
    },
    {
      title: "Upload Dokumen",
      content: "uploadDokumen",
    },
    {
      title: "Selesai",
      content: "finish",
    },
  ];
  const [current, setCurrent] = useState(0);

  const next = () => {
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };
  const items = steps.map((item) => ({ key: item.title, title: item.title }));
  let infoRek: any =
    (dataRekBos.length &&
      dataRekBos.find((item) => item.nsm === dataMadrasah?.nsm)) ||
    null;

  const formVerification = async (values: any) => {
    setLoading(true);
    try {
      const response = await getMadrasahManualByNsm(values.nsm);
      axios
        .get(
          `${baseURL}/v2/reference-services/rekening-portalbos/manual?xCredentialKey=${credentials}&tahun=${isTahun}&nsm=${values?.nsm}&activated=1`,
          {
            withCredentials: false,
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "Access-Control-Allow-Headers":
                "Content-Type,X-Amz-Date,Authorization,X-Api-Key,X-Amz-Security-Token",

              "Access-Control-Allow-Credentials": true,
              Authorization: "Bearer -",
              "X-Erkam-Year": isTahun,
            },
          },
        )
        .then(function (response: any) {
          if (response?.data?.return) {
            let res: any = response?.data?.return || [];
            setDataRekBos(res);
          } else {
            setDataRekBos([]);
          }
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        });

      const dataPortalBos = await getPortalBosManual(
        `&tahun=${isTahun}&nsm=${values.nsm}`,
      );
      setNominalAlokasiBos(dataPortalBos || null);
      const tmoNilai =
        (dataPortalBos &&
          dataPortalBos["apbn_bos_semester1"] +
            dataPortalBos["apbn_bos_semester2"]) ||
        "0";
      setDataBos(dataPortalBos);
      setNilaiPencairan(tmoNilai);
      setDataMadrasah(response || null);
      setCurrent(1);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    buttonValidation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [penerimaBos, fileList, dataMadrasah]);

  const buttonValidation = () => {
    let listVerifikasiDoc: any =
      validasiListDokumen("M", tahap, penerimaBos) || [];
    let dataFileTmp: any =
      (fileList.length &&
        fileList.filter(
          (item) =>
            item.tahap.toString() === tahap &&
            item.penerimaBos.toString() === penerimaBos &&
            item.kodeDok !== 7,
        )) ||
      [];

    let validTolak: any =
      dataFileTmp.find((item: any) => item?.statusValidasi === 2) || null;

    let validList: any = dataFileTmp.map((item) => item?.kodeDok) || [];

    let validVerifikasi: any =
      dataFileTmp.find((item: any) => item?.statusVerifikasiDokumen === 1) ||
      null;

    if (dataAction) {
      setVerificationButton(true);

      setStatusDokumen(
        getStatusDokumen(
          (dataAction?.statusPencairan === 1 && 3) ||
            (dataAction?.statusPencairan === 2 && 5) ||
            (dataAction?.statusPencairan === 3 && 6) ||
            (dataAction?.statusPencairan === 4 && 7),
        ),
      );
      return;
    }

    if (validVerifikasi) {
      setStatusDokumen(getStatusDokumen(2));
      setVerificationButton(true);
      return;
    }

    if (!getJadwal) {
      const lastJadwal: any =
        listJadwalPengajuan
          .reverse()
          .find((item) => moment().isAfter(item.endDate)) || null;
      let textInformation: any = (
        <div className="text-red-500 font-bold">
          Batas Pengajuan BOS TAHAP {lastJadwal?.tahap} BATCH{" "}
          {lastJadwal?.batch} akan berakhir pada{" "}
          {moment(lastJadwal?.endDate).format("DD MMMM YYYY")} pukul 17.00 WIB
        </div>
      );
      setMessageInformation(textInformation);
      setVerificationButton(true);
    } else {
      let textInformation: any = (
        <div className="text-green-500 font-bold">
          Pengajuan BOS TAHAP {getJadwal?.tahap} BATCH {getJadwal?.batch} sedang
          berjalan di mulai dari{" "}
          {moment(getJadwal?.startDate).format("DD MMMM YYYY")} sampai dengan{" "}
          {moment(getJadwal?.endDate).format("DD MMMM YYYY")} pukul 17.00 WIB
        </div>
      );
      setMessageInformation(textInformation);
      if (validTolak) {
        setStatusDokumen(getStatusDokumen(1));
        setVerificationButton(true);
      } else if (
        listVerifikasiDoc.filter((item) => !validList.includes(item)).length
      ) {
        setVerificationButton(true);
      } else {
        setStatusDokumen(getStatusDokumen(0));
        setVerificationButton(false);
      }
    }
  };

  let columnsDokumen: any = [
    {
      title: "Nama",
      dataIndex: "nama",
      width: 110,
      key: "nama",
      render: (nama, record) => {
        if (record.key === "docTBUP") {
          return (
            <>
              <Text>{nama}</Text>
            </>
          );
        } else {
          if (record.key === "docLpj1" && checkDataMadrasah) {
            nama = "Laporan Pertanggung Jawaban (LPJ) Desember 2023";
          }
          return nama;
        }
      },
    },
    {
      title: "Nama File",
      key: "namaFile",
      width: 110,
      render: (record) => {
        let namaFile = fileList.find(
          (item) =>
            item.kodeDok === record.kode &&
            item.tahap.toString() === `${tahap}` &&
            item.penerimaBos.toString() === `${penerimaBos}`,
        );

        return (namaFile?.namaDok && namaFile?.namaDok) || "";
      },
    },
    {
      title: "Tanggal Upload",
      key: "tanggalUpload",
      width: 70,
      render: (record) => {
        let namaFile = fileList.find(
          (item) =>
            item.kodeDok === record.kode &&
            item.tahap.toString() === `${tahap}` &&
            item.penerimaBos.toString() === `${penerimaBos}`,
        );
        return (
          (namaFile?.created_at &&
            moment(namaFile?.created_at).format("DD MMM YYYY")) ||
          ""
        );
      },
    },
    {
      title: "Tanggal Verifikasi",
      key: "tanggalVerifikasi",
      width: 70,
      render: (record) => {
        let namaFile = fileList.find(
          (item) =>
            item.kodeDok === record.kode &&
            item.tahap.toString() === `${tahap}` &&
            item.penerimaBos.toString() === `${penerimaBos}`,
        );
        return (
          (namaFile?.tanggal_verifikasi_dokumen &&
            moment(namaFile?.tanggal_verifikasi_dokumen).format(
              "DD MMM YYYY",
            )) ||
          ""
        );
      },
    },
    {
      title: "Komentar",
      key: "komentar",
      width: 100,
      render: (record) => {
        let namaFile = fileList.find(
          (item) =>
            item.kodeDok === record.kode &&
            item.tahap.toString() === `${tahap}` &&
            item.penerimaBos.toString() === `${penerimaBos}`,
        );
        return (namaFile?.keterangan && namaFile?.keterangan) || "";
      },
    },
    {
      title: "Status",
      width: 80,
      key: "statusValidasi",
      render: (record) => {
        let namaFile = fileList.find(
          (item) =>
            item.kodeDok === record.kode &&
            item.tahap.toString() === `${tahap}` &&
            item.penerimaBos.toString() === `${penerimaBos}`,
        );
        return namaFile ? (
          <Tag
            color={
              (namaFile?.statusValidasi.toString() === "2" && "red") ||
              (namaFile?.statusValidasi.toString() === "1" && "#008000") ||
              "#ffca27"
            }>
            {(namaFile?.statusValidasi.toString() === "2" && "DiTolak") ||
              (namaFile?.statusValidasi.toString() === "1" && "Disetujui") ||
              "Belum Disetujui"}
          </Tag>
        ) : record?.key !== "docTBUP" ? (
          <Tag>Dokumen Kosong</Tag>
        ) : (
          <Tag color={dataAction?.statusPencairan === 3 ? "#008000" : "gray"}>
            {dataAction?.statusPencairan === 3
              ? "Disetujui"
              : "Belum Disetujui"}
          </Tag>
        );
      },
    },
    {
      title: "Upload (Max 2M)",
      key: "upload",
      fixed: "right",
      width: 125,
      render: (record) => {
        let namaFile = fileList.find(
          (item) =>
            item.kodeDok === record.kode &&
            item.tahap.toString() === `${tahap}` &&
            item.penerimaBos.toString() === `${penerimaBos}`,
        );
        let linkDoc: any = record?.doc || null;
        if (record?.key === "docPPDB2" && checkDataMadrasah) {
          linkDoc = "/doc/Surat_Permohonan_Pencairan_Dana_BOS_TA_2024_1.docx";
        }
        if (
          record?.key === "docKPBT7" &&
          dataAction?.tahap === 2 &&
          dataAction?.batch === 1
        ) {
          linkDoc = "/doc/Kuitansi_Penerimaan_BOS_2024.docx";
        }
        return (
          <Space>
            {record?.kode === 4 && (
              <Button
                icon={<FileDoneOutlined />}
                type="primary"
                onClick={() => setOpenModalGenerate(true)}>
                Generate
              </Button>
            )}
            {!namaFile ? (
              !listDoc.includes(record?.key) ? (
                <Upload maxCount={1} accept={record?.type} {...props}>
                  <Button
                    icon={<UploadOutlined />}
                    onClick={() => handleAfterUpload(record)}>
                    Upload
                  </Button>
                </Upload>
              ) : (
                <Button
                  icon={
                    record?.key === "docLpj1" ? (
                      <ReloadOutlined />
                    ) : (
                      <DownloadOutlined />
                    )
                  }
                  onClick={() => handleAction(record?.key)}
                  disabled={
                    record?.key === "docLpj1"
                      ? false
                      : dataAction?.statusPencairan === 3
                      ? false
                      : true
                  }>
                  {record?.key === "docLpj1" ? "" : "Download"}
                </Button>
              )
            ) : (
              <>
                {!listDoc.includes(record?.key) ? (
                  <>
                    <ButtonTableDetail
                      tooltips="Lihat Dokumen"
                      loading={loading}
                      onClick={() => priviewDoc(namaFile?.namaFile, record)}
                    />
                    <ButtonTableDelete
                      disabled={
                        (namaFile?.statusValidasi?.toString() === "1" &&
                          true) ||
                        false
                      }
                      onClick={() => {
                        Modal.confirm({
                          title: "Perhatian",
                          content: "Yakin Hapus Data?",
                          onOk() {
                            return handleDeleteUpload(namaFile.id);
                          },
                          onCancel() {},
                          okText: "Hapus",
                          cancelText: "Batal",
                          okType: "danger",
                        });
                      }}
                    />
                  </>
                ) : (
                  <Button
                    icon={
                      record?.key === "docLpj1" ? (
                        <ReloadOutlined />
                      ) : (
                        <DownloadOutlined />
                      )
                    }
                    disabled={
                      record?.key === "docLpj1"
                        ? false
                        : dataAction?.statusPencairan === 3
                        ? false
                        : true
                    }
                    onClick={() => handleAction(record?.key)}>
                    {record?.key === "docLpj1" ? "" : "Download"}
                  </Button>
                )}
              </>
            )}
            {linkDoc && (
              <ButtonTableDownload
                onClick={() => openInNewTab(publicURL + linkDoc)}
              />
            )}
            {record?.key === "docRKAM6" && (
              <ButtonTableDownload
                onClick={() =>
                  openInNewTab(publicURL + "/doc/Format_EDM_erkam_2024.xlsx")
                }
              />
            )}
            {record?.key === "docLpj1" && (
              <ButtonTableDownload
                onClick={() =>
                  openInNewTab(
                    publicURL +
                      "/doc/Format_Laporan_Pertanggungjawaban_Manual_2024.xlsx",
                  )
                }
              />
            )}
          </Space>
        );
      },
    },
  ];

  const getStatusPencairan = async (values: any, daRek: any) => {
    axios
      .get(
        `${baseURL}${planningService}/dokumen-pencairan/nsm/manual?xCredentialKey=${credentials}&tahun=${isTahun}&nsm=${values?.nsm}&tahap=${values?.tahap}`,
        {
          withCredentials: false,
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Headers":
              "Content-Type,X-Amz-Date,Authorization,X-Api-Key,X-Amz-Security-Token",

            "Access-Control-Allow-Credentials": true,
            Authorization: "Bearer -",
            "X-Erkam-Year": isTahun,
          },
        },
      )
      .then(function (response: any) {
        if (response?.data?.return) {
          const res: any = response?.data?.return;
          const dataRek: any =
            daRek.find((item) => item.nsm === res?.nsmMadrasah) || null;
          let dataTmp: any = {
            ...res,
            namaBank: dataRek?.nama_bank,
            namaRekening: dataRek?.no_rekening_nama,
            noRekening: dataRek?.no_rekening,
            alamat: "-",
          };
          setDataAction(dataTmp);
        } else {
          setDataAction(null);
        }
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });
  };

  const uploadFile = async (params) => {
    let fileData: any = new FormData();
    fileData.append("id", idUpload);
    fileData.append("file", params.file);
    fileData.append(
      "kodeSumberDana",
      (tahap.toString() === "1" && "apbn_bos_semester_1") ||
        "apbn_bos_semester_2",
    );
    fileData.append(
      "namaSumberDana",
      (tahap.toString() === "1" && "APBN - BOS Tahap 1") ||
        "APBN - BOS Tahap 2",
    );
    fileData.append("keterangan", "");
    fileData.append("tahun", 2024);
    fileData.append("kodeDok", fileUpload.kode);
    fileData.append("namaDok", params.file.name);
    fileData.append("rencanaPendapatanId", null);
    fileData.append("tahap", tahap);
    fileData.append("jenisMadrasah", "M");
    fileData.append("namaFile", "-");
    fileData.append("fileUrl", "-");
    fileData.append("madrasahId", dataMadrasah?.id);
    fileData.append("nsm", dataMadrasah?.nsm);
    fileData.append("penerimaBos", penerimaBos);

    if (title === "upload") {
      try {
        await postUploadManual(fileData);
        getDataDokumen();
        notifAlert({
          type: "success",
          description: `Dokumen berhasil upload`,
        });
        return params;
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        await editUploadManual(fileData, idUpload);
        getDataDokumen();
        notifAlert({
          type: "success",
          description: `Dokumen berhasil upload`,
        });
        return params;
      } catch (error) {
        console.log(error);
      }
    }
  };

  const props: UploadProps = {
    name: "file",
    customRequest: uploadFile,

    showUploadList: false,
    headers: {
      authorization: "authorization-text",
    },
    beforeUpload: (file) => {
      let isPDF = true;
      const maxFile = file.size >= 2000000 ? false : true;

      if (!maxFile) {
        notifAlert({
          type: "error",
          description: "Maksimal file 2M",
        });
        isPDF = false;
      }
      return isPDF || Upload.LIST_IGNORE;
    },
    onChange(info) {
      if (info.file.status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === "done") {
        notifAlert({
          type: "success",
          description: `${info.file.name} file berhasil upload`,
        });
      } else if (info.file.status === "error") {
        notifAlert({
          type: "error",
          description: `${info.file.name} file gagal upload.`,
        });
      }
    },
    progress: {
      strokeColor: {
        "0%": "#108ee9",
        "100%": "#87d068",
      },
      strokeWidth: 3,
      format: (percent) => percent && `${parseFloat(percent.toFixed(2))}%`,
    },
  };

  const handleAfterUpload = (record: any) => {
    setFileUpload(record);
    setTitle("upload");
    setIdUpload(uuidv4());
  };

  // const handleEditUpload = (record: any, idEdit: any) => {
  //   setFileUpload(record);
  //   setTitle("editupload");
  //   setIdUpload(idEdit);
  // };

  const priviewDoc = async (params: any, typeDoc: any) => {
    try {
      setLoading(true);
      await getDownloadDokumen(params, typeDoc);
    } catch (error) {
      console.log(error);
    }
  };

  const getDownloadDokumen = async (params: any, typeDoc: any) => {
    axios
      .get(
        `${baseURL}${planningService}/madrasah-manual-upload/download?xCredentialKey=${credentials}&fileName=${params}`,
        {
          withCredentials: false,
          responseType: "arraybuffer",

          headers: {
            Accept: "application/pdf",
            "Content-Type": "application/pdf",
            "Access-Control-Allow-Headers":
              "Content-Type,X-Amz-Date,Authorization,X-Api-Key,X-Amz-Security-Token",
            "Access-Control-Allow-Credentials": true,
            Authorization: "Bearer -",
            "X-Erkam-Year": isTahun,
          },
        },
      )
      .then(function (response: any) {
        const blob = new Blob([response.data], {
          type: typeDoc.type,
        });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        if (typeDoc.type === "application/pdf") {
          setPdfFile(link);
          setOpenModalPriview(true);
        } else {
          link.setAttribute(
            "download",
            `${typeDoc.nama}-${dataMadrasah.nama}.csv`,
          );
          link.click();
        }

        setLoading(false);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
        setLoading(false);
      });
  };

  const handleDeleteUpload = async (idFile: any) => {
    try {
      await deleteFileMadrasahManual(idFile);
      notifAlert({
        type: "success",
        description: "Penghapusan Dokumen Berhasil",
      });
      getDataDokumen();
    } catch (error) {
      console.log(error);
    }
  };

  const getDataDokumen = async () => {
    setLoading(true);
    let params: any = `&tahun=${isTahun}&nsm=${dataMadrasah.nsm}&tahap=${tahap}`;
    try {
      const res = await getFileMadrasahManual(params);
      let result: any =
        (res?.length && res.filter((item) => item?.jenisMadrasah === "M")) ||
        [];
      setFileList(result);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const dataMadrasahFinish = async (values: any) => {
    let payload = {
      madrasahId: dataMadrasah?.id,
      nsm: dataMadrasah?.nsm,
      noRekening: infoRek?.no_rekening || `00000`,
      noRekeningNama: infoRek?.no_rekening_nama || `na`,
      namaKepala: values.namaKamad,
      namaBendahara: values.namaBendahara,
      namaKomite: "MUCHAMAD SIDIK SISDIYANTO",
      namaBank: infoRek?.nama_bank || `na`,
    };
    try {
      let dataPayload = collectData(payload);
      const res = await postMadrasahManual(dataPayload);

      const dataTmp = {
        ...res,
        tahap: values.tahap,
        namaKepala: values.namaKamad,
        namaBendahara: values.namaBendahara,
        noRekening: payload?.noRekening,
        noRekeningNama: payload?.noRekeningNama,
        nilaiPencairan: nilaiPencairan,
      };

      setDataMadrasah(dataTmp);
      getDataDokumen();
      getStatusPencairan(dataTmp, dataRekBos);
      next();
    } catch (error) {
      console.log(error);
    }
  };

  function collectData(values: any) {
    let fileData: any = new FormData();
    for (let obj in values) {
      fileData.append(obj, values[obj]);
    }
    return fileData;
  }

  const handleChange = (values) => {
    for (let obj in values) {
      if (obj === "tahap") {
        setTahap(values.tahap);
      }
      if (obj === "penerimaBos") {
        setPenerimaBos(values.penerimaBos);
      }
      if (obj === "bukanPenerimaBos") {
        if (values.bukanPenerimaBos === "1") {
          setPenerimaBos(values.bukanPenerimaBos);
        }
        setBukanPenerimaBos(values.bukanPenerimaBos);
      }
    }
  };

  const handleAction = (action: any) => {
    setOpenModalAction(true);
    setTitle(action);
    // if (action === "docLpj1") {

    // }
  };

  const handleAjukanVerifikasi = async () => {
    setLoading(true);

    let payload: any = {
      tahap: tahap,
      tahun: isTahun,
      nsm: dataMadrasah?.nsm,
      keterangan: null,
      statusPenerimaBos: penerimaBos,
    };

    try {
      await statusVerifikasiUploadManual(payload);
      Modal.success({
        title: "Sukses",
        content: "Dokumen Berhasil diajukan ke verifikator",
        centered: true,
        wrapClassName: "erkam-custom-modal",
      });
      // setOpenModalUpload(false);
      getDataDokumen();
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  if (!tmpSpesialBOSTahap2.includes(dataMadrasah?.nsm) && tahap === "2") {
    dataTableDokumen = dataTableDokumen.filter(
      (item) => !["docPPK5", "docBPD", "docPTJM3"].includes(item.key),
    );
  } else {
    dataTableDokumen = dataTableDokumen.filter(
      (item) => !["docSPTJB"].includes(item.key),
    );
  }

  dataTableDokumen =
    (!["3", "0"].includes(penerimaBos) &&
      dataTableDokumen.filter((item) => item.key !== "docSPPD")) ||
    dataTableDokumen.filter((item) => item.key !== "docLpj1");

  return (
    <div
      className="relative flex min-h-screen items-center flex-col justify-center overflow-hidden  py-6 sm:py-12"
      style={{
        background: `url(${Background})`,
      }}>
      <div className="w-full space-y-12 px-10 sm:px-8">
        <div className="max-w-screen-xl mx-auto">
          <div id="components-form-login" className=" mb-4">
            <HeaderLogo />
            <div className="text-center">
              Upload Dokumen madrasah non bimtek
            </div>
          </div>
          <div className=" bg-white card shadow-md p-5 mb-4">
            <div className="mb-2">
              <Steps current={current} items={items} />
            </div>
            <Divider />
            <div className="steps-content p-2">
              {steps[current].content === "verification" && (
                <div className="px-4">
                  <Form
                    form={form}
                    name="normal_login"
                    className="login-form"
                    layout="vertical"
                    onFinish={formVerification}>
                    <Form.Item
                      label="NSM"
                      name="nsm"
                      rules={[
                        { required: true, message: "NSM tidak boleh kosong!" },
                      ]}>
                      <Input placeholder="NSM" />
                    </Form.Item>
                  </Form>
                </div>
              )}
              {steps[current].content === "madrasahData" && (
                <div className="px-4">
                  <Form
                    form={formData}
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 16 }}
                    name="normal_login"
                    className="login-form"
                    layout="horizontal"
                    initialValues={{
                      namaKamad: dataMadrasah?.namaKepala || null,
                      namaBendahara: dataMadrasah?.namaBendahara || null,
                      namaKomite: dataMadrasah?.namaKomite || null,
                      // noRekening: dataMadrasah?.noRekening || null,
                      // namaRekening: dataMadrasah?.noRekeningNama || null,
                      // namaBank: dataMadrasah?.namaBank || null,
                    }}
                    onFinish={dataMadrasahFinish}
                    onValuesChange={handleChange}>
                    <div className="flex items-center justify-center mb-4">
                      <div className="flex-none text-center">
                        <div className="mb-2 text-xl font-bold">
                          {dataMadrasah?.nama || ""}
                        </div>

                        <Descriptions
                          layout="vertical"
                          column={
                            (dataAction?.tahap === 2 &&
                              dataAction?.batch === 1 &&
                              3) ||
                            4
                          }
                          bordered>
                          <Descriptions.Item
                            label={`Alokasi Dana BOS TA. ${isTahun}`}>
                            <div className="mb-2 text-xl font-bold">
                              {formatRupiah(nilaiPencairan)}
                            </div>
                          </Descriptions.Item>
                          <Descriptions.Item label={`Nominal APBN-BOS Tahap 1`}>
                            <div className="mb-2 text-xl font-bold">
                              {formatRupiah(
                                nominalAlokasiBos?.apbn_bos_semester1,
                              )}
                            </div>
                          </Descriptions.Item>
                          <Descriptions.Item label={`Nominal APBN-BOS Tahap 2`}>
                            <div className="mb-2 text-xl font-bold">
                              {formatRupiah(
                                nominalAlokasiBos?.apbn_bos_semester2,
                              )}
                            </div>
                          </Descriptions.Item>
                          {dataAction?.tahap === 2 &&
                            dataAction?.batch === 1 && (
                              <>
                                <Descriptions.Item label={`Nominal Non AA`}>
                                  <div className="mb-2 text-xl font-bold">
                                    {formatRupiah(
                                      nominalAlokasiBos?.apbn_bos_semester2 -
                                        nominalAlokasiBos?.nilai_aa,
                                    )}
                                  </div>
                                </Descriptions.Item>
                                <Descriptions.Item label={`Nominal AA`}>
                                  <div className="mb-2 text-xl font-bold">
                                    {formatRupiah(nominalAlokasiBos?.nilai_aa)}
                                  </div>
                                </Descriptions.Item>
                              </>
                            )}

                          <Descriptions.Item label="Jumlah Siswa">
                            <div className="mb-2 text-xl font-bold">
                              {dataBos?.jumlahSiswa} Orang
                            </div>
                          </Descriptions.Item>
                        </Descriptions>
                      </div>
                    </div>

                    <Form.Item
                      label="Nama Kepala Madrasah"
                      name="namaKamad"
                      rules={[
                        {
                          required: true,
                          message: "Nama Kepala Madrasah tidak boleh kosong!",
                        },
                      ]}>
                      <Input placeholder="Nama Kepala Madrasah" />
                    </Form.Item>
                    <Form.Item
                      label="Nama Bendahara Madrasah"
                      name="namaBendahara"
                      rules={[
                        {
                          required: true,
                          message:
                            "Nama Bendahara Madrasah tidak boleh kosong!",
                        },
                      ]}>
                      <Input placeholder="Nama Bendahara Madrasah" />
                    </Form.Item>
                    <Form.Item label="No Rekening Madrasah">
                      {infoRek?.no_rekening || `00000`}
                    </Form.Item>
                    <Form.Item label="Atas Nama Rekening Madrasah">
                      {infoRek?.no_rekening_nama || `na`}
                    </Form.Item>
                    <Form.Item label="Nama Bank">
                      {infoRek?.nama_bank || `na`}
                    </Form.Item>
                    <Form.Item
                      label="Pilih Tahap"
                      name="tahap"
                      rules={[
                        {
                          required: true,
                          message: "Tahap tidak boleh kosong!",
                        },
                      ]}>
                      <Radio.Group>
                        <Radio value="1">Tahap 1</Radio>
                        <Radio value="2">Tahap 2</Radio>
                      </Radio.Group>
                    </Form.Item>
                    {tahap === "2" && (
                      <Form.Item
                        label={`Penerima BOS Tahap 1 TA. ${isTahun}`}
                        name="bukanPenerimaBos"
                        rules={[
                          {
                            required: true,
                            message: "Penerima BOS Tahap 1 tidak boleh kosong!",
                          },
                        ]}>
                        <Radio.Group>
                          <Radio value="1">
                            Penerima Dana BOS Tahap 1 T.A {isTahun}
                          </Radio>
                          <Radio value="bukanPenerimaTahap1">
                            Bukan Penerima Dana BOS Tahap 1 T.A {isTahun}
                          </Radio>
                        </Radio.Group>
                      </Form.Item>
                    )}
                    {(tahap === "1" ||
                      bukanPenerimaBos === "bukanPenerimaTahap1") && (
                      <Form.Item
                        label={`Penerima BOS TA. ${isTahun - 1}`}
                        name="penerimaBos"
                        rules={[
                          {
                            required: true,
                            message: "Penerima BOS tidak boleh kosong!",
                          },
                        ]}>
                        <Radio.Group>
                          <Radio
                            value={
                              ((tahap === "2" ||
                                bukanPenerimaBos === "bukanPenerimaTahap1") &&
                                "2") ||
                              "1"
                            }>
                            Penerima Dana BOS
                          </Radio>
                          <Radio
                            value={
                              ((tahap === "2" ||
                                bukanPenerimaBos === "bukanPenerimaTahap1") &&
                                "3") ||
                              "0"
                            }>
                            Bukan Penerima Dana BOS
                          </Radio>
                        </Radio.Group>
                      </Form.Item>
                    )}
                  </Form>
                </div>
              )}
              {steps[current].content === "uploadDokumen" && (
                <div className="px-4">
                  <Table
                    className="mb-2"
                    columns={columnsDokumen}
                    dataSource={dataTableDokumen.filter((item) =>
                      item.tahap.toString().includes(dataMadrasah?.tahap),
                    )}
                    loading={loading}
                    bordered
                    pagination={false}
                  />
                  <div className="text-xs mb-4">
                    <div className="flex items-center gap-4">
                      <Alert
                        message={
                          <div className="p-2">
                            <div>
                              Print Bukti Upload dapat di akses ketika status
                              dokumen menjadi{" "}
                              <span className="font-bold">Pencairan</span>.
                            </div>
                            {messageInformation}
                          </div>
                        }
                        type="info"
                        showIcon
                      />
                      <div className="flex-auto">
                        <div className="text-lg text-center font-bold mb-2">
                          Status Dokumen
                        </div>
                        <div className="text-center">
                          <Tooltip title={statusDokumen?.status?.toolstips}>
                            <Tag color={statusDokumen?.status?.color}>
                              {statusDokumen?.status?.infoMessage}
                            </Tag>

                            {(dataAction?.statusPencairan === 1 && (
                              <div className="mt-2">
                                Tanggal Verifikasi:{" "}
                                {moment(dataAction?.created_at)
                                  .utc()
                                  .format("DD MMMM YYYY")}
                              </div>
                            )) ||
                              (dataAction?.statusPencairan > 1 && (
                                <>
                                  <div className="mt-2">
                                    {`Batch ${dataAction?.batch}`}
                                  </div>

                                  <div className="mt-2">
                                    Tanggal:{" "}
                                    {moment(dataAction?.submissionDate)
                                      .utc()
                                      .format("DD MMMM YYYY")}
                                  </div>
                                </>
                              ))}
                          </Tooltip>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="text-center mb-4">
                    <Button
                      loading={loading}
                      disabled={verificationButton}
                      onClick={handleAjukanVerifikasi}
                      type="primary">
                      Ajukan Verifikasi
                    </Button>
                  </div>
                </div>
              )}
              {steps[current].content === "finish" && (
                <div className="px-4">
                  <Result
                    status="success"
                    title="Selesai"
                    subTitle="Terima kasih telah melengkapi dokumen madrasah untuk penerimaan bantuan dana BOS."
                  />
                </div>
              )}
            </div>
            <div className="steps-action">
              <div className="flex justify-between">
                {current > 0 && (
                  <Button style={{ margin: "0 8px" }} onClick={() => prev()}>
                    Kembali
                  </Button>
                )}
                {current === 0 && (
                  <>
                    <Button
                      style={{ margin: "0 8px" }}
                      onClick={() => route.push("/home")}>
                      Kembali
                    </Button>
                    <Button
                      type="primary"
                      onClick={() => form.submit()}
                      loading={loading}>
                      Lanjutkan
                    </Button>
                  </>
                )}
                {current === 1 && (
                  <>
                    <Button type="primary" onClick={() => formData.submit()}>
                      Lanjutkan
                    </Button>
                  </>
                )}
                {current === 2 && (
                  <Button type="primary" onClick={() => next()}>
                    Lanjutkan
                  </Button>
                )}
                {current === steps.length - 1 && (
                  <Button type="primary" onClick={() => route.push("/home")}>
                    Selesai
                  </Button>
                )}
              </div>
            </div>
          </div>
          <div className="max-w-screen-md mx-auto">
            <FooterLogin />
          </div>
        </div>
        <Modal
          open={openModalPriview}
          title={`Lihat Dokumen`}
          width={900}
          onCancel={() => setOpenModalPriview(!openModalPriview)}
          footer={[
            <Button
              key="back"
              onClick={() => setOpenModalPriview(!openModalPriview)}>
              Kembali
            </Button>,
          ]}>
          <object
            aria-labelledby="rkam"
            data={pdfFile}
            type="application/pdf"
            width="100%"
            height="400px"></object>
        </Modal>
        <ModalGenerateManual
          openModal={openModalGenerate}
          dataMadrasah={dataMadrasah}
          rekBos={dataRekBos}
          onClose={() => setOpenModalGenerate(!openModalGenerate)}
        />
        <ModalDokumenAction
          openModal={openModalAction}
          dataAction={dataAction}
          dataBOS={dataBos}
          dataLaporan={[]}
          dataFile={fileList}
          dataMadrasah={null}
          onClose={() => setOpenModalAction(false)}
          action={title}
        />
      </div>
    </div>
  );
};
export default UploadManual;
