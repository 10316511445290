/** @format */
import React, { useState, useEffect } from "react";
import { Modal, Button } from "antd";
import { UploadPriview } from "../Uploads/UploadPriview";

type generateModal = {
  title: any;
  openModal: boolean;
  dataFile: any;
  onClose: () => void;
};
const ModalLihatDokumen = ({
  title,
  openModal,
  dataFile,
  onClose,
}: generateModal) => {
  const [fileList, setFileList] = useState<any>([]);

  useEffect(() => {
    let fileTmp: any = [];
    if (dataFile?.length) {
      // eslint-disable-next-line array-callback-return
      dataFile?.map((item: any) => {
        fileTmp.push({
          status: "done",
          uid: item.id,
          name: item.fileName,
          url: item.fileUrl,
          thumbUrl: item.fileUrl,
        });
      });
    }
    setFileList(fileTmp);
  }, [dataFile]);

  return (
    <>
      <Modal
        open={openModal}
        width={1000}
        title={title}
        onCancel={onClose}
        footer={[<Button onClick={onClose}>Kembali</Button>]}>
        <UploadPriview fileList={fileList} tmpFile={dataFile} />
      </Modal>
    </>
  );
};
export default ModalLihatDokumen;
