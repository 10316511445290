/** @format */

import React, { useState, useEffect } from "react";
import { BreadCrumb } from "../../../../components";
import { useHistory, useLocation } from "react-router-dom";
import {
  Table,
  Select,
  Modal,
  Space,
  Button,
  Form,
  Descriptions,
  Badge,
  Switch,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { toTitleCase, uuidv4 } from "../../../../utils/helper";
import {
  ButtonTableDelete,
  ButtonTableEdit,
  ButtonTambah,
} from "../../../../components/Button";
import notifAlert from "../../../../components/NotifAlert";
import { getUsermanAll } from "../../../../services/v2/usermanservice";
import { getReferenceAll } from "../../../../services/v2/referenceservice";
import { setStore } from "../../../../redux/actions";
import { getMadrasah } from "../../../../services/v2/usermanservice/madrasahservices";
import {
  deleteRoleUserID,
  postRoleUserPengawas,
  postRoleUserV2,
} from "../../../../services/v2/usermanservice/roleuserservices";

const Role = () => {
  const route = useHistory();
  const location = useLocation();
  let tmpRoles: any = location?.state || null;
  if (tmpRoles?.groupRole === "pusat") {
    tmpRoles = {
      ...tmpRoles,
      kodeKabkota: null,
      kodeProvinsi: null,
    };
  }

  const itemBreadcrumb = [
    { path: "/", breadcrumbName: "Home" },
    { path: "/", breadcrumbName: "Pengaturan" },
    { path: "/", breadcrumbName: "Managemen User" },
    {
      path: "/",
      breadcrumbName: `${toTitleCase(tmpRoles?.groupRole)}`,
    },
    { path: "/", breadcrumbName: "Role" },
  ];
  const [form] = Form.useForm();
  //   const [search, setSearch] = useState<any>(null);
  const dispatch = useDispatch();
  const [refKelurahan, setRefKelurahan] = useState<any>([]);
  const [refRole, setRefRole] = useState<any>([]);
  const store = useSelector((state: any) => state.store);
  const auths = useSelector((state: any) => state.auth);
  const auth = auths?.data || null;
  // const profile = store?.profile || null;
  const kodeRole = auth?.kode_role || null;
  const tmpListMadrasah = store?.listMadrasah || [];
  const refProvinsi = store.provinsi || [];
  const refKabkota = store.kabkota || [];
  const refKecamatan = store.kecamatan || [];
  const [openModalAction, setOpenModalAction] = useState(false);
  const [title, setTitle] = useState<any>(null);
  const [tableData, setTableData] = useState<any>([]);
  const [id, setID] = useState<any>(null);
  const [isLoading, setLoading] = useState(false);
  const [madrasah, setMadrasah] = useState<any>([]);
  const [provinsi, setProvinsi] = useState<any>(null);
  const [kabkota, setKabkota] = useState<any>(null);
  const [groupRole, setGroupRole] = useState<any>(null);

  const tmpGroup = [
    { kode: "pusat", nama: "Pusat" },
    { kode: "provinsi", nama: "Kantor Wilayah" },
    { kode: "kabkota", nama: "Kantor Kementerian Agama" },
    { kode: "madrasah", nama: "Madrasah" },
    { kode: "pengawas", nama: "Pengawas" },
  ];

  let filterGroup: any = [];

  switch (tmpRoles?.groupRole) {
    case "pusat":
      filterGroup = ["pusat", "provinsi", "kabkota", "madrasah"];
      break;
    case "provinsi":
      filterGroup = ["kabkota", "madrasah"];
      break;
    case "kabkota":
    case "pengawas":
      filterGroup = ["madrasah", "pengawas"];
      break;
    case "madrasah":
      filterGroup = ["madrasah"];
      break;
    // case "pengawas":
    //   filterGroup = ["pengawas"];
    //   break;
  }

  const dataTable = tableData;
  const columns: any = [
    {
      title: "Group",
      key: "groupRole",
      dataIndex: "groupRole",
      render: (groupRole) =>
        tmpGroup.find((item) => item.kode === groupRole)?.nama || "-",
    },
    {
      title: "Role",
      key: "role",
      render: (record) => {
        let codeRole = record?.kodeRole;
        let namaRoles =
          record?.role?.find((item) => item.kode === codeRole)?.nama || "-";
        return namaRoles;
      },
    },
    {
      title: "Detail",
      key: "Detail",
      render: (record) => {
        let nama = "";
        let namaProvinsi =
          refProvinsi.find((items) => items.kode === record.kodeProvinsi)
            ?.nama || "";
        let namaKabkota =
          refKabkota.find((items) => items.kode === record.kodeKabkota)?.nama ||
          "";
        switch (record.groupRole) {
          case "pusat":
            nama = `Group: Pusat`;
            break;
          case "provinsi":
            nama = `Group: Kantor Wilayah, ${namaProvinsi}`;
            break;
          case "kabkota":
            nama = `Group: Kantor Kementerian Agama, ${namaProvinsi} - ${namaKabkota}`;
            break;
          case "pengawas":
            nama = `Group: Pengawas, ${namaProvinsi} - ${namaKabkota}`;
            break;
          case "madrasah":
            nama = `Group: Madrasah, Madrasah: ${record?.madrasah?.nsm} - ${record?.madrasah?.nama},  ${namaProvinsi}, ${namaKabkota}`;
            break;
        }
        return nama;
      },
    },
    {
      title: "Default Role",
      key: "default_role",
      render: (record) => (
        <Badge
          status={
            record.kodeRole === tmpRoles?.kodeRole ? "processing" : "default"
          }
          text={record.kodeRole === tmpRoles?.kodeRole ? "Ya" : "Tidak"}
        />
      ),
    },
    {
      title: "Aksi",
      fixed: "right",
      width: 120,
      render: (record) => (
        <Space size="small">
          <ButtonTableEdit
            disabled={record.kodeRole === tmpRoles?.kodeRole ? true : false}
            onClick={() => handleEdit(record)}
          />
          {tmpRoles?.groupRole !== "pengawas" && (
            <ButtonTableDelete
              disabled={record.kodeRole === tmpRoles?.kodeRole ? true : false}
              onClick={() => {
                Modal.confirm({
                  title: "Perhatian",
                  content: "Yakin Hapus Data?",
                  onOk() {
                    return handleDelete(record);
                  },
                  onCancel() {},
                  okText: "Hapus",
                  cancelText: "Batal",
                  okType: "danger",
                });
              }}
            />
          )}
        </Space>
      ),
    },
  ];

  const handleTambah = () => {
    setOpenModalAction(true);
    setTitle("Tambah");
    setRefRole([]);
    setID(uuidv4());
    if (tmpRoles?.groupRole !== "pusat") {
      setProvinsi(tmpRoles?.kodeProvinsi || null);
      setKabkota(tmpRoles?.kodeKabkota || null);
    }
  };

  const handleDelete = async (record: any) => {
    try {
      await deleteRoleUserID(record.id);
      const multiRoles = await getUsermanAll("check-available", null);
      setTimeout(() => {
        dispatch(setStore({ multiRoles }));
      }, 100);
      notifAlert({ type: "success", description: "Data berhasil dihapus" });
      getData();
    } catch (error) {
      console.log(error);
    }
  };

  const handleEdit = (record: any) => {
    setOpenModalAction(true);
    setTitle("Edit");
    setID(record.id);
    let payload: any = {};

    if (record?.groupRole !== "pengawas") {
      payload = {
        kodeRole: record.kodeRole,
        group: record.groupRole,
        madrasah: record.madrasahId,
        kabkota: record.kodeKabkota,
        provinsi: record.kodeProvinsi,
        offline: record.offline,
        madrasahList: [],
      };
    } else {
      let editListMadrasah: any = record?.listMadrasahIdPengawas?.length
        ? record?.listMadrasahIdPengawas
        : [];
      payload = {
        kodeRole: record.kodeRole,
        group: record.groupRole,
        madrasahList: editListMadrasah,
        madrasah: null,
        kabkota: record.kodeKabkota,
        provinsi: record.kodeProvinsi,
        offline: record?.offline,
      };
    }
    form.setFieldsValue(payload);
    setGroupRole(record?.groupRole);
    setProvinsi(record?.kodeProvinsi);
    setKabkota(record?.kodeKabkota);
    getDataRole(record.groupRole);
  };

  const handleSave = async (values: any) => {
    if (values.group === "pengawas") {
      const payload = {
        id: id,
        kode_role: values.kodeRole,
        group_role: values.group,
        user_id: tmpRoles?.userId,
        list_madrasah_id_pengawas: values?.madrasahList,
        kantor_kabkota_id: tmpRoles?.kantorKabkotaId || null,
        kantor_provinsi_id: tmpRoles?.kantorProvinsiId || null,
        kantor_pusat_id: tmpRoles?.kantorPusatId || null,
        kode_kabkota: values?.kabkota || null,
        kode_provinsi: values?.provinsi || null,
        activated: "1",
        offline: values?.offline || false,
      };
      try {
        await postRoleUserPengawas(payload);
        notifAlert({ type: "success", description: "Data berhasil disimpan" });
        const multiRoles = await getUsermanAll("check-available", null);
        setTimeout(() => {
          dispatch(setStore({ multiRoles }));
        }, 100);
        getData();
        handleReset();
      } catch (error) {
        console.log(error);
      }
    } else {
      const payload = {
        id: id,
        kode_role: values.kodeRole,
        group_role: values.group,
        user_id: tmpRoles?.userId,
        madrasah_id: values?.madrasah,
        kantor_kabkota_id: tmpRoles?.kantorKabkotaId || null,
        kantor_provinsi_id: tmpRoles?.kantorProvinsiId || null,
        kantor_pusat_id: tmpRoles?.kantorPusatId || null,
        kode_kabkota: values?.kabkota || null,
        kode_provinsi: values?.provinsi || null,
        activated: "1",
        offline: values?.offline || false,
      };
      try {
        await postRoleUserV2(payload);
        notifAlert({ type: "success", description: "Data berhasil disimpan" });
        const multiRoles = await getUsermanAll("check-available", null);
        setTimeout(() => {
          dispatch(setStore({ multiRoles }));
        }, 100);
        getData();
        handleReset();
      } catch (error) {
        console.log(error);
      }
    }

    // const payload = {
    //   id: id,
    //   kode_role: values.kodeRole,
    //   group_role: values.group,
    //   user_id: tmpRoles?.userId,
    //   madrasah_id:
    //     (values.group === "pengawas" && values?.madrasahList) ||
    //     values?.madrasah,
    //   kantor_kabkota_id: tmpRoles?.kantorKabkotaId || null,
    //   kantor_provinsi_id: tmpRoles?.kantorProvinsiId || null,
    //   kantor_pusat_id: tmpRoles?.kantorPusatId || null,
    //   kode_kabkota: values?.kabkota || null,
    //   kode_provinsi: values?.provinsi || null,
    //   activated: "1",
    //   offline: values?.offline || false,
    // };
    // try {
    //   await postRoleUserV2(payload);
    //   notifAlert({ type: "success", description: "Data berhasil disimpan" });
    //   const multiRoles = await getUsermanAll("check-available", null);
    //   setTimeout(() => {
    //     dispatch(setStore({ multiRoles }));
    //   }, 100);
    //   getData();
    //   handleReset();
    // } catch (error) {
    //   console.log(error);
    // }
    // form.resetFields();
  };

  const handleReset = () => {
    setOpenModalAction(false);
    setTitle(null);
    setID(null);
    setProvinsi(null);
    setKabkota(null);
    setGroupRole(null);
    form.resetFields();
  };

  const getData = async () => {
    setLoading(true);
    const tmpKelurahan = store.kelurahan || [];
    if (tmpListMadrasah.length === 0) {
      let filter: any = "";
      if (groupRole === "provinsi") {
        filter = `?kodeProvinsi=${auth?.kode_provinsi}`;
      }
      if (groupRole === "kabkota") {
        filter = `?kodeProvinsi=${auth?.kode_provinsi}&kode_kabkota=${auth?.kode_kabkota}`;
      }
      const madrasah = await getMadrasah(filter);
      const listMadrasah = madrasah || [];
      setTimeout(() => {
        dispatch(setStore({ listMadrasah }));
      }, 100);
      setMadrasah(madrasah);
    } else {
      setMadrasah(tmpListMadrasah);
    }

    const tmpDataRoleUser = await getUsermanAll("role-user-v2", {
      activated: 1,
      userId: tmpRoles?.userId,
    });

    setTableData(tmpDataRoleUser);

    if (tmpKelurahan.length) {
      setRefKelurahan(tmpKelurahan);
    } else {
      const kelurahan = await getReferenceAll("kelurahan", { activated: 1 });
      setRefKelurahan(kelurahan);
      setTimeout(() => {
        dispatch(setStore({ kelurahan }));
      }, 100);
    }
    setLoading(false);
  };

  const getDataRole = async (group: any) => {
    try {
      const tmpRole = await getUsermanAll("role", {
        activated: 1,
        group: group,
      });
      let tRoles: any = tmpRole;
      if (kodeRole === "admin_provinsi" && kodeRole === "pengawas_provinsi") {
        tRoles =
          tRoles.filter((item) => item.kode === "pengawas_provinsi") || [];
      } else if (kodeRole === "admin_kabkota" && kodeRole === "pengawas") {
        tRoles = tRoles.filter((item) => item.kode === "pengawas") || [];
      }

      setRefRole(tRoles || []);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (values) => {
    for (let obj in values) {
      switch (obj) {
        case "provinsi":
          setProvinsi(values.provinsi);
          setKabkota(null);
          form.setFieldsValue({ madrasah: null, kabkota: null });
          break;
        case "kabkota":
          setKabkota(values.kabkota);
          form.setFieldsValue({ madrasah: null });
          break;
        case "group":
          setGroupRole(values.group);
          getDataRole(values.group);
          form.setFieldsValue({ madrasah: null, kodeRole: null });
          break;
      }
    }
  };

  return (
    <>
      <BreadCrumb
        routes={itemBreadcrumb}
        title="Role"
        back={true}
        toBack={() => route.goBack()}
      />
      <div className="m-5 p-5 bg-white shadow-md rounded">
        <Descriptions
          title="Profil"
          labelStyle={{ fontWeight: 600 }}
          layout="vertical">
          <Descriptions.Item label="Nama">
            {tmpRoles?.profile?.nama || "-"}
          </Descriptions.Item>
          <Descriptions.Item label="NIK">
            {tmpRoles?.profile?.nik || "-"}
          </Descriptions.Item>
          <Descriptions.Item label="Email">
            {tmpRoles?.profile?.user?.email || "-"}
          </Descriptions.Item>
          <Descriptions.Item label="Provinsi">
            {(tmpRoles?.profile?.kode_provinsi &&
              refProvinsi.find(
                (item) => item.kode === tmpRoles?.profile?.kode_provinsi,
              )?.nama) ||
              "-" ||
              "-"}
          </Descriptions.Item>
          <Descriptions.Item label="Kabupaten / Kota">
            {(tmpRoles?.profile?.kode_kabkota &&
              refKabkota.find(
                (item) => item.kode === tmpRoles?.profile?.kode_kabkota,
              )?.nama) ||
              "-" ||
              "-"}
          </Descriptions.Item>
          <Descriptions.Item label="Kecamatan">
            {(tmpRoles?.profile?.kode_kecamatan &&
              refKecamatan.find(
                (item) => item.kode === tmpRoles?.profile?.kode_kecamatan,
              )?.nama) ||
              "-" ||
              "-"}
          </Descriptions.Item>
          <Descriptions.Item label="Kelurahan">
            {(tmpRoles?.profile?.kode_kelurahan &&
              refKelurahan.find(
                (item) => item.kode === tmpRoles?.profile?.kode_kelurahan,
              )?.nama) ||
              "-" ||
              "-"}
          </Descriptions.Item>
          <Descriptions.Item label="RT">
            {tmpRoles?.profile?.rt || "-"}
          </Descriptions.Item>
          <Descriptions.Item label="RW">
            {tmpRoles?.profile?.rw || "-"}
          </Descriptions.Item>
          <Descriptions.Item label="Alamat Jalan">
            {tmpRoles?.profile?.alamat_jalan || "-"}
          </Descriptions.Item>
        </Descriptions>
        <div className="mb-4 flex justify-end">
          {/* {tmpRoles.groupRole === "pengawas" && (
            <ButtonTambah onClick={handleTambah} />
          )} */}
          <ButtonTambah onClick={handleTambah} loading={isLoading} />
        </div>

        <div className="w-full">
          <Table
            rowKey={(record) => record.id}
            columns={columns}
            dataSource={dataTable}
            bordered
            loading={isLoading}
            scroll={{ x: "1300" }}
          />
        </div>
      </div>
      <Modal
        open={openModalAction}
        title={`${title} Role`}
        onCancel={handleReset}
        footer={[
          <Button key="back" onClick={handleReset}>
            Batal
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={isLoading}
            onClick={() => form.submit()}>
            Simpan
          </Button>,
        ]}>
        <Form
          form={form}
          key="formUserRole"
          name="formUserRole"
          layout="vertical"
          initialValues={{
            kabkota: tmpRoles?.kodeKabkota || null,
            provinsi: tmpRoles?.kodeProvinsi || null,
          }}
          onValuesChange={handleChange}
          onFinish={handleSave}>
          <Form.Item
            label="Group"
            name="group"
            rules={[{ required: true, message: "Group tidak boleh kosong!" }]}>
            <Select
              showSearch
              // disabled={tmpRoles?.groupRole === "pengawas" ? true : false}
              optionFilterProp="children"
              placeholder="Pilih Group">
              {tmpGroup?.length &&
                tmpGroup
                  .filter((item: any) => filterGroup.includes(item.kode))
                  .map((e: any, i: any) => {
                    return (
                      <Select.Option key={`group${i}`} value={e.kode}>
                        {e.nama}
                      </Select.Option>
                    );
                  })}
            </Select>
          </Form.Item>

          <Form.Item
            label="Role"
            name="kodeRole"
            rules={[{ required: true, message: "Role tidak boleh kosong!" }]}>
            <Select
              showSearch
              optionFilterProp="children"
              // disabled={tmpRoles?.groupRole === "pengawas" ? true : false}
              placeholder="Pilih Role">
              {refRole?.length &&
                refRole
                  .filter((item: any) => {
                    if (kodeRole !== "super_admin_pusat") {
                      return ![
                        "admin_provinsi_bos",
                        "admin_pusat_bos",
                        "admin_kabkota_bos",
                      ].includes(item.kode);
                    }
                    return true;
                  })
                  .map((e: any, i: any) => {
                    return (
                      <Select.Option key={`role${i}`} value={e.kode}>
                        {e.nama}
                      </Select.Option>
                    );
                  })}
            </Select>
          </Form.Item>
          {(groupRole === "provinsi" ||
            groupRole === "kabkota" ||
            groupRole === "pengawas" ||
            groupRole === "madrasah") && (
            <Form.Item
              label="Provinsi"
              name="provinsi"
              rules={[
                { required: true, message: "Provinsi tidak boleh kosong!" },
              ]}>
              <Select
                showSearch
                optionFilterProp="children"
                disabled={tmpRoles?.groupRole === "pengawas" ? true : false}
                placeholder="Pilih Provinsi">
                {refProvinsi?.length &&
                  refProvinsi.map((e: any, i: any) => {
                    return (
                      <Select.Option key={`prov${i}`} value={e.kode}>
                        {e.nama}
                      </Select.Option>
                    );
                  })}
              </Select>
            </Form.Item>
          )}
          {(groupRole === "kabkota" ||
            groupRole === "madrasah" ||
            groupRole === "pengawas") && (
            <Form.Item
              label="Kab / Kota"
              name="kabkota"
              rules={[
                { required: true, message: "Kab / Kota tidak boleh kosong!" },
              ]}>
              <Select
                showSearch
                optionFilterProp="children"
                disabled={tmpRoles?.groupRole === "pengawas" ? true : false}
                placeholder="Pilih Kab / Kota">
                {provinsi &&
                  refKabkota?.length &&
                  refKabkota
                    .filter((item) => item.kode_provinsi === provinsi)
                    .map((e: any, i: any) => {
                      return (
                        <Select.Option key={`role${i}`} value={e.kode}>
                          {e.nama}
                        </Select.Option>
                      );
                    })}
              </Select>
            </Form.Item>
          )}
          {groupRole === "madrasah" && (
            <Form.Item
              label="Madrasah"
              name="madrasah"
              rules={[
                { required: true, message: "Madrasah tidak boleh kosong!" },
              ]}>
              <Select
                showSearch
                optionFilterProp="children"
                placeholder="Pilih Madrasah">
                {provinsi &&
                  kabkota &&
                  madrasah?.length &&
                  madrasah
                    .filter(
                      (item) =>
                        item.kode_kabkota === kabkota &&
                        item.kode_provinsi === provinsi,
                    )
                    .map((e: any, i: any) => {
                      return (
                        <Select.Option key={`role${i}`} value={e.id}>
                          {e.nama} | {e.nsm}
                        </Select.Option>
                      );
                    })}
              </Select>
            </Form.Item>
          )}
          {groupRole === "pengawas" && (
            <Form.Item
              label="Madrasah"
              name="madrasahList"
              rules={[
                { required: true, message: "Madrasah tidak boleh kosong!" },
              ]}>
              <Select
                showSearch
                mode="multiple"
                optionFilterProp="children"
                placeholder="Pilih Madrasah">
                {madrasah?.length &&
                  madrasah
                    .filter(
                      (item) =>
                        item.kode_kabkota === kabkota &&
                        item.kode_provinsi === provinsi,
                    )
                    .map((e: any, i: any) => {
                      return (
                        <Select.Option key={`role${i}`} value={e.id}>
                          {e.nama} | {e.nsm}
                        </Select.Option>
                      );
                    })}
              </Select>
            </Form.Item>
          )}
          {groupRole === "madrasah" && (
            <Form.Item label="Offline" name="offline" valuePropName="checked">
              <Switch />
            </Form.Item>
          )}
        </Form>
      </Modal>
    </>
  );
};
export default Role;
