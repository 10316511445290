/** @format */

import React from "react";
import { Form, Modal, Descriptions, DatePicker } from "antd";
import type { RangePickerProps } from "antd/es/date-picker";
import moment from "moment";
import "moment/locale/id";
// import { formatRupiah } from "../../../../utils/helper";

type FormActionType = {
  openModal: boolean;
  title: string;
  data: any;
  handleSave: (e: any) => void;
  handleClose: () => void;
};

const ModalTanggalPencairan = ({
  openModal,
  title,
  data,
  handleSave,
  handleClose,
}: FormActionType) => {
  const [form] = Form.useForm();
  const disabledDate: RangePickerProps["disabledDate"] = (current) => {
    return (
      data?.tanggal_pengajuan &&
      moment(current).isBefore(data?.tanggal_pengajuan, "day")
    );
  };

  return (
    <Modal
      open={openModal}
      title={`${title} Pencairan`}
      width={700}
      onCancel={handleClose}
      cancelText="Batal"
      okText="Simpan"
      onOk={() => form.submit()}>
      <Form
        onFinish={handleSave}
        form={form}
        layout="vertical"
        key="formTanggaPencairan"
        name="formTanggaPencairan">
        <div className="grid grid-cols-1  md:items-center  md:grid-cols-2 gap-2 ">
          <div>
            <Form.Item
              label="Tanggal Pencairan"
              name="tanggalPencairan"
              rules={[
                {
                  required: true,
                  message: "Tanggal Pencairan tidak boleh kosong!",
                },
              ]}>
              <DatePicker
                disabledDate={disabledDate}
                placeholder="Tanggal Pencairan"
                // showTime
              />
            </Form.Item>
          </div>
        </div>
      </Form>
      <div className="p-2">
        <Descriptions
          labelStyle={{ fontWeight: 600 }}
          size="small"
          layout="vertical"
          column={{ xxl: 3, xl: 3, lg: 3, md: 3, sm: 1, xs: 1 }}>
          <Descriptions.Item label="Batch">{data?.batch}</Descriptions.Item>
          <Descriptions.Item label="Tahap">{data?.tahapan}</Descriptions.Item>
          <Descriptions.Item label="Lembaga">
            {data?.jumlah_lembaga}
          </Descriptions.Item>
          <Descriptions.Item label="Tanggal Pengajuan">
            {moment(data?.tanggal_pengajuan).format(
              "dddd,DD MMM YYYY HH:mm:ss",
            )}
          </Descriptions.Item>
          <Descriptions.Item label="Periode">
            {moment(data?.tanggal_mulai_verifikasi).format("DD MMM YYYY")} -{" "}
            {moment(data?.tanggal_akhir_verifikasi).format("DD MMM YYYY")}{" "}
          </Descriptions.Item>
        </Descriptions>
      </div>
    </Modal>
  );
};
export default ModalTanggalPencairan;
