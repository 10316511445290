/** @format */

import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Space, Table, Button, Modal, Descriptions, Tag, Alert } from "antd";
import { BreadCrumb } from "../../../../components";
import { ButtonDropdownExport } from "../../../../components/Button";
import moment from "moment";
import "moment/locale/id";
import jsPDF from "jspdf";
import * as FileSaver from "file-saver";
import ExcelJS from "exceljs";
import autoTable from "jspdf-autotable";
import notifAlert from "../../../../components/NotifAlert";
import InputSearch from "../../../../components/InputSearch";
import { ReloadOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { formatCurr, formatRupiah } from "../../../../utils/helper";
import { getPlanningAll } from "../../../../services/v2/planningservice";
import { getFileMadrasahManual } from "../../../../services/v2/planningservice/uploadmanualservices";
import { getDokumenRencanaDefinitif } from "../../../../services/v2/planningservice/rencanapendapatandefinitif";
import { getLaporanErkam } from "../../../../services/v2/planningservice/laporanservices";
import ModalDokumenAction from "../../../../components/Modal/ModalActionDokumen";

import {
  listDokumen,
  spesialBOSTahap2,
} from "../../../../utils/helper/helperBos";
import {
  getDokumenRencanaDefinitifAxios,
  getLaporanPengembalianDanaAxios,
} from "../../../../services/v2/reportservice";

const RekapitulasiPencairanBosMadrasah = () => {
  const route = useHistory();
  const dataState: any = route ? route.location.state : null;
  const auths = useSelector((state: any) => state.auth);
  const store = useSelector((state: any) => state.store);
  const refKabkota = store.kabkota || [];
  const [pdfFile, setPdfFile] = useState<any>(null);
  let listLap = ["docRKAM6", "docLpj1"];
  const [nilaiPortalBos, setNilaiPortalBos] = useState<any>(null);
  const [nilaiPengembalianDana, setNilaiPengembalianDana] = useState<any>(null);
  let dataTableDokumen: any = listDokumen();
  const dataKabkota =
    refKabkota.find((item) => item.kode === dataState?.kode_kabkota) || null;
  const auth = auths?.data || null;
  const [fileList, setFileList] = useState<any>([]);
  const pathTmp =
    auth?.kode_role === "admin_pusat_bos"
      ? `../listprov/${dataState?.dataProvinsi?.kantor_provinsi_id}`
      : `../list`;
  const [search, setSearch] = useState<any>(null);
  const [tmpMadrasah, setTmpMadrasah] = useState<any>([]);
  const [dataMadrasah, setDataMadrasah] = useState<any>(null);
  const [openModalData, setOpenModalData] = useState<any>(false);
  const [openModalAction, setOpenModalAction] = useState<any>(false);
  const [openModal, setOpenModal] = useState<any>(false);
  const [tipeFile, setTipeFile] = useState<any>(null);
  const [dataAction, setDataAction] = useState<any>(null);
  const [dataLaporan, setDataLaporan] = useState<any>([]);
  const tmpSpesialBOSTahap2 = spesialBOSTahap2();
  const [title, setTitle] = useState<any>(null);
  const itemBreadcrumb = [
    { path: "/", breadcrumbName: "Home" },
    { path: "/", breadcrumbName: "Pencairan Dokumen BOS" },
    {
      path: "/",
      breadcrumbName: `Rekapitulasi Pencairan BOS Tahap ${dataState?.tahap}`,
    },
    { path: "/", breadcrumbName: `Kab - Kota ${dataKabkota?.nama}` },
  ];
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (auths?.isLogin) {
      getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth?.isTahun]);

  /** Filter Search */
  let dataTable: any = search
    ? tmpMadrasah.filter((item: any) => {
        const val = search.toLowerCase();
        return (
          (item.nsm !== null &&
            (item.nsm || "").toString().toLowerCase().includes(val)) ||
          (item.nama_madrasah !== null &&
            (item.nama_madrasah || "").toString().toLowerCase().includes(val))
        );
      })
    : tmpMadrasah;

  const getData = async () => {
    setLoading(true);
    try {
      const kabkotaTmp =
        (await getPlanningAll(
          `portal-bos/rekapitulasi/list/kabkota/${dataState?.kode_kabkota}`,
          {
            tahun: auth?.isTahun,
            tahap: dataState?.tahap,
            kabkotaId: dataState?.kode_kabkota,
          },
        )) || [];
      setTmpMadrasah(kabkotaTmp);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const handleExportFile = (type: any) => {
    if (dataTable.length > 0) {
      if (type === "pdf") {
        handleExportPdf();
      } else if (type === "excel") {
        handleExportExcel();
      }
    } else {
      notifAlert({
        type: "warning",
        description: "Maaf data tidak tersedia!",
      });
    }
  };

  const handleExportExcel = async () => {
    var ExcelJSWorkbook = new ExcelJS.Workbook();
    var worksheet = ExcelJSWorkbook.addWorksheet("Data");
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";

    worksheet.mergeCells("A1:I1");

    worksheet.getColumn("A").width = 5;
    worksheet.getColumn("B").width = 14;
    worksheet.getColumn("C").width = 40;
    worksheet.getColumn("D").width = 10;
    worksheet.getColumn("E").width = 30;
    worksheet.getColumn("F").width = 30;
    worksheet.getColumn("G").width = 30;
    worksheet.getColumn("H").width = 30;
    worksheet.getColumn("I").width = 30;

    const headerCell = worksheet.getCell("A1");
    headerCell.font = {
      size: 11,
      underline: true,
      bold: true,
    };
    headerCell.alignment = { vertical: "middle", horizontal: "center" };
    headerCell.value = `REKAPITULASI PENCAIRAN DANA BOS T.A ${auth.isTahun} TAHAP ${dataState?.tahap} ${dataKabkota.nama}`;

    let dataTmp: any = [];
    tmpMadrasah.map((e, i) => {
      return dataTmp.push([
        i + 1,
        e.nsm,
        e.nama_madrasah,
        e.status_madrasah === "s" ? "Swasta" : "Negeri",
        e.jenjang,
        e.jumlah_siswa,
        (e.status_pencairan === 1 && "Verifikasi") ||
          (e.status_pencairan === 2 && "Pengajuan") ||
          (e.status_pencairan === 3 && "Penyaluran") ||
          (e.status_pencairan === 4 && "Sudah Dicairkan") ||
          "Belum Pencairan",
        e.tanggal_pencairan,
        formatCurr(e.nominal),
      ]);
    });

    worksheet.addTable({
      name: "Table",
      ref: "A3",
      headerRow: true,
      style: {
        showRowStripes: true,
      },
      columns: [
        { name: "No" },
        { name: "NSM" },
        { name: "Madrasah" },
        { name: "Negeri / Swasta" },
        { name: "Jenjang" },
        { name: "Jumlah Siswa" },
        { name: "Status" },
        { name: "Tanggal Pencairan" },
        { name: "Nominal" },
      ],
      rows: dataTmp,
    });

    const excelBuffer = await ExcelJSWorkbook.xlsx.writeBuffer();
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, `Rekapitulasi-Sumber-Dana-BOS${fileExtension}`);
    notifAlert({
      type: "success",
      description: "Data berhasil di export",
    });
  };

  function handleExportPdf() {
    const doc: any = new jsPDF({
      putOnlyUsedFonts: true,
      orientation: "landscape",
      floatPrecision: 16,
    });

    doc.setFont("times", "bold");
    doc.text(
      `REKAPITULASI PENCAIRAN DANA BOS T.A ${auth.isTahun} 
      TAHAP ${dataState?.tahap} ${dataKabkota.nama}`,
      150,
      10,
      {
        align: "center",
      },
    );

    var headers: any = [
      { id: "id", dataKey: "id", padding: 0, header: "No", width: 22 },
      {
        id: "nsm",
        dataKey: "nsm",
        padding: 0,
        header: "NSM",
        width: 50,
      },
      {
        id: "nama",
        dataKey: "nama",
        padding: 0,
        header: "Madrasah",
        width: 50,
      },
      {
        id: "statusSekolah",
        dataKey: "statusSekolah",
        padding: 0,
        header: "Negeri / Swasta",
        width: 50,
      },
      {
        id: "jenjang",
        dataKey: "jenjang",
        padding: 0,
        header: "Jenjang",
        width: 40,
      },
      {
        id: "jumlahSiswa",
        dataKey: "jumlahSiswa",
        padding: 0,
        header: "Jumlah Siswa",
        width: 30,
      },
      {
        id: "statusPencairan",
        dataKey: "statusPencairan",
        padding: 0,
        header: "Status Pencairan",
        width: 50,
      },
      {
        id: "tanggalPencairan",
        dataKey: "tanggalPencairan",
        padding: 0,
        header: "Tanggal Pencairan",
        width: 50,
      },
      {
        id: "nominal",
        dataKey: "nominal",
        padding: 0,
        header: "Nominal",
        width: 50,
      },
    ];
    let las: any = [];
    // const count = type === "pdf_all" ? tableData.length : 10;
    autoTable(doc, {
      styles: { lineColor: 244, lineWidth: 0.1, fontSize: 9 },
      headStyles: {
        halign: "center",
        valign: "middle",
        fillColor: [0, 128, 0],
      }, // Cells in first column centered and green
      columnStyles: {
        0: { halign: "center", cellWidth: 20 },
        1: { halign: "center", cellWidth: 32 },
        2: { halign: "center", cellWidth: 60 },
        3: { halign: "center", cellWidth: 23 },
        4: { halign: "center", cellWidth: 24 },
        5: { halign: "center", cellWidth: 25 },
        6: { halign: "center", cellWidth: 25 },
        7: { halign: "center", cellWidth: 33 },
        8: { halign: "right", cellWidth: 43 },
      },
      startY: 25,
      margin: { top: 4, left: 5, right: 4 },
      columns: headers,
      body: tmpMadrasah.map((e, i) => {
        return {
          id: i + 1,
          nsm: e.nsm,
          nama: e.nama_madrasah,
          statusSekolah: e.status_madrasah === "s" ? "Swasta" : "Negeri",
          jenjang: e.jenjang,
          jumlahSiswa: e.jumlah_siswa,
          statusPencairan:
            (e.status_pencairan === 1 && "Verifikasi") ||
            (e.status_pencairan === 2 && "Pengajuan") ||
            (e.status_pencairan === 3 && "Penyaluran") ||
            (e.status_pencairan === 4 && "Sudah Dicairkan") ||
            "Belum Pencairan",
          tanggalPencairan: e.tanggal_pencairan,
          nominal: formatCurr(e.nominal),
        };
      }),
      didDrawPage: (d) => las.push(d.cursor),
    });
    var blob = doc.output("blob", { filename: "rekapitulasi-sumber-dana-bos" });
    window.open(URL.createObjectURL(blob));
    // doc.output("dataurlnewwindow", { filename: "alokasi-sumber-dana-bos" });
    notifAlert({
      type: "success",
      description: "Data berhasil di export",
    });
  }

  let columns: any = [
    {
      title: "NSM",
      key: "nsm",
      width: 150,
      dataIndex: "nsm",
    },
    {
      title: "Madrasah",
      width: 230,
      dataIndex: "nama_madrasah",
      key: "nama_madrasah",
    },
    {
      title: "Negeri / Swasta",
      dataIndex: "status",
      key: "status",
      width: 110,
      render: (status) => (status === "n" && "Negeri") || "Swasta",
    },
    {
      title: "Jenjang",
      dataIndex: "jenjang",
      width: 100,
      key: "jenjang",
      render: (jenjang) => <div className="uppercase">{jenjang}</div>,
    },
    {
      title: "Jumlah Siswa",
      width: 90,
      dataIndex: "jumlah_siswa",
      key: "jumlah_siswa",
    },
    {
      title: `Jenis`,
      width: 90,
      dataIndex: "jenis_madrasah",
      key: "jenis_madrasah",
      render: (jenis_madrasah) =>
        (jenis_madrasah === "M" && "Manual") || "Reguler",
    },
    {
      title: `Penerima BOS ${auth?.isTahun - 1}`,
      width: 180,
      dataIndex: "penerima_bos",
      key: "penerima_bos",
      render: (penerima_bos) =>
        (penerima_bos === 1 && "Penerima") || "Bukan Penerima",
    },

    {
      title: "Status Pencairan",
      key: "status_pencairan",
      width: 230,
      dataIndex: "status_pencairan",
      render: (status_pencairan) =>
        (status_pencairan === 1 && "Verifikasi") ||
        (status_pencairan === 2 && "Pengajuan") ||
        (status_pencairan === 3 && "Penyaluran") ||
        (status_pencairan === 4 && "Sudah Dicairkan") ||
        "Belum Pencairan",
    },
    {
      title: "Tanggal Pencairan",
      key: "tanggal_pencairan",
      width: 230,
      dataIndex: "tanggal_pencairan",
    },
    {
      title: "Nominal",
      key: "nominal",
      width: 130,
      dataIndex: "nominal",
      render: (nominal) => (
        <div className="text-right">{formatCurr(nominal)}</div>
      ),
    },
    {
      title: "Aksi",
      key: "aksi",
      width: 160,
      fixed: "right",
      render: (record) => {
        return (
          <Space>
            <Button type="primary" onClick={() => handleAfterDokumen(record)}>
              Lihat Dokumen
            </Button>
          </Space>
        );
      },
    },
  ];

  const handleDokumen = async (record: any, tahap: any) => {
    if (record?.jenis_madrasah === "M") {
      try {
        let params: any = `&tahun=${auth?.isTahun}&nsm=${record?.nsm}&tahap=${tahap}`;
        const res = await getFileMadrasahManual(params);
        if (res) {
          let dataResult: any =
            res.filter(
              (item) =>
                item.jenisMadrasah === record?.jenis_madrasah &&
                item.penerimaBos.toString() ===
                  record?.penerima_bos.toString() &&
                item.tahap.toString() === tahap.toString(),
            ) || [];

          setFileList(dataResult || []);
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        let params: any = `?tahun=${auth?.isTahun}&madrasahId=${record?.madrasah_id}&tahap=${tahap}`;
        const res = await getDokumenRencanaDefinitif(params);
        if (res) {
          let dataResult: any =
            res.filter(
              (item) =>
                item.jenisMadrasah === record?.jenis_madrasah &&
                item.penerimaBos.toString() ===
                  record?.penerima_bos.toString() &&
                item.tahap.toString() === tahap.toString(),
            ) || [];

          setFileList(dataResult || []);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleAfterDokumen = (record: any) => {
    setDataMadrasah(record);
    handleDokumen(record, dataState?.tahap);
    setOpenModalData(true);
    getPortalBos(record);
  };

  const getPortalBos = async (values: any) => {
    const dataPortalBos = await getPlanningAll("portal-bos", {
      nsm: values?.nsm,
      tahun: auth?.isTahun,
    });

    setNilaiPortalBos(dataPortalBos || null);
    if (values?.jenisMadrasah === "R") {
      const tahunLpj: any = auth?.isTahun - 1;
      const tmpNilaiPengambalianDana: any =
        await getLaporanPengembalianDanaAxios(
          tahunLpj,
          auth,
          values?.madrasahId,
        );
      setNilaiPengembalianDana(tmpNilaiPengambalianDana || { sisa: 0 });
    } else {
      setNilaiPengembalianDana({ sisa: 0 });
    }
  };

  let columnsDokumen: any = [
    {
      title: "Nama",
      dataIndex: "nama",
      key: "nama",
    },
    {
      title: "Nama File",
      key: "namaFile",
      render: (record) => {
        let tahapTmp: any = dataState?.tahap.toString();
        let namaFile = fileList.find(
          (item) =>
            item.kodeDok === record.kode && item.tahap.toString() === tahapTmp,
        );

        if (record.key === "docBPD") {
          let infoPengembalian: any =
            (nilaiPengembalianDana?.sisa !== 0 && (
              <div className="text-red-500">{`Bukti pengembalian dana sebesar saldo  transaksi ${formatRupiah(
                nilaiPengembalianDana?.sisa,
              )}`}</div>
            )) ||
            "";
          return (
            (namaFile && (
              <>
                {namaFile?.namaDok} {infoPengembalian}
              </>
            )) ||
            infoPengembalian
          );
        } else {
          return namaFile?.namaDok || "";
        }
      },
    },
    {
      title: "Tanggal Upload",
      key: "tanggalUpload",
      render: (record) => {
        let namaFile = fileList.find(
          (item) => item.kodeDok === record.kode && item.tahap === record.tahap,
        );
        return (
          (namaFile?.updated_at &&
            moment(namaFile?.created_at).format("DD MMM YYYY")) ||
          ""
        );
      },
    },
    {
      title: "Tanggal Verifikasi",
      key: "tanggalVerifikasi",
      render: (record) => {
        let namaFile = fileList.find(
          (item) => item.kodeDok === record.kode && item.tahap === record.tahap,
        );
        return (
          (namaFile?.tanggal_verifikasi_dokumen &&
            moment(namaFile?.tanggal_verifikasi_dokumen).format(
              "DD MMM YYYY",
            )) ||
          ""
        );
      },
    },
    {
      title: "Keterangan",
      key: "keterangan",
      render: (record) => {
        let namaFile = fileList.find(
          (item) => item.kodeDok === record.kode && item.tahap === record.tahap,
        );
        return (namaFile?.keterangan && namaFile?.keterangan) || "";
      },
    },
    {
      title: "Status",
      key: "statusValidasi",
      render: (record) => {
        let tahapTmp: any = dataState?.tahap.toString();
        let namaFile = fileList.find(
          (item) =>
            item.kodeDok === record.kode && item.tahap.toString() === tahapTmp,
        );
        if (dataState?.tahap === "1") {
          if (!listLap.includes(record?.key)) {
            return namaFile ? (
              <Tag
                color={
                  (namaFile?.statusValidasi.toString() === "2" && "red") ||
                  (namaFile?.statusValidasi.toString() === "1" && "#008000") ||
                  "#ffca27"
                }>
                {(namaFile?.statusValidasi.toString() === "2" && "DiTolak") ||
                  (namaFile?.statusValidasi.toString() === "1" &&
                    "Disetujui") ||
                  "Belum Disetujui"}
              </Tag>
            ) : (
              <Tag>Dokumen Kosong</Tag>
            );
          } else {
            return namaFile ? (
              <Tag
                color={
                  (namaFile?.statusValidasi.toString() === "2" && "red") ||
                  (namaFile?.statusValidasi.toString() === "1" && "#008000") ||
                  "#ffca27"
                }>
                {(namaFile?.statusValidasi.toString() === "2" && "DiTolak") ||
                  (namaFile?.statusValidasi.toString() === "1" &&
                    "Disetujui") ||
                  "Belum Disetujui"}
              </Tag>
            ) : (
              ""
            );
          }
        } else {
          return namaFile ? (
            <Tag
              color={
                (namaFile?.statusValidasi.toString() === "2" && "red") ||
                (namaFile?.statusValidasi.toString() === "1" && "#008000") ||
                "#ffca27"
              }>
              {(namaFile?.statusValidasi.toString() === "2" && "DiTolak") ||
                (namaFile?.statusValidasi.toString() === "1" && "Disetujui") ||
                "Belum Disetujui"}
            </Tag>
          ) : (
            <Tag>Dokumen Kosong</Tag>
          );
        }
      },
    },
    {
      title: "Aksi",
      key: "aksi",
      render: (record) => {
        let tahapTmp: any = dataState?.tahap.toString();
        let namaFile = fileList.find(
          (item) =>
            item.kodeDok === record.kode && item.tahap.toString() === tahapTmp,
        );

        if (dataState?.tahap === "1") {
          return (
            <Space>
              {namaFile && (
                <>
                  <Button
                    icon={<ReloadOutlined />}
                    onClick={() => handleAfterApproval(record, namaFile)}>
                    Lihat Dokumen
                  </Button>
                </>
              )}
              {fileList.length !== 0 &&
                listLap.includes(record?.key) &&
                dataMadrasah?.penerima_bos === 1 &&
                dataMadrasah?.jenis_madrasah === "R" && (
                  <Button
                    icon={<ReloadOutlined />}
                    onClick={() => handleAction(record?.key)}>
                    Lihat Data
                  </Button>
                )}
            </Space>
          );
        } else {
          return (
            <Space>
              {namaFile && (
                <>
                  <Button
                    icon={<ReloadOutlined />}
                    onClick={() => handleAfterApproval(record, namaFile)}>
                    Lihat Dokumen
                  </Button>
                </>
              )}
              {fileList.length !== 0 &&
                listLap.includes(record?.key) &&
                dataMadrasah?.penerima_bos === 1 &&
                dataMadrasah?.jenis_madrasah === "R" && (
                  <Button
                    icon={<ReloadOutlined />}
                    onClick={() => handleAction(record?.key)}>
                    Lihat Data
                  </Button>
                )}
            </Space>
          );
        }
      },
    },
  ];

  const handleAction = async (action: any) => {
    if (action === "docRKAM6") {
      try {
        const res = await getLaporanErkam({
          tahun: auth?.isTahun,
          madrasahId: dataMadrasah?.madrasah_id,
        });
        if (res) {
          setOpenModalAction(true);
          setTitle(action);
          setDataLaporan(res || []);
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      setOpenModalAction(true);
      setTitle(action);
      setDataAction({
        tahap: dataState?.tahap,
        checkDataMadrasah: tmpSpesialBOSTahap2.includes(dataMadrasah?.nsm),
        auth: auth,
        madrasahId: dataMadrasah?.madrasah_id,
        status: "s",
      });
    }
  };

  const handleAfterApproval = (record: any, fileData: any) => {
    let typeDoc: any = record?.type;
    setOpenModal(true);
    setTitle(record?.nama);
    // setID(fileData);
    setTipeFile(typeDoc);
    getDownloadDokumen(fileData, typeDoc);
  };

  const getDownloadDokumen = async (params: any, typeDoc: any) => {
    setLoading(true);
    try {
      const res: any = await getDokumenRencanaDefinitifAxios(
        params,
        auth,
        typeDoc,
        dataMadrasah,
      );
      if (res) {
        setPdfFile(res);
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  if (
    !tmpSpesialBOSTahap2.includes(dataMadrasah?.nsmMadrasah) &&
    dataState?.tahap === "2"
  ) {
    dataTableDokumen = dataTableDokumen.filter(
      (item) => !["docPPK5", "docBPD", "docPTJM3"].includes(item.key),
    );
  } else {
    dataTableDokumen = dataTableDokumen.filter(
      (item) => !["docSPTJB"].includes(item.key),
    );
  }

  dataTableDokumen =
    ([1, 2].includes(dataMadrasah?.penerima_bos) &&
      dataTableDokumen.filter((item) => item.key !== "docSPPD")) ||
    dataTableDokumen.filter((item) => item.key !== "docLpj1");
  // console.log(dataMadrasah);
  // console.log(dataTableDokumen);
  return (
    <>
      <BreadCrumb
        routes={itemBreadcrumb}
        title={`Kab - Kota ${dataKabkota?.nama}`}
        back={true}
        toBack={() =>
          route.push({
            pathname: pathTmp,
            state: {
              page: dataState?.page || 1,
              pageSize: dataState?.pageSize || 10,
              tahap: dataState?.tahap,
              ...dataState?.dataProvinsi,
            },
          })
        }
      />
      <div className="m-5 p-5 bg-white shadow-md rounded">
        <div className="mb-4 flex justify-end">
          <div className="mr-4">
            <Space wrap>
              <ButtonDropdownExport handleExportFile={handleExportFile} />
            </Space>
          </div>
          <InputSearch
            className="w-3/4"
            onChange={(e: any) => setSearch(e.currentTarget.value)}
          />
        </div>
        <Table
          loading={loading}
          scroll={{ x: "1300" }}
          columns={columns}
          dataSource={dataTable}
          bordered
        />
      </div>
      <Modal
        open={openModalData}
        width={1200}
        title={`Lihat Dokumen`}
        onCancel={() => {
          setOpenModalData(!openModalData);
          setDataMadrasah(null);
          setNilaiPortalBos(null);
        }}
        footer={[
          <Button
            onClick={() => {
              setOpenModalData(!openModalData);
              setDataMadrasah(null);
              setNilaiPortalBos(null);
            }}>
            Kembali
          </Button>,
        ]}>
        <Descriptions className="mb-2 px-6" layout="vertical" bordered>
          <Descriptions.Item label={`Alokasi Dana BOS TA. ${auth?.isTahun}`}>
            <div className="mb-2 text-xl font-bold">
              {formatRupiah(
                nilaiPortalBos?.apbn_bos_semester1 +
                  nilaiPortalBos?.apbn_bos_semester2,
              )}
            </div>
          </Descriptions.Item>
          <Descriptions.Item
            label={`Nominal APBN-BOS Tahap ${dataState?.tahap}`}>
            <div className="mb-2 text-xl font-bold">
              {formatRupiah(
                (dataState?.tahap === "1" &&
                  nilaiPortalBos?.apbn_bos_semester1) ||
                  nilaiPortalBos?.apbn_bos_semester2,
              )}
            </div>
          </Descriptions.Item>
          <Descriptions.Item label="Jumlah Siswa">
            <div className="mb-2 text-xl font-bold">
              {nilaiPortalBos?.jumlahSiswa} Orang
            </div>
          </Descriptions.Item>
        </Descriptions>
        {(dataState?.tahap === "1" && (
          <>
            <Table
              loading={loading}
              columns={columnsDokumen}
              dataSource={
                dataTableDokumen.length
                  ? dataTableDokumen.filter(
                      (item) => item.tahap === 1 && item.key !== "docTBUP",
                    )
                  : []
              }
              bordered
              pagination={false}
            />
          </>
        )) || (
          <Table
            loading={loading}
            columns={columnsDokumen}
            dataSource={
              dataTableDokumen.length
                ? dataTableDokumen.filter(
                    (item) => item.tahap === 2 && item.key !== "docTBUP",
                  )
                : []
            }
            bordered
            pagination={false}
          />
        )}
      </Modal>
      <Modal
        open={openModal}
        width={1200}
        title={`Dokumen ${title}`}
        onCancel={() => setOpenModal(!openModal)}
        footer={[
          <Button onClick={() => setOpenModal(!openModal)}>Kembali</Button>,
        ]}>
        {tipeFile === "application/pdf" ? (
          <object
            aria-labelledby="rkam"
            data={pdfFile}
            type={tipeFile}
            width="100%"
            height="400px"></object>
        ) : (
          <>
            <Alert
              message="Informasi"
              description="File excel sudah berhasil di download, mohon diperiksa kembali!"
              type="info"
              showIcon
            />
          </>
        )}
      </Modal>
      <ModalDokumenAction
        openModal={openModalAction}
        dataAction={null}
        dataBOS={null}
        dataFile={[]}
        dataLaporan={dataLaporan}
        dataMadrasah={dataAction}
        onClose={() => setOpenModalAction(false)}
        action={title}
      />
    </>
  );
};

export default RekapitulasiPencairanBosMadrasah;
