/** @format */

import React from "react";
import { Modal, Form, Alert, Input } from "antd";

type deleteModal = {
  openModal: boolean;
  module: any;
  informationMessage: any;
  onClose: () => void;
  onDelete: (values) => void;
};

const ModalDeleteAllTransaksi = (params: deleteModal) => {
  const [form] = Form.useForm();
  const handleFinish = (values: any) => {
    params.onDelete(values);
  };
  return (
    <>
      <Modal
        open={params.openModal}
        title={`Hapus Semua Transaksi ${params?.module}`}
        onCancel={params.onClose}
        onOk={() => form.submit()}
        okType="danger"
        okText="Hapus">
        <Alert
          message="Perhatian"
          description={params?.informationMessage}
          type="error"
          showIcon
        />
        <Form
          form={form}
          key="DeleteAll"
          onFinish={handleFinish}
          layout="vertical">
          <Form.Item
            rules={[
              {
                required: true,
                message: "Keterangan tidak boleh kosong!",
              },
            ]}
            label="Keterangan"
            name="keterangan">
            <Input.TextArea placeholder={"Keterangan"} />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
export default ModalDeleteAllTransaksi;
