/** @format */

import React, { useState, useEffect } from "react";
import { BreadCrumb } from "../../../../../components";
import { useSelector } from "react-redux";
import {
  // Modal,
  Tag,
  Space,
  Table,
  Button,
  // Form,
  // Input,
  Steps,
  Upload,
} from "antd";
import // ButtonTableApproval,
// ButtonTableDelete,
"../../../../../components/Button";
// import moment from "moment";
// import "moment/locale/id";
import notifAlert from "../../../../../components/NotifAlert";
import {
  UploadOutlined,
  // DownloadOutlined
} from "@ant-design/icons";
import type { UploadProps } from "antd";
import XLSX from "xlsx";
import InputSearch from "../../../../../components/InputSearch";
import { formatRupiah } from "../../../../../utils/helper";
// import jsPDF from "jspdf";
// import * as FileSaver from "file-saver";
// import ExcelJS from "exceljs";
// import autoTable from "jspdf-autotable";
import { useHistory } from "react-router-dom";

const ActionPencairan = () => {
  const route = useHistory();
  const dataState: any = route ? route.location.state : null;
  const itemBreadcrumb = [
    { path: "/", breadcrumbName: "Home" },
    { path: "/", breadcrumbName: "Laporan" },
    { path: "/", breadcrumbName: "Laporan Rencana" },
    { path: "/", breadcrumbName: "Pencairan Dokumen BOS" },
  ];
  const auths = useSelector((state: any) => state.auth);
  // const dispatch = useDispatch();
  const store = useSelector((state: any) => state.store);
  // const [formData] = Form.useForm();
  const [current, setCurrent] = useState(0);
  // const [id, setID] = useState<any>(null);
  const auth = auths?.data || null;
  const [search, setSearch] = useState<any>(null);
  const [tmpMadrasah, setTmpMadrasah] = useState<any>([]);
  // const [dataMadrasah, setDataMadrasah] = useState<any>(null);
  // const [fileImport, setFileImport] = useState<any>([]);
  // const [openModal, setOpenModal] = useState<any>(false);
  const refProvinsi = store.provinsi || [];
  const refKabkota = store.kabkota || [];
  // const [dataListMadrasahBos, setDataListMadrasahBos] = useState<any>([]);
  const [selectedFileList, setSelectFileList] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  // const { RangePicker } = DatePicker;
  const { Step } = Steps;
  const { Dragger } = Upload;

  const handleFileExcel = async (files: any) => {
    var reader = await new FileReader();
    let elements: any = [];
    setLoading(true);
    // setFileXLS(file.originFileObj);
    try {
      reader.onload = function (e: any) {
        var data = new Uint8Array(e.target.result);
        var workbook = XLSX.read(data, { type: "array" });
        var firstSheet = workbook.SheetNames[0];
        // eslint-disable-next-line array-callback-return
        XLSX.utils.sheet_to_json(workbook.Sheets[firstSheet]).map((el: any) => {
          elements.push(el.NSM.toString());
        });

        if (elements.length) {
          // eslint-disable-next-line array-callback-return
          const blob = new Blob([files.file], {
            type: files.file.type,
          });
          const link = window.URL.createObjectURL(blob);
          const typeTmp = ".xlsx";

          let fileTmp: any = [
            {
              status: "done",
              uid: files.file.uid,
              name: files.file.name,
              originFileObj: files.file,
              url: link,
              thumbUrl: `${link}${typeTmp}`,
            },
          ];

          setSelectFileList(fileTmp);
          // setFileImport(elements);
        }
      };

      reader.readAsArrayBuffer(files.file);
    } catch (error) {
      notifAlert({ type: "error", description: "Upload file gagal!" });
    }
    setLoading(false);
  };

  const props: UploadProps = {
    name: "file",
    multiple: false,
    fileList: selectedFileList,
    accept: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    customRequest: handleFileExcel,
    listType: "picture",
    onChange(info: any) {
      const { status } = info.file;

      if (status !== "removed" && status !== "uploading") {
        setSelectFileList([info.file]);
      }
      if (status !== "removed") {
        setSelectFileList([info.file]);
        // handleFileUpload(info.fileList);
      }
      if (status === "done") {
        // message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === "error") {
        // message.error(`${info.file.name} file upload failed.`);
      }
    },
    beforeUpload(file: any) {
      const isExcel =
        file.type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";

      if (!isExcel) {
        notifAlert({
          type: "error",
          description: `${file.name} format yang di upload harus excel`,
        });
      }

      return isExcel || Upload.LIST_IGNORE;
    },
    onDrop(e: any): void {
      console.log("Dropped files", e.dataTransfer.files);
    },
    onRemove(file: any) {
      setSelectFileList([]);
      // setFileImport([]);
    },
  };

  const steps = [
    {
      title: "List Pencairan BOS",
      content: "list",
    },
    {
      title: "Import Pencairan BOS",
      content: "import",
    },
    {
      title: "Madrasah Penerima BOS",
      content: "proses",
    },
  ];

  useEffect(() => {
    if (auths?.isLogin) {
      getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth?.isTahun]);

  /** Filter Search */
  let dataTable: any = search
    ? tmpMadrasah.filter((item: any) => {
        const val = search.toLowerCase();
        return (
          (item.nsmMadrasah !== null &&
            (item.nsmMadrasah || "").toString().toLowerCase().includes(val)) ||
          (item.namaMadrasah !== null &&
            (item.namaMadrasah || "").toString().toLowerCase().includes(val))
        );
      })
    : tmpMadrasah;

  const getData = async () => {
    setLoading(true);
    // try {

    // const res = await getDokumenPencairan(filterParam);
    const res = dataDummyPencairan();
    setTmpMadrasah(res || []);
    console.log(res);
    // }
    // } catch (error) {
    //   console.log(error);
    // }
    setLoading(false);
  };

  const next = () => {
    // setLoading(true);
    setCurrent(current + 1);
  };

  const prev = () => {
    // setLoading(true);
    setCurrent(current - 1);
  };

  // const handleDeleteUpload = async (idFile: any) => {
  //   try {
  //     await deleteDokumenPencairan(idFile);
  //     notifAlert({
  //       type: "success",
  //       description: "Penghapusan Dokumen Berhasil",
  //     });
  //     getData();
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  let columnsDokumen: any = [
    {
      title: "NSM",
      dataIndex: "nsmMadrasah",
      key: "nsmMadrasah",
    },
    {
      title: "Nama",
      dataIndex: "namaMadrasah",
      key: "namaMadrasah",
    },
    {
      title: "Jenis Upload",
      dataIndex: "jenisMadrasah",
      key: "jenisMadrasah",
      render: (jenisMadrasah) => {
        return (
          <Tag color={jenisMadrasah === "R" ? "#008000" : "#ffca27"}>
            {jenisMadrasah === "M" ? "Upload Manual" : "eRKAM"}
          </Tag>
        );
      },
    },
    {
      title: "Tahap",
      dataIndex: "tahap",
      key: "tahap",
      render: (tahap) => (tahap === 1 && "Tahap 1") || "Tahap 2",
    },
    {
      title: "Nilai Pencairan",
      dataIndex: "nilaiPencairan",
      key: "nilaiPencairan",
      render: (nilaiPencairan) => (
        <div className="text-right">{formatRupiah(nilaiPencairan)}</div>
      ),
    },
    {
      title: "Status",
      key: "statusPencairan",
      dataIndex: "statusPencairan",
      render: (statusPencairan) => {
        return (
          <Tag color={statusPencairan ? "#008000" : "#ffca27"}>
            {statusPencairan ? "Disetujui" : "Belum Disetujui"}
          </Tag>
        );
      },
    },
  ];
  const columnsList: any = [
    {
      title: "NSM",
      dataIndex: "nsm",
      key: "nsm",
    },
    {
      title: "Madrasah",
      dataIndex: "nama",
      key: "nama",
    },
    {
      title: "Jenjang",
      key: "jenjang",
      render: (record) => record.jenjang.nama,
    },
    {
      title: "Provinsi",
      dataIndex: "kode_provinsi",
      key: "kode_provinsi",
      render: (kode_provinsi) =>
        refProvinsi
          .filter((item) => item.kode === kode_provinsi)
          .map((item) => item.nama),
    },
    {
      title: "Kab / Kota",
      dataIndex: "kode_kabkota",
      key: "kode_kabkota",
      render: (kode_kabkota) =>
        refKabkota
          .filter((item) => item.kode === kode_kabkota)
          .map((item) => item.nama),
    },
    {
      title: "Aksi",
      key: "aksi",
      render: (record: any) => (
        <Space size="small">
          {/* <ButtonTableDelete
            loading={loading}
            onClick={() => {
              Modal.confirm({
                title: "Perhatian",
                content: "Anda akan hapus data ini?",
                onOk() {
                  return handleDelete(record);
                },
                onCancel() {},
                okText: "Hapus",
                cancelText: "Batal",
                okType: "danger",
              });
            }}
          /> */}
        </Space>
      ),
    },
  ];

  const handleSave = async () => {
    // let payload: any = {
    //   statusPencairan: 1,
    //   // id: id,
    //   // tahun: dataMadrasah?.tahun,
    //   // tahap: dataMadrasah?.tahap,
    //   // nsmMadrasah: dataMadrasah?.nsmMadrasah,
    //   // madrasahId: dataMadrasah?.madrasahId,
    //   // nilaiPencairan: dataMadrasah?.nilaiPencairan,
    //   // noBuktiPencairan: values.noBuktiPencairan,
    // };
    try {
      // await editDokumenPencairan(payload, id);
      notifAlert({
        type: "success",
        description: "Dokumen Berhasil disetujui",
      });
      // getData();
    } catch (error) {
      console.log(error);
    }
  };

  // const handleReset = () => {
  //   setOpenModal(false);
  //   setDataMadrasah(null);
  //   setID(null);
  //   formData.resetFields();
  // };

  return (
    <>
      <BreadCrumb
        routes={itemBreadcrumb}
        title={`Pencairan`}
        back={true}
        toBack={() =>
          route.push({
            pathname: "../pencairan-dokumen-bos",
            state: {
              page: dataState?.page || 1,
              pageSize: dataState?.pageSize || 10,
            },
          })
        }
      />

      <div className="m-5 p-5 bg-white shadow-md rounded">
        <Steps current={current} size="small">
          {steps.map((item) => (
            <Step key={item.title} title={item.title} />
          ))}
        </Steps>
        <div className="my-4">
          {(steps[current].content === "list" && (
            <>
              <div className="mb-4 flex justify-end">
                <div className="mr-4">
                  <Space wrap>
                    <Button type="primary">Export</Button>
                  </Space>
                </div>
                <InputSearch
                  className="w-3/4"
                  onChange={(e: any) => setSearch(e.currentTarget.value)}
                />
              </div>
              <Table
                // rowSelection={rowSelection}
                loading={loading}
                rowKey={(record) => record.madrasahId}
                columns={columnsDokumen}
                dataSource={dataTable}
                bordered
                pagination={false}
              />
            </>
          )) ||
            (steps[current].content === "import" && (
              <div className="flex flex-row mb-10">
                <div className="flex-1 p-4">
                  <Dragger {...props}>
                    <p className="ant-upload-drag-icon">
                      <UploadOutlined />
                    </p>
                    <p className="ant-upload-text">
                      Klik atau tarik File Excel
                    </p>
                    <p className="ant-upload-hint">
                      Format file .excel yang akan di upload harus sesuai dengan
                      file template hasil export.
                    </p>
                  </Dragger>
                </div>
              </div>
            )) ||
            (steps[current].content === "proses" && (
              <Table
                // rowKey={(record) => record?.nsm}
                dataSource={[]}
                columns={columnsList}
                bordered
              />
            ))}
          <div className="py-2 flex justify-between">
            {current > 0 && current < 3 && (
              <Button
                // loading={loading}
                style={{ margin: "0 8px" }}
                onClick={() => prev()}>
                Kembali
              </Button>
            )}
            {current <= 2 && (
              <Button
                type="primary"
                className="text-right"
                onClick={() => next()}>
                Lanjut
              </Button>
            )}
            {current === 2 && (
              <Button
                type="primary"
                loading={loading}
                onClick={() => handleSave()}
                // disabled={dataListMadrasahBos.length ? false : true}
              >
                Simpan
              </Button>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

const dataDummyPencairan = () => [
  {
    id: "0c4qsy04ewj6m",
    tahun: 2023,
    kantorKabkotaId: "42f0d936-be54-4fe1-997e-2767058bf4e0",
    kodeKabkota: "32.12",
    namaKantorKabkota: "INDRAMAYU",
    kantorProvinsiId: "bc853c62-8156-686a-3f0c-868469557330",
    kodeProvinsi: "32",
    namaKantorProvinsi: "Kantor Wilayah Kementerian Agama Provinsi Jawa Barat",
    kantorPusatId: "4ae99146-b711-4c2f-af06-9bbbd37ccf84",
    namaKantorPusat: "bc853c62-8156-686a-3f0c-868469557330",
    madrasahId: "0164c520-8e60-4881-a0bb-147cc392b12b",
    nsmMadrasah: "121232120037",
    namaMadrasah: "MTSS DARUL FALAH",
    jenjangMadrasah: "mts",
    jenisMadrasah: "R",
    penerimaBos: 1,
    tahap: 1,
    statusPencairan: 0,
    nilaiPencairan: 364200000,
    noBuktiPencairan: "",
    seqNo: 14,
    created_by: "7213d10d-5c66-4d32-855b-b8d83efcb3b6",
    updated_by: null,
    created_at: "2023-04-23T01:51:34.308Z",
    updated_at: "2023-04-23T01:51:34.308Z",
  },
  {
    id: "0cax3eewrsdh2",
    tahun: 2023,
    kantorKabkotaId: "90572196-c375-4f36-86e9-a4b1beddf727",
    kodeKabkota: "32.14",
    namaKantorKabkota: "PURWAKARTA",
    kantorProvinsiId: "bc853c62-8156-686a-3f0c-868469557330",
    kodeProvinsi: "32",
    namaKantorProvinsi: "Kantor Wilayah Kementerian Agama Provinsi Jawa Barat",
    kantorPusatId: "4ae99146-b711-4c2f-af06-9bbbd37ccf84",
    namaKantorPusat: "bc853c62-8156-686a-3f0c-868469557330",
    madrasahId: "43bdcbb6-aab5-406d-a18b-80f5816e4460",
    nsmMadrasah: "111232140048",
    namaMadrasah: "MIS AL-MAQSUDIYYAH",
    jenjangMadrasah: "mi",
    jenisMadrasah: "R",
    penerimaBos: 1,
    tahap: 1,
    statusPencairan: 0,
    nilaiPencairan: 130190000,
    noBuktiPencairan: "",
    seqNo: 16,
    created_by: "21c05066-d150-4935-b300-571a32fa8b0c",
    updated_by: null,
    created_at: "2023-05-12T05:35:44.230Z",
    updated_at: "2023-05-12T05:35:44.230Z",
  },
  {
    id: "0cgvvrc7m3sa1",
    tahun: 2023,
    kantorKabkotaId: "0fa14320-17da-4416-bfa8-30242ae2a3fe",
    kodeKabkota: "32.18",
    namaKantorKabkota: "PANGANDARAN",
    kantorProvinsiId: "bc853c62-8156-686a-3f0c-868469557330",
    kodeProvinsi: "32",
    namaKantorProvinsi: "Kantor Wilayah Kementerian Agama Provinsi Jawa Barat",
    kantorPusatId: "4ae99146-b711-4c2f-af06-9bbbd37ccf84",
    namaKantorPusat: "bc853c62-8156-686a-3f0c-868469557330",
    madrasahId: "57aaa08a-1028-4d49-ae81-899c51e40307",
    nsmMadrasah: "111232180043",
    namaMadrasah: "MIS CIKULU",
    jenjangMadrasah: "mi",
    jenisMadrasah: "R",
    penerimaBos: 1,
    tahap: 1,
    statusPencairan: 0,
    nilaiPencairan: 51750000,
    noBuktiPencairan: "",
    seqNo: 28,
    created_by: "4057e6c1-a447-4cbb-bd28-3bba0daeeb0c",
    updated_by: null,
    created_at: "2023-05-30T06:05:52.446Z",
    updated_at: "2023-05-30T06:05:52.446Z",
  },
  {
    id: "0cmgkm419de14",
    tahun: 2023,
    kantorKabkotaId: "d38732ac-a811-47d8-ab31-4e954b2fa071",
    kodeKabkota: "32.79",
    namaKantorKabkota: null,
    kantorProvinsiId: "bc853c62-8156-686a-3f0c-868469557330",
    kodeProvinsi: "32",
    namaKantorProvinsi: null,
    kantorPusatId: "4ae99146-b711-4c2f-af06-9bbbd37ccf84",
    namaKantorPusat: null,
    madrasahId: "086e4579-52d4-448e-9b85-2113f0564f37",
    nsmMadrasah: "121232790007",
    namaMadrasah: "MTSS SA AL-ABROR",
    jenjangMadrasah: "mts",
    jenisMadrasah: "M",
    penerimaBos: 1,
    tahap: 1,
    statusPencairan: 0,
    nilaiPencairan: 17600000,
    noBuktiPencairan: "",
    seqNo: 32,
    created_by: "8af4f575-0a69-4cf8-a992-be371d6ecdbe",
    updated_by: null,
    created_at: "2023-06-11T02:08:07.562Z",
    updated_at: "2023-06-11T02:08:07.562Z",
  },
  {
    id: "0djs181eeq7bh",
    tahun: 2023,
    kantorKabkotaId: "d4962bf7-b4e2-47e2-96af-1e5af1976087",
    kodeKabkota: "32.07",
    namaKantorKabkota: "CIAMIS",
    kantorProvinsiId: "bc853c62-8156-686a-3f0c-868469557330",
    kodeProvinsi: "32",
    namaKantorProvinsi: "Kantor Wilayah Kementerian Agama Provinsi Jawa Barat",
    kantorPusatId: "4ae99146-b711-4c2f-af06-9bbbd37ccf84",
    namaKantorPusat: "bc853c62-8156-686a-3f0c-868469557330",
    madrasahId: "fb63c113-787a-44b9-bdae-989bf1dcecba",
    nsmMadrasah: "111232070121",
    namaMadrasah: "MIS BAREGBEG",
    jenjangMadrasah: "mi",
    jenisMadrasah: "R",
    penerimaBos: 1,
    tahap: 1,
    statusPencairan: 0,
    nilaiPencairan: 54000000,
    noBuktiPencairan: "",
    seqNo: 38,
    created_by: "9628ff67-caa6-4cc0-af8e-fac0248f5e6e",
    updated_by: null,
    created_at: "2023-09-13T02:43:51.155Z",
    updated_at: "2023-09-13T02:43:51.155Z",
  },
  {
    id: "0dnjasz0nvdbk",
    tahun: 2023,
    kantorKabkotaId: "d4962bf7-b4e2-47e2-96af-1e5af1976087",
    kodeKabkota: "32.07",
    namaKantorKabkota: "CIAMIS",
    kantorProvinsiId: "bc853c62-8156-686a-3f0c-868469557330",
    kodeProvinsi: "32",
    namaKantorProvinsi: "Kantor Wilayah Kementerian Agama Provinsi Jawa Barat",
    kantorPusatId: "4ae99146-b711-4c2f-af06-9bbbd37ccf84",
    namaKantorPusat: "bc853c62-8156-686a-3f0c-868469557330",
    madrasahId: "3afd6f45-6757-4d1f-a679-f5597e7ee227",
    nsmMadrasah: "111232070004",
    namaMadrasah: "MIS BOJONGSARI",
    jenjangMadrasah: "mi",
    jenisMadrasah: "R",
    penerimaBos: 1,
    tahap: 1,
    statusPencairan: 0,
    nilaiPencairan: 140400000,
    noBuktiPencairan: "",
    seqNo: 39,
    created_by: "9628ff67-caa6-4cc0-af8e-fac0248f5e6e",
    updated_by: null,
    created_at: "2023-09-21T06:48:43.397Z",
    updated_at: "2023-09-21T06:48:43.397Z",
  },
  {
    id: "0dnq96tt9vd5c",
    tahun: 2023,
    kantorKabkotaId: "ea778f65-e7d9-4afc-e7a7-b76674cdd07c",
    kodeKabkota: "32.07",
    namaKantorKabkota:
      "Kantor Kabupaten Aceh Selatan Kementrian Agama Provinsi Aceh",
    kantorProvinsiId: "88243653-5177-0f52-5bf4-14c8aade4195",
    kodeProvinsi: "32",
    namaKantorProvinsi: "Kantor Wilayah Kementerian Agama Provinsi Aceh",
    kantorPusatId: "4ae99146-b711-4c2f-af06-9bbbd37ccf84",
    namaKantorPusat: "88243653-5177-0f52-5bf4-14c8aade4195",
    madrasahId: "08d821d4-86e1-4321-81c8-3761155594ab",
    nsmMadrasah: "121232070042",
    namaMadrasah: "MTSS CINYASAG",
    jenjangMadrasah: "mts",
    jenisMadrasah: "R",
    penerimaBos: 1,
    tahap: 1,
    statusPencairan: 0,
    nilaiPencairan: 121550000,
    noBuktiPencairan: "",
    seqNo: 40,
    created_by: "9628ff67-caa6-4cc0-af8e-fac0248f5e6e",
    updated_by: null,
    created_at: "2023-09-22T06:20:47.570Z",
    updated_at: "2023-09-22T06:20:47.570Z",
  },
  {
    id: "0dnv72jx5ne7p",
    tahun: 2023,
    kantorKabkotaId: "d4962bf7-b4e2-47e2-96af-1e5af1976087",
    kodeKabkota: "32.07",
    namaKantorKabkota: "CIAMIS",
    kantorProvinsiId: "bc853c62-8156-686a-3f0c-868469557330",
    kodeProvinsi: "32",
    namaKantorProvinsi: "Kantor Wilayah Kementerian Agama Provinsi Jawa Barat",
    kantorPusatId: "4ae99146-b711-4c2f-af06-9bbbd37ccf84",
    namaKantorPusat: "bc853c62-8156-686a-3f0c-868469557330",
    madrasahId: "201637df-d559-4f76-abb3-3c554545f37a",
    nsmMadrasah: "111232070025",
    namaMadrasah: "MIS HANDAPHERANG",
    jenjangMadrasah: "mi",
    jenisMadrasah: "R",
    penerimaBos: 1,
    tahap: 1,
    statusPencairan: 0,
    nilaiPencairan: 124200000,
    noBuktiPencairan: "",
    seqNo: 41,
    created_by: "9628ff67-caa6-4cc0-af8e-fac0248f5e6e",
    updated_by: null,
    created_at: "2023-09-22T03:30:42.921Z",
    updated_at: "2023-09-22T03:30:42.921Z",
  },
];

export default ActionPencairan;
