/** @format */

import React from "react";
import { useSelector } from "react-redux";
import { Collapse, Alert, Divider } from "antd";
import { BreadCrumb } from "../../components";

const { Panel } = Collapse;

const Panduan = () => {
  const itemBreadcrumb = [
    { path: "/", breadcrumbName: "Home" },
    { path: "/", breadcrumbName: "Panduan" },
  ];
  const auths = useSelector((state: any) => state.auth);
  const store = useSelector((state: any) => state.store);
  const auth = auths.data;
  const profile = store?.profile || null;
  const groupRole = auth?.group_role || null;
  let panduanList: any = [];
  switch (groupRole) {
    case "madrasah":
      panduanList = [
        {
          title:
            "Penjelasan fitur fitur e RKAM Tingkat Madrasah, Akses Website e RKAM oleh Admin Madrasah",
          url: "https://www.youtube.com/embed/e7RWNxYxLUY",
          key: "panduan1",
        },
        {
          title: "Fitur Utama pada Akun Madrasah",
          url: "https://www.youtube.com/embed/7tFA52OKuUg",
          key: "panduan2",
        },
        {
          title: "Penjelasan Fitur Tahun Anggaran",
          url: "https://www.youtube.com/embed/hYy1myvW63Y",
          key: "panduan3",
        },
        {
          title: "Penjelasan Fitur Notifikasi",
          url: "https://www.youtube.com/embed/6oPxtrujwFA",
          key: "panduan4",
        },
        {
          title: "Penjelasan Fitur Sinkronisasi Offline ke Online",
          url: "https://www.youtube.com/embed/6YHZ13dl1lU",
          key: "panduan5",
        },
        {
          title: "Penjelasan Fitur Profil User",
          url: "https://www.youtube.com/embed/LE2LwRMGwRI",
          key: "panduan6",
        },
        {
          title: "Penjelasan Fitur Dashboard",
          url: "https://www.youtube.com/embed/xs9Ctc0ooy8",
          key: "panduan7",
        },
        {
          title: "Penjelasan Fitur Profil Madrasah",
          url: "https://www.youtube.com/embed/FhhigJrz-Xo",
          key: "panduan8",
        },
        {
          title: "Penjelasan Fitur Pengaturan",
          url: "https://www.youtube.com/embed/GPYl_lk21LA",
          key: "panduan9",
        },
        {
          title: "Penjelasan Fitur Referensi",
          url: "https://www.youtube.com/embed/oLsmKacKMdo",
          key: "panduan10",
        },
        {
          title:
            "Penjelasan Fitur Rencana dan Alur Proses Pengisian Pagu Indikatif",
          url: "https://www.youtube.com/embed/u9fuLSF1KkE",
          key: "panduan11",
        },
        {
          title: "Penjelasan Penetapan Jadwal oleh Admin KabupatenKota",
          url: "https://www.youtube.com/embed/Hi0HCoby6MY",
          key: "panduan12",
        },
        {
          title:
            "Penjelasan Proses Pengisian Pendapatan Pagu Indikatif oleh Kepala Madrasah",
          url: "https://www.youtube.com/embed/qvKSaZReADo",
          key: "panduan13",
        },
        {
          title: "Penjelasan Alur Proses Pengisian Belanja Pagu Indikatif",
          url: "https://www.youtube.com/embed/3ii85QTWiFs",
          key: "panduan14",
        },
        {
          title: "Penjelasan Pengisian Rincian Biaya oleh Staff Atau Bendahara",
          url: "https://www.youtube.com/embed/qQaclZB5icM",
          key: "panduan15",
        },
        {
          title:
            "Penjelasan Persetujuan Rincian Biaya Indkatif oleh Kepala Madrasah",
          url: "https://www.youtube.com/embed/9dsr9d4_jho",
          key: "panduan16",
        },
        {
          title:
            "Penjelasan Alur Proses Pengisian Pendapatan dan Belanja Pagu Definitif",
          url: "https://www.youtube.com/embed/WtbPec4QYco",
          key: "panduan17",
        },
        {
          title:
            "Penjelasan Import Rencana Definitif dari Rencana Indikatif oleh Kepala Madrasah",
          url: "https://www.youtube.com/embed/Q1aTtmuGZOo",
          key: "panduan18",
        },
        {
          title: "Fitur Realisasi pada Tingkat Madrasah",
          url: "https://www.youtube.com/embed/EWvtxA3Dy6M",
          key: "panduan19",
        },
        {
          title: "Alur Proses Realisasi Secara Umum",
          url: "https://www.youtube.com/embed/1ek2RqVhX-o",
          key: "panduan20",
        },
        {
          title: "Fitur Realisasi Pendapatan",
          url: "https://www.youtube.com/embed/UyWv8ziifEE",
          key: "panduan21",
        },
        {
          title: "Fitur Realisasi Pindah Buku",
          url: "https://www.youtube.com/embed/N1k3b79SNZM",
          key: "panduan22",
        },
        {
          title: "Fitur Realisasi Pengeluaran Kegiatan",
          url: "https://www.youtube.com/embed/enkduhTPoOE",
          key: "panduan23",
        },
        {
          title: "Fitur Realisasi Pengeluaran Pajak",
          url: "https://www.youtube.com/embed/LDuYwiJC4bk",
          key: "panduan24",
        },
        {
          title: "Fitur Realisasi Pengembalian Dana",
          url: "https://www.youtube.com/embed/g4_2fMs11oE",
          key: "panduan25",
        },
        {
          title: "Fitur Realisasi Output Kegiatan",
          url: "https://www.youtube.com/embed/_zT5LMrzrdw",
          key: "panduan26",
        },
        {
          title: "Penjelasan Fitur Laporan pada Akun Tingkat Madrasah",
          url: "https://www.youtube.com/embed/zTwrvtGV6cc",
          key: "panduan27",
        },
        {
          title: "Fitur Laporan Rencana",
          url: "https://www.youtube.com/embed/bs-GvkUYrAQ",
          key: "panduan28",
        },
        {
          title: "Fitur Laporan Penatausahaan",
          url: "https://www.youtube.com/embed/L8pOzAmBARk",
          key: "panduan29",
        },
        {
          title: "Fitur Laporan Realisasi",
          url: "https://www.youtube.com/embed/Yj7qOqd0mwk",
          key: "panduan30",
        },
      ];
      break;
    case "kabkota":
    case "pengawas":
      panduanList = [
        {
          title:
            "Penjelasan fitur fitur eRKAM Tingkat Kabupaten Kota, Akses Website eRKAM",
          url: "https://www.youtube.com/embed/PIyWja7rMLg",
          key: "panduan1",
        },
        {
          title: "Fitur Utama pada Akun Kabupaten Kota",
          url: "https://www.youtube.com/embed/2oZRmiuwp3g",
          key: "panduan2",
        },
        {
          title: "Penjelasan Fitur Tahun Anggaran",
          url: "https://www.youtube.com/embed/z1YZGFkRHks",
          key: "panduan3",
        },
        {
          title: "Penjelasan Fitur Profil User",
          url: "https://www.youtube.com/embed/s6yfBvwqPGc",
          key: "panduan4",
        },
        {
          title: "Penjelasan Fitur Dashboard",
          url: "https://www.youtube.com/embed/TwPNP8e-kPg",
          key: "panduan5",
        },
        {
          title: "Penjelasan Fitur Referensi",
          url: "https://www.youtube.com/embed/hrPazf4_tAQ",
          key: "panduan6",
        },
        {
          title: "Penjelasan Fitur Laporan",
          url: "https://www.youtube.com/embed/pM-vlnAqRhg",
          key: "panduan7",
        },
        {
          title: "Penjelasan Fitur Komentar",
          url: "https://www.youtube.com/embed/hapASXSws4E",
          key: "panduan8",
        },
      ];
      break;
    case "provinsi":
      panduanList = [
        {
          title:
            "Penjelasan fitur fitur e RKAM Tingkat Provinsi dan Tahun Anggaran",
          url: "https://www.youtube.com/embed/hFjg6bI4nDA",
          key: "panduan1",
        },
        {
          title: "Penjelasan Fitur Profil User",
          url: "https://www.youtube.com/embed/YSin-bsq6qk",
          key: "panduan2",
        },
        {
          title: "Penjelasan Fitur Dashboard",
          url: "https://www.youtube.com/embed/JlVQWbyEkLA",
          key: "panduan3",
        },
        {
          title: "Penjelasan Fitur Pengaturan",
          url: "https://www.youtube.com/embed/pFAPE8rkKaE",
          key: "panduan4",
        },
        {
          title: "Penjelasan Fitur Referensi",
          url: "https://www.youtube.com/embed/oPr_iKmijfo",
          key: "panduan5",
        },
        {
          title: "Penjelasan Fitur Laporan",
          url: "https://www.youtube.com/embed/t09WvcHx9eQ",
          key: "panduan6",
        },
      ];
      break;
    case "pusat":
      panduanList = [
        {
          title: "Penjelasan fitur fitur e RKAM Tingkat Pusat",
          url: "https://www.youtube.com/embed/wTCzZUsojvM",
          key: "panduan1",
        },
        {
          title: "Fitur Utama pada Akun Pusat",
          url: "https://www.youtube.com/embed/CONo5vvEtUM",
          key: "panduan2",
        },
        {
          title: "Penjelasan Fitur Tahun Anggaran",
          url: "https://www.youtube.com/embed/N6yUIWU8Gf4",
          key: "panduan3",
        },
        {
          title: "Penjelasan Fitur Profil User",
          url: "https://www.youtube.com/embed/LwFGvco9OQ8",
          key: "panduan4",
        },
        {
          title: "Penjelasan Fitur Dashboard",
          url: "https://www.youtube.com/embed/UZ-VZSmuFlY",
          key: "panduan5",
        },
        {
          title: "Penjelasan Fitur Pengaturan",
          url: "https://www.youtube.com/embed/tI0TMRC_xRU",
          key: "panduan6",
        },
        {
          title: "Penjelasan Fitur Referensi",
          url: "https://www.youtube.com/embed/j9ifUJp0bz4",
          key: "panduan7",
        },
        {
          title: "Penjelasan Fitur Program dan Kegiatan",
          url: "https://www.youtube.com/embed/q5Nakp-oQUs",
          key: "panduan8",
        },
        {
          title: "Penjelasan Fitur Tahun",
          url: "https://www.youtube.com/embed/NAlJ1uBObCw",
          key: "panduan9",
        },
        {
          title: "Penjelasan Fitur Jenis Belanja",
          url: "https://www.youtube.com/embed/XfEi1cjHmfQ",
          key: "panduan10",
        },
        {
          title: "Penjelasan Fitur Komponen Biaya",
          url: "https://www.youtube.com/embed/4LHGtM2r1E8",
          key: "panduan11",
        },
        {
          title: "Penjelasan Fitur Kegiatan",
          url: "https://www.youtube.com/embed/bTMdMzOig7Y",
          key: "panduan12",
        },
        {
          title: "Penjelasan Fitur Sub Kegiatan",
          url: "https://www.youtube.com/embed/gXkNedrc0kE",
          key: "panduan13",
        },
        {
          title: "Penjelasan Fitur Satuan",
          url: "https://www.youtube.com/embed/SpTj5wt_KNU",
          key: "panduan14",
        },
        {
          title: "Penjelasan Fitur Pajak",
          url: "https://www.youtube.com/embed/HO9eq8oAz-Y",
          key: "panduan15",
        },
        {
          title: "Penjelasan Fitur Tipe Pencairan",
          url: "https://www.youtube.com/embed/ToFUvKtyZ60",
          key: "panduan16",
        },
        {
          title: "Penjelasan Fitur Madrasah",
          url: "https://www.youtube.com/embed/H3xejQznoOQ",
          key: "panduan17",
        },
        {
          title: "Penjelasan Fitur Sumber Dana Madrasah",
          url: "https://www.youtube.com/embed/LOmEXHuCVKY",
          key: "panduan18",
        },
        {
          title: "Penjelasan Fitur Laporan",
          url: "https://www.youtube.com/embed/-uSC7MCz7YQ",
          key: "panduan19",
        },
      ];
      break;
  }
  console.log(profile);

  return (
    <>
      <BreadCrumb routes={itemBreadcrumb} title="Panduan" />
      <div className="p-5">
        <div className="bg-white shadow-md p-4">
          <Alert
            message="Informasi Panduan Penggunaan Applikasi eRKAM"
            description="Dibawah ini adalah panduan bagaimana langkah - langkah penggunaan Applikasi eRKAM."
            type="info"
            showIcon
          />
          <Divider />
          {panduanList.length && (
            <Collapse collapsible="header" key="1" accordion>
              {panduanList.map((item: any) => {
                return (
                  <Panel header={item.title} key={item.key}>
                    <iframe
                      width={560}
                      height={315}
                      src={item.url}
                      title={item.title}
                      // frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen></iframe>
                  </Panel>
                );
              })}
            </Collapse>
          )}
        </div>
      </div>
    </>
  );
};
export default Panduan;
