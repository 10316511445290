/** @format */

import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  Tabs,
  Modal,
  Tag,
  Space,
  Table,
  Button,
  Alert,
  Form,
  Input,
} from "antd";
import { BreadCrumb } from "../../../components";
import { uuidv4 } from "../../../utils/helper";
import { ButtonTableApproval } from "../../../components/Button";
import {
  getDokumenRencanaDefinitif,
  statusDokumenRencanaDefinitif,
} from "../../../services/v2/planningservice/rencanapendapatandefinitif";
import notifAlert from "../../../components/NotifAlert";
import axios from "axios";
import { planningService } from "../../../services/v2/constant";
import FilterMadrasahUpload from "../Component/FilterMadrasahUpload";
import {
  getFileMadrasahManual,
  getMadrasahManual,
  statusUploadManual,
} from "../../../services/v2/planningservice/uploadmanualservices";
import {
  ExclamationCircleOutlined,
  ReloadOutlined,
  FileSearchOutlined,
} from "@ant-design/icons";
import { postDokumenPencairan } from "../../../services/v2/planningservice/pencairanservices";
import ModalDokumenAction from "../../../components/Modal/ModalActionDokumen";
import { getPlanningAll } from "../../../services/v2/planningservice";
import InputSearch from "../../../components/InputSearch";
import { getLaporanErkam } from "../../../services/v2/planningservice/laporanservices";
import { listDokumen } from "../../../utils/helper/helperBos";
export const baseURL: string =
  window.REACT_APP_API_URL ||
  // "https://gateway-dev.erkam.cronos.co.id/api";
  "https://staging-api-gateway.erkam-v2.kemenag.go.id/api";
const { TabPane } = Tabs;
const LaporanDokumen = () => {
  const itemBreadcrumb = [
    { path: "/", breadcrumbName: "Home" },
    { path: "/", breadcrumbName: "Laporan" },
    { path: "/", breadcrumbName: "Laporan Rencana" },
    { path: "/", breadcrumbName: "Kelengkapan Dokumen BOS" },
  ];
  const auths = useSelector((state: any) => state.auth);
  // const store = useSelector((state: any) => state.store);
  const [form] = Form.useForm();
  const [tabActive, setTabActive] = useState<any>("tahap1");
  const [fileList, setFileList] = useState<any>([]);
  const [id, setID] = useState<any>(null);
  let dataTableDokumen: any = listDokumen();
  const auth = auths?.data || null;
  const [dataAction, setDataAction] = useState<any>(null);
  const [dataMadrasah, setDataMadrasah] = useState<any>(null);
  const [tmpMadrasah, setTmpMadrasah] = useState<any>([]);
  const [dataLaporan, setDataLaporan] = useState<any>([]);
  const [pdfFile, setPdfFile] = useState<any>(null);
  const [title, setTitle] = useState<any>(null);
  const [tmpFilter, setTmpFilter] = useState<any>(null);
  const [tipeFile, setTipeFile] = useState<any>(null);
  const [openModal, setOpenModal] = useState<any>(false);
  const [openModalData, setOpenModalData] = useState<any>(false);
  const [search, setSearch] = useState<any>(null);
  const [openModalAction, setOpenModalAction] = useState<any>(false);
  const [loading, setLoading] = useState(false);
  const [activeTabKey, setActiveTabKey] = useState<string>("pm1");
  let listLap = ["docRKAM6", "docLpj1"];
  useEffect(() => {
    if (auths?.isLogin) {
      getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tmpFilter, auth?.isTahun, activeTabKey]);

  const onTabClick = (key) => {
    setTabActive(key);
    handleDokumen(dataMadrasah, key);
  };

  const getData = async () => {
    setLoading(true);
    let filterParam: any = `?kodeProvinsi=${auth?.kode_provinsi}`;
    if (auth?.group_role === "provinsi") {
      if (tmpFilter?.kabkota) {
        filterParam = `${filterParam}&kodeKabkota=${tmpFilter?.kabkota}`;
      }
    } else {
      filterParam = `${filterParam}&kodeKabkota=${auth?.kode_kabkota}`;
    }

    if (activeTabKey === "pm2") {
      filterParam = `${filterParam}&jenisMadrasah=M`;
    } else {
      filterParam = `${filterParam}&jenisMadrasah=R`;
    }

    const madrasahTmp = (await getMadrasahManual(filterParam)) || [];
    let madrasah =
      (tmpFilter?.penerimaBos !== undefined &&
        madrasahTmp.filter(
          (item) => item.penerimaBos === tmpFilter?.penerimaBos,
        )) ||
      madrasahTmp;

    madrasah =
      (tmpFilter?.status !== undefined &&
        madrasah.filter((item) => item.statusValidasi === tmpFilter.status)) ||
      madrasah;

    setTmpMadrasah(madrasah || []);
    setLoading(false);
  };

  const handleChangeValues = (values: any) => {
    setTmpFilter(values);
  };

  let swastaMadrasah: any =
    auth?.group_role === "provinsi"
      ? (tmpMadrasah.length &&
          tmpMadrasah.filter((item) => item.jenjangMadrasah === "ma")) ||
        []
      : (tmpMadrasah.length &&
          tmpMadrasah.filter((item) => item.jenjangMadrasah !== "ma")) ||
        [];

  let dataTable: any = search
    ? swastaMadrasah.filter((item: any) => {
        const val = search.toLowerCase();
        return (
          (item.nsmMadrasah !== null &&
            (item.nsmMadrasah || "").toString().toLowerCase().includes(val)) ||
          (item.namaMadrasah !== null &&
            (item.namaMadrasah || "").toString().toLowerCase().includes(val))
        );
      })
    : swastaMadrasah;

  let columnsDokumen: any = [
    {
      title: "Nama",
      dataIndex: "nama",
      key: "nama",
    },
    {
      title: "Nama File",
      key: "namaFile",
      render: (record) => {
        let tahapTmp: any = tabActive === "tahap1" ? "1" : "2";
        let namaFile = fileList.find(
          (item) =>
            item.kodeDok === record.kode && item.tahap.toString() === tahapTmp,
        );
        return namaFile?.namaDok || "";
      },
    },
    { title: "Keterangan", key: "keterangan", dataIndex: "keterangan" },
    {
      title: "Status",
      key: "statusValidasi",
      render: (record) => {
        let tahapTmp: any = tabActive === "tahap1" ? "1" : "2";
        let namaFile = fileList.find(
          (item) =>
            item.kodeDok === record.kode && item.tahap.toString() === tahapTmp,
        );
        if (activeTabKey === "pm1") {
          if (!listLap.includes(record?.key)) {
            return namaFile ? (
              <Tag
                color={
                  (namaFile?.statusValidasi.toString() === "2" && "red") ||
                  (namaFile?.statusValidasi.toString() === "1" && "#008000") ||
                  "#ffca27"
                }>
                {(namaFile?.statusValidasi.toString() === "2" && "DiTolak") ||
                  (namaFile?.statusValidasi.toString() === "1" &&
                    "Disetujui") ||
                  "Belum Disetujui"}
              </Tag>
            ) : (
              <Tag>Dokumen Kosong</Tag>
            );
          }
        } else {
          return namaFile ? (
            <Tag
              color={
                (namaFile?.statusValidasi.toString() === "2" && "red") ||
                (namaFile?.statusValidasi.toString() === "1" && "#008000") ||
                "#ffca27"
              }>
              {(namaFile?.statusValidasi.toString() === "2" && "DiTolak") ||
                (namaFile?.statusValidasi.toString() === "1" && "Disetujui") ||
                "Belum Disetujui"}
            </Tag>
          ) : (
            <Tag>Dokumen Kosong</Tag>
          );
        }
      },
    },
    {
      title: "Aksi",
      key: "aksi",
      render: (record) => {
        let tahapTmp: any = tabActive === "tahap1" ? "1" : "2";
        let namaFile = fileList.find(
          (item) =>
            item.kodeDok === record.kode && item.tahap.toString() === tahapTmp,
        );

        if (activeTabKey === "pm1") {
          return (
            <Space>
              {namaFile && (
                <>
                  <ButtonTableApproval
                    tooltips="Konfirmasi Dokumen"
                    onClick={() => handleAfterApproval(record, namaFile)}
                  />
                </>
              )}
              {fileList.length !== 0 && listLap.includes(record?.key) && (
                <Button
                  icon={<ReloadOutlined />}
                  onClick={() => handleAction(record?.key)}>
                  Lihat Data
                </Button>
              )}
            </Space>
          );
        } else {
          return (
            <Space>
              {namaFile && (
                <>
                  <ButtonTableApproval
                    tooltips="Konfirmasi Dokumen"
                    onClick={() => handleAfterApproval(record, namaFile)}
                  />
                </>
              )}
            </Space>
          );
        }
      },
    },
  ];

  let columns: any = [
    {
      title: "NSM",
      dataIndex: "nsmMadrasah",
      key: "nsmMadrasah",
    },
    {
      title: "Nama",
      dataIndex: "namaMadrasah",
      key: "namaMadrasah",
    },
    {
      title: "Penerima BOS 2022",
      key: "penerimaBos",
      dataIndex: "penerimaBos",
      render: (penerimaBos) => {
        return (
          <Tag color={(penerimaBos === 1 && "#008000") || "#ffca27"}>
            {(penerimaBos === 1 && "Penerima") || "Bukan Penerima"}
          </Tag>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "statusValidasi",
      key: "statusValidasi",
      render: (statusValidasi) => {
        return (
          <Tag color={(statusValidasi === 1 && "#008000") || "#ffca27"}>
            {(statusValidasi === 1 && "Disetujui") || "Belum Disetujui"}
          </Tag>
        );
      },
    },
    {
      title: "Aksi",
      key: "aksi",
      render: (record) => {
        return (
          <Space>
            <Button
              icon={<FileSearchOutlined />}
              onClick={() => handleAfterDokumen(record)}>
              Lihat Dokumen
            </Button>
          </Space>
        );
      },
    },
  ];

  const handleAction = async (action: any) => {
    if (action === "docRKAM6") {
      try {
        const res = await getLaporanErkam({
          tahun: dataMadrasah?.tahun,
          madrasahId: dataMadrasah?.madrasahId,
        });
        if (res) {
          setOpenModalAction(true);
          setTitle(action);
          setDataLaporan(res || []);
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        const res = await getPlanningAll("realisasi-rkam-bos/nsm", {
          tahun:
            tabActive === "tahap1"
              ? dataMadrasah?.tahun - 1
              : dataMadrasah?.tahun,
          nsm: dataMadrasah?.nsmMadrasah,
          tahap: tabActive === "tahap1" ? 2 : 1,
        });
        if (res) {
          setOpenModalAction(true);
          setTitle(action);
          setDataAction(res || null);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleAfterDokumen = (record: any) => {
    setDataMadrasah(record);
    handleDokumen(record, tabActive);
    setOpenModalData(true);
  };

  const handleDokumen = async (record: any, tahap: any) => {
    if (activeTabKey === "pm2") {
      try {
        let params: any = `&tahun=${record?.tahun}&nsm=${
          record?.nsmMadrasah
        }&tahap=${tahap === "tahap1" ? 1 : 2}`;
        const res = await getFileMadrasahManual(params);
        if (res) {
          setFileList(res || []);
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        let params: any = `?tahun=${record?.tahun}&madrasahId=${record?.madrasahId}`;
        const res = await getDokumenRencanaDefinitif(params);

        if (res) {
          setFileList(res || []);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleSave = async (values: any) => {
    let keterangan = values?.keterangan || "";
    let statusValid = values?.keterangan ? 2 : 1;
    if (activeTabKey === "pm2") {
      try {
        await statusUploadManual(
          `${id?.id}?statusValidasi=${statusValid}&keterangan=${keterangan}`,
        );
        notifAlert({
          type: "success",
          description: "Dokumen Berhasil di setujui",
        });
        handleDokumen(dataMadrasah, tabActive);
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        await statusDokumenRencanaDefinitif(
          `${id?.id}?statusValidasi=${statusValid}&keterangan=${keterangan}`,
        );
        notifAlert({
          type: "success",
          description: "Dokumen Berhasil di setujui",
        });
        handleDokumen(dataMadrasah, tabActive);
      } catch (error) {
        console.log(error);
      }
    }
    form.resetFields();
    setOpenModal(false);
  };

  const getDownloadDokumen = async (params: any, typeDoc: any) => {
    axios
      .get(
        `${baseURL}${planningService}/dokumen-rencana-definitif/download?fileName=${params?.namaFile}`,
        {
          withCredentials: false,
          responseType: "arraybuffer",

          headers: {
            Accept: "application/pdf",
            "Content-Type": "application/pdf",
            "Access-Control-Allow-Headers":
              "Content-Type,X-Amz-Date,Authorization,X-Api-Key,X-Amz-Security-Token,X-Erkam-Year",
            "Access-Control-Allow-Credentials": true,
            Authorization: "Bearer " + auth?.token,
            "X-Erkam-Year": auth?.isTahun,
          },
        },
      )
      .then(function (response: any) {
        const blob = new Blob([response.data], {
          type: typeDoc,
        });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);

        if (blob?.type === "application/pdf") {
          setPdfFile(link);
        } else {
          link.setAttribute(
            "download",
            `${typeDoc.nama}-${dataMadrasah?.namaMadrasah}.csv`,
          );
          link.click();
        }
        setLoading(false);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
        setLoading(false);
      });
  };

  const handleAfterApproval = (record: any, fileData: any) => {
    let typeDoc: any = activeTabKey === "pm1" ? "application/pdf" : record.type;
    setLoading(true);
    setOpenModal(true);
    setTitle(record.nama);
    setID(fileData);
    setTipeFile(typeDoc);
    getDownloadDokumen(fileData, typeDoc);
  };

  const handlePencairan = async () => {
    let tahapTmp = tabActive === "tahap1" ? 1 : 2;
    const dataPortalBos = await getPlanningAll("portal-bos", {
      nsm: dataMadrasah?.nsmMadrasah,
      tahun: dataMadrasah?.tahun,
    });

    const tmpBos =
      tabActive === "tahap1" ? "apbn_bos_semester1" : "apbn_bos_semester2";
    const tmoNilai = (dataPortalBos && dataPortalBos[`${tmpBos}`]) || "1";
    let payload: any = {
      id: uuidv4(),
      tahun: auth?.isTahun,
      statusPencairan: 0,
      tahap: tahapTmp,
      madrasahId: dataMadrasah?.madrasahId || null,
      nsmMadrasah: dataMadrasah?.nsmMadrasah || null,
      namaMadrasah: dataMadrasah?.namaMadrasah || null,
      nilaiPencairan: tmoNilai,
      noBuktiPencairan: "",
    };

    try {
      const res = await postDokumenPencairan(payload);
      if (res) {
        notifAlert({
          type: "success",
          description: `Data Dokumen Madrasah ${res?.namaMadrasah} telah dikirim ke Pusat`,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const confirmPencairan = () => {
    Modal.confirm({
      title: "Konfirmasi",
      icon: <ExclamationCircleOutlined />,
      content: "Apakah dokumen Dana BOS akan dicairkan?",
      okText: "Ya",
      onOk() {
        return handlePencairan();
      },
      cancelText: "Batal",
    });
  };

  dataTableDokumen =
    (dataMadrasah?.penerimaBos === 1 &&
      dataTableDokumen.filter((item) => item.key !== "docSPPD")) ||
    dataTableDokumen.filter((item) => item.key !== "docLpj1");

  return (
    <>
      <BreadCrumb
        routes={itemBreadcrumb}
        title="Kelengkapan Dokumen BOS"
        footer={
          <Tabs
            defaultActiveKey="pm1"
            size="small"
            onChange={(key) => {
              setFileList([]);
              setTmpFilter(null);
              setActiveTabKey(key);
            }}>
            <TabPane tab="eRKAM" key="pm1" />
            <TabPane tab="Upload Manual" key="pm2" />
          </Tabs>
        }
      />
      <FilterMadrasahUpload
        type={activeTabKey}
        handleChangeValues={handleChangeValues}
      />
      <div className="m-5 p-5 bg-white shadow-md rounded">
        <div className="mb-4 flex justify-end">
          <InputSearch
            className="w-3/4"
            onChange={(e: any) => setSearch(e.currentTarget.value)}
          />
        </div>
        <Table
          loading={loading}
          rowKey={(record) => record.madrasahId}
          columns={columns}
          dataSource={dataTable}
          bordered
          pagination={false}
        />
      </div>
      <Modal
        open={openModal}
        width={1000}
        title={`Approval Dokumen ${title}`}
        onCancel={() => setOpenModal(!openModal)}
        footer={[
          <Button onClick={() => setOpenModal(!openModal)}>Batal</Button>,
          <Button onClick={() => form.submit()} danger>
            Tolak
          </Button>,
          <Button type="primary" onClick={() => handleSave(1)}>
            Disetujui
          </Button>,
        ]}>
        {tipeFile === "application/pdf" ? (
          <object
            aria-labelledby="rkam"
            data={pdfFile}
            type={tipeFile}
            width="100%"
            height="400px"></object>
        ) : (
          <>
            <Alert
              message="Informasi"
              description="File excel sudah berhasil di download, mohon diperiksa kembali!"
              type="info"
              showIcon
            />
          </>
        )}
        <div className="py-4">
          <Form
            form={form}
            key="formAprrovalDocument"
            name="formAprrovalDocument"
            labelAlign="left"
            onFinish={handleSave}>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: "Keterangan tidak boleh kosong!",
                },
              ]}
              label="Keterangan"
              name="keterangan">
              <Input.TextArea placeholder="Keterangan" />
            </Form.Item>
          </Form>
        </div>
      </Modal>
      <Modal
        open={openModalData}
        width={1000}
        title={`Lihat Dokumen`}
        onCancel={() => {
          setOpenModalData(!openModalData);
          setTabActive("tahap1");
        }}
        footer={[
          <Button
            onClick={() => {
              setOpenModalData(!openModalData);
              setTabActive("tahap1");
            }}>
            Batal
          </Button>,
          <Button type="primary" onClick={confirmPencairan}>
            Pencairan Dokumen Tahap {tabActive === "tahap1" ? 1 : 2}
          </Button>,
        ]}>
        <Tabs
          activeKey={tabActive || "tahap1"}
          defaultActiveKey={"tahap1"}
          onTabClick={onTabClick}>
          <TabPane tab="Tahap 1" key="tahap1">
            <Table
              loading={loading}
              columns={columnsDokumen}
              dataSource={
                dataTableDokumen.length
                  ? dataTableDokumen.filter(
                      (item) => item.tahap === 1 && item.key !== "docTBUP",
                    )
                  : []
              }
              bordered
              pagination={false}
            />
          </TabPane>
          <TabPane tab="Tahap 2" key="tahap2">
            <Table
              loading={loading}
              columns={columnsDokumen}
              dataSource={
                dataTableDokumen.length
                  ? dataTableDokumen.filter((item) => item.tahap === 2)
                  : []
              }
              bordered
              pagination={false}
            />
          </TabPane>
        </Tabs>
      </Modal>
      <ModalDokumenAction
        openModal={openModalAction}
        dataAction={null}
        dataBOS={null}
        dataFile={[]}
        dataLaporan={dataLaporan}
        dataMadrasah={dataAction}
        onClose={() => setOpenModalAction(false)}
        action={title}
      />
    </>
  );
};

export default LaporanDokumen;
