/** @format */
/**update fix */
import axios from "axios";
import api from "../../../utils/api";
import { planningService, reportService } from "../constant";
const basePath = `${reportService}/realizations`;
const basePathLPJ = `${reportService}`;
export const baseURL: string =
  window.REACT_APP_API_URL ||
  // "https://api-gateway.erkam-v2.kemenag.go.id/api";
  "https://staging-api-gateway.erkam-v2.kemenag.go.id/api";

export const getReportAll = async (ref: any, params: any): Promise<any> => {
  try {
    let response: any = await api.get<any>(`${basePath}/${ref}`, {
      params,
    });
    return response.data.return;
  } catch (error) {
    console.log(error);
  }
};

export const getReportPlanningAll = async (
  ref: any,
  params: any,
): Promise<any> => {
  try {
    let response: any = await api.get<any>(`${basePathLPJ}/${ref}`, {
      params,
    });
    return response.data.return;
  } catch (error) {
    console.log(error);
  }
};
/** Get Laporan Realisasi Tahun   */
type laporanRealisasiParams = {
  tahun: any;
};

export const getLaporanRealisasi = async (params: laporanRealisasiParams) => {
  try {
    const response = await api.get(`${basePath}/biaya/laporan-keuangan`, {
      params,
    });
    return response.data.return;
  } catch (error) {
    console.log(error);
  }
};

export const getDasboardAkb = async (params: laporanRealisasiParams) => {
  try {
    const response = await api.get(`${basePath}/dashboard-akb`, {
      params,
    });
    return response.data.return;
  } catch (error) {
    console.log(error);
  }
};

export const getDashboardSNP = async (params: laporanRealisasiParams) => {
  try {
    const response = await api.get(`${basePath}/dashboard-snp`, {
      params,
    });
    return response.data.return;
  } catch (error) {
    console.log(error);
  }
};

/** Get Laporan APBM Dashboard By Madrasah ID dan Tahun   */
type laporanAPBMDashboardParams = {
  tahun: number;
};

export const getLaporanRAPBMDashboard = async (
  params: laporanAPBMDashboardParams,
) => {
  try {
    const response = await api.get(`${basePath}/laporan-apbm-dashboard`, {
      params,
    });
    return response.data.return;
  } catch (error) {
    console.log(error);
  }
};

/** Get Laporan BKU By Madrasah ID dan Tahun   */
type laporanBKUParams = {
  madrasahId?: string;
  kodeSumberDana?: string;
  tahun: any;
  bulan?: any;
};

export const getLaporanBKU = async (params: laporanBKUParams) => {
  try {
    const response = await api.get(`${basePath}/laporan-bku`, {
      params,
    });
    // setStoreDB("laporanBKU", response.data.return);
    return response.data.return;
  } catch (error) {
    console.log(error);
  }
};

/** Get Laporan BKU Konsolidasi   */
type laporanBKUKonsolidasiParams = {
  kodeKabKota?: string;
  kodeSumberDana?: string;
  status?: string;
  tahun: any;
  bulan?: any;
};

export const getLaporanBKUKonsolidasi = async (
  params: laporanBKUKonsolidasiParams,
) => {
  try {
    const response = await api.get(`${basePath}/laporan-bku-konsolidasi`, {
      params,
    });
    // setStoreDB("laporanBKU", response.data.return);
    return response.data.return;
  } catch (error) {
    console.log(error);
  }
};

/** Get Laporan BKU Tunai By Madrasah ID dan Tahun   */
type laporanBKUTunaiParams = {
  madrasahId?: string;
  kodeSumberDana?: string;
  tahun: any;
  periode?: any;
};

export const getLaporanBKUTunai = async (params: laporanBKUTunaiParams) => {
  try {
    const response = await api.get(
      `${basePath}/laporan-buku-pembantu-kas-tunai`,
      {
        params,
      },
    );
    // setStoreDB("laporanBKU", response.data.return);
    return response.data.return;
  } catch (error) {
    console.log(error);
  }
};

/** Get Laporan Pembantu Pajak By Madrasah ID dan Tahun   */
type laporanPembantuPajakParams = {
  madrasahId?: string;
  tahun: any;
  bulan: any;
};

export const getLaporanPembantuPajak = async (
  params: laporanPembantuPajakParams,
) => {
  try {
    const response = await api.get(`${basePath}/laporan-pembantu-pajak`, {
      params,
    });
    // setStoreDB("laporanPembantuPajak", response.data.return);
    return response.data.return;
  } catch (error) {
    console.log(error);
  }
};

/** Get Laporan Pertanggung Jawaban Bendahara Pengeluaran By Madrasah ID dan Tahun   */
type laporanPertanggungJawabanParams = {
  madrasahId?: string;
  tahun: number;
  kodeSumberDana: string;
};

/** Get Laporan Pertanggung Jawaban Bendahara Pengeluaran By Madrasah ID dan Tahun   */
export const getLaporanPertanggungJawabanBendaharaPengeluaran = async (
  params: laporanPertanggungJawabanParams,
) => {
  try {
    const response = await api.get(
      `${basePath}/laporan-pertanggungjawaban-bendahara-pengeluaran`,
      {
        params,
      },
    );
    // setStoreDB(
    //   "laporanPertanggungJawabanBendaharaPengeluaran",
    //   response.data.return,
    // );
    return response.data.return;
  } catch (error) {
    console.log(error);
  }
};

type LPJParams = {
  madrasahId?: string;
  tahun: number;
  sumberDana: string;
  uangTunaiDiBrankas?: number;
  uangDiRekening?: number;
  penjelasanSelisihKas?: string;
  periode?: number;
};

export const postLaporanPertanggungJawaban = async (params: LPJParams) => {
  try {
    const response = await api.post(
      `${basePathLPJ}/laporan-pertanggung-jawaban`,
      params,
    );
    return response.data.return;
  } catch (error) {
    console.log(error);
  }
};

type GetLPJParams = {
  tahun: number;
  kodeSumberDana?: string;
  periode?: number;
};

export const getLaporanPertanggungJawaban = async (params: GetLPJParams) => {
  try {
    // Set nilai default periode jika tidak tersedia
    const defaultParams = {
      ...params,
      periode: params.periode || 1,
      kodeSumberDana: params.kodeSumberDana || "apbn_bos_semester_1",
    };

    const response = await api.get(
      `${basePathLPJ}/laporan-pertanggung-jawaban`,
      {
        params: defaultParams,
      },
    );

    // setStoreDB("laporanSumberDana", response.data.return);
    return response.data.return;
  } catch (error) {
    console.log(error);
  }
};

/** Get Laporan Daftar Sumber Dana By Madrasah ID dan Tahun   */
type laporanSumberDanaParams = {
  madrasahId?: string;
  tahun: any;
};

export const getLaporanDaftarSumberDana = async (
  params: laporanSumberDanaParams,
) => {
  try {
    const response = await api.get(`${basePath}/laporan/daftar-sumber-dana`, {
      params,
    });
    // setStoreDB("laporanSumberDana", response.data.return);
    return response.data.return;
  } catch (error) {
    console.log(error);
  }
};

/** Get Laporan Output Kegiatan By Madrasah ID dan Tahun   */
type laporanOutputKegiatanParams = {
  madrasahId?: string;
  tahun: any;
};

export const getLaporanOutputKegiatan = async (
  params: laporanOutputKegiatanParams,
) => {
  try {
    const response = await api.get(`${basePath}/laporan/output-kegiatan`, {
      params,
    });
    return response.data.return;
  } catch (error) {
    console.log(error);
  }
};

export const getLaporanPertanggungJawabanAxios = async (
  tahunLpj: any,
  auth: any,
  madrasahId: any,
  periode: any,
) => {
  return axios
    .get(
      `${baseURL}/v2/report-services/laporan-pertanggung-jawaban?tahun=${tahunLpj}&kodeSumberDana=apbn_bos_semester_2&periode=${periode}&madrasahId=${madrasahId}`,
      {
        withCredentials: false,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Headers":
            "Content-Type,X-Amz-Date,Authorization,X-Api-Key,X-Amz-Security-Token,X-Erkam-Year",
          "Access-Control-Allow-Credentials": true,
          Authorization: "Bearer " + auth?.token,
          "X-Erkam-Year": tahunLpj,
        },
      },
    )
    .then(function (response: any) {
      // console.log(response);
      if (response?.data?.return) {
        return response?.data?.return || null;
      }
      return null;
    })
    .catch(function (error) {
      // handle error
      console.log(error);
    });
};

export const getLaporanPengembalianDanaAxios = async (
  tahunLpj: any,
  auth: any,
  madrasahId: any,
) => {
  return axios
    .get(
      `${baseURL}/v2/realization-services/realizations/pengembalian-dana/portal-bos/sisa-saldo-realisasi?madrasahId=${madrasahId}&tahun=${tahunLpj}`,
      {
        withCredentials: false,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Headers":
            "Content-Type,X-Amz-Date,Authorization,X-Api-Key,X-Amz-Security-Token,X-Erkam-Year",
          "Access-Control-Allow-Credentials": true,
          Authorization: "Bearer " + auth?.token,
          "X-Erkam-Year": tahunLpj,
        },
      },
    )
    .then(function (response: any) {
      // console.log(response);
      if (response?.data?.return) {
        // setDataAction(response?.data?.return || null);
        return response?.data?.return || { sisa: 0 };
      } else {
        return response?.data?.return || { sisa: 0 };
      }
    })
    .catch(function (error) {
      // handle error
      console.log(error);
    });
};

export const getDokumenRencanaDefinitifAxios = async (
  params: any,
  auth: any,
  typeDoc: any,
  dataMadrasah: any,
) => {
  return axios
    .get(
      `${baseURL}${planningService}/dokumen-rencana-definitif/download?fileName=${params?.namaFile}`,
      {
        withCredentials: false,
        responseType: "arraybuffer",
        headers: {
          Accept: "application/pdf",
          "Content-Type": "application/pdf",
          "Access-Control-Allow-Headers":
            "Content-Type,X-Amz-Date,Authorization,X-Api-Key,X-Amz-Security-Token,X-Erkam-Year",
          "Access-Control-Allow-Credentials": true,
          Authorization: "Bearer " + auth?.token,
          "X-Erkam-Year": auth?.isTahun,
        },
      },
    )
    .then(function (response: any) {
      const blob = new Blob([response.data], {
        type: typeDoc,
      });
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);

      if (blob?.type === "application/pdf") {
        return link;
      } else {
        link.setAttribute(
          "download",
          `${typeDoc.nama}-${dataMadrasah?.namaMadrasah}.xlsx`,
        );
        link.click();
        return null;
      }
    })
    .catch(function (error) {
      // handle error

      console.log(error);
      return null;
    });
};

export const getBPKSummaryAanggaranRealisasi = async (
  tahun: any,
  auth: any,
  kodeSumberDana: any,
) => {
  return axios
    .get(
      `${baseURL}/v2/report-services/bpk/summary-anggaran-realisasi?tahun=${tahun}&kodeSumberDana=${kodeSumberDana}`,
      {
        withCredentials: false,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Headers":
            "Content-Type,X-Amz-Date,Authorization,X-Api-Key,X-Amz-Security-Token",
          "Access-Control-Allow-Credentials": true,
          Authorization: "Bearer " + auth?.token,
        },
      },
    )
    .then(function (response: any) {
      // console.log(response);
      if (response?.data?.return) {
        // setDataAction(response?.data?.return || null);
        return response?.data?.return || [];
      } else {
        return response?.data?.return || [];
      }
    })
    .catch(function (error) {
      // handle error
      console.log(error);
    });
};

export const getBPKSummaryAanggaranRealisasiKabkota = async (
  tahun: any,
  auth: any,
  kodeKabKota: any,
  kodeSumberDana: any,
) => {
  return axios
    .get(
      `${baseURL}/v2/report-services/bpk/summary-anggaran-realisasi-kabkota?tahun=${tahun}&kodeSumberDana=${kodeSumberDana}&kodeKabKota=${kodeKabKota}`,
      {
        withCredentials: false,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Headers":
            "Content-Type,X-Amz-Date,Authorization,X-Api-Key,X-Amz-Security-Token",
          "Access-Control-Allow-Credentials": true,
          Authorization: "Bearer " + auth?.token,
        },
      },
    )
    .then(function (response: any) {
      // console.log(response);
      if (response?.data?.return) {
        // setDataAction(response?.data?.return || null);
        return response?.data?.return || [];
      } else {
        return response?.data?.return || [];
      }
    })
    .catch(function (error) {
      // handle error
      console.log(error);
    });
};

export const getBPKSummaryAanggaranRealisasiProvinsi = async (
  tahun: any,
  auth: any,
  kodeProvinsi: any,
  kodeSumberDana: any,
) => {
  return axios
    .get(
      `${baseURL}/v2/report-services/bpk/summary-anggaran-realisasi-provinsi?tahun=${tahun}&kodeSumberDana=${kodeSumberDana}&kodeProvinsi=${kodeProvinsi}`,
      {
        withCredentials: false,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Headers":
            "Content-Type,X-Amz-Date,Authorization,X-Api-Key,X-Amz-Security-Token",
          "Access-Control-Allow-Credentials": true,
          Authorization: "Bearer " + auth?.token,
        },
      },
    )
    .then(function (response: any) {
      // console.log(response);
      if (response?.data?.return) {
        // setDataAction(response?.data?.return || null);
        return response?.data?.return || [];
      } else {
        return response?.data?.return || [];
      }
    })
    .catch(function (error) {
      // handle error
      console.log(error);
    });
};

export const getBPKSumberDanaAanggaranRealisasi = async (
  tahun: any,
  auth: any,
) => {
  return axios
    .get(
      `${baseURL}/v2/report-services/bpk/sumber-dana-anggaran-realisasi?tahun=${tahun}`,
      {
        withCredentials: false,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Headers":
            "Content-Type,X-Amz-Date,Authorization,X-Api-Key,X-Amz-Security-Token",
          "Access-Control-Allow-Credentials": true,
          Authorization: "Bearer " + auth?.token,
        },
      },
    )
    .then(function (response: any) {
      // console.log(response);
      if (response?.data?.return) {
        // setDataAction(response?.data?.return || null);
        return response?.data?.return || [];
      } else {
        return response?.data?.return || [];
      }
    })
    .catch(function (error) {
      // handle error
      console.log(error);
    });
};

/** Get Laporan BKU without  By Madrasah ID dan Tahun   */

export const getLaporanBKUBPK = async (params: any) => {
  try {
    const response = await api.get(
      `${basePath}/public/laporan-bku-without-periode?nsm=${params?.nsm}&kodeSumberDana=${params?.kodeSumberDana}&tahun=${params?.tahun}`,
    );
    return response.data.return;
  } catch (error) {
    console.log(error);
  }
  // return axios
  //   .get(
  //     `${baseURL}/v2/report-services/realizations/public/laporan-bku-without-periode?nsm=${params?.nsm}&kodeSumberDana=${params?.kodeSumberDana}&tahun=${params?.tahun}`,
  //     {
  //       withCredentials: false,
  //       headers: {
  //         Accept: "application/json",
  //         "Content-Type": "application/json",
  //         "Access-Control-Allow-Origin": "*",
  //         "Access-Control-Allow-Credentials": false,
  //         "Access-Control-Allow-Headers":
  //           "Content-Type,X-Amz-Date,Authorization,X-Api-Key,X-Amz-Security-Token,X-Erkam-Year",
  //         "X-Erkam-Year": params?.tahun,
  //       },
  //     },
  //   )
  //   .then(function (response: any) {
  //     // console.log(response);
  //     if (response?.data?.return) {
  //       // setDataAction(response?.data?.return || null);
  //       return response?.data?.return || [];
  //     } else {
  //       return response?.data?.return || [];
  //     }
  //   })
  //   .catch(function (error) {
  //     // handle error
  //     console.log(error);
  //   });
};
