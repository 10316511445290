/** @format */

import { Input, Select, Button, InputNumber, Form, Tooltip } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { uuidv4 } from "../../../../../utils/helper";

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean;
  dataIndex: string;
  name: string;
  title: any;
  rules: any;
  inputType: "number" | "text";
  record: any;
  index: number;
  tmpSatuan: any;
  tmpPajak: any;
  tmpTambahKomponen: any;
  tmpJenisBelanja: any;
  listKoefisien: any;
  listSatuan: any;
  tambahJenisBelanja: any;
  madrasahStatus: any;
  disabledPembiayaan: boolean;
  handleOpenKomponen: (e: string) => void;
  handleOpenSumberDana: (e: string) => void;
  handleOpenAKB: (e: string) => void;
  tmpTipePencairan: any;
  children: React.ReactNode;
}

const EditableCell: React.FC<EditableCellProps> = ({
  editing,
  dataIndex,
  title,
  name,
  rules,
  inputType,
  record,
  tmpSatuan,
  tmpPajak,
  tmpTipePencairan,
  index,
  tmpJenisBelanja,
  tmpTambahKomponen,
  children,
  listKoefisien,
  listSatuan,
  madrasahStatus,
  disabledPembiayaan,
  handleOpenKomponen,
  handleOpenSumberDana,
  handleOpenAKB,
  ...restProps
}) => {
  // console.log(listKoefisien);
  let inputNode: any = null;
  let idRandom = uuidv4();

  switch (name) {
    case "komponenBiayaNama":
      inputNode = (
        <Input
          key={`kombi${idRandom}`}
          disabled
          suffix={
            <Button
              size="small"
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => handleOpenKomponen("Edit")}
            />
          }
        />
      );
      break;
    case "ketBiayaLain":
      inputNode = (
        <Input
          key={`biayLa${idRandom}`}
          disabled={
            tmpTambahKomponen && tmpTambahKomponen?.listKomponen?.belanja_lain
              ? false
              : true
          }
          // placeholder="Keterangan Komponen Biaya"
        />
      );
      break;
    case "kodeJenisBelanja":
      inputNode = (
        <Select
          key={`editAkun${idRandom}`}
          placeholder="Kode Akun"
          style={{ width: "100%" }}>
          {tmpTambahKomponen &&
            tmpJenisBelanja.length &&
            tmpJenisBelanja
              .filter((item: any) =>
                tmpTambahKomponen?.listKomponen?.jenis_belanja.includes(
                  item.kode,
                ),
              )
              .map((item: any) => (
                <Select.Option
                  key={`kodjes${item.kode}${idRandom}`}
                  value={item.kode}>
                  <Tooltip title={`${item.kode}-${item.nama}`}>
                    {item.nama}
                  </Tooltip>
                </Select.Option>
              ))}
        </Select>
      );
      break;
    case "koef1Jumlah":
    case "koef2Jumlah":
    case "koef3Jumlah":
    case "koef4Jumlah":
      inputNode = (
        <InputNumber
          key={`${name}${idRandom}`}
          min={0}
          type="number"
          placeholder="Target"
          disabled={tmpTambahKomponen ? disabledPembiayaan : true}
        />
      );
      break;
    case "koef1Satuan":
    case "koef2Satuan":
    case "koef3Satuan":
    case "koef4Satuan":
      inputNode = (
        <Select
          showSearch
          allowClear
          key={`${name}${idRandom}`}
          disabled={tmpTambahKomponen ? disabledPembiayaan : true}
          placeholder="Satuan"
          style={{ width: "100%" }}>
          {tmpSatuan.length &&
            tmpSatuan
              .filter((item: any) => !listSatuan.includes(item.kode))
              .sort((a, b) => a.nama.localeCompare(b.nama))
              .map((item: any) => (
                <Select.Option
                  key={`sat${item.kode}${idRandom}`}
                  value={item.kode}>
                  {item.nama}
                </Select.Option>
              ))}
        </Select>
      );
      break;
    case "hargaSatuan":
      inputNode = (
        <InputNumber
          key={`inputtambahHargaSatuan${idRandom}`}
          style={{ width: "100%", background: "#fff", color: "#000" }}
          disabled
          formatter={(value) =>
            `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          }
          // prefix="Rp."
        />
      );
      break;
    case "pajak":
      inputNode = (
        <Select
          key={`${name}${idRandom}`}
          placeholder={tmpTambahKomponen ? "PPN" : "Komponen masih kosong"}
          style={{ width: "100%" }}>
          {tmpTambahKomponen &&
            tmpPajak.length &&
            tmpPajak
              .filter(
                (item: any) =>
                  !["pph21", "pph22", "ppnTerhutang", "pph23"].includes(
                    item.kode,
                  ),
              )
              .map((item: any) => (
                <Select.Option
                  key={`paj${item.kode}${index}`}
                  value={item.kode}>
                  {item.deskripsi}
                </Select.Option>
              ))}
        </Select>
      );
      break;
    case "totalHarga":
      inputNode = (
        <InputNumber
          key={`${name}${idRandom}`}
          style={{ width: "100%", background: "#fff", color: "#000" }}
          formatter={(value) =>
            `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          }
          disabled
          // prefix="Rp."
        />
      );
      break;
    case "sumberDana":
      inputNode = (
        <Input
          key={`${name}${idRandom}`}
          disabled
          suffix={
            <Button
              size="small"
              type="primary"
              icon={<PlusOutlined />}
              disabled={disabledPembiayaan}
              onClick={() => handleOpenSumberDana("Edit")}
            />
          }
        />
      );
      break;
    case "tipePencairan":
      inputNode = (
        <Select
          key={`${name}${idRandom}`}
          disabled={madrasahStatus && madrasahStatus === "n" ? false : true}
          placeholder="Tipe Pencairan"
          style={{ width: "100%" }}>
          {tmpTipePencairan.length &&
            tmpTipePencairan.map((item: any) => (
              <Select.Option
                key={`tipe${item.kode}${idRandom}`}
                value={item.kode}>
                {item.nama}
              </Select.Option>
            ))}
        </Select>
      );
      break;
    case "akb":
      inputNode = (
        <Input
          key={`${name}${idRandom}`}
          disabled
          suffix={
            <Button
              size="small"
              type="primary"
              disabled={disabledPembiayaan}
              icon={<PlusOutlined />}
              onClick={() => handleOpenAKB("Edit")}
            />
          }
        />
      );
      break;
  }

  return (
    <td key={`tdac${uuidv4()}`} {...restProps}>
      {editing ? (
        <>
          <Form.Item
            key={`${name}${idRandom}`}
            name={name}
            // style={{ margin: 0 }}
            rules={rules}
            hasFeedback>
            {inputNode}
          </Form.Item>
        </>
      ) : (
        children
      )}
    </td>
  );
};
export default EditableCell;
