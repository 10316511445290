/** @format */

import React, { useEffect, useState } from "react";
import { Form, Input, Button, Divider, Space, Select, Typography } from "antd";
import { useHistory } from "react-router";
import notifAlert from "../../../components/NotifAlert";
import { useDispatch, useSelector } from "react-redux";
import { getProfile } from "../../../services/v2/usermanservice/profileservices";
import { setStore } from "../../../redux/actions";
import { editMadrasahNsm } from "../../../services/v2/usermanservice/madrasahservices";
import { getReferenceAll } from "../../../services/v2/referenceservice";
const { Text } = Typography;

const ProfileMadrasah = () => {
  const route = useHistory();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const store = useSelector((state: any) => state.store);
  const madrasah = store?.profile?.madrasah || null;
  const { provinsi, kabkota, kecamatan } = store;
  const [isLoading, setIsLoading] = useState(false);
  const [kelurahan, setRefKelurahan] = useState<any>([]);
  const getData = async () => {
    const tmpKelurahan = store.kelurahan || [];
    if (tmpKelurahan.length) {
      setRefKelurahan(tmpKelurahan);
    } else {
      const kelurahan = await getReferenceAll("kelurahan", { activated: 1 });
      setRefKelurahan(kelurahan);
      setTimeout(() => {
        dispatch(setStore({ kelurahan }));
      }, 100);
    }
  };

  useEffect(() => {
    getData();
    form.setFieldsValue({
      nsm: madrasah?.nsm,
      npsn: madrasah?.npsn,
      nama: madrasah?.nama,
      jenjang: madrasah?.jenjang.nama,
      status: madrasah?.status === "n" ? "Negeri" : "Swasta",
      provinsi: madrasah?.kode_provinsi,
      kabkota: madrasah?.kode_kabkota,
      kelurahan: madrasah?.kode_kelurahan,
      kecamatan: madrasah?.kode_kecamatan,
      rt: madrasah?.rt,
      rw: madrasah?.rw,
      alamat: madrasah?.alamat_jalan,
      kodepos: madrasah?.kode_pos,
      npwp: madrasah?.npwp,
      telepon: madrasah?.telp,
      website: madrasah?.website,
      email: madrasah?.email,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [madrasah]);

  const handleSaveProfile = async (values) => {
    setIsLoading(true);
    try {
      let payload = {
        nsm: values.nsm,
        // nama_komite: values.nama,
      };
      await editMadrasahNsm(payload, madrasah?.id);
      setTimeout(async () => {
        const profile = await getProfile();
        const dataProfile = { profile };
        dispatch(setStore(dataProfile));
      }, 100);
      notifAlert({ type: "success", description: "Data Berhasil di simpan" });
    } catch (err) {
      console.log(err);
    }
    setIsLoading(false);
  };

  return (
    <>
      <div className="p-4">
        <Form
          form={form}
          key="formEditProfile"
          name="formEditProfile"
          layout="vertical"
          onFinish={handleSaveProfile}>
          <Text strong>Profil Madrasah</Text>
          <Divider />
          <div className="flex  flex-col  md:flex-row gap-2 ">
            <div className="flex-1">
              <Form.Item
                label="NSM"
                name="nsm"
                rules={[
                  {
                    required: true,
                    message: "NSM tidak boleh kosong!",
                  },
                ]}>
                <Input placeholder="NSM" />
              </Form.Item>
            </div>
            <div className="flex-1">
              <Form.Item label="NPSN" name="npsn">
                <Input placeholder="NPSN" disabled />
              </Form.Item>
            </div>
            <div className="flex-1">
              <Form.Item label="Nama Lembaga" name="nama">
                <Input placeholder="Nama Lembaga" disabled />
              </Form.Item>
            </div>
          </div>
          <div className="flex  flex-col  md:flex-row gap-2 ">
            <div className="flex-1">
              <Form.Item label="Jenjang" name="jenjang">
                <Input placeholder="Jenjang" disabled />
              </Form.Item>
            </div>
            <div className="flex-1">
              <Form.Item label="Status Madrasah" name="status">
                <Input placeholder="Status Madrasah" disabled />
              </Form.Item>
            </div>
          </div>
          <Text strong>Alamat Madrasah</Text>
          <Divider />
          <div className="flex  flex-col  md:flex-row gap-2 ">
            <div className="flex-1">
              <Form.Item
                label="Provinsi"
                name="provinsi"
                rules={[
                  {
                    required: true,
                    message: "Provinsi tidak boleh kosong!",
                  },
                ]}>
                <Select placeholder="Provinsi" disabled>
                  {provinsi.map((item: any, index: number) => (
                    <Select.Option key={`pro${index}`} value={item.kode}>
                      {item.nama}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
            <div className="flex-1">
              <Form.Item
                label="Kabupaten/Kota"
                name="kabkota"
                rules={[
                  {
                    required: true,
                    message: "Kabupaten/Kota tidak boleh kosong!",
                  },
                ]}>
                <Select placeholder="Kabupaten/Kota" disabled>
                  {kabkota.map((item: any, index: number) => (
                    <Select.Option key={`kab${index}`} value={item.kode}>
                      {item.nama}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
          </div>
          <div className="flex  flex-col  md:flex-row gap-2 ">
            <div className="flex-1">
              <Form.Item label="Kecamatan" name="kecamatan">
                <Select placeholder="Kecamatan" disabled>
                  {kecamatan.map((item: any, index: number) => (
                    <Select.Option key={`kec${index}`} value={item.kode}>
                      {item.nama}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
            <div className="flex-1">
              <Form.Item label="Kelurahan" name="kelurahan">
                <Select placeholder="Kelurahan" disabled>
                  {kelurahan.map((item: any, index: number) => (
                    <Select.Option key={`kel${index}`} value={item.kode}>
                      {item.nama}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
          </div>
          <div className="flex  flex-col  md:flex-row gap-2 ">
            <div className="flex-1">
              <Form.Item label="RT" name="rt">
                <Input placeholder="RT" disabled />
              </Form.Item>
            </div>
            <div className="flex-1">
              <Form.Item label="RW" name="rw">
                <Input placeholder="RW" disabled />
              </Form.Item>
            </div>
          </div>
          <div className="flex  flex-col  md:flex-row gap-2 ">
            <div className="flex-1">
              <Form.Item label="Alamat Lengkap" name="alamat">
                <Input placeholder="Alamat Lengkap" disabled />
              </Form.Item>
            </div>
            <div className="flex-1">
              <Form.Item label="Kode Pos" name="kodepos">
                <Input placeholder="Kode Pos" disabled />
              </Form.Item>
            </div>
          </div>
          <Text strong>Informasi Lainnya</Text>
          <Divider />
          <div className="flex  flex-col  md:flex-row gap-2 ">
            <div className="flex-1">
              <Form.Item label="NPWP" name="npwp">
                <Input placeholder="NPWP" disabled />
              </Form.Item>
            </div>
            <div className="flex-1">
              <Form.Item label="Telepon" name="telepon">
                <Input placeholder="Telepon" disabled />
              </Form.Item>
            </div>
          </div>
          <div className="flex  flex-col  md:flex-row gap-2 ">
            <div className="flex-1">
              <Form.Item label="Email" name="email">
                <Input placeholder="Email" disabled />
              </Form.Item>
            </div>
            <div className="flex-1">
              <Form.Item label="Website" name="website">
                <Input placeholder="Website" disabled />
              </Form.Item>
            </div>
          </div>
          <Divider />
          <Space className="">
            <Button
              onClick={() => {
                route.push("/profile-madrasah");
              }}>
              Batal
            </Button>
            <Button type="primary" htmlType="submit" loading={isLoading}>
              Simpan
            </Button>
          </Space>
        </Form>
      </div>
    </>
  );
};

export default ProfileMadrasah;
